import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Markdown from 'markdown-to-jsx';
import { decode } from 'html-entities';
import { Typography } from '../../../../components';
import { ActivityAvatar } from '../atoms';

dayjs.extend(relativeTime);

function PrimaryText({ name }: {name: string}) {
  return <Typography variant="body-14-bold" className="text-trueGray-800 inline-block" style={{ wordBreak: 'break-word' }}>{name}</Typography>;
}

function Timestamp({ value }: { value: number}) {
  return <Typography variant="body-14-bold" className="text-trueGray-800 inline-block">{dayjs(Number(value)).format('ll')}</Typography>;
}

interface ActivityItemProps{
  message: string;
  userType: string;
  eventType: string;
  createdAt: number;
  showRelativeTime?: boolean;
  hLayout?: boolean;
}

function ActivityItem(props: ActivityItemProps): JSX.Element {
  const {
    message, userType, eventType, createdAt, showRelativeTime = true, hLayout = false,
  } = props;
  const htmlDecodedMessage = decode(message);

  return (
    <>
      <div className="flex flex-row pb-4">
        <div className="flex flex-col">
          <ActivityAvatar userType={userType} eventType={eventType} />
        </div>
        <div className={`ml-4 flex-1 text-[14px] ${hLayout ? 'flex items-start justify-between' : ''}`}>
          <Markdown options={{
            forceInline: true,
            overrides: {
              PrimaryText: {
                component: PrimaryText,
              },
              Timestamp: {
                component: Timestamp,
              },
            },
          }}
          >
            {htmlDecodedMessage}
          </Markdown>
          <div className="text-trueGray-500 text-12 ml-1 whitespace-nowrap">{showRelativeTime ? dayjs(createdAt).fromNow() : dayjs(createdAt).format('h:mm A')}</div>
        </div>
      </div>
    </>
  );
}

export default ActivityItem;
