/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated-types/index';

import { api } from 'api/base-api';
module.hot?.accept();
export type GetMspArchiveReasonListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetMspArchiveReasonListQuery = {
  __typename?: 'Query';
  mspQuery?: {
    __typename: 'MspQuery';
    listMspArchivalReasons:
      | { __typename?: 'FilterItemsPayload' }
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: Types.ErrorType;
          message: string;
        };
  };
};

export const GetMspArchiveReasonListDocument = `
    query GetMspArchiveReasonList {
  mspQuery {
    __typename
    listMspArchivalReasons {
      ... on GraphqlError {
        code
        type
        message
      }
      ... on FilterItemsPayloadResponse {
        ... on GraphqlError {
          code
          type
          message
        }
        ... on FilterItemsPayload {
          items {
            ... on FilterItems {
              key
              displayName
              isVisible
              filterType
              values
            }
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetMspArchiveReasonList: build.query<
      GetMspArchiveReasonListQuery,
      GetMspArchiveReasonListQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMspArchiveReasonListDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMspArchiveReasonListQuery,
  useLazyGetMspArchiveReasonListQuery,
} = injectedRtkApi;
