import Popover from '@mui/material/Popover';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { trackEvent } from '../../../analytics';
import AlertIcon from '../../../assets/alert-icon.svg';
import LinkIcon from '../../../assets/link-icon.svg';
import SalesforceIcon from '../../../assets/salesforce.svg';
import { toolTip } from '../../../common-language';
import { RootState } from '../../../common/redux-store';
import { Typography } from '../../../components/typography';
import {
  IUser,
  MspPermissionPayload,
  MspUserBridge,
  Opportunity,
  MspInvitePermission,
  UserType,
} from '../../../generated-types';
import SearchMsp from './search-msp';
import SearchOpportunity from './search-opportunity';
import { CustomToolTip } from '../../../components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '440px',
    height: '370px',
    background: theme.palette.primary.contrastText,
    borderRadius: '3px',
    padding: '10px',
    border: `1px solid ${theme.palette.primary.contrastText}`,
    boxSizing: 'border-box',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.31)',
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
  },
}));

interface IOpportunityProps {
  datatest?: string | undefined;
  refetchMsp?: () => void;
  isUsedInSalesforce: boolean;
}

const mapState = (state: RootState) => ({
  opportunityDetails: state?.msp?.mspDetail?.opportunity as Opportunity,
  mspOwnerData: state?.msp?.mspDetail?.createdBy as IUser,
  userDetails: state?.userInfo?.userDetails,
  mspPermissions: (
    (state?.msp?.mspDetail?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ trackEvent }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MspOpportunityProps = IOpportunityProps & PropsFromRedux;

function MspOpportunity(props: MspOpportunityProps) {
  const {
    opportunityDetails,
    mspOwnerData,
    userDetails,
    datatest,
    isUsedInSalesforce = false,
    refetchMsp,
    mspPermissions,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const openOpportunity = useCallback(
    (
      event: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>,
    ) => {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, [setAnchorEl, open]);
  let isOwner = false;
  if (mspOwnerData && userDetails) {
    isOwner = mspOwnerData?.id === userDetails?.userId ?? false;
  }
  if (
    mspPermissions?.includes(MspInvitePermission.ALL) &&
    userDetails.userType === UserType.SELLER
  ) {
    isOwner = true;
  }

  if (opportunityDetails) {
    return (
      <div className="group flex items-center space-x-1 border-0 border-l border-solid border-gray-300 pl-3">
        <Tooltip placement="top" arrow title={opportunityDetails?.name}>
          <div className="flex items-center space-x-2">
            <div className="relative">
              <img
                alt="salesforce"
                src={SalesforceIcon as string}
                width={28}
                height={28}
                role="presentation"
              />
              <img
                alt="link icon"
                src={LinkIcon as string}
                width={36}
                height={36}
                role="presentation"
                className="absolute -right-6 -top-3"
              />
            </div>
            <Typography
              variant="body-14-bold"
              className="hidden truncate pl-3 text-neutral group-hover:flex"
            >
              {opportunityDetails?.name}
            </Typography>
          </div>
        </Tooltip>
        {isOwner && (
          <Tooltip
            arrow
            placement="top"
            title={toolTip['MspPlanView.removeOpportunity']}
          >
            <div className="hidden group-hover:flex">
              <FiXCircle className="text-mid" data-test={datatest} />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <>
      {isUsedInSalesforce ? (
        <div>
          <Button color="primary" onClick={openOpportunity} size="small">
            <Typography variant="label-12-regular">
              <FormattedMessage
                id="Button.assignMsp"
                defaultMessage="+ Assign Mutual Success Plan"
              />
            </Typography>
          </Button>
        </div>
      ) : (
        <>
          {isOwner && (
            <>
              <div className="h-5 border-0 border-l border-solid border-gray-300 pr-2" />
              <CustomToolTip arrow title={toolTip['Salesforce.opportunity']}>
                <div className="relative mr-4 cursor-pointer p-1">
                  <img
                    onClick={openOpportunity}
                    alt="salesforce"
                    src={SalesforceIcon as string}
                    width={28}
                    height={28}
                    role="presentation"
                  />
                  <img
                    alt="alert icon"
                    onClick={openOpportunity}
                    src={AlertIcon as string}
                    width={24}
                    height={24}
                    role="presentation"
                    className="absolute -right-3.5 top-0"
                  />
                </div>
              </CustomToolTip>
            </>
          )}
        </>
      )}

      <Popover
        id={anchorEl ? 'filters' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isUsedInSalesforce ? 'top' : 'bottom',
          horizontal: isUsedInSalesforce ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: isUsedInSalesforce ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        elevation={2}
      >
        <>
          <Typography className="px-4 pt-4" variant="heading4">
            {isUsedInSalesforce ? 'Link MSP' : 'Assign Opportunity'}
          </Typography>
          {isUsedInSalesforce ? (
            <SearchMsp />
          ) : (
            <SearchOpportunity
              refetchMspdata={() => {
                setAnchorEl(null);
                if (refetchMsp && typeof refetchMsp === 'function')
                  refetchMsp();
              }}
            />
          )}
        </>
      </Popover>
    </>
  );
}
export default connector(MspOpportunity);
