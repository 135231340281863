import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  ActivityTimelineConnection,
  ActivityTimeline,
  ActivityTimelineEdge,
  ActivityTimelineHighlights,
  PageInfo,
} from '../../../generated-types';
import { extendedApi } from '../api';

const activityAdapter = createEntityAdapter({
  selectId: (item: ActivityTimelineEdge) =>
    (item?.node as ActivityTimeline)?.createdAt,
  sortComparer: (a, b) =>
    (a?.node as ActivityTimeline)?.createdAt
    > (b?.node as ActivityTimeline)?.createdAt,
});

const initialState = {
  data: activityAdapter.getInitialState(),
  highlights: [] as ActivityTimelineHighlights[],
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    afterCursor: '',
    beforeCursor: '',
  } as PageInfo,
  filters: [] as string[],
  isLoading: false,
  error: {
    type: '',
    message: '',
  },
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        extendedApi.endpoints.getActivityTimeline.matchFulfilled,
        (state, action) => {
          const filters = action.meta.arg.originalArgs.input.filterEventTypes;
          const { activityTimelineHighlights, pageInfo } = action.payload as ActivityTimelineConnection;

          state.highlights = activityTimelineHighlights ?? [];
          state.pageInfo = pageInfo ?? {};
          state.isLoading = false;
          state.error = { type: '', message: '' };
          state.filters = filters ?? [];
        },
      )
      .addMatcher(
        extendedApi.endpoints.getActivityTimeline.matchPending,
        (state) => {
          state.isLoading = true;
          state.error = { type: '', message: '' };
        },
      )
      .addMatcher(
        extendedApi.endpoints.getActivityTimeline.matchRejected,
        (state, action) => {
          state.error = {
            type: action.error.message as string,
            message: action.error.message as string,
          };
        },
      );
  },
});

export default activitySlice.reducer;
