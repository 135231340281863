import clsx from 'clsx';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { getMspId } from '../../../common/methods/get-msp-id';
import { RootState } from '../../../common/redux-store';
import { BaModal, Typography } from '../../../components';
import { MspInvitePermission, UserType } from '../../../generated-types';
import { useShareMsp } from './custom-hooks';
import type { TSelectedUser } from './share-api-hooks';

export enum Roles {
  COMMENTER = 'COMMENTER',
  OWNER = 'OWNER',
  CO_OWNER = 'CO_OWNER',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  NONE = 'NONE',
}

type PermissionProps = {
  role?: Roles;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setSelectedUser?: React.Dispatch<React.SetStateAction<TSelectedUser>>;
  userId?: string;
  userIndex?: number;
  userType?: UserType;
  permission?: MspInvitePermission;
  mspPermissions?: MspInvitePermission[];
};

type SubscriberPermissionModalProps = {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  anchorEl: HTMLElement | null;
  onChangePermission: (role: Roles, anchorEl: HTMLElement | null) => void;
  setSelectedUser?: React.Dispatch<React.SetStateAction<TSelectedUser>>;
  isSearchBar?: boolean;
  onNewUserOpen?: () => void;
  userType?: UserType | null;
  mspPermissions?: MspInvitePermission[];
};

const PERMISSION_LIST = {
  [Roles.VIEWER]: 'Viewer',
  [Roles.COMMENTER]: 'Commenter',
  [Roles.EDITOR]: 'Editor',
  [Roles.CO_OWNER]: 'Co-owner',
  [Roles.NONE]: 'Remove Access',
};

function SubscriberPermission(props: PermissionProps) {
  const {
    role = Roles.VIEWER,
    setAnchorEl,
    setSelectedUser,
    userId,
    userIndex = 0,
    userType,
    permission,
    mspPermissions,
  } = props;

  const { userId: loginUserID } = useSelector(
    (state: RootState) => state.userInfo.userDetails,
  );
  const isRoleEditable =
    loginUserID !== userId
      ? (mspPermissions?.includes(MspInvitePermission.EDIT) &&
          role !== Roles.CO_OWNER) ||
        mspPermissions?.includes(MspInvitePermission.ALL)
      : false;

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser?.({
      anchorEl: null,
      role,
      userId: userId ?? null,
      userType: userType ?? null,
    });
  };

  return (
    <>
      {role === Roles.OWNER && permission === MspInvitePermission.ALL ? (
        <div className="flex items-center justify-end px-3" key={userIndex}>
          <Typography
            variant="label-12-regular"
            className="text-right text-mid"
          >
            Owner
          </Typography>
        </div>
      ) : (
        <div
          className={`flex cursor-pointer items-center justify-end ${
            !isRoleEditable ? 'mr-2' : ''
          }`}
          key={userIndex}
          onClick={isRoleEditable ? onClick : undefined}
        >
          <Typography
            variant="label-12-medium"
            className="items-center p-1 text-right text-mid"
          >
            {PERMISSION_LIST[role]}
            {isRoleEditable && (
              <FiChevronDown className="px-0.5 text-mid" size={10} />
            )}
          </Typography>
        </div>
      )}
    </>
  );
}

function SubscriberPermissionModal(props: SubscriberPermissionModalProps) {
  const {
    anchorEl,
    setAnchorEl,
    onChangePermission,
    isSearchBar = false,
    onNewUserOpen,
    userType,
    setSelectedUser,
    mspPermissions,
  } = props;

  const { clearMouseLeaveEvent } = useShareMsp({ setAnchorEl });
  const dispatch = useDispatch();
  const mspId = getMspId(window.location.pathname);
  const onClose = () => {
    setAnchorEl(null);
    setSelectedUser?.({
      userId: null,
      userType: null,
      role: null,
      anchorEl: null,
    });
  };

  const onPermissionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    role: Roles,
  ) => {
    onChangePermission(role, event.currentTarget);
    setAnchorEl(null);
  };

  const onEditUserDetailClick = () => {
    dispatch(
      trackEvent(TRACKING_CONSTANTS.CLICKED_EDIT_USER_PROFILE, {
        mspId,
      }),
    );
    setAnchorEl(null);
    onNewUserOpen?.();
  };

  return (
    <BaModal
      placement="bottom-end"
      zIndex={50}
      onClose={onClose}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onMouseEnter={clearMouseLeaveEvent}
      borderRadiusClass="rounded-none"
      key={uuid()}
    >
      <div className="flex w-[150px] flex-col py-1">
        {Object.keys(PERMISSION_LIST)
          .filter((role) =>
            mspPermissions?.includes(MspInvitePermission.EDIT)
              ? role !== Roles.CO_OWNER
              : true,
          )
          .map((role) => (
            <>
              {!isSearchBar &&
                role === Roles.NONE &&
                userType === UserType.BUYER && (
                  <div
                    key={role}
                    className={clsx(
                      'cursor-pointer py-1.5 px-4 text-[11px] hover:bg-light-bg-10',
                      (role as Roles) === Roles.NONE
                        ? 'border-0 border-t border-solid border-gray-200 text-mid hover:text-primary'
                        : 'text-mid hover:text-primary',
                    )}
                    onClick={onEditUserDetailClick}
                  >
                    <Typography variant="label-12-medium">
                      Edit User Profile
                    </Typography>
                  </div>
                )}
              {((isSearchBar && role !== Roles.NONE) || !isSearchBar) && (
                <div
                  key={role}
                  className={clsx(
                    'cursor-pointer py-1.5 px-4 text-[11px] hover:bg-light-bg-10',
                    role === Roles.NONE
                      ? 'text-error'
                      : 'text-mid hover:text-primary',
                    userType === UserType.SELLER && role === Roles.NONE
                      ? 'border-0 border-t border-solid border-gray-200 text-mid hover:text-primary'
                      : '',
                  )}
                  onClick={(event) => onPermissionClick(event, role as Roles)}
                >
                  <Typography variant="label-12-medium">
                    {PERMISSION_LIST[role as Roles]}
                  </Typography>
                </div>
              )}
            </>
          ))}
      </div>
    </BaModal>
  );
}

export { SubscriberPermission, SubscriberPermissionModal };
