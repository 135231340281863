import { gql } from '@apollo/client';
import { UserPayloadResponse } from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

export const ADD_USER = gql`
  ${CORE_GRAPH_ERROR}
  mutation AddUser($input: CreateUserInput!) {
    userMutation {
      createUser(input: $input) {
        __typename
        ...CoreErrorFields
        ... on UserPayload {
          items {
            __typename
            ...CoreErrorFields
            ... on User {
              id
              firstName
              lastName
              email
              userType
              primaryId
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIBER = gql`
  ${CORE_GRAPH_ERROR}
  mutation ($id: ID!, $input: UpdateUserInput!) {
    userMutation {
      updateUser(id: $id, input: $input) {
        __typename
        ...CoreErrorFields
        ... on UserPayload {
          items {
            ... on User {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export type UpdateUserResult = {
  userMutation: { updateUser: UserPayloadResponse };
};

export type AddUserResult = {
  userMutation: { createUser: UserPayloadResponse };
};
