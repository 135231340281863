import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

const Grey3Typography = withStyles({
  root: {
    color: '#828282',
  },
})(Typography);

export default Grey3Typography;
