import { TOption } from '../../components/ba-select/common';
import { Company, Msp, Opportunity } from '../../generated-types';

export interface IBrandCompany {
  name: string;
  domain: string;
  logo: string;
}

export interface ICompanyResponse {
  response: IBrandCompany[];
}
export interface IMspCreationStore {
  opportunityList: undefined | Opportunity[];
  selectedOpportunity: undefined | Opportunity;
  opportunityLoading: boolean;
  opportunityError: unknown;
  templateLoading: boolean;
  templateError: unknown;
  selectedTemplate: Msp | undefined;
  templateList: Msp[] | undefined;
  companyLoading: boolean;
  companyError: unknown;
  selectedCompany: TOption | null;
  companyList: Array<TOption> | undefined;
  selectedDate: number;
  displayName?: string;
  isFromValid: boolean;
  displayNameError: string;
  selectedDateError: string;
  companyDetailsError: unknown;
  companyDetailsLoading: boolean;
  companyDetails: Company | undefined;
  companySearchedTerm: string | undefined;
  isOpportunityDisabled?: boolean;
}

export const NETWORK_ERROR = 'network error';

export const lastTemplateOption = {
  id: '234',
  name: 'Proceed without template',
};
