/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { capitalizeString } from '../../../common/utilities/utils';
import { IFilterConfig, TFilterItem } from '../../../components/filters/model';
import {
  BaseMilestone,
  BaseMsp,
  BooleanObject,
  Company, FilterItemsPayload,
  GraphqlError, Maybe, Task, TaskConnection, TaskEdge, TaskPayload, TaskStatus,
} from '../../../generated-types';
import {
  fetchMyTasksFilters, fetchMyTasksList, ResultMyTasks, ResultMyTasksFilters, TaskType, updateMyTaskStatus, updateTaskDueDate, updateTaskInCompleteStatus,
} from './api-call';

export interface GqlError extends GraphqlError{
  id: string;
}

export type ITaskResponse = GqlError | ITask;
interface IDefaultMyTasks{
    myTasks: {
      [key:string]: ITaskResponse[];
    }
    myTasksLoading: {
      [key:string]: boolean;
    }
    searchText: string;
    filterDetails: IFilterConfig;
    filterLoading: boolean;
    filterError: unknown;
}
export interface ITask extends Task {
  mspName:string;
  milestoneName: string;
  companyName: string;
  mspId: string;
  taskType: TaskType,
  count?: number,
}

const defaultMyTasks:IDefaultMyTasks = {
  myTasks: {
    overdue: [],
    dueToday: [],
    dueTomorrow: [],
    dueTenDays: [],
  },
  myTasksLoading: {
    overdue: true,
    dueToday: true,
    dueTomorrow: true,
    dueTenDays: true,
  },
  searchText: '',
  filterDetails: {} as IFilterConfig,
  filterLoading: true,
  filterError: undefined as unknown,
};

export const datakeyMap: { [key: string]: string } = {
  'msp.buyerCompanyId': 'AccountName',
};

const DEFAULT_FILTER_COLUMNS = {
  filterDetails: {} as IFilterConfig,
};

function transformFilterResponse(response: ResultMyTasksFilters) {
  try {
    const myTaskFilters = response?.taskQuery.myTaskFilters;
    if (
      myTaskFilters?.__typename === 'GraphqlError'
    ) {
      return DEFAULT_FILTER_COLUMNS;
    }
    const filterDetails = (myTaskFilters as FilterItemsPayload)?.items?.reduce((accumulator: IFilterConfig, current) => {
      const {
        displayName, key, values, isVisible,
      } = current;

      if (key && displayName && values && isVisible) {
        const val = {
          key,
          labelInfo: {
            title: displayName,
          },
          trackEventMessage: `Clicked ${displayName}`,
          list: values?.reduce((acc, cur) => {
            const { id, displayName } = cur;
            if (id && displayName) {
              acc.push({
                value: id,
                label: displayName,
                isSelected: false,
              });
            }

            return acc as TFilterItem[];
          }, [] as TFilterItem[]),
        };
        accumulator[displayName] = val;
      }
      return accumulator;
    }, {} as IFilterConfig) ?? {};

    return { filterDetails };
  } catch (err) {
    return DEFAULT_FILTER_COLUMNS;
  }
}

function transformMyTasksResponse(response: ResultMyTasks, taskType:TaskType) {
  const myTasksResultList = response?.taskQuery?.myTasks;
  if (myTasksResultList?.__typename === 'GraphqlError') {
    return [];
  }
  const edges = ((myTasksResultList as TaskConnection)?.edges) as TaskEdge[];

  const tasks = [] as Array<ITaskResponse>;
  for (let i = 0; i < edges?.length; i += 1) {
    const node = edges[i]?.node;

    if (node?.__typename === 'GraphqlError'
    || (node as Task)?.milestone?.__typename === 'GraphqlError'
    || (node as Task)?.msp?.__typename === 'GraphqlError') {
      const id = uuid();
      const graphQLError = (node as GraphqlError) || ((node as Task)?.milestone as GraphqlError)
      || ((node as Task)?.msp as GraphqlError);
      tasks.push({ id, ...graphQLError });
    } else {
      const { name: milestoneName, id: milestoneId } = ((node as Task)?.milestone as BaseMilestone);
      const { name: mspName, buyerCompany: Company, id: mspId } = ((node as Task)?.msp as BaseMsp);

      tasks.push({
        ...node, milestoneId, milestoneName, mspName, companyName: capitalizeString((Company as Company)?.displayName), mspId, taskType,
      } as ITask);
    }
  }

  return tasks;
}

const myTasksSlice = createSlice({
  name: 'milestone-template',
  initialState: {
    ...defaultMyTasks,
    loading: false,
    error: undefined as unknown,
  },
  reducers: {
    setSearchText: (state, action: PayloadAction<Maybe<string>>) => {
      state.searchText = action.payload ?? '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTasksList.fulfilled, (state, { payload }) => {
        const { data, taskType } = payload;
        const tasks = transformMyTasksResponse(data, taskType);
        state.myTasks[taskType] = tasks;
        state.myTasksLoading[taskType] = false;
      })
      .addCase(fetchMyTasksList.pending, (state, action) => {
        const { meta } = action;
        state.myTasksLoading[meta.arg.taskType] = true;
      })
      .addCase(fetchMyTasksList.rejected, (state, { payload, meta }) => {
        state.error = payload;
        state.myTasksLoading[meta.arg.taskType] = false;
      })
      .addCase(fetchMyTasksFilters.fulfilled, (state, { payload }) => {
        const { filterDetails } = transformFilterResponse(payload);
        state.filterDetails = filterDetails;
        state.filterLoading = false;
      })
      .addCase(fetchMyTasksFilters.pending, (state) => {
        state.filterLoading = true;
      })
      .addCase(fetchMyTasksFilters.rejected, (state, { payload }) => {
        state.filterError = payload;
        state.filterLoading = false;
      })
      .addCase(updateTaskDueDate.fulfilled, (state, { payload }) => {
        const { taskType, data } = payload;

        const resultTask = ((data?.taskMutation?.updateTask as TaskPayload)?.items?.[0] as Task);
        if (resultTask) {
          const tasks = state.myTasks[taskType];
          const index = tasks.findIndex((task) => (task as Task)?.id === resultTask?.id);
          if (index > -1) {
            const taskObj = tasks[index] as Task;
            taskObj.dueDate = resultTask?.dueDate;
          }
        }
        state.loading = false;
      })
      .addCase(updateTaskDueDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTaskDueDate.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateMyTaskStatus.fulfilled, (state, { payload }) => {
        const { taskType, data, newRequestedTaskStatus } = payload;
        const tasks = state.myTasks[taskType];
        const index = tasks.findIndex((task) => (task as Task)?.id === (data?.taskMutation?.changeTaskStatus as BooleanObject)?.id);
        if (index > -1) {
          const taskObj = tasks[index] as Task;
          taskObj.status = newRequestedTaskStatus as TaskStatus;
        }

        state.loading = false;
      })
      .addCase(updateMyTaskStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMyTaskStatus.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      });
    builder.addCase(updateTaskInCompleteStatus.fulfilled, (state, { payload }) => {
      const { taskType, data } = payload;
      const tasks = state.myTasks[taskType];
      // TODO:optimization with enitity framework
      const index = tasks.findIndex((task) => (task as Task)?.id === (data?.taskMutation?.markTasksPending as BooleanObject)?.id);
      if (index > -1) {
        const taskObj = tasks[index] as Task;
        taskObj.status = TaskStatus.PENDING;
      }
      state.loading = false;
    }).addCase(updateTaskInCompleteStatus.pending, (state) => {
      state.loading = true;
    }).addCase(updateTaskInCompleteStatus.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { setSearchText } = myTasksSlice.actions;
export default myTasksSlice.reducer;
