/* eslint-disable @typescript-eslint/unbound-method */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  IEntityCompany,
  IEntityPlaceholder,
  IEntityUser,
} from './entity-types';

export const usersAdapter = createEntityAdapter<IEntityUser>({
  //   sortComparer: (a, b) =>
  //     (a?.firstName ?? '').localeCompare(b?.firstName ?? ''),
});

export const companyAdapter = createEntityAdapter<IEntityCompany>({});
export const placeholderAdapter = createEntityAdapter<IEntityPlaceholder>({
  selectId: (placeholder) => placeholder?.placeholderId ?? '  ',
});

const entitySlice = createSlice({
  name: 'entity-store',
  initialState: {
    users: usersAdapter.getInitialState({}),
    company: companyAdapter.getInitialState({}),
    placeholder: placeholderAdapter.getInitialState({}),
  },
  reducers: {
    addSingleUser: (state, action: PayloadAction<IEntityUser>) => {
      usersAdapter.addOne(state.users, action.payload);
    },
    setAllUsers(state, action: PayloadAction<IEntityUser[]>) {
      usersAdapter.setAll(state.users, action.payload);
    },
    updatedUser: (
      state,
      action: PayloadAction<Record<string, IEntityUser>>,
    ) => {
      //   usersAdapter.updateOne(state.users, action.payload);
    },
    setUserAndCompany: (
      state,
      action: PayloadAction<{
        user: Record<string, IEntityUser>;
        company: Record<string, IEntityCompany>;
      }>,
    ) => {
      const { user, company } = action.payload;
      usersAdapter.upsertMany(state.users, user);
      companyAdapter.upsertMany(state.company, company);
    },
    setUsers: (state, action: PayloadAction<Record<string, IEntityUser>>) => {
      usersAdapter.upsertMany(state.users, action.payload);
    },
    setPlaceholders: (
      state,
      action: PayloadAction<{
        placeholder: Record<string, IEntityPlaceholder>;
      }>,
    ) => {
      const { placeholder } = action.payload;
      placeholderAdapter.upsertMany(state.placeholder, placeholder);
    },
  },
});

export const {
  updatedUser,
  setAllUsers,
  addSingleUser,
  setUserAndCompany,
  setUsers,
  setPlaceholders,
} = entitySlice.actions;

export default entitySlice.reducer;
