import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UppyFile } from '@uppy/core';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { getMspId } from '../../../common/methods/get-msp-id';
import { IUserInfo } from '../../../common/redux-store/reducers/models';
import {
  IFilterConfig,
  IFilterDetails,
  TFilterItem,
} from '../../../components/filters/model';
import {
  FilterItems,
  FilterItemsPayload,
  GraphqlError,
  Media,
  MediaAnalytics,
  MediaBelongsTo,
  MediaConnection,
  MediaEdge,
  MediaPayloadItem,
  ObjectType,
  User,
  UserSource,
} from '../../../generated-types';
import {
  fetchResourceFilters,
  fetchResourceList,
  ResultResourceFilters,
  ResultResourceList,
} from './api';
import {
  EDialogActionType,
  IResource,
  IResourceSlice,
  IResourceStore,
  TResource,
} from './models';

const defaultResource = [] as TResource[];

function reduceResourceArr(resourceArr: MediaEdge[], loggedInUserId: string) {
  const resourceList = [] as Array<TResource>;

  for (let i = 0; i < resourceArr?.length; i += 1) {
    let resource = {} as TResource;
    const node = resourceArr[i]?.node;
    const item = (node as MediaPayloadItem)?.item;

    if (node?.__typename === 'GraphqlError' || !item || !node) {
      const id = `error${uuid()}`;
      resource = {
        id,
        ...node,
        fileName: (node as GraphqlError)?.message || 'Something went wrong',
      };
      resourceList.push(resource);
    } else {
      const {
        createdBy,
        context = {},
        name,
        link,
      } = item as Media & { context: Record<string, unknown>; link: string };

      const { firstName, lastName } = createdBy as User;
      const fullName = `${firstName ?? ''} ${lastName ?? ''}`;
      const fileName = name || link;
      resource = {
        ...item,
        ...context,
        uploadedBy: fullName,
        deletePermission: (item?.createdBy as User)?.id === loggedInUserId,
        tableKey: `${item?.id}${i}`,
        fileName,
      };

      resourceList.push(resource);
    }
  }

  return { error: null as unknown as GraphqlError, resourceList };
}

function transformResourceResponse({
  response,
  loggedInUserId,
}: {
  response: ResultResourceList;
  loggedInUserId: string;
}) {
  try {
    const listMedia = response?.mediaQuery?.listMedia;

    if (listMedia?.__typename === 'GraphqlError') {
      return { error: listMedia, resourceList: defaultResource };
    }
    const resourceArr = (listMedia as MediaConnection)?.edges;
    if (!resourceArr) {
      return {
        error: null as unknown as GraphqlError,
        resourceList: defaultResource,
      };
    }

    return reduceResourceArr(resourceArr, loggedInUserId);
  } catch (err: unknown) {
    return {
      error: err as GraphqlError,
      resourceList: defaultResource,
    };
  }
}

const DEFAULT_FILTERS = {} as IFilterConfig;
function transformFilterResponse(response: ResultResourceFilters) {
  try {
    const mediaFilters = response?.mediaQuery?.mediaFilters;

    if (mediaFilters?.__typename === 'GraphqlError') {
      return DEFAULT_FILTERS;
    }

    const filterDetails =
      (mediaFilters as FilterItemsPayload)?.items?.reduce(
        (accumulator: IFilterConfig, current: FilterItems) => {
          const { displayName, key, values, isVisible } = current;

          if (key && displayName && values && isVisible) {
            const val = {
              key,
              labelInfo: {
                title: displayName,
              },
              trackEventMessage: `Clicked ${displayName}`,
              list: values?.reduce((acc, cur) => {
                const { id, displayName } = cur as {
                  id: string;
                  displayName: string;
                };
                let label = displayName;
                if (key === 'createdById') {
                  const { firstName, lastName } = cur as {
                    firstName: string;
                    lastName: string;
                  };
                  label = `${firstName ?? ''} ${lastName ?? ''}`;
                }
                if (id && label) {
                  (acc as TFilterItem[]).push({
                    value: id,
                    label,
                    isSelected: false,
                  });
                }
                return acc as TFilterItem[];
              }, [] as TFilterItem[]) as TFilterItem[],
            };
            accumulator[displayName] = val;
          }
          return accumulator;
        },
        {} as IFilterConfig,
      ) ?? {};

    return filterDetails;
  } catch {
    return DEFAULT_FILTERS;
  }
}
// <T extends never>
function crossResourceUpdate<T>({
  state,
  updateField,
  newValue,
  parentId,
  mediaId,
  type,
}: {
  state: IResourceStore;
  newValue: T;
  updateField: keyof IResource;
  parentId: string;
  mediaId: string;
  type: ObjectType;
}) {
  const mspId = getMspId(window.location.pathname);
  const index = state[parentId]?.resourceList.findIndex(
    ({ id }) => id === mediaId,
  );
  const { id: belongsToId } =
    ((
      (state[parentId].resourceList[index] as IResource)
        .analytics as MediaAnalytics
    )?.belongsTo as MediaBelongsTo) || {};

  if (index > -1) {
    const tr = state[parentId].resourceList[index] as IResource;
    tr[updateField] = newValue as never;
  }

  if (type !== ObjectType.MSP && state[mspId]) {
    const indexInResourceTab = state[mspId].resourceList.findIndex(
      ({ id }) => id === mediaId,
    );
    if (indexInResourceTab > -1) {
      (state[mspId].resourceList[indexInResourceTab] as IResource)[
        updateField
      ] = newValue as never;
    }
  } else if (
    index > -1 &&
    state[belongsToId]?.resourceList &&
    belongsToId !== parentId
  ) {
    const indexLocal = state[belongsToId]?.resourceList.findIndex(
      ({ id }) => id === mediaId,
    );
    if (indexLocal > -1) {
      (state[belongsToId].resourceList[indexLocal] as IResource)[updateField] =
        newValue as never;
    }
  }
}

const ResourceSlice = createSlice({
  name: 'resources',
  initialState: {} as IResourceStore,
  reducers: {
    setResourceSearch: (
      state,
      action: PayloadAction<{ searchTerm: string; parentId: string }>,
    ) => {
      const { parentId, searchTerm } = action.payload;
      if (state[parentId]) {
        state[parentId].searchTerm = searchTerm ?? '';
      }
    },
    resetResourceList: (state, action: PayloadAction<string>) => {
      const parentId = action.payload;
      if (state[parentId]) {
        state[parentId].resourceList = [];
        state[parentId].loading = true;
      }
    },
    setResourceList: (
      state,
      action: PayloadAction<{ list: TResource[]; parentId: string }>,
    ) => {
      const { parentId, list } = action.payload;
      if (state[parentId]) {
        state[parentId].resourceList = list;
      } else {
        state[parentId] = { resourceList: list } as IResourceSlice;
      }
    },
    setBulkResourceList: (
      state,
      action: PayloadAction<{ list: TResource[]; parentIds: string[] }>,
    ) => {
      const { parentIds, list } = action.payload;
      parentIds?.forEach((parentId) => {
        const resourceList = list.filter((media) => {
          const belongsToId = (
            ((media as IResource)?.analytics as MediaAnalytics)
              ?.belongsTo as MediaBelongsTo
          )?.id;
          if (belongsToId === parentId) {
            return true;
          }
          return false;
        });
        if (resourceList) {
          state[parentId] = { resourceList } as IResourceSlice;
        }
      });
    },
    setCommentResourceList: (
      state,
      action: PayloadAction<{
        list: TResource[];
        parentId: string;
        tempId: string;
      }>,
    ) => {
      const { list, parentId, tempId } = action.payload;
      delete state[tempId];
      state[parentId] = { resourceList: list } as IResourceSlice;
    },
    toggleMarkInternal: (
      state,
      action: PayloadAction<{
        mediaId: string;
        parentId: string;
        type: ObjectType;
        newValue: boolean;
      }>,
    ) => {
      const { mediaId = '', parentId, type, newValue } = action.payload;
      crossResourceUpdate({
        state,
        type,
        parentId,
        mediaId,
        updateField: 'isInternal',
        newValue,
      });
    },
    openPreviewer: (
      state,
      action: PayloadAction<{ mediaId: string; parentId: string }>,
    ) => {
      const { mediaId, parentId } = action.payload ?? '';
      const currentDocumentNo = state[parentId].resourceList.findIndex(
        (resource) => resource.id === mediaId,
      );
      state[parentId].selectedResourceIndex = currentDocumentNo;
      state[parentId].isPreviewerOpen = true;
    },
    closePreviewer: (state, action: PayloadAction<string>) => {
      state[action.payload].isPreviewerOpen = false;
    },
    setSelectedResourceIndex: (
      state,
      action: PayloadAction<{ index: number; parentId: string }>,
    ) => {
      const { index, parentId } = action.payload ?? '';
      state[parentId].selectedResourceIndex = index;
    },
    addNewResources: (
      state,
      action: PayloadAction<{
        list: TResource[];
        parentId: string;
        mspId: string;
        type: ObjectType;
      }>,
    ) => {
      const { parentId, list, mspId, type } = action.payload;
      state[parentId].resourceList.unshift(...list);
      list?.forEach((resource) => {
        const { mediaType } = resource as IResource;
        if (mediaType === 'LINK') {
          if (type !== ObjectType.MSP && state[mspId]) {
            state[mspId].resourceList.unshift(resource);
          }
        }
      });
    },
    addInProgressResource: (
      state,
      action: PayloadAction<{
        file: TResource;
        user: IUserInfo;
        parentId: string;
      }>,
    ) => {
      const {
        file: resource = {} as IResource,
        user,
        parentId,
      } = action.payload || {};
      const { name, email, userType } = user || ({} as IUserInfo);
      const [firstName, lastName] = name?.split(' ');
      const createdBy = {
        firstName,
        lastName,
        email,
        userType,
        source: UserSource.KEYCLOAK,
      };
      const file = {
        ...resource,
        fileName: (resource as IResource)?.name,
        inProgress: true,
        isLoading: true,
        deletePermission: true,
        tableKey: resource?.id,
        createdBy,
        uploadedBy: name,
        createdAt: dayjs().unix() * 1000,
      };

      if (parentId?.includes('ms')) {
        state[parentId].resourceList.push(file);
      } else {
        state[parentId]?.resourceList?.unshift(file);
      }
    },
    updateResource: (
      state,
      action: PayloadAction<{
        list: IResource[];
        parentId: string;
        mspId: string;
        type: ObjectType;
      }>,
    ) => {
      const { parentId, list, type, mspId } = action.payload;
      list?.forEach((resource) => {
        const { fileName } = resource;
        const fileIndex = state[parentId].resourceList?.findIndex((resource) =>
          fileName?.includes(
            (resource as IResource)?.name?.substring(0, 40) ||
              String(Math.random()),
          ),
        );
        if (fileIndex > -1) {
          state[parentId].resourceList[fileIndex] = resource;
        }
        if (
          type !== ObjectType.MSP &&
          parentId !== 'new-milestone' &&
          state[mspId]
        ) {
          state[mspId].resourceList.unshift(resource);
        }
      });
    },
    updateErrorResource: (
      state,
      action: PayloadAction<{
        file: UppyFile & { error: unknown };
        parentId: string;
      }>,
    ) => {
      const { parentId, file } = action.payload;
      const { id: mediaId, error } = file as UppyFile & { error: string };
      if (error) {
        const fileIndex = state[parentId]?.resourceList?.findIndex(
          ({ id: _id }) => mediaId === _id,
        );
        if (fileIndex > -1) {
          const oldRecord = state[parentId].resourceList[fileIndex];
          state[parentId].resourceList[fileIndex] = {
            ...oldRecord,
            error,
            inProgress: false,
            isLoading: true,
          };
        }
      }
    },
    deleteResourceStore: (
      state,
      action: PayloadAction<{
        mediaId: string;
        parentId: string;
        type: ObjectType;
      }>,
    ) => {
      const { mediaId = '', parentId, type } = action.payload;
      const mspId = getMspId(window.location.pathname);
      const fileIndex = state[parentId]?.resourceList?.findIndex(
        ({ id }) => id === mediaId,
      );
      const { id: belongsToId } =
        ((
          (state[parentId].resourceList[fileIndex] as IResource)
            .analytics as MediaAnalytics
        )?.belongsTo as MediaBelongsTo) || {};

      if (fileIndex > -1) {
        state[parentId]?.resourceList?.splice(fileIndex, 1);
      }

      if (type !== ObjectType.MSP && state[mspId]) {
        const indexInResourceTab = state[mspId].resourceList.findIndex(
          ({ id }) => id === mediaId,
        );
        if (indexInResourceTab > -1) {
          state[mspId]?.resourceList?.splice(indexInResourceTab, 1);
        }
      } else if (
        fileIndex > -1 &&
        state[belongsToId] &&
        belongsToId !== parentId
      ) {
        const indexLocal = state[belongsToId].resourceList.findIndex(
          ({ id }) => id === mediaId,
        );
        if (indexLocal > -1) {
          state[belongsToId]?.resourceList?.splice(indexLocal, 1);
        }
      }
    },
    toggleFavorite: (
      state,
      action: PayloadAction<{
        mediaId: string;
        parentId: string;
        type: ObjectType;
        newValue: boolean;
      }>,
    ) => {
      const { mediaId = '', parentId, type, newValue } = action.payload;
      crossResourceUpdate({
        state,
        type,
        parentId,
        mediaId,
        updateField: 'isFavorite',
        newValue,
      });
    },
    togglePinTop: (
      state,
      action: PayloadAction<{ mediaId: string; parentId: string }>,
    ) => {
      const { mediaId = '', parentId } = action.payload;
      const index = state[parentId]?.resourceList.findIndex(
        ({ id }) => id === mediaId,
      );
      if (index > -1) {
        (state[parentId].resourceList[index] as IResource).isPin = !(
          state[parentId].resourceList[index] as IResource
        ).isPin;
      }
    },
    setFilterDetails: (
      state,
      action: PayloadAction<{
        searchTerm: string | undefined;
        selectedFilters: IFilterDetails;
        parentId: string;
      }>,
    ) => {
      const { searchTerm, parentId, selectedFilters } = action.payload;
      state[parentId].prevSearchTerm = searchTerm;
      state[parentId].prevSelectedFilters = selectedFilters;
    },
    toggleResourceActionDialog: (
      state,
      action: PayloadAction<{
        parentId: string;
        mediaId?: string;
        actionType?: EDialogActionType;
      }>,
    ) => {
      const { mediaId, parentId, actionType } = action.payload;
      state[parentId].isRenameDialogOpen = !state[parentId].isRenameDialogOpen;
      if (mediaId) state[parentId].selectedMediaIdForAction = mediaId;
      if (actionType) state[parentId].actionType = actionType;
    },
    renameFileName: (
      state,
      action: PayloadAction<{
        parentId: string;
        type: ObjectType;
        mediaId: string;
        newName: string;
      }>,
    ) => {
      const { mediaId, parentId, newName, type } = action.payload;

      crossResourceUpdate({
        state,
        type,
        parentId,
        mediaId,
        updateField: 'fileName',
        newValue: newName,
      });
    },
    updateSharedUrl: (
      state,
      action: PayloadAction<{
        parentId: string;
        type: ObjectType;
        mediaId: string;
        url: string | null;
      }>,
    ) => {
      const { mediaId, parentId, url, type } = action.payload;

      crossResourceUpdate({
        state,
        type,
        parentId,
        mediaId,
        updateField: 'fileShareUrl',
        newValue: url,
      });
    },
    setExecutiveSummaryResource: (
      state,
      action: PayloadAction<{
        parentId: string;
        resourceArr: MediaEdge[];
        loggedInUserId: string;
      }>,
    ) => {
      const { resourceArr, parentId, loggedInUserId } = action.payload;
      try {
        if (!state[parentId]) {
          const { error, resourceList } = reduceResourceArr(
            resourceArr,
            loggedInUserId,
          );
          state[parentId] = {} as IResourceSlice;
          state[parentId].resourceList = resourceList;
          state[parentId].error = error;
        }
      } catch (err) {
        state[parentId].error = (err as Error)?.message;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourceList.fulfilled, (state, { payload }) => {
        const { parentId } = payload;
        const { error, resourceList } = transformResourceResponse(payload);
        state[parentId].resourceList = resourceList;
        state[parentId].error = error;
        state[parentId].loading = false;
      })
      .addCase(fetchResourceList.pending, (state, payload) => {
        const parentId = payload?.meta?.arg?.parentId;
        if (!state[parentId]) {
          state[parentId] = {} as IResourceSlice;
        }
        state[parentId].loading = true;
      })
      .addCase(fetchResourceList.rejected, (state, { payload }) => {
        const { parentId, message } = payload as {
          parentId: string;
          message: string;
        };
        state[parentId].error = message;
        state[parentId].loading = false;
      })
      .addCase(fetchResourceFilters.fulfilled, (state, { payload }) => {
        const { parentId, filters } = payload;
        const filterDetails = transformFilterResponse(filters);
        state[parentId].filterDetails = filterDetails;
        state[parentId].filterLoading = false;
      })
      .addCase(fetchResourceFilters.pending, (state, payload) => {
        const parentId = payload?.meta?.arg?.parentId;
        if (!state[parentId]) {
          state[parentId] = {} as IResourceSlice;
        }
        state[parentId].filterLoading = true;
      })
      .addCase(fetchResourceFilters.rejected, (state, { payload }) => {
        const { parentId, message } = payload as {
          parentId: string;
          message: string;
        };
        // if (!state[parentId]) { state[parentId] = {} as IResourceSlice; }
        state[parentId].filterError = message;
        state[parentId].filterLoading = false;
      });
  },
});

export const {
  setResourceSearch,
  resetResourceList,
  toggleMarkInternal,
  openPreviewer,
  closePreviewer,
  setSelectedResourceIndex,
  addNewResources,
  deleteResourceStore,
  toggleFavorite,
  updateResource,
  addInProgressResource,
  updateErrorResource,
  togglePinTop,
  setResourceList,
  setFilterDetails,
  toggleResourceActionDialog,
  renameFileName,
  updateSharedUrl,
  setExecutiveSummaryResource,
  setBulkResourceList,
  setCommentResourceList,
} = ResourceSlice.actions;

export default ResourceSlice.reducer;
