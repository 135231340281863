import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grey3Typography from '../typographies/grey3';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    message: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
    },
  }));

const messageList: string[] = [];

function CircularLoader({
  isMessageEnabled = false,
  size = 50,
  padding = 17,
}: {
  isMessageEnabled?: boolean;
  size?: number;
  padding?:number
}) {
  const classes = useStyles();

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      py={padding || 17}
    >
      <CircularProgress size={size} className={classes.root} />
      {isMessageEnabled && messageList.length > 0 && (
        <Box
          component="div"
          textAlign="center"
          mt={6}
          className={classes.message}
        >
          <Grey3Typography variant="subtitle2">
            {Math.floor(Math.random() * messageList.length)}
          </Grey3Typography>
        </Box>
      )}
    </Box>
  );
}

export default CircularLoader;
