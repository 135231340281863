import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../common/redux-store';
import { FilterComponent } from '../../components';
import FilterChips from '../../components/filter-chips';
import { setDashboardSearch } from './dashboard-redux-store';
import MspSearch from './msp-search';
import TableHeadCommonLayout from './table-head-layout';

const mapState = (state: RootState) => ({
  filterDetails: state?.dashboard?.filterDetails,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDashboardSearch,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function DashboardHeader(props: PropsFromRedux) {
  const { filterDetails, setDashboardSearch } = props;

  return (
    <TableHeadCommonLayout className="dashboard-search-filter-section">
      <>
        <div className="flex items-center justify-between">
          <MspSearch />
          <FilterComponent
            sectionName="dashboard"
            filterConfig={filterDetails}
            isResetButtonEnabled={false}
          />
        </div>
        <FilterChips
          sectionName="dashboard"
          onClearAll={() => setDashboardSearch('')}
        />
      </>
    </TableHeadCommonLayout>
  );
}

export default connector(DashboardHeader);
