import { Company } from './../../api/index';
/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import type { ColumnShape } from 'react-base-table';
import { v4 as uuid } from 'uuid';
import type { PayloadAction } from '@reduxjs/toolkit';
import { capitalizeString } from '../../common/utilities/utils';
import { IFilterConfig, TFilterItem } from '../../components/filters/model';
import {
  Alignx,
  DashboardColumn,
  DashboardColumnPayload,
  FilterItemsPayload,
  GraphqlError,
  Logo,
  Maybe,
  Msp,
  MspAnalytics,
  MspConnection,
  MspTrackStatus,
  Opportunity,
  User,
} from '../../generated-types';
import {
  fetchMspFilters,
  fetchMspList,
  mspPagination,
  ResultMsp,
} from './api-call';
import {
  ArchivalReasonListType,
  IDashboardState,
  IMspState,
  TMsp,
} from './models';

const defaultMsp = { pageInfo: null, mspList: [] as TMsp[] };

export function transformMspResponse(response: ResultMsp) {
  try {
    const dashboard = response?.alignxQuery?.dashboard;
    if (dashboard?.__typename === 'GraphqlError') {
      return defaultMsp;
    }
    const msps = (dashboard as Alignx)?.msps;
    if (msps?.__typename === 'GraphqlError') {
      return defaultMsp;
    }
    const edges = (msps as MspConnection)?.edges;
    const pageInfo = (msps as MspConnection)?.pageInfo;
    if (!edges) return defaultMsp;

    const mspList = [] as Array<TMsp>;
    let msp = {} as TMsp;

    for (let i = 0; i < edges?.length; i += 1) {
      msp = {} as TMsp;
      const node = edges[i]?.node;

      if (node?.__typename === 'GraphqlError' || !node) {
        const id = `error${uuid()}`;
        msp = {
          id,
          ...node,
          name: (node as GraphqlError)?.message || 'Something went wrong',
        };
        mspList.push(msp);
      } else {
        const {
          id,
          analytics,
          opportunity,
          buyerCompany,
          createdBy,
          sharedBy,
          ...rest
        } = node as Msp;

        msp = { ...rest, id, tableKey: `${id}${i}` };
        if (analytics && analytics?.__typename !== 'GraphqlError') {
          const {
            __typename: _,
            track,
            lastActivity,
            lastExternalActivity,
            ...otherAnalytics
          } = analytics as MspAnalytics;
          msp = track
            ? { ...msp, ...track, ...otherAnalytics }
            : { ...msp, ...otherAnalytics };
          msp =
            lastActivity && lastExternalActivity
              ? {
                  ...msp,
                  ...{ lastActivityAt: lastActivity.activityAt },
                  ...{
                    lastExternalActivityAt: lastExternalActivity.activityAt,
                  },
                }
              : { ...msp };
        }

        if (createdBy?.__typename !== 'GraphqlError') {
          const { firstName, lastName } = createdBy as User;
          msp = {
            ...msp,
            ownerName: `${capitalizeString(firstName ?? '')} ${capitalizeString(
              lastName ?? '',
            )}`,
          };
        }

        if (sharedBy?.__typename !== 'GraphqlError') {
          const { firstName, lastName } = createdBy as User;
          msp = {
            ...msp,
            sharedByUserName: `${capitalizeString(
              firstName ?? '',
            )} ${capitalizeString(lastName ?? '')}`,
          };
        }

        if (opportunity?.__typename !== 'GraphqlError' && opportunity) {
          msp = {
            ...msp,
            opportunityId: (opportunity as Opportunity).id,
            opportunityStage: (opportunity as Opportunity).stageName,
            OpportunityCloseDate: (opportunity as Opportunity).closeDate,
          };
        }

        if (buyerCompany?.__typename !== 'GraphqlError' && buyerCompany) {
          msp = {
            ...msp,
            buyerLogo: ((buyerCompany as Company)?.logo as Logo)?.url,
          };
        }

        mspList.push(msp);
      }
    }

    return { mspList, pageInfo };
  } catch (err: unknown) {
    return {
      mspList: [
        { id: `error${uuid()}`, name: 'Something went wrong' },
      ] as TMsp[],
      pageInfo: null,
    };
  }
}
export const datakeyMap: { [key: string]: string } = {
  'msp.name': 'name',
  'msp.stage': 'mspStage',
  'msp.status': 'mspTrackStatus',
  'mspAnalytics.mspProgress': 'mspProgress',
  'mspAnalytics.mspSlip': 'mspSlip',
  'opportunity.closeDate': 'OpportunityCloseDate',
  'opportunity.stage': 'opportunityStage',
  'mspAnalytics.lastExternalActivityAt': 'lastExternalActivityAt',
  'mspAnalytics.lastActivityAt': 'lastActivityAt',
};

function getWidth(key: string) {
  switch (key) {
    case 'msp.name':
      return '25%' as unknown as number;

    default:
      return 100;
  }
}

const DEFAULT_FILTER_COLUMNS = {
  filterDetails: {} as IFilterConfig,
  dashboardColumns: [] as ColumnShape[],
};

function transformFilterResponse(response: ResultMsp) {
  try {
    const dashboard = response?.alignxQuery?.dashboard;
    if (
      dashboard?.__typename === 'GraphqlError' ||
      (dashboard as Alignx)?.dashboardFiltersV1?.__typename === 'GraphqlError'
    ) {
      return DEFAULT_FILTER_COLUMNS;
    }
    const filterDetails =
      (
        (dashboard as Alignx)?.dashboardFiltersV1 as FilterItemsPayload
      )?.items?.reduce((accumulator: IFilterConfig, current) => {
        const { displayName, key, values, isVisible } = current;

        if (key && displayName && values && isVisible) {
          const filterArr = values as unknown as {
            id: string;
            displayName: string;
          }[];
          const filterList = filterArr?.reduce((acc, cur) => {
            const { id, displayName } = cur;
            if (id && displayName && id !== MspTrackStatus?.UNKNOWN) {
              const filterItem = {
                value: id,
                label: displayName,
                isSelected: false,
                listKey: key === 'mspAnalytics.mspStatus' ? id : '',
              };
              acc.push(filterItem);
            }
            return acc;
          }, [] as TFilterItem[]);
          const val = {
            key,
            labelInfo: {
              title: displayName,
            },
            trackEventMessage: `Clicked ${displayName}`,
            list: filterList,
          };
          accumulator[displayName] = val;
        }
        return accumulator;
      }, {} as IFilterConfig) ?? {};

    const dashboardColumns =
      (
        (dashboard as Alignx)?.dashboardColumns as DashboardColumnPayload
      )?.items?.reduce((accumulator: ColumnShape[], current) => {
        const { displayName, key, isVisible, isSortable } =
          current as DashboardColumn;

        if (key && isVisible) {
          const val = {
            dataKey: datakeyMap[key],
            key: datakeyMap[key],
            id: key,
            title: displayName,
            flexGrow: 1,
            sortable: isSortable,
            width: getWidth(key),
          };
          accumulator.push(val);
        }
        return accumulator;
      }, []) ?? [];

    return { filterDetails, dashboardColumns };
  } catch (err) {
    return DEFAULT_FILTER_COLUMNS;
  }
}

const dashboardSlice = createSlice({
  name: 'milestone-template',
  initialState: {
    mspList: defaultMsp.mspList,
    pageInfo: defaultMsp.pageInfo,
    loading: true,
    error: undefined as unknown,
    filterDetails: {} as IFilterConfig,
    filterLoading: true,
    filterError: undefined as unknown,
    dashboardColumns: [] as ColumnShape[],
    search: '',
    isNextPageLoading: false,
    archivalReasonList: [] as ArchivalReasonListType[],
  } as IDashboardState,
  reducers: {
    setDashboardSearch: (state, action: PayloadAction<Maybe<string>>) => {
      state.search = action.payload ?? '';
    },
    resetMspList: (state) => {
      state.mspList = [];
      state.loading = true;
    },
    setArchivalReasonList: (
      state,
      action: PayloadAction<ArchivalReasonListType[]>,
    ) => {
      state.archivalReasonList = action.payload ?? [];
    },
    setMspStage: (
      state,
      action: PayloadAction<{ mspId: string; mspStage: string }>,
    ) => {
      const { mspId, mspStage } = action.payload;
      const arr = state.mspList as IMspState[];
      const msp = arr?.find((msp) => msp?.id === mspId);
      if (msp) msp.mspStage = mspStage;
    },
    setOpportunitySearch: (state, action: PayloadAction<Maybe<string>>) => {
      state.opportunitySearch = action.payload ?? '';
    },
    setAccountSearch: (state, action: PayloadAction<Maybe<string>>) => {
      state.accountSearch = action.payload ?? '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMspList.fulfilled, (state, { payload }) => {
        const { mspList, pageInfo } = transformMspResponse(payload);
        state.mspList = mspList;
        state.pageInfo = pageInfo;
        state.loading = false;
      })
      .addCase(fetchMspList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMspList.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(fetchMspFilters.fulfilled, (state, { payload }) => {
        const { filterDetails, dashboardColumns } =
          transformFilterResponse(payload);
        state.filterDetails = filterDetails;
        state.dashboardColumns = dashboardColumns;
        state.filterLoading = false;
      })
      .addCase(fetchMspFilters.pending, (state) => {
        state.filterLoading = true;
      })
      .addCase(fetchMspFilters.rejected, (state, { payload }) => {
        state.filterError = payload;
        state.filterLoading = false;
      })
      .addCase(mspPagination.fulfilled, (state, { payload }) => {
        const { mspList, pageInfo } = transformMspResponse(payload);
        state.mspList = state.mspList.concat(mspList);
        state.pageInfo = pageInfo;
        state.isNextPageLoading = false;
      })
      .addCase(mspPagination.pending, (state) => {
        state.isNextPageLoading = true;
      })
      .addCase(mspPagination.rejected, (state, { payload }) => {
        state.error = payload;
        state.isNextPageLoading = false;
      });
  },
});

export const {
  setDashboardSearch,
  resetMspList,
  setMspStage,
  setArchivalReasonList,
  setOpportunitySearch,
  setAccountSearch,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
