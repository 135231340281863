import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { BiNetworkChart } from 'react-icons/bi';
import { FiBarChart, FiCalendar, FiUser, FiUsers } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { trackEvent } from '../../analytics';
import { colorPalette } from '../../common/newTheme';
import { RootState } from '../../common/redux-store';
import { boxShadow } from '../../common/theme-config';
import {
  AssigneeIcon,
  DotIcon,
  FilterIcon,
  OfficeBuildingIcon,
} from '../icons';
import { Typography } from '../typography';
import { FilterList } from './filter-list';
import {
  clearAllFilters,
  resetFilters,
  setSelectedFilters,
} from './filter-redux';
import { IFilterContainerProps, TFilterItem } from './model';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    boxShadow: boxShadow[600],
    borderRadius: '3px',
    border: `1px solid ${colorPalette.mid[30]}`,
    background: theme.palette.primary.contrastText,
  },
}));

function getIcon(id: string | undefined) {
  switch (id) {
    case 'mspAnalytics.mspStage':
      return <FiBarChart />;
    case 'opportunity.stageName':
      return <FiBarChart />;
    case 'mspAnalytics.mspStatus':
      return <DotIcon />;
    case 'opportunity.fiscal':
      return <FiCalendar />;
    case 'opportunity.forecastCategory':
      return <BiNetworkChart className="mt-1.5 rotate-45 text-lg" />;
    // case 'opportunity.forecastCateory': return ;
    case 'mspSubscriber.invitedById':
      return <FiUsers />;
    case 'msp.createdById':
    case 'createdBy':
      return <FiUser />;
    case 'msp.buyerCompanyId':
      return <OfficeBuildingIcon />;
    case 'task.assigneeId':
      return <AssigneeIcon color="bg-mid-500" />;
    case 'task.status':
      return <DotIcon color="bg-mid-500" />;

    default:
      return null;
  }
}

function getFilterDropDownIcon(id: string | undefined) {
  switch (id) {
    case 'task.assigneeId':
      return <FilterIcon fontSize="small" color="bg-mid-500" />;
    case 'msp.buyerCompanyId':
      return <FilterIcon fontSize="small" color="bg-mid-500" />;
    default:
      return <ExpandMoreIcon fontSize="small" />;
  }
}

const mapState = (state: RootState) => ({
  reduxSelectedFilters: state.filters.selectedFilters,
  isExpanded: state?.milestoneDetail?.accordionOpenState?.expandedAll,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedFilters,
      resetFilters,
      trackEvent,
      clearAllFilters,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type CombinedProps = PropsFromRedux & IFilterContainerProps;

function FilterContainer(props: CombinedProps) {
  const {
    filterConfig,
    reduxSelectedFilters,
    resetFilters,
    trackEvent,
    onApply,
    isExpanded,
    debounceTime = 0,
    sectionName,
    isResetButtonEnabled = true,
    isCustomFilterIconVisible = true,
    isFilterTitleHidden = false,
    transformOrigin,
    anchorOrigin,
    rootClasses,
    isSingleSelect = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const selectedFilters = reduxSelectedFilters[sectionName] ?? {};
  const [selectedFilterType, setSelectedFilterType] = useState<string>('');
  const classes = useStyles();
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedType: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedFilterType(selectedType ?? '');
      if (filterConfig[selectedType]?.trackEventMessage)
        trackEvent(filterConfig[selectedType]?.trackEventMessage);
    },
    [setAnchorEl],
  );

  // const onClose = useCallback(() => {
  //   setSelectedFilterType('');
  // }, []);

  let list = [] as TFilterItem[];
  if (
    selectedFilterType &&
    selectedFilters[selectedFilterType] &&
    selectedFilters[selectedFilterType].length !== 0
  )
    list = selectedFilters[selectedFilterType];
  else list = filterConfig[selectedFilterType]?.list;

  const selectedLen = Object.keys(selectedFilters).reduce(
    (acc, cur) =>
      selectedFilters[cur].filter(({ isSelected }) => isSelected).length + acc,
    0,
  );
  const isFilterSelected = !!(
    selectedFilterType &&
    selectedFilters[selectedFilterType] &&
    selectedFilters[selectedFilterType].some(
      ({ isSelected }) => isSelected === true,
    )
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedFilterType('');
  }, []);

  function getSelectedOptionTitle(
    selectedArr: TFilterItem[],
    defaultMessage: string,
    sectionName: string,
  ) {
    const selectedOptionTitle =
      sectionName === 'analytics' && selectedArr?.length
        ? selectedArr[0]?.value
        : defaultMessage;
    return selectedOptionTitle;
  }

  const dropdownRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div className="flex flex-wrap items-center justify-end space-x-2 ">
        {isResetButtonEnabled && selectedLen > 0 && (
          <Typography
            variant="body-14-regular"
            className="cursor-pointer text-error"
            onClick={() => resetFilters({ sectionName })}
          >
            Clear all
          </Typography>
        )}
        {filterConfig &&
          Object.keys(filterConfig).map((name) => {
            const { id, title: defaultMessage } = filterConfig[name]?.labelInfo;
            const { key } = filterConfig[name];
            const selectedArr = selectedFilters[name]?.filter(
              ({ isSelected }) => isSelected,
            );
            return (
              <div
                ref={dropdownRef}
                role="presentation"
                key={`${id ?? ''}${key ?? ''}`}
                className={clsx(
                  selectedArr?.length || selectedFilterType === name
                    ? 'text-primary'
                    : 'text-mid-500',
                  selectedFilterType === name && 'bg-primary-100',
                  'flex cursor-pointer items-center space-x-1 rounded-full py-1 px-2 hover:bg-primary-100 hover:text-primary',
                )}
                // color={selectedArr?.length ? 'primary' : 'default'}
                onClick={(e) => handleClick(e, name)}
              >
                {isCustomFilterIconVisible && getIcon(key)}
                {!isFilterTitleHidden && (
                  <Typography variant="body-13-regular" element="p">
                    {id ? (
                      <FormattedMessage
                        id={id}
                        defaultMessage={getSelectedOptionTitle(
                          selectedArr,
                          defaultMessage,
                          sectionName,
                        )}
                      />
                    ) : (
                      getSelectedOptionTitle(
                        selectedArr,
                        defaultMessage,
                        sectionName,
                      )
                    )}
                  </Typography>
                )}
                {getFilterDropDownIcon(key)}
              </div>
            );
          })}
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        // container={dropdownRef?.current}
        // TODO:Change all these styling using tailwind classes
        classes={{ paper: classes.paper, root: rootClasses || '' }}
        anchorOrigin={{
          vertical: anchorOrigin ? anchorOrigin?.vertical : 'bottom',
          horizontal: anchorOrigin ? anchorOrigin?.horizontal : 'right',
        }}
        transformOrigin={{
          vertical: transformOrigin ? transformOrigin?.vertical : 'top',
          horizontal: transformOrigin ? transformOrigin?.horizontal : 'right',
        }}
      >
        <FilterList
          list={list}
          isExpanded={isExpanded}
          selectedFilterType={selectedFilterType}
          onApply={onApply}
          sectionName={sectionName}
          debounceTime={debounceTime}
          isFilterSelected={isFilterSelected}
          isSingleSelect={isSingleSelect}
          onClose={handleClose}
        />
      </Popover>
    </>
  );
}

export default connector(FilterContainer);
