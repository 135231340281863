import { gql, useMutation } from '@apollo/client';
import {
  BooleanResponse,
  TaskMutationRemoveAssigneeArgs,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

type RemoveAssigneeInput = {
  variables: TaskMutationRemoveAssigneeArgs;
};

export const REMOVE_ASSIGNEE = gql`
  ${CORE_GRAPH_ERROR}
  mutation RemoveAssignee($assigneeId: ID!, $taskIds: [ID!]!) {
    taskMutation {
      removeAssignee(assigneeId: $assigneeId, taskIds: $taskIds) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export type RemoveAssignResult = {
  taskMutation: { removeAssignee: BooleanResponse };
};

export const RemoveAssigneeMutation = (
  onCompletedRemoveAssignee: (data: RemoveAssignResult) => void,
): ((removeAssignee: RemoveAssigneeInput) => any
) => {
  const [resultRemoveAssignee] = useMutation(REMOVE_ASSIGNEE, {
    onCompleted(data: RemoveAssignResult) {
      onCompletedRemoveAssignee(data);
    },
  });
  return resultRemoveAssignee;
};
