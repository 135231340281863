/* eslint-disable react/no-unused-prop-types */
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { RootState } from '../../../common/redux-store';
import { BaInput } from '../../../components';
import { setSearchText } from './all-tasks-redux-store';

const mapState = (state: RootState) => ({
  searchText: state?.myTasks?.searchText,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSearchText,
      trackEvent,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function AllTaskSearch(props: PropsFromRedux) {
  const { setSearchText, searchText, trackEvent } = props;

  const onChange = (text: string | undefined) => {
    setSearchText(text || '');
  };

  return (
    <div className="w-1/3 mac:w-1/3 xl:w-1/4">
      <BaInput
        parentValue={searchText}
        onChange={onChange}
        debounceTime={500}
        placeholder="Search Tasks"
        type="search"
      />
    </div>
  );
}

export default connector(AllTaskSearch);
