import Drawer from '@mui/material/Drawer';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DashboardInsightView } from './dashboard-insight-view';
import InsightsIcon from '../../../assets/insights-icon';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';

interface DashboardInsightContainerProps {
  mspId?: string;
  mspName?: string;
  mspStage?: string;
}

function DashboardInsightContainer(props: DashboardInsightContainerProps) {
  const { mspId, mspName, mspStage } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);
  const onOpen = useCallback(() => {
    setIsDrawerOpen(true);
    dispatch(
      trackEvent(TRACKING_CONSTANTS.CLICKED_INSIGHTS_ICON, {
        from: 'dashboard',
      }),
    );
  }, []);

  useEffect(() => {
    if (mspId) {
      sessionStorage.setItem('mspid', mspId);
    }
    return () => {
      sessionStorage.removeItem('mspid');
    };
  }, [mspId]);

  const headerRef = document.querySelector('.ba-header-section') as HTMLElement;

  return mspStage !== 'DRAFT' ? (
    <>
      <InsightsIcon
        onClick={onOpen}
        fontSize="medium"
        className="ml-2 mt-2 hidden cursor-pointer text-old-primary hover:text-warning-300 group-hover:flex"
      />
      <Drawer
        container={headerRef}
        anchor="right"
        open={isDrawerOpen}
        onClose={onClose}
      >
        <DashboardInsightView
          mspId={mspId}
          mspName={mspName}
          onClose={onClose}
        />
      </Drawer>
    </>
  ) : null;
}

export default DashboardInsightContainer;
