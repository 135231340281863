import { useState, useLayoutEffect, useEffect, useRef } from 'react';

export function useSectionHeight({
  filterSectionClass,
  classList = [],
}: {
  classList: string[];
  filterSectionClass: string;
}) {
  const [headerElement, setHeaderElement] = useState<Element | null>(null);
  const [sectionHeight, setSectionHt] = useState(0);
  const totalHeight = useRef(0);
  useLayoutEffect(() => {
    const ele = document.getElementsByClassName(filterSectionClass)[0];
    if (Array.isArray(classList) && classList.length) {
      for (let i = 0; i < classList.length; i += 1) {
        const eleHeight =
          document.getElementsByClassName(classList[i])[0]?.clientHeight ?? 0;
        totalHeight.current += eleHeight;
      }
    }
    setHeaderElement(ele);
  }, []);

  useEffect(() => {
    setSectionHt(headerElement?.clientHeight ?? 0);
  }, [headerElement?.clientHeight]);

  useEffect(
    () => () => {
      totalHeight.current = 0;
    },
    [],
  );

  return sectionHeight + totalHeight.current;
}
