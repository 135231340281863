import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../common/methods/debounce';

type TSearchHookProps = {
  parentValue: string | undefined;
  onChange?: (text: string | undefined) => void;
  debounceTime: number;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  maxLength?: number;
};

export function useDebouncedSearch({
  parentValue,
  debounceTime,
  onChange,
  inputRef,
  maxLength,
}: TSearchHookProps) {
  const [text, setText] = useState<string | undefined>(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!maxLength) setText(value);
    else if (maxLength && (value ?? '')?.length <= maxLength) setText(value);
    else setText(value?.substring(0, maxLength));
  };

  const clearText = useCallback(() => {
    setText('');
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    setText(parentValue);
  }, [parentValue]);

  const debouncedSearch = useDebounce((text: string) => {
    if (typeof onChange === 'function') onChange(text);
  }, debounceTime);

  useEffect(() => {
    if (text !== undefined && text !== parentValue) {
      if (text === '' && typeof onChange === 'function') onChange('');
      else debouncedSearch(text);
    }
  }, [text]);

  return {
    handleChange,
    clearText,
    text,
    setText,
  };
}
