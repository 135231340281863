/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetAccountContactsQueryVariables = Types.Exact<{
  otherContactInput: Types.OtherContactInput;
  defaultContactInput: Types.DefaultContactInput;
}>;

export type GetAccountContactsQuery = {
  __typename?: 'Query';
  relationshipQuery?: {
    __typename?: 'RelationshipQuery';
    leftSideRelationshipItemsList?:
      | { __typename?: 'GraphqlError' }
      | {
          __typename?: 'LeftSideRelationshipItems';
          otherContacts?:
            | {
                __typename: 'GraphqlError';
                type: Types.ErrorType;
                message: string;
              }
            | {
                __typename: 'RelationshipItemConnection';
                totalCount?: number;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: string;
                  beforeCursor?: string;
                };
                edges?: Array<{
                  __typename?: 'RelationshipItemEdge';
                  cursor?: string;
                  node?: {
                    __typename?: 'RelationshipContact';
                    id: string;
                    name?: string;
                    title?: string;
                    objectType?: Types.RelationshipObjectType;
                    reportTo?:
                      | { __typename?: 'GraphqlError' }
                      | {
                          __typename?: 'UserPayload';
                          items?: Array<
                            | { __typename?: 'GraphqlError' }
                            | {
                                __typename?: 'User';
                                id?: string;
                                primaryId?: string;
                                firstName?: string;
                                lastName?: string;
                                title?: string;
                                email?: string;
                              }
                          >;
                        };
                    contactRoles?:
                      | {
                          __typename?: 'ContactPayload';
                          items?: Array<
                            | {
                                __typename?: 'ContactRole';
                                id: string;
                                name: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >;
                        }
                      | {
                          __typename: 'GraphqlError';
                          code: string;
                          message: string;
                          type: Types.ErrorType;
                          helpLink?: string;
                        };
                    avatar?: { __typename?: 'Avatar'; url?: string };
                    buyerCompany?:
                      | { __typename?: 'Company'; displayName: string }
                      | { __typename?: 'GraphqlError' };
                  };
                }>;
              };
          defaultContacts?:
            | {
                __typename: 'GraphqlError';
                type: Types.ErrorType;
                message: string;
              }
            | {
                __typename: 'RelationshipItemConnection';
                totalCount?: number;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: string;
                  beforeCursor?: string;
                };
                edges?: Array<{
                  __typename?: 'RelationshipItemEdge';
                  cursor?: string;
                  node?: {
                    __typename?: 'RelationshipContact';
                    id: string;
                    name?: string;
                    title?: string;
                    objectType?: Types.RelationshipObjectType;
                    reportTo?:
                      | { __typename?: 'GraphqlError' }
                      | {
                          __typename?: 'UserPayload';
                          items?: Array<
                            | { __typename?: 'GraphqlError' }
                            | {
                                __typename?: 'User';
                                id?: string;
                                primaryId?: string;
                                firstName?: string;
                                lastName?: string;
                                title?: string;
                                email?: string;
                              }
                          >;
                        };
                    contactRoles?:
                      | {
                          __typename?: 'ContactPayload';
                          items?: Array<
                            | {
                                __typename?: 'ContactRole';
                                id: string;
                                name: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >;
                        }
                      | {
                          __typename: 'GraphqlError';
                          code: string;
                          message: string;
                          type: Types.ErrorType;
                          helpLink?: string;
                        };
                    avatar?: { __typename?: 'Avatar'; url?: string };
                    buyerCompany?:
                      | { __typename?: 'Company'; displayName: string }
                      | { __typename?: 'GraphqlError' };
                  };
                }>;
              };
        };
  };
};

export const GetAccountContactsDocument = `
    query getAccountContacts($otherContactInput: OtherContactInput!, $defaultContactInput: DefaultContactInput!) {
  relationshipQuery {
    leftSideRelationshipItemsList {
      ... on LeftSideRelationshipItems {
        otherContacts(input: $otherContactInput) {
          __typename
          ... on GraphqlError {
            type
            message
          }
          ... on RelationshipItemConnection {
            pageInfo {
              hasNextPage
              hasPreviousPage
              afterCursor
              beforeCursor
            }
            totalCount
            edges {
              cursor
              node {
                ... on RelationshipContact {
                  id
                  name
                  email
                  title
                  objectType
                  reportTo {
                    ... on UserPayload {
                      items {
                        ... on User {
                          id
                          primaryId
                          firstName
                          lastName
                          title
                          email
                        }
                      }
                    }
                  }
                  contactRoles {
                    ...CoreErrorFields
                    ... on ContactPayload {
                      items {
                        ... on ContactRole {
                          id
                          name
                        }
                      }
                    }
                  }
                  avatar {
                    url
                  }
                  buyerCompany {
                    ... on Company {
                      displayName
                    }
                  }
                }
              }
            }
          }
        }
        defaultContacts(input: $defaultContactInput) {
          __typename
          ... on GraphqlError {
            type
            message
          }
          ... on RelationshipItemConnection {
            pageInfo {
              hasNextPage
              hasPreviousPage
              afterCursor
              beforeCursor
            }
            totalCount
            edges {
              cursor
              node {
                ... on RelationshipContact {
                  id
                  name
                  email
                  title
                  objectType
                  reportTo {
                    ... on UserPayload {
                      items {
                        ... on User {
                          id
                          primaryId
                          firstName
                          lastName
                          title
                          email
                        }
                      }
                    }
                  }
                  contactRoles {
                    ...CoreErrorFields
                    ... on ContactPayload {
                      items {
                        ... on ContactRole {
                          id
                          name
                        }
                      }
                    }
                  }
                  avatar {
                    url
                  }
                  buyerCompany {
                    ... on Company {
                      displayName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getAccountContacts: build.query<
      GetAccountContactsQuery,
      GetAccountContactsQueryVariables
    >({
      query: (variables) => ({
        document: GetAccountContactsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAccountContactsQuery, useLazyGetAccountContactsQuery } =
  injectedRtkApi;
