import type { ColumnShape } from 'react-base-table';
import { IFilterConfig } from '../../components/filters/model';
import { PageInfo, GraphqlError } from '../../generated-types/index';

export interface IMspState {
  id?: string;
  lastActivitydAt?: number;
  mspTrackStatus?: string;
  name?: string;
  mspSlip?: number;
  lastExternalActivityAt?: number;
  opportunityId?: string;
  opportunityStage?: string;
  OpportunityCloseDate?: number;
  isOwner?: boolean;
  ownerName?: string;
  sharedByUserName?: string;
  tableKey?: string;
  mspStage?: string;
  buyerLogo?: string;
}

export interface GqlError extends GraphqlError {
  id: string;
}

export type TMsp = GqlError | IMspState;

export type ArchivalReasonListType = {
  displayName: string;
  id: string;
  name: string;
};

export interface IDashboardState {
  mspList: TMsp[];
  loading: boolean;
  error: unknown;
  filterDetails: IFilterConfig;
  filterLoading: boolean;
  filterError: unknown;
  dashboardColumns: ColumnShape[];
  search: string | undefined;
  pageInfo: PageInfo | null;
  isNextPageLoading: boolean;
  archivalReasonList: ArchivalReasonListType[];
  opportunitySearch?: string;
  opportunityPageInfo?: PageInfo;
  accountSearch?: string;
}

export enum BoxFilterType {
  assigneedToMeFilters = 'assigneedToMeFilters',
  overdueFilters = 'overdueFilters',
  dueTodayFilters = 'dueTodayFilters',
  dueSevenDaysFilters = 'dueSevenDaysFilters',
}
export interface BoxFilterFields {
  id: string;
  isSelected: boolean;
  field: string;
  value: string;
  label: string;
  iconType: string;
  filterType: BoxFilterType;
}

export enum BoxFilterDisplayName {
  assigneedToMeFilters = 'Assigned to me',
  overdueFilters = 'Overdue',
  dueTodayFilters = 'Due today',
  dueSevenDaysFilters = 'Due within 7 days',
}
