import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { RootState } from '../../../common/redux-store';
import {
  AssigneeType,
  Company,
  CompanyType,
  User,
} from '../../../generated-types';
import { AssigneeSearchTabType } from '../../msp-view/model';
import { updateTaskAssignee, TaskType } from './api-call';
import AssignSearchUser, { SearchType } from '../../../components/search-component';
import { resetToSearchComponentInitialState } from '../../../common/redux-store/reducers/search-user-reducer';
import { fetchPlaceholderList } from '../../msp-view/milestone-details/api-call';

interface IAddAsssigneeProps {
  onClose:()=>void;
  taskId: string;
  mspId: string;
  taskType: TaskType;
  isTaskInternal:boolean
  sellerCompany:Company | undefined
}

const mapStateToProps = (state: RootState) => ({
  selectedTab: state.searchComponent.taskAssigneeSelectedTab,
  placeholderList: state?.milestoneDetail?.placeholderData?.placeholderList,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetToSearchComponentInitialState,
      fetchPlaceholderList,
    },
    dispatch,
  );
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = IAddAsssigneeProps & PropsFromRedux

function AddAssignee(props: Props) {
  const {
    taskId,
    isTaskInternal,
    resetToSearchComponentInitialState,
    onClose,
    selectedTab,
    sellerCompany,
    fetchPlaceholderList,
    mspId,
    taskType,
    placeholderList,
  } = props;
  let localPlaceholderList = placeholderList;
  const client = useApolloClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (taskId) resetToSearchComponentInitialState();
  }, [resetToSearchComponentInitialState, taskId]);

  const onAssign = useCallback((selectedUsersList:User[]) => {
    if (typeof onClose === 'function') onClose();
    const selectedUser = selectedUsersList[0] ?? {};
    const user = selectedUser;
    if (selectedUser) {
      dispatch(updateTaskAssignee({
        client, taskId, mspId, taskType, dispatch, user, assigneeType: selectedTab === AssigneeSearchTabType?.PLACEHOLDER ? AssigneeType?.PLACEHOLDER : AssigneeType?.USER,
      }));
    }
  }, [onClose, taskId, client, mspId, taskType, dispatch, selectedTab]);

  useEffect(() => {
    fetchPlaceholderList({ client, mspId });
  }, []);
  if (isTaskInternal && placeholderList && Array.isArray(placeholderList)) localPlaceholderList = placeholderList.filter((item) => item?.userType === CompanyType?.SELLER);
  const tabList = [{ label: 'Stakeholders', value: AssigneeSearchTabType.STAKEHOLDER }, { label: 'Placeholders', value: AssigneeSearchTabType.PLACEHOLDER }];
  return (
    <AssignSearchUser
      title="Assign User"
      companyDetails={isTaskInternal ? sellerCompany as Company : undefined}
      userList={selectedTab === AssigneeSearchTabType.PLACEHOLDER ? localPlaceholderList ?? [] : []}
      onSubmit={onAssign}
      onClose={onClose}
      userLimit={1}
      searchType={SearchType.ADDTASK}
      tabList={tabList}
      isMulti
    />

  );
}

export default connector(AddAssignee);
