import { RootState } from '../../../../common/redux-store';
import { TOption } from '../../../../components/ba-select/common';
import {
  AccountPlan,
  AccountPlanConnection,
  AccountPlanEdge,
  Company,
  CompanyConnection,
  GraphqlError,
} from '../../../../generated-types';
import {
  ListAccountPlansQuery,
  api as ListAccountApi,
} from './gql/list-account.generated';

import {
  SearchAccountPlanQuery,
  api as SearchAccountApi,
} from './gql/search-account.generated';

import {
  api as searchCompanyApi,
  SearchCompaniesQuery,
} from './gql/search-company.generated';

export const extendedSearchCompanyApi = searchCompanyApi.enhanceEndpoints({
  endpoints: {
    searchCompanies: {
      keepUnusedDataFor: 1800,
      transformResponse: (response: SearchCompaniesQuery) => {
        if (
          response?.companyQuery?.searchCompany?.__typename === 'GraphqlError'
        ) {
          throw new Error('Service error');
        }
        const { edges: searchCompanyResult } =
          (response?.companyQuery?.searchCompany as CompanyConnection) || {};

        const companies = searchCompanyResult?.map((edge) => {
          const { companyName, id, extId, displayName } = edge?.node as Company;
          return {
            id,
            name: displayName,
            extId: extId as string,
            companyName,
          };
        });

        return companies;
      },
      async onQueryStarted(
        _args,
        { getState, queryFulfilled, updateCachedData },
      ) {
        try {
          await queryFulfilled;

          const sellerCompanyId = (
            (getState() as RootState)?.msp?.mspDetail?.sellerCompany as Company
          )?.id;

          updateCachedData((draft) => {
            const index = (draft as TOption[])?.findIndex(
              ({ id }) => id === sellerCompanyId,
            );
            if (index !== -1) (draft as TOption[])?.splice(index, 1);
          });
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useLazySearchCompaniesQuery } = extendedSearchCompanyApi;

function transformAccountResponse(edge: AccountPlanEdge) {
  const { buyerCompany, ...rest } = (edge?.node || {}) as AccountPlan;
  const company = (buyerCompany || {}) as Company;

  return { ...rest, ...company };
}

export const extendedListAccountApi = ListAccountApi.enhanceEndpoints({
  endpoints: {
    listAccountPlans: {
      transformResponse: (response: ListAccountPlansQuery) => {
        if (
          response?.accountPlanQuery?.listAccountPlans?.__typename ===
          'GraphqlError'
        ) {
          const { code, type } = response?.accountPlanQuery
            ?.listAccountPlans as GraphqlError;
          throw new Error(`${type}:${code}`);
        }
        const opportunityList = (
          response?.accountPlanQuery?.listAccountPlans as AccountPlanConnection
        )?.edges?.map((edge) => transformAccountResponse(edge));

        return opportunityList;
      },
      keepUnusedDataFor: 60 * 30,
    },
  },
});

export const { useListAccountPlansQuery } = extendedListAccountApi;

export const extendedSearchAccountApi = SearchAccountApi.enhanceEndpoints({
  endpoints: {
    searchAccountPlan: {
      transformResponse: (response: SearchAccountPlanQuery) => {
        if (
          response?.accountPlanQuery?.searchAccountPlan?.__typename ===
          'GraphqlError'
        ) {
          const { code, type } = response?.accountPlanQuery
            ?.searchAccountPlan as GraphqlError;
          throw new Error(`${type}:${code}`);
        }
        const opportunityList = (
          response?.accountPlanQuery?.searchAccountPlan as AccountPlanConnection
        )?.edges?.map((edge) => transformAccountResponse(edge));

        return opportunityList;
      },
      keepUnusedDataFor: 60 * 30,
    },
  },
});

export const { useSearchAccountPlanQuery, useLazySearchAccountPlanQuery } =
  extendedSearchAccountApi;
