/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type SearchAccountPlanQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SearchAccountPlanInput>;
}>;

export type SearchAccountPlanQuery = {
  __typename?: 'Query';
  accountPlanQuery?: {
    __typename?: 'AccountPlanQuery';
    searchAccountPlan:
      | {
          __typename?: 'AccountPlanConnection';
          edges?: Array<{
            __typename?: 'AccountPlanEdge';
            node?:
              | {
                  __typename?: 'AccountPlan';
                  id: string;
                  createdAt: number;
                  buyerCompany?:
                    | {
                        __typename?: 'Company';
                        displayName: string;
                        companyName: string;
                      }
                    | { __typename?: 'GraphqlError' };
                }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: Types.ErrorType;
                  helpLink?: string;
                };
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const SearchAccountPlanDocument = `
    query searchAccountPlan($input: SearchAccountPlanInput) {
  accountPlanQuery {
    searchAccountPlan(input: $input) {
      ...CoreErrorFields
      ... on AccountPlanConnection {
        edges {
          node {
            ...CoreErrorFields
            ... on AccountPlan {
              id
              createdAt
              buyerCompany {
                ... on Company {
                  displayName
                  companyName
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    searchAccountPlan: build.query<
      SearchAccountPlanQuery,
      SearchAccountPlanQueryVariables | void
    >({
      query: (variables) => ({
        document: SearchAccountPlanDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchAccountPlanQuery, useLazySearchAccountPlanQuery } =
  injectedRtkApi;
