import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IList } from './models';

export const initialState = {
  list: [] as IList[],
};
const mspSlice = createSlice({
  name: 'saveIndicator',
  initialState,
  reducers: {
    removeFromSavingList: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(({ id }) => id !== action.payload);
    },
    addToSavingList: (state, action: PayloadAction<IList>) => {
      state.list.push(action.payload);
    },
  },
});
export const { addToSavingList, removeFromSavingList } = mspSlice.actions;

export default mspSlice.reducer;
