import clsx from 'clsx';
import React, { forwardRef, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../analytics';
import { Typography } from '../typography';
import {
  COMMON_SEARCH_CLASSES,
  EndIcon,
  ErrorMessage,
  LastOption,
  NoOptionsFound,
  OptionLayout,
  OptionListLayout,
  SelectLayout,
  TOption,
  useSelectState,
} from './common';
import './styles.css';

interface IBaSelectProps {
  optionList: TOption[];
  setSelected: (option: TOption | null) => void;
  placeholder?: string;
  selectedItem?: TOption | null;
  lastOption?: TOption;
  isLoading?: boolean;
  error?: string;
  onRefetch?: () => void;
  startIcon?: React.ReactElement;
  isNetworkError?: boolean;
  clearable?: boolean;
  inputClassName?: string;
  disabled?: boolean;
  searchable?: boolean;
  heightClass?: string;
  name?: string;
  component?: string;
  optionType?: string;
  disabledOptionMessage?: string;
  noOptionsMessage?: string;
}
export const BaSelect = forwardRef<HTMLInputElement, IBaSelectProps>(
  function Input(props, ref) {
    const {
      placeholder = 'Please select...',
      optionList = [],
      isLoading,
      setSelected,
      selectedItem,
      lastOption,
      error,
      onRefetch,
      startIcon,
      isNetworkError,
      clearable = false,
      inputClassName,
      disabled = false,
      searchable = true,
      heightClass = '',
      name,
      component = '',
      optionType,
      disabledOptionMessage,
      noOptionsMessage,
    } = props;

    const {
      onSelect,
      onLastOption,
      openModal,
      handleClose,
      isOpen,
      searchTerm,
      inputContainerRef,
      setSearchTerm,
    } = useSelectState({ isLoading, setSelected, lastOption, disabled });
    const dispatch = useDispatch();

    useEffect(() => {
      if (isOpen) {
        dispatch(trackEvent(`Accessed ${component} Dropdown`));
      }
    }, [isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!searchTerm) {
        inputContainerRef?.current?.setAttribute('data-name', '');
        setSelected(null);
      }
      setSearchTerm(value);
    };

    const filteredList = optionList?.filter(({ name = '' }) =>
      name?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase() || ''),
    );

    const isClearIconVisible = searchTerm || selectedItem?.name;
    return (
      <div className="relative">
        <SelectLayout
          isOpen={isOpen}
          error={error}
          openModal={openModal}
          inputClassName={clsx(inputClassName, disabled && 'bg-mid-10')}
          heightClass={heightClass}
        >
          <div
            className={clsx(
              'flex flex-1 items-center space-x-2',
              !searchTerm && 'focus-within:opacity-50',
            )}
          >
            {startIcon}
            {searchable ? (
              <div
                ref={inputContainerRef}
                className="ba-search-select-container flex flex-1 items-center"
                data-name={selectedItem?.name}
              >
                <input
                  value={searchTerm}
                  ref={ref}
                  placeholder={selectedItem?.name ? '' : placeholder}
                  type="text"
                  autoFocus={false}
                  onChange={handleChange}
                  className={clsx(
                    COMMON_SEARCH_CLASSES,
                    disabled && 'bg-mid-10',
                  )}
                  disabled={disabled}
                  name={name}
                />
              </div>
            ) : (
              <Typography
                className={clsx(
                  'pl-1',
                  optionType === 'methodology'
                    ? 'flex min-h-[44px] items-center py-1'
                    : selectedItem?.name
                    ? 'truncate py-2 text-neutral '
                    : 'truncate py-2 text-neutral-200 ',
                )}
                variant="body-13-regular"
              >
                {selectedItem?.name ?? placeholder}
              </Typography>
            )}
          </div>

          {clearable && isClearIconVisible && (
            <FiXCircle
              onClick={(e) => {
                e.stopPropagation();
                setSearchTerm('');
                setSelected(null);
              }}
              className="cursor-pointer pr-2 text-16 text-mid-200 hover:text-warning"
            />
          )}
          <EndIcon
            isNetworkError={!!isNetworkError}
            isLoading={!!isLoading}
            error={error}
            onRefetch={onRefetch}
          />
        </SelectLayout>

        {isOpen && (
          <OptionListLayout onClickAway={handleClose}>
            <div className="max-h-48 overflow-auto ">
              <>
                {filteredList?.map(
                  ({
                    id,
                    name,
                    logo,
                    endField,
                    startIcon,
                    isOptionDisabled,
                  }) => (
                    <OptionLayout
                      key={id}
                      handleClose={handleClose}
                      setSelected={onSelect}
                      id={id}
                      name={name}
                      logo={logo}
                      endField={endField}
                      startIcon={startIcon}
                      isOptionDisabled={isOptionDisabled}
                      disabledOptionMessage={disabledOptionMessage}
                    />
                  ),
                )}
                {(optionList?.length === 0 || filteredList?.length === 0) &&
                  (noOptionsMessage ? (
                    <NoOptionsFound>{noOptionsMessage}</NoOptionsFound>
                  ) : (
                    <NoOptionsFound />
                  ))}
              </>
            </div>
            {lastOption && (
              <LastOption onLastOption={onLastOption} name={lastOption?.name} />
            )}
          </OptionListLayout>
        )}
        {error && <ErrorMessage error={error} />}
      </div>
    );
  },
);

export default BaSelect;
