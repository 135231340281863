import { gql, useMutation } from '@apollo/client';
import {
  TaskMutationCreateUserAndAssignToTasksArgs,
  UserPayloadResponse,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

type CreateUserAndAssignToTasksInput = {
  variables: TaskMutationCreateUserAndAssignToTasksArgs;
};

export const ADD_USER_ASSIGN_TASK = gql`
  ${CORE_GRAPH_ERROR}
  mutation AddUserAssignTask($taskIds: [ID!]!, $input: CreateUserInput!) {
    taskMutation {
      createUserAndAssignToTasks(taskIds: $taskIds, input: $input) {
        __typename
        ...CoreErrorFields
        ... on UserPayload {
          items {
            __typename
            ...CoreErrorFields
            ... on User {
              id
              firstName
              lastName
              email
              userType
              primaryId
            }
          }
        }
      }
    }
  }
`;

export type AddUserAssignTaskResult = {
  taskMutation: { createUserAndAssignToTasks: UserPayloadResponse };
};

export const CreateUserAndAssignToTasks = (
  onCompletedAddedUser: (taskData: AddUserAssignTaskResult) => void,
): ((createUserAndAssignTask: CreateUserAndAssignToTasksInput) => any
) => {
  const [resultAddedUser] = useMutation(ADD_USER_ASSIGN_TASK, {
    onCompleted(taskData: AddUserAssignTaskResult) {
      onCompletedAddedUser(taskData);
    },
  });
  return resultAddedUser;
};
