import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { User, Usp } from '../../../generated-types';
import { IUspData, IUspObject } from '../model';
import { Roles } from './subscriber-permission';

export const initialState = {
  selectedCollaborator: [] as User[],
  selectedRole: Roles.VIEWER,
  uspData: {} as IUspData,
  isShareMspLoading: false,
};

const mspSlice = createSlice({
  name: 'share-msp',
  initialState,
  reducers: {
    setSelectedCollaborator: (state, action: PayloadAction<User[]>) => {
      state.selectedCollaborator = action.payload;
    },
    setSelectedPermission: (state, action: PayloadAction<Roles>) => {
      state.selectedRole = action.payload;
    },
    setUspData: (
      state,
      action: PayloadAction<{
        uspData?: Usp[];
        type?: keyof IUspData;
        data?: IUspObject;
      }>,
    ) => {
      const { uspData, data, type } = action.payload;
      if (type) state.uspData[type] = data;
      else if (uspData) {
        if (uspData.length === 0) {
          state.uspData.public = undefined;
          state.uspData.private = undefined;
        }
        uspData.forEach((usp) => {
          if (usp.public) {
            state.uspData.public = {
              password: usp.password,
              id: usp.id,
              link: usp.link,
            } as IUspObject;
          } else if (!usp.public) {
            state.uspData.private = {
              password: usp.password,
              id: usp.id,
              link: usp.link,
            } as IUspObject;
          }
        });
      }
    },
    setShareMspLoader: (state, action: PayloadAction<boolean>) => {
      state.isShareMspLoading = action.payload;
    },
  },
});

export const {
  setSelectedCollaborator,
  setSelectedPermission,
  setUspData,
  setShareMspLoader,
} = mspSlice.actions;

export default mspSlice.reducer;
