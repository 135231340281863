import { useApolloClient } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useThrottle } from 'rooks';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { RootState } from '../../../common/redux-store';
import { BaModal, CustomToolTip, Typography } from '../../../components';
import { BaResource } from '../../../generated-types';
import { fetchNotification } from './api-call';
import { IAccountList, NotificationTabType } from './models';
import {
  setAccountIdAndNotificationType,
  setBaResource,
  setMarkAllReadNotification,
} from './notification-redux-store';

const mapState = (state: RootState) => ({
  notificationList: state.notification.notificationList,
  userId: state.userInfo.userDetails.userId ?? '',
  loading: state.notification.loading,
  accountList: state.notification.accountList,
  notificationType:
    state.notification.notificationFilterDetails.notificationType,
  accountId: state.notification.notificationFilterDetails.accountId,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchNotification,
      setMarkAllReadNotification,
      setAccountIdAndNotificationType,
      setBaResource,
      trackEvent,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type InputProps = {
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
};
type NotificationFilterProps = InputProps & PropsFromRedux;

const notificationFilterList = [
  {
    name: 'Timeline',
    key: NotificationTabType.TIMELINE,
  },
  {
    name: 'By Account',
    key: NotificationTabType.BY_ACCOUNT,
  },
  {
    name: 'By Type',
    key: NotificationTabType.BY_TYPE,
  },
];

let mouseLeaveTimer: unknown | number;

function NotificationFilter(props: NotificationFilterProps) {
  const {
    userId,
    accountList,
    setAccountIdAndNotificationType,
    fetchNotification,
    setSelectedTab,
    accountId,
    setBaResource,
    notificationType,
    trackEvent,
  } = props;

  const client = useApolloClient();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleAccountPopoverClose = useCallback(() => {
    setAccountAnchorEl(null);
    handleClose();
  }, []);

  const onNotificationTypeClick = useCallback(
    (type: string, event: React.MouseEvent<HTMLElement>) => {
      if (type !== NotificationTabType.BY_ACCOUNT) {
        if (type === NotificationTabType.TIMELINE) {
          setBaResource(undefined);
        }
        setSelectedTab(BaResource.TASK);
        setAccountIdAndNotificationType({ accountId: undefined, type });
        fetchNotification({
          client,
          userId,
          accountId: undefined,
          baResourceList:
            type === NotificationTabType.BY_TYPE
              ? [{ type: BaResource.TASK }]
              : undefined,
        });
        trackEvent(TRACKING_CONSTANTS.NOTIFICATION_FILTERS, {
          notificationFilterType: type,
        });
        handleClose();
      } else {
        setAccountAnchorEl(event.currentTarget);
        setBaResource(undefined);
      }
    },
    [
      client,
      fetchNotification,
      setAccountIdAndNotificationType,
      setBaResource,
      setSelectedTab,
      userId,
    ],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickAccount = (accountId: string) => {
    trackEvent(TRACKING_CONSTANTS.NOTIFICATION_FILTERS, {
      notificationFilterType: NotificationTabType.BY_ACCOUNT,
      accountId,
    });
    setAccountIdAndNotificationType({
      accountId,
      type: NotificationTabType.BY_ACCOUNT,
    });
    fetchNotification({ client, userId, accountId });
    handleAccountPopoverClose();
  };

  const handleMouseleave = (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  ) => {
    mouseLeaveTimer = setTimeout(() => {
      setAnchorEl(null);
    }, 420);
  };
  const clearMouseLeaveEvent = () => {
    clearTimeout(mouseLeaveTimer as number);
  };

  const [handleMouseEnter] = useThrottle(
    (
      targetElem: HTMLElement,
      setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    ) => {
      setAnchorEl(targetElem);
    },
    400,
  );

  const handleNotificationFilterOnHover = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => {
      const eventType = event?.type;
      if (
        type === NotificationTabType.BY_ACCOUNT &&
        eventType === 'mouseenter'
      ) {
        handleMouseEnter(event.currentTarget, setAccountAnchorEl);
      } else if (
        type === NotificationTabType.BY_ACCOUNT &&
        eventType === 'mouseleave'
      ) {
        handleMouseleave(setAccountAnchorEl);
      }
    },
    [],
  );

  return (
    <div className="flex items-center">
      <div
        role="presentation"
        className="flex cursor-pointer items-center space-x-1 rounded-full py-1 px-2 hover:bg-primary-100"
        onClick={handleClick}
      >
        <Typography
          variant="body-13-medium"
          element="p"
          className="text-primary"
        >
          {notificationType}
        </Typography>
        <ExpandMoreIcon fontSize="small" color="primary" />
      </div>
      <BaModal
        anchorEl={anchorEl}
        onClose={handleClose}
        setAnchorEl={setAnchorEl}
        placement="bottom-start"
        zIndex={50}
      >
        {notificationFilterList?.map(({ name, key }) => (
          <div
            role="presentation"
            key={key}
            className="flex cursor-pointer items-center justify-start space-x-2 px-4 py-2 hover:bg-neutral-50 hover:text-primary"
            onMouseEnter={(event) =>
              handleNotificationFilterOnHover(event, key)
            }
            onMouseLeave={(event) =>
              handleNotificationFilterOnHover(event, key)
            }
            onClick={(event) => onNotificationTypeClick(key, event)}
          >
            <Typography variant="body-14-regular" className="text-neutral-400">
              {name}
            </Typography>
            {name === NotificationTabType.BY_ACCOUNT && (
              <BiChevronRight fontSize={20} />
            )}
          </div>
        ))}
      </BaModal>
      <BaModal
        anchorEl={accountAnchorEl}
        onMouseEnter={clearMouseLeaveEvent}
        onClose={handleAccountPopoverClose}
        setAnchorEl={setAccountAnchorEl}
        placement="right-start"
        zIndex={50}
      >
        <div onMouseLeave={() => setAccountAnchorEl(null)}>
          {accountList?.map((account) => (
            <div
              role="presentation"
              key={(account as IAccountList).accountId}
              className={clsx(
                'flex w-32 cursor-pointer items-center justify-between space-x-2 px-4 py-2 text-neutral-400 hover:bg-neutral-50 hover:text-primary',
                (account as IAccountList).accountId === accountId &&
                  'bg-neutral-50',
              )}
              onClick={() =>
                onClickAccount((account as IAccountList).accountId)
              }
            >
              <CustomToolTip
                title={(account as IAccountList).accountName}
                showTruncated
              >
                <div className="w-24 truncate capitalize">
                  <Typography variant="body-14-regular">
                    {(account as IAccountList).accountName}
                  </Typography>
                </div>
              </CustomToolTip>
              <div className="flex items-center">
                <div className="flex h-5 w-7 items-center justify-center rounded border border-solid border-gray-300 bg-gray-300 text-center text-[10px] font-semibold text-neutral-500">
                  {(account as IAccountList).unreadCount}
                </div>
              </div>
            </div>
          ))}
        </div>
      </BaModal>
    </div>
  );
}

export default connector(NotificationFilter);
