import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comment, Maybe } from '../../../generated-types';
// import { INotesState } from './models';

export const initialState = {
  commentLists: [] as Maybe<Comment>[],
  noteId: '',
  commentText: '',
  editCommentText: '',
  isEdited: false,
  isPopoverOpen: false,
  selectedComment: undefined as Comment | undefined,
  moreOptionsAnchorEl: null as EventTarget | null,
};

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setCommentListsAndNoteId: (
      state,
      action: PayloadAction<{
        commentLists: Maybe<Comment>[];
        noteId: string;
      }>,
    ) => {
      state.commentLists = action.payload.commentLists;
      state.noteId = action.payload.noteId;
    },
    setPopoverData: (
      state,
      action: PayloadAction<{
        isPopoverOpen: boolean;
        moreOptionsAnchorEl: Element | null;
      }>,
    ) => {
      state.isPopoverOpen = action.payload.isPopoverOpen;
      state.moreOptionsAnchorEl = action.payload.moreOptionsAnchorEl;
    },
    setOnMoreOptionClick: (
      state,
      action: PayloadAction<{
        selectedComment: Comment;
        isPopoverOpen: boolean;
        moreOptionsAnchorEl: Element;
      }>,
    ) => {
      state.selectedComment = action.payload.selectedComment;
      state.isPopoverOpen = action.payload.isPopoverOpen;
      state.moreOptionsAnchorEl = action.payload.moreOptionsAnchorEl;
    },
    setOnUpdateCommentCancelClick: (
      state,
      action: PayloadAction<{
        editCommentText: string;
        isPopoverOpen: boolean;
        selectedComment: undefined;
      }>,
    ) => {
      state.editCommentText = action.payload.editCommentText;
      state.selectedComment = action.payload.selectedComment;
      state.isPopoverOpen = action.payload.isPopoverOpen;
    },
    setOnUpdateCommentEditClick: (
      state,
      action: PayloadAction<{
        isPopoverOpen: boolean;
        isEdited: boolean;
        editCommentText: string;
      }>,
    ) => {
      state.isPopoverOpen = action.payload.isPopoverOpen;
      state.editCommentText = action.payload.editCommentText;
      state.isEdited = action.payload.isEdited;
    },
    setCommentText: (state, action: PayloadAction<string>) => {
      state.commentText = action.payload;
    },
    setEditCommentText: (state, action: PayloadAction<string>) => {
      state.editCommentText = action.payload;
    },
    setCommentList: (state, action: PayloadAction<Maybe<Comment>[]>) => {
      state.commentLists = action.payload;
    },
    setCommentListAndSelectedComment: (
      state,
      action: PayloadAction<{
        commentLists: Maybe<Comment>[];
        selectedComment: undefined;
      }>,
    ) => {
      state.commentLists = action.payload.commentLists;
      state.selectedComment = action.payload.selectedComment;
    },
    resetCommentTextAndisEditedFlag: (
      state,
      action: PayloadAction<{ commentText: string; isEdited: boolean }>,
    ) => {
      state.commentText = action.payload.commentText;
      state.isEdited = action.payload.isEdited;
    },
  },
});
export const {
  setCommentList,
  setPopoverData,
  setOnMoreOptionClick,
  setOnUpdateCommentCancelClick,
  setOnUpdateCommentEditClick,
  setCommentListsAndNoteId,
  setCommentText,
  setEditCommentText,
  setCommentListAndSelectedComment,
  resetCommentTextAndisEditedFlag,
} = noteSlice.actions;

export default noteSlice.reducer;
