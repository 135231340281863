import { Typography } from '../../../components';
import InsightIcon from '../../../assets/insights-icon';
import { colorPalette } from '../../../common/newTheme';
import exitIcon from '../../../assets/exit-icon.svg';

interface HeaderProps {
  onClose: () => void;
  mspName: string;
}

function SideDrawerHeader(props: HeaderProps) {
  const { onClose, mspName } = props;
  return (
    <div className="flex flex-row justify-between items-center ">
      <div className="flex flex-row justify-start items-center space-x-1">
        <InsightIcon onClick={onClose} fontSize="small" className="ml-2 mt-2 text-old-primary" />
        <Typography variant="heading3-bold" className="text-center">
          {mspName}
        </Typography>
      </div>

      <img alt="exitIcon" src={exitIcon} height={16} width={16} onClick={onClose} role="presentation" className="cursor-pointer " />
    </div>
  );
}

export default SideDrawerHeader;
