import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../common/redux-store';
import { FilterComponent } from '../../../components';
import FilterChips from '../../../components/filter-chips';
import TableHeadCommonLayout from '../table-head-layout';
import AllTaskSearch from './all-task-search';
import { setSearchText } from './all-tasks-redux-store';
import AllTaskFilter from './all-task-extra-filters';

const mapState = (state: RootState) => ({
  filterDetails: state?.allTasks?.filterDetails,
  selectedFilters: state?.filters?.selectedFilters?.myTasks,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSearchText,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function AllTasksHeader(props: PropsFromRedux) {
  const {
    filterDetails, setSearchText,
  } = props;

  return (
    <TableHeadCommonLayout className="my-task-search-filter-section">
      <>
        <div className="flex justify-between items-center">
          <AllTaskSearch />
          <div className="flex flex-col xl:flex-row space-x-3">
            <AllTaskFilter />
            <div className="mt-3 xl:mt-0">
              <FilterComponent
                sectionName="allTasks"
                filterConfig={filterDetails}
                isResetButtonEnabled={false}
              />
            </div>
          </div>
        </div>
        <FilterChips sectionName="allTasks" onClearAll={() => setSearchText('')} />
      </>
    </TableHeadCommonLayout>
  );
}

export default connector(AllTasksHeader);
