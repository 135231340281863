import 'dayjs/locale/zh-cn';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ApolloApp from './App';
import { message } from './common-language';
import KeycloakProvider from './common/auth/authentication-context';
import {
  getKeycloakInstance,
  initKeycloak,
} from './common/auth/utils/keycloak-instance';
import store from './common/redux-store';
import './common/tailwind.css';
import './index.css';
import { initializeDatadog } from './utils/datadog';

if (process.env.TAILWIND_BUILD) {
  import('./tailwind-build/output.css');
}

function App() {
  if (process.env.STAGE === 'prod') {
    initializeDatadog();
  }

  return (
    <Provider store={store}>
      <KeycloakProvider keycloak={keycloak}>
        <IntlProvider locale="en" messages={message}>
          <ApolloApp />
        </IntlProvider>
      </KeycloakProvider>
    </Provider>
  );
}

if (module.hot) (module.hot as { accept: () => void }).accept();

const renderApp = () => render(<App />, document.querySelector('#root'));

const keycloak = getKeycloakInstance();
initKeycloak(keycloak, renderApp);
