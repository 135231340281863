import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import errorImg from '../../assets/error-page.svg';
import forbiddenPageImg from '../../assets/forbidden-error.svg';
import mspNotFoundImg from '../../assets/msp-not-found.svg';
import pageNotFoundImg from '../../assets/page-not-found.svg';
import ErrorPageCommonLayout from './error-page';
import { MspOpportunity } from '../../alignx/msp-view/msp-opportunity';

export enum ErrorCodes {
  ACCESS_DENIED = 'ACCESS_DENIED',
  MSP_NOT_FOUND = 'MSP_NOT_FOUND',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  OPPORTUNITY_NOT_FOUND = 'OPPORTUNITY_NOT_FOUND',
  UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS',
}

function ErrorPages({
  errorCode,
  message,
  description,
}: {
  errorCode?: string;
  message?: string;
  description?: string;
}) {
  const history = useHistory();

  switch (errorCode) {
    case ErrorCodes.ACCESS_DENIED:
      return (
        <ErrorPageCommonLayout>
          <>
            <img
              alt="forbiddenPageImg"
              src={forbiddenPageImg as string}
              width="35%"
              height="25%"
            />
            <ErrorTitle id="ForbiddenPage.title" defaultMessage="Forbidden" />
            <ErrorMessage
              id="ForbiddenPage.message"
              defaultMessage="You don’t have permission to access on this server."
              text={message}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/ui/alignx');
              }}
            >
              <FormattedMessage
                id="Button.backToHome"
                defaultMessage="Back to home"
              />
            </Button>
          </>
        </ErrorPageCommonLayout>
      );
    case ErrorCodes.PAGE_NOT_FOUND:
      return (
        <ErrorPageCommonLayout>
          <>
            <img
              alt="pageNotFoundImg"
              src={pageNotFoundImg as string}
              width="30%"
              height="25%"
            />
            <ErrorTitle
              id="PageNotFound.title"
              defaultMessage="404 - Page Not Found"
            />
            <ErrorMessage
              id="PageNotFound.message"
              defaultMessage="The page you’re looking for is currently under maintenance and will be back soon."
              text={message}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/ui/alignx');
              }}
            >
              <FormattedMessage
                id="Button.backToHome"
                defaultMessage="Back to home"
              />
            </Button>
          </>
        </ErrorPageCommonLayout>
      );
    case ErrorCodes.MSP_NOT_FOUND:
      return (
        <ErrorPageCommonLayout>
          <>
            <img
              alt="mspNotFoundImg"
              src={mspNotFoundImg as string}
              width="35%"
              height="25%"
            />
            <ErrorMessage
              id="MspNotFound.message"
              defaultMessage="Looks like there is no Mutual Success Plan on this opportunity"
              text={message}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/ui/alignx/dashboard?create-msp=start');
              }}
            >
              <FormattedMessage id="Button.Msp" defaultMessage="+ Create New" />
            </Button>
            <MspOpportunity isUsedInSalesforce />
          </>
        </ErrorPageCommonLayout>
      );
    case ErrorCodes.OPPORTUNITY_NOT_FOUND: {
      return (
        <ErrorPageCommonLayout>
          <>
            <img
              alt="opportunityNotFoundImg"
              src={errorImg as string}
              width="35%"
              height="25%"
            />
            <ErrorTitle
              id="OpportunityNotFound.title"
              defaultMessage="404 - Opportunity Not Found"
            />
            {/* <ErrorMessage
              id="OpportunityNotFound.message"
              defaultMessage="The opportunity you’re looking for is currently under maintenance and will be back soon."
              text={message}
            /> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/ui/alignx');
              }}
            >
              <FormattedMessage
                id="Button.backToHome"
                defaultMessage="Back to home"
              />
            </Button>
          </>
        </ErrorPageCommonLayout>
      );
    }
    default:
      return (
        <ErrorPageCommonLayout>
          <>
            <img
              alt="errorImg"
              src={errorImg as string}
              width="30%"
              height="25%"
            />
            <ErrorTitle id="ErrorPage.title" defaultMessage="Server Error" />
            <ErrorMessage
              id="ErrorPage.prefixDescription"
              defaultMessage="The Server encountered an internal error or misconfiguration
              and was"
              text={description}
              suffixText={{
                id: 'ErrorPage.suffixDescription',
                defaultMessage: 'unable to complete your request.',
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              <FormattedMessage
                id="Button.refreshPage"
                defaultMessage="Refresh page"
              />
            </Button>
          </>
        </ErrorPageCommonLayout>
      );
  }
}

function ErrorTitle(props: { id: string; defaultMessage: string }) {
  const { id, defaultMessage } = props;
  return (
    <div className="text-3xl font-extrabold">
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </div>
  );
}

function ErrorMessage(props: {
  id: string;
  defaultMessage: string;
  text?: string;
  className?: string;
  suffixText?: { id: string; defaultMessage: string };
}) {
  const { id, defaultMessage, text, className, suffixText } = props;
  return (
    <div className={`text-sm ${className ?? ''}`}>
      {text ?? <FormattedMessage id={id} defaultMessage={defaultMessage} />}
      <br />
      {suffixText && (
        <FormattedMessage
          id={suffixText?.id}
          defaultMessage={suffixText?.defaultMessage}
        />
      )}
    </div>
  );
}

export default ErrorPages;
