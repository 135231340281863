/* eslint-disable */
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import { getToken } from '../common/auth/authentication-service';
import { getMspId } from '../common/methods/get-msp-id';
import { getAPIUrl, orgIdExtractor } from '../utils';
// import { RootState } from '../common/redux-store';

const url = getAPIUrl();

export const client = new GraphQLClient(url);

export const api = createApi({
  // reducerPath: 'api',
  baseQuery: graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers: any) => {
      const accessToken = getToken();
      const mspId =
        getMspId(window.location.pathname) || sessionStorage.getItem('mspid');
      const orgId = orgIdExtractor();
      const uspToken = sessionStorage.getItem(mspId);

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      if (mspId) {
        headers.set('mspid', mspId);
      }
      if (orgId) {
        headers.set('sellerorgid', orgId);
      }
      if (uspToken) {
        headers.set('usptoken', uspToken);
      }
      return headers;
    },
  }),
  // refetchOnMountOrArgChange: true,
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  endpoints: () => ({}),
  // tagTypes: ['Task'],
});
