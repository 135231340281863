import AccountHeader from './account-header';
import AccountTable from './account-table';

function AccountTab() {
  return (
    <div>
      <AccountHeader />
      <AccountTable />
    </div>
  );
}

export default AccountTab;
