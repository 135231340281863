import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useDebounce } from '../../common/methods/debounce';
import {
  COMMON_SEARCH_CLASSES,
  EndIcon,
  ErrorMessage,
  LastOption,
  NoOptionsFound,
  OptionLayout,
  OptionListLayout,
  RetryComponent,
  SearchSelectLayout,
  TOption,
  useSelectState,
} from './common';
import SearchShimmerLoading from './search-shimmer-loading';
import './styles.css';

interface IBaSelectProps {
  optionList: TOption[] | undefined;
  setSelected: (option: TOption | null) => void;
  placeholder?: string;
  selectedItem?: TOption | null;
  lastOption?: TOption;
  isLoading?: boolean;
  error?: string;
  onRefetch?: (name: string) => void;
  startIcon?: React.ReactElement;
  onSearchChange?: (text: string | undefined) => void;
  isNetworkError?: boolean;
  resetOptions?: () => void;
  disabled?: boolean;
  disabledOptionMessage?: string;
  noOptionsMessage?: string;
}

function SearchAndSelect(props: IBaSelectProps) {
  const {
    placeholder = 'Please select...',
    optionList,
    isLoading,
    setSelected,
    selectedItem,
    lastOption,
    error,
    onRefetch,
    startIcon,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSearchChange = () => {},
    isNetworkError,
    resetOptions,
    disabled = false,
    noOptionsMessage,
  } = props;
  const searchRef = useRef<HTMLInputElement>(null);

  const refetchCall = useCallback(() => {
    if (typeof onRefetch === 'function') {
      onRefetch(searchRef?.current?.value || '');
    }
  }, [onRefetch]);

  const {
    onSelect,
    onLastOption,
    openModal,
    handleClose,
    isOpen,
    searchTerm,
    inputContainerRef,
    setSearchTerm,
    setIsOpen,
  } = useSelectState({
    isLoading,
    setSelected,
    lastOption,
    disabled,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!searchTerm) {
      inputContainerRef?.current?.setAttribute('data-name', '');
    }
    setSearchTerm(value);
  };
  const clearText = useCallback(() => {
    setSearchTerm('');
    searchRef.current?.focus();
    if (typeof resetOptions === 'function') resetOptions();
  }, []);
  const debouncedSearch = useDebounce((text: string) => {
    onSearchChange(text);
  }, 900);

  useEffect(() => {
    if (searchTerm !== undefined) {
      debouncedSearch(searchTerm);
      if (searchTerm !== '') {
        setIsOpen(true);
      }
    }
  }, [debouncedSearch, searchTerm]);

  return (
    <div className="relative">
      <SearchSelectLayout
        optionList={optionList}
        isOpen={isOpen}
        error={error}
        openModal={openModal}
        isLoading={isLoading}
      >
        <div
          className={clsx(
            'flex flex-1 items-center space-x-2 truncate ',
            !searchTerm && 'focus-within:opacity-50',
          )}
        >
          <>
            <div className="flex max-h-5 truncate">{startIcon}</div>
            <div
              ref={inputContainerRef}
              className="ba-search-select-container flex flex-1 items-center"
              data-name={selectedItem?.name}
            >
              <input
                ref={searchRef}
                value={searchTerm}
                placeholder={selectedItem?.name ? '' : placeholder}
                type="text"
                autoFocus={false}
                onChange={handleChange}
                className={COMMON_SEARCH_CLASSES}
              />
              {!!searchTerm?.length && !isLoading && (
                <FiXCircle
                  onClick={clearText}
                  className="mr-1 cursor-pointer text-14 text-mid-200 hover:text-warning"
                />
              )}
              <EndIcon
                isNetworkError={!!isNetworkError}
                isLoading={!!isLoading}
                error={error}
                // onRefetch={onRefetch}
              />
            </div>
          </>
        </div>
        {isNetworkError && (
          <RetryComponent error={error} onRefetch={refetchCall} />
        )}
      </SearchSelectLayout>
      {isOpen && (optionList || isLoading) && (
        <OptionListLayout onClickAway={handleClose}>
          {isLoading ? (
            <div className="w-full">
              {[1, 2, 3].map((key) => (
                <SearchShimmerLoading key={key} />
              ))}
            </div>
          ) : (
            <>
              {optionList?.length === 0 ? (
                <NoOptionsFound>{noOptionsMessage}</NoOptionsFound>
              ) : (
                <>
                  {optionList?.map(({ id, name, logo, endField }) => (
                    <OptionLayout
                      key={id}
                      handleClose={handleClose}
                      setSelected={onSelect}
                      id={id}
                      name={name}
                      logo={logo}
                      endField={endField}
                    />
                  ))}
                </>
              )}
            </>
          )}
          {!!optionList?.length && lastOption && (
            <LastOption onLastOption={onLastOption} name={lastOption?.name} />
          )}
        </OptionListLayout>
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default SearchAndSelect;
