import { Typography } from '../../../../components';

interface StatusCardLabelProps {
  label: string;
}

function StatusCardLabel({ label }:StatusCardLabelProps) {
  return (
    <Typography variant="body-13-medium" className="pt-4 pb-2 text-xs font-medium text-mid">
      {label}
    </Typography>
  );
}

export default StatusCardLabel;
