/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetOwnerAndCoOwnerOpportunitiesQueryVariables = Types.Exact<{
  input: Types.GetOpportunityForOwnerAndCoOwnerInput;
}>;

export type GetOwnerAndCoOwnerOpportunitiesQuery = {
  __typename?: 'Query';
  opportunityQuery?: {
    __typename?: 'OpportunityQuery';
    getOpportunityForOwnerAndCoOwner:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'OpportunityConnection';
          edges?: Array<{
            __typename?: 'OpportunityEdge';
            node?:
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: Types.ErrorType;
                  helpLink?: string;
                }
              | {
                  __typename: 'Opportunity';
                  id: string;
                  accountId: string;
                  name: string;
                };
          }>;
        };
  };
};

export const GetOwnerAndCoOwnerOpportunitiesDocument = `
    query GetOwnerAndCoOwnerOpportunities($input: getOpportunityForOwnerAndCoOwnerInput!) {
  opportunityQuery {
    getOpportunityForOwnerAndCoOwner(input: $input) {
      __typename
      ...CoreErrorFields
      ... on OpportunityConnection {
        edges {
          node {
            __typename
            ...CoreErrorFields
            ... on Opportunity {
              id
              accountId
              name
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetOwnerAndCoOwnerOpportunities: build.query<
      GetOwnerAndCoOwnerOpportunitiesQuery,
      GetOwnerAndCoOwnerOpportunitiesQueryVariables
    >({
      query: (variables) => ({
        document: GetOwnerAndCoOwnerOpportunitiesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOwnerAndCoOwnerOpportunitiesQuery,
  useLazyGetOwnerAndCoOwnerOpportunitiesQuery,
} = injectedRtkApi;
