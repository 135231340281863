import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_PLACEHOLDER_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query listPlaceholders(
    $operations: Operations
  ) {
    memberQuery {
      listPlaceholders(operations: $operations) {
        __typename
        ...CoreErrorFields
        ...on PlaceholderConnection {
            edges {
      node {
        __typename
        ...CoreErrorFields
        ... on Placeholder {
          id
          name
          description
          companyType
        }
      }
    }
          }
        }
      }
    }
`;
