import type { Dispatch } from '@reduxjs/toolkit';
import clsx from 'clsx';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChartIcon from './Chart.svg';
import OrgChartIcon from './org-charts.svg';
// import SettingsIcon from './Settings.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useMemo } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useGetOrganizationQuery } from '../../alignx/Header/org-api';
import {
  ALL_TASKS_PATH,
  DASHBOARD_PATH,
  RMAP_PATH,
} from '../../alignx/dashboard/tab-details';
import { ITabDetail } from '../../alignx/msp-view/model';
import NotificationContainer from '../../alignx/msp-view/notification/notification-container';
import { BaCard } from '../../ba-components';
import { ErrorBoundary, InfiniteLoader } from '../../components';
import { Company, Logo, Organization, UserType } from '../../generated-types';
import { orgIdExtractor } from '../../utils';
import { setSidebarStatus } from '../redux-store/reducers/app-reducer';
import { RootState } from '../redux-store/redux-types';
import CheckIcon from './Check.svg';
import { NavLink, NavTitleCard } from './nav-link';
import UserMenu from './user-menu';
import { useSortedTabList } from '../hooks/use-sorted-tabs';

const ICON_CLASSES = 'rounded-full bg-mid-900 p-1.5 text-11 text-white';

export const NAV_DETAILS = new Map<string, { path: string; icon: string }>();
NAV_DETAILS.set('msp', { path: DASHBOARD_PATH, icon: ChartIcon as string });
NAV_DETAILS.set('rMap', { path: RMAP_PATH, icon: OrgChartIcon as string });
NAV_DETAILS.set('allTasks', {
  path: ALL_TASKS_PATH,
  icon: CheckIcon as string,
});

const defaultTabs = [
  {
    name: 'msp',
    label: 'Mutual Success Plans',
    isEnabled: true,
    isDefault: true,
    order: 100000,
    subTabs: [],
    isInternal: false,
    value: 'msp',
  },
  {
    name: 'allTasks',
    label: 'All Tasks',
    isEnabled: true,
    isDefault: false,
    order: 200000,
    subTabs: [],
    isInternal: false,
    value: 'allTasks',
  },
  // {
  //   name: 'rMap',
  //   label: 'Relationship Map',
  //   isEnabled: true,
  //   isDefault: false,
  //   order: {
  //     $numberInt: '300000',
  //   },
  //   subTabs: [
  //     {
  //       name: 'msp',
  //       label: 'MSP Dashboard',
  //       isEnabled: true,
  //       isDefault: true,
  //       order: {
  //         $numberInt: '100000',
  //       },
  //       subTabs: [],
  //       isInternal: false,
  //       value: 'rMap_msp',
  //     },
  //     {
  //       name: 'accountPlans',
  //       label: 'Account Plans Dashboard',
  //       isEnabled: true,
  //       isDefault: false,
  //       order: {
  //         $numberInt: '200000',
  //       },
  //       subTabs: [],
  //       isInternal: false,
  //       value: 'rMap_accountPlans',
  //     },
  //   ],
  //   isInternal: false,
  //   value: 'rMap',
  // },
];

const mapStateToProps = (state: RootState) => {
  return {
    orgLogoUrl: ((state?.organization?.company as Company)?.logo as Logo)?.url,
    orgLogo: (state?.organization as Organization)?.orgLogo?.original,
    isSidebarOpen: state.appState.isSidebarOpen,
    insideSalesforceStatus: state?.msp?.insideSalesforceStatus,
    tabList: (state?.organization as Organization)?.configs?.others
      ?.dashboardTabs as ITabDetail[],
    isBuyer: state.userInfo?.userDetails?.userType === UserType.BUYER,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setSidebarStatus }, dispatch);
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SideNavigation(props: PropsFromRedux) {
  const {
    orgLogoUrl,
    orgLogo,
    setSidebarStatus,
    isSidebarOpen,
    insideSalesforceStatus,
    tabList = defaultTabs as ITabDetail[],
    isBuyer,
  } = props;
  const sellerCompanyLogo = orgLogo || orgLogoUrl;
  const history = useHistory();
  const { isLoading } = useGetOrganizationQuery({
    orgId: orgIdExtractor(),
  });

  const sortedTabList = useSortedTabList({ tabList, isBuyer });

  return (
    <ErrorBoundary title="Side navigation">
      <ClickAwayListener onClickAway={() => setSidebarStatus(false)}>
        <aside
          className={clsx(
            'fixed flex h-screen flex-col justify-between bg-neutral-700',
            isSidebarOpen ? 'w-60' : 'w-14',
          )}
        >
          <BaCard.Root openDelay={0} closeDelay={0}>
            <BaCard.Trigger asChild>
              <div
                onClick={() => setSidebarStatus(!isSidebarOpen)}
                className={clsx(
                  'fixed top-11  flex cursor-pointer items-center rounded-full bg-white p-[3px]',
                  isSidebarOpen ? 'left-[226px]' : 'left-[42px]',
                )}
              >
                {isSidebarOpen ? (
                  <FiX className={ICON_CLASSES} />
                ) : (
                  <FiMenu className={ICON_CLASSES} />
                )}
              </div>
            </BaCard.Trigger>
            <NavTitleCard title={isSidebarOpen ? 'Collapse' : 'Expand'} />
          </BaCard.Root>

          <section>
            <section
              className="mb-5 cursor-pointer "
              onClick={() => history.push('/ui/alignx')}
            >
              {isLoading ? (
                <div className="my-4 mx-auto">
                  <InfiniteLoader width={60} color="white" />
                </div>
              ) : (
                <img
                  src={sellerCompanyLogo}
                  alt="home"
                  className="m-4 h-6 w-6 rounded-sm object-cover object-left"
                />
              )}
            </section>
            <section className="flex flex-col space-y-4 pt-3">
              {sortedTabList?.map((tab) => (
                <NavLink
                  key={tab.label}
                  title={tab.label}
                  iconSrc={NAV_DETAILS.get(tab.name)?.icon}
                  pathname={NAV_DETAILS.get(tab.name)?.path}
                />
              ))}

              <div className="mx-2 flex border-0 border-t border-solid border-mid-300" />
              {/* <NavLink title="Settings" iconSrc={SettingsIcon as string} /> */}
              {!insideSalesforceStatus && <NotificationContainer />}
            </section>
          </section>
          {!insideSalesforceStatus && (
            <div className="m-3">
              <UserMenu />
            </div>
          )}
        </aside>
      </ClickAwayListener>
    </ErrorBoundary>
  );
}

export default connector(SideNavigation);
