/* eslint-disable operator-linebreak */
import { ApolloClient, gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { fetchCall } from '../../common/methods/fetch-call';
import { TOption } from '../../components/ba-select/common';
import {
  Company,
  CompanyConnection,
  CompanyQuery,
  Msp,
  MspConnection,
  Operation,
  Opportunity,
  OpportunityConnection,
  OpportunityQuery,
  TemplateQuery,
} from '../../generated-types';
import { CORE_GRAPH_ERROR } from '../../graphql/fragments/error';
import { getCompanyDefaultLogoUrl } from '../../utils';
import { ICompanyResponse } from './models';

const opportunityVariables = {
  input: {
    operations: {
      sort: [
        {
          order: 'ASC',
          field: 'opportunity.forecastCategory',
        },
      ],
    },
  },
};

const GET_OPPORTUNITIES = gql`
  ${CORE_GRAPH_ERROR}
  query GetOpportunities($input: getOpportunityForOwnerAndCoOwnerInput!) {
    opportunityQuery {
      getOpportunityForOwnerAndCoOwner(input: $input) {
        __typename
        ...CoreErrorFields
        ... on OpportunityConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Opportunity {
                id
                accountId
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_MSP_TEMPLATE_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query GetMspTemplateList_ChooseTemplate {
    templateQuery {
      searchMspTemplates {
        __typename
        ...CoreErrorFields
        ... on MspConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Msp {
                id
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;

type ResultTemplate = {
  templateQuery: TemplateQuery;
};

export const fetchTemplates = createAsyncThunk(
  'create-msp/get-templates',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data } = await client.query<ResultTemplate>({
        query: GET_MSP_TEMPLATE_LIST,
        variables: {},
      });
      if (data.templateQuery.searchMspTemplates.__typename === 'GraphqlError') {
        throw new Error('Something went wrong while getting templates.');
      }

      return (
        (data?.templateQuery?.searchMspTemplates as MspConnection)?.edges?.map(
          (item) => item?.node as Msp,
        ) || []
      );
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

type ResultOpportunityQuery = {
  opportunityQuery: OpportunityQuery;
};

export const fetchOpportunities = createAsyncThunk(
  'create-msp/get-opportunities',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data } = await client.query<ResultOpportunityQuery>({
        query: GET_OPPORTUNITIES,
        variables: opportunityVariables,
      });
      if (
        data?.opportunityQuery?.getOpportunityForOwnerAndCoOwner?.__typename ===
        'GraphqlError'
      ) {
        throw new Error('Something went wrong while getting opportunities.');
      }

      const oppList: TOption[] | Opportunity[] =
        (
          data?.opportunityQuery
            ?.getOpportunityForOwnerAndCoOwner as OpportunityConnection
        )?.edges?.map((edge) => {
          const opportunity = edge?.node as Opportunity;
          if (opportunity) {
            return {
              id: opportunity?.id,
              name: opportunity?.name,
              accountId: opportunity?.accountId,
              isOptionDisabled: !opportunity?.accountId,
            };
          }

          return {} as Opportunity;
        }) || [];

      return oppList;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

const defaultLogoUrl = getCompanyDefaultLogoUrl();

export const fetchCompanies = createAsyncThunk(
  'create-msp/get-companies',
  async (
    {
      companyName,
      trackEvent,
    }: { companyName: string; trackEvent: (type: string, orgs: any) => void },
    { rejectWithValue },
  ) => {
    try {
      const apiData = await fetchCall({
        method: 'POST',
        url: '/v1/cura/companies/_suggest',
        data: { name: companyName },
      });
      const data = (await apiData?.json()) as ICompanyResponse;
      const list = data?.response?.map(({ domain, name, logo }) => ({
        name,
        logo,
        id: domain,
        endField: domain,
      })) as TOption[];

      trackEvent(TRACKING_CONSTANTS.CREATE_MSP_SEARCH_COMPANY, {
        companyName,
      });

      if (list.length) return list;

      return [
        {
          name: companyName,
          logo: defaultLogoUrl,
          id: companyName,
        },
      ] as TOption[];
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

const GET_COMPANY = gql`
  ${CORE_GRAPH_ERROR}
  query searchCompany($operations: Operations!) {
    companyQuery {
      searchCompany(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on CompanyConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on Company {
                id
                companyName
              }
            }
          }
        }
      }
    }
  }
`;

type ResultCompanyDetailsQuery = {
  companyQuery: CompanyQuery;
};

export const fetchCompanyDetails = createAsyncThunk(
  'create-msp/get-company-details',
  async (
    { client, accountId }: { client: ApolloClient<object>; accountId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultCompanyDetailsQuery>({
        query: GET_COMPANY,
        variables: {
          operations: {
            filter: {
              operator: 'AND',
              filters: {
                op: Operation.EQ,
                values: [accountId],
                field: 'company.extId',
              },
            },
          },
        },
      });
      if (data?.companyQuery?.searchCompany?.__typename === 'GraphqlError') {
        throw new Error('Something went wrong while getting opportunities.');
      }

      const company = (data?.companyQuery?.searchCompany as CompanyConnection)
        ?.edges?.[0];

      if (company) return company?.node;

      return {} as Company;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);
