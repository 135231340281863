import { SvgIcon, SvgIconProps } from '@mui/material';

export default function InsightsIcon({ color, ...props }: Omit<SvgIconProps, 'color'> & { color?: string }) {
  return (
    <SvgIcon {...props}>
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.41224 10.148C4.29137 10.1476 4.18199 10.076 4.13355 9.96515L3.4639 8.42921C3.15195 7.71024 2.57852 7.13669 1.85975 6.82441L0.323689 6.15467C0.211969 6.10668 0.139648 5.99686 0.139648 5.87532C0.139648 5.75378 0.211969 5.64384 0.323689 5.59596L1.85975 4.92622C2.57838 4.61405 3.15172 4.04062 3.4639 3.322L4.13355 1.78594V1.78605C4.18199 1.67489 4.2917 1.60291 4.41302 1.60291C4.53422 1.60291 4.64393 1.67489 4.69237 1.78605L5.36203 3.32211V3.322C5.6742 4.04062 6.24752 4.61408 6.96617 4.92622L8.50224 5.59596C8.61396 5.64384 8.68628 5.75378 8.68628 5.87532C8.68628 5.99686 8.61396 6.10668 8.50224 6.15467L6.96617 6.82441C6.24743 7.13669 5.67397 7.71024 5.36203 8.42921L4.69237 9.96526V9.96515C4.64371 10.0764 4.53366 10.1482 4.41223 10.148L4.41224 10.148Z" fill="currentColor" />
        <path d="M10.7679 15.0311C10.6707 15.0311 10.5827 14.9735 10.5439 14.8843L10.1403 13.96C9.95864 13.5419 9.62505 13.2083 9.20685 13.0266L8.2825 12.6237C8.19344 12.5849 8.13574 12.4969 8.13574 12.3997C8.13574 12.3025 8.19344 12.2146 8.2825 12.1757L9.2075 11.7732C9.62548 11.5912 9.95885 11.2575 10.1403 10.8394L10.5432 9.91509C10.5822 9.82603 10.67 9.76855 10.7672 9.76855C10.8643 9.76855 10.9523 9.82603 10.9912 9.91509L11.3941 10.8394C11.5758 11.2579 11.9096 11.5916 12.3282 11.7732L13.2525 12.1761C13.3414 12.215 13.399 12.3029 13.399 12.4001C13.399 12.4972 13.3414 12.5851 13.2525 12.6241L12.3282 13.027C11.9101 13.2087 11.5765 13.5423 11.3949 13.9603L10.992 14.8847C10.953 14.9737 10.865 15.0312 10.7679 15.0311L10.7679 15.0311Z" fill="currentColor" />
        <path d="M12.686 4.57509C12.6053 4.57509 12.5322 4.52732 12.4998 4.45333L12.2211 3.81339L12.221 3.81328C12.0989 3.53202 11.8745 3.30759 11.5932 3.18548L10.9533 2.90657C10.879 2.87432 10.8311 2.8011 10.8311 2.7203C10.8311 2.63939 10.879 2.56617 10.9533 2.53392L11.5932 2.25535C11.8741 2.13347 12.0983 1.90957 12.2209 1.629L12.4998 0.989062V0.98895C12.5322 0.914956 12.6053 0.867188 12.686 0.867188C12.7668 0.867188 12.84 0.915067 12.8722 0.989173L13.1508 1.62912C13.2731 1.91037 13.4976 2.13469 13.7788 2.25703L14.418 2.53538C14.4922 2.56763 14.5401 2.64085 14.5401 2.72176C14.5401 2.80256 14.4922 2.87578 14.418 2.90803L13.778 3.18694C13.4967 3.30903 13.2722 3.53348 13.15 3.81474L12.8717 4.45468L12.8716 4.45479C12.8389 4.528 12.7662 4.5751 12.686 4.5751L12.686 4.57509Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
}
