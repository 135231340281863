/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetAccountPlanByExtCompanyIdQueryVariables = Types.Exact<{
  extId: Types.Scalars['ID'];
}>;

export type GetAccountPlanByExtCompanyIdQuery = {
  __typename?: 'Query';
  accountPlanQuery?: {
    __typename?: 'AccountPlanQuery';
    getAccountPlanByExtCompanyId:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Array<
            | {
                __typename?: 'AccountPlan';
                id: string;
                extCompanyId: string;
                companyId: string;
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const GetAccountPlanByExtCompanyIdDocument = `
    query getAccountPlanByExtCompanyId($extId: ID!) {
  accountPlanQuery {
    getAccountPlanByExtCompanyId(extCompanyId: $extId) {
      ...CoreErrorFields
      ... on AccountPlanPayload {
        items {
          ... on AccountPlan {
            id
            extCompanyId
            companyId
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getAccountPlanByExtCompanyId: build.query<
      GetAccountPlanByExtCompanyIdQuery,
      GetAccountPlanByExtCompanyIdQueryVariables
    >({
      query: (variables) => ({
        document: GetAccountPlanByExtCompanyIdDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAccountPlanByExtCompanyIdQuery,
  useLazyGetAccountPlanByExtCompanyIdQuery,
} = injectedRtkApi;
