import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { FiAlertOctagon, FiChevronDown, FiRotateCcw } from 'react-icons/fi';
import { CustomToolTip } from '../tool-tip/tooltip';
import { Typography } from '../typography';

export type TOption = {
  id: string;
  name: string;
  logo?: string;
  endField?: string;
  startIcon?: ReactElement;
  isOptionDisabled?: boolean;
};

export const OPTION_CLASSES =
  'py-3 px-5 hover:bg-primary-100 cursor-pointer rounded-md';
export function NoOptionsFound({ children }: { children?: React.ReactNode }) {
  return (
    <Typography
      variant="body-13-regular"
      className="rounded-md py-3 px-5 text-mid-400"
    >
      {children || 'No matching Templates found.'}
    </Typography>
  );
}

export function LastOption({
  name,
  onLastOption,
}: {
  name: string;
  onLastOption: () => void;
}) {
  return (
    <Typography
      onClick={onLastOption}
      variant="body-13-regular"
      className={`border-0 border-t border-solid border-gray-100 text-error ${OPTION_CLASSES}`}
    >
      {name}
    </Typography>
  );
}

const COMMON_LAYOUT_CLASSES =
  'flex box-border items-center justify-between px-2 cursor-pointer';
const OPEN_MODAL_CLASSES =
  'border-t-primary border-r-primary border-l-primary border-b-0 border border-solid rounded-t-md';
const CLOSE_MODAL_CLASSES = 'border-gray-200 border border-solid rounded-md';

export function SelectLayout({
  children,
  isOpen,
  error,
  openModal,
  inputClassName,
  heightClass,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  error?: string;
  openModal: () => void;
  inputClassName?: string;
  heightClass?: string;
}) {
  return (
    <div
      onClick={openModal}
      role="presentation"
      className={clsx(
        isOpen ? OPEN_MODAL_CLASSES : CLOSE_MODAL_CLASSES,
        error && 'border-b-error',
        COMMON_LAYOUT_CLASSES,
        inputClassName,
        heightClass ?? 'h-11',
      )}
    >
      {children}
    </div>
  );
}

export function SearchSelectLayout({
  children,
  isOpen,
  error,
  openModal,
  optionList,
  isLoading,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  error?: string;
  openModal: () => void;
  optionList?: TOption[] | undefined;
  isLoading?: boolean | undefined;
}) {
  return (
    <div
      onClick={openModal}
      role="presentation"
      className={clsx(
        // eslint-disable-next-line no-nested-ternary
        isOpen && optionList
          ? OPEN_MODAL_CLASSES
          : isOpen && isLoading
          ? OPEN_MODAL_CLASSES
          : `${CLOSE_MODAL_CLASSES} focus-within:border-primary`,
        isLoading && isOpen
          ? 'rounded-b-none focus-within:border-b-transparent'
          : '',
        error && 'border-b-error',
        COMMON_LAYOUT_CLASSES,
      )}
    >
      {children}
    </div>
  );
}

export function OptionListLayout({
  children,
  onClickAway,
}: {
  children: React.ReactNode;
  onClickAway: () => void;
}) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="absolute z-10 box-border max-h-60 w-full overflow-y-auto rounded-b-md border-0 border-b border-l border-r border-solid border-primary bg-white shadow-500">
        {children}
      </div>
    </ClickAwayListener>
  );
}

export function ErrorMessage({ error }: { error: string }) {
  return (
    <Typography
      element="div"
      variant="label-12-regular"
      className="bg-transparent px-2 text-error"
    >
      {error}
    </Typography>
  );
}
type TOptionLayoutProps = {
  id: string;
  name: string;
  logo?: string;
  endField?: string;
  handleClose: () => void;
  setSelected: (option: TOption) => void;
  startIcon?: ReactElement;
  isOptionDisabled?: boolean;
  disabledOptionMessage?: string;
};
export function OptionLayout({
  id,
  name,
  logo,
  endField,
  handleClose,
  setSelected,
  startIcon,
  isOptionDisabled,
  disabledOptionMessage,
}: TOptionLayoutProps) {
  const isMethodologyOptions = id.split('-')?.[0] === 'methodology';
  return (
    <div
      key={id}
      className={clsx(
        'grid grid-cols-8 items-center justify-between gap-1',
        isOptionDisabled ? 'cursor-not-allowed py-3 px-5' : OPTION_CLASSES,
      )}
      onClick={() => {
        if (!isOptionDisabled) {
          handleClose();
          setSelected({
            id,
            name,
            logo,
            endField,
          });
        }
      }}
      role="presentation"
    >
      <div
        className={`flex items-center space-x-2 ${
          endField || isOptionDisabled ? 'col-span-5' : 'col-span-8'
        }`}
      >
        {startIcon}
        {logo && (
          <img className="max-h-5 truncate rounded-md" src={logo} alt={name} />
        )}
        <CustomToolTip title={name} showTruncated={!isMethodologyOptions}>
          <div>
            <Typography
              className={clsx(
                isOptionDisabled ? 'text-mid-300' : 'text-neutral',
                !isMethodologyOptions ? 'truncate' : '',
              )}
              variant="body-13-regular"
            >
              {name}
            </Typography>
          </div>
        </CustomToolTip>
      </div>
      {endField && (
        <div className="col-span-3">
          <CustomToolTip title={endField || ''} showTruncated>
            <Typography
              className="truncate text-right text-mid-300"
              variant="body-13-regular"
            >
              {endField}
            </Typography>
          </CustomToolTip>
        </div>
      )}
      {isOptionDisabled && (
        <Typography
          element="div"
          className="col-span-3 truncate text-right text-error-300"
          variant="label-12-regular"
        >
          {disabledOptionMessage || 'Disabled'}
        </Typography>
      )}
    </div>
  );
}

export function EndIcon({
  isLoading,
  error,
  onRefetch,
  isNetworkError,
}: {
  isLoading: boolean;
  error?: string;
  onRefetch?: () => void;
  isNetworkError: boolean;
}) {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (typeof onRefetch === 'function') onRefetch();
    },
    [onRefetch],
  );
  if (isLoading) return <CircularProgress size={16} />;

  if (error && isNetworkError) {
    return (
      <CustomToolTip title="Retry again.">
        <div className="ml-1 flex items-center">
          <FiRotateCcw
            className="cursor-pointer hover:text-primary"
            onClick={onClick}
          />
        </div>
      </CustomToolTip>
    );
  }

  return <FiChevronDown className="text-mid-400" />;
}

export function RetryComponent({
  error,
  onRefetch,
}: {
  error?: string;
  onRefetch?: () => void;
}) {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (typeof onRefetch === 'function') onRefetch();
    },
    [onRefetch],
  );

  if (error) {
    return (
      <CustomToolTip title="Retry again.">
        <div className="flex items-center">
          <FiRotateCcw
            className="ml-1 cursor-pointer hover:text-primary"
            onClick={onClick}
          />
        </div>
      </CustomToolTip>
    );
  }

  return null;
}

interface IUseSelectProps {
  isLoading?: boolean;
  lastOption?: TOption;
  setSelected: (option: TOption | null) => void;
  disabled?: boolean;
}

export function useSelectState({
  isLoading,
  lastOption,
  setSelected,
  disabled,
}: IUseSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const openModal = useCallback(() => {
    if (!isLoading && !disabled) {
      setIsOpen(true);
    }
  }, [isLoading]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onLastOption = useCallback(() => {
    handleClose();
    if (lastOption) {
      setSelected(lastOption);
      setSearchTerm('');
    }
  }, [handleClose, lastOption, setSelected]);

  const onSelect = useCallback(
    ({ id, name, logo, endField }: TOption) => {
      setSearchTerm('');
      setSelected({
        id,
        name,
        logo,
        endField,
      });
      inputContainerRef?.current?.setAttribute('data-name', name);
    },
    [setSelected],
  );

  return {
    onSelect,
    onLastOption,
    openModal,
    handleClose,
    isOpen,
    searchTerm,
    inputContainerRef,
    setSearchTerm,
    setIsOpen,
  };
}

export const COMMON_SEARCH_CLASSES =
  'placeholder:font-extralight placeholder-mid-400 text-13 h-auto box-border w-full truncate placeholder:text-13 border-0 focus:outline-none rounded py-2 bg-white';
