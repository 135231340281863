import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

const RootWithModal: React.FC<DropdownMenu.DropdownMenuProps> =
  DropdownMenu.Root;

const Root = ({
  children,
  ...props
}: { children: ReactNode } & DropdownMenu.DropdownMenuProps) => (
  <DropdownMenu.Root {...props} modal={false}>
    {children}
  </DropdownMenu.Root>
);

const Trigger: React.ForwardRefExoticComponent<
  DropdownMenu.DropdownMenuTriggerProps & React.RefAttributes<HTMLButtonElement>
> = DropdownMenu.Trigger;

const MenuSeparator: React.FC<DropdownMenu.DropdownMenuSeparatorProps> =
  DropdownMenu.Separator;

const Portal: React.FC<DropdownMenu.DropdownMenuPortalProps> =
  DropdownMenu.Portal;

interface IDropDownItemProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
  paddingclass?: string;
}

const Content = ({
  children,
  className,
  paddingclass = 'py-2',
  ...props
}: IDropDownItemProps & DropdownMenu.DropdownMenuContentProps) => (
  <DropdownMenu.Content
    {...props}
    className={clsx(
      'rounded-lg bg-white shadow-i-map-filter-card',
      className,
      paddingclass,
    )}
  >
    {children}
  </DropdownMenu.Content>
);

const DROP_DOWN_LAYOUT = 'flex items-center';
function Item({
  children,
  paddingclass = 'px-4 py-2',
  className,
  ...props
}: IDropDownItemProps & DropdownMenu.DropdownMenuItemProps) {
  return (
    <DropdownMenu.Item
      {...props}
      className={clsx(
        paddingclass,
        className,
        DROP_DOWN_LAYOUT,
        'ba-dropdown-menu-item cursor-pointer hover:bg-primary-light-bg focus-visible:outline-none',
      )}
    >
      {children}
    </DropdownMenu.Item>
  );
}

const Arrow: React.ForwardRefExoticComponent<
  DropdownMenu.DropdownMenuArrowProps & React.RefAttributes<SVGSVGElement>
> = DropdownMenu.Arrow;

const WhiteArrow = (props: DropdownMenu.DropdownMenuArrowProps) => (
  <Arrow {...props} className="fill-white" />
);

export const DropDownMenu = {
  WhiteArrow,
  RootWithModal,
  Root,
  Trigger,
  MenuSeparator,
  Portal,
  Content,
  Item,
};
