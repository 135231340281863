import Chip from '@mui/material/Chip';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { CustomToolTip, Typography } from '..';
import { RootState } from '../../common/redux-store';
import {
  clearAllFilters,
  resetFilters,
  unSelectFilter,
} from '../filters/filter-redux';
import { CrossIcon } from '../icons';

const mapState = (state: RootState) => ({
  reduxSelectedFilters: state?.filters?.selectedFilters,
  filterDetails: state?.dashboard?.filterDetails,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      unSelectFilter,
      clearAllFilters,
      resetFilters,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector> & {
  sectionName: string;
  onClearAll?: () => void;
};
function FilterChips(props: PropsFromRedux) {
  const {
    reduxSelectedFilters,
    unSelectFilter,
    clearAllFilters,
    resetFilters,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClearAll = () => {},
    sectionName,
  } = props;
  const selectedFilters = reduxSelectedFilters[sectionName];
  const filterList = selectedFilters ? Object.keys(selectedFilters) : [];
  const handleDelete = (value: string, key: string) => {
    unSelectFilter({ value, key, sectionName });
  };

  if (Array.isArray(filterList) && filterList.length) {
    return (
      <div className="chips-section flex flex-wrap items-center space-x-2">
        {filterList.map((key) => {
          const list = selectedFilters[key]?.filter(
            ({ isSelected }) => isSelected,
          );
          return (
            <>
              <div className="inline-flex items-center space-x-2 rounded border border-solid border-light-bg px-2 hover:border-mid-300">
                <Typography variant="body-13-regular" element="span">
                  {key}:
                </Typography>
                <div className="inline-flex flex-wrap items-center space-x-2 py-0.5">
                  {list?.map(({ value, label }) => (
                    <Chip
                      key={value}
                      onDelete={(e) => handleDelete(value ?? '', key)}
                      label={label}
                      className="capitalize"
                      variant="outlined"
                      deleteIcon={<CrossIcon className="font-12" />}
                    />
                  ))}
                  <CustomToolTip
                    arrow
                    placement="top"
                    title={`Clear "${key}" filters`}
                  >
                    <div className="item-clear flex">
                      <CrossIcon
                        className="font-18 cursor-pointer rounded-full border border-solid border-mid-100 p-1 text-error hover:bg-mid-50"
                        onClick={() =>
                          clearAllFilters({
                            filterType: key,
                            sectionName,
                          })
                        }
                      />
                    </div>
                  </CustomToolTip>
                </div>
              </div>
              <span className="h-8 border-0 border-l border-solid border-gray-300" />
            </>
          );
        })}
        <Typography
          variant="body-13-regular"
          className="cursor-pointer rounded p-2 text-error hover:bg-mid-50"
          onClick={() => {
            resetFilters({ sectionName });
            onClearAll();
          }}
        >
          Clear all
        </Typography>
      </div>
    );
  }

  return null;
}

export default connector(FilterChips);
