import { gql } from '@apollo/client';

export const CORE_GRAPH_ERROR = gql`
  fragment CoreErrorFields on GraphqlError {
    code
    message
    type
    helpLink
  }
`;
