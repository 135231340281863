import { gql, useMutation } from '@apollo/client';
import {
  TaskMutationUpdateTaskArgs,
  TaskPayloadResponse,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

export interface UpdateTask {
  variables: TaskMutationUpdateTaskArgs;
}

export const UPDATE_TASK = gql`
  ${CORE_GRAPH_ERROR}
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    taskMutation {
      updateTask(id: $id, input: $input) {
        __typename
        ...CoreErrorFields
        ... on TaskPayload {
          items {
            ...CoreErrorFields
            ... on Task {
              id
              name
              dueDate
              description
            }
          }
        }
      }
    }
  }
`;
export const BULK_UPDATE_TASK = gql`
  ${CORE_GRAPH_ERROR}
  mutation BulkUpdateTask($input: [UpdateTaskInput!]!) {
    taskMutation {
      bulkUpdateTasks(input: $input) {
        __typename
        ...CoreErrorFields
        ... on TaskPayload {
          items {
            ...CoreErrorFields
            ... on Task {
              id
              name
              dueDate
            }
          }
        }
      }
    }
  }
`;

export type UpdateTaskResult = {
  taskMutation: { updateTask: TaskPayloadResponse };
};
export type BulkUpdateTaskResult = {
  taskMutation: { bulkUpdateTasks: TaskPayloadResponse };
};
