/* eslint-disable max-len */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { capitalizeString } from '../../../common/utilities/utils';
import {
  CompanyType,
  GraphqlError,
  Maybe,
  Members,
  Placeholder,
  Role,
  RoleConnection,
  RoleMembers,
  Stakeholder,
  UserType,
} from '../../../generated-types';
import {
  ResultListRoles,
  assignPlaceholder,
  fetchRoleList,
  removePlaceholder,
  removeStakeholder,
  replacePlaceholderWithStakeholder,
  updateRole,
} from './api-call';

export interface GqlError extends GraphqlError {
  id: string;
}

export interface IRole extends Role {
  memberIds: string[];
}

export interface IStakeholder extends Stakeholder {
  userType: UserType;
  orgId?: string;
}

export interface IMembers {
  [key: string]: IStakeholder;
}

interface IDefaultStakeholder {
  buyerRoles: IRole[];
  sellerRoles: IRole[];
  searchText: string;
  members: {
    [key: string]: IStakeholder;
  };
  isHidden: {
    [key: string]: boolean;
  };
}

const defaultMyTasks: IDefaultStakeholder = {
  buyerRoles: [],
  sellerRoles: [],
  searchText: '',
  members: {},
  isHidden: {},
};

function transformRoleListResponse(response: ResultListRoles) {
  const tempRoleLists = response?.roleQuery?.listRoles;
  const roles = [] as Array<IRole>;
  let buyerRoles = [] as Array<IRole>;
  let sellerRoles = [] as Array<IRole>;
  const members: IMembers = {};

  if (tempRoleLists?.__typename === 'GraphqlError') {
    return { buyerRoles, sellerRoles, members };
  }
  const { edges = [] } = tempRoleLists as RoleConnection;

  const setPlaceholderData = (placeholder: Placeholder) => {
    const stakeholder = {
      id: placeholder?.id,
      firstName: placeholder?.name,
      orgId: placeholder?.orgId,
      email: '',
    };
    members[placeholder.id] = stakeholder as IStakeholder;
  };

  const setStakeholderData = (stakeholder: Stakeholder, userType: UserType) => {
    const id = stakeholder?.id || stakeholder?.primaryId || stakeholder?.email;
    members[id] = {
      ...stakeholder,
      firstName: capitalizeString(
        stakeholder?.firstName || stakeholder.email?.split('@')?.[0],
      ),
      userType,
    };
  };

  for (let i = 0; i < edges?.length; i += 1) {
    const node = edges[i]?.node;

    if (node?.__typename === 'GraphqlError') {
      const id = uuid();
      const graphQLError = node;
      roles.push({ id, ...graphQLError } as unknown as IRole);
    } else {
      const buyerMembers =
        (((node as Role)?.members as RoleMembers)?.buyerMembers as Members) ??
        {};
      const sellerMembers =
        (((node as Role)?.members as RoleMembers)?.sellerMembers as Members) ??
        {};
      if (buyerMembers) {
        buyerMembers?.placeholders?.forEach((placeholder) => {
          setPlaceholderData(placeholder as Placeholder);
        });
        buyerMembers?.stakeholders?.forEach((stakeholder) => {
          setStakeholderData(stakeholder as Stakeholder, UserType.BUYER);
        });
      }
      if (sellerMembers) {
        sellerMembers?.placeholders?.forEach((placeholder) => {
          setPlaceholderData(placeholder as Placeholder);
        });
        sellerMembers?.stakeholders?.forEach((stakeholder) => {
          setStakeholderData(stakeholder as Stakeholder, UserType.SELLER);
        });
      }
      const buyerMembersIds =
        (
          buyerMembers?.placeholders?.map(
            (placeholder) => (placeholder as Placeholder).id,
          ) ?? []
        ).concat(
          buyerMembers?.stakeholders?.map(
            (stakeholder) =>
              (stakeholder as Stakeholder)?.id ||
              (stakeholder as Stakeholder)?.primaryId ||
              '',
          ) ?? [],
        ) ?? [];
      const sellerMembersIds =
        (
          sellerMembers?.placeholders?.map(
            (placeholder) => (placeholder as Placeholder)?.id,
          ) ?? []
        ).concat(
          sellerMembers?.stakeholders?.map(
            (stakeholder) =>
              (stakeholder as Stakeholder)?.id ||
              (stakeholder as Stakeholder)?.primaryId ||
              '',
          ) ?? [],
        ) ?? [];
      roles.push({
        ...node,
        memberIds: buyerMembersIds?.concat(sellerMembersIds).filter((id) => id),
      } as IRole);
    }
  }
  buyerRoles =
    roles?.filter((role) => role?.companyType === CompanyType.BUYER) ?? [];
  sellerRoles =
    roles?.filter((role) => role?.companyType === CompanyType.SELLER) ?? [];
  return { buyerRoles, sellerRoles, members };
}

const initialState = {
  ...defaultMyTasks,
  loading: false,
  error: undefined as unknown,
  placeholderError: undefined as unknown as Error,
  placeholderLoading: false,
  roleListError: undefined as unknown as Error,
  roleListLoading: false,
};

const stakeholderSlice = createSlice({
  name: 'msp-stakeholder',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<Maybe<string>>) => {
      state.searchText = action.payload ?? '';
    },
    addNewRoleList: (state, action: PayloadAction<Maybe<CompanyType>>) => {
      const newRole = {
        name: '',
        id: `newRole-${uuid()}`,
        companyType: action.payload,
      };
      if (action.payload === CompanyType.BUYER) {
        state.buyerRoles?.push({
          ...newRole,
          order: state.buyerRoles?.length + 1,
        } as IRole);
      } else if (action.payload === CompanyType.SELLER) {
        state.sellerRoles?.push({
          ...newRole,
          order: state.sellerRoles?.length + 1,
        } as IRole);
      }
    },
    deleteRole: (
      state,
      action: PayloadAction<{ roleId: string; companyType: CompanyType }>,
    ) => {
      const { roleId, companyType } = action.payload;
      const roles =
        companyType === CompanyType.BUYER
          ? state.buyerRoles
          : state.sellerRoles;
      const index = roles?.findIndex((role) => role?.id === roleId);
      if (index > -1) roles?.splice(index, 1);
    },
    moveRole: (
      state,
      action: PayloadAction<{
        sourceIndex: number;
        destinationIndex: number;
        companyType: CompanyType;
      }>,
    ) => {
      const { sourceIndex, destinationIndex, companyType } = action.payload;
      const roles =
        companyType === CompanyType.BUYER
          ? state.buyerRoles
          : state.sellerRoles;
      const movedRole = roles?.splice(sourceIndex, 1);
      if (movedRole) {
        roles?.splice(destinationIndex, 0, movedRole[0]);
      }
    },
    addNewStakeholder: (
      state,
      action: PayloadAction<{
        companyType: CompanyType;
        roleId: string;
        stakeholders: IStakeholder[];
      }>,
    ) => {
      const { companyType, roleId, stakeholders } = action.payload;
      const buyerRoles = state?.buyerRoles;
      const sellerRoles = state?.sellerRoles;
      const roles = (
        companyType === CompanyType.BUYER ? buyerRoles : sellerRoles
      ).find(({ id }) => id === roleId);
      if (roles) {
        const StakeholderIds = stakeholders?.map((stakeholder) => {
          const id = stakeholder.id ?? '';
          state.members[id] = {
            ...stakeholder,
            firstName: capitalizeString(
              stakeholder.firstName || stakeholder.email?.split('@')?.[0],
            ),
          };
          return id;
        });
        roles.memberIds = roles.memberIds?.length
          ? roles.memberIds?.concat(StakeholderIds)
          : StakeholderIds;
      }
    },
    updateMspStakeholderIsHidden: (
      state,
      action: PayloadAction<CompanyType>,
    ) => {
      const { payload: companyType } = action;
      state.isHidden[companyType] = !state.isHidden[companyType];
    },
    createRole: (
      state,
      action: PayloadAction<{ data: Role; roleId: string }>,
    ) => {
      const { data, roleId } = action.payload;
      const roles =
        data.companyType === CompanyType.BUYER
          ? state.buyerRoles
          : state.sellerRoles;
      const roleIndex = roles?.findIndex((role) => role?.id === roleId);
      if (roleIndex > -1) {
        roles[roleIndex] = { ...data, memberIds: [] };
      }
    },
    updateRoleName: (
      state,
      action: PayloadAction<{
        roleId: string;
        name: string;
        companyType: CompanyType;
      }>,
    ) => {
      const { roleId, name, companyType } = action.payload;
      const role =
        companyType === CompanyType.BUYER
          ? state.buyerRoles?.find((role) => role?.id === roleId)
          : state.sellerRoles?.find((role) => role?.id === roleId);
      if (role) {
        role.name = name;
      }
    },
    resetUpdateRoleName: (
      state,
      action: PayloadAction<{
        roleId: string;
        name: string;
        companyType: CompanyType;
      }>,
    ) => {
      const { roleId, name, companyType } = action.payload;
      const role =
        companyType === CompanyType.BUYER
          ? state.buyerRoles?.find((role) => role?.id === roleId)
          : state.sellerRoles?.find((role) => role?.id === roleId);
      if (role) {
        role.name = name;
      }
    },
    setRolePlaceholderName: (
      state,
      action: PayloadAction<{ placeholderId: string; name: string }>,
    ) => {
      const member = state.members?.[action.payload.placeholderId];
      member.firstName = `Placeholder-${action.payload.name}`;
    },
    resetStakeholderInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const { buyerRoles, sellerRoles, members } =
          transformRoleListResponse(data);
        state.buyerRoles = buyerRoles;
        state.sellerRoles = sellerRoles;
        state.members = members;
        state.roleListLoading = false;
        state.roleListError = null as unknown as Error;
      })
      .addCase(fetchRoleList.pending, (state) => {
        state.roleListLoading = true;
      })
      .addCase(fetchRoleList.rejected, (state, { payload }) => {
        state.roleListError = payload as Error;
        state.roleListLoading = false;
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const role =
          data.companyType === CompanyType.BUYER
            ? state.buyerRoles?.find((role) => role.id === data.id)
            : state.sellerRoles?.find((role) => role.id === data.id);
        if (role) {
          role.name = data?.name;
          role.isHidden = data?.isHidden;
        }
        state.loading = false;
      })
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRole.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(removeStakeholder.fulfilled, (state, { payload }) => {
        const { roleId, stakeholderId, companyType } = payload;
        const roles =
          companyType === CompanyType.BUYER
            ? state.buyerRoles
            : state.sellerRoles;
        const memberIds = roles?.find((role) => role?.id === roleId)?.memberIds;
        if (memberIds?.length) {
          const index =
            memberIds?.findIndex((memberId) => memberId === stakeholderId) ??
            -1;
          if (index > -1) memberIds?.splice(index, 1);
        }
        state.loading = false;
      })
      .addCase(removeStakeholder.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeStakeholder.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(removePlaceholder.fulfilled, (state, { payload }) => {
        const { roleId, placeholderId, companyType } = payload;
        const roles =
          companyType === CompanyType.BUYER
            ? state.buyerRoles
            : state.sellerRoles;
        const memberIds = roles?.find((role) => role?.id === roleId)?.memberIds;
        if (memberIds?.length) {
          const index =
            memberIds?.findIndex((memberId) => memberId === placeholderId) ??
            -1;
          if (index > -1) memberIds?.splice(index, 1);
        }
        state.loading = false;
      })
      .addCase(removePlaceholder.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePlaceholder.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(assignPlaceholder.fulfilled, (state, { payload }) => {
        const { roleId, placeholderId, name, companyType } = payload;
        const roles =
          companyType === CompanyType.BUYER
            ? state.buyerRoles
            : state.sellerRoles;
        const memberIds =
          roles?.find((role) => role?.id === roleId)?.memberIds ?? [];
        memberIds?.unshift(placeholderId);
        state.members[placeholderId] = {
          id: placeholderId,
          firstName: name,
          email: '',
        } as IStakeholder;
        state.placeholderLoading = false;
        state.placeholderError = null as unknown as Error;
      })
      .addCase(assignPlaceholder.pending, (state) => {
        state.placeholderLoading = true;
      })
      .addCase(assignPlaceholder.rejected, (state, { payload }) => {
        state.placeholderError = payload as Error;
        state.placeholderLoading = false;
      })
      .addCase(
        replacePlaceholderWithStakeholder.fulfilled,
        (state, { payload }) => {
          const { roleId, placeholderId, user, companyType } = payload;
          const roles =
            companyType === CompanyType.BUYER
              ? state.buyerRoles
              : state.sellerRoles;
          const memberIds =
            roles?.find((role) => role?.id === roleId)?.memberIds ?? [];
          const memberIndex = memberIds?.findIndex(
            (memberId) => memberId === placeholderId,
          );
          memberIds?.splice(memberIndex, 1);
          delete state.members[placeholderId];
          if (user?.id) {
            memberIds?.push(user.id);
            state.members[user.id] = {
              ...user,
              firstName: capitalizeString(
                user?.firstName || user?.email?.split('@')?.[0],
              ),
            } as IStakeholder;
          }
          state.loading = false;
        },
      )
      .addCase(replacePlaceholderWithStakeholder.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        replacePlaceholderWithStakeholder.rejected,
        (state, { payload }) => {
          state.error = payload;
          state.loading = false;
        },
      );
  },
});

export const {
  setSearchText,
  addNewRoleList,
  deleteRole,
  moveRole,
  updateMspStakeholderIsHidden,
  addNewStakeholder,
  createRole,
  updateRoleName,
  resetUpdateRoleName,
  resetStakeholderInitialState,
  setRolePlaceholderName,
} = stakeholderSlice.actions;
export default stakeholderSlice.reducer;
