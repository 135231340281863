import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const buyerExperienceSlice = createSlice({
  name: 'app-state',
  initialState: {
    isSidebarOpen: false,
  },
  reducers: {
    setSidebarStatus: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setSidebarStatus } = buyerExperienceSlice.actions;
export default buyerExperienceSlice.reducer;
