import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Dispatch } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import {
  Filter,
  FilterItemsPayloadResponse,
  Operation,
  TaskConnectionResponse,
  TaskPayloadResponse,
  TaskStatus,
} from '../../../generated-types';
import { UPDATE_TASK } from '../../../graphql/mutation/task/update-task';
import {
  CHANGE_TASK_STATUS,
  MARK_TASKS_INCOMPLETE,
  UpdateTaskPendingStatus,
  UpdateTaskStatus,
} from '../../../graphql/mutation/task/update-task-status';
import { EMspSaveIndicator } from '../../msp-view/model';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import { GET_MY_TASKS, MY_TASKS_FILTERS } from './graphql/get-my-tasks';

export enum TaskType {
  OVERDUE = 'overdue',
  DUETODAY = 'dueToday',
  DUETOMORROW = 'dueTomorrow',
  DUEINTENDAYS = 'dueTenDays',
}

export type ResultMyTasks = {
  taskQuery: {
    myTasks: TaskConnectionResponse;
  };
};

export type ResultMyTasksFilters = {
  taskQuery: {
    myTaskFilters: FilterItemsPayloadResponse;
  };
};

export interface InputProps {
  client: ApolloClient<object>;
  taskId: string;
  mspId: string;
  taskType: TaskType;
  showToast: (
    message: JSX.Element | string,
    options: {
      variant: string;
    },
  ) => void;
  dispatch: Dispatch<any>;
  newRequestedTaskStatus?: TaskStatus;
}

const sort = [
  { field: 'task.mspId', order: 'ASC' },
  { field: 'task.dueDate', order: 'ASC' },
];

export const fetchMyTasksList = createAsyncThunk(
  'dashboard/get-my-tasks',
  async (
    {
      client,
      searchTerm,
      taskType,
      filters,
    }: {
      client: ApolloClient<object>;
      searchTerm?: string;
      taskType: TaskType;
      filters: Filter[];
    },
    { rejectWithValue },
  ) => {
    try {
      const tempFilters = [...filters];
      tempFilters.push({
        op: Operation.IN,
        values: [TaskStatus.PENDING, TaskStatus.INPROGRESS],
        field: 'task.status',
      });
      if (searchTerm) {
        tempFilters.push({
          op: Operation.LIKE,
          values: [searchTerm],
          field: 'task.name',
        });
      }

      const { data } = await client.query<ResultMyTasks>({
        query: GET_MY_TASKS,
        variables: {
          operations: {
            filter: {
              operator: 'AND',
              filters: tempFilters,
            },
            sort,
          },
        },
      });
      return { data, taskType };
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const fetchMyTasksFilters = createAsyncThunk(
  'dashboard/get-my-task-filters',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data } = await client.query<ResultMyTasksFilters>({
        query: MY_TASKS_FILTERS,
        variables: {},
      });
      return data;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export interface ITaskDueDateResponse {
  taskMutation: { updateTask: TaskPayloadResponse };
}

export const updateTaskDueDate = createAsyncThunk(
  'dashboard/update-task-due-date',
  async (
    {
      client,
      dueDate,
      taskId,
      mspId,
      showToast,
      taskType,
      dispatch,
    }: InputProps & { dueDate: number },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ITaskDueDateResponse>({
        query: UPDATE_TASK,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          id: taskId,
          input: { dueDate },
        },
      });
      if (data.taskMutation.updateTask.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.taskDueDateError"
            defaultMessage="Task Due Date didn't save, please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Something went wrong');
      } else {
        showToast(
          <FormattedMessage
            id="MspPlanView.taskDueDate"
            defaultMessage="Task Due Date updated"
          />,
          {
            variant: 'success',
          },
        );
      }
      return { data, taskType };
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const updateMyTaskStatus = createAsyncThunk(
  'dashboard/update-task-status',
  async (
    {
      client,
      taskId,
      mspId,
      showToast,
      taskType,
      dispatch,
      newRequestedTaskStatus,
    }: InputProps,
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_STATUS }),
      );
      const { data } = await client.query<UpdateTaskStatus>({
        query: CHANGE_TASK_STATUS,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            taskIds: [taskId],
            taskStatus: newRequestedTaskStatus,
          },
        },
      });
      if (data.taskMutation.changeTaskStatus?.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.mspTaskStatusAPIError"
            defaultMessage="Task completion status not saved, Please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Task completion status not saved, Please try again');
      } else {
        dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      }
      return { data, taskType, newRequestedTaskStatus };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const updateTaskInCompleteStatus = createAsyncThunk(
  'dashboard/update-task-status-incomplete',
  async (
    { client, taskId, mspId, showToast, taskType, dispatch }: InputProps,
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_STATUS }),
      );
      const { data } = await client.query<UpdateTaskPendingStatus>({
        query: MARK_TASKS_INCOMPLETE,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          ids: [taskId],
        },
      });
      if (data.taskMutation.markTasksPending?.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.mspTaskStatusAPIError"
            defaultMessage="Task completion status not saved, Please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Task completion status not saved, Please try again');
      } else {
        dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      }
      return { data, taskType };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      return rejectWithValue((error as Error)?.message);
    }
  },
);
