export type TVariants =
  | 'heading1'
  | 'heading2'
  | 'heading2-bold'
  | 'heading2-semibold'
  | 'heading3'
  | 'heading3-bold'
  | 'heading4'
  | 'heading5'
  | 'heading5-bold'
  | 'heading5-semibold'
  | 'heading6'
  | 'body-14-bold'
  | 'body-14-semibold'
  | 'body-14-medium'
  | 'body-14-regular'
  | 'body-12-bold'
  | 'body-12-regular'
  | 'body-13-bold'
  | 'body-13-semibold'
  | 'body-13-regular'
  | 'body-11-regular'
  | 'body-11-bold'
  | 'body-10-regular'
  | 'body-13-medium'
  | 'label-14-medium'
  | 'label-12-bold'
  | 'label-12-semibold'
  | 'label-12-medium'
  | 'label-12-regular'
  | 'label-11-bold'
  | 'label-11-medium'
  | 'label-11-regular'
  | 'label-10-bold'
  | 'label-10-medium'
  | 'label-10-regular'
  | 'label-9-regular'
  | 'label-8-bold';

export type TElements =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'div'
  | 'span'
  | 'p'
  | 'a';

export const FONT_WEIGHT_CLASSES = {
  100: 'font-thin',
  200: 'font-extralight',
  300: 'font-light',
  400: 'font-normal',
  500: 'font-medium',
  600: 'font-semibold',
  700: 'font-bold',
  800: 'font-extrabold',
  900: 'font-black',
};
//  | '100'
//     | '200'
//     | '300'
interface IVariantMap {
  fontWeight: '400' | '500' | '600' | '700' | '800' | '900';
  fontSizeClass: string;
  fontSize: number;
  element: TElements;
}

export const VARIANT_RECORDS: Record<TVariants, IVariantMap> = {
  heading1: {
    fontWeight: '900',
    fontSizeClass: 'text-24',
    fontSize: 24,
    element: 'h1',
  },
  heading2: {
    fontWeight: '900',
    fontSizeClass: 'text-20',
    element: 'h2',
    fontSize: 20,
  },
  'heading2-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-20',
    fontSize: 20,
    element: 'h2',
  },
  'heading2-semibold': {
    fontWeight: '600',
    fontSizeClass: 'text-20',
    element: 'h2',
    fontSize: 20,
  },
  heading3: {
    fontWeight: '800',
    fontSizeClass: 'text-18',
    element: 'h3',
    fontSize: 18,
  },
  'heading3-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-18',
    element: 'h3',
    fontSize: 18,
  },
  heading4: {
    fontWeight: '700',
    fontSizeClass: 'text-16',
    element: 'h4',
    fontSize: 16,
  },
  heading5: {
    fontWeight: '400',
    fontSizeClass: 'text-16',
    fontSize: 16,
    element: 'h5',
  },
  'heading5-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-16',
    fontSize: 16,
    element: 'h5',
  },
  'heading5-semibold': {
    fontWeight: '600',
    fontSizeClass: 'text-16',
    fontSize: 16,
    element: 'h5',
  },
  heading6: {
    fontWeight: '700',
    fontSizeClass: 'text-14',
    fontSize: 14,
    element: 'h6',
  },
  'body-14-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-14',
    fontSize: 14,
    element: 'p',
  },
  'body-14-semibold': {
    fontWeight: '600',
    fontSizeClass: 'text-14',
    element: 'p',
    fontSize: 14,
  },
  'body-14-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-14',
    fontSize: 14,
    element: 'p',
  },
  'body-14-regular': {
    fontWeight: '400',
    fontSize: 14,
    fontSizeClass: 'text-14',
    element: 'p',
  },
  'body-12-bold': {
    fontWeight: '700',
    fontSize: 12,
    fontSizeClass: 'text-13',
    element: 'p',
  },
  'body-12-regular': {
    fontWeight: '500',
    fontSize: 12,
    fontSizeClass: 'text-13',
    element: 'p',
  },
  'body-13-bold': {
    fontWeight: '700',
    fontSize: 13,
    fontSizeClass: 'text-13',
    element: 'p',
  },
  'body-13-semibold': {
    fontWeight: '600',
    fontSize: 13,
    fontSizeClass: 'text-13',
    element: 'p',
  },
  'body-13-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-13',
    fontSize: 13,
    element: 'p',
  },
  'body-13-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-13',
    element: 'p',
    fontSize: 13,
  },
  'body-11-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-11',
    element: 'p',
    fontSize: 12,
  },
  'body-11-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-11',
    element: 'p',
    fontSize: 11,
  },
  'body-10-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-10',
    element: 'p',
    fontSize: 10,
  },
  'label-14-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-14',
    fontSize: 14,
    element: 'span',
  },
  'label-12-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-12',
    element: 'span',
    fontSize: 12,
  },
  'label-12-semibold': {
    fontWeight: '600',
    fontSizeClass: 'text-12',
    element: 'span',
    fontSize: 12,
  },
  'label-12-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-12',
    element: 'span',
    fontSize: 12,
  },
  'label-12-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-12',
    element: 'span',
    fontSize: 12,
  },
  'label-11-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-11',
    element: 'span',
    fontSize: 10,
  },
  'label-11-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-11',
    element: 'span',
    fontSize: 10,
  },
  'label-11-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-11',
    element: 'span',
    fontSize: 10,
  },
  'label-10-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-10',
    element: 'span',
    fontSize: 10,
  },
  'label-10-medium': {
    fontWeight: '500',
    fontSizeClass: 'text-10',
    element: 'span',
    fontSize: 10,
  },
  'label-10-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-10',
    element: 'span',
    fontSize: 10,
  },
  'label-9-regular': {
    fontWeight: '400',
    fontSizeClass: 'text-9',
    element: 'span',
    fontSize: 9,
  },
  'label-8-bold': {
    fontWeight: '700',
    fontSizeClass: 'text-8',
    element: 'span',
    fontSize: 8,
  },
};
