import { TaskStatus } from '../../generated-types';
import MarkTaskProgressWithArrowIcon from '../../assets/task-progress.svg';
import MarkTaskPendingWithArrowIcon from '../../assets/task-pending.svg';
import MarkTaskCompletedWithArrowIcon from '../../assets/task-completed.svg';
import clsx from 'clsx';

export const taskStatusIcons = {
  COMPLETED: {
    alt: 'complete-task',
    src: MarkTaskCompletedWithArrowIcon as string,
  },
  PENDING: {
    alt: 'pending-task',
    src: MarkTaskPendingWithArrowIcon as string,
  },
  INPROGRESS: {
    alt: 'in-progress-task',
    src: MarkTaskProgressWithArrowIcon as string,
  },
};

function TaskStatusIcon({
  taskStatus,
  className,
}: {
  taskStatus: TaskStatus;
  className?: string;
}) {
  const iconDetail = taskStatusIcons[taskStatus] || taskStatusIcons.PENDING;
  return (
    <img
      alt={iconDetail.alt}
      src={iconDetail.src}
      className={clsx(className)}
    />
  );
}
export default TaskStatusIcon;
