import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const ADD_STAKEHOLDERS = gql`
  ${CORE_GRAPH_ERROR}
  mutation addStakeholdersToRoles($input: [AddStakeHolderToRoleInput!]!) {
    memberMutation {
      addStakeholdersToRoles(input: $input) {
        __typename
        ...CoreErrorFields
        ... on AddOrRemoveStakeholderPayload {
          items {
            roleId
            stakeholderId
            stakeholderPrimaryId
          }
        }
      }
    }
  }
`;

export const ADD_STAKEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation addStakeholderToRole($input: AddStakeHolderToRoleInput!) {
    memberMutation {
      addStakeholderToRole(input: $input) {
        __typename
        ...CoreErrorFields
        ... on AddOrRemoveStakeholderPayload {
          items {
            roleId
            stakeholderId
          }
        }
      }
    }
  }
`;

export const REPLACE_PLACEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation replacePlaceholderWithStakeholder(
    $input: ReplacePlaceholderWithStakeholderInput!
  ) {
    memberMutation {
      replacePlaceholderWithStakeholder(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
        }
      }
    }
  }
`;

export const REMOVE_STAKEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation removeStakeholderFromRole($input: RemoveStakeholderFromRoleInput!) {
    memberMutation {
      removeStakeholderFromRole(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const CREATE_ASSIGN_STAKEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation createAndAssignStakeholderToRole(
    $input: CreateAndAssignStakeholderToRoleInput!
  ) {
    memberMutation {
      createAndAssignStakeholderToRole(input: $input) {
        __typename
        ... on UserPayload {
          items {
            ... on User {
              id
            }
          }
        }
        ...CoreErrorFields
      }
    }
  }
`;
