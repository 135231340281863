import { gql } from '@apollo/client';
import {
  BooleanResponse,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

export const MARK_TASKS_INCOMPLETE = gql`
  ${CORE_GRAPH_ERROR}
  mutation MarkTasksIncomplete($ids: [ID!]!) {
    taskMutation {
      markTasksPending(ids: $ids) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const MARK_TASKS_COMPLETE = gql`
  ${CORE_GRAPH_ERROR}
  mutation MarkTasksComplete($ids: [ID!]!) {
    taskMutation {
      markTasksComplete(ids: $ids) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export type UpdateTaskCompleteStatus = {
  taskMutation: {
    markTasksComplete?: BooleanResponse;
  };
};

export type UpdateTaskPendingStatus = {
  taskMutation: {
    markTasksPending?: BooleanResponse;
  };
};

export const CHANGE_TASK_STATUS = gql`
  ${CORE_GRAPH_ERROR}
  mutation ChangeTaskStatus($input: ChangeTaskStatusInput!) {
    taskMutation {
      changeTaskStatus(input:$input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export type UpdateTaskStatus = {
  taskMutation: {
    changeTaskStatus?: BooleanResponse;
  };
};
