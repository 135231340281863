import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Markdown from 'markdown-to-jsx';
import { useCallback } from 'react';
import { FaCircle, FaRegComment } from 'react-icons/fa';
import {
  FiCalendar,
  FiPaperclip,
  FiUserCheck,
  FiUserPlus,
} from 'react-icons/fi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import bulbSvg from '../../../assets/Bulb.svg';
import NotificationSvg from '../../../assets/notification.svg';
import orgSvg from '../../../assets/organization.svg';
import { toolTip } from '../../../common-language';
import { getMspId } from '../../../common/methods/get-msp-id';
import { CustomToolTip, Typography } from '../../../components';
import { showToast } from '../../../components/hooks/use-toastify';
import { DotIcon } from '../../../components/icons';
import {
  NotificationEventType,
  NotificationStatus,
  PushNotificationMutationUpdatePushNotificationStatusArgs,
} from '../../../generated-types';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import { EMspSaveIndicator } from '../model';
import {
  MARKED_READ_NOTIFICATION,
  ResultMarkedReadNotification,
} from './graphql';
import { INotification, NotificationCardType } from './models';
import { setMarkReadNotification } from './notification-redux-store';

dayjs.extend(relativeTime);

type TNotificationCard = {
  notification: INotification;
};

function NotificationIcon(props: { notificationType: string }) {
  const { notificationType } = props;
  switch (notificationType) {
    case NotificationEventType.COMMENTS_ADDED:
      return <FaRegComment fontSize={16} />;
    case NotificationEventType.ROLE_ASSIGNED:
      return <FiUserCheck fontSize={16} />;
    case NotificationEventType.TASK_ASSIGNED:
      return <HiOutlineClipboardList fontSize={16} />;
    case NotificationEventType.TASK_DUE_DATE_UPDATED:
      return <FiCalendar fontSize={16} />;
    case NotificationEventType.MSP_INVITE:
      return <FiUserPlus fontSize={16} />;
    case NotificationEventType.MSP_ENGAGEMENT:
      return <img src={bulbSvg as string} alt="bulb" width={16} height={16} />;
    default:
      return <FiPaperclip fontSize={16} />;
  }
}

function NotificationCard(props: TNotificationCard) {
  const { notification } = props;
  const mspId = getMspId(window.location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();

  const [markedReadNotification] = useMutation<
    ResultMarkedReadNotification,
    PushNotificationMutationUpdatePushNotificationStatusArgs
  >(MARKED_READ_NOTIFICATION);

  const onMarkedRead = (NotificationId: string) => {
    dispatch(
      addToSavingList({ saving: true, id: EMspSaveIndicator.MARK_AS_READ }),
    );
    markedReadNotification({
      variables: {
        input: {
          userNotificationStatusId: NotificationId,
          status: NotificationStatus.READ,
        },
      },
    })
      .then((resp) => {
        if (
          resp.data?.pushNotificationMutation?.updatePushNotificationStatus
            ?.__typename !== 'GraphqlError'
        ) {
          dispatch(setMarkReadNotification(NotificationId));
          dispatch(
            trackEvent(TRACKING_CONSTANTS.NOTIFICATION_MARKED_AS_READ, {
              mspId,
              id: NotificationId,
            }),
          );
        } else {
          throw new Error('Something went wrong');
        }
        dispatch(removeFromSavingList(EMspSaveIndicator.MARK_AS_READ));
      })
      .catch(() => {
        showToast(
          <FormattedMessage
            id="Notification.markRead"
            defaultMessage="Mark read notification could not be saved, please try again"
          />,
          {
            variant: 'error',
          },
        );
        dispatch(removeFromSavingList(EMspSaveIndicator.MARK_AS_READ));
      });
  };

  const onNotificationCardClick = useCallback(() => {
    const newPath = notification?.link?.split('buyerassist.io')[1];
    if (notification?.status !== NotificationStatus.READ) {
      dispatch(
        trackEvent(TRACKING_CONSTANTS.MARKED_READ_NOTIFICATION, {
          userNotificationStatusId: notification?.userNotificationStatusId,
        }),
      );
      onMarkedRead(notification?.userNotificationStatusId);
    }
    if (newPath) {
      history.push(newPath);
    }
  }, [notification]);

  return (
    <div
      key={notification?.userNotificationStatusId}
      role="presentation"
      className={clsx(
        'grid min-h-[52px] w-full py-5 px-4',
        notification?.type !== NotificationCardType.END_NOTIFICATION &&
          'cursor-pointer hover:bg-light-bg',
      )}
      onClick={() =>
        notification?.type !== NotificationCardType.END_NOTIFICATION &&
        onNotificationCardClick()
      }
    >
      <NotificationCardVariants
        notificationType={notification?.type as NotificationCardType}
        notification={notification}
        onMarkedRead={onMarkedRead}
      />
    </div>
  );
}
type TNotificationType = {
  notificationType: NotificationCardType;
  notification: INotification;
  onMarkedRead: (NotificationId: string) => void;
};

function NotificationCardVariants(props: TNotificationType) {
  const { notificationType, notification, onMarkedRead } = props;
  switch (notificationType) {
    case NotificationCardType.END_NOTIFICATION:
      return <NotificationEndCard />;
    case NotificationCardType.INSIGHT_NOTIFICATION:
      return (
        <NotificationInsightCard
          notification={notification}
          onMarkedRead={onMarkedRead}
        />
      );
    default:
      return (
        <Notification notification={notification} onMarkedRead={onMarkedRead} />
      );
  }
}

type TNotification = {
  notification: INotification;
  onMarkedRead: (NotificationId: string) => void;
};

function Notification(props: TNotification) {
  const { notification, onMarkedRead } = props;
  if (!notification) return null;
  return (
    <>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-1">
          <div
            className={clsx(
              'relative flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid p-1 text-white',
              notification?.authorizerType === 'SELLER'
                ? 'border-primary-300 bg-primary-300'
                : 'border-success-200 bg-success-200',
            )}
          >
            <NotificationIcon
              notificationType={notification?.notificationType}
            />
          </div>
        </div>
        <div className="col-span-10 pl-2">
          <div className="flex items-center space-x-2">
            <Typography variant="body-14-bold" className="col-span-8 truncate">
              {notification?.authorizerName || 'Insights'}
            </Typography>
            <Typography
              variant="label-12-regular"
              className="min-w-[64px] px-1 pt-[3px] text-neutral-300"
              element="p"
            >
              {dayjs(notification?.createdAt)?.fromNow()}
            </Typography>
          </div>
          <div className="flex items-center space-x-1">
            <img src={orgSvg as string} alt="org" width={12} height={12} />
            <Typography
              variant="label-12-medium"
              className="truncate capitalize text-neutral-300"
            >
              {notification?.accountName}
            </Typography>
          </div>
        </div>
        <div className="col-span-1 flex self-baseline pl-3 pt-1">
          {notification?.status !== NotificationStatus.READ && (
            <CustomToolTip arrow title={toolTip['Notification.MarkRead']}>
              <FaCircle
                size={6}
                onClick={(event) => {
                  event.stopPropagation();
                  onMarkedRead(notification?.userNotificationStatusId);
                }}
                className="cursor-pointer rounded-full text-primary-400 ring-offset-4 hover:ring-1"
              />
            </CustomToolTip>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 pt-2">
        <Typography
          variant="body-13-medium"
          className="col-span-10 col-start-2 cursor-pointer px-2"
        >
          <Markdown>{notification?.message}</Markdown>
          <br />
          {notification?.additionalMessage && (
            <div className="cursor-pointer italic">
              <Markdown>{notification?.additionalMessage}</Markdown>
            </div>
          )}
        </Typography>
      </div>
    </>
  );
}

function NotificationEndCard() {
  return (
    <div className="flex flex-col items-center px-4 py-5">
      <img
        alt="notification"
        src={NotificationSvg as string}
        width={110}
        height={100}
      />
      <Typography variant="body-13-semibold" className="text-center">
        That&apos;s the end of your Notifications.
      </Typography>
    </div>
  );
}

function NotificationInsightCard(props: TNotification) {
  const { notification, onMarkedRead } = props;
  if (!notification) return null;
  return (
    <div className="grid h-full w-full grid-cols-12 items-center">
      <div className="col-span-1">
        <div className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid border-warning-300 bg-warning-300 p-1 text-white">
          <NotificationIcon notificationType={notification?.notificationType} />
        </div>
      </div>
      <div className="col-span-10 flex flex-col">
        <Typography
          variant="body-13-medium"
          className="col-span-10 cursor-pointer px-2"
        >
          <Markdown>{notification?.message}</Markdown>
          <br />
          {notification?.additionalMessage && (
            <div className="cursor-pointer italic">
              <Markdown>{notification?.additionalMessage}</Markdown>
            </div>
          )}
        </Typography>
        <div className="mt-2 flex flex-row pl-2">
          <div className="flex justify-center space-x-2">
            <div className="flex items-center space-x-1">
              <img src={orgSvg as string} alt="org" width={12} height={12} />
              <Typography
                variant="label-12-medium"
                className="truncate capitalize text-neutral-300"
              >
                {notification?.accountName}
              </Typography>
            </div>
            <div className="col-span-1 flex items-center pl-1 ">
              <DotIcon className="text-mid" />
              <Typography
                variant="label-12-regular"
                className="min-w-[64px] px-1 text-neutral-300"
                element="p"
              >
                {dayjs(notification?.createdAt)?.fromNow()}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex self-baseline pl-3 pt-1">
        {notification?.status !== NotificationStatus.READ && (
          <CustomToolTip arrow title={toolTip['Notification.MarkRead']}>
            <FaCircle
              size={6}
              onClick={(event) => {
                event.stopPropagation();
                onMarkedRead(notification?.userNotificationStatusId);
              }}
              className="cursor-pointer rounded-full text-primary-400 ring-offset-4 hover:ring-1"
            />
          </CustomToolTip>
        )}
      </div>
    </div>
  );
}

export default NotificationCard;
