import { TaskStatus } from '../../generated-types';
import { useDebounce } from '../../common/methods/debounce';

let moueseLeaveTimer:unknown|number;

function useUpdateTaskStatus() {
  const handleMouseleave = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    moueseLeaveTimer = setTimeout(() => {
      setAnchorEl(null);
    }, 320);
  };
  const clearMouseLeaveEvent = () => {
    clearTimeout(moueseLeaveTimer as number);
  };
  const handleMouseEnter = useDebounce((targetElem:HTMLElement, setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    setAnchorEl(targetElem);
  }, 300);
  const handleTaskStatusOnHover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    const eventType = event?.type;
    const targetElem = event?.currentTarget;
    if (eventType === 'mouseleave') {
      handleMouseleave(setAnchorEl);
    } else {
      handleMouseEnter(targetElem, setAnchorEl);
    }
  };

  const handleTaskStatus = (taskCurrentStatus: TaskStatus) => {
    if (taskCurrentStatus) {
      if (taskCurrentStatus === TaskStatus?.COMPLETED) {
        return TaskStatus?.COMPLETED;
      }
      if (taskCurrentStatus === TaskStatus?.INPROGRESS) {
        return TaskStatus.COMPLETED;
      }
      return TaskStatus.INPROGRESS;
    }
    return TaskStatus.PENDING;
  };
  return { handleTaskStatus, handleTaskStatusOnHover, clearMouseLeaveEvent };
}
export default useUpdateTaskStatus;
