/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createAction } from '@reduxjs/toolkit';
import { EventTypes } from 'redux-segment';
import { isBuyer } from '../common/auth/utils';
import getUserDetails, {
  getOrgDetails,
} from '../common/methods/get-user-details';
import { Company, Organization } from '../generated-types';

const segment = function (eventType: keyof typeof EventTypes) {
  return createAction('Analytics Event', (event: string, payload?: any) => {
    const slURL = (window.smartlook && window.smartlook.playUrl) || '';
    const { pathname } = window.location;
    const { company, companyId, userType, userId, email } = getUserDetails();
    const { companyName, id } = (getOrgDetails()?.company || {}) as Company;
    const { orgId, orgName } = (getOrgDetails() || {}) as Organization;

    const eventProperties: Record<string, unknown> = {
      isBuyer: isBuyer(userType || ''),
    };
    return {
      payload: {
        event,
        ...payload,
        // createdAt: new Date().toISOString(),
      },
      meta: {
        analytics: {
          eventType: EventTypes[eventType],
          eventPayload: {
            event,
            properties: {
              ...payload,
              slURL,
              pathname,
              company: company || companyName || '',
              companyId: companyId || id || '',
              userType,
              userId,
              email,
              orgId,
              orgName,
              ...eventProperties,
            },
          },
        },
      },
    };
  });
};

const events = {
  identifyEvent: segment('identify'),
  trackEvent: segment('track'),
  pageEvent: segment('page'),
  groupEvent: segment('group'),
  aliasEvent: segment('alias'),
};

export const { identifyEvent, trackEvent, pageEvent, groupEvent, aliasEvent } =
  events;

export default segment;
