import {
  api as generatedApi,
  ActivityTimelineConnection,
  MediaEngagementConnection,
  UserEngagementConnection,
  MspAnalyticsTimeline,
  MspEngagementTimeline,
  MspMetrics,
  Query,
  MspEngagementDetails,
} from '../../../api';

export const extendedApi = generatedApi.enhanceEndpoints({
  endpoints: {
    getActivityTimeline: {
      keepUnusedDataFor: 3600,
      transformResponse: (response: Query) => {
        const data = response?.activityTimelineQuery?.getActivityTimeline;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as ActivityTimelineConnection;
      },
    },
    getMediaEngagement: {
      refetchOnMountOrArgChange: true,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMediaEngagement;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MediaEngagementConnection;
      },
    },
    getUserEngagement: {
      refetchOnMountOrArgChange: true,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getUserEngagement;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as UserEngagementConnection;
      },
    },
    getMspAnalyticsTimeline: {
      keepUnusedDataFor: 3600,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMspAnalyticsTimeline;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MspAnalyticsTimeline;
      },
    },
    getMspEngagementTimeline: {
      refetchOnMountOrArgChange: true,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMspEngagementTimeline;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MspEngagementTimeline;
      },
    },
    getMspAnalyticsMetrics: {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      keepUnusedDataFor: 3600,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMspAnalyticsMetrics;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MspMetrics;
      },
    },
    getMspEngagement: {
      refetchOnMountOrArgChange: true,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMspEngagement;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MspEngagementDetails;
      },
    },
  },
});

export const {
  useGetMediaEngagementQuery,
  useLazyGetMediaEngagementQuery,
  useGetUserEngagementQuery,
  useLazyGetUserEngagementQuery,
  useGetActivityTimelineQuery,
  useLazyGetActivityTimelineQuery,
  useGetMspAnalyticsTimelineQuery,
  useLazyGetMspAnalyticsTimelineQuery,
  useGetMspEngagementTimelineQuery,
  useLazyGetMspEngagementTimelineQuery,
  useGetMspAnalyticsMetricsQuery,
  useLazyGetMspAnalyticsMetricsQuery,
  useGetMspEngagementQuery,
  useLazyGetMspEngagementQuery,
} = extendedApi;
