export enum TRACKING_CONSTANTS {
  CREATED_MSP_FROM_TEMPLATE = 'Created MSP From Template',
  CREATED_MSP_FROM_SCRATCH = 'Created MSP From Scratch',
  CREATE_MSP_FAILED = 'Create MSP Failed',
  CREATE_MSP_SEARCH_COMPANY = 'Create MSP search company',
  ADDED_COMMENT = 'Added Comment to Task',
  DELETE_COMMENT = 'Delete Comment',
  CREATED_MILESTONE_FROM_TEMPLATE = 'Created Milestone From Template',
  CREATED_MILESTONE_FROM_SCRATCH = 'Created Milestone From Scratch',
  DELETED_MILESTONE = 'Deleted Milestone',
  MARK_MILESTONE_INTERNAL = 'Marked Milestone',
  COPIED_MILESTONE_LINK = 'Copied Milestone Link',
  MARK_DUPLICATE = 'Cloned Milestone',
  DELETED_TASK = 'Deleted Task',
  MARKED_TASK_INTERNAL = 'Marked Task',
  COPIED_TASK_LINK = 'Copied Task Link',
  ADDED_TASK = 'Added Task',
  GENERATED_PRIVATE_SHARING_LINK = 'Generated Private Sharing Link',
  GENERATED_PUBLIC_SHARING_LINK = 'Generated Public Sharing Link',
  ADD_UPDATE_PASSWORD_FOR_SHARING_LINK = 'Add/Update Password for Sharing Link',
  COPIED_SHARE_ASSIGNEE_PRIVATE_LINK = 'Copied Share Assignee Private Link',
  COPIED_SHARE_ASSIGNEE_PUBLIC_LINK = 'Copied Share Assignee Public Link',
  UPDATED_TASK_DUE_DATE = 'Updated Task Due Date',
  ADDED_UPDATED_TASK_ASSIGNEE = 'Added/Updated Task Assignee',
  UPDATED_TASK_NAME = 'Updated Task Name',
  UPDATED_TASK_DESCRIPTION = 'Updated Task Description',
  ENTERED_EMAIL_ADDRESS_ON_LINK_VISIT = 'Entered Email Address On Link Visit',
  CLICKED_NEW_MSP_BUTTON = 'Clicked New MSP Button',
  CICKED_PUBLISH_MSP = 'Clicked Publish MSP',
  PUBLISHED_MSP = 'Published MSP',
  CLICKED_SHARE_MSP = 'Clicked Share MSP',
  ACCESSED_MSP = 'Accessed MSP',
  UPDATED_TASK_STATUS = 'Updated Task Status',
  ACCESSED_MSP_MILESTONES = 'Accessed MSP Milestones',
  CLICKED_ASSETS_TAB = 'Clicked Assets Tab',
  CLICKED_RESOURCES_TAB = 'Clicked Resources Tab',
  UPLOAD_ASSET = 'Upload Assets',
  DOWNLOAD_ASSET = 'Download asset',
  SUBMITTED_SEARCH_ASSET = 'Submitted Search Query for Assets',
  SEARCH_ASSET_FAILED = 'Search for Assests Failed',
  ACCESS_ASSET = 'Access Asset',
  DELETE_ASSET = 'Delete Asset',
  RENAME_ASSET = 'Rename Asset',
  SEARCH_ASSET = 'Search Asset',
  MARKED_ASSET_FAVORITE = 'Marked Asset Favorite',
  FAILED_MARKED_ASSET_FAVORITE = 'Failed Mark Asset Favorite',
  FAILED_MARKED_ASSET_UNFAVORITE = 'Failed Mark Asset Unfavorite',
  MARKED_ASSET_UNFAVORITE = 'Marked Asset Unfavorite',
  MARKED_ASSET_UNPINNED = 'Marked Asset Unpinned',
  FAILED_MARKED_ASSET_UNPINNED = 'Failed To Mark Asset Unpinned',
  MARKED_ASSET_PINNED = 'Marked Asset Pinned',
  FAILED_MARKED_ASSET_PINNED = 'Failed To Mark Asset Pinned',
  REVOKED_ASSET_PUBLIC_LINK = 'Revoked Asset Public Link',
  CREATED_ASSET_PUBLIC_LINK = 'Created Asset Public Link',
  FAILED_CREATE_ASSET_PUBLIC_LINK = 'Failed To Create Asset Public Link',
  COPIED_CREATED_ASSET_PUBLIC_LINK = 'Copied Created Asset Public Link',
  TOGGLE_ASSET_INTERNAL = 'Toggle Asset Internal',
  FAILED_TOGGLE_ASSET_INTERNAL = 'Failed Toggle Asset Internal',
  DELETE_COMPANY_LOGO = 'Remove Company Logo',
  EDIT_COMPANY_LOGO = 'Edit Company Logo',
  DELETE_ASSET_FAILED = 'Delete Asset Failed',
  SORTED_ASSET = 'Sorted Assets',
  SORTED_ASSET_FAILED = 'Sorted Assets Failed',
  FILTERED_ASSET = 'Filtered Assets',
  FILTERED_ASSET_FAILED = 'Filter Assets Failed',
  CANCEL_UPLOAD = 'Cancelled Upload',
  UPLOAD_FAILED = 'Upload Asset failed',
  CLICKED_UPLOAD_ASSET = 'Clicked Upload Asset',
  CLICKED_NEW_ASSET = 'Clicked Add Assets',
  DOWNLOAD_FILE = 'Clicked Download Asset',
  DOWNLOAD_FILE_FAILED = 'Download Asset Failed',
  DOWNLOAD_FILE_SUCCESS = 'Downloaded Asset',
  RETURNED_SEARCHED_ASSETS = 'Returned Search Result for Assets',
  ROLE_ADDED = 'Added Role to Organisation',
  ROLE_NAME_UPDATED = 'Role Name Updated',
  STAKEHOLDER_CONTACT_HOVER = 'Stakeholder Contact Hover',
  ACCESS_STAKEHOLDER_CONTACT_HIGHLIGHTS = 'Access Stakeholder Contact Highlights',
  STAKEHOLDER_ADDED = 'Added Stakeholder to Role',
  PLACEHOLDER_ADDED = 'Added Placeholder to Role',
  STAKEHOLDER_REMOVED = 'Removed Stakeholder from Role',
  PLACEHOLDER_REMOVED = 'Removed Placeholder from Role',
  CREATE_NEW_ROLES_CANCELLED = 'Cancelled out of Adding New Roles',
  ADD_STAKEHOLDERS_CANCELLED = 'Cancelled out of Adding Stakeholders',
  ADD_PLACEHOLDERS_CANCELLED = 'Cancelled out of Adding Placeholders',
  REMOVE_CONTACT_CANCELLED = 'Cancelled out of Removing Contact',
  CLICKED_EDIT_TIMELINE = 'Clicked Edit Timeline',
  CLICKED_ENABLE_CUSTOMER_VIEW = 'Clicked Enable Customer View Timeline',
  CLICKED_TIMELINE_ORDER = 'Clicked on Timeline Order',
  SAVE_TIMELINE = 'Clicked on Save Timeline Button',
  CLICKED_DATEPICKER_TIMELINE = 'Clicked on Datepicker of Timeline',
  CLICKED_ADD_TIMELINE = 'Click Add Timeline',
  CLICKED_REMOVE_TIMELINE = 'Click Remove Timeline',
  OPPORTUNITY_SELECTED = 'Set Selected Opportunity',
  OPPORTUNITY_SEARCH = 'Opportunity Search',
  MSP_SELECTED = 'Set Selected Msp',
  BULK_ASSIGNED_INVITEES = 'Bulk Assigned Invitees',
  TASK_DUE_DATE_CHANGED_ALL_TASK = 'Task due dates is changed using all task',
  TASK_ASSIGNEE_CHANGED_ALL_TASK = 'Assignee updated from all task ',
  TASK_STATUS_CHANGED_ALL_TASK = 'Task status is changed using all task',
  ADD_USER_ON_SHARE_CLICKED = 'Clicked Add User on Share',
  CLICKED_NOTIFICATION = 'Clicked on notification bell icon',
  MARKED_READ_NOTIFICATION = 'Marked read notification',
  MARKED_ALL_READ_NOTIFICATION = 'Marked all read notification',
  NOTIFICATION_FILTERS = 'Clicked on notification filters',
  CLICKED_NOTIFICATION_BY_TYPE_TAB = 'Clicked on notification filter byType tab',
  CLICKED_INSIGHTS_ICON = 'Clicked Insights Icon',
  VIEW_MSP_ANALYTICS = 'Clicked View MSP Analytics',
  VIEW_ENGAGEMENT_INSIGHTS = 'Clicked Engagement Analytics on MSP Page',
  VIEW_STAKEHOLDER_INSIGHTS = 'Clicked Highlights Block in Stakeholder Info Popup',
  GROUP_BY_SELECTED = 'Clicked Group By',
  FILTER_BY_SELECTED = 'Clicked Filter By',
  CLICKED_MEDIA_LEADERBOARD_SHOW_ALL = 'Clicked Media Leaderboard Show All Button',
  CLICKED_USER_LEADERBOARD_SHOW_ALL = 'Clicked User Leaderboard Show All Button',
  DASHBOARD_MSP_SEARCH = 'Search For MSP',
  LOADING_MORE_MSP = 'Loading More Msp',
  LOADING_MORE_TASK = 'Loading More Task',
  ACCESSED_ALL_TASK = 'Accessed All Task',
  ACCESSED_EXTERNAL_VIEW = 'Accessed External View',
  UPDATE_MSP_TITLE = 'Update MSP Title',
  REMOVE_USER_ACCESS_FROM_MSP = 'Remove User Access From MSP',
  CLICKED_USER_UPDATE_ACCESS = 'Clicked User Update Access',
  UPDATE_USER_ACCESS = 'Update User Access',
  NEW_USER_ADDED = 'New User Added',
  BUSINESS_OBJECTIVE_ADDED = 'Business Objective Added',
  TIMELINE_ORDER_SWITCHED = 'Timeline Order Switch',
  REMOVE_TIMELINE_STAGE = 'Timeline Stage Removed',
  TIMELINE_STAGE_ADDED = 'Timeline Stage Added',
  CLICKED_ADD_FILES = 'Clicked Add Files',
  CLICKED_NEW_MILESTONE_BUTTON = 'New Milestone Button Clicked',
  MILESTONE_TEMPLATE_SEARCH = 'Milestone Template Search',
  MILESTONE_TEMPLATE_SELECTED = 'Milestone Template Selected',
  TASK_SEARCH = 'Task Search',
  UPDATE_MILESTONE_NAME = 'Update Milestone Name',
  UPDATE_MILESTONE_DATE = 'Update Milestone Date',
  REORDER_TASK = 'Task Sequence Rearranged',
  CLICKED_TASK_DETAIL_BUTTON = 'Clicked Task Detail Button',
  CLICKED_MILESTONE_DETAIL_BUTTON = 'Clicked Milestone Detail Button',
  CLICKED_MILESTONE_RESOURCE_DETAIL = 'Clicked Milestone Resource',
  MEDIA_ATTACHED = 'Media Attached',
  MILESTONE_DETAILS_UPDATED = 'Milestone Detail Updated',
  EXPENDED_ALL_MILESTONE = 'Expended All Milestone',
  COLLAPSED_ALL_MILESTONE = 'Collapsed All Milestone',
  STAKEHOLDER_ROLE_HIDE = 'Stakeholder Role Hide',
  MEDDIC_NOTES_ADDED_UPDATED = 'Meddic Notes Added/Updated',
  MEDDIC_SCORE_ADDED_UPDATED = 'Meddic Score Added/Updated',
  ADDED_MEDDIC_ECONOMIC_BUYER = 'Added Meddic Economic Buyer',
  ADDED_CHAMPION = 'Added Meddic Champion',
  MEDDIC_DETAILS_ADDED_UPDATED = 'Meddic Details Added/Updated',
  SYNC_METHODOLOGY = 'Synced Mehodology',
  FAILED_SYNC_METHODOLOGY = 'Failed Synced Mehodology',
  NOTIFICATION_MARKED_AS_READ = 'Notification Mark As Read',
  NOTIFICATION_MARKED_ALL_AS_READ = 'Notification Mark All As Read',
  ACCESSED_OVERDUE_TASK = 'Accessed Overdue Task',
  MARKED_ALL_MILESTONE_COMPLETED = 'Marked All Milestone Completed',
  CLICKED_MSP_ARCHIVE = 'Clicked Msp Archive',
  MSP_ARCHIVED = 'Archived MSP',
  CLICKED_MSP_ARCHIVE_RESTORE = 'Clicked Msp Archive Restore',
  ARCHIVED_MSP_RESTORED = 'Archived Msp Restored',
  SHARED_MSP = 'Shared MSP',
  CLICKED_EDIT_USER_PROFILE = 'Clicked Edit User Profile',
  SELECTED_OPPORTUNITY = 'Selected Opportunity',
  OPPORTUNITY_LINKED_TO_MSP = 'Opportunity Linked To MSP',
  COMPANY_LOGO_UPDATED = 'Company Logo Replaced',
  TASK_DUE_DATE_REMOVED = 'Task Due Date Removed',
  ACCESSED_FILTERS = 'Accessed Filters',

  // Influencer Map
  INFLUENCER_MAP_TAB_CLICKED = 'CLICKED#BTN#RMAP_TAB',
  INFLUENCER_MAP_CANVAS_ZOOM_IN = 'CLICKED#BTN#CANVAS_ZOOM_IN',
  INFLUENCER_MAP_CANVAS_ZOOM_OUT = 'CLICKED#BTN#CANVAS_ZOOM_OUT',
  INFLUENCER_MAP_CANVAS_FIT_VIEW = 'CLICKED#BTN#CANVAS_FIT_VIEW',
  INFLUENCER_MAP_CANVAS_DOWNLOAD = 'CLICKED#BTN#DOWNLOAD',
  CONTACT_CARD_DROPPED_TO_CANVAS = 'DROPPED#LIST_ITEM#CONTACT',
  CONTACT_CARD_CONNECTION_LINE_CREATED = 'CREATED#LINE#CONNECTION_LINE',
  CONTACT_CARD_CONNECTION_LINE_CLICKED = 'CLICKED#LINE#CANVAS_LINE',
  CONTACT_CARD_NOTES_CLICKED = 'CLICKED#BTN#CONTACT_CARD_NOTE',
  CONTACT_CARD_EDIT_CLICKED = 'CLICKED#BTN#CONTACT_CARD_NOTE_EDIT',
  CONTACT_CARD_REMOVE_CLICKED = 'Contact card remove clicked',
  LEFT_PANEL_CONTACT_EDIT_CLICKED = 'CLICKED#BTN#CONTACT_EDIT',
  CONNECTION_LINE_NOTES_CLICKED = 'CLICKED#BTN#CONTACT_CARD_EDGE_NOTE',
  CONNECTION_LINE_NOTES_DELETE_CLICKED = 'CLICKED#BTN#CONTACT_CARD_EDGE_NOTE_DELETE',
  CLICKED_COMMENT_ICON = 'CLICKED#BTN#CONTACT_CARD_NOTE',
  UPDATED_COMMENT = 'SAVED#CONTACT_CARD_NOTE',
  INFLUENCER_MAP_CONTACT_SEARCHED = 'CLICKED#INPUT#CONTACT_SEARCH',
  ACCESSED_INFLUENCER_MAP = 'OPENED#RMAP',
  CLOSED_INFLUENCER_MAP = 'CLOSED#RMAP',
  INFLUENCER_MAP_CONTACT_PROFILE_EDITED = 'UPDATED#CONTACT_PROFILE',
  INFLUENCER_MAP_CONTACT_REMOVED = 'CLICKED#BTN#CONTACT_DELETE',
  LINE_COLOR_CHANGED = 'CLICKED#SELECT_OPTION#LINE_COLOR',
  ADD_LINE_NOTE = 'CLICKED#BTN#LINE_NOTES_ADD',
  DELETE_LINE_NOTE = 'CLICKED#BTN#LINE_NOTES_DELETE',
  DELETE_EDGE_LINE = 'CLICKED#BTN#LINE_REMOVE',
  DELETE_CONTACT_CARD = 'CLICKED#BTN#DELETE_CONTACT_CARD',
  INFLUENCER_STRENGTH_ADDED_UPDATED = 'CLICKED#SELECT#NEW_CONTACT_RELATIONSHIP_STRENGTH',
  INFLUENCER_CONTACT_CARD_UPDATED = 'UPDATED#INFLUENCER_CONTACT_CARD',
  RMAP_OPEN_IN_NEW_TAB = 'CLICKED#BTN#CANVAS_OPEN_IN_NEW_TAB',
  FILTERS_BUYER_SENTIMENT = 'CLICKED#SELECT_OPTION#FILTERS_BUYER_SENTIMENT',
  FILTERS_STAKEHOLDER = 'CLICKED#SELECT_OPTION#FILTERS_STAKEHOLDER',
  FILTERS_RELATIONSHIP_STRENGTH = 'CLICKED#SELECT_OPTION#FILTERS_RELATIONSHIP_STRENGTH',
  OPEN_CONTACT_PANEL = 'HOVERED#PANEL#CONTACT',
  CONTACT_ADD_USER = 'CLICKED#BTN#CONTACT_ADD_USER',
  CLOSE_CONTACT_PANEL = 'CLOSED#PANEL#CONTACT',
  CONTACT_CARD_CONTACT_ROLE_APPLY = 'CLICKED#BTN#CONTACT_CARD_CONTACT_ROLE_APPLY',
  CONTACT_CARD_CONTACT_ROLE_CLEAR = 'CLICKED#BTN#CONTACT_CARD_CONTACT_ROLE_CLEAR',
  CONTACT_CARD_CONTACT_ROLE = 'CLICKED#SELECT#CONTACT_CARD_CONTACT_ROLE',
  // Influencer Map LinkedIn
  LINKEDIN_PROFILE_COPIED = 'CLICKED#BTN#CONTACT_CARD_LINKEDIN_URL_COPY',
  LINKEDIN_PROFILE_FETCHED = 'FETCH#CONTACT_CARD_LINKEDIN_PROFILE',
  LINKEDIN_ICON_PROFILE_CLICKED = 'HOVERED#BTN#CONTACT_CARD_LINKEDIN_ICON',
  LINKEDIN_POPUP_PROFILE_CLICKED = 'CLICKED#BTN#CONTACT_CARD_LINKEDIN_VIEW_PROFILE',
  LINKEDIN_PROFILE_URL_CHANGED = 'CLICKED#BTN#CONTACT_CARD_LINKEDIN_URL_UPDATE',
  LINKEDIN_PROFILE_URL_ADDED = 'ADD#INPUT#CONTACT_CARD_LINKEDIN_URL',
}
