/* eslint-disable no-nested-ternary */
import { Button, CircularProgress } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { Dispatch } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { toolTip } from '../../common-language';
import { AssigneeSearchTabType } from '../../alignx/msp-view/model';
import { IStakeholder } from '../../alignx/msp-view/stakeholders/stakeholder-redux-store';
import NoUser from '../../assets/no-stakeholder.svg';
import { RootState } from '../../common/redux-store';
import {
  setSearchText,
  setSelectedUserList,
} from '../../common/redux-store/reducers/search-user-reducer';
import { CompanyType, User } from '../../generated-types';
import { CommonAvatar } from '../avatar/new-avatar';
import { showToast } from '../hooks/use-toastify';
import { DotIcon, RightTickIcon } from '../icons';
import { BaInput } from '../input';
import { CustomToolTip } from '../tool-tip/tooltip';
import { Typography } from '../typography';
import UserChip from './search-user-chips';
import { getFullName } from '../../common/utilities/utils';

export enum SearchType {
  STAKEHOLDER = 'STAKEHOLDER',
  ADDTASK = 'ADDTASK',
  MEDDIC = 'MEDDIC',
}

interface ISearchUserProps {
  onSearch: (term: string) => void;
  isLoading: boolean;
  userList: User[] | undefined;
  error: Error | undefined;
  initialSelectedUsers: User[];
  userLimit?: number;
  isMulti: boolean;
  onNewUserOpen: () => void;
  onPlaceholderSearch?: (term: string, placeholderList: User[]) => void;
  inputPlaceholderMessage?: string;
  searchType?: SearchType;
  onUserPlaceholderAssignInTask: (selectedUser: User[]) => void;
}

const mapStateToProps = (state: RootState) => ({
  selectedUser: state.searchComponent.selectedUser,
  searchText: state.searchComponent.searchText,
  selectedTab: state.searchComponent.taskAssigneeSelectedTab,
  placeholderList: state.milestoneDetail.placeholderData.placeholderList,
  isTaskInternal: state?.milestoneDetail?.assigneeModal?.isTaskInternal,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedUserList,
      setSearchText,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector> & ISearchUserProps;

function SearchAddUser(props: PropsFromRedux) {
  const {
    onSearch,
    isLoading = false,
    userList = [],
    error,
    initialSelectedUsers,
    userLimit = 20,
    selectedUser,
    setSelectedUserList,
    setSearchText,
    searchText,
    isMulti,
    selectedTab,
    onPlaceholderSearch,
    inputPlaceholderMessage,
    isTaskInternal,
    searchType,
    onUserPlaceholderAssignInTask,
    onNewUserOpen,
  } = props;
  let { placeholderList = [] } = props;
  if (isTaskInternal && placeholderList && Array.isArray(placeholderList))
    placeholderList = placeholderList.filter(
      (item) => item?.userType === CompanyType?.SELLER,
    );
  const searchUser = useCallback((name?: string) => {
    setSearchText(name ?? '');
  }, []);

  useEffect(() => {
    if (onSearch && typeof onSearch === 'function' && searchText) {
      if (selectedTab === AssigneeSearchTabType.PLACEHOLDER) {
        if (onPlaceholderSearch && typeof onPlaceholderSearch === 'function')
          onPlaceholderSearch(searchText, placeholderList as unknown as User[]);
      } else {
        onSearch(searchText);
      }
    }
  }, [onSearch, searchText]);

  const onSelectionChange = useCallback(
    (user: User) => {
      if (userLimit === 1 && searchType === SearchType.ADDTASK) {
        onUserPlaceholderAssignInTask([user] as User[]);
      }
      if (isMulti || selectedUser?.length === 0) {
        const index = selectedUser?.findIndex(
          ({ email }) => email === user?.email,
        );
        if (index === -1) setSelectedUserList(selectedUser?.concat(user));
        else {
          setSelectedUserList(
            selectedUser?.filter(({ email }) => email !== user?.email),
          );
        }
        setSearchText('');
      }
    },
    [
      selectedUser,
      setSelectedUserList,
      userLimit,
      onUserPlaceholderAssignInTask,
    ],
  );
  const isPlaceholderTabSelected =
    selectedTab === AssigneeSearchTabType.PLACEHOLDER;
  const ChipList =
    isMulti &&
    selectedUser?.map((user) => (
      <UserChip
        key={user.id}
        member={user as IStakeholder}
        onClickRemove={(_event, _id, email) => {
          setSelectedUserList(
            selectedUser.filter(({ email: _email }) => email !== _email),
          );
        }}
        height={28}
      />
    ));

  return (
    <div className="w-400 space-y-4">
      <div className="flex w-full">
        <BaInput
          type="search"
          className="ml-2.5 mr-3 w-400"
          debounceTime={200}
          parentValue=""
          startIcon={ChipList}
          onChange={searchUser}
          placeholder={inputPlaceholderMessage || 'Search Assignee'}
        />
        {!isPlaceholderTabSelected && (
          <CustomToolTip title={toolTip['MspPlanView.addUser']}>
            <div
              role="presentation"
              onClick={onNewUserOpen}
              className=" mr-1 box-content flex h-5 cursor-pointer items-center justify-center rounded border border-solid border-primary bg-primary p-2 text-white"
            >
              <FiUserPlus size={16} />
            </div>
          </CustomToolTip>
        )}
      </div>
      <div className="box-border h-48 max-h-60 min-h-full w-full overflow-y-auto overflow-x-hidden">
        {error ? (
          <div className="flex h-full flex-col items-center justify-center space-y-4">
            <Typography variant="body-14-regular" className="text-error">
              Something went wrong
            </Typography>
            <Typography variant="body-13-regular" className="text-mid">
              {error?.message}
            </Typography>
          </div>
        ) : isLoading ? (
          <div className="flex h-full items-center justify-center">
            <CircularProgress size={24} />
          </div>
        ) : userList.length === 0 ? (
          <div className="flex flex-col items-center p-8">
            {!searchText && (
              <img alt="user" src={NoUser as string} width={40} height={40} />
            )}
            <Typography variant="label-12-semibold">
              {!searchText
                ? `Search ${
                    isPlaceholderTabSelected ? 'placeholder' : 'user'
                  } in MSP`
                : `${
                    isPlaceholderTabSelected ? 'Placeholder' : 'User'
                  } not found in the MSP`}
            </Typography>
            {selectedTab !== AssigneeSearchTabType.PLACEHOLDER && searchText && (
              <Button variant="text" color="primary" onClick={onNewUserOpen}>
                Create User
              </Button>
            )}
          </div>
        ) : (
          userList?.map((user, _index) => {
            const { firstName, email, primaryId, userType, id } = user;
            const isSelected =
              selectedUser?.findIndex(({ email: _email, id: _id }) => {
                if (email) {
                  return email === _email;
                }
                return id === _id;
              }) > -1;
            const prevSelected =
              initialSelectedUsers?.findIndex(
                ({ email: _email }) => email === _email,
              ) > -1;
            const limitCount = userLimit - (initialSelectedUsers?.length ?? 0);
            const exceedErrorMsg = `The ${
              isPlaceholderTabSelected ? 'placeholder' : 'stakeholder'
            } addition limit is exceeded`;
            const limitErrorMsg = `Can't add more than ${limitCount} stakeholders`;

            return (
              <div
                key={primaryId || id || email}
                role="presentation"
                onClick={() => {
                  if (
                    (selectedUser?.length ?? 0) < limitCount &&
                    !prevSelected
                  ) {
                    onSelectionChange(user);
                  } else if (!prevSelected) {
                    showToast(
                      `${limitCount === 0 ? exceedErrorMsg : limitErrorMsg}`,
                      {
                        variant: 'error',
                      },
                    );
                  }
                }}
                className={clsx(
                  isSelected && 'bg-primary-100',
                  prevSelected
                    ? 'bg-mid-50'
                    : 'cursor-pointer hover:bg-light-bg',
                  'my-2 box-border flex items-center justify-between px-2 py-1',
                )}
              >
                <div className="box-border flex w-full items-center space-x-4">
                  <CommonAvatar
                    fullName={getFullName(user)}
                    email={email ?? ''}
                    className=""
                    userType={userType}
                    width={24}
                    fontSize={8}
                  />
                  <CustomToolTip
                    title={firstName as string}
                    placement="top-start"
                    arrow
                    disableHoverListener={!email}
                  >
                    <Typography
                      variant="body-13-semibold"
                      className={`truncate max-w-${
                        isPlaceholderTabSelected ? '32ch' : '24ch'
                      } capitalize text-neutral`}
                    >
                      {getFullName(user)}
                    </Typography>
                  </CustomToolTip>
                  {email && (
                    <div className="flex truncate">
                      <DotIcon className="pt-1 text-12 text-mid" />
                      <Typography
                        variant="label-12-regular"
                        className="max-w-24ch truncate text-mid"
                      >
                        {email}
                      </Typography>
                    </div>
                  )}
                </div>
                {isSelected && (
                  <RightTickIcon className="text-18 text-primary" />
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
export default connector(SearchAddUser);
