import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_ALL_TASKS = gql`
  ${CORE_GRAPH_ERROR}
  query allTasksWithFilters(
    $operations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    taskQuery {
      allTasksWithFilters(
        operations: $operations
        pageConfiguration: $pageConfiguration
      ) {
        __typename
        ...CoreErrorFields
        ... on TaskConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
          }
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Task {
                id
                name
                dueDate
                status
                isInternal
                description
                assigneeType
                milestone {
                  __typename
                  ...CoreErrorFields
                  ... on Milestone {
                    id
                    name
                  }
                }
                msp {
                  __typename
                  ...CoreErrorFields
                  ... on Msp {
                    id
                    name
                    buyerCompany {
                      __typename
                      ...CoreErrorFields
                      ... on Company {
                        id
                        companyName
                        displayName
                      }
                    }
                    sellerCompany {
                      __typename
                      ...CoreErrorFields
                      ... on Company {
                        id
                        companyName
                        displayName
                      }
                    }
                    userBridge {
                      ...CoreErrorFields
                      ... on MspUserBridge {
                        permissions {
                          __typename
                          ...CoreErrorFields
                          ... on MspPermissionPayload {
                            items
                          }
                        }
                      }
                    }
                  }
                }
                assignee {
                  __typename
                  ...CoreErrorFields
                  ... on User {
                    id
                    firstName
                    lastName
                    email
                    userType
                  }
                  ... on Placeholder {
                    placeholderId: id
                    name
                    companyType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ALL_TASKS_FILTERS = gql`
  ${CORE_GRAPH_ERROR}
  query allTaskFilters {
    taskQuery {
      allTaskFilters {
        ...CoreErrorFields
        ... on FilterItemsPayload {
          items {
            id
            key
            displayName
            values
            isVisible
            filterType
          }
        }
      }
    }
  }
`;

export const ALL_TASKS_FILTERS_COUNT = gql`
  ${CORE_GRAPH_ERROR}
  query allTaskFiltersCount($ids: [ID!]!) {
    taskQuery {
      allTaskFiltersCount(ids: $ids) {
        ...CoreErrorFields
        ... on FilterItemsCountPayload {
          items {
            id
            key
            count
          }
        }
      }
    }
  }
`;
