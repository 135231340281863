import { ReactElement } from 'react';

function TableHeadCommonLayout(props: {
  children: ReactElement;
  className?: string;
}) {
  const { children, className } = props;

  return (
    <div
      className={`${
        className ?? ''
      } dashboard-table-header-section box-border w-full space-y-2 whitespace-nowrap border-0 border-t border-solid  border-mid-50 bg-light-bg py-2 px-7`}
    >
      {children}
    </div>
  );
}

export default TableHeadCommonLayout;
