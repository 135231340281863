import type { RumEvent } from '@datadog/browser-rum';

// errors to be whitelisted on Datadog
const WHITE_LIST = [
  'Script error',
  'ResizeObserver loop limit exceeded',
  "TypeError: Cannot read properties of undefined (reading 'isHidden')",
  'FORBIDDEN_ERROR:ACCOUNT_PLAN_NOT_FOUND',
];

export function excludeWhitelistedErrors(event: RumEvent) {
  let isIncluded = true;

  WHITE_LIST.forEach((whiteListedError) => {
    if (
      event?.type === 'error' &&
      event?.error?.message?.includes(whiteListedError)
    ) {
      isIncluded = false;
    }
  });

  return isIncluded;
}
