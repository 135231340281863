import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../graphql/fragments/error';

export const GET_FILTERED_MSP_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query GetFilteredMspList(
    $mspOperations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    alignxQuery {
      dashboard {
        __typename
        ...CoreErrorFields
        ... on Alignx {
          msps(
            operations: $mspOperations
            pageConfiguration: $pageConfiguration
          ) {
            ...CoreErrorFields
            ... on MspConnection {
              pageInfo {
                hasNextPage
                hasPreviousPage
                afterCursor
                beforeCursor
              }
              edges {
                node {
                  ...CoreErrorFields
                  ... on Msp {
                    id
                    name
                    isOwner
                    createdBy {
                      ... on User {
                        lastName
                        firstName
                      }
                    }
                    sharedBy {
                      ... on User {
                        lastName
                        firstName
                      }
                    }
                    opportunity {
                      ... on Opportunity {
                        id
                        stageName
                        closeDate
                      }
                    }
                    buyerCompany {
                      __typename
                      ...CoreErrorFields
                      ... on Company {
                        id
                        logo {
                          ... on Logo {
                            id
                            fileName
                            url
                          }
                        }
                      }
                    }
                    analytics {
                      ...CoreErrorFields
                      ... on MspAnalytics {
                        track {
                          ... on MspTrack {
                            mspTrackStatus
                            mspSlip
                          }
                        }
                        mspStage
                        mspStatus
                        mspProgress
                        lastActivity {
                          ... on LatestActivity {
                            activityAt
                            activityById
                          }
                        }
                        lastExternalActivity {
                          ... on LatestActivity {
                            activityAt
                            activityById
                          }
                        }
                        opportunity {
                          ... on AnalyticsOpportunity {
                            opportunityCloseDate
                            opportunityStage
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MSP_FILTERS = gql`
  ${CORE_GRAPH_ERROR}
  query AlignxQuery {
    alignxQuery {
      dashboard {
        ...CoreErrorFields
        ... on Alignx {
          dashboardFiltersV1 {
            __typename
            ... on FilterItemsPayload {
              items {
                key
                displayName
                values
                isVisible
              }
            }
          }
          dashboardColumns {
            ... on DashboardColumnPayload {
              items {
                ... on DashboardColumn {
                  displayName
                  key
                  isVisible
                  isSortable
                }
              }
            }
          }
        }
      }
    }
  }
`;
