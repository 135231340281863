import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { showToast } from '../../../components/hooks/use-toastify';
import { NotificationStatus, PushNotificationMutationBulkUpdatePushNotificationStatusArgs } from '../../../generated-types';
import { addToSavingList, removeFromSavingList } from '../../save-indicator/save-redux';
import { EMspSaveIndicator } from '../model';
import { MARKED_ALL_READ, ResultMarkedAllReadNotification } from './graphql';
import { setMarkAllReadNotification } from './notification-redux-store';

export function useMarkedReadNotification() {
  const dispatch = useDispatch();

  const [markedAllRead] = useMutation<
    ResultMarkedAllReadNotification,
    PushNotificationMutationBulkUpdatePushNotificationStatusArgs
  >(MARKED_ALL_READ);

  const markedReadNotification = (
    userId: string,
    status: NotificationStatus,
  ) => {
    if (status !== NotificationStatus.ACKNOWLEDGED) {
      dispatch(addToSavingList({ saving: true, id: EMspSaveIndicator.MARK_AS_READ }));
      dispatch(trackEvent(TRACKING_CONSTANTS.MARKED_ALL_READ_NOTIFICATION));
    }
    markedAllRead({
      variables: {
        input: {
          userId,
          startTimestamp: dayjs().valueOf(),
          status,
        },
      },
    })
      .then((resp) => {
        if (
          resp.data?.pushNotificationMutation?.bulkUpdatePushNotificationStatus
            .__typename !== 'GraphqlError'
        ) {
          if (status === NotificationStatus.READ) dispatch(setMarkAllReadNotification(status));
        } else {
          throw new Error('Something went wrong');
        }
        if (status !== NotificationStatus.ACKNOWLEDGED) dispatch(removeFromSavingList(EMspSaveIndicator.MARK_AS_READ));
      })
      .catch(() => {
        showToast(
          <FormattedMessage
            id="Notification.markAllRead"
            defaultMessage="Mark all as read could not be saved, please try again"
          />,
          {
            variant: 'error',
          },
        );
        dispatch(removeFromSavingList(EMspSaveIndicator.MARK_AS_READ));
      });
  };

  return { markedReadNotification };
}
