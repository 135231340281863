/* eslint-disable no-undef */
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { boxShadow } from '../../common/theme-config';

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    poster: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    poster?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true;
  }
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    orange: Palette['primary'];
    green: Palette['primary'];
    lightPink: Palette['primary'];
    lightBlue: Palette['primary'];
    greenShadow: Palette['primary'];
    warningUnderline: Palette['primary'];
    disabledTextColor: Palette['primary'];
    primaryColors: Palette['primary'][];
    secondaryColors: Palette['primary'][];
  }
  interface PaletteOptions {
    orange: PaletteOptions['primary'] | SimplePaletteColorOptions;
    green: PaletteOptions['primary'] | SimplePaletteColorOptions;
    lightPink: PaletteOptions['primary'] | SimplePaletteColorOptions;
    lightBlue: PaletteOptions['primary'] | SimplePaletteColorOptions;
    greenShadow: PaletteOptions['primary'] | SimplePaletteColorOptions;
    warningUnderline: PaletteOptions['primary'] | SimplePaletteColorOptions;
    disabledTextColor: PaletteOptions['primary'] | SimplePaletteColorOptions;
    primaryColors: PaletteOptions['primary'][] | SimplePaletteColorOptions[];
    secondaryColors: Palette['primary'][] | SimplePaletteColorOptions[];
  }
}

const fontFamily = '"Lato", "Helvetica", "Arial", \'sans-serif\'';
const theme = createTheme(adaptV4Theme({
  palette: {
    orange: {
      light: '#fc755710',
      main: '#fc7557',
      dark: '#F2994A',
    },
    green: {
      light: '#E7FFF1',
      main: '#27AE60',
      dark: '#3EC878',
    },
    lightPink: {
      main: '#F0D6DF',
      dark: '#FD4954',
      contrastText: '#67263B',
    },
    lightBlue: {
      main: '#D6DCF0',
      light: '#E8F4FE',
      dark: '#485cf0',
      contrastText: '#273B80',
    },
    grey: {
      50: '#F6F8FA',
      300: '#929292',
      700: '#E3E3E3',
      900: '#FAFAFA',
    },
    greenShadow: {
      main: '0px 3px 15px 2px rgba(39, 174, 96, 0.1)',
      dark: '#59758A',
    },
    disabledTextColor: { main: '#25213B' },
    primary: { main: '#485cf0', light: '#B6BEF9' },
    secondary: { main: '#2ad2f4' },
    warning: {
      main: '#FC7557',
      dark: '#FD4954',
      light: 'rgba(253,73,84, 0.1)',
    },
    warningUnderline: { main: '#E92935' },
    primaryColors: [
      { main: '#F0D6DF', dark: '#FD4954', contrastText: '#58565E' },
      { main: '#F5FFEF', contrastText: '#58565E' },
      { main: '#D4FED0', contrastText: '#58565E' },
      { main: '#B0D9FE', contrastText: '#58565E' },
      { main: '#FECC91', contrastText: '#58565E' },
    ],
    secondaryColors: [
      { main: '#D6DCF0', dark: '#485cf0', contrastText: '#58565E' },
      { main: '#D0F6FE', contrastText: '#58565E' },
      { main: '#E1B1FE', contrastText: '#58565E' },
      { main: '#FD91B1', contrastText: '#58565E' },
      { main: '#EFF3FF', contrastText: '#58565E' },
    ],
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiListItem: {
      dense: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      gutters: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },

    MuiDialogActions: {
      root: {
        paddingRight: 24,
        paddingLeft: 24,
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 'unset',
      },
    },
    MuiTableRow: {
      hover: {
        '&$hover:hover': {
          backgroundColor: '#F0F8FF',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none', // css style to remove capitalization
        fontWeight: 500,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none', // css style to remove capitalization
        fontWeight: 400,
        // border: '1px solid ',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
        // fontSize: '0.875rem',
      },
    },
    MuiChip: {
      root: {
        color: '#000',
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: boxShadow[100],
      },
    },
    MuiMenu: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },

  typography: {
    fontFamily,
    poster: {
      fontFamily,
      fontWeight: 600,
      fontSize: 12,
    },
    h2: {
      fontFamily,
      fontWeight: 600,
      fontSize: 40,
    },
    h3: {
      fontFamily,
      fontWeight: 600,
      fontSize: 36,
    },
    h4: {
      fontFamily,
      fontWeight: 600,
      fontSize: 24,
    },
    h5: {
      fontFamily,
      fontWeight: 600,
      fontSize: 20,
    },
    h6: {
      fontFamily,
      fontWeight: 600,
      fontSize: 18,
    },
    subtitle1: {
      fontFamily,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
    },
    subtitle2: {
      fontFamily,
      fontWeight: 400,
      fontSize: 14,
    },
    button: {
      fontFamily,
      fontWeight: 600,
      fontSize: 14,
    },
    body1: {
      fontFamily,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
    },
    body2: {
      fontFamily,
      fontWeight: 400,
      fontSize: 12,
    },
  },
}));

export default theme;
