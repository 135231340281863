import { NewAvatar, Typography } from '../../../components';
import { StatusCardLabel } from '../../../features/analytics/components/atoms';
import { User } from '../../../generated-types';
import UserExclamation from '../../../assets/user-exclamation.svg';

interface LastAccessCardProps {
  label: string;
  content: string;
  lastAccessUser: User;
  hasData: boolean;
}

function getFullName(user: User) {
  if (user) {
    const { firstName, lastName, email } = user;
    return `${firstName ?? ''}${lastName ?? ''}`
      ? `${firstName ?? ''} ${lastName ?? ''}`
      : email ?? '';
  }
  return 'Anonymous';
}

function LastAccessCard({
  label,
  content,
  lastAccessUser,
  hasData,
}: LastAccessCardProps) {
  return (
    <div className="w-[180px] rounded border border-solid border-[#DCE3EA] pl-4 pb-3">
      <StatusCardLabel label={label} />

      {hasData ? (
        <>
          <Typography variant="heading3-bold" className="mb-2 text-mid">
            {content}
          </Typography>

          <div className="mt-1 flex flex-row justify-items-center align-baseline">
            <NewAvatar
              size="small"
              fullName={getFullName(lastAccessUser)}
              email={lastAccessUser?.email ?? ''}
              className="mr-1 space-x-4 text-neutral-300"
              userType={lastAccessUser?.userType ?? ''}
            />
            <Typography
              variant="label-12-regular"
              className="pt-1 text-[11px] text-neutral-450"
            >
              {getFullName(lastAccessUser)}
            </Typography>
          </div>
        </>
      ) : (
        <div className="flex flex-col pt-1">
          <img
            alt="user"
            src={UserExclamation as string}
            width={17}
            height={17}
            className="text-mid"
          />
          <Typography
            variant="label-12-medium"
            className="mt-4 text-[12px] text-mid"
          >
            No external activity
          </Typography>
        </div>
      )}
    </div>
  );
}
export default LastAccessCard;
