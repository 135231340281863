import { toast } from 'react-toastify';

export function showToast(
  message: JSX.Element | string,
  options: { variant: string },
) {
  switch (options.variant) {
    case 'success': {
      toast.success(message);
      break;
    }
    case 'info': {
      toast.info(message);
      break;
    }
    case 'warning': {
      toast.warning(message);
      break;
    }
    case 'error': {
      toast.error(message);
      break;
    }
    default: {
      toast(message);
    }
  }
}
