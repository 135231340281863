import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_OVERDUE_TASKS_IN_MILESTONE = gql`
  ${CORE_GRAPH_ERROR}
  query overdueTasks(
    $operations: Operations
  ) {
    taskQuery {
      overdueTasks(operations: $operations) {
        __typename
        ...CoreErrorFields
        ...on TaskConnection {
            edges {
      node {
        __typename
        ...CoreErrorFields
        ... on Task {
          id
          name
          dueDate
          status
          isInternal
          assigneeType
          milestoneId
          milestone {
                    __typename
                    ...CoreErrorFields
                    ... on Milestone {
                      id
                      name
                      isInternal
                      startDate
                      endDate
                    }
                  }
          assignee {
            __typename
            ...CoreErrorFields
            ... on User {
              id
              firstName
              lastName
              email
              userType
            }
             ... on Placeholder {
              placeholderId:id
              name
              companyType
            }
          }
        }
      }
    }
          }
        }
      }
    }
`;

export const GET_MSP_TRACK_STATUS = gql`
  ${CORE_GRAPH_ERROR}
  query getMspTrackStatus($id: ID!, $options: GetMspAnalyticsOptions) {
    mspQuery {
      getMsp(id: $id) {
        __typename
        ...CoreErrorFields
        ... on MspPayload {
          items {
            ...CoreErrorFields
            ... on Msp {
              id
              analytics(options: $options){
                ...CoreErrorFields
                ... on MspAnalytics {
                  track {
                    __typename
                    ... on MspTrack {
                      mspTrackStatus
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
