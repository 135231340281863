/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated-types/index';

import { api } from 'api/base-api';
module.hot?.accept();
export type GetOrganizationQueryVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organizationQuery?: {
    __typename?: 'OrganizationQuery';
    getOrganization:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'OrganizationPayload';
          items?: Array<
            | {
                __typename?: 'GraphqlError';
                code: string;
                message: string;
                type: Types.ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'Organization';
                orgId: string;
                orgType: Types.OrgType;
                orgName: string;
                state: Types.CompanyState;
                orgLogo?: {
                  __typename?: 'OrgLogo';
                  original?: string;
                  favicon?: string;
                };
                configs: {
                  __typename?: 'OrgConfig';
                  others?: { [key: string]: any };
                  salesforce?: {
                    __typename?: 'SalesforceConfig';
                    isEnabled?: boolean;
                    qualifiedStageNames?: Array<string>;
                  };
                  branding?: {
                    __typename?: 'BrandingConfig';
                    header?: {
                      __typename?: 'BrandingHeader';
                      color?: string;
                      textColor?: string;
                    };
                  };
                  methodology?: {
                    __typename?: 'MethodologyConfig';
                    isEnabled?: boolean;
                    type?: string;
                    displayName?: string;
                  };
                };
              }
          >;
        };
  };
};

export const GetOrganizationDocument = `
    query GetOrganization($orgId: ID!) {
  organizationQuery {
    getOrganization(orgId: $orgId) {
      __typename
      ... on GraphqlError {
        code
        message
        type
        helpLink
      }
      ... on OrganizationPayload {
        items {
          ... on GraphqlError {
            code
            message
            type
            helpLink
          }
          ... on Organization {
            __typename
            orgId
            orgType
            orgName
            state
            orgLogo {
              original
              favicon
            }
            configs {
              others
              salesforce {
                isEnabled
                qualifiedStageNames
              }
              branding {
                header {
                  color
                  textColor
                }
              }
              methodology {
                isEnabled
                type
                displayName
              }
            }
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetOrganization: build.query<
      GetOrganizationQuery,
      GetOrganizationQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationQuery, useLazyGetOrganizationQuery } =
  injectedRtkApi;
