import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pageEvent } from '../../analytics';

export function usePageViews() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      pageEvent('Page Event', {
        to: location.pathname,
        search: location.search,
      }),
    );
  }, [dispatch, location]);
}
