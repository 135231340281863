import { Dispatch } from '@reduxjs/toolkit';
import {
  KeycloakInitOptions,
  KeycloakInstance,
  KeycloakLoginOptions,
  KeycloakOnLoad,
} from 'keycloak-js';
import { createContext, ReactElement, useContext, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBaseUrl, getRedirectUrl } from '../../utils';
import { RootState } from '../redux-store';
import { setRefreshToken, setToken } from './auth-redux';
import {
  AuthRefreshSuccessHandler,
  AuthSuccessHandler,
} from './authentication-service';

interface KeycloakContextType {
  keycloak: KeycloakInstance;
  loginOptions: KeycloakLoginOptions;
  initOptions: KeycloakInitOptions;
  loadingComponent: any;
}

interface KeycloakProviderProps {
  /**
   * Keycloak instance
   */
  keycloak: KeycloakInstance;
  /**
   * keycloak login options
   */
  loginOptions?: KeycloakLoginOptions;
  /**
   * keycloak init options
   */
  initOptions?: KeycloakInitOptions;
  children: ReactElement;
  /**
   * the loading component to show while keycloak is initializing
   */
  loadingComponent?: any;
}

export const KeycloakContext = createContext<KeycloakContextType | undefined>(
  undefined,
);
KeycloakContext.displayName = 'KeycloakContext';

export const useKeycloakContext = (): KeycloakContextType => {
  const context = useContext(KeycloakContext);
  if (!context) throw new Error('You must use the KeycloakProvider');
  return context;
};

const mapStateToProps = (state: RootState) => {
  const { token, refreshToken } = state.auth;
  return {
    token,
    refreshToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setToken,
      setRefreshToken,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const KeycloakProvider = ({
  keycloak,
  loginOptions,
  initOptions,
  loadingComponent,
  children,
  setToken,
}: KeycloakProviderProps & PropsFromRedux) => {
  const redirectUri = getRedirectUrl();
  const baseUrl = getBaseUrl();
  const handleTokenRefresh = (token: string) => {
    setToken(token);
  };

  keycloak.onAuthRefreshSuccess = AuthRefreshSuccessHandler(
    keycloak,
    handleTokenRefresh,
  );
  keycloak.onAuthSuccess = AuthSuccessHandler(keycloak, handleTokenRefresh);

  const value = useMemo(
    () => ({
      keycloak,
      loginOptions: {
        redirectUri,
        ...loginOptions,
      },
      initOptions: {
        onLoad: 'check-sso' as KeycloakOnLoad,
        silentCheckSsoRedirectUri: `${baseUrl}/public/silent-check-sso.html`,
        enableLogging: true,
        ...initOptions,
      },
      loadingComponent,
    }),
    [],
  );

  // useEffect(() => () => clearToken());

  return (
    <KeycloakContext.Provider value={value}>
      {children}
    </KeycloakContext.Provider>
  );
};

export default connector(KeycloakProvider);
