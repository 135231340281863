import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../../generated-types';
import { IUserInfo } from './models';

export const initialState = {
  userDetails: {
    name: 'Anonymous',
    email: '',
    userId: '',
    company: '',
    userType: '' as UserType,
  } as IUserInfo,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (
      state,
      action: PayloadAction<{
        name: string;
        email: string;
        userId?: string;
        company?: string;
        userType?: UserType;
        companyId?: string;
      }>,
    ) => {
      state.userDetails = {
        company: state.userDetails.company,
        userId: state.userDetails.userId,
        userType: state.userDetails.userType,
        companyId: state.userDetails.companyId,
        ...action.payload,
      };
    },
  },
});

export const { setUserDetails } = userSlice.actions;

export default userSlice.reducer;
