import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { trackEvent } from '../../../../analytics';
import { TRACKING_CONSTANTS } from '../../../../analytics/constants/trackingConstants';
import { RootState } from '../../../../common/redux-store';
import { BaInput } from '../../../../components';
import { setAccountSearch } from '../../dashboard-redux-store';
import CreateNewRmapAccount from './create-new-rmap-account';
import { Organization } from '../../../../generated-types';

const mapState = (state: RootState) => ({
  searchRedux: state.dashboard.accountSearch,
  isSalesForceEnabled: !!(state?.organization as Organization)?.configs
    ?.salesforce?.isEnabled,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ trackEvent, setAccountSearch }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const HEADER_CLASS =
  'flex items-center justify-between space-x-4 bg-mid-50 px-6 py-3';

function OpportunityHeader(props: PropsFromRedux) {
  const { searchRedux, setAccountSearch, isSalesForceEnabled } = props;

  const onChange = useCallback(
    (text: string | undefined) => {
      trackEvent(TRACKING_CONSTANTS.DASHBOARD_MSP_SEARCH, {
        searchTerm: text ? text?.trimStart() : '',
      });
      setAccountSearch(text ? text?.trimStart() : '');
    },
    [setAccountSearch],
  );
  return (
    <div className={HEADER_CLASS}>
      <BaInput
        parentValue={searchRedux}
        onChange={onChange}
        placeholder="Search Account"
        debounceTime={500}
        type="search"
        className="w-1/3"
      />
      {isSalesForceEnabled && <CreateNewRmapAccount />}
    </div>
  );
}

export default connector(OpportunityHeader);
