import { gql } from '@apollo/client';
import {
  Comment,
  CommentConnection,
  MediaConnection,
  MediaPayloadItem,
  Notes,
  NotesResponse,
  User,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../../graphql/fragments/error';
import {
  ASSETS_FRAGMENT,
  AUDIO_FRAGMENT,
  DOCUMENT_FRAGMENT,
  IMAGE_FRAGMENT,
  VIDEO_FRAGMENT,
} from '../../../graphql/fragments/resources-fragments';
import { TResource } from '../resources/models';

export type ResultTask = {
  taskQuery: { getTaskNotes: NotesResponse };
};

export const GET_NOTES = gql`
  ${CORE_GRAPH_ERROR}
  query GetNotesWithComments($taskId: ID!) {
    taskQuery {
      getTaskNotes(taskId: $taskId) {
        __typename
        ...CoreErrorFields
        ... on Notes {
          id
          comments {
            ...CoreErrorFields
            ... on CommentConnection {
              edges {
                node {
                  __typename
                  ...CoreErrorFields
                  ... on Comment {
                    id
                    createdAt
                    messageData {
                      message
                      isEdited
                      editedAt
                    }
                    createdBy {
                      ...CoreErrorFields
                      ... on User {
                        firstName
                        lastName
                        id
                        userType
                        email
                      }
                    }
                    media {
                      ... on GraphqlError {
                        code
                        message
                      }
                      ... on MediaConnection {
                        edges {
                          node {
                            ... on GraphqlError {
                              code
                              message
                            }
                            ... on MediaResponse {
                              ... on GraphqlError {
                                code
                                message
                              }
                              ... on MediaPayloadItem {
                                item {
                                  id
                                  fileName
                                  url
                                  createdBy {
                                    ...CoreErrorFields
                                    ... on User {
                                      id
                                    }
                                  }
                                  analytics {
                                    ...CoreErrorFields
                                    ... on MediaAnalytics {
                                      belongsTo {
                                        ...CoreErrorFields
                                        ... on MediaBelongsTo {
                                          id
                                          name
                                          displayName
                                          objectType
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NOTES_MEDIA = gql`
  ${CORE_GRAPH_ERROR}
  ${ASSETS_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${AUDIO_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  query GetNotesMedia($taskId: ID!) {
    taskQuery {
      getTaskNotes(taskId: $taskId) {
        __typename
        ...CoreErrorFields
        ... on Notes {
          id
          comments {
            ...CoreErrorFields
            ... on CommentConnection {
              edges {
                node {
                  __typename
                  ...CoreErrorFields
                  ... on Comment {
                    id
                    media {
                      ... on GraphqlError {
                        code
                        message
                      }
                      ... on MediaConnection {
                        edges {
                          node {
                            ... on GraphqlError {
                              code
                              message
                            }
                            ... on MediaResponse {
                              ... on GraphqlError {
                                code
                                message
                              }
                              ... on MediaPayloadItem {
                                item {
                                  id
                                  name
                                  orgId
                                  createdBy {
                                    ...CoreErrorFields
                                    ... on User {
                                      id
                                      firstName
                                      lastName
                                      email
                                      userType
                                    }
                                  }
                                  description
                                  mediaType
                                  mimeType
                                  createdAt
                                  ...AssetsFragment
                                  ...ImageFragment
                                  ...AudioFragment
                                  ...DocumentFragment
                                  ...VideoFragment
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const transformCommentResponse = (data: ResultTask) =>
  (
    (data?.taskQuery.getTaskNotes as Notes)?.comments as CommentConnection
  )?.edges?.map(
    (comment) =>
      ({
        ...comment?.node,
      } as Comment),
  ) || [];

export const transformCommentMedia = (
  data: ResultTask,
  loggedInUserId?: string,
) => {
  let mediaList: TResource[] = [];
  (
    (data?.taskQuery.getTaskNotes as Notes)?.comments as CommentConnection
  )?.edges?.forEach((comment) => {
    let restructuredMedia;
    if (((comment?.node as Comment)?.media as MediaConnection)?.edges?.length) {
      restructuredMedia = (
        (comment?.node as Comment)?.media as MediaConnection
      )?.edges?.map(({ node }) => ({
        permissions: {
          delete:
            ((node as MediaPayloadItem)?.item?.createdBy as User)?.id ===
            loggedInUserId,
        },
        moduleName: 'msp-milestone-comment',
        ...(node as MediaPayloadItem)?.item,
      }));
    }
    if (restructuredMedia) {
      mediaList = mediaList.concat([...restructuredMedia]);
    }
  }) as unknown as TResource[];
  return mediaList;
};

export enum TaskTabType {
  BASIC_DETAILS = 'BASIC_DETAILS',
  COMMENTS = 'COMMENTS',
}

export enum MilestoneType {
  BASIC_DETAILS = 'BASIC_DETAILS',
  MILESTONE_DETAILS = 'MILESTONE_DETAILS',
}

export enum TaskDetailType {
  PLAN_VIEW = 'PLAN_VIEW',
  DASHBOARD = 'DASHBOARD',
}
