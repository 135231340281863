import * as Tooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { ReactNode } from 'react';

const Provider = ({
  children,
  delayDuration = 0,
}: {
  children: ReactNode;
  delayDuration?: number;
}) => (
  <Tooltip.Provider delayDuration={delayDuration}>{children}</Tooltip.Provider>
);

const Root = Tooltip.Root;

const Trigger = Tooltip.Trigger;

const Arrow = ({
  fillColorClass = 'fill-black',
}: {
  fillColorClass?: 'fill-white' | 'fill-black';
}) => <Tooltip.Arrow className={clsx('shadow-100', fillColorClass)} />;

const Content = ({
  children,
  className,
  bgColorClass = 'bg-black',
  paddingclass = 'px-3 py-2',
  ...props
}: {
  children: ReactNode;
  className?: string;
  bgColorClass?: 'bg-black' | 'bg-white';
  paddingclass?: string;
} & Tooltip.TooltipContentProps) => (
  <Tooltip.Portal>
    <Tooltip.Content
      {...props}
      className={clsx(
        'rounded-md shadow-80',
        className,
        bgColorClass,
        paddingclass,
      )}
    >
      {children}
    </Tooltip.Content>
  </Tooltip.Portal>
);

export const BaTooltip = { Provider, Root, Trigger, Arrow, Content };
