import { datadogRum } from '@datadog/browser-rum';
import { excludeWhitelistedErrors } from './whitelist';

export function initializeDatadog() {
  datadogRum.init({
    beforeSend: (event, _context) => {
      return excludeWhitelistedErrors(event);
    },
    applicationId: 'c8019bf8-66d0-4ae8-997e-7664cfe5055b',
    clientToken: 'puba9836f65d478d188cf373606297adf04',
    site: 'datadoghq.com',
    service: 'production-buyerassist-frontend',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['clickmap'],
  });

  datadogRum.startSessionReplayRecording();
}
