import { Component, ErrorInfo } from 'react';
import ErrorPages from '../common/methods/error-index';

interface Props {
  title: string;
  description?: string;
}
interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO sentry integration
    console.error('Uncaught error:', error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError || error) {
      return <ErrorPages />;
    }

    return children;
  }
}

export default ErrorBoundary;
