import { ApolloClient } from '@apollo/client';
import type { Dispatch } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import { v4 as getNS, v5 as uuidNameSpace } from 'uuid';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { showToast } from '../../../components/hooks/use-toastify';
import {
  Assignee,
  AssigneeType,
  Filter,
  FilterItemsCountPayloadResponse,
  FilterItemsPayloadResponse,
  FiltersExpression,
  Operation,
  TaskConnectionResponse,
  TaskPayloadResponse,
  TaskStatus,
  User,
} from '../../../generated-types';
import {
  AddAssignResult,
  ADD_ASSIGNEE,
  RemoveAssignResult,
} from '../../../graphql/mutation/task';
import { REMOVE_ASSIGNEE } from '../../../graphql/mutation/task/remove-assignee';
import { UPDATE_TASK } from '../../../graphql/mutation/task/update-task';
import {
  CHANGE_TASK_STATUS,
  UpdateTaskStatus,
} from '../../../graphql/mutation/task/update-task-status';
import { EMspSaveIndicator, IPlaceholder } from '../../msp-view/model';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import {
  ALL_TASKS_FILTERS,
  ALL_TASKS_FILTERS_COUNT,
  GET_ALL_TASKS,
} from './graphql/get-all-tasks';

const RequestNameSpace = getNS();

export enum TaskType {
  OVERDUE = 'overdue',
  DUETODAY = 'dueToday',
  DUETOMORROW = 'dueTomorrow',
  DUEINTENDAYS = 'dueTenDays',
  ALLTASKS = 'allTasks',
}

export type ResultAllTasks = {
  taskQuery: {
    allTasksWithFilters: TaskConnectionResponse;
  };
};

export type ResultAllTasksFilters = {
  taskQuery: {
    allTaskFilters: FilterItemsPayloadResponse;
  };
};

export type ResultAllTasksFiltersCount = {
  taskQuery: {
    allTaskFiltersCount: FilterItemsCountPayloadResponse;
  };
};

export interface InputProps {
  client: ApolloClient<object>;
  taskId: string;
  mspId: string;
  taskType: TaskType;
  dispatch: Dispatch<any>;
  newRequestedTaskStatus?: TaskStatus;
}

const sort = [
  { field: 'task.status', order: 'DESC' },
  { field: 'task.dueDate', order: 'ASC' },
];

export const fetchAllTasksList = createAsyncThunk(
  'dashboard/get-all-tasks',
  async (
    {
      client,
      searchTerm,
      taskType,
      filters,
      afterCursor,
      childExpressionFilters,
    }: {
      client: ApolloClient<object>;
      searchTerm?: string;
      taskType: TaskType;
      filters: Filter[];
      afterCursor?: string;
      childExpressionFilters: unknown[];
    },
    { rejectWithValue },
  ) => {
    try {
      const tempFilters = [...filters];
      if (searchTerm) {
        tempFilters.push({
          op: Operation.LIKE,
          values: [searchTerm],
          field: 'task.name',
        });
      }
      const taskoperations = {
        filter: {
          operator: 'AND',
          filters: tempFilters,
        },
        sort,
      } as { filter: FiltersExpression };
      if (childExpressionFilters && childExpressionFilters.length > 0) {
        taskoperations.filter.childExpressions = [
          { operator: 'OR', childExpressions: childExpressionFilters },
        ] as FiltersExpression[];
      }
      const { data } = await client.query<ResultAllTasks>({
        query: GET_ALL_TASKS,
        variables: {
          operations: taskoperations,
          pageConfiguration: {
            limit: 30,
            ...(afterCursor && { afterCursor }),
          },
        },
        context: {
          requestTrackerId: uuidNameSpace('ALL_TASKS', RequestNameSpace),
        },
      });
      return { data, taskType };
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const taskPagination = createAsyncThunk(
  'dashboard/all-task-Pagination',
  async (
    {
      client,
      afterCursor,
    }: {
      client: ApolloClient<object>;
      afterCursor?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultAllTasks>({
        query: GET_ALL_TASKS,

        variables: {
          pageConfiguration: {
            limit: 30,
            afterCursor,
          },
        },
      });
      return { data };
    } catch (error: unknown) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const fetchAllTasksFilters = createAsyncThunk(
  'dashboard/get-all-task-filters',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data } = await client.query<ResultAllTasksFilters>({
        query: ALL_TASKS_FILTERS,
        variables: {},
      });
      return data;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const fetchAllTasksFiltersCount = createAsyncThunk(
  'dashboard/get-all-task-filters-count',
  async (
    { client, ids }: { client: ApolloClient<object>; ids: string[] },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultAllTasksFiltersCount>({
        query: ALL_TASKS_FILTERS_COUNT,
        context: {
          headers: {
            TimezoneOffset: new Date().getTimezoneOffset() * -1,
          },
        },
        variables: {
          ids,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export interface ITaskDueDateResponse {
  taskMutation: { updateTask: TaskPayloadResponse };
}

export const updateTaskDueDate = createAsyncThunk(
  'dashboard/update-all-task-due-date',
  async (
    {
      client,
      dueDate,
      taskId,
      mspId,
      // showToast,
      taskType,
      dispatch,
    }: InputProps & { dueDate: number },
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_DATE }),
      );
      const { data } = await client.query<ITaskDueDateResponse>({
        query: UPDATE_TASK,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          id: taskId,
          input: { dueDate },
        },
      });
      if (data.taskMutation.updateTask.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.milestoneEndDate"
            defaultMessage="Milestone End Date didn't save, please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Something went wrong');
      } else {
        dispatch(removeFromSavingList(EMspSaveIndicator.TASK_DATE));
      }
      return { data, taskType };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_DATE));
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const updateAllTaskStatus = createAsyncThunk(
  'dashboard/update-all-task-status',
  async (
    {
      client,
      taskId,
      mspId,
      taskType,
      dispatch,
      newRequestedTaskStatus,
    }: InputProps,
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_STATUS }),
      );
      const { data } = await client.query<UpdateTaskStatus>({
        query: CHANGE_TASK_STATUS,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            taskIds: [taskId],
            taskStatus: newRequestedTaskStatus,
          },
        },
      });
      if (data.taskMutation.changeTaskStatus?.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.mspTaskStatusAPIError"
            defaultMessage="Task completion status not saved, Please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Task completion status not saved, Please try again');
      } else {
        trackEvent(TRACKING_CONSTANTS.UPDATED_TASK_STATUS, {
          newRequestedTaskStatus,
          mspId,
          from: 'All Task Tab',
        });
        dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      }
      return { data, taskType, newRequestedTaskStatus };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_STATUS));
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export type ResultPlaceholderList = {
  memberQuery: {
    listPlaceholders: TaskConnectionResponse;
  };
};
export const updateTaskAssignee = createAsyncThunk(
  'dashboard/update-task-assignee',
  async (
    {
      client,
      taskId,
      mspId,
      taskType,
      user,
      assigneeType,
      dispatch,
    }: InputProps & { user: User; assigneeType: AssigneeType },
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_ASSIGNEE }),
      );
      const { data } = await client.query<AddAssignResult>({
        query: ADD_ASSIGNEE,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          taskIds: taskId,
          userIdMap: { id: user.id, primaryId: user?.primaryId, assigneeType },
        },
      });
      if (data.taskMutation.addAssignee.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.addAssigneeError"
            defaultMessage="Task assignee could not be saved. Please try again."
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('Something went wrong');
      } else {
        dispatch(
          trackEvent(TRACKING_CONSTANTS.ADDED_UPDATED_TASK_ASSIGNEE, {
            taskId,
            assigneeId: user?.id,
            from: 'All Task Tab',
          }),
        );
        dispatch(removeFromSavingList(EMspSaveIndicator.TASK_ASSIGNEE));
      }
      return {
        data,
        taskType,
        user,
        taskId,
      };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_ASSIGNEE));
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const removeTaskAssignee = createAsyncThunk(
  'dashboard/remove-task-assignee',
  async (
    {
      client,
      taskId,
      mspId,
      taskType,
      dispatch,
      user,
    }: InputProps & { user: User | undefined | Assignee },
    { rejectWithValue },
  ) => {
    try {
      dispatch(
        addToSavingList({
          saving: true,
          id: EMspSaveIndicator.REMOVE_ASSIGNEE,
        }),
      );
      const { data } = await client.query<RemoveAssignResult>({
        query: REMOVE_ASSIGNEE,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          assigneeId:
            (user as User)?.id ?? (user as IPlaceholder)?.placeholderId,
          taskIds: [taskId],
        },
      });
      if (data.taskMutation.removeAssignee.__typename === 'GraphqlError') {
        showToast(
          <FormattedMessage
            id="MspPlanView.removeAssigneeApiError"
            defaultMessage="Could not remove assignee, please try again"
          />,
          {
            variant: 'error',
          },
        );
        throw new Error('remove assignee failed');
      } else {
        dispatch(removeFromSavingList(EMspSaveIndicator.REMOVE_ASSIGNEE));
      }
      return {
        data,
        taskId,
      };
    } catch (error) {
      dispatch(removeFromSavingList(EMspSaveIndicator.REMOVE_ASSIGNEE));
      return rejectWithValue((error as Error)?.message);
    }
  },
);
