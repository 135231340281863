import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

const Root: React.FC<Dialog.DialogProps> = Dialog.Root;

const Trigger: React.ForwardRefExoticComponent<
  Dialog.DialogTriggerProps & React.RefAttributes<HTMLButtonElement>
> = Dialog.Trigger;
const Portal: React.FC<Dialog.DialogPortalProps> = Dialog.Portal;

const Title: React.ForwardRefExoticComponent<
  Dialog.DialogTitleProps & React.RefAttributes<HTMLHeadingElement>
> = Dialog.Title;

const Description: React.ForwardRefExoticComponent<
  Dialog.DialogDescriptionProps & React.RefAttributes<HTMLParagraphElement>
> = Dialog.Description;

const Overlay = () => (
  <Dialog.Overlay className="fixed inset-0 z-20 h-screen w-screen bg-black opacity-40" />
);
const CenteredContent = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Dialog.Content
    {...props}
    className={`fixed top-1/2 left-1/2 z-30 -translate-x-1/2 -translate-y-1/2 rounded-10 bg-white shadow-500 ${
      className || ''
    }`}
  >
    {children}
  </Dialog.Content>
);
const RightDrawer = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Dialog.Content
    {...props}
    className={` fixed top-0 right-0 bg-white shadow-500 ${className || ''}`}
  >
    {children}
  </Dialog.Content>
);

const Close = Dialog.Close;

export const BaDialog = {
  Root,
  Trigger,
  Portal,
  Title,
  Description,
  Overlay,
  CenteredContent,
  RightDrawer,
  Close,
};
