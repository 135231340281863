import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { RootState } from '../../common/redux-store';
import { BaInput } from '../../components';
import { setDashboardSearch } from './dashboard-redux-store';

const mapState = (state: RootState) => ({
  searchRedux: state?.dashboard?.search,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDashboardSearch,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function MspSearch(props: PropsFromRedux) {
  const { searchRedux, setDashboardSearch } = props;
  const dispatch = useDispatch();

  const onChange = useCallback(
    (text: string | undefined) => {
      dispatch(
        trackEvent(TRACKING_CONSTANTS.DASHBOARD_MSP_SEARCH, {
          searchTerm: text ? text?.trimStart() : '',
        }),
      );
      setDashboardSearch(text ? text?.trimStart() : '');
    },
    [setDashboardSearch],
  );

  return (
    <div className="w-1/3">
      <BaInput
        parentValue={searchRedux}
        onChange={onChange}
        placeholder="Search MSP"
        debounceTime={500}
        type="search"
      />
    </div>
  );
}

export default connector(MspSearch);
