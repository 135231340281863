import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  methodology: null,
};

const meddicSlice = createSlice({
  name: 'meddic',
  initialState,
  reducers: {
    setMethodology: (state, action) => {
      state.methodology = action.payload;
    },
    clearMethodology: (state) => {
      state.methodology = null;
    },
  },
});

export const { setMethodology, clearMethodology } = meddicSlice.actions;

export default meddicSlice.reducer;
