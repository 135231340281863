import { Skeleton } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { AnalyticsMilestone, BaseMilestone } from '../../../generated-types';
import SubSectionHeader from './sub-section-header';
import { Divider, Typography } from '../../../components';
import ActivityMilestoneListItem from './activity-milestone-list-item';

interface ActivityMilestoneViewProps {
  activityMilestoneData: [AnalyticsMilestone];
  isLoading: boolean;
  isError: boolean;
  mspId: string;
}

function ActivityMilestoneView(props: ActivityMilestoneViewProps) {
  const { activityMilestoneData, isLoading, isError, mspId } = props;
  if (isLoading) {
    return (
      <div>
        <SubSectionHeader content="Active Milestones" />
        <Divider />
        <div className="grid grid-cols-12 px-8 pt-2 pb-2">
          <div className="col-span-7 space-x-1">
            <Skeleton animation="wave" variant="text" width={170} />
          </div>
          <div className="col-span-3 col-start-8">
            <Skeleton animation="wave" variant="text" width={70} />
          </div>
          <div className="col-span-2 col-start-11 flex justify-end space-x-1">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton animation="wave" variant="text" width={50} />
          </div>
        </div>
      </div>
    );
  }
  if (activityMilestoneData) {
    return (
      <div>
        <SubSectionHeader content="Active Milestones" />
        <Divider />
        {activityMilestoneData?.length > 0 ? (
          activityMilestoneData?.map((activityMilestone) => (
            <ActivityMilestoneListItem
              key={mspId}
              itemDetail={(activityMilestone?.milestone as BaseMilestone).name}
              dateRange={`${formatDate(
                (activityMilestone?.milestone as BaseMilestone)?.startDate,
              )} - ${formatDate(
                (activityMilestone?.milestone as BaseMilestone)?.endDate,
              )} `}
              completedTasks={activityMilestone?.progress?.tasksCompleted ?? 0}
              totalTasks={activityMilestone?.progress?.tasksCount ?? 0}
              overdueTask={activityMilestone?.progress?.tasksOverdue ?? 0}
              mspId={mspId}
            />
          ))
        ) : (
          <div className="px-8">No Milestones to work on immediately</div>
        )}
      </div>
    );
  }
  return null;
}
const formatDate = (data: number) => dayjs(data * 1000).format('MMM DD');
export default ActivityMilestoneView;
