import { Redirect, Route, Switch } from 'react-router-dom';
import AllTasks from './all-tasks/all-task-container';
import RmapDashboardContainer from './rmap-dashboard/rmap-dashboard-container';
import MspDashboardContainer from './msp-dashboard-container';

export const DASHBOARD_PATH = '/ui/alignx/dashboard';
export const ALL_TASKS_PATH = '/ui/alignx/alltasks';
export const RMAP_PATH = '/ui/alignx/rmap-dashboard';

function TabList() {
  return (
    <Switch>
      <Route path="/ui/alignx/:tab" render={() => <DashboardTabDetails />} />
      <Redirect from="/ui/alignx" to="/ui/alignx/dashboard" />
    </Switch>
  );
}

function DashboardTabDetails() {
  return (
    <Switch>
      <Route path={DASHBOARD_PATH} render={() => <MspDashboardContainer />} />
      <Route path={ALL_TASKS_PATH} render={() => <AllTasks />} />
      <Route path={RMAP_PATH} render={() => <RmapDashboardContainer />} />
    </Switch>
  );
}

export default TabList;
