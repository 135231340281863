import { useMemo } from 'react';
import { ITabDetail } from '../../alignx/msp-view/model';

export const useSortedTabList = ({
  tabList = [],
  isBuyer,
}: {
  tabList: ITabDetail[];
  isBuyer: boolean;
}) => {
  try {
    const sortedTabList = useMemo(() => {
      const copyList = [...tabList]
        ?.filter((tab) => {
          return tab?.isEnabled && (isBuyer ? !tab?.isInternal : true);
        })
        ?.sort((a, b) => Number(a?.order ?? 0) - Number(b?.order ?? 0));

      return copyList;
    }, [tabList, isBuyer]);

    return sortedTabList;
  } catch (err) {
    console.log(err);
  }
  return [] as ITabDetail[];
};
