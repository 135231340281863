import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../../generated-types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AssigneeSearchTabType } from '../../../alignx/msp-view/model';

export const initialState = {
  searchUserList: [] as User[],
  existingUserList: [] as User[],
  searchText: '',
  selectedUser: [] as User[],
  taskAssigneeSelectedTab:
    AssigneeSearchTabType.STAKEHOLDER as AssigneeSearchTabType,
  isNewUserAdded: false as boolean,
  mspId: undefined as string | undefined,
};

const searchComponent = createSlice({
  name: 'msp',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSelectedUserList: (state, action: PayloadAction<User[]>) => {
      state.selectedUser = action.payload;
    },
    setSelectedTabValue: (
      state,
      action: PayloadAction<AssigneeSearchTabType>,
    ) => {
      state.taskAssigneeSelectedTab = action.payload;
    },
    setNewUserAddedStatus: (state, action: PayloadAction<boolean>) => {
      state.isNewUserAdded = action.payload;
    },
    setUserSearchMspId: (state, action: PayloadAction<{ mspId: string }>) => {
      const { mspId } = action.payload;
      state.mspId = mspId;
    },
    resetToSearchComponentInitialState: () => initialState,
  },
});

export const {
  setSearchText,
  setSelectedUserList,
  resetToSearchComponentInitialState,
  setSelectedTabValue,
  setNewUserAddedStatus,
  setUserSearchMspId,
} = searchComponent.actions;

export default searchComponent.reducer;
