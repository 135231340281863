import { gql } from '@apollo/client';

const COMMON_ATTRIBUTES = `
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ...CoreErrorFields
    ... on MediaAnalytics {
      belongsTo {
        ...CoreErrorFields
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
        }
      }
      engagementCount {
        ...CoreErrorFields
          ... on EngagementCount {
          viewCount
          downloadCount
        }
      }
    }
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
    }
  }
`;
const MIN_ATTRIBUTES = `
  fileName
  source
  analytics {
    ...CoreErrorFields
    ... on MediaAnalytics {
      belongsTo {
        ...CoreErrorFields
        ... on MediaBelongsTo {
          id
          objectType
        }
      }
    }
  }
`;
const COMMON_ATTRIBUTES_WITH_URL = `
  fileAccessUrl
  fileDownloadUrl
  ${COMMON_ATTRIBUTES}
`;

const MIN_ATTRIBUTES_WITH_URL = `
  fileAccessUrl
  fileDownloadUrl
  ${MIN_ATTRIBUTES}
`;

export const ASSETS_FRAGMENT = gql`
fragment AssetsFragment on Assets {
${COMMON_ATTRIBUTES_WITH_URL}
}
`;
export const IMAGE_FRAGMENT = gql`
fragment ImageFragment on Image {
${COMMON_ATTRIBUTES_WITH_URL}
}
`;
export const AUDIO_FRAGMENT = gql`
fragment AudioFragment on Audio {
${COMMON_ATTRIBUTES_WITH_URL}
}
`;

export const DOCUMENT_FRAGMENT = gql`
fragment DocumentFragment on Document {
${COMMON_ATTRIBUTES_WITH_URL}
}
`;

export const VIDEO_FRAGMENT = gql`
fragment VideoFragment on Video {
${COMMON_ATTRIBUTES_WITH_URL}
}
`;

export const LINK_FRAGMENT = gql`
  fragment LinkFragment on Link {
    ${COMMON_ATTRIBUTES}
    link
    name
  }
`;

export const MIN_ASSETS_FRAGMENT = gql`
fragment MinimalAssetsFragment on Assets {
${MIN_ATTRIBUTES_WITH_URL}
}
`;
export const MIN_IMAGE_FRAGMENT = gql`
fragment MinimalImageFragment on Image {
${MIN_ATTRIBUTES_WITH_URL}
}
`;
export const MIN_AUDIO_FRAGMENT = gql`
fragment MinimalAudioFragment on Audio {
${MIN_ATTRIBUTES_WITH_URL}
}
`;

export const MIN_DOCUMENT_FRAGMENT = gql`
fragment MinimalDocumentFragment on Document {
${MIN_ATTRIBUTES_WITH_URL}
}
`;

export const MIN_VIDEO_FRAGMENT = gql`
fragment MinimalVideoFragment on Video {
${MIN_ATTRIBUTES_WITH_URL}
}
`;
export const MIN_LINK_FRAGMENT = gql`
  fragment MinimalLinkFragment on Link {
    ${MIN_ATTRIBUTES_WITH_URL}
    link
    name
  }
`;
