/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { api } from 'api/base-api';
module.hot?.accept();
export type ToggleMspsArchivalStatusMutationVariables = Types.Exact<{
  input: Types.ArchiveMspInput;
}>;

export type ToggleMspsArchivalStatusMutation = {
  __typename?: 'Mutation';
  mspMutation?: {
    __typename?: 'MspMutation';
    toggleMspsArchivalStatus:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: Types.ErrorType;
          message: string;
        }
      | {
          __typename: 'ToggleMspArchivePayload';
          items?: Array<{
            __typename?: 'ToggleMspArchiveResponse';
            id: string;
            mspStage: Types.MspStage;
          }>;
        };
  };
};

export const ToggleMspsArchivalStatusDocument = `
    mutation toggleMspsArchivalStatus($input: ArchiveMspInput!) {
  mspMutation {
    toggleMspsArchivalStatus(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on ToggleMspArchivePayload {
        items {
          id
          mspStage
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    toggleMspsArchivalStatus: build.mutation<
      ToggleMspsArchivalStatusMutation,
      ToggleMspsArchivalStatusMutationVariables
    >({
      query: (variables) => ({
        document: ToggleMspsArchivalStatusDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useToggleMspsArchivalStatusMutation } = injectedRtkApi;
