import { gql, useMutation } from '@apollo/client';
import {
  BooleanResponse,
  TaskMutationReOrderTaskArgs,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

type ReOrderTaskInput = {
  variables: TaskMutationReOrderTaskArgs;
};

export const REORDER_TASK = gql`
  ${CORE_GRAPH_ERROR}
  mutation ReOrderTask($taskId: ID!, $to: Int!) {
    taskMutation {
      reOrderTask(taskId: $taskId, to: $to) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const REORDER_TASK_ACROSS_MILESTONE = gql`
  ${CORE_GRAPH_ERROR}
  mutation MoveTasksAcrossMilestone($input: MoveTasksAcrossMilestoneInput!) {
    taskMutation {
      moveTasksAcrossMilestone(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export type ReOrderTaskResult = {
  taskMutation: { reOrderTask: BooleanResponse };
};

export type ReOrderTaskAcrossResult = {
  taskMutation: { moveTasksAcrossMilestone: BooleanResponse };
};

export const ReOrderTasksMutation = (
  onCompletedReOrderTask: (taskData: ReOrderTaskResult) => void,
): ((createUserAndAssignTask: ReOrderTaskInput) => any) => {
  const [resultReOrderTask] = useMutation(REORDER_TASK, {
    onCompleted(taskData: ReOrderTaskResult) {
      onCompletedReOrderTask(taskData);
    },
  });
  return resultReOrderTask;
};
