/* eslint-disable max-len */
import { PushNotificationConnection } from '@buyerassist/graphql-generated-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
  BaResource,
  NotificationStatus,
  PushNotification,
  PushNotificationAccountDetailsPayload,
  PushNotificationConnectionResponse,
} from '../../../generated-types';
import {
  fetchNotification,
  notificationPagination,
  ResultNotification,
} from './api-call';
import {
  GqlError,
  INotification,
  INotificationState,
  NotificationCardType,
  TAccountList,
  TNotification,
} from './models';

const defaultNotification = {
  pageInfo: null,
  notificationList: [] as TNotification[],
  newCount: 0,
  totalCount: 0,
};

const defaultNotificationFilterDetails = {
  accountId: '',
  baResourceList: undefined,
  notificationType: 'Timeline',
};

function transformNotificationResponse(
  notificationData: PushNotificationConnectionResponse,
  isPolling?: boolean,
) {
  try {
    if (notificationData?.__typename === 'GraphqlError') {
      return {
        ...defaultNotification,
        errorMessage: 'Something went wrong',
      };
    }
    const edges = (notificationData as PushNotificationConnection)?.edges;
    const pageInfo = (notificationData as PushNotificationConnection)?.pageInfo;
    if (!edges) return defaultNotification;

    const notificationList = [] as Array<TNotification>;
    let notification = {} as TNotification;

    for (let i = 0; i < edges?.length; i += 1) {
      notification = {} as TNotification;
      const node = edges[i]?.node;

      if (node?.__typename === 'GraphqlError' || !node) {
        const id = `error${uuid()}`;
        notification = {
          userNotificationStatusId: id,
          tableKey: id + i,
          ...node,
        } as GqlError;
        notificationList.push(notification);
      } else {
        const {
          account,
          authorizer,
          metaData,
          userNotificationStatusId,
          ...rest
        } = node as PushNotification;

        notification = {
          ...rest,
          ...metaData,
          type: authorizer.name
            ? NotificationCardType.NOTIFICATION
            : NotificationCardType.INSIGHT_NOTIFICATION,
          userNotificationStatusId,
          tableKey: userNotificationStatusId + i,
          accountName: account.name,
          accountId: account.id,
          authorizerName: authorizer.name,
          authorizerType: authorizer.type,
          additionalMessage: metaData?.additionalMessage,
        };

        notificationList.push(notification);
      }
    }

    const totalCount = (notificationData as PushNotificationConnection)?.totalCount ?? 0;

    if (
      totalCount === notificationList.length
      && !pageInfo.hasNextPage
      && !isPolling
    ) {
      notificationList?.push({
        type: NotificationCardType.END_NOTIFICATION,
      } as unknown as TNotification);
    }
    return {
      notificationList,
      pageInfo,
      newCount: (notificationData as PushNotificationConnection).newCount ?? 0,
      totalCount,
    };
  } catch (err: unknown) {
    const id = `error${uuid()}`;
    return {
      notificationList: [
        {
          userNotificationStatusId: id,
          tableKey: id,
          name: 'Something went wrong',
        },
      ] as TNotification[],
      pageInfo: null,
      newCount: 0,
      totalCount: 0,
    };
  }
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationList: defaultNotification.notificationList,
    pageInfo: defaultNotification.pageInfo,
    loading: true,
    error: undefined as unknown,
    isNextPageLoading: false,
    accountList: [] as TAccountList[],
    newCount: 0,
    totalCount: 0,
    notificationFilterDetails: defaultNotificationFilterDetails,
  } as INotificationState,
  reducers: {
    resetNotificationList: (state) => {
      state.notificationList = [];
      state.loading = true;
    },
    setMarkAllReadNotification: (
      state,
      action: PayloadAction<NotificationStatus>,
    ) => {
      state.notificationList = state.notificationList.map(
        (notification) =>
          ({
            ...notification,
            status: action.payload,
          } as TNotification),
      );
    },
    setMarkReadNotification: (state, action: PayloadAction<string>) => {
      const notificationIndex = state.notificationList.findIndex(
        (notification) =>
          (notification as TNotification).userNotificationStatusId
          === action.payload,
      );
      (state.notificationList[notificationIndex] as INotification).status = NotificationStatus.READ;
    },
    setAccountIdAndNotificationType: (
      state,
      action: PayloadAction<{ accountId: string | undefined; type: string }>,
    ) => {
      state.notificationFilterDetails.notificationType = action.payload.type;
      state.notificationFilterDetails.accountId = action.payload.accountId;
    },
    setBaResource: (state, action: PayloadAction<BaResource | undefined>) => {
      state.notificationFilterDetails.baResourceList = action.payload
        ? [action.payload]
        : undefined;
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.newCount = action.payload;
    },
    resetNotificationFilterDetails: (state) => {
      state.notificationFilterDetails = defaultNotificationFilterDetails;
    },
    setPollingNotificationData: (
      state,
      action: PayloadAction<ResultNotification>,
    ) => {
      const { notificationList, newCount } = transformNotificationResponse(
        action.payload.pushNotificationQuery.pollNotifications,
        true,
      );
      state.notificationList = notificationList.concat(state.notificationList);
      state.newCount += newCount;
      state.totalCount += newCount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.fulfilled, (state, { payload }) => {
        const { data, accountData } = payload;
        const {
          notificationList,
          pageInfo,
          newCount,
          totalCount,
          errorMessage,
        } = transformNotificationResponse(
          data?.pushNotificationQuery?.getPushNotifications,
        );
        state.notificationList = notificationList;
        state.accountList = (
          accountData.pushNotificationQuery
            .getNotificationAccounts as PushNotificationAccountDetailsPayload
        ).items as TAccountList[];
        state.pageInfo = pageInfo;
        state.loading = false;
        state.newCount = newCount;
        state.error = errorMessage;
        state.totalCount = totalCount;
      })
      .addCase(fetchNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotification.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(notificationPagination.fulfilled, (state, { payload }) => {
        const { notificationList, pageInfo } = transformNotificationResponse(
          payload.pushNotificationQuery.getPushNotifications,
        );
        state.notificationList = state.notificationList.concat(notificationList);
        state.pageInfo = pageInfo;
        state.isNextPageLoading = false;
      })
      .addCase(notificationPagination.pending, (state) => {
        state.isNextPageLoading = true;
      })
      .addCase(notificationPagination.rejected, (state, { payload }) => {
        state.error = payload;
        state.isNextPageLoading = false;
      });
    // .addCase(pollNotification.fulfilled, (state, { payload }) => {
    //   const { notificationList, newCount } = transformNotificationResponse(
    //     payload.pushNotificationQuery.pollNotifications,
    //   );
    //   state.notificationList = notificationList.concat(
    //     state.notificationList,
    //   );
    //   state.newCount += newCount;
    //   state.totalCount += newCount;
    // })
    // .addCase(pollNotification.pending, (state) => {})
    // .addCase(pollNotification.rejected, (state, { payload }) => {
    //   state.error = payload;
    // });
  },
});

export const {
  resetNotificationList,
  setMarkAllReadNotification,
  setMarkReadNotification,
  setAccountIdAndNotificationType,
  setBaResource,
  setNotificationCount,
  resetNotificationFilterDetails,
  setPollingNotificationData,
} = notificationSlice.actions;

export default notificationSlice.reducer;
