import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
import { MouseEvent, useCallback, useRef, useState } from 'react';
import { FiChevronDown, FiRotateCcw } from 'react-icons/fi';
import { CustomToolTip, Typography } from '../../../../components';
import { DotIcon } from '../../../../components/icons';
import { Maybe, Milestone, Msp } from '../../../../generated-types';

export type TOption = {
  id: string;
  name: string;
  logo?: string;
  endField?: string;
};
export const OPTION_CLASSES = 'py-3 px-5 hover:bg-primary-100 cursor-pointer';
export function NoOptionsFound({ children }: { children?: React.ReactNode }) {
  return (
    <Typography
      variant="body-13-regular"
      className="rounded-md py-3 px-5 text-mid-400"
    >
      {children || 'No options found.'}
    </Typography>
  );
}

export function LastOption({
  name,
  onLastOption,
}: {
  name: string;
  onLastOption: () => void;
}) {
  return (
    <Typography
      onClick={onLastOption}
      variant="body-13-regular"
      className={`border-0 border-t border-solid border-gray-100 text-error ${OPTION_CLASSES}`}
    >
      {name}
    </Typography>
  );
}

const COMMON_LAYOUT_CLASSES =
  'h-11 flex box-border items-center justify-between cursor-pointer';
const OPEN_MODAL_CLASSES = 'rounded-t-md';
const CLOSE_MODAL_CLASSES = '';
const SELECTED_COMMON_LAYOUT_CLASSES =
  'items-center justify-between px-2 cursor-pointer';
const SELECTED_OPEN_MODAL_CLASSES =
  'border-t-primary border-r-primary border-l-primary border-b-white border border-solid rounded-t-md';
const SELECTED_CLOSE_MODAL_CLASSES =
  'border-gray-200 border border-solid rounded-md';

export function SelectLayout({
  children,
  isOpen,
  error,
  openModal,
  isMilestoneTemplateSelected,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  error?: string;
  openModal: (isMilestoneSelected: boolean) => void;
  isMilestoneTemplateSelected: boolean;
}) {
  return (
    <div
      onClick={() => openModal(!!isMilestoneTemplateSelected)}
      role="presentation"
      className={clsx(
        isOpen && isMilestoneTemplateSelected
          ? SELECTED_OPEN_MODAL_CLASSES
          : isOpen && !isMilestoneTemplateSelected
          ? OPEN_MODAL_CLASSES
          : isMilestoneTemplateSelected
          ? SELECTED_CLOSE_MODAL_CLASSES
          : CLOSE_MODAL_CLASSES,
        error && 'border-b-error',
        isMilestoneTemplateSelected
          ? SELECTED_COMMON_LAYOUT_CLASSES
          : COMMON_LAYOUT_CLASSES,
      )}
    >
      {children}
    </div>
  );
}

export function SearchSelectLayout({
  children,
  isOpen,
  error,
  openModal,
  optionList,
  isLoading,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  error?: string;
  openModal: () => void;
  optionList?: TOption[] | undefined;
  isLoading?: boolean | undefined;
}) {
  return (
    <div
      onClick={openModal}
      role="presentation"
      className={clsx(
        // eslint-disable-next-line no-nested-ternary
        isOpen && optionList
          ? OPEN_MODAL_CLASSES
          : isOpen && isLoading
          ? OPEN_MODAL_CLASSES
          : `${CLOSE_MODAL_CLASSES} focus-within:border-primary`,
        isLoading ? 'rounded-b-none focus-within:border-b-transparent' : '',
        error && 'border-b-error',
        COMMON_LAYOUT_CLASSES,
      )}
    >
      {children}
    </div>
  );
}

export function OptionListLayout({
  children,
  onClickAway,
  isMilestoneTemplateSelected,
}: {
  children: React.ReactNode;
  onClickAway: () => void;
  isMilestoneTemplateSelected: boolean;
}) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={`absolute z-10 box-border w-full rounded-b-md border-0 border-b border-l border-r border-solid bg-white shadow-100 ${
          isMilestoneTemplateSelected ? 'border-primary' : ' border-[#F4F4F4]'
        }`}
      >
        {children}
      </div>
    </ClickAwayListener>
  );
}

export function ErrorMessage({ error }: { error: string }) {
  return (
    <Typography
      element="div"
      variant="label-12-regular"
      className="bg-transparent text-error"
    >
      {error}
    </Typography>
  );
}
type TOptionLayoutProps = {
  id: string;
  name: string;
  msp?: Msp;
  taskCount: number | undefined;
  handleClose: () => void;
  setSelected: (option: TOption) => void;
  dueDateCount: number;
};
export function OptionLayout({
  id,
  name,
  msp,
  taskCount,
  handleClose,
  setSelected,
  dueDateCount,
}: TOptionLayoutProps) {
  return (
    <div
      key={id}
      className={`${OPTION_CLASSES} grid grid-cols-12 items-center justify-between gap-1 space-x-10`}
      onClick={() => {
        handleClose();
        setSelected({
          id,
          name,
        });
      }}
      role="presentation"
    >
      <div className="col-span-9 flex items-center">
        <CustomToolTip arrow placement="top" showTruncated title={name}>
          <div className="col-span-5 truncate">
            <Typography
              className="flex truncate text-neutral"
              variant="body-13-semibold"
            >
              {name}
            </Typography>
          </div>
        </CustomToolTip>
        <CustomToolTip
          arrow
          placement="top"
          showTruncated
          title={msp?.name ?? ''}
        >
          <div className="col-span-4 flex items-center truncate">
            <DotIcon className="pl-2 pt-0.5 text-10 text-mid" />
            <Typography
              className="truncate pl-1 text-[#7A7A7A]"
              variant="label-10-regular"
            >
              {msp?.name}
            </Typography>
          </div>
        </CustomToolTip>
      </div>
      <div className="col-span-3 flex">
        {taskCount && taskCount >= 0 ? (
          <Typography
            className="truncate text-[#118DEF]"
            variant="label-10-bold"
          >
            {`${taskCount} Tasks`}
          </Typography>
        ) : (
          ''
        )}
        {dueDateCount >= 0 ? (
          <Typography
            className="truncate text-[#118DEF]"
            variant="label-10-bold"
          >
            {`${
              dueDateCount >= 0 && taskCount && taskCount >= 0 ? ',' : ''
            } ${dueDateCount} days`}
          </Typography>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function EndIcon({ isLoading }: { isLoading?: boolean }) {
  // if (isLoading) return <CircularProgress size={16} />;
  return <FiChevronDown className="text-mid-400" />;
}

export function RetryComponent({
  error,
  onRefetch,
}: {
  error?: string;
  onRefetch?: () => void;
}) {
  const onClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (typeof onRefetch === 'function') onRefetch();
    },
    [onRefetch],
  );

  if (error) {
    return (
      <CustomToolTip title="Retry again.">
        <div className="flex items-center">
          <FiRotateCcw
            className="ml-1 cursor-pointer hover:text-primary"
            onClick={onClick}
          />
        </div>
      </CustomToolTip>
    );
  }

  return null;
}

interface IUseSelectProps {
  isLoading?: boolean;
  lastOption?: TOption;
  setSelected: (option: TOption | null) => void;
}

export function useSelectState({
  isLoading,
  lastOption,
  setSelected,
}: IUseSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const openModal = useCallback(() => {
    if (!isLoading) {
      // setIsOpen(true);
    }
  }, [isLoading]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onLastOption = useCallback(() => {
    handleClose();
    if (lastOption) {
      setSelected(lastOption);
      setSearchTerm('');
    }
  }, [handleClose, lastOption, setSelected]);

  const onSelect = useCallback(
    (selectedMilestone?: Maybe<Milestone>) => {
      setSearchTerm('');
      setSelected(selectedMilestone as Milestone);
      inputContainerRef?.current?.setAttribute(
        'data-name',
        (selectedMilestone as Milestone)?.name,
      );
    },
    [setSelected],
  );

  return {
    onSelect,
    onLastOption,
    openModal,
    handleClose,
    isOpen,
    searchTerm,
    inputContainerRef,
    setSearchTerm,
    setIsOpen,
  };
}

export const MILESTONE_SELECT_COMMON_CLASSES =
  'placeholder:font-bold placeholder:text-18 placeholder:text-[#B8B8B8] text-18 font-bold text-neutral h-auto box-border w-full truncate border-0 focus:outline-none rounded py-2.5';
export const MILESTONE_NAME_ERROR_COMMON_CLASSES =
  'placeholder:font-bold placeholder:text-18 placeholder:text-[#B8B8B8] text-18 font-bold text-neutral h-auto box-border w-full truncate border-l-0 border-t-0 border-r-0 border-b-error focus:outline-none rounded py-2.5';

export const COMMON_SEARCH_CLASSES =
  'placeholder:font-extralight placeholder-mid-400 text-18 text-neutral h-auto box-border w-full truncate placeholder:text-13 border-0 focus:outline-none rounded py-2.5';
