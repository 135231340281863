import React from 'react';
import { Typography } from '../../../components';
import CloudErrImg from '../../../assets/cloud-error.svg';
import Button from '@mui/material/Button';

function AccountErrorPage() {
  return (
    <div className="flex flex-col items-center space-y-6">
      <img src={CloudErrImg as string} alt="" />
      <Typography variant="heading5-semibold">
        Uh oh! This Account is not yet synced into BuyerAssist.
      </Typography>
      <div className="flex flex-col items-center space-y-2">
        <Typography variant="body-13-medium" className="text-mid">
          If this is a newly created account, please try after 10 minutes.
        </Typography>
        <Typography variant="body-13-medium" className="text-mid">
          If this issue persists, please contact your System Administrator for
          help.
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </div>
  );
}

export default AccountErrorPage;
