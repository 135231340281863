import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from './fragments/error';

export const SEARCH_USER = gql`
  ${CORE_GRAPH_ERROR}
  query SearchUserCollaborator(
    $operations: Operations
    $scope: RelationshipScopeInput
  ) {
    mspQuery {
      searchUsersInMsp(operations: $operations, scope: $scope) {
        __typename
        ...CoreErrorFields
        ... on UserConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on User {
                firstName
                lastName
                email
                id
                userType
                primaryId
              }
            }
          }
        }
      }
    }
  }
`;
