import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../../common/redux-store';
import { useCallback } from 'react';
import { trackEvent } from '../../../../analytics';
import { TRACKING_CONSTANTS } from '../../../../analytics/constants/trackingConstants';
import { BaInput, FilterComponent } from '../../../../components';
import { setOpportunitySearch } from '../../dashboard-redux-store';
import { useGetOpportunityFiltersQuery } from '../rmap-dashboard-api';
import { IFilterConfig } from '../../../../components/filters/model';
import { HEADER_CLASS } from '../account/account-header';
import CreateNewRmapWithOpportunity from './create-new-rmap-with-opportunity';
import { Organization } from '../../../../generated-types';

const mapState = (state: RootState) => ({
  searchRedux: state.dashboard.opportunitySearch,
  isRmapOnlyEnabled: (
    (state?.organization as Organization)?.configs?.others
      ?.featureFlags as Record<string, boolean>
  )?.isRmapOnlyEnabled,
  isSalesForceEnabled: !!(state?.organization as Organization)?.configs
    ?.salesforce?.isEnabled,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ trackEvent, setOpportunitySearch }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function OpportunityHeader(props: PropsFromRedux) {
  const {
    searchRedux,
    setOpportunitySearch,
    isRmapOnlyEnabled,
    isSalesForceEnabled,
  } = props;
  const { data } = useGetOpportunityFiltersQuery();

  const onChange = useCallback(
    (text: string | undefined) => {
      trackEvent(TRACKING_CONSTANTS.DASHBOARD_MSP_SEARCH, {
        searchTerm: text ? text?.trimStart() : '',
      });
      setOpportunitySearch(text ? text?.trimStart() : '');
    },
    [setOpportunitySearch],
  );
  return (
    <div className={HEADER_CLASS}>
      <BaInput
        parentValue={searchRedux}
        onChange={onChange}
        placeholder="Search Opportunity"
        debounceTime={500}
        type="search"
        className="w-1/3"
      />
      {data && (
        <FilterComponent
          sectionName="rmap-opportunity"
          filterConfig={data as IFilterConfig}
          isResetButtonEnabled={false}
          debounceTime={1300}
        />
      )}
      {isRmapOnlyEnabled && isSalesForceEnabled && (
        <CreateNewRmapWithOpportunity />
      )}
    </div>
  );
}

export default connector(OpportunityHeader);
