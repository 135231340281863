import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BaResourceDetails,
  PushNotificationQuery,
} from '../../../generated-types';
import { GET_NOTIFICATION_ACCOUNTS, GET_NOTIFICATION_LIST } from './graphql';

export type ResultNotification = {
  pushNotificationQuery: PushNotificationQuery;
};

export const fetchNotification = createAsyncThunk(
  'notification/get-notification',
  async (
    {
      client,
      userId,
      accountId,
      baResourceList,
    }: {
      client: ApolloClient<object>;
      userId: string;
      accountId?: string;
      baResourceList?: BaResourceDetails[];
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultNotification>({
        query: GET_NOTIFICATION_LIST,
        variables: {
          input: {
            userId,
            ...(accountId && { accountIds: [accountId] }),
            ...(baResourceList && { baResourceList }),
          },
          pageConfiguration: {
            limit: 30,
          },
        },
      });
      const { data: accountData } = await client.query<ResultNotification>({
        query: GET_NOTIFICATION_ACCOUNTS,
        variables: {
          userId,
        },
      });
      return { data, accountData };
    } catch (error: unknown) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const notificationPagination = createAsyncThunk(
  'notification/notificationPagination',
  async (
    {
      client,
      afterCursor,
      userId,
      accountId,
      baResourceList,
    }: {
      client: ApolloClient<object>;
      afterCursor?: string;
      userId: string;
      accountId?: string;
      baResourceList?: BaResourceDetails[];
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultNotification>({
        query: GET_NOTIFICATION_LIST,
        variables: {
          input: {
            userId,
            ...(accountId && { accountIds: [accountId] }),
            ...(baResourceList && { baResourceList }),
          },
          pageConfiguration: {
            limit: 30,
            afterCursor,
          },
        },
      });
      return data;
    } catch (error: unknown) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

// export const pollNotification = createAsyncThunk(
//   'notification/poll-notification',
//   async (
//     { client, userId }: { client: ApolloClient<object>; userId: string },
//     { rejectWithValue },
//   ) => {
//     try {
//       const { data } = await client
//         .watchQuery<ResultNotification>({
//           query: POLL_NOTIFICATION_LIST,
//           variables: {
//             userId,
//           },
//           pollInterval: 6000,
//         })
//         .result();
//       return data;
//     } catch (error: unknown) {
//       return rejectWithValue((error as Error)?.message);
//     }
//   },
// );
