import { Typography } from '../../../components';

interface SubSectionHeaderProps {
    content: string;
  }

export default function SubSectionHeader({ content }:SubSectionHeaderProps) {
  return (
    <Typography
      variant="heading5-bold"
      className="text-left mt-8 mb-4 px-8 leading-6 text-sm text-[#395468]"
    >
      {content}
    </Typography>
  );
}
