import React from 'react';
import NewHeader from './new-header';
import ErrorBoundary from '../../components/error-boundary';

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ErrorBoundary title="Something went wrong.">
        <NewHeader />
      </ErrorBoundary>
      <ErrorBoundary title="Something went wrong.">{children}</ErrorBoundary>
    </>
  );
}

export default Layout;
