import { getBaseUrl, orgIdExtractor } from '../../utils';

const orgId = orgIdExtractor();
export interface IFetchParams {
  method: string;
  url?: string;
  data?: any;
  headers?: { [key: string]: string };
  authToken?: string | undefined;
}

export function fetchCall({
  method,
  url = '',
  data = {},
  authToken = '',
  headers,
}: IFetchParams) {
  const baseUrl = getBaseUrl();
  let localHeaders: { [key: string]: string } = {
    'Content-type': 'application/json; charset=UTF-8',
    SellerOrgId: orgId,
  };

  if (authToken) {
    localHeaders = { ...localHeaders, Authorization: `Bearer ${authToken}` };
  }
  localHeaders = { ...localHeaders, ...headers };

  if (method === 'GET') {
    return fetch(`${baseUrl || ''}${url || ''}`, {
      method,
      headers: localHeaders,
    });
  }

  return fetch(`${baseUrl || ''}${url || ''}`, {
    method,
    body: JSON.stringify(data),
    headers: localHeaders,
  });
}

// .then((response) => {
//       if (!response.ok) {
//         throw new Error(response.statusText);
//       }
//       // Read the response as json.
//       return response.json();
//     });
