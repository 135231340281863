import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const CREATE_ASSIGN_PLACEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation createAndAssignPlaceholderToRole(
    $input: CreateAndAssignPlaceholderToRoleInput!
  ) {
    memberMutation {
      createAndAssignPlaceholderToRole(input: $input) {
        __typename
        ...CoreErrorFields
        ... on AddOrRemovePlaceholderPayload {
          items {
            roleId
            placeholderId
          }
        }
      }
    }
  }
`;

export const REMOVE_PLACEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation removePlaceholderFromRole($input: RemovePlaceholderFromRoleInput!) {
    memberMutation {
      removePlaceholderFromRole(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const REPLACE_PLACEHOLDER_WITH_STAKEHOLDER = gql`
  ${CORE_GRAPH_ERROR}
  mutation replacePlaceholderWithStakeholder($input: ReplacePlaceholderWithStakeholderInput!) {
    memberMutation {
      replacePlaceholderWithStakeholder(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;
