import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { getFilteredObjectList } from '../../common/methods/filter-object';
import { RootState } from '../../common/redux-store';
import { isInternal } from '../../common/redux-store/selectors';
import { CustomToolTip, FilterComponent, ProgressBar } from '../../components';
import { DotIcon, UserCheck } from '../../components/icons';
import { Typography } from '../../components/typography';
import { MspTrackStatus, Organization } from '../../generated-types';
import DashboardInsightContainer from './dashboard-analytics/dashboard-insight-container';
import { IMspState } from './models';
import MspArchival from './msp-archival';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';

dayjs.extend(relativeTime);

const statusMap = {
  [MspTrackStatus.OFF_TRACK]: {
    id: 'mspStatus.offTrack',
    msg: 'Off Track',
    color: 'text-error',
  },
  [MspTrackStatus.UNKNOWN]: {
    id: 'mspStatus.unknown',
    msg: '--',
    color: 'text-mid',
  },
  [MspTrackStatus.ON_TRACK]: {
    id: 'mspStatus.onTrack',
    msg: 'On Track',
    color: 'text-success',
  },
};

export const headerRenderer = ({
  column: { title },
}: {
  column: { title: string };
}) => (
  <CustomToolTip showTruncated arrow placement="top" title={title}>
    <div className="truncate">
      <Typography
        variant="body-13-bold"
        className="w-full truncate text-mid-500"
      >
        {title}
      </Typography>
    </div>
  </CustomToolTip>
);

export const unixDateRenderer = ({ cellData }: { cellData: number }) => {
  try {
    return (
      <Typography variant="body-13-regular" className="text-neutral-400">
        {cellData ? dayjs(cellData * 1000).format('MMM D, YYYY') : '--'}
      </Typography>
    );
  } catch {
    return null;
  }
};
export const normalDateRenderer = ({
  cellData,
  format = 'MMM D, YYYY',
}: {
  cellData: number;
  format?: string;
}) => {
  try {
    return (
      <Typography variant="body-13-regular" className="text-neutral-400">
        {cellData ? dayjs(cellData).format(format) : '--'}
      </Typography>
    );
  } catch {
    return null;
  }
};
export const behindByRenderer = ({ cellData }: { cellData: number }) => (
  <Typography variant="body-13-regular" className="text-neutral-400">
    {cellData ? dayjs(cellData).fromNow().replace('ago', 'behind') : '--'}
  </Typography>
);

export const TableCell = ({ cellData }: { cellData: string }) => {
  try {
    if (cellData === null || cellData === undefined) {
      return (
        <Typography
          variant="body-13-regular"
          className="capitalize text-neutral-400"
        >
          --
        </Typography>
      );
    }

    return (
      <Typography
        variant="body-13-regular"
        className="capitalize text-neutral-400"
      >
        {cellData?.toLowerCase() ?? '--'}
      </Typography>
    );
  } catch {
    return null;
  }
};

export const NameHeaderRenderer = ({
  column: { title },
}: {
  column: { title: string };
}) => (
  <Typography variant="body-13-bold" className="pl-4 text-mid-500">
    {title}
  </Typography>
);
export const AssigneeHeaderRenderer = ({
  column: { title },
}: {
  column: { title: string };
}) => {
  const { filterDetails } = useSelector((state: RootState) => state?.allTasks);
  const assigneeFilterList = getFilteredObjectList(filterDetails, 'Assignee');
  return (
    <>
      <Typography variant="body-13-bold" className="pl-1 text-mid-500">
        {title}
      </Typography>
      <FilterComponent
        sectionName="allTasks"
        filterConfig={assigneeFilterList}
        isResetButtonEnabled={false}
        isCustomFilterIconVisible={false}
        isFilterTitleHidden
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        rootClasses="mt-3"
      />
    </>
  );
};

export const AccountNameHeaderRenderer = ({
  column: { title },
}: {
  column: { title: string };
}) => {
  const { filterDetails } = useSelector((state: RootState) => state?.allTasks);
  const assigneeFilterList = getFilteredObjectList(filterDetails, 'Account');
  return (
    <>
      <Typography variant="body-13-bold" className="text-mid-500">
        {title}
      </Typography>
      <FilterComponent
        sectionName="allTasks"
        filterConfig={assigneeFilterList}
        isResetButtonEnabled={false}
        isCustomFilterIconVisible={false}
        isFilterTitleHidden
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        rootClasses="mt-3"
      />
    </>
  );
};

export const StatusRow = ({ cellData }: { cellData: MspTrackStatus }) => {
  try {
    if (!cellData) return null;
    return (
      <div className="flex items-center space-x-1">
        {MspTrackStatus.UNKNOWN !== cellData && (
          <DotIcon className={`${statusMap[cellData]?.color} text-16`} />
        )}
        <Typography variant="body-13-regular" className="text-neutral-400">
          <FormattedMessage
            id={statusMap[cellData]?.id}
            defaultMessage={statusMap[cellData]?.msg}
          />
        </Typography>
      </div>
    );
  } catch {
    return null;
  }
};

export const MspNameCell = ({
  cellData,
  rowData,
}: {
  cellData?: NonNullable<React.ReactNode>;
  rowData?: IMspState;
}) => {
  const isAnalyticsEnabled =
    useSelector((state: RootState) => {
      const others = (state.organization as Organization)?.configs?.others as {
        featureFlags: { analytics: boolean };
      };
      return others?.featureFlags?.analytics;
    }) || false;
  const store = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const logo = rowData?.buyerLogo;

  try {
    if (rowData?.id?.includes('error'))
      return (
        <Typography
          variant="body-13-semibold"
          className="primary-highlight w-full truncate text-neutral"
        >
          {cellData}
        </Typography>
      );
    return (
      <>
        <div
          className="flex w-full items-center justify-between"
          key={rowData?.id}
        >
          <div className="flex cursor-pointer flex-row items-center truncate">
            <Link
              to={{
                pathname: `/ui/alignx/msp/${rowData?.id ?? ''}`,
                search: new URLSearchParams(window.location.search).toString(),
              }}
              className="mr-1 truncate pl-4 no-underline"
            >
              <div className="flex items-center space-x-3 ">
                {logo ? (
                  <img
                    src={logo}
                    alt=""
                    className="h-6 w-6 rounded border border-solid border-mid-100 bg-white object-cover object-left p-[3px]"
                  />
                ) : (
                  <HiOutlineOfficeBuilding className="rounded border border-solid border-mid-100 p-[3px] text-16 text-mid" />
                )}

                <CustomToolTip
                  arrow
                  placement="top"
                  showTruncated
                  title={cellData ?? ''}
                >
                  <div
                    className="truncate"
                    onClick={() => {
                      dispatch(
                        trackEvent(TRACKING_CONSTANTS.ACCESSED_MSP, {
                          from: 'Dashboard',
                          mspId: rowData?.id,
                        }),
                      );
                    }}
                  >
                    <Typography
                      variant="body-13-semibold"
                      className="primary-highlight truncate text-neutral"
                    >
                      {cellData}
                    </Typography>
                  </div>
                </CustomToolTip>
              </div>
            </Link>

            {isInternal(store) && isAnalyticsEnabled && (
              <DashboardInsightContainer
                mspId={rowData?.id}
                mspName={rowData?.name}
                mspStage={rowData?.mspStage}
              />
            )}
            {rowData?.mspStage !== 'ARCHIVED' && (
              <MspArchival
                mspId={rowData?.id}
                mspStage={rowData?.mspStage}
                isInsightIconAvailable={
                  !!(isInternal(store) && isAnalyticsEnabled)
                }
                isOwner={rowData?.isOwner || false}
              />
            )}
          </div>
          {typeof rowData?.isOwner === 'boolean' && !rowData?.isOwner && (
            <CustomToolTip
              arrow
              placement="top"
              title={
                <span>
                  {rowData?.sharedByUserName
                    ? `Shared by: ${rowData?.sharedByUserName} `
                    : ''}
                  <br />
                  {rowData?.ownerName ? `Owner: ${rowData?.ownerName}` : ''}
                </span>
              }
            >
              <div className="ml-1 mr-1 mt-0.5 items-center">
                <UserCheck className="text-mid-400" />
              </div>
            </CustomToolTip>
          )}
        </div>
      </>
    );
  } catch {
    return null;
  }
};

export const mapStage = ({ cellData }: { cellData: string }) => {
  try {
    return (
      <div className="flex items-center space-x-2">
        <Typography
          variant="body-13-regular"
          className="capitalize text-neutral-400"
        >
          {cellData?.toLowerCase()}
        </Typography>
      </div>
    );
  } catch {
    return null;
  }
};

export const progressCell = ({ cellData }: { cellData: number }) => {
  try {
    if (cellData === undefined || cellData === null) return <div>--</div>;
    return (
      <div className="flex w-full items-center space-x-2 md:w-4/5 lg:w-3/5 xl:w-1/2">
        <ProgressBar completionPercentage={cellData} />
        <Typography
          variant="body-13-regular"
          className="text-neutral-400"
        >{`${cellData}%`}</Typography>
      </div>
    );
  } catch {
    return null;
  }
};

export function getCellRenderer(key: string | undefined) {
  switch (key) {
    case 'name':
      return <MspNameCell />;
    case 'mspTrackStatus':
      return StatusRow;
    case 'mspProgress':
      return progressCell;
    case 'OpportunityCloseDate':
      return normalDateRenderer;
    case 'mspStage':
      return mapStage;
    case 'lastTaskCompletedAt':
    case 'lastActivityAt':
    case 'lastExternalActivityAt':
      return unixDateRenderer;

    default:
      return TableCell;
  }
}
export function getHeaderRenderer(key: string | undefined) {
  switch (key) {
    case 'name':
      return NameHeaderRenderer;

    default:
      return headerRenderer;
  }
}
