import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import {
  MspMetrics,
  MspTrackStatus,
  User,
  UserResponse,
} from '../../../generated-types';
import LastAccessCard from './last-access-card';
import LastActivityCard from './last-activity-card';
import SummaryStatusCard from './summary-status-card';

interface DashboardMetricCardsProps {
  data: MspMetrics;
  isLoading: boolean;
  isError: boolean;
}

function DashboardMetricCards(props: DashboardMetricCardsProps) {
  const { data, isLoading } = props;
  const trackStatus = data?.mspTrack?.mspTrackStatus ?? '';
  const slipInterval = data?.mspTrack?.mspSlipDisplayTime ?? 0;
  const activityAtData = data?.lastActivity?.activityAt;
  const lastActivityDate = activityAtData
    ? dayjs(activityAtData * 1000).format('MMM DD, YYYY')
    : 'N/A';
  const lastActivityMessage = data?.lastActivity?.message || 'No activity';
  const lastExternalActivityDate = data?.lastExternalActivity?.activityAt;
  const lastAccessDate = lastExternalActivityDate
    ? dayjs(lastExternalActivityDate * 1000).format('MMM DD, YYYY')
    : 'N/A';
  const lastAccessUser =
    data?.lastExternalActivity?.activityByUser ??
    ('' as unknown as UserResponse);
  const lastExternalActivity = data?.lastExternalActivity;

  if (isLoading) {
    return (
      <div className="mt-5 flex flex-row justify-between">
        <Skeleton
          variant="rectangular"
          width={180}
          height={118}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={180}
          height={118}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={180}
          height={118}
          animation="wave"
        />
      </div>
    );
  }
  if (data) {
    return (
      <div className="mt-5 flex flex-row justify-between space-x-2">
        <SummaryStatusCard
          label="Status"
          content={trackStatus as MspTrackStatus}
          secondaryContent={`Estimated Slip: ${slipInterval}`}
        />
        <LastActivityCard
          label="Last Activity"
          content={lastActivityDate}
          secondaryContent={lastActivityMessage}
        />
        <LastAccessCard
          label="Last External Activity"
          content={lastAccessDate}
          lastAccessUser={lastAccessUser as User}
          hasData={lastExternalActivityDate !== null}
        />
      </div>
    );
  }
  return null;
}

export default DashboardMetricCards;
