const actionItems = 'flex items-center';

function TaskContainerCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
  taskRef?: React.MutableRefObject<null>;
}) {
  const { children, classList, taskRef } = props;
  return (
    <div
      ref={taskRef}
      className={`${
        classList ?? ''
      } task-row-container grid grid-cols-12 grid-rows-1 rounded-md border border-solid border-gray-100`}
    >
      {children}
    </div>
  );
}
function CommonActionCountLayout(props: {
  children: React.ReactNode;
  value?: number;
  isDueDate?: boolean;
  onClick?: () => void;
  isVisibleByDefault?: boolean;
}) {
  const {
    children,
    value,
    isDueDate = false,
    onClick,
    isVisibleByDefault = false,
  } = props;
  return (
    <div
      role="presentation"
      className={`flex h-5 w-10 cursor-pointer items-center justify-center rounded-2xl px-0.5 py-0.5 ${
        isVisibleByDefault
          ? 'visible'
          : 'group-milestone-hover:visible invisible'
      } ${
        isDueDate ? 'bg-[#FFF1F2] text-warning' : 'bg-primary-100 text-primary'
      }`}
      onClick={onClick}
    >
      <div className="flex">{children}</div>
      <div className="pl-1 text-12">{value}</div>
    </div>
  );
}

function TaskAssigneeCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div
      className={`${classList ?? ''} ${actionItems} col-span-5 justify-start`}
    >
      {children}
    </div>
  );
}

function TaskDueDateCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div
      className={`${classList ?? ''} ${actionItems} col-span-3 cursor-default`}
    >
      {children}
    </div>
  );
}

function TaskNotesCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div className={`${classList ?? ''} ${actionItems} col-span-1`}>
      {children}
    </div>
  );
}

function TaskActionMenuCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div
      className={`${
        classList ?? ''
      } ${actionItems} col-span-2 justify-end pr-1`}
    >
      {children}
    </div>
  );
}

function TaskActionsColumnCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div className={`${classList ?? ''} col-span-6 grid grid-cols-12`}>
      {children}
    </div>
  );
}

function TaskTitleCommonLayout(props: {
  children: React.ReactNode;
  classList?: string;
}) {
  const { children, classList } = props;
  return (
    <div
      className={`${
        classList ?? ''
      } col-span-6 flex w-4/5 items-center justify-start`}
    >
      {children}
    </div>
  );
}

export {
  TaskContainerCommonLayout,
  TaskActionsColumnCommonLayout,
  TaskAssigneeCommonLayout,
  TaskDueDateCommonLayout,
  TaskNotesCommonLayout,
  TaskActionMenuCommonLayout,
  TaskTitleCommonLayout,
  CommonActionCountLayout,
};
