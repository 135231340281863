export const message = {
  'Button.back': 'Back',
  'Button.backToDashboard': 'Back to dashboard',
  'Button.next': 'Next',
  'Button.cancel': 'Cancel',
  'Button.save': 'Save',
  'Button.share': 'Share',
  'Button.restore': 'Restore',
  'Button.archive': 'Archive',
  'Button.includeExternalUsers': 'Allow External users.',
  'Button.reset': 'Reset',
  'Button.createMsp': 'Continue without a template',
  'Button.assignConfirm': 'Assign',
  'Button.ignore': 'No',
  'Button.agree': 'Yes',
  'Button.addUser': 'Add new user',
  'Button.allUsers': 'Assignee',
  'Button.allTimes': 'All times',
  'Button.allStatus': 'Status',
  'Button.publish': 'Publish',
  'Button.comment': 'Comment',
  'Button.assignMsp': '+ Assign Mutual Success Plan',
  'Button.assignOpportunity': '+ Assign Opportunity',
  'Button.sync': 'Sync',
  'Button.update': 'Update',
  'Button.addToSuccessPlan': 'Add to success plan',
  'Button.newMilestone': 'New Milestone',
  'Button.createMilestone': 'Create Milestone',
  'Button.startWithoutTemplate': 'Start without a template',
  'Button.newTask': 'New task',
  'Button.assign': 'Assign now',
  'Button.createAssign': 'Create user and Assign',
  'Button.copyLink': 'Copy link',
  'Button.generateLink': 'Generate link',
  'Button.change': 'Change',
  'Button.add': 'Add',
  'Button.close': 'Close',
  'Button.apply': 'Apply',
  'Button.confirm': 'Confirm',
  'Button.continue': 'Continue',
  'Button.goToMsp': 'Go to Mutual Success Plan',
  'Button.viewMore': 'View More',
  'Button.viewLess': 'View Less',
  'Button.send': 'Send',
  'Button.customerView': 'External View',
  'Button.syncNow': 'Sync Now',
  'Button.sendInvite': 'Invite',
  'Button.viewMspAnalytics': 'View MSP Analytics',
  'Button.disengage': 'Yes, Remove.',
  'Button.create': 'Create',
  'Button.createNew': 'Create New',
  'Button.newMspPlan': 'Create Mutual Success Plan',
  'AlignxDashboard.onTrackPlan': 'On track',
  'AlignxDashboard.offTrackPlan': 'Off track',
  'Button.opportunityNotListed': 'Opportunity not listed here',
  'Button.saveMsp': 'Generate Mutual Success Plan!',
  'Form.mspNamePlaceholder': 'Enter Mutual Success Plan name',
  'Form.link': 'Link',
  'Form.mspName': 'Mutual Success Plan name*',
  'Form.description': 'Executive summary*',
  'Form.mspCustomerName': 'Customer name*',
  'Form.descriptionPlaceholder': 'Enter description',
  'Form.customerNamePlaceholder': 'Customer name',
  'Form.companyName': 'Company Name',
  'Form.firstName': 'First Name *',
  'Form.lastName': 'Last Name *',
  'Form.email': 'Email Address',
  'Form.optionalEmail': 'Enter Email Address',
  'Form.requiredEmail': 'Enter Email Address *',
  'Form.password': 'Password',
  'Form.accountName': 'Account name',
  'Form.accountNamePlaceholder': 'Enter account name',
  'AlignxDashboard.tableTitle': 'All success plan',
  'AlignxDashboardTableColumn.successPlan': 'Mutual Success Plan name',
  'AlignxDashboardTableColumn.amount': 'Amount',
  'AlignxDashboardTableColumn.closeDate': 'Close date',
  'AlignxDashboardTableColumn.startDate': 'Start date',
  'AlignxDashboard.createSuccessPlan': 'Create success plan',
  'Editor.addTask': 'Add task',
  'Editor.enterName': 'Enter name',
  'Editor.enterTaskDetails': 'Enter task',
  'Editor.milestoneName': 'Enter milestone name',
  'MilestoneDetails.goals': 'Goals',
  'MilestoneDetails.goalsPlaceholder': 'Add Milestone Goals',
  'MilestoneDetails.Placeholder': 'Add a description...',
  'MilestoneDetails.riskDependencies': 'Risks and Dependencies',
  'MilestoneDetails.riskDependenciesPlaceholder':
    'Manage Milestone Risks and Dependencies',
  'MilestoneDetails.successCriteria': 'Success Criteria',
  'MilestoneDetails.successCriteriaPlaceholder':
    'Add Milestone Success Criteria',
  'Tab.planView': 'Plan view',
  'Tab.canvasView': 'Canvas view',
  'Tab.taskList': 'Task list',
  'Tab.milestoneDetails': 'Milestone details',
  'AddMileStone.optionalDetails': 'Optional details',
  'AddMileStone.addTask': 'Add tasks',
  'AddMileStone.addTaskDetails':
    '(Press enter or click on add button to add task)',
  'AddMileStone.chooseTask': 'Select Tasks:',
  'AddMileStone.milestoneName': 'Milestone Name',
  'Snackbar.publishMessage': 'Publish successfully',
  'Snackbar.linkCopied': 'URL copied',
  'Snackbar.passwordCopied': 'Password copied',
  'Snackbar.removeComment': 'Comment deleted',
  'Snackbar.addAssigneSuccess': 'Assigned successfully',
  'Snackbar.userAddSucess': 'New User added successfully',
  'Snackbar.mspCreatedSuccess': 'MSP created successfully',
  'Snackbar.mspRestoredSuccess': 'Mutual Success Plan successfully restored!',
  'Snackbar.mspArchivedSuccess': 'Mutual Success Plan successfully archived!',
  'Snackbar.milestoneCreatedSuccess': 'Milestone created successfully',
  'Snackbar.somethingWentWrong': 'Something went wrong, please try again!',
  'Snackbar.pleaseFillDetails': 'Please fill in the required fields',
  'Snackbar.emailCopied': 'Email copied',
  'Popover.deleteTask': 'Delete Task',
  'Popover.duplicateTask': 'Duplicate task',
  'Popover.deleteMilestone': 'Delete Milestone',
  'Popover.duplicateMilestone': 'Duplicate milestone',
  'Popover.editComment': 'Edit comment',
  'Popover.deleteComment': 'Delete comment',
  'MilestoneAccordion.dueDate': 'Due date',
  'MilestoneAccordion.completed': 'completed',
  'TaskStatus.pending': 'Pending',
  'TaskStatus.completed': 'Completed',
  'AlignxDashboardTableColumn.activeMilestones': 'Active milestones',
  'MilestoneBlankState.title': 'No Milestones yet',
  'MilestoneBlankState.description':
    'Create your first new Milestone. You can do it all from scratch or use a template.',
  'MspBlankState.title': 'No Mutual Success Plan created',
  'MspBlankState.description':
    'Get started with your first Mutual Success Plan',
  'TaskBlankState.title': 'Create a task',
  'TaskBlankState.description':
    'Milestones are a collection of tasks, create your first task in this milestone',
  'Keydates.vendorOfChoice': 'Vendor of choice',
  'Keydates.contractSignature': 'Contract signature',
  'Keydates.goLive': 'Go Live Date',
  'Keydates.implementation': 'Implementation',
  'Keydates.paperWork': 'Paperwork',
  'Keydates.reverseTimeline': 'Reverse Timeline',
  'Keydates.forwardTimeline': 'Forward Timeline',
  'SearchBarPlaceholder.searchTemplate': 'Search for a template',
  'SearchBarPlaceholder.searchUser': 'Search for a user',
  'Taskstatus.completed': 'Completed',
  'Taskstatus.pending': 'Pending',
  'Datepicker.startDate': 'Start date*',
  'Datepicker.mspStartDate': 'MSP Starting Date *',
  'AlignxDashboard.onTrack': 'On track',
  'AlignxDashboard.offTrack': 'Off track',
  'AlignxDashboard.draft': 'Draft',
  'ShareMsp.title': 'Share Mutual Success Plan',
  'ShareMsp.invitePersons': 'Search or Invite collaborators',
  'ShareMsp.noOptions': 'No options',
  'ShareMsp.personalizeShareHeading': 'Share with People',
  'ShareMsp.personalizeShareMessage': 'Only invited user can access this link',
  'ShareMsp.publicShareHeading': ' Public link',
  'ShareMsp.publicShareMessage': 'Anyone can view using this link',
  'ShareMsp.passwordHeading': 'Password protect this plan',
  'Password.message':
    'Password must contain at least 8 digits, 1 character and 1 number',
  'UpdateMsp.readMore': 'View more',
  'UpdateMsp.readLess': 'View less',
  'CreateMspModal.modalTitle': 'Add new milestone',
  'CreateMsp.selectOpportunity': 'Selected Opportunity',
  'CreateMsp.selectOpportunityPageTitle': 'Select the Opportunity',
  'CreateMsp.unauthorizedUserMessageTitle':
    'You are not authorized to create a plan',
  'CreateMsp.unauthorizedUserMessageText':
    'Please get in touch with the Account Administrator.',
  'Editor.addComment': 'Add comment',
  'Editor.updateComment': 'Edit your comment',
  'MspPlanView.mspNameError': 'Msp Name should not be more than 100 characters',
  'MspPlanView.mspNameAPIError': "Msp Name didn't save. Please try again",
  'Dashboard.greet': 'Hello',
  'Dashboard.greetLabel': 'Here are your Mutual Success Plans!',
  'Dashboard.tableTitle': 'All Mutual Success Plans',
  'Dashboard.onTrackLabel': 'On Track',
  'Dashboard.offTrackLabel': 'Off Track',
  'Button.newMspPlan': 'Create Mutual Success Plan',
  'Dashboard.newMspPlanTitle': 'New Mutual Success Plan',
  'NewMspPlan.displayName': 'Mutual Success Plan (MSP) Name *',
  'NewMspPlan.mspTemplate': 'MSP Template *',
  'ChooseTemplate.title':
    'Step 2: Pick the right template for your opportunity',
  'CreateAlign.title':
    'Step 3: Customize Mutual Success Plan for your opportunity',
  'Dashboard.MspNotFoundFilterTitle':
    'No Mutual Success Plan found matching your search',
  'Dashboard.MspNotFoundFilterDesc': 'Please change the filters and try again',
  'Dashboard.loadingMessageTitle': 'Data And Coffee,',
  'Dashboard.loadingSupportMessage':
    "both taste best when they're brewed fresh!",
  'MspPlanView.executiveSummary': 'Executive Summary',
  'MspPlanView.decisionCriteria': 'Decision Criteria',
  'MspPlanView.milestoneNameError':
    'Milestone Name should not be more than 100 characters',
  'MspPlanView.taskNameError':
    'Task Name should not be more than 200 characters',
  'MspPlanView.newTaskApiError': 'New Task could not created.',
  'MspCreateFlow.unsavedChangesPrompt':
    'Changes that you made may not be saved',
  'Tooltip.listOfOpportunity': 'List of Opportunities:',
  'MspPlanView.taskReorderError': 'Task re order not saved. Please try again.',
  'MspPlanView.mspTaskStatusAPIError':
    'Task completion status not saved, Please try again',
  'MspPlanView.mspMarkInternalAPIError':
    'Mark internal not changed, Please try again',
  'MspPlanView.taskNameAPIError': "Task Name didn't save, please try again",
  'MspPlanView.taskDeleteAPIError':
    'Task could not be deleted, please try again',
  'MspPlanView.taskStatusPending': 'Not Started',
  'MspPlanView.taskStatusCompleted': 'Completed',
  'MspPlanView.taskStatusProgress': 'In Progress',
  'MspPlanView.taskMarkInternalAPIError':
    'Task could not be marked, please try again',
  'MspPlanView.taskDuplicateAPIError':
    'Task could not be duplicated, please try again',
  'MspPlanView.milestoneStartDate':
    "Milestone Start Date didn't save, please try again",
  'MspPlanView.taskDueDate': "Task Due Dates didn't save, please try again",
  'MspPlanView.milestoneEndDate':
    "Milestone End Date didn't save, please try again",
  'CreateMsp.searchOpportunityPlaceHolder':
    'Search opportunities from Salesforce',
  'MspPlanView.addAssigneeError':
    'Uh oh! We were unable to do that. Please try again.',
  'MspPlanView.userSearchApiError':
    'Failed to get searched member. Please try again.',
  'MspPlanView.milestoneDetailsAPIError':
    "Milestone Details didn't save , please try again",
  'Editor.executiveSummaryPlaceholder': 'Enter Executive Summary',
  'WarningTitle.publish': 'Publish success plan?',
  'WarningMessage.publish':
    'Publishing success plan will allow you to share it with your stakeholders.',
  'MspPlanView.mspPublishAPIError':
    'Msp could not published, Please try again.',
  'MspPlanView.milestoneDeleteAPIError':
    'Milestone could not be deleted, please try again',
  'MspPlanView.createMilestoneAPIError':
    'Milestone could not be saved, please try again',
  'MspPlanView.createBulkTaskAndMilestoneDetailsAPIError':
    'Tasks and Milestone details could not be saved, please try again',
  'MspPlanView.createBulkTaskAPIError':
    'Tasks could not be saved, please try again',
  'MspPlanView.createMilestoneDetailsAPIError':
    'Milestone details could not be saved, please try again',
  'MspPlanView.milestoneDuplicateAPIError':
    'Milestone could not be duplicated, please try again',
  'MoreAction.mspArchiveMessage': 'Archive MSP',
  'MoreAction.mspUnArchiveMessage': 'Unarchive',
  'MoreAction.milestoneDeleteActionMessage': 'Delete Milestone',
  'MoreAction.milestoneDuplicateActionMessage': 'Duplicate',
  'MoreAction.milestoneInternalActionMessage': 'Mark as Internal',
  'MoreAction.milestoneReinstatelActionMessage': 'Reinstate Milestone',
  'MoreAction.taskDeleteActionMessage': 'Delete Task',
  'MoreAction.taskReinstatelActionMessage': 'Reinstate Task',
  'MoreAction.taskDuplicateActionMessage': 'Duplicate',
  'MoreAction.commentDeleteActionMessage': 'Delete',
  'MoreAction.commentEditActionMessage': 'Edit',
  'MspPlanView.mspExecutiveSummaryAPIError':
    "Executive Summary didn't save. Please try again",
  'MspPlanView.mspDecisionCriteriaAPIError':
    "Decision Criteria didn't save. Please try again",
  'MspPlanView.mspReverseTimelineAPIError':
    "Key Dates didn't save, please try again",
  'MspPlanView.assigneeApiError':
    'User could not be assigned, please try again',
  'MspPlanView.removeAssigneeApiError':
    'Could not Remove Assignee, please try again',
  'MspPlanView.mspOnTrackMsg': 'Woohoo! There are no Overdue Tasks',
  'MspPlanView.mspOnTrackBuyerMsg': "Looks like you're solid!",
  'MspPlanView.mspOnTrackBuyerDetailMsg':
    'But some minor things are going on at',
  'WarningMessage.removeUserMsg': 'Do you want to remove user?',
  'WarningMessage.modifyTaskDueDatesMsg':
    'Do you want to shift the corresponding task due dates in this milestone proportionately ?',
  'WarningMessage.deselectOpportunity':
    'Are you sure you want to deselect Opportunity ?',
  'WarningTitle.removeUserTitle': 'Remove user',
  'WarningTitle.milestoneStartDateTitle': 'Update Date!',
  'WarningTitle.deleteMilestone': 'Delete this milestone?',
  'WarningTitle.deleteTask': 'Delete this task?',
  'WarningTitle.markMilestoneInternal': 'Make this an Internal Milestone?',
  'WarningTitle.reinstateMilestone': 'Reinstate External access?',
  'WarningMessage.markMilestoneInternal':
    'It will no longer be visible to External users.',
  'WarningMessage.reinstateMilestone':
    'The Milestone will become visible to External users.',
  'WarningTitle.markTaskInternal': 'Make this an Internal Task?',
  'WarningTitle.reinstateTask': 'Reinstate External access?',
  'WarningMessage.markTaskInternal':
    'It will no longer be visible to External users.',
  'WarningMessage.reinstateTask':
    'The Task will become visible to External users.',
  'WarningTitle.removeTimelineSteps':
    'By removing this {timelineLabel} will change the duration between other fields',
  'WarningTitle.deselectOpportunity':
    'Are you sure you want to deselect Opportunity ?',
  'MspPlanView.noAssetsAddedMessageTitle': 'No Assets added',
  'WarningTitle.deleteAsset': 'Are you sure you want to delete?',
  'MspPlanView.commentUpdateError':
    'Comment could not be updated, please try again',
  'MspPlanView.commentPostError':
    'Comment could not be posted, please try again',
  'MspPlanView.commentDeleteError':
    'Comment could not be deleted. Something went wrong',
  'Media.deleteAPIError': 'Delete operation not completed, Please try again',
  'Media.FavoriteAPIError':
    'Favorite operation not completed, Please try again',
  'Media.UnfavoriteAPIError':
    'UnFavorite operation not completed, Please try again',
  'Media.PinAPIError': 'Pin operation not completed, Please try again',
  'Media.RenameFileAPIError': 'File could not be renamed, Please try again',
  'Media.PublicAccessAPIError':
    'Something went wrong in getting Public access, Please try again',
  'Media.attachAPIError': 'Attach operation not completed, Please try again',
  'MspPlanView.updatePermission': 'Privilege updated successfully.',
  'MspPlanView.userUserInShareMsp':
    'User created successfully. Invite them to the MSP.',
  'MspPlanView.addUserError': "We couldn't create the User. Please try again.",
  'MspPlanView.updatePermissionError':
    "We couldn't update the User's privilege. Retry after some time.",
  'MspPlanView.permissionRevoked': "User's access successfully removed.",
  'MspPlanView.permissionRevokedError':
    "We couldn't remove the User's access. Please try again.",
  'MspPlanView.permissionUpdated': 'User permission updated!',
  'MspPlanView.createUsp': 'Public URL generated.',
  'MspPlanView.createUspError':
    "We couldn't generate a Public URL. Please try again.",
  'MspPlanView.updateUsp': 'Password updated.',
  'MspPlanView.clearPassword': 'Password removed.',
  'MspPlanView.emailNotification': 'Notify User',
  'MspPlanView.milestoneStatus': 'Overdue Tasks',
  'MspPlanView.sendCopyToMe': 'Send a copy to me',
  'MspPlanView.bulkInvitee': 'Users invited successfully!',
  'MspPlanView.bulkInviteeError':
    'Users could not be invited, please try again',
  'ShareMsp.private': 'Copy private link',
  'ShareMsp.public': 'Copy public link',
  'ShareMsp.publicLinkSupportText':
    'Users cannot edit, only view the Mutual Success Plan. They can ignore the email address prompt.',
  'ShareMsp.privateLinkSupportText':
    'Users must enter an authorized email address to view/edit the Mutual Success Plan.',
  'ShareMsp.setPassword': 'Set Password',
  'MspPlanView.getMilestoneTemplateAPIError':
    'Failed to get milestone template data. Please try again.',
  'ShareMsp.privateUrl': 'Private URL',
  'ShareMsp.publicUrl': 'Public URL',
  'MspPlanView.businessObjectives': 'Business Objectives',
  'MspPlanView.businessObjectivesPlaceholder': 'Enter Business Objectives',
  'MspPlanView.decisionCriteriaPlaceholder': 'Enter Decision Criteria',
  'MspPlanView.businessObjectivesError':
    'Business Objectives should not be more than 280 characters',
  'MspPlanView.searchOpportunityPlaceHolder': 'Search Opportunity',
  'MspPlanView.searchMspPlaceHolder': 'Search mutual success plan',
  'MspPlanView.economicImpactAPIError':
    "Business Objectives didn't save. Please try again",
  'MspPlanView.ownerArchivedMessage':
    'This is an Archived MSP and cannot be edited. Restore it to make any changes.',
  'MspPlanView.archivedMessage':
    'This is an Archived MSP and cannot be edited. MSPs become editable once Owners restore them.',
  'Button.backToHome': 'Back to home',
  'Button.refreshPage': 'Refresh page',
  'Button.Msp': '+ Create New',
  'ForbiddenPage.title': 'Forbidden',
  'ForbiddenPage.message':
    'You don’t have permission to access on this server.',
  'PageNotFound.title': '404 - Page Not Found',
  'PageNotFound.message':
    'The page you’re looking for is currently under maintenance and will be back soon.',
  'ErrorPage.title': "We'll be back soon",
  'ErrorPage.message': 'Oops Something went wrong.',
  'ErrorPage.prefixDescription':
    'The Server encountered an internal error or misconfiguration and was',
  'ErrorPage.suffixDescription': 'unable to complete your request.',
  'MspNotFound.message':
    'Looks like there is no Mutual Success Plan on this opportunity',
  'Placeholder.notifyMessage': 'Add a personal note',
  'MspTeamView.addStakeholderToRoleError':
    'The user you are trying to add already exists in the current role.',
  'MspTeamView.assignStakeholderToPlaceholderError':
    'The user you are trying to assign already exists in the current role.',
  'MspTeamView.addPlaceholderToRoleError':
    'A placeholder already exists in the current role.',
  'MspPlanView.duplicateRoleError':
    'The role already exists in the organisation.',
  'MspPlanView.emptyRoleError': 'Role cannot be empty.',
  'Meddic.tabHeader': 'MEDDIC',
  'Meddpicc.tabHeader': 'MEDDPICC',
  'Meddic.tabCaption': 'Internal view only',
  'ErrorCode.ALREADY_EXIST': 'User already exists',
  'mspStatus.offTrack': 'Off Track',
  'mspStatus.unknown': '--',
  'mspStatus.onTrack': 'On Track',
  'ShareMsp.copyLink': 'Copy URL',
  'MspShare.inviteUser': 'User invited successfully',
  'MspShare.inviteMultipleUser': 'All Users invited successfully',
  'MspShare.inviteUserError':
    "We couldn't invite this User now. Please try again after some time.",
  'MspShare.inviteMultipleUserError':
    "We couldn't invite Users to this MSP. Retry inviting after some time.",
  'User.signOut': 'Sign Out',
  'User.settings': 'Settings',
  'MspPlan.taskPlaceholder': 'Enter Task Name',
  'MspShare.userProfile': 'User Profile',
  'MspPlan.milestoneTitle': 'Milestones & Tasks',
  'Milestone.copyLink': 'Copy Milestone link',
  'Task.copyLink': 'Copy Task link',
  'MspShare.createNewUser': 'Add',

  // Salesforce
  'Button.connectSalesforce': 'Connect to Salesforce',
  'Button.disconnect': 'Disconnect Salesforce',
  'Salesforce.title': 'Salesforce',
  'Salesforce.connectMessage': "You're connected",
  'Salesforce.disconnectMessage': "You're disconnected",

  // Slack
  'Button.connectSlack': 'Connect to Slack',
  'Button.disconnectSlack': 'Disconnect Slack',
  'Slack.title': 'Slack',
  'Slack.connectMessage': "You're connected",
  'Slack.disconnectMessage': "You're disconnected",
  'CreateMilestone.markInternal': 'Mark as Internal',
  'MspPlanView.roleNameError':
    'Role Name should not be more than 100 characters',
  'WarningTitle.hideRole': 'Do you want to hide this Role?',
  'WarningTitle.showRole': 'Are you sure you want to show role?',
  'MspStakeholder.hideRole': 'Hide Role',
  'MspStakeholder.unHideRole': 'Show Role',
  'MspStakeholder.assignUser': 'Assign User',
  'MspStakeholder.removeFromList': 'Remove from team',
  'MspStakeholder.removeAssignee': 'Remove Assignee',
  'MspStakeholder.addNewStakeholderError':
    'Could not add stakeholder. Please try again',
  'MspTeamView.updateRoleError': 'Role could not be updated, please try again.',
  'WarningTitle.stakeholder': 'Are you sure you want to delete?',
  'WarningTitle.placeholder': 'Remove Placeholder from Role?',
  'WarningTitle.user': 'Remove User from Role?',
  'MspTeamView.createRoleError': 'Role could not be created, please try again.',
  'MspTeamView.deleteStakeholderError':
    'Stakeholder could not be deleted, please try again.',
  'MspTeamView.deletePlaceholderError':
    'Placeholder could not be deleted, please try again.',
  'MspTeamView.assignStakeholdersError':
    'User could not be assigned, please try again.',
  'MspTeamView.assignPlaceholderError':
    'Placeholder could not be created, please try again.',
  'MspTeamView.replacePlaceholderError':
    'Stakeholder could not be assigned, please try again.',
  'MspPlanView.isTimelineEnable': 'Enable in External View',
  'MspPlanView.mutateTimelineError': "Timeline didn't save. Please try again",
  'MspPlanView.TimelineNotes':
    'Note: Updates to any date or duration will change the subsequent dates and duration.',
  'WarningTitle.removeUserPermission':
    "Do you want to remove this User's access to the MSP?",
  'ShareMsp.AddUserMessage': 'User not found here. Click here to add new',
  'ShareMsp.NoOptions': 'No options',
  'Notification.markAllRead':
    'Mark all as read could not be saved, please try again',
  'Notification.markRead':
    'Mark read notification could not be saved, please try again',
  'MspPlanAnalytics.ActivityTimelineHeader': 'Engagement Highlights',

  // For Analytics Tab
  'Analytics.TimeLineTrackerHeader': 'MSP Timeline',
  'Analytics.EngagementTrackerHeader': 'MSP Activity Tracker',
  'Analytics.AssetsLeaderboardHeader': 'MSP Resources Leaderboard',
  'Analytics.UserLeaderboardHeader': 'External Users Engagement Leaderboard',
  'Analytics.HighlightsHeader': 'Highlights',
  'Analytics.ActivityTimelineHeader': 'Activity Timeline',
  'Analytics.UserActivityHeader': 'User Activity',
  'Editor.descriptionPlaceholder': 'Type your description here',
  'Analytics.externalUserVisit': 'External User Visits',

  // Influencer Map
  'InfluencerMap.FormUpdateSuccess':
    'Updated Buyer Contact Profile successfully',
  'InfluencerMap.FormUpdateFailure': 'Fields are not updated, please try again',
  'Influencer.createGraph': 'Something went wrong while saving your changes.',
  'Influencer.modifyNote': 'Something went wrong while saving notes.',
  'Influencer.UserCardDetailFailure':
    'Something went wrong while getting user details.',
  'Influencer.userExists': 'User already exists.',
  'Influencer.noChangesDetected': 'No changes were made to the Profile.',
  'WarningTitle.deleteComment': 'Delete Notes?',
  'WarningMessage.deleteCommentMessage':
    'This is permanent. You cannot recover the notes.',
  'WarningTitle.deleteCard': 'Remove Contact Card',
  'WarningMessage.deleteCardMessage':
    'Do you want to remove the contact card from the canvas?',
  'Influencer.edgeExists': 'The contacts are already connected',
  'Influencer.CreateUser.noEmail': 'Enter email to create user',
  'Influencer.CreateUser.invalidEmail': 'Please enter valid email',
  'InfluencerMap.cardUpdateSuccess': '{fieldName} updated',
  'InfluencerMap.cardUpdateFailure':
    '{fieldName} is not updated, please try again',

  // Dashboard
  'Dashboard.blankMsgMspTitle': 'No matching MSPs found',
  'Dashboard.blankMsgMspDescription':
    'Modify the search criteria. We will look through the MSPs again.',
  'MyTask.blankStateMsg': 'You don’t have any new tasks now.',
  'MyTask.noDataTitle': 'No matching Tasks found',
  'MyTask.noDataDescription':
    'Modify the search criteria. We will look through the Tasks again.',
};
export const toolTip = {
  // Msp view page
  'Button.publish': 'Click to Publish',
  'Button.share': 'Click to Share',
  'MspPlanView.editGoLive': 'Edit Go-live date',
  'MspPlanView.editImplementationPeriod': 'Edit implementation period',
  'MspPlanView.contractDate':
    'Contract signature date (Auto-calculated from Go-Live date)',
  'MspPlanView.editWorkPeriod': 'Edit paperwork period',
  'MspPlanView.vendorChoiceDate':
    'Vendor of Choice declaration date (Auto-calculated from Go-Live date)',
  'MspPlanView.collapseMilestone': 'Collapse all Milestones',
  'MspPlanView.expandMilestone': 'Expand all Milestones',
  'MspPlanView.moreActions': 'More actions',
  'MspPlanView.startDateMilestone': 'Start date of the milestone',
  'MspPlanView.endDateMilestone': 'End date of the milestone',
  'MspPlanView.addAssignee': 'Add assignee',
  'MspPlanView.createNewUser': 'New User',
  'MspPlanView.creatingNewUser': 'Creating new user',
  'MspPlanView.nameOfAssignee': 'Display complete name of the assignee',
  'MspPlanView.comments': 'Add / view comments',
  'MspPlanView.addTask': 'Add task',
  'MspPlanView.executiveSummary':
    'Click here to add the executive summary for your customer',
  'MspPlanView.filterMilestoneByStatus': 'Filter tasks by status',
  'MspPlanView.filterMilestoneByUser': 'Filter tasks by assignee',
  'MspPlanView.taskCompletionStatus': 'Task completion status',
  'MspPlanView.taskDueDate': 'Task Due Date',
  'MspPlanView.viewMilestone': 'View milestone details',
  'MspPlanView.viewTask': 'View task details',
  'MspPlanView.milestoneAfterLaunchDate':
    'End date of this milestone is after the go live date',
  'MspPlanView.taskBeforeMilestoneStartDate':
    'Due date of tasks is before the milestone start date',
  'MspPlanView.taskAfterMilestoneEndDate':
    'Due date of tasks is after the milestone end date',
  'MspPlanView.taskNotInBetweenMilestoneDates':
    'Due date of tasks is not in between the milestone dates',
  'MspPlanView.buyerLogoUploadApiError':
    'Unable to upload buyer logo. Please try again',
  'MspPlanView.markedInternal': 'Not Visible to External Team',
  'MspPlanView.markedInternalTask': 'Internal Task',
  'MspPlanView.reverseTimelineToggleOff':
    'Enable to show this in customer view',
  'MspPlanView.reverseTimelineToggleOn':
    'Disable to hide this in customer view',
  'MspPlanView.markDueTaskCompleted': 'Mark all completed',
  'MspPlanView.reverseTimeLineMessage':
    'The teams have not yet agreed on the timeline. Please contact your Account Executive to activate the Reverse Timeline.',
  'MspPlanView.removeOpportunity':
    'Please contact BA support if you want to remove this opportunity from the MSP.',
  'MspPlanView.restoreMsp': 'Do you want to Restore this MSP?',
  'MspPlanView.restoreMspMessage':
    'Existing Internal users can always access restored MSPs. External Users must be allowed access.',
  'MspPlanView.archiveMsp': 'How do you want to archive this MSP?',
  'MspPlanView.archiveMspMessage':
    'Archived MSPs <b>are uneditable</b>. While existing Internal users can view these MSPs, External users cannot.',
  // Dashboard
  'Dashboard.Create NewMsp': 'Create a new Msp',
  'Dashboard.filterBasedOnforecast':
    'Filter list based on Salesforce forecast category',
  'Dashboard.filterBasedOnCloseDate':
    'Filter list based on Salesforce close date',
  'Dashboard.filterBasedOnOpportunityStage':
    'Filter list based on Salesforce opportunity stage',
  // Craete msp flow
  'CreateMsp.searchOpportunityPlaceHolder':
    'Search opportunities from Salesforce',
  'CreateMsp.mspWithoutSalesForce':
    'Create Mutual Success Plan without connecting to Salesforce',
  'CreateMsp.successPlanName': 'Mutual Success Plan name is customer-facing',
  'CreateMsp.successPlanSummary': `Executive summary is a customized overview of the proposed partnership. This is customer-facing
    and can be updated later as well`,
  'CreateMsp.mspStartDate': `Mutual Success Plan Starting Date is usually the current date or the date on which the
    mutual success plan starts. All other dates in the mutual success plan get mapped automatically from this date`,
  'Stakeholder.headerRole': 'Roles',
  'Stakeholder.headerStakeholder': 'Stakeholders',
  'MspPlanView.mspShareGenerateButton':
    'Only the Mutual Success Plan owner can create a public link, kindly contact your relationship owner to have this enabled',
  'MspPlanView.maxTimelineAddedLimit':
    'You cannot add more than 5 steps in the timeline. ',
  'MspPlanView.addUser': 'Add New User',
  'MspPlanViewTask.taskBeforeMilestoneStartDate':
    'Task Due Date precedes the Milestone Start date',
  'MspPlanViewTask.taskAfterMilestoneEndDate':
    'Task Due Date exceeds the Milestone End date',
  'MspPlanViewTask.taskDue': 'Overdue',
  'MspPlanViewTask.closeToDueDate': 'Close to due date',
  'MspPlanView.milestoneTaskDue': 'Overdue',
  'Notification.MarkRead': 'Mark as read',
  'MspPlanView.assignUser': 'Assign User',
  'Salesforce.opportunity': 'Connect to Opportunity',
  'MoreAction.taskDeleteActionMessage': 'Delete Task',
  'MoreAction.editComment': 'Edit Comment',
  'MoreAction.deleteComment': 'Delete Comment',
};

export const viewOnlyToolTip = {
  // Msp view page
  'Button.share': 'Share',
  'MspPlanView.editGoLive': 'Go-live date',
  'MspPlanView.editImplementationPeriod': 'Implementation period',
  'MspPlanView.contractDate':
    'Contract signature date (Auto-calculated from Go-Live date)',
  'MspPlanView.editWorkPeriod': 'Paperwork period',
  'MspPlanView.vendorChoiceDate':
    'Vendor of Choice declaration date (Auto-calculated from Go-Live date)',
  'MspPlanView.collapseMilestone': 'Collapse all milestones',
  'MspPlanView.expandMilestone': 'Expand all milestones',
  'MspPlanView.moreActions':
    'You need ‘Can Edit’ permission to access this. Reach out to the MSP Owner.',
  'MspPlanView.startDateMilestone': 'Start date of the milestone',
  'MspPlanView.endDateMilestone': 'End date of the milestone',
  'MspPlanView.addAssignee': 'No user added',
  'MspPlanView.comments': 'Comments',
  'MspPlanView.filterMilestoneByStatus': 'Filter tasks by status',
  'MspPlanView.filterMilestoneByUser': 'Filter tasks by assignee',
  'MspPlanView.taskCompletionStatus': 'Task completion status',
  'MspPlanView.taskDueDate': 'Task Due Date',
  'MspPlanView.viewMilestone': 'View milestone details',
  'MspPlanView.viewTask': 'View task details',
  'MspPlanView.milestoneAfterLaunchDate':
    'End date of this milestone is after the go live date',
  'MspPlanView.taskBeforeMilestoneStartDate':
    'Due date of tasks is before the milestone start date',
  'MspPlanView.taskAfterMilestoneEndDate':
    'Due date of tasks is after the milestone end date',
  'MspPlanView.taskNotInBetweenMilestoneDates':
    'Due date of tasks is not in between the milestone dates',
  'MspPlanView.mspExecutiveSummaryAttachmentError':
    'Error while attaching media to exective summary',
};

export const headerText = {
  'Overview.objective': 'Business Objectives',
  'Overview.summary': 'Executive Summary',
  'Overview.forwardTimeline': 'Forward Timeline',
  'Overview.reverseTimeline': 'Reverse Timeline',
};

export const headerTextVVS = {
  'Overview.objective': 'Value Statement',
  'Overview.summary': 'Value Summary',
  'Overview.forwardTimeline': 'Forward Milestone',
  'Overview.reverseTimeline': 'Reverse Milestone',
};
