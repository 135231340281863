import { useApolloClient } from '@apollo/client';
import { Badge } from '@mui/material';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import 'react-base-table/styles.css';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { BaCard, BaPopover } from '../../../ba-components';
import { RootState } from '../../../common/redux-store';
import { NotificationStatus } from '../../../generated-types';
import {
  NavItem,
  NavTitleCard,
} from '../../../common/side-navigation/nav-link';
import { fetchNotification } from './api-call';
import { useMarkedReadNotification } from './api-hooks';
import Bell from './Bell.svg';
import NotificationModal from './notification-modal';
import {
  resetNotificationFilterDetails,
  setNotificationCount,
} from './notification-redux-store';

const mapState = (state: RootState) => ({
  newCount: state?.notification.newCount,
  userId: state?.userInfo.userDetails.userId ?? '',
  isSidebarOpen: state.appState.isSidebarOpen,
});

const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setNotificationCount,
      resetNotificationFilterDetails,
      fetchNotification,
      trackEvent,
    },
    dispatch,
  );

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function NotificationContainer(props: PropsFromRedux) {
  const {
    newCount,
    userId,
    setNotificationCount,
    resetNotificationFilterDetails,
    fetchNotification,
    trackEvent,
    isSidebarOpen,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { markedReadNotification } = useMarkedReadNotification();
  const client = useApolloClient();

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    trackEvent(TRACKING_CONSTANTS.CLICKED_NOTIFICATION);
    if (newCount) {
      markedReadNotification(userId, NotificationStatus.ACKNOWLEDGED);
      setNotificationCount(0);
    }
  };

  const handleClose = useCallback(() => {
    resetNotificationFilterDetails();
    fetchNotification({ client, userId });
  }, [client, fetchNotification, resetNotificationFilterDetails, userId]);

  return (
    <div title="Notifications">
      <BaPopover.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
        {process.env.NOTIFICATION && userId && (
          <BaPopover.Trigger asChild>
            <div
              className="flex cursor-pointer items-center hover:bg-white-opacity-10"
              role="presentation"
              onClick={handleClick}
            >
              <BaCard.Root openDelay={0} closeDelay={0}>
                <BaCard.Trigger asChild>
                  <div>
                    <NavItem
                      title="Notifications"
                      iconComponent={
                        <Badge
                          badgeContent={newCount}
                          classes={{
                            badge:
                              'p-0 h-5 z-0 w-5 text-8 text-white bg-error border-solid border-white',
                          }}
                        >
                          <img
                            src={Bell as string}
                            alt="notifications"
                            className={clsx(
                              newCount
                                ? 'animate-tilt border-primary fill-primary text-primary'
                                : 'text-mid-400',
                              '',
                            )}
                          />
                        </Badge>
                      }
                    />
                  </div>
                </BaCard.Trigger>
                {!isSidebarOpen && <NavTitleCard title="Notifications" />}
              </BaCard.Root>
            </div>
          </BaPopover.Trigger>
        )}
        <BaPopover.Content
          onCloseAutoFocus={handleClose}
          side="right"
          paddingclass="p-0"
          sideOffset={-2}
        >
          <BaPopover.Arrow />
          <NotificationModal />
        </BaPopover.Content>
      </BaPopover.Root>
    </div>
  );
}

export default connector(NotificationContainer);
