/* eslint-disable no-use-before-define */
import { Dayjs } from 'dayjs';
import { IShowMapping } from '../../components/filters/model';
import {
  AssigneeType,
  BooleanResponse,
  CompanyResponse,
  CompanyType,
  ExecutiveSummary,
  Maybe,
  MediaConnection,
  Milestone,
  MilestoneConnectionResponse,
  MilestoneDetails,
  Msp,
  MspAnalyticsResponse,
  MspInvitePermission,
  MspKeyDatesResponse,
  MspPayloadResponse,
  MspUserBridgeResponse,
  Opportunity,
  OpportunityResponse,
  Placeholder,
  StakeholderAnalyticsResponse,
  SubscriberConnectionResponse,
  TaskStatus,
  TimelineOrder,
  User,
  UserResponse,
  UserType,
} from '../../generated-types';
import { EPermissions } from './shareable-msp/models';
import { TimelineData } from './timeline/custom-timeline';

export type ResultMsp = {
  mspQuery: { getMsp: MspPayloadResponse };
};

export type GetMspInput = {
  id: string;
};

export type KeyDateData = {
  vendorChoiceNumber: number;
  contactSignatureNumber: number;
  goLiveDate: Dayjs;
};

export type UpdateMSPResult = {
  mspMutation: {
    updateMsp: MspPayloadResponse;
  };
};

export type PublishMspResult = {
  mspMutation: { publishMsp: BooleanResponse };
};
export type LinkOpportunityMspResult = {
  mspMutation: { linkOpportunity: BooleanResponse };
};
export type DeleteMspResult = {
  mspMutation: { deleteMsp: BooleanResponse };
};

export enum ESortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum MoreAction {
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  MARKINTERNAL = 'MARKINTERNAL',
  REINSTATE = 'REINSTATE',
  COPY_LINK = 'COPY_LINK',
}
export enum ComponentType {
  MILESTONE = 'MILESTONE',
  TASK = 'TASK',
}
export enum AssigneeSearchTabType {
  STAKEHOLDER = 'STAKEHOLDER',
  PLACEHOLDER = 'PLACEHOLDER',
}

export enum EMspSaveIndicator {
  'MSP_NAME' = 'MSP NAME',
  'MSP_ARCHIVE' = 'MSP ARCHIVE',
  'MSP_EXECUTIVE_SUMMARY' = 'MSP EXECUTIVE SUMMARY',
  'MSP_KEY_DATES' = 'MSP KEY DATES',
  'MSP_ISPUBLISHED' = 'MSP ISPUBLISHED',
  'MILESTONE_NAME' = 'MILESTONE_NAME',
  'TASK_NAME' = 'TASK_NAME',
  'MSP_SAVE_MILESTONE' = 'MSP SAVE MILESTONE',
  'TASK_STATUS' = 'TASK_STATUS',
  'TASK_REORDER' = 'TASK_REORDER',
  'TASK_DELETE' = 'TASK_DELETE',
  'TASK_INTERNAL' = 'TASK_INTERNAL',
  'TASK_CLONE' = 'TASK_CLONE',
  'MILESTONE_DATE' = 'MILESTONE_DATE',
  'TASK_ASSIGNEE' = 'TASK_ASSIGNEE',
  'MILESTONE_DETAILS' = 'MILESTONE_DETAILS',
  'MILESTONE_DELETE' = 'MILESTONE_DELETE',
  'MARK_MILESTONE_INTERNAL' = 'MARK_MILESTONE_INTERNAL',
  'MILESTONE_CLONE' = 'MILESTONE_CLONE',
  'REMOVE_ASSIGNEE' = 'REMOVE_ASSIGNEE',
  'TASK_COMMENT_NEW' = 'TASK_COMMENT_NEW',
  'TASK_COMMENT_EDIT' = 'TASK_COMMENT_EDIT',
  'TASK_COMMENT_DELETE' = 'TASK_COMMENT_DELETE',
  'DELETE_MEDIA' = 'DELETE_MEDIA',
  'USP' = 'USP',
  'MSP_ECONOMIC_IMPACT' = 'MSP_ECONOMIC_IMPACT',
  'MSP_REVERSE_TIMELINE' = 'MSP_REVERSE_TIMELINE',
  'MEDDIC' = 'MEDDIC',
  'MSP_OPPORTUNITY_UPDATE' = 'MSP_OPPORTUNITY_UPDATE',
  'OPPORTUNITY_MSP_UPDATE' = 'OPPORTUNITY_MSP_UPDATE',
  'TASK_DATE' = 'TASK_DATE',
  'STAKEHOLDER_CREATE_ROLE' = 'STAKEHOLDER_CREATE_ROLE',
  'STAKEHOLDER_UPDATE_ROLE' = 'STAKEHOLDER_UPDATE_ROLE',
  'STAKEHOLDER_ADDED' = 'STAKEHOLDER_ADDED',
  'PLACEHOLDER_ADDED' = 'PLACEHOLDER_ADDED',
  'REMOVE_STAKEHOLDER' = 'REMOVE_STAKEHOLDER',
  'REMOVE_PLACEHOLDER' = 'REMOVE_PLACEHOLDER',
  'REPLACE_PLACEHOLDER_WITH_STAKEHOLDER' = 'REPLACE_PLACEHOLDER_WITH_STAKEHOLDER',
  'ASSET_MARK_INTERNAL' = 'ASSET_MARK_INTERNAL',
  'UPLOADING_RESOURCE' = 'UPLOADING_RESOURCE',
  'DELETE_RESOURCE' = 'DELETE_RESOURCE',
  'FAVORITE_RESOURCE' = 'FAVORITE_RESOURCE',
  'UNFAVORITE_RESOURCE' = 'UNFAVORITE_RESOURCE',
  'PIN_RESOURCE' = 'PIN_RESOURCE',
  'UNPIN_RESOURCE' = 'UNPIN_RESOURCE',
  'RENAME_FILE' = 'RENAME_FILE',
  'REVOKE_PUBLIC_ACCESS' = 'REVOKE_PUBLIC_ACCESS',
  'MARK_AS_READ' = 'MARK_AS_READ',
  'CREATE_INFLUENCER_GRAPH' = 'CREATE_INFLUENCER_GRAPH',
  'MODIFY_NOTE' = 'MODIFY_NOTE',
}

export type TypographyVariantsType =
  | 'button'
  | 'overline'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2';

export interface IAssigneeModal {
  isOpen: boolean;
  mspIndex: number;
  taskIndex: number;
  taskId: string;
  isTaskInternal: boolean;
}

export interface IShareModel {
  selectedCollaborator: User[];
  selectedPermission: MspInvitePermission;
  uspData: IUspData;
  isShareLoading: boolean;
}

export interface IUspData {
  public?: IUspObject;
  private?: IUspObject;
}

export interface IUspObject {
  id: string;
  password: string;
  link: string;
}

export interface IMspStore {
  milestones: MilestoneConnectionResponse;
  accordionOpenState: {
    expandedAll: boolean;
    collapsedAll: boolean;
  };
  assigneeModal: IAssigneeModal;
  showMapping: IShowMapping;
  isFilterApplied: boolean;
  shareModel: IShareModel;
  isTaskLoading: boolean;
  milestonePreviousState: { [key: string]: MilestoneDetails };
  overDueMilestones: IMilestoneOverDueDetail;
  placeholderData: IPlaceholderList;
  shouldMilestoneDataReloaded: boolean;
  milestoneFirstLoad: boolean;
  commentFirstLoad: boolean;
  taskFirstLoad: boolean;
  isMilestoneResourceHighlight: boolean;
  isMilestoneCreateDrawer: boolean;
}

export interface IMilestoneOverDueDetail {
  milestoneOverdueList: string[];
  milestoneOverdueDetails: { [key: string]: IMilestoneDetails };
  taskDetails: { [key: string]: ITaskDetails };
  userDetails: { [key: string]: IUserDetails };
  loading: boolean;
  error: unknown;
}

export interface IPlaceholder extends Placeholder {
  placeholderId: string | undefined;
  userType: CompanyType;
  firstName: string;
  lastName: string;
  email: string;
}
export interface IPlaceholderList {
  placeholderList: Partial<IPlaceholder>[];
  loading: boolean;
  error: unknown;
}

export interface IMilestoneDetails extends Partial<Milestone> {
  taskList: string[];
  isInternal: boolean;
  isMarkedCompleteEnabled: boolean;
}

export interface ITaskDetails {
  userList: string[];
  name: string | null;
  status: TaskStatus | null | undefined;
  dueDate: number | null | undefined;
  id: string;
  isInternal: boolean;
  assigneeType: AssigneeType | null | undefined;
}
export interface IUserDetails {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  id: string | undefined;
  email: string | null | undefined;
  userType: UserType | CompanyType;
}

interface IUspPermission {
  hasFullPermission: boolean;
  permissions: EPermissions;
  isPasswordRequired: boolean;
  isEmailRequired: boolean;
  isEmailOptional: boolean;
  isUserInfoModalOpen: boolean;
  loadingAccess: boolean;
  loadingUSPData: boolean;
  loadingUSPPermission: boolean;
  hasAccessError: string;
  hasUSPDataError: string;
  hasUSPPermissionError: string;
}
export interface IMspReduxStore {
  mspDetail: IMsp;
  isMspLoading: boolean;
  opportunityList: Opportunity[];
  mspList: Msp[];
  isCustomerViewEnabled: boolean;
  uspPermission: IUspPermission;
  isViewModeEnabled: boolean;
  mspPermission: MspInvitePermission;
  insideSalesforceStatus: boolean;
}

export interface Stakeholder {
  roleId: string;
  roleName: string;
  primary: boolean;
  loading?: boolean;
  placeholder?: boolean;
  members: {
    placeholder?: boolean;
    fullName?: string;
    email: string;
    userId: string;
    loading: boolean;
    analytics?: StakeholderAnalyticsResponse;
  }[];
}
interface TimelinePayloadItem {
  items: TimelineData[];
}

export interface IMsp {
  id: string;
  isPublished: boolean;
  isArchived: boolean;
  name: string;
  closeDate: number;
  decisionCriteria: string;
  summary: Maybe<ExecutiveSummary>;
  keyDates?: Maybe<MspKeyDatesResponse>;
  subscribers: SubscriberConnectionResponse;
  userBridge?: Maybe<MspUserBridgeResponse>;
  assets?: Maybe<MediaConnection>;
  buyerCompany: CompanyResponse;
  sellerCompany: CompanyResponse;
  economicImpact: string;
  isReverseTimeLineEnabled: boolean;
  timelineOrder: TimelineOrder;
  createdBy: UserResponse;
  opportunity: OpportunityResponse;
  timelines: TimelinePayloadItem;
  analytics?: Maybe<MspAnalyticsResponse>;
}
export interface IUppyFileResponse {
  source: string;
  s3Multipart: { uploadId: string; key: string };
  meta: { name: string; description: string; type: string };
  data: { size: number };
  id: string;
}

export interface IMilestone extends Milestone {
  resourceCount: number;
}

export type ITabDetail = {
  name: string;
  label: string;
  isEnabled: boolean;
  isDefault: boolean;
  order: number;
  isInternal?: boolean;
  subTabs?: ITabDetail[];
  value?: string;
  title?: string;
};
