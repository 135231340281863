import { gql, useMutation } from '@apollo/client';
import {
  TaskMutationCreateTaskArgs,
  TaskPayloadResponse,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

type CreateTask = {
  variables: TaskMutationCreateTaskArgs;
};
// TODO Add goals mutation
export const CREATE_TASK = gql`
  ${CORE_GRAPH_ERROR}
  mutation CreateTask(
    $input: CreateTaskInput!
    $milestoneId: ID!
    $order: Int
  ) {
    taskMutation {
      createTask(input: $input, milestoneId: $milestoneId, order: $order) {
        __typename
        ...CoreErrorFields
        ... on TaskPayload {
          items {
            ...CoreErrorFields
            ... on Task {
              id
              name
              dueDate
              status
              isInternal
            }
          }
        }
      }
    }
  }
`;

export type CreateTaskResult = {
  taskMutation: { createTask: TaskPayloadResponse };
};

export const CreateTaskMutation = (
  onCompletedCreateTask: (taskData: CreateTaskResult) => void,
): ((createTask: CreateTask) => any
) => {
  const [resultTask] = useMutation(CREATE_TASK, {
    onCompleted(taskData: CreateTaskResult) {
      onCompletedCreateTask(taskData);
    },
  });
  return resultTask;
};
