import { ClickAwayListener, Grow } from '@mui/material';
import Popper from '@mui/material/Popper';
import type { PopperPlacementType } from '@mui/material/Popper';
import React, { useCallback } from 'react';

interface IModalProps {
  anchorEl: HTMLElement | null;
  setAnchorEl:
    | React.Dispatch<React.SetStateAction<HTMLElement | null>>
    | ((anchorEl: HTMLElement | null) => void);
  children: React.ReactNode;
  placement: PopperPlacementType;
  onClose?: () => void;
  borderRadiusClass?: string;
  className?: string;
  zIndex?: number;
  popperOffSet?: [number, number];
  container?: HTMLElement | null;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  overflowClass?: string;
}

export function BaModal(props: IModalProps) {
  const {
    anchorEl,
    setAnchorEl,
    borderRadiusClass = 'rounded',
    placement,
    onClose,
    className,
    zIndex,
    popperOffSet,
    onMouseEnter,
    onMouseLeave,
    container,
    overflowClass,
  } = props;

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (onClose) onClose();
  }, [onClose, setAnchorEl]);

  return (
    <Popper
      className={zIndex ? `z-${zIndex}` : ''}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transition
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      container={container}
      placement={placement}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: popperOffSet || [0, 4],
          },
          enabled: true,
        },
      ]}
    >
      {({ TransitionProps, placement }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div
              className={`border border-solid border-mid-30 bg-white shadow-80 ${borderRadiusClass} ${
                overflowClass || 'overflow-hidden'
              } ${className || ''}`}
            >
              {props.children}
            </div>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
}
