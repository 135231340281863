import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Operation, TaskConnectionResponse } from '../../../generated-types';
import { GET_PLACEHOLDER_LIST } from './graphql';

export type ResultPlaceholderList = {
  memberQuery: {
    listPlaceholders: TaskConnectionResponse;
  };
};
export const fetchPlaceholderList = createAsyncThunk(
  'msp/get-placeholder-lists',
  async (
    {
      client,
      mspId,
    }: {
      client: ApolloClient<object>;
      mspId:string
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultPlaceholderList>({
        query: GET_PLACEHOLDER_LIST,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          operations: {
            filter: {
              operator: 'AND',
              filters: [
                {
                  op: Operation.IN,
                  field: 'placeholder.mspId',
                  values: [mspId],
                },
              ],
            },
          },
        },
      });
      return { data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
