import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import { HTMLAttributes } from 'react';
import { colorPalette } from '../../common/newTheme';
import { UserType } from '../../generated-types';
import { User } from '../icons';

type TVariants = 100 | 200 | 300 | 400 | 600 | 500;
const sizeMap = {
  small: { width: 24, fontSize: 8 },
  medium: { width: 30, fontSize: 10 },
  large: { width: 46, fontSize: 14 },
};

const AvatarBackgroundColor = (
  index: TVariants,
  userType?: UserType,
  fullName?: string,
) => {
  const isPlaceholder = fullName?.startsWith('Placeholder-');

  if (userType === UserType.BUYER && isPlaceholder) {
    return colorPalette.neutral[300];
  }
  if (userType === UserType.SELLER && isPlaceholder) {
    return colorPalette.mid[300];
  }
  if (userType === UserType.BUYER) {
    return colorPalette.green[400];
  }
  if (userType === UserType.SELLER) {
    return colorPalette.primary[300];
  }
  return colorPalette.mid[300];
};

interface AvatarProps {
  size: number;
  fontSize: number;
  userType?: UserType;
  index: TVariants;
  fullName?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: (props: AvatarProps) => props.size,
    height: (props) => props.size,
    background: (props) =>
      AvatarBackgroundColor(props.index, props.userType, props?.fullName),
    fontSize: (props) => props.fontSize,
    fontWeight: 600,
    color: 'white !important',
  },
}));

interface BAAvatarProps extends HTMLAttributes<HTMLElement> {
  /** Full name of the User */
  fullName: string;
  email: string;
  /** Size of the avatar image */
  size?: 'small' | 'medium' | 'large';
  width?: number;
  fontSize?: number;
  /** Whether no user assigned */
  noUser?: boolean;
  className?: string;
  userType?: UserType;
  avatarRef?: HTMLElement | null;
}

export function NewAvatar(props: BAAvatarProps) {
  const {
    fullName = 'Anonymous',
    email,
    userType,
    size = 'medium',
    className,
    avatarRef,
    ...prop
  } = props;
  // const index = (((email || fullName).length % 6) || 1) * 100 as TVariants;
  const classes = useStyles({
    size: sizeMap[size].width,
    fontSize: sizeMap[size].fontSize,
    index: 300,
    userType,
    fullName,
  });

  const initials = fullName
    ?.split(' ')
    ?.map((word: string) => word[0])
    ?.slice(0, 2);

  // TODO: uppercase css is leaking need to fix it form mui
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Avatar
      ref={avatarRef}
      classes={{
        root: `uppercase ${className ?? ''}`,
      }}
      className={classes.root}
      {...prop}
    >
      {initials}
    </Avatar>
  );
}

export function CommonAvatar(props: BAAvatarProps) {
  const {
    fullName,
    email,
    userType,
    width,
    size = 'medium',
    className,
    fontSize,
    ...prop
  } = props;
  const index = ((((email || fullName)?.length % 3) + 3 || 3) *
    100) as TVariants;
  const classes = useStyles({
    size: width || sizeMap[size].width,
    fontSize: fontSize || sizeMap[size].fontSize,
    index,
    userType,
    fullName,
  });

  const initials = fullName
    ?.split(' ')
    .map((word: string) => word[0])
    .slice(0, 2);

  const isNotPlaceholder = !fullName?.startsWith('Placeholder-');

  return (
    <Avatar
      classes={{
        root: `uppercase ${className ?? ''}`,
      }}
      className={classes.root}
      {...prop}
    >
      {email || isNotPlaceholder ? (
        initials
      ) : (
        <User
          size={(fontSize || sizeMap[size].fontSize) ?? 16}
          color="#FFFFFF"
        />
      )}
    </Avatar>
  );
}
