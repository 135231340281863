import { gql } from '@apollo/client';
import { BooleanResponse } from '@buyerassist/graphql-generated-types';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_NOTIFICATION_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query (
    $input: GetPushNotificationRequestBody!
    $pageConfiguration: PageConfiguration
  ) {
    pushNotificationQuery {
      getPushNotifications(
        input: $input
        pageConfiguration: $pageConfiguration
      ) {
        ...CoreErrorFields
        ... on PushNotificationConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
            __typename
          }
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on PushNotification {
                __typename
                notificationType
                userNotificationStatusId
                message
                link
                createdAt
                status
                authorizer {
                  ... on PushNotificationAuthorizerDetails {
                    name
                    type
                  }
                }
                metaData {
                  ... on PushNotificationMetaData {
                    additionalMessage
                    references {
                      ... on PushNotificationMetaDataReferences {
                        id
                        type
                      }
                    }
                  }
                }
                account {
                  ... on PushNotificationAccount {
                    id
                    name
                  }
                }
              }
            }
          }
          totalCount
          newCount
        }
      }
    }
  }
`;

export type ResultMarkedAllReadNotification = {
  pushNotificationMutation: {
    bulkUpdatePushNotificationStatus: BooleanResponse;
  };
};

export type ResultMarkedReadNotification = {
  pushNotificationMutation: { updatePushNotificationStatus: BooleanResponse };
};

export const MARKED_ALL_READ = gql`
  ${CORE_GRAPH_ERROR}
  mutation ($input: BulkUpdateNotificationStatusRequestBody!) {
    pushNotificationMutation {
      bulkUpdatePushNotificationStatus(input: $input) {
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const MARKED_READ_NOTIFICATION = gql`
  ${CORE_GRAPH_ERROR}
  mutation ($input: UpdateNotificationStatusRequestBody!) {
    pushNotificationMutation {
      updatePushNotificationStatus(input: $input) {
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const POLL_NOTIFICATION_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query ($userId: ID!) {
    pushNotificationQuery {
      pollNotifications(userId: $userId) {
        ...CoreErrorFields
        ... on PushNotificationConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
            __typename
          }
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on PushNotification {
                __typename
                notificationType
                userNotificationStatusId
                message
                link
                createdAt
                status
                authorizer {
                  ... on PushNotificationAuthorizerDetails {
                    name
                    type
                  }
                }
                metaData {
                  ... on PushNotificationMetaData {
                    additionalMessage
                    references {
                      ... on PushNotificationMetaDataReferences {
                        id
                        type
                      }
                    }
                  }
                }
                account {
                  ... on PushNotificationAccount {
                    id
                    name
                  }
                }
              }
            }
          }
          totalCount
          newCount
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_ACCOUNTS = gql`
  ${CORE_GRAPH_ERROR}
  query ($userId: ID!) {
    pushNotificationQuery {
      getNotificationAccounts(userId: $userId) {
        ...CoreErrorFields
        ... on PushNotificationAccountDetailsPayload {
          items {
            ...CoreErrorFields
            ... on PushNotificationAccountDetails {
              accountId
              accountName
              unreadCount
            }
          }
        }
      }
    }
  }
`;
