/* eslint-disable */
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import type { Uppy, UppyFile } from '@uppy/core';
import UppyCore from '@uppy/core';
import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import {
  addFileToUploads,
  removeFileFromUploads,
  setMediaUploadingStatus,
  updateFileProgressInUploads,
} from '../alignx/msp-view/uploading-redux-store';
import { trackEvent } from '../analytics';
import { TRACKING_CONSTANTS } from '../analytics/constants/trackingConstants';
import { RootState } from '../common/redux-store';
import { getMspId } from '../common/methods/get-msp-id';
import { useKeycloakContext } from '../common/auth/authentication-context';
import { showToast } from '../components/hooks/use-toastify';
import { getCompanionUrl, orgIdExtractor } from '../utils';

const orgId = orgIdExtractor();
const mspId = getMspId(window.location.pathname);
const uspToken = sessionStorage.getItem(mspId);

export const UppyAggregatorContext = createContext<{
  uppy: Uppy;
  uppyFiles: UppyFile[];
}>({
  uppy: new UppyCore(),
  uppyFiles: [],
});

const UppyAggregatorProvider = UppyAggregatorContext.Provider;

const mapStateToProps = (state: RootState) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFileToUploads,
      removeFileFromUploads,
      setMediaUploadingStatus,
      updateFileProgressInUploads,
      trackEvent,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export function UppyAggregator(
  props: { children: ReactNode } & PropsFromRedux,
) {
  const {
    children,
    token,
    addFileToUploads,
    removeFileFromUploads,
    setMediaUploadingStatus,
    updateFileProgressInUploads,
    trackEvent,
  } = props;

  const [uploadFiles, setUploadFiles] = useState<UppyFile[]>([]);
  const { keycloak } = useKeycloakContext();

  const uppy = useRef<Uppy>();

  uppy.current = useMemo(() => {
    let instance = uppy.current;
    if (instance === undefined) {
      instance = new UppyCore({
        autoProceed: true,
        debug: process.env.STAGE !== 'prod',
        allowMultipleUploadBatches: true,
      });

      instance.use(AwsS3Multipart, {
        companionUrl: getCompanionUrl(),
        companionHeaders: {
          Authorization: `Bearer ${token || keycloak?.token}`,
          SellerOrgId: orgId,
          UspToken: uspToken!,
        },
      });

      instance
        .on('file-added', (file: UppyFile) => {
          try {
            const setResourceFileMeta =
              instance?.getState()?.setResourceFileMeta;
            if (typeof setResourceFileMeta === 'function') {
              setResourceFileMeta(file);
            }

            const setOverviewFileMeta =
              instance?.getState()?.setOverviewFileMeta;
            if (typeof setOverviewFileMeta === 'function') {
              setOverviewFileMeta(file);
            }

            const onFileSelect = instance?.getState()?.onFileSelect;
            if (typeof onFileSelect === 'function') onFileSelect(file);

            // const setMilestoneFileMeta = instance?.getState()?.setMilestoneFileMeta;
            // if (typeof setMilestoneFileMeta === 'function') setMilestoneFileMeta(file);

            const setAssetFileMeta = instance?.getState()?.setAssetFileMeta;
            if (typeof setAssetFileMeta === 'function') setAssetFileMeta(file);

            const setCommentFileMeta = instance?.getState()?.setCommentFileMeta;
            if (typeof setCommentFileMeta === 'function') {
              setCommentFileMeta(file);
            }

            instance?.setFileState(file.id, { isLoading: true });
            setUploadFiles(instance?.getFiles() ?? []);
          } catch (err) {
            console.log(err);
          }
        })
        .on('files-added', (files: UppyFile[]) => {
          setUploadFiles(files);
          window.onbeforeunload = () => 'You may loose content';
        })
        .on('file-removed', (file, reason) => {
          setUploadFiles(instance?.getFiles() ?? []);
          removeFileFromUploads({ id: file.id });
          trackEvent(TRACKING_CONSTANTS.CANCEL_UPLOAD);
        })
        .on('upload', ({ id, fileIDs }) => {
          setMediaUploadingStatus({ uploading: true });
          addFileToUploads({ id, fileIDs });
        })
        .on('progress', () => {})
        .on('upload-progress', (file, progress) => {
          instance?.setFileState(file.id, { isUploading: true });

          const { id: fileId, name: fileName, source } = file;
          const { bytesUploaded, bytesTotal } = progress;
          const uploadFraction = bytesUploaded / bytesTotal;

          updateFileProgressInUploads({
            file: { fileId, fileName, source },
            progress: uploadFraction.toFixed(4) * 100,
          });
          setUploadFiles(instance?.getFiles() ?? []);
        })
        .on('upload-success', (file: UppyFile, response) => {
          instance?.setFileState(file.id, {
            isLoading: false,
            isUploading: false,
          });
          setUploadFiles(instance?.getFiles() ?? []);
        })
        .on('complete', (result) => {
          // const onUploadComplete = instance?.getState()?.onUploadComplete;
          // if (typeof onUploadComplete === 'function') onUploadComplete(result.successful);

          result.successful.forEach((file) => {
            if (file.deleteAfterUpload !== false) {
              instance?.removeFile(file.id);
            }
            if (file.attachOperation) {
              file.attachOperation([file]);
            }
            if (file.afterUppyUpload) {
              file.afterUppyUpload(file);
            }
            if (file.attachMediaToComment) {
              file.attachMediaToComment([file]);
            }
          });
          setUploadFiles(instance?.getFiles() ?? []);
          window.onbeforeunload = null;
        })
        .on('error', (err) => {})
        .on('upload-retry', () => {})
        .on('upload-error', (file, err, response) => {
          // keycloak
          //   .updateToken(30)
          //   .then(() => console.log('token updated'))
          //   .catch((err) => console.error(err));

          const onUploadError = instance?.getState()?.onUploadError;
          if (typeof onUploadError === 'function') {
            onUploadError({ ...file, error: err });
          }

          instance?.setFileState(file.id, { error: true });
          // instance?.removeFile(file.id);
          setUploadFiles(instance?.getFiles() ?? []);
          trackEvent(TRACKING_CONSTANTS.UPLOAD_FAILED);
        })
        .on('restriction-failed', (file, error) => {
          showToast(`Upload Failed. ${error}`, {
            variant: 'error',
          });
          instance?.removeFile(file.id);
          trackEvent(TRACKING_CONSTANTS.UPLOAD_FAILED, {
            reason: `File Type not supported. Error: ${error}`,
          });
        });
    }
    return instance;
  }, []);

  useEffect(() => {
    try {
      if (uppy.current instanceof UppyCore) {
        const awsPlugin = uppy.current.getPlugin('AwsS3Multipart');
        awsPlugin.opts.companionHeaders.Authorization = `Bearer ${
          token || keycloak?.token
        }`;
      }
    } catch (error) {
      console.log(error);
    }
  }, [keycloak?.token, token]);

  useEffect(
    () => () => {
      if (uppy.current instanceof UppyCore) {
        uppy.current.close();
      }
    },
    [],
  );

  return (
    <UppyAggregatorProvider
      value={{ uppy: uppy.current, uppyFiles: uploadFiles }}
    >
      {children}
    </UppyAggregatorProvider>
  );
}

export const ConnectedUppyAggregator = connector(UppyAggregator);
