import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: string;
  JSON: { [key: string]: any };
  Timestamp: number;
  URL: string;
};

export type AccountPlan = {
  __typename?: 'AccountPlan';
  id: Scalars['ID'];
  extCompanyId: Scalars['ID'];
  companyId: Scalars['ID'];
  sellerCompany?: Maybe<CompanyResponse>;
  buyerCompany?: Maybe<CompanyResponse>;
  syncData?: Maybe<AccountPlanSyncDataResponse>;
  orgId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type AccountPlanConnection = {
  __typename?: 'AccountPlanConnection';
  edges?: Maybe<Array<Maybe<AccountPlanEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AccountPlanConnectionResponse =
  | AccountPlanConnection
  | GraphqlError;

export type AccountPlanEdge = {
  __typename?: 'AccountPlanEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AccountPlanResponse>;
};

export type AccountPlanMutation = {
  __typename?: 'AccountPlanMutation';
  createAccountPlan: AccountPlanPayloadResponse;
};

export type AccountPlanMutationCreateAccountPlanArgs = {
  input: CreateAccountPlanInput;
};

export type AccountPlanPayload = {
  __typename?: 'AccountPlanPayload';
  items?: Maybe<Array<Maybe<AccountPlanResponse>>>;
};

export type AccountPlanPayloadResponse = AccountPlanPayload | GraphqlError;

export type AccountPlanQuery = {
  __typename?: 'AccountPlanQuery';
  getAccountPlan: AccountPlanPayloadResponse;
  getAccountPlanByExtCompanyId: AccountPlanPayloadResponse;
  listAccountPlans: AccountPlanConnectionResponse;
  searchAccountPlan: AccountPlanConnectionResponse;
};

export type AccountPlanQueryGetAccountPlanArgs = {
  accountPlanId: Scalars['ID'];
};

export type AccountPlanQueryGetAccountPlanByExtCompanyIdArgs = {
  extCompanyId: Scalars['ID'];
};

export type AccountPlanQueryListAccountPlansArgs = {
  input?: Maybe<ListAccountPlanInput>;
};

export type AccountPlanQuerySearchAccountPlanArgs = {
  input?: Maybe<SearchAccountPlanInput>;
};

export type AccountPlanResponse = AccountPlan | GraphqlError;

export type AccountPlanSyncData = {
  __typename?: 'AccountPlanSyncData';
  status?: Maybe<Scalars['Boolean']>;
};

export type AccountPlanSyncDataResponse = AccountPlanSyncData | GraphqlError;

export enum ActionType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export type ActivityTimeline = {
  __typename?: 'ActivityTimeline';
  authorizer: ActivityTimelineAuthorizerDetails;
  message: Scalars['String'];
  task?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  milestone?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  comment?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  media?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  role?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  stakeholder?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  methodology?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  opportunity?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  user?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  msp?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  mspTemplate?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  createdAt: Scalars['Timestamp'];
  eventType: Scalars['String'];
};

export type ActivityTimelineAuthorizerDetails = {
  __typename?: 'ActivityTimelineAuthorizerDetails';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ActivityTimelineAuthorizerInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ActivityTimelineConnection = {
  __typename?: 'ActivityTimelineConnection';
  edges?: Maybe<Array<Maybe<ActivityTimelineEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
  activityTimelineHighlights?: Maybe<Array<ActivityTimelineHighlights>>;
};

export type ActivityTimelineConnectionResponse =
  | ActivityTimelineConnection
  | GraphqlError;

export type ActivityTimelineEdge = {
  __typename?: 'ActivityTimelineEdge';
  node?: Maybe<ActivityTimelineResponse>;
};

export type ActivityTimelineHighlights = {
  __typename?: 'ActivityTimelineHighlights';
  eventType: Scalars['String'];
  count: Scalars['Int'];
};

export type ActivityTimelineQuery = {
  __typename?: 'ActivityTimelineQuery';
  getActivityTimeline: ActivityTimelineConnectionResponse;
  getMspActivityTimeseries: MspActivityTimeseriesResponse;
};

export type ActivityTimelineQueryGetActivityTimelineArgs = {
  input: GetActivityTimelineRequestBody;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type ActivityTimelineQueryGetMspActivityTimeseriesArgs = {
  mspId: Scalars['ID'];
  requestOptions?: Maybe<MspActivityTimeseriesQueryOptions>;
};

export type ActivityTimelineReferenceInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BaResource>;
};

export type ActivityTimelineResourceDetails = {
  __typename?: 'ActivityTimelineResourceDetails';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateFields?: Maybe<Array<Maybe<ActivityTimelineResourceUpdateFields>>>;
  link?: Maybe<Scalars['String']>;
};

export type ActivityTimelineResourceUpdateFields = {
  __typename?: 'ActivityTimelineResourceUpdateFields';
  property?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ActivityTimelineResponse = ActivityTimeline | GraphqlError;

export type ActivityTimelineScopeInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AddCommentInput = {
  reference: ReferenceInput;
  references?: Maybe<Array<Maybe<AnnotationReferenceInput>>>;
  annotationType: AnnotationType;
  message: Scalars['String'];
  media?: Maybe<Array<CreateMediaInput>>;
};

export type AddNotesInput = {
  reference: ReferenceInput;
  references?: Maybe<Array<Maybe<AnnotationReferenceInput>>>;
  scope: AnnotationScopeInput;
  annotationType: AnnotationType;
  message: Scalars['String'];
};

export type AddOrRemovePlaceholderPayload = {
  __typename?: 'AddOrRemovePlaceholderPayload';
  items?: Maybe<Array<AddOrRemovePlaceholderResponse>>;
};

export type AddOrRemovePlaceholderResponse = {
  __typename?: 'AddOrRemovePlaceholderResponse';
  roleId: Scalars['ID'];
  placeholderId: Scalars['ID'];
};

export type AddOrRemovePlaceholderRoleResponse =
  | AddOrRemovePlaceholderPayload
  | GraphqlError;

export type AddOrRemoveStakeholderPayload = {
  __typename?: 'AddOrRemoveStakeholderPayload';
  items?: Maybe<Array<AddOrRemoveStakeholderResponse>>;
};

export type AddOrRemoveStakeholderResponse = {
  __typename?: 'AddOrRemoveStakeholderResponse';
  roleId: Scalars['ID'];
  stakeholderId: Scalars['ID'];
  stakeholderPrimaryId?: Maybe<Scalars['ID']>;
};

export type AddOrRemoveStakeholderRoleResponse =
  | AddOrRemoveStakeholderPayload
  | GraphqlError;

export type AddPrimaryStakeholderInput = {
  stakeholderId: Scalars['ID'];
};

export type AddPrimaryStakeholderPayload = {
  __typename?: 'AddPrimaryStakeholderPayload';
  items?: Maybe<Array<StakeholderResponse>>;
};

export type AddPrimaryStakeholderPayloadResponse =
  | AddPrimaryStakeholderPayload
  | GraphqlError;

export type AddReporteeOrReportsTo = {
  userId: Scalars['String'];
  managerId: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type AddStakeHolderToRoleInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  stakeholderIdMap: UserIdMapInput;
};

export type Alignx = {
  __typename?: 'Alignx';
  msps: MspConnectionResponse;
  analytics: AlignxAnalyticsResponse;
  dashboardFilters?: Maybe<DashboardFilterPayloadResponse>;
  dashboardFiltersV1?: Maybe<FilterItemsPayloadResponse>;
  dashboardColumns?: Maybe<DashboardColumnPayloadResponse>;
};

export type AlignxMspsArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type AlignxAnalytics = {
  __typename?: 'AlignxAnalytics';
  mspTrackCounts: MspTrackCountsResponse;
  mspPublishStatus: MspPublishStatusResponse;
  opportunityCountsAtStage: OpportunityCountsAtStagePayloadResponse;
};

export type AlignxAnalyticsOpportunityCountsAtStageArgs = {
  stageNames: Array<Scalars['String']>;
};

export type AlignxAnalyticsResponse = AlignxAnalytics | GraphqlError;

export type AlignxQuery = {
  __typename?: 'AlignxQuery';
  dashboard: AlignxResponse;
};

export type AlignxResponse = Alignx | GraphqlError;

export type AnalyticsActivity = {
  __typename?: 'AnalyticsActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityById?: Maybe<Scalars['String']>;
  activityByUser?: Maybe<UserResponse>;
  message?: Maybe<Scalars['String']>;
};

export type AnalyticsBaseTask = {
  __typename?: 'AnalyticsBaseTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  status: TaskStatus;
  dueDate: Scalars['Int'];
  milestoneId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  assigneeType?: Maybe<AssigneeType>;
  milestoneName?: Maybe<Scalars['String']>;
};

export type AnalyticsMilestone = {
  __typename?: 'AnalyticsMilestone';
  milestone?: Maybe<BaseMilestone>;
  progress?: Maybe<AnalyticsMilestoneProgress>;
};

export type AnalyticsMilestoneProgress = {
  __typename?: 'AnalyticsMilestoneProgress';
  tasksCount?: Maybe<Scalars['Int']>;
  tasksCompleted?: Maybe<Scalars['Int']>;
  tasksOverdue?: Maybe<Scalars['Int']>;
};

export type AnalyticsOpportunity = {
  __typename?: 'AnalyticsOpportunity';
  opportunityCloseDate?: Maybe<Scalars['Timestamp']>;
  opportunityStage?: Maybe<Scalars['String']>;
};

export type AnalyticsTask = {
  __typename?: 'AnalyticsTask';
  task?: Maybe<AnalyticsBaseTask>;
  assignee?: Maybe<AnalyticsTaskAssignee>;
};

export type AnalyticsTaskAssignee = AnalyticsUser | Placeholder;

export type AnalyticsUser = {
  __typename?: 'AnalyticsUser';
  id: Scalars['ID'];
  primaryId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userType: UserType;
  email?: Maybe<Scalars['EmailAddress']>;
};

export type Annotation = {
  id: Scalars['ID'];
  reference: Reference;
  references?: Maybe<Array<Maybe<AnnotationReferenceResponse>>>;
  annotationType: AnnotationType;
  createdBy: UserResponse;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type AnnotationMutation = {
  __typename?: 'AnnotationMutation';
  addComment: CommentPayloadResponse;
  updateComment: CommentPayloadResponse;
  removeComment: BooleanResponse;
  addNotes: NotesPayloadResponse;
  updateNotes: NotesPayloadResponse;
  removeNotes: BooleanResponse;
};

export type AnnotationMutationAddCommentArgs = {
  input: AddCommentInput;
};

export type AnnotationMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type AnnotationMutationRemoveCommentArgs = {
  id: Scalars['ID'];
};

export type AnnotationMutationAddNotesArgs = {
  input: AddNotesInput;
};

export type AnnotationMutationUpdateNotesArgs = {
  input: UpdateNotesInput;
};

export type AnnotationMutationRemoveNotesArgs = {
  id: Scalars['ID'];
};

export type AnnotationQuery = {
  __typename?: 'AnnotationQuery';
  getNotes: NotesPayloadResponse;
  getRecentConversations: CommentConnectionResponse;
};

export type AnnotationQueryGetNotesArgs = {
  input: GetNotesInput;
};

export type AnnotationQueryGetRecentConversationsArgs = {
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type AnnotationReferenceInput = {
  id: Scalars['ID'];
  referenceType: ReferenceType;
  primaryAttachedSource?: Maybe<Scalars['Boolean']>;
};

export type AnnotationReferenceResponse = {
  __typename?: 'AnnotationReferenceResponse';
  id: Scalars['ID'];
  referenceType: ReferenceType;
  primaryAttachedSource?: Maybe<Scalars['Boolean']>;
};

export type AnnotationScope = {
  __typename?: 'AnnotationScope';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type AnnotationScopeInput = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export enum AnnotationType {
  COMMENTS = 'COMMENTS',
  NOTES = 'NOTES',
}

export type ApproveTimelineStages = {
  timelineId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type ArchiveMspInput = {
  ids: Array<Scalars['ID']>;
  isArchived: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  restoreExternalUsers?: Maybe<Scalars['Boolean']>;
};

export type Assets = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  createdBy: UserResponse;
  name?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  context?: Maybe<MediaContextResponse>;
  url: Scalars['URL'];
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileShareUrl?: Maybe<Scalars['URL']>;
  formats?: Maybe<Scalars['JSON']>;
  thumbnail?: Maybe<Thumbnail>;
  mimeType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  analytics?: Maybe<MediaAnalyticsResponse>;
  isInternal?: Maybe<Scalars['Boolean']>;
};

export type Assignee = User | Placeholder | GraphqlError;

export enum AssigneeType {
  PLACEHOLDER = 'PLACEHOLDER',
  USER = 'USER',
}

export type AttachMedia = {
  __typename?: 'AttachMedia';
  scopeId: Scalars['ID'];
  mediaId: Scalars['ID'];
  referenceId: Scalars['ID'];
  referenceType: ObjectType;
};

export type AttachMediaInput = {
  scopeId: Scalars['ID'];
  referenceId: Scalars['ID'];
  references?: Maybe<Array<Maybe<MediaReferenceInput>>>;
  referenceType: ObjectType;
  isInternal?: Maybe<Scalars['Boolean']>;
};

export type AttachMediaPayload = {
  __typename?: 'AttachMediaPayload';
  items?: Maybe<Array<AttachMediaResponse>>;
};

export type AttachMediaPayloadResponse = AttachMediaPayload | GraphqlError;

export type AttachMediaResponse = AttachMedia | GraphqlError;

export type Audio = Media &
  Assets & {
    __typename?: 'Audio';
    id: Scalars['ID'];
    orgId: Scalars['ID'];
    createdBy: UserResponse;
    name?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fileSize?: Maybe<Scalars['Int']>;
    description?: Maybe<Scalars['String']>;
    mediaType: MediaType;
    url: Scalars['URL'];
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    formats?: Maybe<Scalars['JSON']>;
    context?: Maybe<MediaContextResponse>;
    thumbnail?: Maybe<Thumbnail>;
    mimeType?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    createdAt: Scalars['Timestamp'];
    analytics?: Maybe<MediaAnalyticsResponse>;
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
  };

export type AudioMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type Avatar = {
  __typename?: 'Avatar';
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<UserResponse>;
  name?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  url?: Maybe<Scalars['URL']>;
  formats?: Maybe<Scalars['JSON']>;
  mimeType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
};

export enum BaResource {
  ACCOUNT = 'ACCOUNT',
  COMMENTS = 'COMMENTS',
  COMPANY = 'COMPANY',
  GROUP = 'GROUP',
  MANAGER = 'MANAGER',
  MEDIA = 'MEDIA',
  METHODOLOGY = 'METHODOLOGY',
  MILESTONE = 'MILESTONE',
  MSP = 'MSP',
  NOTES = 'NOTES',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_PREFERENCE = 'NOTIFICATION_PREFERENCE',
  ORGANIZATION = 'ORGANIZATION',
  PLACEHOLDER = 'PLACEHOLDER',
  ROLE = 'ROLE',
  STAKEHOLDER = 'STAKEHOLDER',
  TASK = 'TASK',
  TEMPLATE = 'TEMPLATE',
  TIMELINE = 'TIMELINE',
  USER = 'USER',
  USER_NOTIFICATION_STATUS = 'USER_NOTIFICATION_STATUS',
}

export type BaResourceDetails = {
  type: BaResource;
};

export type BaResourceCountObject = {
  __typename?: 'BaResourceCountObject';
  internalCount: Scalars['Int'];
  externalCount: Scalars['Int'];
};

export type BaResourceCountResponse = BaResourceCountObject | GraphqlError;

export type BaseMilestone = IMilestone & {
  __typename?: 'BaseMilestone';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  createdAt: Scalars['Int'];
};

export type BaseMsp = IMsp & {
  __typename?: 'BaseMsp';
  id: Scalars['ID'];
  opportunityId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  createdBy?: Maybe<UserResponse>;
  startDate: Scalars['Int'];
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  economicImpact?: Maybe<Scalars['String']>;
  buyerCompany: CompanyResponse;
};

export type BelongsToResponse = MediaBelongsTo | GraphqlError;

export type BooleanObject = {
  __typename?: 'BooleanObject';
  id?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponse = BooleanObject | GraphqlError;

export type Brand = {
  __typename?: 'Brand';
  banners?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  backgroundBanners?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  icons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  logos?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  themes?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type BrandingConfig = {
  __typename?: 'BrandingConfig';
  header?: Maybe<BrandingHeader>;
};

export type BrandingConfigInput = {
  header?: Maybe<BrandingHeaderInput>;
};

export type BrandingHeader = {
  __typename?: 'BrandingHeader';
  color?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type BrandingHeaderInput = {
  color?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type BulkUpdateNotificationStatusRequestBody = {
  userId: Scalars['String'];
  startTimestamp: Scalars['Timestamp'];
  status?: Maybe<NotificationStatus>;
};

export type ChangeTaskStatusInput = {
  taskIds: Array<Scalars['ID']>;
  taskStatus: TaskStatus;
};

export type CloneRelationshipMapInput = {
  fromScope: CloneRelationshipScopeInput;
  toScope: CloneRelationshipScopeInput;
};

export type CloneRelationshipScopeInput = {
  id: Scalars['ID'];
  scope: RelationshipScopeType;
};

export type Comment = Annotation & {
  __typename?: 'Comment';
  id: Scalars['ID'];
  reference: Reference;
  references?: Maybe<Array<Maybe<AnnotationReferenceResponse>>>;
  annotationType: AnnotationType;
  messageData: MessageData;
  createdBy: UserResponse;
  media?: Maybe<MediaConnectionResponse>;
  updatedAt: Scalars['Timestamp'];
  createdAt: Scalars['Timestamp'];
  isInternal?: Maybe<Scalars['Boolean']>;
  commentedIn?: Maybe<CommentedInPayload>;
};

export type CommentMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CommentConnectionResponse = CommentConnection | GraphqlError;

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node?: Maybe<CommentResponse>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  items?: Maybe<Array<CommentResponse>>;
};

export type CommentPayloadResponse = CommentPayload | GraphqlError;

export type CommentResponse = Comment | GraphqlError;

export type CommentedIn = {
  __typename?: 'CommentedIn';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  objectType?: Maybe<Scalars['String']>;
};

export type CommentedInPayload = {
  __typename?: 'CommentedInPayload';
  items?: Maybe<Array<Maybe<CommentedInResponse>>>;
};

export type CommentedInResponse = CommentedIn | GraphqlError;

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  companyName: Scalars['String'];
  displayName: Scalars['String'];
  logo?: Maybe<LogoResponse>;
  createdAt: Scalars['Timestamp'];
  originalSource: OriginalSourceForCompanyType;
  brand?: Maybe<Brand>;
  extId?: Maybe<Scalars['String']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CompanyConnectionResponse = CompanyConnection | GraphqlError;

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<CompanyResponse>;
};

export type CompanyMutation = {
  __typename?: 'CompanyMutation';
  createCompany: CompanyPayloadResponse;
  updateCompany: CompanyPayloadResponse;
};

export type CompanyMutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type CompanyMutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: UpdateCompanyInput;
};

export type CompanyPayload = {
  __typename?: 'CompanyPayload';
  items?: Maybe<Array<CompanyResponse>>;
};

export type CompanyPayloadResponse = CompanyPayload | GraphqlError;

export type CompanyQuery = {
  __typename?: 'CompanyQuery';
  searchCompany: CompanyConnectionResponse;
  getCompany: CompanyPayloadResponse;
  getCompanyByName: CompanyPayloadResponse;
};

export type CompanyQuerySearchCompanyArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations: Operations;
};

export type CompanyQueryGetCompanyArgs = {
  id: Scalars['ID'];
};

export type CompanyQueryGetCompanyByNameArgs = {
  companyName: Scalars['String'];
};

export type CompanyResponse = Company | GraphqlError;

export enum CompanyState {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  MAINTENANCE = 'MAINTENANCE',
}

export enum CompanyType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type Contact = {
  __typename?: 'Contact';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  title?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyResponse>;
  userCompaniesScope?: Maybe<Array<Maybe<UserCompanyScope>>>;
  userType?: Maybe<UserType>;
  originalSource?: Maybe<OriginalSourceForContact>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContactConnectionResponse = ContactConnection | GraphqlError;

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ContactResponse>;
};

export type ContactMutation = {
  __typename?: 'ContactMutation';
  createContact: ContactObjectPayloadResponse;
  updateContact: ContactObjectPayloadResponse;
};

export type ContactMutationCreateContactArgs = {
  input: CreateContactInput;
};

export type ContactMutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type ContactObjectPayload = {
  __typename?: 'ContactObjectPayload';
  items?: Maybe<Array<ContactResponse>>;
};

export type ContactObjectPayloadResponse = ContactObjectPayload | GraphqlError;

export type ContactPayload = {
  __typename?: 'ContactPayload';
  items?: Maybe<Array<ContactRoleResponse>>;
};

export type ContactPayloadResponse = ContactPayload | GraphqlError;

export type ContactQuery = {
  __typename?: 'ContactQuery';
  getContacts: ContactObjectPayloadResponse;
  searchContacts: ContactConnectionResponse;
};

export type ContactQueryGetContactsArgs = {
  ids: Array<Scalars['ID']>;
};

export type ContactQuerySearchContactsArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations: Operations;
};

export type ContactResponse = Contact | GraphqlError;

export type ContactRole = {
  __typename?: 'ContactRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  meta?: Maybe<ContactRoleMeta>;
};

export type ContactRoleMeta = {
  __typename?: 'ContactRoleMeta';
  color?: Maybe<Scalars['JSON']>;
};

export type ContactRoleMetaInput = {
  color?: Maybe<Scalars['JSON']>;
};

export type ContactRoleMutation = {
  __typename?: 'ContactRoleMutation';
  createContactRole: ContactPayloadResponse;
  updateContactRole: ContactPayloadResponse;
  removeContactRole: BooleanResponse;
};

export type ContactRoleMutationCreateContactRoleArgs = {
  input: CreateContactRoleInput;
};

export type ContactRoleMutationUpdateContactRoleArgs = {
  input: UpdateContactRoleInput;
};

export type ContactRoleMutationRemoveContactRoleArgs = {
  id: Scalars['ID'];
};

export type ContactRolePayload = {
  __typename?: 'ContactRolePayload';
  items: Array<ContactRoleResponse>;
};

export type ContactRoleQuery = {
  __typename?: 'ContactRoleQuery';
  listContactsRoles: ContactRolesPayloadResponse;
};

export type ContactRoleQueryListContactsRolesArgs = {
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type ContactRoleResponse = ContactRole | GraphqlError;

export type ContactRolesPayloadResponse = ContactRolePayload | GraphqlError;

export type CreateAccountPlanInput = {
  extCompanyId: Scalars['ID'];
};

export type CreateAndAssignPlaceholderToRoleInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  companyType: CompanyType;
  placeholderInput: PlaceholderInput;
};

export type CreateAndAssignPrimaryStakeholderToRoleInput = {
  userInput: CreateUserInput;
};

export type CreateAndAssignStakeholderToRoleInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  userInput: CreateUserInput;
};

export type CreateAndAttachMediaInput = {
  mediaInput: Array<CreateMediaInput>;
  attachInput: AttachMediaInput;
};

export type CreateAssetsInput = {
  path: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  fileSize: Scalars['Int'];
};

export type CreateCompanyInput = {
  companyName: Scalars['String'];
  extId?: Maybe<Scalars['String']>;
  originalSource: OriginalSourceForCompanyInput;
};

export type CreateContactInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  companyId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  originalSource: OriginalSourceForContactInput;
};

export type CreateContactRoleInput = {
  name: Scalars['String'];
  meta?: Maybe<ContactRoleMetaInput>;
};

export type CreateExecutiveSummaryInput = {
  description?: Maybe<Scalars['String']>;
  media?: Maybe<Array<CreateMediaInput>>;
};

export type CreateInfluencerMapContactFieldInput = {
  key: Scalars['String'];
  fieldContextType: Scalars['String'];
  data: Scalars['JSON'];
};

export type CreateInfluencerMapContactInput = {
  scope?: Maybe<RelationshipScopeInput>;
  structureId: Scalars['ID'];
  fields: Array<CreateInfluencerMapContactFieldInput>;
};

export type CreateLinkInput = {
  link: Scalars['URL'];
  name?: Maybe<Scalars['String']>;
};

export type CreateLovItemInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: LovType;
  value: Scalars['String'];
  scope?: Maybe<ScopeJsonInput>;
  originalSource: OriginalSourceJsonInput;
  displayValue: Scalars['String'];
  active: Scalars['Boolean'];
  isDefaultValue: Scalars['Boolean'];
};

export type CreateMediaInput = {
  uploadId?: Maybe<Scalars['ID']>;
  linkInput?: Maybe<CreateLinkInput>;
  assetsInput?: Maybe<CreateAssetsInput>;
  source: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  path?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
};

export type CreateMilestoneInput = {
  mspId: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  media?: Maybe<Array<CreateMediaInput>>;
  isInternal?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['ID']>;
  tasks?: Maybe<Array<Maybe<CreateTaskInput>>>;
  milestoneDetails?: Maybe<MilestoneDetailsInput>;
};

export type CreateMilestoneTemplateInput = {
  mspId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate: Scalars['Int'];
  endDate?: Maybe<Scalars['Int']>;
  isInternal?: Maybe<Scalars['Boolean']>;
};

export type CreateMspInput = {
  opportunityId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  summary?: Maybe<CreateExecutiveSummaryInput>;
  description?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  decisionCriteria?: Maybe<Scalars['String']>;
  buyerCompanyId?: Maybe<Scalars['ID']>;
  sellerCompanyId?: Maybe<Scalars['ID']>;
  buyerCompanyName?: Maybe<Scalars['String']>;
  buyerCompanyDisplayName?: Maybe<Scalars['String']>;
  buyerCompanyLogo?: Maybe<CreateMediaInput>;
  startDate: Scalars['Int'];
  closeDate: Scalars['Int'];
  media?: Maybe<Array<CreateMediaInput>>;
  uploadBuyerCompanyLogo?: Maybe<UploadMediaInput>;
  ownerId?: Maybe<Scalars['ID']>;
};

export type CreateMspTemplateInput = {
  opportunityId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<CreateExecutiveSummaryInput>;
  economicImpact?: Maybe<Scalars['String']>;
  decisionCriteria?: Maybe<Scalars['String']>;
  startDate: Scalars['Int'];
  buyerCompanyId?: Maybe<Scalars['ID']>;
  sellerCompanyId?: Maybe<Scalars['ID']>;
  buyerCompanyName?: Maybe<Scalars['String']>;
  buyerCompanyDisplayName?: Maybe<Scalars['String']>;
  buyerCompanyLogo?: Maybe<CreateMediaInput>;
  uploadBuyerCompanyLogo?: Maybe<UploadMediaInput>;
  media?: Maybe<Array<Maybe<CreateMediaInput>>>;
};

export type CreateNotificationPreferenceInput = {
  preferences: NotificationScopePreferenceInput;
  event: Scalars['String'];
  scopeId: Scalars['String'];
  scopeType: NotificationScopeType;
};

export type CreateNotificationPreferenceRequestBody = {
  notificationPreferences: Array<CreateNotificationPreferenceInput>;
};

export type CreateOpportunityInput = {
  orgId: Scalars['String'];
  accountId: Scalars['String'];
  contactId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  closeDate?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isWon?: Maybe<Scalars['Boolean']>;
  forecastCategory?: Maybe<Scalars['String']>;
  forecastCategoryName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  fiscal?: Maybe<Scalars['String']>;
  sourceSystem?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
  intOwnerId?: Maybe<Scalars['String']>;
  extOwnerId?: Maybe<Scalars['String']>;
  intCompanyId?: Maybe<Scalars['String']>;
  extCompanyId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  orgId: Scalars['ID'];
  orgName: Scalars['String'];
  orgType: OrgType;
  orgLogo?: Maybe<OrgLogoInput>;
  configs: OrgConfigInput;
  state: CompanyState;
};

export type CreateRelationshipGraphInput = {
  scope?: Maybe<RelationshipScopeInput>;
  nodes?: Maybe<Array<Maybe<GraphNodeInput>>>;
  edges?: Maybe<Array<Maybe<GraphEdgeInput>>>;
  viewport: Scalars['JSON'];
};

export type CreateRoleInput = {
  scopeId: Scalars['ID'];
  scopeType: RoleScope;
  companyType: CompanyType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateTaskInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Int']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<CreateMediaInput>>;
  templateId?: Maybe<Scalars['ID']>;
  assignee?: Maybe<UserIdMapInput>;
};

export type CreateTimelineStepsInput = {
  label: Scalars['String'];
  date: Scalars['Int'];
};

export type CreateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  companyId: Scalars['ID'];
  companyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateUspInput = {
  mspId: Scalars['String'];
  public: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
};

export type DashboardColumn = {
  __typename?: 'DashboardColumn';
  displayName: Scalars['String'];
  isVisible: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  isSortable: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
};

export type DashboardColumnPayload = {
  __typename?: 'DashboardColumnPayload';
  items?: Maybe<Array<DashboardColumnResponse>>;
};

export type DashboardColumnPayloadResponse =
  | DashboardColumnPayload
  | GraphqlError;

export type DashboardColumnResponse = DashboardColumn | GraphqlError;

export type DashboardFilter = {
  __typename?: 'DashboardFilter';
  key?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DashboardFilterPayload = {
  __typename?: 'DashboardFilterPayload';
  items?: Maybe<Array<DashboardFilterResponse>>;
};

export type DashboardFilterPayloadResponse =
  | DashboardFilterPayload
  | GraphqlError;

export type DashboardFilterResponse = DashboardFilter | GraphqlError;

export type DataPayload = {
  __typename?: 'DataPayload';
  id?: Maybe<Scalars['ID']>;
  values?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  key?: Maybe<Scalars['ID']>;
};

export type DataPayloadResource = {
  __typename?: 'DataPayloadResource';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

export type DataSourceClient = {
  __typename?: 'DataSourceClient';
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
};

export type DataSourceConfig = {
  __typename?: 'DataSourceConfig';
  path?: Maybe<DataSourcePath>;
  client?: Maybe<Array<Maybe<DataSourceClient>>>;
};

export type DataSourcePath = {
  __typename?: 'DataSourcePath';
  defaultValuePath?: Maybe<Array<Scalars['JSON']>>;
  valueSuggestionsPath?: Maybe<Array<Scalars['JSON']>>;
};

export type DefaultContactInput = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
  scope?: Maybe<RelationshipScopeInput>;
};

export type DeleteTimelineStepsInput = {
  timelineIds?: Maybe<Array<Scalars['ID']>>;
};

export type DetachMedia = {
  __typename?: 'DetachMedia';
  mediaId: Scalars['ID'];
  /** @deprecated Pass mediaId only */
  referenceId?: Maybe<Scalars['ID']>;
};

export type DetachMediaInput = {
  referenceId?: Maybe<Scalars['ID']>;
  mediaId: Scalars['ID'];
};

export type DetachMediaResponse = DetachMedia | GraphqlError;

export type DisapproveTimelineStages = {
  timelineId: Scalars['ID'];
};

export type Document = Media &
  Assets & {
    __typename?: 'Document';
    id: Scalars['ID'];
    orgId: Scalars['ID'];
    createdBy: UserResponse;
    name?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fileSize?: Maybe<Scalars['Int']>;
    description?: Maybe<Scalars['String']>;
    mediaType: MediaType;
    url: Scalars['URL'];
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    formats?: Maybe<Scalars['JSON']>;
    thumbnail?: Maybe<Thumbnail>;
    context?: Maybe<MediaContextResponse>;
    mimeType?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    createdAt: Scalars['Timestamp'];
    analytics?: Maybe<MediaAnalyticsResponse>;
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
  };

export type DocumentMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type EngagementCount = {
  __typename?: 'EngagementCount';
  viewCount?: Maybe<Scalars['Int']>;
  downloadCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
};

export type EngagementCountResponse = EngagementCount | GraphqlError;

export enum ErrorType {
  BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR',
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
}

export type ExecutiveSummary = {
  __typename?: 'ExecutiveSummary';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  media?: Maybe<MediaConnectionResponse>;
};

export type ExecutiveSummaryMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type ExternalLinkInput = {
  mediaId: Scalars['ID'];
};

export type FavoriteMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type Filter = {
  op: Operation;
  values: Array<Scalars['String']>;
  field: Scalars['String'];
};

export type FilterItems = {
  __typename?: 'FilterItems';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  isVisible: Scalars['Boolean'];
  displayName: Scalars['String'];
  filterType: FilterType;
  values?: Maybe<Array<Scalars['JSON']>>;
  order?: Maybe<Scalars['Int']>;
};

export type FilterItemsCount = {
  __typename?: 'FilterItemsCount';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  values?: Maybe<Array<Scalars['JSON']>>;
  count: Scalars['Int'];
};

export type FilterItemsCountPayload = {
  __typename?: 'FilterItemsCountPayload';
  items?: Maybe<Array<FilterItemsCount>>;
};

export type FilterItemsCountPayloadResponse =
  | FilterItemsCountPayload
  | GraphqlError;

export type FilterItemsPayload = {
  __typename?: 'FilterItemsPayload';
  items?: Maybe<Array<FilterItems>>;
};

export type FilterItemsPayloadResponse = FilterItemsPayload | GraphqlError;

export enum FilterType {
  DROP_DOWN_FILTER = 'DROP_DOWN_FILTER',
  QUICK_FILTER = 'QUICK_FILTER',
}

export type FiltersExpression = {
  operator: Operator;
  filters?: Maybe<Array<Filter>>;
  childExpressions?: Maybe<Array<FiltersExpression>>;
};

export type FromMilestoneInput = {
  milestoneId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type GetActivityTimelineRequestBody = {
  userId: Scalars['String'];
  scopes?: Maybe<Array<ActivityTimelineScopeInput>>;
  references?: Maybe<Array<ActivityTimelineReferenceInput>>;
  authorizers?: Maybe<Array<ActivityTimelineAuthorizerInput>>;
  orgId?: Maybe<Scalars['String']>;
  createdAfterTimestamp?: Maybe<Scalars['Timestamp']>;
  filterEventTypes?: Maybe<Array<Scalars['String']>>;
  getCountEventTypes?: Maybe<Array<Scalars['String']>>;
};

export type GetMediaEngagementRequest = {
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetMspAnalyticsOptions = {
  recalculateAnalytics?: Maybe<Scalars['Boolean']>;
};

export type GetMspEngagementUsersRequest = {
  endTimestamp: Scalars['Timestamp'];
  startTimestamp?: Maybe<Scalars['Timestamp']>;
  userType?: Maybe<UserType>;
};

export type GetNotesInput = {
  referenceIds: Array<Scalars['ID']>;
  scopeId: Scalars['ID'];
};

export type GetPushNotificationRequestBody = {
  userId: Scalars['String'];
  baResourceList?: Maybe<Array<BaResourceDetails>>;
  accountIds?: Maybe<Array<Scalars['String']>>;
};

export type GetRelationshipGraphInput = {
  scope: RelationshipScopeInput;
  fetchType: RelationshipGraphFetchType;
};

export type GetRelationshipItemsFieldValuesInput = {
  scope?: Maybe<RelationshipScopeInput>;
};

export type GetRelationshipItemsInput = {
  scope: RelationshipScopeInput;
  itemIds: Array<Scalars['ID']>;
};

export type GetUserEngagementRequest = {
  userIds?: Maybe<Array<Scalars['String']>>;
  userType?: Maybe<UserType>;
};

export type GraphEdge = {
  __typename?: 'GraphEdge';
  id: Scalars['ID'];
  selected?: Maybe<Scalars['Boolean']>;
  animated?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  data?: Maybe<GraphItemDataResponse>;
  markerEnd?: Maybe<Scalars['JSON']>;
  markerStart?: Maybe<Scalars['JSON']>;
  style?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['ID']>;
  sourceHandle?: Maybe<Scalars['ID']>;
  target?: Maybe<Scalars['ID']>;
  targetHandle?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type GraphEdgeInput = {
  id: Scalars['ID'];
  animated?: Maybe<Scalars['Boolean']>;
  actionType?: Maybe<ActionType>;
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  selected?: Maybe<Scalars['Boolean']>;
  markerEnd?: Maybe<Scalars['JSON']>;
  markerStart?: Maybe<Scalars['JSON']>;
  style?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['ID']>;
  sourceHandle?: Maybe<Scalars['ID']>;
  target?: Maybe<Scalars['ID']>;
  targetHandle?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type GraphItemData = {
  __typename?: 'GraphItemData';
  id?: Maybe<Scalars['ID']>;
  resource?: Maybe<DataPayloadResource>;
  dataPayload?: Maybe<Array<Maybe<DataPayload>>>;
  metaData?: Maybe<GraphItemDataMetaData>;
};

export type GraphItemDataMetaData = {
  __typename?: 'GraphItemDataMetaData';
  structureId?: Maybe<Scalars['ID']>;
  structureType?: Maybe<StructureType>;
};

export type GraphItemDataResponse = GraphItemData | GraphqlError;

export type GraphNode = {
  __typename?: 'GraphNode';
  id: Scalars['ID'];
  parentNode?: Maybe<Scalars['ID']>;
  animated?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  dragging?: Maybe<Scalars['Boolean']>;
  positionAbsolute?: Maybe<Scalars['JSON']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['JSON']>;
  data?: Maybe<GraphItemDataResponse>;
  clientId?: Maybe<Scalars['ID']>;
  sourcePosition?: Maybe<Scalars['String']>;
  targetPosition?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Scalars['JSON']>;
};

export type GraphNodeInput = {
  id: Scalars['ID'];
  animated?: Maybe<Scalars['Boolean']>;
  actionType?: Maybe<ActionType>;
  parentNode?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  dragging?: Maybe<Scalars['Boolean']>;
  positionAbsolute?: Maybe<Scalars['JSON']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['JSON']>;
  data?: Maybe<Scalars['JSON']>;
  sourcePosition?: Maybe<Scalars['String']>;
  targetPosition?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Scalars['JSON']>;
  draggable?: Maybe<Scalars['Boolean']>;
};

export type GraphqlError = {
  __typename?: 'GraphqlError';
  type: ErrorType;
  code: Scalars['String'];
  message: Scalars['String'];
  helpLink?: Maybe<Scalars['URL']>;
};

export type HealthStatus = {
  __typename?: 'HealthStatus';
  message: Scalars['String'];
};

export type IMilestone = {
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  createdAt: Scalars['Int'];
};

export type IMsp = {
  id: Scalars['ID'];
  opportunityId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  startDate: Scalars['Int'];
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
};

export type IOriginalSourceObject = {
  __typename?: 'IOriginalSourceObject';
  id?: Maybe<Scalars['String']>;
  object?: Maybe<SourceObjectType>;
  system: OriginalSourceSystem;
};

export type IRelationshipBaseItem = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  objectType?: Maybe<RelationshipObjectType>;
};

export type IUser = {
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyResponse>;
  email?: Maybe<Scalars['EmailAddress']>;
};

export type Image = Media &
  Assets & {
    __typename?: 'Image';
    id: Scalars['ID'];
    orgId: Scalars['ID'];
    createdBy: UserResponse;
    name?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fileSize?: Maybe<Scalars['Int']>;
    description?: Maybe<Scalars['String']>;
    mediaType: MediaType;
    url: Scalars['URL'];
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    formats?: Maybe<Scalars['JSON']>;
    context?: Maybe<MediaContextResponse>;
    thumbnail?: Maybe<Thumbnail>;
    mimeType?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    analytics?: Maybe<MediaAnalyticsResponse>;
    createdAt: Scalars['Timestamp'];
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
  };

export type ImageMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type IntObject = {
  __typename?: 'IntObject';
  value: Scalars['Int'];
};

export type IntResponse = IntObject | GraphqlError;

export type InviteInput = {
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  userIdMaps?: Maybe<Array<Maybe<UserIdMapInput>>>;
  mspId: Scalars['ID'];
  role?: Maybe<Roles>;
  permission: MspInvitePermission;
  notification?: Maybe<NotificationInput>;
};

export type LatestActivity = {
  __typename?: 'LatestActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityById?: Maybe<Scalars['String']>;
  activityBy?: Maybe<UserResponse>;
  role?: Maybe<RolePayloadResponse>;
  message?: Maybe<Scalars['String']>;
};

export type LeftSideRelationshipItems = {
  __typename?: 'LeftSideRelationshipItems';
  otherContacts?: Maybe<RelationshipItemConnectionResponse>;
  defaultContacts?: Maybe<RelationshipItemConnectionResponse>;
};

export type LeftSideRelationshipItemsOtherContactsArgs = {
  input: OtherContactInput;
};

export type LeftSideRelationshipItemsDefaultContactsArgs = {
  input: DefaultContactInput;
};

export type LeftSideRelationshipItemsResponse =
  | LeftSideRelationshipItems
  | GraphqlError;

export type Link = Media & {
  __typename?: 'Link';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  createdBy: UserResponse;
  name?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  link: Scalars['URL'];
  /** @deprecated Remove this field */
  url: Scalars['URL'];
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileShareUrl?: Maybe<Scalars['URL']>;
  thumbnail?: Maybe<Thumbnail>;
  context?: Maybe<MediaContextResponse>;
  mimeType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  analytics?: Maybe<MediaAnalyticsResponse>;
  createdAt: Scalars['Timestamp'];
  isInternal?: Maybe<Scalars['Boolean']>;
};

export type LinkOpportunityInput = {
  mspId: Scalars['ID'];
  opportunityId: Scalars['ID'];
  accountId: Scalars['ID'];
};

export type LinkedinData = {
  __typename?: 'LinkedinData';
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['URL']>;
  avatarUrl?: Maybe<Scalars['URL']>;
};

export type LinkedinDataResponse = LinkedinData | GraphqlError;

export type ListAccountPlanInput = {
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type Logo = Media & {
  __typename?: 'Logo';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  createdBy: UserResponse;
  name?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  url: Scalars['URL'];
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  /** @deprecated Remove this field */
  isInternal?: Maybe<Scalars['Boolean']>;
  formats?: Maybe<Scalars['JSON']>;
  mimeType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  analytics?: Maybe<MediaAnalyticsResponse>;
  mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
};

export type LogoMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type LogoResponse = Logo | GraphqlError;

export type LovItem = {
  __typename?: 'LovItem';
  id: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: LovType;
  value: Scalars['String'];
  scope?: Maybe<ScopeJson>;
  originalSource: OriginalSourceJson;
  createdAt: Scalars['Timestamp'];
  displayValue: Scalars['String'];
  active: Scalars['Boolean'];
  isDefaultValue: Scalars['Boolean'];
};

export type LovItemConnection = {
  __typename?: 'LovItemConnection';
  edges?: Maybe<Array<Maybe<LovItemEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LovItemConnectionResponse = LovItemConnection | GraphqlError;

export type LovItemEdge = {
  __typename?: 'LovItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<LovItemResponse>;
};

export type LovItemMutation = {
  __typename?: 'LovItemMutation';
  createLovItem: LovItemResponse;
  updateLovItem: LovItemResponse;
  bulkCreateLovItems: LovItemPayloadResponse;
};

export type LovItemMutationCreateLovItemArgs = {
  input: CreateLovItemInput;
};

export type LovItemMutationUpdateLovItemArgs = {
  id: Scalars['String'];
  input: UpdateLovItemInput;
};

export type LovItemMutationBulkCreateLovItemsArgs = {
  input: Array<CreateLovItemInput>;
};

export type LovItemPayload = {
  __typename?: 'LovItemPayload';
  items: Array<LovItemResponse>;
};

export type LovItemPayloadResponse = LovItemPayload | GraphqlError;

export type LovItemQuery = {
  __typename?: 'LovItemQuery';
  getLovItem: LovItemResponse;
  searchLovItems: LovItemConnectionResponse;
};

export type LovItemQueryGetLovItemArgs = {
  id: Scalars['ID'];
};

export type LovItemQuerySearchLovItemsArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations: Operations;
};

export type LovItemResponse = LovItem | GraphqlError;

export enum LovType {
  AP_USER_ROLE = 'AP_USER_ROLE',
  AP_USER_SENTIMENTS = 'AP_USER_SENTIMENTS',
  COMPANY_USER_RELATIONSHIP_STRENGTH = 'COMPANY_USER_RELATIONSHIP_STRENGTH',
  COMPANY_USER_ROLE = 'COMPANY_USER_ROLE',
  MSP_USER_ROLE = 'MSP_USER_ROLE',
  MSP_USER_SENTIMENTS = 'MSP_USER_SENTIMENTS',
}

export type Manager = {
  __typename?: 'Manager';
  id: Scalars['ID'];
  userId: Scalars['String'];
  managerId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ManagerConnection = {
  __typename?: 'ManagerConnection';
  edges?: Maybe<Array<Maybe<ManagerEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ManagerConnectionResponse = ManagerConnection | GraphqlError;

export type ManagerEdge = {
  __typename?: 'ManagerEdge';
  cursor: Scalars['String'];
  node?: Maybe<ManagerResponse>;
};

export type ManagerMutation = {
  __typename?: 'ManagerMutation';
  addReportee: ManagerPayloadResponse;
  addReportsTo: ManagerPayloadResponse;
  removeReportee: BooleanResponse;
  removeReportsTo: BooleanResponse;
  bulkAddAndDeleteReportsTo: BooleanResponse;
  addReporteeAndRemoveExistingManager: ManagerPayloadResponse;
};

export type ManagerMutationAddReporteeArgs = {
  input: AddReporteeOrReportsTo;
};

export type ManagerMutationAddReportsToArgs = {
  input: AddReporteeOrReportsTo;
};

export type ManagerMutationRemoveReporteeArgs = {
  input: RemoveReporteeOrReportsTo;
};

export type ManagerMutationRemoveReportsToArgs = {
  input: RemoveReporteeOrReportsTo;
};

export type ManagerMutationBulkAddAndDeleteReportsToArgs = {
  input: BulkAddAndDeleteReportsToInput;
};

export type ManagerMutationAddReporteeAndRemoveExistingManagerArgs = {
  input: AddReporteeOrReportsTo;
};

export type ManagerPayload = {
  __typename?: 'ManagerPayload';
  items?: Maybe<Array<ManagerResponse>>;
};

export type ManagerPayloadResponse = ManagerPayload | GraphqlError;

export type ManagerQuery = {
  __typename?: 'ManagerQuery';
  searchManager: ManagerConnectionResponse;
};

export type ManagerQuerySearchManagerArgs = {
  operations: Operations;
};

export type ManagerResponse = Manager | GraphqlError;

export type MarkMediaInternalInput = {
  referenceId?: Maybe<Scalars['ID']>;
  mediaIds: Array<Scalars['ID']>;
  isInternal: Scalars['Boolean'];
};

export type MarkMilestoneInternalInput = {
  milestoneIds: Array<Scalars['ID']>;
  isInternal: Scalars['Boolean'];
};

export type MarkTaskInternalInput = {
  taskIds: Array<Scalars['ID']>;
  isInternal: Scalars['Boolean'];
};

export type Meddic = Node & {
  __typename?: 'Meddic';
  id: Scalars['ID'];
  mspId: Scalars['ID'];
  metrics?: Maybe<MethodologyStringQualifier>;
  economicBuyer?: Maybe<MethodologyUserQualifier>;
  decisionCriteria?: Maybe<MethodologyStringQualifier>;
  decisionProcess?: Maybe<MethodologyStringQualifier>;
  identifiedPain?: Maybe<MethodologyStringQualifier>;
  champion?: Maybe<MethodologyUserQualifier>;
  analytics?: Maybe<MethodologyAnalyticsResponse>;
};

export type MeddicMutation = {
  __typename?: 'MeddicMutation';
  updateMeddic: MeddicPayloadResponse;
  syncMeddic: MeddicPayloadResponse;
};

export type MeddicMutationUpdateMeddicArgs = {
  mspId: Scalars['ID'];
  input: UpdateMeddicInput;
};

export type MeddicMutationSyncMeddicArgs = {
  mspId: Scalars['ID'];
  input: SyncMeddicInput;
};

export type MeddicPayload = {
  __typename?: 'MeddicPayload';
  items?: Maybe<Array<MeddicResponse>>;
};

export type MeddicPayloadResponse = MeddicPayload | GraphqlError;

export type MeddicQualifierComment = {
  __typename?: 'MeddicQualifierComment';
  qualifierId?: Maybe<Scalars['String']>;
  comments?: Maybe<CommentConnectionResponse>;
};

export type MeddicQualifierCommentPayload = {
  __typename?: 'MeddicQualifierCommentPayload';
  items?: Maybe<Array<Maybe<MeddicQualifierComment>>>;
};

export type MeddicQualifierCommentPayloadResponse =
  | MeddicQualifierCommentPayload
  | GraphqlError;

export type MeddicQuery = {
  __typename?: 'MeddicQuery';
  getMeddicForMsp: MeddicPayloadResponse;
  getMeddicComments?: Maybe<MeddicQualifierCommentPayloadResponse>;
};

export type MeddicQueryGetMeddicForMspArgs = {
  mspId: Scalars['ID'];
};

export type MeddicQueryGetMeddicCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type MeddicResponse = Meddic | GraphqlError;

export type Meddpicc = Node & {
  __typename?: 'Meddpicc';
  id: Scalars['ID'];
  mspId: Scalars['ID'];
  metrics?: Maybe<MethodologyStringQualifier>;
  economicBuyer?: Maybe<MethodologyUserQualifier>;
  decisionCriteria?: Maybe<MethodologyStringQualifier>;
  decisionProcess?: Maybe<MethodologyStringQualifier>;
  paperProcess?: Maybe<MethodologyTaskQualifier>;
  identifiedPain?: Maybe<MethodologyStringQualifier>;
  champion?: Maybe<MethodologyUserQualifier>;
  competition?: Maybe<MethodologyStringQualifier>;
  analytics?: Maybe<MethodologyAnalyticsResponse>;
};

export type MeddpiccMutation = {
  __typename?: 'MeddpiccMutation';
  updateMeddpicc: MeddpiccPayloadResponse;
  syncMeddpicc: MeddpiccPayloadResponse;
};

export type MeddpiccMutationUpdateMeddpiccArgs = {
  mspId: Scalars['ID'];
  input: UpdateMeddpiccInput;
};

export type MeddpiccMutationSyncMeddpiccArgs = {
  mspId: Scalars['ID'];
  input: SyncMeddpiccInput;
};

export type MeddpiccPayload = {
  __typename?: 'MeddpiccPayload';
  items?: Maybe<Array<MeddpiccResponse>>;
};

export type MeddpiccPayloadResponse = MeddpiccPayload | GraphqlError;

export type MeddpiccQualifierComment = {
  __typename?: 'MeddpiccQualifierComment';
  qualifierId?: Maybe<Scalars['String']>;
  comments?: Maybe<CommentConnectionResponse>;
};

export type MeddpiccQualifierCommentPayload = {
  __typename?: 'MeddpiccQualifierCommentPayload';
  items?: Maybe<Array<Maybe<MeddpiccQualifierComment>>>;
};

export type MeddpiccQualifierCommentPayloadResponse =
  | MeddpiccQualifierCommentPayload
  | GraphqlError;

export type MeddpiccQuery = {
  __typename?: 'MeddpiccQuery';
  getMeddpiccForMsp: MeddpiccPayloadResponse;
  getMeddpiccComments?: Maybe<MeddpiccQualifierCommentPayloadResponse>;
};

export type MeddpiccQueryGetMeddpiccForMspArgs = {
  mspId: Scalars['ID'];
};

export type MeddpiccQueryGetMeddpiccCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type MeddpiccResponse = Meddpicc | GraphqlError;

export type Meddpiccpa = Node & {
  __typename?: 'Meddpiccpa';
  id: Scalars['ID'];
  mspId: Scalars['ID'];
  metrics?: Maybe<MethodologyStringQualifier>;
  economicBuyer?: Maybe<MethodologyUserQualifier>;
  decisionCriteria?: Maybe<MethodologyStringQualifier>;
  decisionProcess?: Maybe<MethodologyStringQualifier>;
  paperProcess?: Maybe<MethodologyStringQualifier>;
  identifiedPain?: Maybe<MethodologyStringQualifier>;
  champion?: Maybe<MethodologyUserQualifier>;
  competition?: Maybe<MethodologyStringQualifier>;
  product?: Maybe<MethodologyStringQualifier>;
  additionalInfo?: Maybe<MethodologyStringQualifier>;
  analytics?: Maybe<MethodologyAnalyticsResponse>;
};

export type MeddpiccpaMutation = {
  __typename?: 'MeddpiccpaMutation';
  updateMeddpiccpa: MeddpiccpaPayloadResponse;
  syncMeddpiccpa: MeddpiccpaPayloadResponse;
};

export type MeddpiccpaMutationUpdateMeddpiccpaArgs = {
  mspId: Scalars['ID'];
  input: UpdateMeddpiccpaInput;
};

export type MeddpiccpaMutationSyncMeddpiccpaArgs = {
  mspId: Scalars['ID'];
  input: SyncMeddpiccpaInput;
};

export type MeddpiccpaPayload = {
  __typename?: 'MeddpiccpaPayload';
  items?: Maybe<Array<MeddpiccpaResponse>>;
};

export type MeddpiccpaPayloadResponse = MeddpiccpaPayload | GraphqlError;

export type MeddpiccpaQualifierComment = {
  __typename?: 'MeddpiccpaQualifierComment';
  qualifierId?: Maybe<Scalars['String']>;
  comments?: Maybe<CommentConnectionResponse>;
};

export type MeddpiccpaQualifierCommentPayload = {
  __typename?: 'MeddpiccpaQualifierCommentPayload';
  items?: Maybe<Array<Maybe<MeddpiccpaQualifierComment>>>;
};

export type MeddpiccpaQualifierCommentPayloadResponse =
  | MeddpiccpaQualifierCommentPayload
  | GraphqlError;

export type MeddpiccpaQuery = {
  __typename?: 'MeddpiccpaQuery';
  getMeddpiccpaForMsp: MeddpiccpaPayloadResponse;
  getMeddpiccpaComments?: Maybe<MeddpiccpaQualifierCommentPayloadResponse>;
};

export type MeddpiccpaQueryGetMeddpiccpaForMspArgs = {
  mspId: Scalars['ID'];
};

export type MeddpiccpaQueryGetMeddpiccpaCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type MeddpiccpaResponse = Meddpiccpa | GraphqlError;

export type Media = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  createdBy: UserResponse;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {fileName}`. */
  fileName?: Maybe<Scalars['String']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {fileSize}`. */
  fileSize?: Maybe<Scalars['Int']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {url}`. */
  url: Scalars['URL'];
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {source}`. */
  source?: Maybe<Scalars['String']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {analytics}`. */
  analytics?: Maybe<MediaAnalyticsResponse>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {isInternal}`. */
  isInternal?: Maybe<Scalars['Boolean']>;
};

export type MediaAnalytics = {
  __typename?: 'MediaAnalytics';
  belongsTo?: Maybe<BelongsToResponse>;
  engagementCount?: Maybe<EngagementCountResponse>;
};

export type MediaAnalyticsResponse = MediaAnalytics | GraphqlError;

export type MediaBelongsTo = {
  __typename?: 'MediaBelongsTo';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  objectType: Scalars['String'];
};

export type MediaConnection = {
  __typename?: 'MediaConnection';
  edges?: Maybe<Array<Maybe<MediaEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MediaConnectionResponse = MediaConnection | GraphqlError;

export type MediaContext = {
  __typename?: 'MediaContext';
  isPin?: Maybe<Scalars['Boolean']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
};

export type MediaContextResponse = MediaContext | GraphqlError;

export type MediaEdge = {
  __typename?: 'MediaEdge';
  cursor: Scalars['String'];
  node?: Maybe<MediaResponse>;
};

export type MediaEngagement = {
  __typename?: 'MediaEngagement';
  id: Scalars['String'];
  downloadCount: Scalars['Int'];
  viewCount: Scalars['Int'];
  media?: Maybe<MediaConnectionResponse>;
};

export type MediaEngagementConnection = {
  __typename?: 'MediaEngagementConnection';
  edges?: Maybe<Array<Maybe<MediaEngagementEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MediaEngagementConnectionResponse =
  | MediaEngagementConnection
  | GraphqlError;

export type MediaEngagementEdge = {
  __typename?: 'MediaEngagementEdge';
  node?: Maybe<MediaEngagementResponse>;
};

export type MediaEngagementResponse = MediaEngagement | GraphqlError;

export type MediaExternalLink = {
  __typename?: 'MediaExternalLink';
  url?: Maybe<Scalars['URL']>;
};

export type MediaExternalLinkResponse = MediaExternalLink | GraphqlError;

export type MediaMutation = {
  __typename?: 'MediaMutation';
  createAndAttachMedia: MediaPayloadResponse;
  uploadAndAttachMedia: MediaPayloadResponse;
  updateMedia: MediaPayloadResponse;
  attachMedia: AttachMediaPayloadResponse;
  replaceAttachedMedia: MediaPayloadResponse;
  detachMedia: DetachMediaResponse;
  markMediaInternal: BooleanResponse;
  favoriteMedia: BooleanResponse;
  unfavoriteMedia: BooleanResponse;
  pinMedia: BooleanResponse;
  unpinMedia: BooleanResponse;
  createExternalLink: MediaExternalLinkResponse;
  revokeExternalAccess: BooleanResponse;
};

export type MediaMutationCreateAndAttachMediaArgs = {
  input: CreateAndAttachMediaInput;
};

export type MediaMutationUploadAndAttachMediaArgs = {
  input: UploadAndAttachMediaInput;
};

export type MediaMutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type MediaMutationAttachMediaArgs = {
  mediaIds: Array<Scalars['ID']>;
  input: AttachMediaInput;
};

export type MediaMutationReplaceAttachedMediaArgs = {
  input: ReplaceAttachedMediaInput;
};

export type MediaMutationDetachMediaArgs = {
  input: DetachMediaInput;
};

export type MediaMutationMarkMediaInternalArgs = {
  input: MarkMediaInternalInput;
};

export type MediaMutationFavoriteMediaArgs = {
  input: FavoriteMediaInput;
};

export type MediaMutationUnfavoriteMediaArgs = {
  input: UnfavoriteMediaInput;
};

export type MediaMutationPinMediaArgs = {
  input: PinMediaInput;
};

export type MediaMutationUnpinMediaArgs = {
  input: UnpinMediaInput;
};

export type MediaMutationCreateExternalLinkArgs = {
  input: ExternalLinkInput;
};

export type MediaMutationRevokeExternalAccessArgs = {
  input: RevokeMediaExternalAccessInput;
};

export type MediaPayload = {
  __typename?: 'MediaPayload';
  items?: Maybe<Array<MediaResponse>>;
};

export type MediaPayloadItem = {
  __typename?: 'MediaPayloadItem';
  item: Media;
};

export type MediaPayloadResponse = MediaPayload | GraphqlError;

export type MediaQuery = {
  __typename?: 'MediaQuery';
  listMedia?: Maybe<MediaConnectionResponse>;
  mediaFilters: FilterItemsPayloadResponse;
  myFavoriteMedia?: Maybe<MediaConnectionResponse>;
  pinnedMedia?: Maybe<MediaConnectionResponse>;
  getMediaEngagement?: Maybe<Media>;
};

export type MediaQueryListMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations: Operations;
};

export type MediaQueryMediaFiltersArgs = {
  operations: Operations;
};

export type MediaQueryMyFavoriteMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MediaQueryPinnedMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MediaQueryGetMediaEngagementArgs = {
  mediaId: Scalars['ID'];
};

export type MediaReferenceInput = {
  id: Scalars['ID'];
  referenceType: ObjectType;
  primaryAttachedScope?: Maybe<Scalars['Boolean']>;
  primaryAttachedSource?: Maybe<Scalars['Boolean']>;
};

export enum MediaReferenceType {
  COMMENT = 'COMMENT',
  MILESTONE = 'MILESTONE',
  MSP = 'MSP',
  MSP_SUMMARY = 'MSP_SUMMARY',
  TASK = 'TASK',
}

export type MediaReferences = {
  __typename?: 'MediaReferences';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  referenceType: Scalars['String'];
  primaryAttachedSource?: Maybe<Scalars['Boolean']>;
};

export type MediaReferencesPayloadResponse =
  | MediaReferencestoPayload
  | GraphqlError;

export type MediaReferencesToResponse = MediaReferences | GraphqlError;

export type MediaReferencestoPayload = {
  __typename?: 'MediaReferencestoPayload';
  items?: Maybe<Array<Maybe<MediaReferencesToResponse>>>;
};

export type MediaResponse = MediaPayloadItem | GraphqlError;

export enum MediaType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  LOGO = 'LOGO',
  PDF = 'PDF',
  THUMBNAIL = 'THUMBNAIL',
  VIDEO = 'VIDEO',
  ZIP = 'ZIP',
}

export type MemberMutation = {
  __typename?: 'MemberMutation';
  createAndAssignPlaceholderToRole: AddOrRemovePlaceholderRoleResponse;
  createAndAssignPlaceholdersToRoles: AddOrRemovePlaceholderRoleResponse;
  removePlaceholderFromRole: BooleanResponse;
  removePlaceholdersFromRoles: BooleanResponse;
  createAndAssignStakeholderToRole: UserPayloadResponse;
  addStakeholderToRole: AddOrRemoveStakeholderRoleResponse;
  addStakeholdersToRoles: AddOrRemoveStakeholderRoleResponse;
  replacePlaceholderWithStakeholder: BooleanResponse;
  replaceStakeholder: BooleanResponse;
  removeStakeholderFromRole: BooleanResponse;
  removeStakeholdersFromRoles: BooleanResponse;
  addPrimaryStakeHolder: AddPrimaryStakeholderPayloadResponse;
  removePrimaryStakeholder: BooleanResponse;
  createAndAssignPrimaryStakeHolder: AddPrimaryStakeholderPayloadResponse;
};

export type MemberMutationCreateAndAssignPlaceholderToRoleArgs = {
  input: CreateAndAssignPlaceholderToRoleInput;
};

export type MemberMutationCreateAndAssignPlaceholdersToRolesArgs = {
  input: Array<CreateAndAssignPlaceholderToRoleInput>;
};

export type MemberMutationRemovePlaceholderFromRoleArgs = {
  input: RemovePlaceholderFromRoleInput;
};

export type MemberMutationRemovePlaceholdersFromRolesArgs = {
  input: Array<RemovePlaceholderFromRoleInput>;
};

export type MemberMutationCreateAndAssignStakeholderToRoleArgs = {
  input: CreateAndAssignStakeholderToRoleInput;
};

export type MemberMutationAddStakeholderToRoleArgs = {
  input: AddStakeHolderToRoleInput;
};

export type MemberMutationAddStakeholdersToRolesArgs = {
  input: Array<AddStakeHolderToRoleInput>;
};

export type MemberMutationReplacePlaceholderWithStakeholderArgs = {
  input: ReplacePlaceholderWithStakeholderInput;
};

export type MemberMutationReplaceStakeholderArgs = {
  input: ReplaceStakeholderInput;
};

export type MemberMutationRemoveStakeholderFromRoleArgs = {
  input: RemoveStakeholderFromRoleInput;
};

export type MemberMutationRemoveStakeholdersFromRolesArgs = {
  input: Array<RemoveStakeholderFromRoleInput>;
};

export type MemberMutationAddPrimaryStakeHolderArgs = {
  input: AddPrimaryStakeholderInput;
};

export type MemberMutationRemovePrimaryStakeholderArgs = {
  input: RemovePrimaryStakeholderInput;
};

export type MemberMutationCreateAndAssignPrimaryStakeHolderArgs = {
  input: CreateAndAssignPrimaryStakeholderToRoleInput;
};

export type MemberQuery = {
  __typename?: 'MemberQuery';
  listPlaceholders: PlaceholderConnectionResponse;
  getPrimaryStakeholders: PrimaryStakeHolderResponse;
};

export type MemberQueryListPlaceholdersArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type Members = {
  __typename?: 'Members';
  stakeholders?: Maybe<Array<StakeholderResponse>>;
  placeholders?: Maybe<Array<PlaceholderResponse>>;
};

export type MembersResponse = Members | GraphqlError;

export type MessageData = {
  __typename?: 'MessageData';
  message: Scalars['String'];
  isEdited: Scalars['Boolean'];
  editedAt?: Maybe<Scalars['Timestamp']>;
};

export type MethodologyAnalytics = {
  __typename?: 'MethodologyAnalytics';
  lastUpdatedAt: Scalars['Timestamp'];
  lastSyncedAt?: Maybe<Scalars['Timestamp']>;
  isSynced: Scalars['Boolean'];
  isSyncEnabled: Scalars['Boolean'];
  syncDisableReason?: Maybe<Scalars['String']>;
  firstSyncDone: Scalars['Boolean'];
};

export type MethodologyAnalyticsResponse = MethodologyAnalytics | GraphqlError;

export type MethodologyConfig = {
  __typename?: 'MethodologyConfig';
  isEnabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  templateId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export type MethodologyConfigInput = {
  isEnabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  templateId: Scalars['ID'];
  type?: Maybe<MethodologyTypes>;
};

export type MethodologyPayload = {
  __typename?: 'MethodologyPayload';
  items?: Maybe<Array<MethodologyResponse>>;
};

export type MethodologyQualifierAnalytics = {
  __typename?: 'MethodologyQualifierAnalytics';
  lastUpdatedAt: Scalars['Timestamp'];
  lastSyncedAt?: Maybe<Scalars['Timestamp']>;
  isSynced: Scalars['Boolean'];
};

export type MethodologyQualifierData = {
  id: Scalars['ID'];
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type MethodologyQualifierMetadata = {
  __typename?: 'MethodologyQualifierMetadata';
  isDataEditable?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  defaultData?: Maybe<Scalars['String']>;
  score?: Maybe<MethodologyQualifierScoreSettings>;
};

export type MethodologyQualifierNotes = {
  __typename?: 'MethodologyQualifierNotes';
  value: Scalars['String'];
};

export type MethodologyQualifierScoreOptions = {
  __typename?: 'MethodologyQualifierScoreOptions';
  displayName: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['Int'];
};

export type MethodologyQualifierScoreSettings = {
  __typename?: 'MethodologyQualifierScoreSettings';
  placeholder?: Maybe<Scalars['String']>;
  options?: Maybe<Array<MethodologyQualifierScoreOptions>>;
};

export type MethodologyResponse =
  | Meddic
  | Meddpicc
  | Meddpiccpa
  | ValueSelling
  | GraphqlError;

export type MethodologyStringQualifier = {
  __typename?: 'MethodologyStringQualifier';
  current?: Maybe<MethodologyStringQualifierData>;
  salesforce?: Maybe<MethodologyStringQualifierData>;
  comments?: Maybe<CommentConnectionResponse>;
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  metadata?: Maybe<MethodologyQualifierMetadata>;
};

export type MethodologyStringQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyStringQualifierData';
  id: Scalars['ID'];
  data?: Maybe<Scalars['String']>;
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type MethodologyTaskQualifier = {
  __typename?: 'MethodologyTaskQualifier';
  current?: Maybe<MethodologyTaskQualifierData>;
  salesforce?: Maybe<MethodologyTaskQualifierData>;
  comments?: Maybe<CommentConnectionResponse>;
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  metadata?: Maybe<MethodologyQualifierMetadata>;
};

export type MethodologyTaskQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyTaskQualifierData';
  id: Scalars['ID'];
  data?: Maybe<Array<Maybe<TaskResponse>>>;
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export enum MethodologyTypes {
  MEDDIC = 'MEDDIC',
  MEDDPICC = 'MEDDPICC',
  MEDDPICCPA = 'MEDDPICCPA',
  VALUE_SELLING_CLOSE_PLAN = 'VALUE_SELLING_CLOSE_PLAN',
}

export type MethodologyUserQualifier = {
  __typename?: 'MethodologyUserQualifier';
  current?: Maybe<MethodologyUserQualifierData>;
  salesforce?: Maybe<MethodologyUserQualifierData>;
  comments?: Maybe<CommentConnectionResponse>;
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  metadata?: Maybe<MethodologyQualifierMetadata>;
};

export type MethodologyUserQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyUserQualifierData';
  id: Scalars['ID'];
  data?: Maybe<Array<Maybe<UserResponse>>>;
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type Milestone = IMilestone & {
  __typename?: 'Milestone';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  milestoneDetails?: Maybe<MilestoneDetailsResponse>;
  analytics?: Maybe<MilestoneAnalyticsResponse>;
  tasks: TaskConnectionResponse;
  media?: Maybe<MediaConnectionResponse>;
  createdAt: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  msp?: Maybe<MspResponse>;
};

export type MilestoneTasksArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MilestoneMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MilestoneAnalytics = {
  __typename?: 'MilestoneAnalytics';
  progressStatus?: Maybe<MilestoneTasksProgressStatusResponse>;
  /** @deprecated Use mediaResourceCount */
  mediaCount?: Maybe<IntResponse>;
  mediaResourceCount?: Maybe<BaResourceCountResponse>;
  milestoneTrack?: Maybe<MilestoneTrackStatusResponse>;
};

export type MilestoneAnalyticsResponse = MilestoneAnalytics | GraphqlError;

export type MilestoneConnection = {
  __typename?: 'MilestoneConnection';
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MilestoneConnectionResponse = MilestoneConnection | GraphqlError;

export type MilestoneDetails = {
  __typename?: 'MilestoneDetails';
  milestoneId: Scalars['ID'];
  goals?: Maybe<Scalars['String']>;
  riskDependencies?: Maybe<Scalars['String']>;
  successCriteria?: Maybe<Scalars['String']>;
};

export type MilestoneDetailsInput = {
  goals?: Maybe<Scalars['String']>;
  riskDependencies?: Maybe<Scalars['String']>;
  successCriteria?: Maybe<Scalars['String']>;
};

export type MilestoneDetailsPayload = {
  __typename?: 'MilestoneDetailsPayload';
  items?: Maybe<Array<MilestoneDetailsResponse>>;
};

export type MilestoneDetailsPayloadResponse =
  | MilestoneDetailsPayload
  | GraphqlError;

export type MilestoneDetailsResponse = MilestoneDetails | GraphqlError;

export type MilestoneEdge = {
  __typename?: 'MilestoneEdge';
  cursor: Scalars['String'];
  node?: Maybe<MilestoneResponse>;
};

export type MilestoneHighlights = {
  __typename?: 'MilestoneHighlights';
  activeMilestones?: Maybe<Array<Maybe<AnalyticsMilestone>>>;
};

export type MilestoneMutation = {
  __typename?: 'MilestoneMutation';
  createMilestone: MilestonePayloadResponse;
  createMilestoneV2: MilestonePayloadResponse;
  updateMilestone: MilestonePayloadResponse;
  deleteMilestone: BooleanResponse;
  upsertMilestoneDetails: MilestoneDetailsPayloadResponse;
  cloneMilestone: MilestonePayloadResponse;
  markMilestonesInternal: BooleanResponse;
};

export type MilestoneMutationCreateMilestoneArgs = {
  input: CreateMilestoneInput;
  isTemplate?: Maybe<Scalars['Boolean']>;
};

export type MilestoneMutationCreateMilestoneV2Args = {
  input: CreateMilestoneInput;
};

export type MilestoneMutationUpdateMilestoneArgs = {
  id: Scalars['ID'];
  input: UpdateMilestoneInput;
};

export type MilestoneMutationDeleteMilestoneArgs = {
  id: Scalars['ID'];
};

export type MilestoneMutationUpsertMilestoneDetailsArgs = {
  milestoneId: Scalars['ID'];
  input: MilestoneDetailsInput;
};

export type MilestoneMutationCloneMilestoneArgs = {
  id: Scalars['ID'];
};

export type MilestoneMutationMarkMilestonesInternalArgs = {
  input?: Maybe<MarkMilestoneInternalInput>;
};

export type MilestonePayload = {
  __typename?: 'MilestonePayload';
  items?: Maybe<Array<MilestoneResponse>>;
};

export type MilestonePayloadResponse = MilestonePayload | GraphqlError;

export type MilestoneQuery = {
  __typename?: 'MilestoneQuery';
  listMilestones: MilestoneConnectionResponse;
  getMilestones: MilestonePayloadResponse;
  getMilestoneDetails: MilestoneDetailsPayloadResponse;
  activeMilestones: MilestonePayload;
};

export type MilestoneQueryListMilestonesArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MilestoneQueryGetMilestonesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MilestoneQueryGetMilestoneDetailsArgs = {
  milestoneId: Scalars['ID'];
};

export type MilestoneResponse = Milestone | BaseMilestone | GraphqlError;

export type MilestoneTasksProgressStatus = {
  __typename?: 'MilestoneTasksProgressStatus';
  total: Scalars['Int'];
  completed: Scalars['Int'];
  pending: Scalars['Int'];
  inprogress: Scalars['Int'];
};

export type MilestoneTasksProgressStatusResponse =
  | MilestoneTasksProgressStatus
  | GraphqlError;

export type MilestoneTrack = {
  __typename?: 'MilestoneTrack';
  milestoneTrackStatus: MilestoneTrackStatus;
  dueTasksCount?: Maybe<Scalars['Int']>;
};

export enum MilestoneTrackStatus {
  ON_TRACK = 'ON_TRACK',
  OVER_DUE = 'OVER_DUE',
}

export type MilestoneTrackStatusResponse = MilestoneTrack | GraphqlError;

export type MoveTasksAcrossMilestoneInput = {
  fromMilestone: FromMilestoneInput;
  toMilestone: ToMilestoneInput;
};

export type Msp = IMsp & {
  __typename?: 'Msp';
  id: Scalars['ID'];
  /** @deprecated Use `opportunity=> id`. */
  opportunityId?: Maybe<Scalars['ID']>;
  opportunity?: Maybe<OpportunityResponse>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<ExecutiveSummary>;
  decisionCriteria?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  buyerCompany: CompanyResponse;
  sellerCompany: CompanyResponse;
  isPublished: Scalars['Boolean'];
  isOwner?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `owner`. */
  createdBy?: Maybe<UserResponse>;
  sharedBy?: Maybe<UserResponse>;
  keyDates?: Maybe<MspKeyDatesResponse>;
  startDate: Scalars['Int'];
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  analytics?: Maybe<MspAnalyticsResponse>;
  milestones: MilestoneConnectionResponse;
  subscribers: SubscriberConnectionResponse;
  media?: Maybe<MediaConnectionResponse>;
  usps: UpsPayloadResponse;
  userBridge?: Maybe<MspUserBridgeResponse>;
  isReverseTimeLineEnabled: Scalars['Boolean'];
  timelineOrder?: Maybe<TimelineOrder>;
  owner?: Maybe<UserResponse>;
  timelines?: Maybe<TimelinePayloadResponse>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archivalReason?: Maybe<Scalars['String']>;
};

export type MspAnalyticsArgs = {
  options?: Maybe<GetMspAnalyticsOptions>;
};

export type MspMilestonesArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MspSubscribersArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type MspMediaArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MspAccessScreenData = {
  __typename?: 'MspAccessScreenData';
  buyerCompany: CompanyResponse;
  sellerCompany: CompanyResponse;
};

export type MspAccessScreenDataInput = {
  mspId: Scalars['ID'];
};

export type MspAccessScreenDataResponse = MspAccessScreenData | GraphqlError;

export type MspActivityTimeseries = {
  __typename?: 'MspActivityTimeseries';
  mspId: Scalars['ID'];
  timeline?: Maybe<Array<Maybe<MspActivityTimeseriesData>>>;
  timelineUsers?: Maybe<UserPayloadResponse>;
};

export type MspActivityTimeseriesData = {
  __typename?: 'MspActivityTimeseriesData';
  timestamp: Scalars['Timestamp'];
  activityCount: Scalars['Int'];
  buyerActivityCount?: Maybe<Scalars['Int']>;
  sellerActivityCount?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
  sellerUserIds?: Maybe<Array<Scalars['String']>>;
  buyerUserIds?: Maybe<Array<Scalars['String']>>;
  timeParameterDisplayName: Scalars['String'];
};

export type MspActivityTimeseriesFilterOptions = {
  userType?: Maybe<UserType>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export enum MspActivityTimeseriesGroupByOptions {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type MspActivityTimeseriesQueryOptions = {
  groupBy?: Maybe<MspActivityTimeseriesGroupByOptions>;
  filter?: Maybe<MspActivityTimeseriesFilterOptions>;
};

export type MspActivityTimeseriesResponse =
  | MspActivityTimeseries
  | GraphqlError;

export type MspAnalytics = {
  __typename?: 'MspAnalytics';
  mspId: Scalars['ID'];
  track?: Maybe<MspTrack>;
  mspStage?: Maybe<MspStage>;
  mspStatus?: Maybe<MspTrackStatus>;
  mspProgress?: Maybe<Scalars['Int']>;
  lastActivity?: Maybe<LatestActivity>;
  lastExternalActivity?: Maybe<LatestActivity>;
  /** @deprecated this is moved to meta */
  lastTaskCompletedAt?: Maybe<Scalars['Timestamp']>;
  /** @deprecated this can be read from lastExternalAccess */
  lastExternalAccessedAt?: Maybe<Scalars['Timestamp']>;
  opportunity?: Maybe<AnalyticsOpportunity>;
};

export type MspAnalyticsHighlights = {
  __typename?: 'MspAnalyticsHighlights';
  milestones?: Maybe<MilestoneHighlights>;
  tasks?: Maybe<TaskHighlights>;
  insights?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MspAnalyticsHighlightsResponse =
  | MspAnalyticsHighlights
  | GraphqlError;

export type MspAnalyticsQuery = {
  __typename?: 'MspAnalyticsQuery';
  getMspAnalyticsHighlights: MspAnalyticsHighlightsResponse;
  getMspAnalyticsTimeline: MspAnalyticsTimelineResponse;
  getMspEngagementTimeline: MspEngagementTimelineResponse;
  getMspAnalyticsMetrics: MspMetricsResponse;
  getMspEngagementTimelineUsers: MspEngagementUsersPayloadResponse;
  getMediaEngagement: MediaEngagementConnectionResponse;
  getUserEngagement: UserEngagementConnectionResponse;
  getMspEngagement?: Maybe<MspEngagementDetailsResponse>;
};

export type MspAnalyticsQueryGetMspAnalyticsHighlightsArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspAnalyticsTimelineArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspEngagementTimelineArgs = {
  mspId: Scalars['ID'];
  requestOptions?: Maybe<MspAnalyticsTimelineQueryOptions>;
};

export type MspAnalyticsQueryGetMspAnalyticsMetricsArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspEngagementTimelineUsersArgs = {
  mspId: Scalars['ID'];
  requestOptions: GetMspEngagementUsersRequest;
};

export type MspAnalyticsQueryGetMediaEngagementArgs = {
  mspId: Scalars['ID'];
  requestOptions?: Maybe<GetMediaEngagementRequest>;
};

export type MspAnalyticsQueryGetUserEngagementArgs = {
  mspId: Scalars['ID'];
  requestOptions?: Maybe<GetUserEngagementRequest>;
};

export type MspAnalyticsQueryGetMspEngagementArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsResponse = MspAnalytics | GraphqlError;

export type MspAnalyticsStakeholders = {
  __typename?: 'MspAnalyticsStakeholders';
  stakeholders?: Maybe<Array<Maybe<AnalyticsUser>>>;
  subscribers?: Maybe<Array<Maybe<AnalyticsUser>>>;
};

export type MspAnalyticsStakeholdersResponse =
  | MspAnalyticsStakeholders
  | GraphqlError;

export type MspAnalyticsTimeline = {
  __typename?: 'MspAnalyticsTimeline';
  mspId: Scalars['ID'];
  timeline?: Maybe<Array<Maybe<MspAnalyticsTimelineData>>>;
  lastUpdatedAt?: Maybe<Scalars['Timestamp']>;
  plannedCompletionDate?: Maybe<Scalars['Timestamp']>;
};

export type MspAnalyticsTimelineData = {
  __typename?: 'MspAnalyticsTimelineData';
  timestamp: Scalars['Timestamp'];
  mspProgress?: Maybe<Scalars['Int']>;
  mspSlip?: Maybe<Scalars['Int']>;
  plannedMspProgress?: Maybe<Scalars['Int']>;
  timeParameterDisplayName: Scalars['String'];
};

export type MspAnalyticsTimelineFilterOptions = {
  userType?: Maybe<UserType>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export enum MspAnalyticsTimelineGroupByOptions {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type MspAnalyticsTimelineQueryOptions = {
  groupBy?: Maybe<MspAnalyticsTimelineGroupByOptions>;
  filter?: Maybe<MspAnalyticsTimelineFilterOptions>;
};

export type MspAnalyticsTimelineResponse = MspAnalyticsTimeline | GraphqlError;

export type MspConnection = {
  __typename?: 'MspConnection';
  edges?: Maybe<Array<Maybe<MspEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MspConnectionResponse = MspConnection | GraphqlError;

export type MspEdge = {
  __typename?: 'MspEdge';
  cursor: Scalars['String'];
  node?: Maybe<MspResponse>;
};

export type MspEngagement = {
  __typename?: 'MspEngagement';
  lastActivity?: Maybe<LatestActivity>;
  user?: Maybe<MspEngagementUserDetails>;
  msp?: Maybe<EngagementCount>;
  media?: Maybe<EngagementCount>;
  comment?: Maybe<EngagementCount>;
};

export type MspEngagementDetails = {
  __typename?: 'MspEngagementDetails';
  internalUserEngagement?: Maybe<MspEngagement>;
  externalUserEngagement?: Maybe<MspEngagement>;
};

export type MspEngagementDetailsResponse = MspEngagementDetails | GraphqlError;

export type MspEngagementTimeline = {
  __typename?: 'MspEngagementTimeline';
  mspId: Scalars['ID'];
  timeline?: Maybe<Array<Maybe<MspEngagementTimelineData>>>;
};

export type MspEngagementTimelineData = {
  __typename?: 'MspEngagementTimelineData';
  timestamp: Scalars['Timestamp'];
  engagementCount: Scalars['Int'];
  buyerEngagementCount?: Maybe<Scalars['Int']>;
  sellerEngagementCount?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
  sellerUserIds?: Maybe<Array<Scalars['String']>>;
  buyerUserIds?: Maybe<Array<Scalars['String']>>;
  timeParameterDisplayName: Scalars['String'];
};

export type MspEngagementTimelineResponse =
  | MspEngagementTimeline
  | GraphqlError;

export type MspEngagementUserDetails = {
  __typename?: 'MspEngagementUserDetails';
  count?: Maybe<Scalars['Int']>;
  engagementUsers?: Maybe<MspEngagmentUsersLatestActivityResponse>;
};

export type MspEngagementUsers = {
  __typename?: 'MspEngagementUsers';
  userId: Scalars['String'];
  user?: Maybe<UserResponse>;
};

export type MspEngagementUsersPayload = {
  __typename?: 'MspEngagementUsersPayload';
  items?: Maybe<Array<MspEngagementUsersRepsonse>>;
};

export type MspEngagementUsersPayloadResponse =
  | MspEngagementUsersPayload
  | GraphqlError;

export type MspEngagementUsersRepsonse = MspEngagementUsers | GraphqlError;

export type MspEngagmentUsersLatestActivity = {
  __typename?: 'MspEngagmentUsersLatestActivity';
  items?: Maybe<Array<UserActivity>>;
};

export type MspEngagmentUsersLatestActivityResponse =
  | MspEngagmentUsersLatestActivity
  | GraphqlError;

export type MspId = Node & {
  __typename?: 'MspId';
  id: Scalars['ID'];
};

export type MspIdResponse = MspId | GraphqlError;

export type MspInvite = UserIdMap | GraphqlError;

export type MspInviteMutation = {
  __typename?: 'MspInviteMutation';
  invite: MspInvitePayloadResponse;
  updatePermission: BooleanResponse;
  revokePermission: BooleanResponse;
};

export type MspInviteMutationInviteArgs = {
  input: InviteInput;
};

export type MspInviteMutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MspInviteMutationRevokePermissionArgs = {
  input: RevokePermissionInput;
};

export type MspInvitePayloadResponse = MspInviteResponse | GraphqlError;

export enum MspInvitePermission {
  ALL = 'ALL',
  COMMENT = 'COMMENT',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  NONE = 'NONE',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export type MspInviteResponse = {
  __typename?: 'MspInviteResponse';
  items?: Maybe<Array<Maybe<MspInvite>>>;
};

export type MspKeyDates = {
  __typename?: 'MspKeyDates';
  goLiveDate?: Maybe<Scalars['Int']>;
  contractSignatureDate?: Maybe<Scalars['Int']>;
  vendorChoiceDate?: Maybe<Scalars['Int']>;
};

export type MspKeyDatesResponse = MspKeyDates | GraphqlError;

export type MspMetrics = {
  __typename?: 'MspMetrics';
  mspId?: Maybe<Scalars['String']>;
  mspTrack?: Maybe<MspTrack>;
  lastActivity?: Maybe<AnalyticsActivity>;
  lastExternalActivity?: Maybe<AnalyticsActivity>;
  externalStakeholders?: Maybe<MspAnalyticsStakeholdersResponse>;
};

export type MspMetricsResponse = MspMetrics | GraphqlError;

export type MspMutation = {
  __typename?: 'MspMutation';
  createMsp: MspPayloadResponse;
  updateMsp: MspPayloadResponse;
  deleteMsp: BooleanResponse;
  toggleMspsArchivalStatus: ToggleMspsArchivalResponse;
  publishMsp: BooleanResponse;
  linkOpportunity: BooleanResponse;
  transferOwnership: BooleanResponse;
};

export type MspMutationCreateMspArgs = {
  input: CreateMspInput;
  isTemplate?: Maybe<Scalars['Boolean']>;
};

export type MspMutationUpdateMspArgs = {
  id: Scalars['ID'];
  input: UpdateMspInput;
};

export type MspMutationDeleteMspArgs = {
  id: Scalars['ID'];
};

export type MspMutationToggleMspsArchivalStatusArgs = {
  input: ArchiveMspInput;
};

export type MspMutationPublishMspArgs = {
  id: Scalars['ID'];
};

export type MspMutationLinkOpportunityArgs = {
  input: LinkOpportunityInput;
};

export type MspMutationTransferOwnershipArgs = {
  id: Scalars['ID'];
  input: TransferOwnershipInput;
};

export type MspPayload = {
  __typename?: 'MspPayload';
  items?: Maybe<Array<MspResponse>>;
};

export type MspPayloadResponse = MspPayload | GraphqlError;

export type MspPermissionPayload = {
  __typename?: 'MspPermissionPayload';
  items: Array<MspInvitePermission>;
};

export type MspPermissionResponse = MspPermissionPayload | GraphqlError;

export type MspPublishStatus = {
  __typename?: 'MspPublishStatus';
  drafts: Scalars['Int'];
  published: Scalars['Int'];
};

export type MspPublishStatusResponse = MspPublishStatus | GraphqlError;

export type MspQuery = {
  __typename?: 'MspQuery';
  getMsp: MspPayloadResponse;
  listMsps: MspConnectionResponse;
  searchUsersInMsp: UserConnectionResponse;
  getMspIdForOpportunity: MspIdResponse;
  getMspByExtOpportunityId: MspResponse;
  getUserReferences: UserReferencesPayloadResponse;
  searchMspsFromEmails: MspsWithEmailsPayloadResponse;
  listMspsToLinkInCRM: MspConnectionResponse;
  mspAccessScreenData: MspAccessScreenDataResponse;
  listMspArchivalReasons: FilterItemsPayloadResponse;
};

export type MspQueryGetMspArgs = {
  id: Scalars['ID'];
};

export type MspQueryListMspsArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MspQuerySearchUsersInMspArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
  scope?: Maybe<RelationshipScopeInput>;
};

export type MspQueryGetMspIdForOpportunityArgs = {
  oppId: Scalars['ID'];
};

export type MspQueryGetMspByExtOpportunityIdArgs = {
  extId: Scalars['ID'];
};

export type MspQueryGetUserReferencesArgs = {
  operations?: Maybe<Operations>;
};

export type MspQuerySearchMspsFromEmailsArgs = {
  emails: Array<Scalars['EmailAddress']>;
};

export type MspQueryListMspsToLinkInCrmArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type MspQueryMspAccessScreenDataArgs = {
  input?: Maybe<MspAccessScreenDataInput>;
};

export type MspResponse = Msp | BaseMsp | GraphqlError;

export enum MspStage {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum MspSubscriberStatus {
  ADDED = 'ADDED',
  INVITED = 'INVITED',
  OWNER = 'OWNER',
}

export type MspTrack = {
  __typename?: 'MspTrack';
  mspTrackStatus: MspTrackStatus;
  behindBy?: Maybe<Scalars['Timestamp']>;
  mspSlip?: Maybe<Scalars['Int']>;
  mspSlipDisplayTime?: Maybe<Scalars['String']>;
};

export type MspTrackCounts = {
  __typename?: 'MspTrackCounts';
  total: Scalars['Int'];
  onTrack: Scalars['Int'];
  offTrack: Scalars['Int'];
};

export type MspTrackCountsResponse = MspTrackCounts | GraphqlError;

export enum MspTrackStatus {
  OFF_TRACK = 'OFF_TRACK',
  ON_TRACK = 'ON_TRACK',
  UNKNOWN = 'UNKNOWN',
}

export type MspUserBridge = {
  __typename?: 'MspUserBridge';
  permissions: MspPermissionResponse;
  role?: Maybe<Roles>;
};

export type MspUserBridgeResponse = MspUserBridge | GraphqlError;

export type MspWithEmail = {
  __typename?: 'MspWithEmail';
  email: Scalars['String'];
  msps: Msp;
};

export type MspsWithEmails = {
  __typename?: 'MspsWithEmails';
  items: Array<MspWithEmail>;
};

export type MspsWithEmailsPayloadResponse = MspsWithEmails | GraphqlError;

export type MutateTimelineStepsInput = {
  createTimelineSteps?: Maybe<Array<CreateTimelineStepsInput>>;
  updateTimelineSteps?: Maybe<Array<UpdateTimelineStepsInput>>;
  deleteTimelineSteps?: Maybe<DeleteTimelineStepsInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<MediaMutation>;
  organizationMutation?: Maybe<OrganizationMutation>;
  userMutation?: Maybe<UserMutation>;
  templateMutation?: Maybe<TemplateMutation>;
  annotationMutation?: Maybe<AnnotationMutation>;
  mspMutation?: Maybe<MspMutation>;
  milestoneMutation?: Maybe<MilestoneMutation>;
  taskMutation?: Maybe<TaskMutation>;
  mspInviteMutation?: Maybe<MspInviteMutation>;
  uspMutation?: Maybe<UspMutation>;
  roleMutation?: Maybe<RoleMutation>;
  memberMutation?: Maybe<MemberMutation>;
  meddicMutation?: Maybe<MeddicMutation>;
  meddpiccMutation?: Maybe<MeddpiccMutation>;
  meddpiccpaMutation?: Maybe<MeddpiccpaMutation>;
  valueSellingMutation?: Maybe<ValueSellingMutation>;
  preferenceMutation?: Maybe<PreferenceMutation>;
  timelineMutation?: Maybe<TimelineMutation>;
  opportunityMutation?: Maybe<OpportunityMutation>;
  pushNotificationMutation?: Maybe<PushNotificationMutation>;
  relationshipMutation?: Maybe<RelationshipMutation>;
  contactRoleMutation?: Maybe<ContactRoleMutation>;
  companyMutation?: Maybe<CompanyMutation>;
  lovItemMutation?: Maybe<LovItemMutation>;
  accountPlanMutation?: Maybe<AccountPlanMutation>;
  contactMutation?: Maybe<ContactMutation>;
  managerMutation?: Maybe<ManagerMutation>;
};

export type Node = {
  id: Scalars['ID'];
};

export type Notes = Annotation & {
  __typename?: 'Notes';
  id: Scalars['ID'];
  reference: Reference;
  references?: Maybe<Array<Maybe<AnnotationReferenceResponse>>>;
  annotationType: AnnotationType;
  scope?: Maybe<AnnotationScope>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  messageData: MessageData;
  comments?: Maybe<CommentConnectionResponse>;
  data?: Maybe<Scalars['String']>;
};

export type NotesCommentsArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type NotesPayload = {
  __typename?: 'NotesPayload';
  items?: Maybe<Array<NotesResponse>>;
};

export type NotesPayloadResponse = NotesPayload | GraphqlError;

export type NotesResponse = Notes | GraphqlError;

export type NotificationChannelPreference = {
  __typename?: 'NotificationChannelPreference';
  type: NotificationChannels;
  isEnabled: Scalars['Boolean'];
};

export type NotificationChannelPreferenceInput = {
  type: NotificationChannels;
  isEnabled: Scalars['Boolean'];
};

export type NotificationChannelPreferencePayload = {
  __typename?: 'NotificationChannelPreferencePayload';
  items: Array<NotificationPreferenceResponse>;
};

export type NotificationChannelPreferencePayloadResponse =
  | NotificationChannelPreferencePayload
  | GraphqlError;

export type NotificationChannelPreferenceResponse = {
  __typename?: 'NotificationChannelPreferenceResponse';
  status: Scalars['Boolean'];
  type: NotificationChannels;
  message?: Maybe<Scalars['String']>;
};

export enum NotificationChannels {
  APP_PUSH = 'APP_PUSH',
  EMAIL = 'EMAIL',
  SLACK = 'SLACK',
}

export type NotificationEntityPreference = {
  __typename?: 'NotificationEntityPreference';
  entityType: NotificationEntityType;
  entityId: Scalars['String'];
  isNotificationEnabled: Scalars['Boolean'];
};

export type NotificationEntityPreferenceInput = {
  entityType: NotificationEntityType;
  entityId: Scalars['String'];
  isNotificationEnabled: Scalars['Boolean'];
};

export enum NotificationEntityType {
  MSP = 'MSP',
}

export enum NotificationEventType {
  COMMENTS_ADDED = 'COMMENTS_ADDED',
  MANAGER_DAILY_DIGEST = 'MANAGER_DAILY_DIGEST',
  MANAGER_DASHBOARD_DIGEST = 'MANAGER_DASHBOARD_DIGEST',
  MSP_ENGAGEMENT = 'MSP_ENGAGEMENT',
  MSP_INVITE = 'MSP_INVITE',
  MSP_TRACK = 'MSP_TRACK',
  NOTIFICATION_EVENT = 'NOTIFICATION_EVENT',
  OWNER_DAILY_DIGEST = 'OWNER_DAILY_DIGEST',
  ROLE_ASSIGNED = 'ROLE_ASSIGNED',
  TASK_ASSIGNED = 'TASK_ASSIGNED',
  TASK_DUE_DATE_UPDATED = 'TASK_DUE_DATE_UPDATED',
  USER_DASHBOARD_DIGEST = 'USER_DASHBOARD_DIGEST',
  USER_MSP_DIGEST = 'USER_MSP_DIGEST',
}

export type NotificationInput = {
  notify: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  copyMe: Scalars['Boolean'];
};

export type NotificationPreferenceEntity = {
  __typename?: 'NotificationPreferenceEntity';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  scopeType: NotificationScopeType;
  scopeId: Scalars['ID'];
  event: Scalars['String'];
  preferences: NotificationScopePreference;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  version?: Maybe<Scalars['Int']>;
};

export type NotificationPreferenceEntityPayload = {
  __typename?: 'NotificationPreferenceEntityPayload';
  items: Array<NotificationPreferenceEntityResponse>;
};

export type NotificationPreferenceEntityPayloadResponse =
  | NotificationPreferenceEntityPayload
  | GraphqlError;

export type NotificationPreferenceEntityResponse =
  | NotificationPreferenceEntity
  | GraphqlError;

export type NotificationPreferenceRequest = {
  scopes: Array<NotificationPreferenceScopeInput>;
  event: Scalars['String'];
};

export type NotificationPreferenceResponse =
  | NotificationChannelPreferenceResponse
  | GraphqlError;

export type NotificationPreferenceScopeInput = {
  scopeType: NotificationScopeType;
  scopeId: Scalars['String'];
};

export type NotificationScopePreference = {
  __typename?: 'NotificationScopePreference';
  channels?: Maybe<Array<Maybe<NotificationChannelPreference>>>;
  entity?: Maybe<Array<Maybe<NotificationEntityPreference>>>;
};

export type NotificationScopePreferenceInput = {
  channels: Array<NotificationChannelPreferenceInput>;
  entity?: Maybe<Array<Maybe<NotificationEntityPreferenceInput>>>;
};

export enum NotificationScopeType {
  COMPANY = 'COMPANY',
  MSP = 'MSP',
  ORG = 'ORG',
  USER = 'USER',
}

export enum NotificationStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  READ = 'READ',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export enum ObjectForContactSource {
  CONTACT = 'CONTACT',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
}

export enum ObjectForSource {
  ACCOUNT = 'ACCOUNT',
  COMPANY = 'COMPANY',
  ORGANIZATION = 'ORGANIZATION',
}

export enum ObjectType {
  COMMENT = 'COMMENT',
  COMPANY = 'COMPANY',
  MILESTONE = 'MILESTONE',
  MSP = 'MSP',
  MSP_SUMMARY = 'MSP_SUMMARY',
  TASK = 'TASK',
}

export enum Operation {
  EQ = 'EQ',
  EXISTS = 'EXISTS',
  GE = 'GE',
  GT = 'GT',
  IN = 'IN',
  LE = 'LE',
  LIKE = 'LIKE',
  LT = 'LT',
  MATCH = 'MATCH',
  NE = 'NE',
  NIN = 'NIN',
  NULL = 'NULL',
  SEARCH = 'SEARCH',
}

export type Operations = {
  filter?: Maybe<FiltersExpression>;
  sort?: Maybe<Array<SortExpression>>;
};

export enum Operator {
  AGGREGATION = 'AGGREGATION',
  AND = 'AND',
  OR = 'OR',
}

export type Opportunity = {
  __typename?: 'Opportunity';
  id: Scalars['ID'];
  orgId: Scalars['String'];
  accountId: Scalars['String'];
  contactId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  closeDate?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isWon?: Maybe<Scalars['Boolean']>;
  forecastCategory?: Maybe<Scalars['String']>;
  forecastCategoryName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  deletedAt?: Maybe<Scalars['Timestamp']>;
  fiscal?: Maybe<Scalars['String']>;
  sourceSystem?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
  intOwnerId?: Maybe<Scalars['String']>;
  extOwnerId?: Maybe<Scalars['String']>;
  intCompanyId?: Maybe<Scalars['String']>;
  extCompanyId?: Maybe<Scalars['String']>;
  originalSource: IOriginalSourceObject;
};

export type OpportunityConnection = {
  __typename?: 'OpportunityConnection';
  edges?: Maybe<Array<Maybe<OpportunityEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OpportunityConnectionResponse =
  | OpportunityConnection
  | GraphqlError;

export type OpportunityCountsAtStage = {
  __typename?: 'OpportunityCountsAtStage';
  stageName: Scalars['String'];
  counts: Scalars['Int'];
};

export type OpportunityCountsAtStagePayload = {
  __typename?: 'OpportunityCountsAtStagePayload';
  items?: Maybe<Array<OpportunityCountsAtStageResponse>>;
};

export type OpportunityCountsAtStagePayloadResponse =
  | OpportunityCountsAtStagePayload
  | GraphqlError;

export type OpportunityCountsAtStageResponse =
  | OpportunityCountsAtStage
  | GraphqlError;

export type OpportunityEdge = {
  __typename?: 'OpportunityEdge';
  cursor: Scalars['String'];
  node?: Maybe<OpportunityResponse>;
};

export enum OpportunityForType {
  CO_OWNER = 'CO_OWNER',
  OWNER = 'OWNER',
}

export type OpportunityMutation = {
  __typename?: 'OpportunityMutation';
  createOpportunity: OpportunityResponse;
  updateOpportunity: OpportunityResponse;
  deleteOpportunity: BooleanResponse;
};

export type OpportunityMutationCreateOpportunityArgs = {
  input: CreateOpportunityInput;
};

export type OpportunityMutationUpdateOpportunityArgs = {
  id: Scalars['String'];
  input: UpdateOpportunityInput;
};

export type OpportunityMutationDeleteOpportunityArgs = {
  id: Scalars['ID'];
};

export type OpportunityQuery = {
  __typename?: 'OpportunityQuery';
  getOpportunity: OpportunityResponse;
  listOpportunity: OpportunityConnectionResponse;
  searchOpportunity: OpportunityConnectionResponse;
  getOpportunityForOwnerAndCoOwner: OpportunityConnectionResponse;
};

export type OpportunityQueryGetOpportunityArgs = {
  id: Scalars['ID'];
};

export type OpportunityQueryListOpportunityArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type OpportunityQuerySearchOpportunityArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type OpportunityQueryGetOpportunityForOwnerAndCoOwnerArgs = {
  input: GetOpportunityForOwnerAndCoOwnerInput;
};

export type OpportunityResponse = Opportunity | GraphqlError;

export type OrgConfig = {
  __typename?: 'OrgConfig';
  others?: Maybe<Scalars['JSON']>;
  salesforce?: Maybe<SalesforceConfig>;
  branding?: Maybe<BrandingConfig>;
  methodology?: Maybe<MethodologyConfig>;
};

export type OrgConfigInput = {
  others?: Maybe<Scalars['JSON']>;
  salesforce?: Maybe<SalesforceConfigInput>;
  branding?: Maybe<BrandingConfigInput>;
  methodology?: Maybe<MethodologyConfigInput>;
};

export type OrgLogo = {
  __typename?: 'OrgLogo';
  original?: Maybe<Scalars['URL']>;
  favicon?: Maybe<Scalars['URL']>;
};

export type OrgLogoInput = {
  original?: Maybe<Scalars['URL']>;
  favicon?: Maybe<Scalars['URL']>;
};

export enum OrgType {
  CUSTOMER = 'CUSTOMER',
  DEV = 'DEV',
  PROD = 'PROD',
  TEST = 'TEST',
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  orgName: Scalars['String'];
  orgLogo?: Maybe<OrgLogo>;
  orgType: OrgType;
  configs: OrgConfig;
  state: CompanyState;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type OrganizationMutation = {
  __typename?: 'OrganizationMutation';
  createOrganization: OrganizationPayloadResponse;
  updateOrganization: OrganizationPayloadResponse;
};

export type OrganizationMutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type OrganizationMutationUpdateOrganizationArgs = {
  orgId: Scalars['ID'];
  input: UpdateOrganizationInput;
};

export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  items?: Maybe<Array<OrganizationResponse>>;
};

export type OrganizationPayloadResponse = OrganizationPayload | GraphqlError;

export type OrganizationQuery = {
  __typename?: 'OrganizationQuery';
  getOrganization: OrganizationPayloadResponse;
};

export type OrganizationQueryGetOrganizationArgs = {
  orgId: Scalars['ID'];
};

export type OrganizationResponse = Organization | GraphqlError;

export type OriginalSourceForCompanyInput = {
  id?: Maybe<Scalars['String']>;
  system: SystemForSource;
  object: ObjectForSource;
};

export type OriginalSourceForCompanyType = {
  __typename?: 'OriginalSourceForCompanyType';
  id?: Maybe<Scalars['String']>;
  system: SystemForSource;
  object: ObjectForSource;
};

export type OriginalSourceForContact = {
  __typename?: 'OriginalSourceForContact';
  id?: Maybe<Scalars['String']>;
  object: ObjectForContactSource;
  system: SystemForContactSource;
};

export type OriginalSourceForContactInput = {
  id?: Maybe<Scalars['String']>;
  object: ObjectForContactSource;
  system: SystemForContactSource;
};

export type OriginalSourceJson = {
  __typename?: 'OriginalSourceJson';
  id?: Maybe<Scalars['String']>;
  source: SourceType;
};

export type OriginalSourceJsonInput = {
  id: Scalars['String'];
  source: SourceType;
};

export enum OriginalSourceSystem {
  HUBSPOT = 'HUBSPOT',
  INTERNAL = 'INTERNAL',
  PIPEDRIVE = 'PIPEDRIVE',
  SALESFORCE = 'SALESFORCE',
  UNKNOWN = 'UNKNOWN',
  ZAPIER = 'ZAPIER',
}

export type OtherContactInput = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
  scope?: Maybe<RelationshipScopeInput>;
};

export type PageConfiguration = {
  limit?: Maybe<Scalars['Int']>;
  afterCursor?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  afterCursor?: Maybe<Scalars['String']>;
  beforeCursor?: Maybe<Scalars['String']>;
};

export type PinMediaInput = {
  mediaIds: Array<Scalars['ID']>;
  contextId: Scalars['ID'];
};

export type Placeholder = {
  __typename?: 'Placeholder';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  companyType?: Maybe<CompanyType>;
};

export type PlaceholderConnection = {
  __typename?: 'PlaceholderConnection';
  edges?: Maybe<Array<Maybe<PlaceholderEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PlaceholderConnectionResponse =
  | PlaceholderConnection
  | GraphqlError;

export type PlaceholderEdge = {
  __typename?: 'PlaceholderEdge';
  cursor: Scalars['String'];
  node?: Maybe<PlaceholderResponse>;
};

export type PlaceholderInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PlaceholderResponse = Placeholder | GraphqlError;

export type PreferenceMutation = {
  __typename?: 'PreferenceMutation';
  createPreferences: NotificationPreferenceEntityPayloadResponse;
};

export type PreferenceMutationCreatePreferencesArgs = {
  input: CreateNotificationPreferenceRequestBody;
};

export type PreferenceQuery = {
  __typename?: 'PreferenceQuery';
  getPreferences: NotificationChannelPreferencePayloadResponse;
};

export type PreferenceQueryGetPreferencesArgs = {
  input: NotificationPreferenceRequest;
};

export type PrimaryStakeHolderResponse = PrimaryStakeholder | GraphqlError;

export type PrimaryStakeholder = {
  __typename?: 'PrimaryStakeholder';
  buyerStakeholders?: Maybe<Array<Maybe<StakeholderResponse>>>;
  sellerStakeholders?: Maybe<Array<Maybe<StakeholderResponse>>>;
};

export type PushNotification = {
  __typename?: 'PushNotification';
  notificationType: NotificationEventType;
  account: PushNotificationAccount;
  userNotificationStatusId: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  link: Scalars['String'];
  status: Scalars['String'];
  authorizer: PushNotificationAuthorizerDetails;
  metaData?: Maybe<PushNotificationMetaData>;
};

export type PushNotificationAccount = {
  __typename?: 'PushNotificationAccount';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PushNotificationAccountDetails = {
  __typename?: 'PushNotificationAccountDetails';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  unreadCount: Scalars['Int'];
};

export type PushNotificationAccountDetailsPayload = {
  __typename?: 'PushNotificationAccountDetailsPayload';
  items: Array<PushNotificationAccountDetailsRepsonse>;
};

export type PushNotificationAccountDetailsPayloadResponse =
  | PushNotificationAccountDetailsPayload
  | GraphqlError;

export type PushNotificationAccountDetailsRepsonse =
  | PushNotificationAccountDetails
  | GraphqlError;

export type PushNotificationAuthorizerDetails = {
  __typename?: 'PushNotificationAuthorizerDetails';
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PushNotificationConnection = {
  __typename?: 'PushNotificationConnection';
  edges?: Maybe<Array<Maybe<PushNotificationEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
  newCount?: Maybe<Scalars['Int']>;
};

export type PushNotificationConnectionResponse =
  | PushNotificationConnection
  | GraphqlError;

export type PushNotificationEdge = {
  __typename?: 'PushNotificationEdge';
  cursor: Scalars['String'];
  node?: Maybe<PushNotificationResponse>;
};

export type PushNotificationMetaData = {
  __typename?: 'PushNotificationMetaData';
  additionalMessage?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<PushNotificationMetaDataResources>>;
  references?: Maybe<Array<PushNotificationMetaDataReferences>>;
};

export type PushNotificationMetaDataReferences = {
  __typename?: 'PushNotificationMetaDataReferences';
  id: Scalars['String'];
  type: BaResource;
};

export type PushNotificationMetaDataResources = {
  __typename?: 'PushNotificationMetaDataResources';
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type PushNotificationMutation = {
  __typename?: 'PushNotificationMutation';
  updatePushNotificationStatus: BooleanResponse;
  bulkUpdatePushNotificationStatus: BooleanResponse;
};

export type PushNotificationMutationUpdatePushNotificationStatusArgs = {
  input: UpdateNotificationStatusRequestBody;
};

export type PushNotificationMutationBulkUpdatePushNotificationStatusArgs = {
  input: BulkUpdateNotificationStatusRequestBody;
};

export type PushNotificationQuery = {
  __typename?: 'PushNotificationQuery';
  getPushNotifications: PushNotificationConnectionResponse;
  pollNotifications: PushNotificationConnectionResponse;
  getNotificationAccounts: PushNotificationAccountDetailsPayloadResponse;
};

export type PushNotificationQueryGetPushNotificationsArgs = {
  input: GetPushNotificationRequestBody;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type PushNotificationQueryPollNotificationsArgs = {
  userId: Scalars['ID'];
};

export type PushNotificationQueryGetNotificationAccountsArgs = {
  userId: Scalars['ID'];
};

export type PushNotificationResponse = PushNotification | GraphqlError;

export type Query = {
  __typename?: 'Query';
  health?: Maybe<HealthStatus>;
  organizationQuery?: Maybe<OrganizationQuery>;
  opportunityQuery?: Maybe<OpportunityQuery>;
  templateQuery?: Maybe<TemplateQuery>;
  userQuery?: Maybe<UserQuery>;
  alignxQuery?: Maybe<AlignxQuery>;
  mspQuery?: Maybe<MspQuery>;
  milestoneQuery?: Maybe<MilestoneQuery>;
  taskQuery?: Maybe<TaskQuery>;
  mediaQuery?: Maybe<MediaQuery>;
  roleQuery?: Maybe<RoleQuery>;
  companyQuery?: Maybe<CompanyQuery>;
  meddicQuery?: Maybe<MeddicQuery>;
  meddpiccQuery?: Maybe<MeddpiccQuery>;
  meddpiccpaQuery?: Maybe<MeddpiccpaQuery>;
  valueSellingQuery?: Maybe<ValueSellingQuery>;
  memberQuery?: Maybe<MemberQuery>;
  preferenceQuery?: Maybe<PreferenceQuery>;
  pushNotificationQuery?: Maybe<PushNotificationQuery>;
  activityTimelineQuery?: Maybe<ActivityTimelineQuery>;
  mspAnalyticsQuery?: Maybe<MspAnalyticsQuery>;
  annotationQuery?: Maybe<AnnotationQuery>;
  relationshipQuery?: Maybe<RelationshipQuery>;
  structureQuery?: Maybe<StructureQuery>;
  lovItemQuery?: Maybe<LovItemQuery>;
  contactRoleQuery?: Maybe<ContactRoleQuery>;
  accountPlanQuery?: Maybe<AccountPlanQuery>;
  contactQuery?: Maybe<ContactQuery>;
  managerQuery?: Maybe<ManagerQuery>;
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  type: ReferenceType;
};

export type ReferenceInput = {
  id: Scalars['ID'];
  type: ReferenceType;
};

export enum ReferenceType {
  ACCOUNT_PLAN = 'ACCOUNT_PLAN',
  COMMENTS = 'COMMENTS',
  EDGE = 'EDGE',
  METHODOLOGY_QUALIFIER = 'METHODOLOGY_QUALIFIER',
  MSP = 'MSP',
  NOTES = 'NOTES',
  TASK = 'TASK',
  USER = 'USER',
}

export type RelationshipContact = IRelationshipBaseItem & {
  __typename?: 'RelationshipContact';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  objectType?: Maybe<RelationshipObjectType>;
  stakeholderRoles?: Maybe<RolePayloadResponse>;
  contactRoles?: Maybe<ContactPayloadResponse>;
  notes?: Maybe<NotesPayload>;
  reportTo?: Maybe<UserPayloadResponse>;
  strength?: Maybe<RelationshipContactStrengthResponse>;
  sentiments?: Maybe<RelationshipContactSentimentsPayloadResponse>;
  assignees?: Maybe<UserPayloadResponse>;
  isKeyStakeholder?: Maybe<BooleanResponse>;
  inviteStatus?: Maybe<RelationshipInviteDataResponse>;
  linkedinData?: Maybe<LinkedinDataResponse>;
  avatar?: Maybe<Avatar>;
  buyerCompany?: Maybe<CompanyResponse>;
  sellerCompany?: Maybe<CompanyResponse>;
};

export type RelationshipContactSentiments = {
  __typename?: 'RelationshipContactSentiments';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RelationshipContactSentimentsPayload = {
  __typename?: 'RelationshipContactSentimentsPayload';
  items?: Maybe<Array<RelationshipContactSentimentsResponse>>;
};

export type RelationshipContactSentimentsPayloadResponse =
  | RelationshipContactSentimentsPayload
  | GraphqlError;

export type RelationshipContactSentimentsResponse =
  | RelationshipContactSentiments
  | GraphqlError;

export type RelationshipContactStrength = {
  __typename?: 'RelationshipContactStrength';
  id?: Maybe<Scalars['ID']>;
  displayValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type RelationshipContactStrengthResponse =
  | RelationshipContactStrength
  | GraphqlError;

export type RelationshipGraph = {
  __typename?: 'RelationshipGraph';
  id?: Maybe<Scalars['ID']>;
  scope?: Maybe<SearchRelationshipMapsScope>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  nodes?: Maybe<Array<Maybe<GraphNode>>>;
  edges?: Maybe<Array<Maybe<GraphEdge>>>;
  viewport?: Maybe<Scalars['JSON']>;
  analytics?: Maybe<RelationshipGraphAnalyticsResponse>;
};

export type RelationshipGraphAnalytics = {
  __typename?: 'RelationshipGraphAnalytics';
  contactsCount?: Maybe<Scalars['Int']>;
  nodesCount?: Maybe<Scalars['Int']>;
};

export type RelationshipGraphAnalyticsResponse =
  | RelationshipGraphAnalytics
  | GraphqlError;

export enum RelationshipGraphFetchType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export type RelationshipGraphqlPayload = {
  __typename?: 'RelationshipGraphqlPayload';
  edges?: Maybe<Array<Maybe<RelationshipGraphqlResponse>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RelationshipGraphqlPayloadResponse =
  | RelationshipGraphqlPayload
  | GraphqlError;

export type RelationshipGraphqlResponse = RelationshipGraph | GraphqlError;

export type RelationshipInviteData = {
  __typename?: 'RelationshipInviteData';
  lastActivityAt?: Maybe<Scalars['Int']>;
  isInvited?: Maybe<Scalars['Boolean']>;
};

export type RelationshipInviteDataResponse =
  | RelationshipInviteData
  | GraphqlError;

export type RelationshipItemConnection = {
  __typename?: 'RelationshipItemConnection';
  edges?: Maybe<Array<Maybe<RelationshipItemEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RelationshipItemConnectionResponse =
  | RelationshipItemConnection
  | GraphqlError;

export type RelationshipItemEdge = {
  __typename?: 'RelationshipItemEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IRelationshipBaseItem>;
};

export type RelationshipItemPayload = {
  __typename?: 'RelationshipItemPayload';
  items?: Maybe<Array<IRelationshipBaseItem>>;
};

export type RelationshipItemPayloadResponse =
  | RelationshipItemPayload
  | GraphqlError;

export type RelationshipItemsAssigneeFieldValue =
  | RelationshipItemsFieldValue
  | GraphqlError;

export type RelationshipItemsFieldAssigneeValueConnection = {
  __typename?: 'RelationshipItemsFieldAssigneeValueConnection';
  edges?: Maybe<Array<Maybe<RelationshipItemsFieldAssigneeValueEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RelationshipItemsFieldAssigneeValueEdge = {
  __typename?: 'RelationshipItemsFieldAssigneeValueEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RelationshipItemsAssigneeFieldValue>;
};

export type RelationshipItemsFieldValue = {
  __typename?: 'RelationshipItemsFieldValue';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<RelationshipItemsFieldValueMetaData>;
};

export type RelationshipItemsFieldValueMetaData = {
  __typename?: 'RelationshipItemsFieldValueMetaData';
  color?: Maybe<Scalars['JSON']>;
};

export type RelationshipItemsFieldValuePayload = {
  __typename?: 'RelationshipItemsFieldValuePayload';
  stakeholderRoles?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  contactRoles?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  strength?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  sentiments?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  assignees?: Maybe<RelationshipItemsFieldAssigneeValueConnection>;
};

export type RelationshipItemsFieldValuePayloadAssigneesArgs = {
  input?: Maybe<RelationshipItemsFieldValuePayloadAssigneeInput>;
};

export type RelationshipItemsFieldValuePayloadAssigneeInput = {
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type RelationshipItemsFieldValueResponse =
  | RelationshipItemsFieldValuePayload
  | GraphqlError;

export type RelationshipMutation = {
  __typename?: 'RelationshipMutation';
  createRelationshipGraph: RelationshipGraphqlResponse;
  updateRelationshipItemData: UpdateRelationshipItemDataPayloadResponse;
  createInfluencerMapContact: BooleanResponse;
  cloneRelationshipMap: RelationshipGraphqlResponse;
};

export type RelationshipMutationCreateRelationshipGraphArgs = {
  input: CreateRelationshipGraphInput;
};

export type RelationshipMutationUpdateRelationshipItemDataArgs = {
  input: UpdateRelationshipItemDataInput;
};

export type RelationshipMutationCreateInfluencerMapContactArgs = {
  input: CreateInfluencerMapContactInput;
};

export type RelationshipMutationCloneRelationshipMapArgs = {
  input: CloneRelationshipMapInput;
};

export enum RelationshipObjectType {
  CONTACT = 'CONTACT',
}

export type RelationshipQuery = {
  __typename?: 'RelationshipQuery';
  relationshipGraph: RelationshipGraphqlResponse;
  searchRelationshipItems: RelationshipItemConnectionResponse;
  getRelationshipItems: RelationshipItemPayloadResponse;
  getRelationshipItemsFieldValues: RelationshipItemsFieldValueResponse;
  leftSideRelationshipItemsList?: Maybe<LeftSideRelationshipItemsResponse>;
  searchRelationshipMaps: RelationshipGraphqlPayloadResponse;
};

export type RelationshipQueryRelationshipGraphArgs = {
  input: GetRelationshipGraphInput;
};

export type RelationshipQuerySearchRelationshipItemsArgs = {
  input: SearchRelationshipItemInput;
};

export type RelationshipQueryGetRelationshipItemsArgs = {
  input: GetRelationshipItemsInput;
};

export type RelationshipQueryGetRelationshipItemsFieldValuesArgs = {
  input?: Maybe<GetRelationshipItemsFieldValuesInput>;
};

export type RelationshipQuerySearchRelationshipMapsArgs = {
  input?: Maybe<SearchRelationshipMapsInput>;
};

export type RelationshipScopeInput = {
  id: Scalars['ID'];
  type: RelationshipScopeType;
};

export enum RelationshipScopeType {
  ACCOUNT_PLAN = 'ACCOUNT_PLAN',
  COMPANY = 'COMPANY',
  MSP = 'MSP',
  OPPORTUNITY = 'OPPORTUNITY',
}

export type RemovePlaceholderFromRoleInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  placeholderId: Scalars['ID'];
};

export type RemovePrimaryStakeholderInput = {
  stakeholderId: Scalars['ID'];
};

export type RemoveReporteeOrReportsTo = {
  userId: Scalars['String'];
  managerId: Scalars['String'];
};

export type RemoveStakeholderFromRoleInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  stakeholderId: Scalars['ID'];
};

export type ReplaceAttachedMediaInput = {
  detachInput: DetachMediaInput;
  mediaInput: CreateMediaInput;
  attachInput: AttachMediaInput;
};

export type ReplacePlaceholderWithStakeholderInput = {
  scopeId: Scalars['ID'];
  roleId: Scalars['ID'];
  placeholderId: Scalars['ID'];
  stakeholderIdMap: UserIdMapInput;
};

export type ReplaceStakeholderInput = {
  previousStakeholderIdMap: UserIdMapInput;
  currentStakeholderIdMap: UserIdMapInput;
};

export type RevokeMediaExternalAccessInput = {
  mediaId: Scalars['ID'];
};

export type RevokePermissionInput = {
  userId: Scalars['ID'];
  mspId?: Maybe<Scalars['ID']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  scopeId: Scalars['ID'];
  createdBy: UserResponse;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  scopeType: RoleScope;
  companyType: CompanyType;
  isHidden: Scalars['Boolean'];
  members?: Maybe<RoleMembersResponse>;
  createdAt: Scalars['Timestamp'];
  order: Scalars['Int'];
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RoleConnectionResponse = RoleConnection | GraphqlError;

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String'];
  node?: Maybe<RoleResponse>;
};

export type RoleMembers = {
  __typename?: 'RoleMembers';
  buyerMembers?: Maybe<MembersResponse>;
  sellerMembers?: Maybe<MembersResponse>;
};

export type RoleMembersResponse = RoleMembers | GraphqlError;

export type RoleMutation = {
  __typename?: 'RoleMutation';
  createRole: RolePayloadResponse;
  updateRole: RolePayloadResponse;
  removeRole: BooleanResponse;
  reOrderRole: BooleanResponse;
};

export type RoleMutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type RoleMutationUpdateRoleArgs = {
  id: Scalars['ID'];
  input: UpdateRoleInput;
};

export type RoleMutationRemoveRoleArgs = {
  id: Scalars['ID'];
};

export type RoleMutationReOrderRoleArgs = {
  roleId: Scalars['ID'];
  to: Scalars['Int'];
};

export type RolePayload = {
  __typename?: 'RolePayload';
  items?: Maybe<Array<RoleResponse>>;
};

export type RolePayloadResponse = RolePayload | GraphqlError;

export type RoleQuery = {
  __typename?: 'RoleQuery';
  listRoles: RoleConnectionResponse;
};

export type RoleQueryListRolesArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type RoleResponse = Role | GraphqlError;

export enum RoleScope {
  MSP = 'MSP',
  ORG = 'ORG',
  TEMPLATE = 'TEMPLATE',
}

export enum Roles {
  COMMENTER = 'COMMENTER',
  CO_OWNER = 'CO_OWNER',
  DEFAULT = 'DEFAULT',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
}

export type SalesforceConfig = {
  __typename?: 'SalesforceConfig';
  isEnabled?: Maybe<Scalars['Boolean']>;
  qualifiedStageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesforceConfigInput = {
  isEnabled?: Maybe<Scalars['Boolean']>;
  qualifiedStageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ScopeJson = {
  __typename?: 'ScopeJson';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ScopeType>;
};

export type ScopeJsonInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ScopeType>;
};

export enum ScopeType {
  ORGANIZATION = 'ORGANIZATION',
}

export type SearchAccountPlanInput = {
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
};

export type SearchRelationshipItemInput = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
  scope?: Maybe<RelationshipScopeInput>;
};

export type SearchRelationshipMapsInput = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type SearchRelationshipMapsScope = {
  __typename?: 'SearchRelationshipMapsScope';
  scopeId?: Maybe<Scalars['ID']>;
  scopeType?: Maybe<RelationshipScopeType>;
};

export type SortExpression = {
  order: SortOrder;
  field: Scalars['String'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SourceObjectType {
  DEAL = 'DEAL',
  OPPORTUNITY = 'OPPORTUNITY',
  UNKNOWN = 'UNKNOWN',
}

export enum SourceType {
  SALESFORCE = 'SALESFORCE',
  ZAPIER = 'ZAPIER',
}

export type Stakeholder = IUser & {
  __typename?: 'Stakeholder';
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
  isPrimaryStakeholder?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyResponse>;
  email?: Maybe<Scalars['EmailAddress']>;
  analytics?: Maybe<StakeholderAnalyticsResponse>;
  title?: Maybe<Scalars['String']>;
  role?: Maybe<RolePayloadResponse>;
  linkedinData?: Maybe<LinkedinDataResponse>;
};

export type StakeholderAnalytics = {
  __typename?: 'StakeholderAnalytics';
  engagementCount?: Maybe<EngagementCountResponse>;
};

export type StakeholderAnalyticsResponse = StakeholderAnalytics | GraphqlError;

export type StakeholderResponse = Stakeholder | GraphqlError;

export type Structure = {
  __typename?: 'Structure';
  id: Scalars['ID'];
  name: Scalars['String'];
  structureType: StructureType;
  description?: Maybe<Scalars['String']>;
  fields: Array<StructureField>;
  style?: Maybe<Scalars['JSON']>;
  dataSourceConfig?: Maybe<DataSourceConfig>;
};

export type StructureField = {
  __typename?: 'StructureField';
  style?: Maybe<Scalars['JSON']>;
  fieldProperty?: Maybe<StructureFieldProperty>;
};

export type StructureFieldProperty = {
  __typename?: 'StructureFieldProperty';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fieldType: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  defaultValue?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  values?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  fieldContextType?: Maybe<Scalars['String']>;
  dataSourceConfig?: Maybe<DataSourceConfig>;
};

export type StructureQuery = {
  __typename?: 'StructureQuery';
  listStructure: StructureResponsePayloadResponse;
};

export type StructureQueryListStructureArgs = {
  operations?: Maybe<Operations>;
};

export type StructureResponse = Structure | GraphqlError;

export type StructureResponsePayload = {
  __typename?: 'StructureResponsePayload';
  items?: Maybe<Array<Maybe<StructureResponse>>>;
};

export type StructureResponsePayloadResponse =
  | StructureResponsePayload
  | GraphqlError;

export enum StructureType {
  CONTACT_CARD = 'CONTACT_CARD',
  CONTACT_FORM = 'CONTACT_FORM',
  CREATE_CONTACT_FORM = 'CREATE_CONTACT_FORM',
  EDGE = 'EDGE',
  NOTES = 'NOTES',
  TEMPLATE_CARD = 'TEMPLATE_CARD',
}

export type Subscriber = {
  __typename?: 'Subscriber';
  user: UserResponse;
  status: MspSubscriberStatus;
  permission: MspInvitePermission;
  role?: Maybe<Roles>;
};

export type SubscriberConnection = {
  __typename?: 'SubscriberConnection';
  edges?: Maybe<Array<Maybe<SubscriberEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriberConnectionResponse = SubscriberConnection | GraphqlError;

export type SubscriberEdge = {
  __typename?: 'SubscriberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubscriberResponse>;
};

export type SubscriberResponse = Subscriber | GraphqlError;

export type SyncMeddicInput = {
  qualifiers: Array<Maybe<Scalars['String']>>;
};

export type SyncMeddpiccInput = {
  qualifiers: Array<Maybe<Scalars['String']>>;
};

export type SyncMeddpiccpaInput = {
  qualifiers: Array<Maybe<Scalars['String']>>;
};

export type SyncValueSellingInput = {
  qualifiers: Array<Maybe<Scalars['String']>>;
};

export enum SystemForContactSource {
  HUBSPOT = 'HUBSPOT',
  INTERNAL = 'INTERNAL',
  KEYCLOAK = 'KEYCLOAK',
  PIPEDRIVE = 'PIPEDRIVE',
  SALESFORCE = 'SALESFORCE',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
  ZAPIER = 'ZAPIER',
}

export enum SystemForSource {
  HUBSPOT = 'HUBSPOT',
  INTERNAL = 'INTERNAL',
  PIPEDRIVE = 'PIPEDRIVE',
  SALESFORCE = 'SALESFORCE',
  ZAPIER = 'ZAPIER',
}

export type Task = Node & {
  __typename?: 'Task';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  status: TaskStatus;
  dueDate: Scalars['Int'];
  milestoneId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<UserResponse>;
  assignee?: Maybe<Assignee>;
  notes?: Maybe<NotesResponse>;
  subscribers: UserConnectionResponse;
  milestone: MilestoneResponse;
  msp: MspResponse;
  createdAt: Scalars['Int'];
  analytics?: Maybe<TaskAnalyticsResponse>;
  isInternal: Scalars['Boolean'];
  assigneeType?: Maybe<AssigneeType>;
};

export type TaskSubscribersArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TaskAnalytics = {
  __typename?: 'TaskAnalytics';
  /** @deprecated Use taskCommentCount field */
  commentCount?: Maybe<IntResponse>;
  taskCommentCount?: Maybe<Scalars['Int']>;
  taskTrack?: Maybe<TaskTrack>;
  /** @deprecated Use taskMediaResourceCount */
  taskMediaCount?: Maybe<IntResponse>;
  taskMediaResourceCount?: Maybe<BaResourceCountResponse>;
};

export type TaskAnalyticsResponse = TaskAnalytics | GraphqlError;

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TaskConnectionResponse = TaskConnection | GraphqlError;

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['String'];
  node?: Maybe<TaskResponse>;
};

export type TaskHighlights = {
  __typename?: 'TaskHighlights';
  upcomingTasks?: Maybe<Array<Maybe<AnalyticsTask>>>;
};

export type TaskMutation = {
  __typename?: 'TaskMutation';
  createTask: TaskPayloadResponse;
  bulkCreateTasks: TaskPayloadResponse;
  updateTask: TaskPayloadResponse;
  bulkUpdateTasks: TaskPayloadResponse;
  deleteTask: BooleanResponse;
  markTasksComplete: BooleanResponse;
  markTasksPending: BooleanResponse;
  changeTaskStatus: BooleanResponse;
  markAllTasksInMilestoneComplete: BooleanResponse;
  markAllTasksInMilestonePending: BooleanResponse;
  reOrderTask: BooleanResponse;
  moveTasksAcrossMilestone: BooleanResponse;
  cloneTasks: TaskPayloadResponse;
  addAssignee: BooleanResponse;
  removeAssignee: BooleanResponse;
  createUserAndAssignToTasks: UserPayloadResponse;
  markTasksInternal: BooleanResponse;
};

export type TaskMutationCreateTaskArgs = {
  milestoneId: Scalars['ID'];
  input: CreateTaskInput;
  order?: Maybe<Scalars['Int']>;
};

export type TaskMutationBulkCreateTasksArgs = {
  milestoneId: Scalars['ID'];
  input: Array<CreateTaskInput>;
};

export type TaskMutationUpdateTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInput;
};

export type TaskMutationBulkUpdateTasksArgs = {
  input: Array<UpdateTaskInput>;
};

export type TaskMutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type TaskMutationMarkTasksCompleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskMutationMarkTasksPendingArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskMutationChangeTaskStatusArgs = {
  input?: Maybe<ChangeTaskStatusInput>;
};

export type TaskMutationMarkAllTasksInMilestoneCompleteArgs = {
  milestoneId: Scalars['ID'];
};

export type TaskMutationMarkAllTasksInMilestonePendingArgs = {
  milestoneId: Scalars['ID'];
};

export type TaskMutationReOrderTaskArgs = {
  taskId: Scalars['ID'];
  to: Scalars['Int'];
};

export type TaskMutationMoveTasksAcrossMilestoneArgs = {
  input: MoveTasksAcrossMilestoneInput;
};

export type TaskMutationCloneTasksArgs = {
  taskIds: Array<Scalars['ID']>;
  milestoneId: Scalars['ID'];
};

export type TaskMutationAddAssigneeArgs = {
  userIdMap?: Maybe<UserIdMapInput>;
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationRemoveAssigneeArgs = {
  assigneeId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationCreateUserAndAssignToTasksArgs = {
  taskIds: Array<Scalars['ID']>;
  input: CreateUserInput;
};

export type TaskMutationMarkTasksInternalArgs = {
  input?: Maybe<MarkTaskInternalInput>;
};

export type TaskPayload = {
  __typename?: 'TaskPayload';
  items: Array<TaskResponse>;
};

export type TaskPayloadResponse = TaskPayload | GraphqlError;

export type TaskQuery = {
  __typename?: 'TaskQuery';
  getTasks: TaskPayloadResponse;
  getTaskNotes: NotesResponse;
  listTasks: TaskConnectionResponse;
  myTasks: TaskConnectionResponse;
  overdueTasks: TaskConnectionResponse;
  myTaskFilters?: Maybe<FilterItemsPayloadResponse>;
  allTaskFilters?: Maybe<FilterItemsPayloadResponse>;
  allTasksWithFilters: TaskConnectionResponse;
  allTaskFiltersCount: FilterItemsCountPayloadResponse;
};

export type TaskQueryGetTasksArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskQueryGetTaskNotesArgs = {
  taskId: Scalars['ID'];
};

export type TaskQueryListTasksArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TaskQueryMyTasksArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TaskQueryOverdueTasksArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TaskQueryAllTasksWithFiltersArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TaskQueryAllTaskFiltersCountArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskResponse = Task | GraphqlError;

export enum TaskStatus {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
}

export type TaskTrack = {
  __typename?: 'TaskTrack';
  taskTrackStatus: TaskTrackStatus;
  overdueByDays?: Maybe<Scalars['Int']>;
  behindBy?: Maybe<Scalars['Timestamp']>;
};

export enum TaskTrackStatus {
  ON_TRACK = 'ON_TRACK',
  OVERDUE = 'OVERDUE',
}

export type TemplateMutation = {
  __typename?: 'TemplateMutation';
  createMspFromTemplate: MspPayloadResponse;
  createTemplateFromMsp: MspPayloadResponse;
  createMilestoneFromTemplate: MilestonePayloadResponse;
  createTemplateFromMilestone: MilestonePayloadResponse;
};

export type TemplateMutationCreateMspFromTemplateArgs = {
  templateId: Scalars['ID'];
  input: CreateMspTemplateInput;
};

export type TemplateMutationCreateTemplateFromMspArgs = {
  mspId: Scalars['ID'];
};

export type TemplateMutationCreateMilestoneFromTemplateArgs = {
  templateId: Scalars['ID'];
  input: CreateMilestoneTemplateInput;
};

export type TemplateMutationCreateTemplateFromMilestoneArgs = {
  milestoneId: Scalars['ID'];
};

export type TemplateQuery = {
  __typename?: 'TemplateQuery';
  searchMspTemplates: MspConnectionResponse;
  searchMilestoneTemplates: MilestoneConnectionResponse;
};

export type TemplateQuerySearchMspTemplatesArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type TemplateQuerySearchMilestoneTemplatesArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type Thumbnail = Media & {
  __typename?: 'Thumbnail';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  createdBy: UserResponse;
  name?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  url: Scalars['URL'];
  /** @deprecated Remove this field */
  isInternal?: Maybe<Scalars['Boolean']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  formats?: Maybe<Scalars['JSON']>;
  mimeType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  analytics?: Maybe<MediaAnalyticsResponse>;
  createdAt: Scalars['Timestamp'];
  mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
};

export type ThumbnailMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type Timeline = {
  __typename?: 'Timeline';
  id: Scalars['ID'];
  isApproved?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<NotesResponse>;
  label: Scalars['String'];
  date: Scalars['Int'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  createdBy: UserResponse;
};

export type TimelineMutation = {
  __typename?: 'TimelineMutation';
  mutateTimelineSteps: TimelineStepsPayloadResponse;
  approveTimelineStages: BooleanResponse;
  disapproveTimelineStages: BooleanResponse;
};

export type TimelineMutationMutateTimelineStepsArgs = {
  input?: Maybe<MutateTimelineStepsInput>;
};

export type TimelineMutationApproveTimelineStagesArgs = {
  input: ApproveTimelineStages;
};

export type TimelineMutationDisapproveTimelineStagesArgs = {
  input: DisapproveTimelineStages;
};

export enum TimelineOrder {
  FORWARD = 'FORWARD',
  REVERSE = 'REVERSE',
}

export type TimelinePayload = {
  __typename?: 'TimelinePayload';
  items?: Maybe<Array<TimelineResponse>>;
};

export type TimelinePayloadResponse = TimelinePayload | GraphqlError;

export type TimelineResponse = Timeline | GraphqlError;

export type TimelineStepsPayload = {
  __typename?: 'TimelineStepsPayload';
  createTimelineSteps?: Maybe<Array<TimelineResponse>>;
  updateTimelineSteps?: Maybe<Array<TimelineResponse>>;
  deleteTimelineSteps?: Maybe<Array<BooleanResponse>>;
};

export type TimelineStepsPayloadResponse = TimelineStepsPayload | GraphqlError;

export type ToMilestoneInput = {
  afterTaskId?: Maybe<Scalars['ID']>;
  milestoneId: Scalars['ID'];
};

export type ToggleMspArchivePayload = {
  __typename?: 'ToggleMspArchivePayload';
  items?: Maybe<Array<ToggleMspArchiveResponse>>;
};

export type ToggleMspArchiveResponse = {
  __typename?: 'ToggleMspArchiveResponse';
  id: Scalars['ID'];
  mspStage: MspStage;
};

export type ToggleMspsArchivalResponse = ToggleMspArchivePayload | GraphqlError;

export type TransferOwnershipInput = {
  transfereeId: Scalars['ID'];
  transferredToId: Scalars['ID'];
  deletedTransferee?: Maybe<Scalars['Boolean']>;
};

export type TransferUserInput = {
  transfereeId: Scalars['String'];
  transferredToId: Scalars['String'];
};

export type UnfavoriteMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type UnpinMediaInput = {
  mediaIds: Array<Scalars['ID']>;
  contextId: Scalars['ID'];
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
  media: Array<CreateMediaInput>;
};

export type UpdateCompanyInput = {
  companyName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  companyId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['ID']>;
};

export type UpdateContactRoleInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  meta?: Maybe<ContactRoleMetaInput>;
};

export type UpdateExecutiveSummaryInput = {
  description: Scalars['String'];
};

export type UpdateLovItemInput = {
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<LovType>;
  value?: Maybe<Scalars['String']>;
  scope?: Maybe<ScopeJsonInput>;
  displayValue?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  isDefaultValue?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateMeddicInput = {
  metrics?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  economicBuyer?: Maybe<UpdateMethodologyQualifierUserInput>;
  decisionCriteria?: Maybe<UpdateMethodologyQualifierStringInput>;
  decisionProcess?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  identifiedPain?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  champion?: Maybe<UpdateMethodologyQualifierUserInput>;
};

export type UpdateMeddpiccInput = {
  metrics?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  economicBuyer?: Maybe<UpdateMethodologyQualifierUserInput>;
  decisionCriteria?: Maybe<UpdateMethodologyQualifierStringInput>;
  decisionProcess?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  paperProcess?: Maybe<UpdateMethodologyQualifierTaskInput>;
  identifiedPain?: Maybe<UpdateMethodologyQualifierAttributesInput>;
  champion?: Maybe<UpdateMethodologyQualifierUserInput>;
  competition?: Maybe<UpdateMethodologyQualifierStringInput>;
};

export type UpdateMeddpiccpaInput = {
  metrics?: Maybe<UpdateMethodologyQualifierStringInput>;
  economicBuyer?: Maybe<UpdateMethodologyQualifierUserInput>;
  decisionCriteria?: Maybe<UpdateMethodologyQualifierStringInput>;
  decisionProcess?: Maybe<UpdateMethodologyQualifierStringInput>;
  paperProcess?: Maybe<UpdateMethodologyQualifierStringInput>;
  identifiedPain?: Maybe<UpdateMethodologyQualifierStringInput>;
  champion?: Maybe<UpdateMethodologyQualifierUserInput>;
  competition?: Maybe<UpdateMethodologyQualifierStringInput>;
  product?: Maybe<UpdateMethodologyQualifierStringInput>;
  additionalInfo?: Maybe<UpdateMethodologyQualifierStringInput>;
};

export type UpdateMediaInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierAttributesInput = {
  notes?: Maybe<Array<Maybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: Maybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierNotesInput = {
  value?: Maybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierStringInput = {
  data?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: Maybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierTaskDataInputPayload = {
  id?: Maybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierTaskInput = {
  data?: Maybe<Array<Maybe<UpdateMethodologyQualifierTaskDataInputPayload>>>;
  notes?: Maybe<Array<Maybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: Maybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierUserDataInputPayload = {
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
};

export type UpdateMethodologyQualifierUserInput = {
  data?: Maybe<Array<Maybe<UpdateMethodologyQualifierUserDataInputPayload>>>;
  notes?: Maybe<Array<Maybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: Maybe<Scalars['Int']>;
};

export type UpdateMilestoneInput = {
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
};

export type UpdateMspInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<UpdateExecutiveSummaryInput>;
  decisionCriteria?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  closeDate?: Maybe<Scalars['Int']>;
  goLiveDate?: Maybe<Scalars['Int']>;
  contractSignatureDate?: Maybe<Scalars['Int']>;
  vendorChoiceDate?: Maybe<Scalars['Int']>;
  isReverseTimeLineEnabled?: Maybe<Scalars['Boolean']>;
  timelineOrder?: Maybe<TimelineOrder>;
  ownerId?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archivalReason?: Maybe<Scalars['String']>;
};

export type UpdateNotesInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type UpdateNotificationStatusRequestBody = {
  userNotificationStatusId: Scalars['String'];
  status: NotificationStatus;
};

export type UpdateOpportunityInput = {
  contactId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  closeDate?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isWon?: Maybe<Scalars['Boolean']>;
  fiscal?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
  intOwnerId?: Maybe<Scalars['String']>;
  extOwnerId?: Maybe<Scalars['String']>;
  intCompanyId?: Maybe<Scalars['String']>;
  extCompanyId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  orgLogo?: Maybe<OrgLogoInput>;
  configs: OrgConfigInput;
  state?: Maybe<CompanyState>;
  orgType?: Maybe<OrgType>;
};

export type UpdatePermissionInput = {
  userId: Scalars['ID'];
  mspId: Scalars['ID'];
  role?: Maybe<Roles>;
  permission: MspInvitePermission;
};

export type UpdateRelationshipItemData = {
  __typename?: 'UpdateRelationshipItemData';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateRelationshipItemDataFieldInput = {
  key: Scalars['String'];
  fieldContextType: Scalars['String'];
  data: Scalars['JSON'];
};

export type UpdateRelationshipItemDataInput = {
  id: Scalars['ID'];
  scope?: Maybe<RelationshipScopeInput>;
  structureId: Scalars['ID'];
  fields: Array<UpdateRelationshipItemDataFieldInput>;
};

export type UpdateRelationshipItemDataPayload = {
  __typename?: 'UpdateRelationshipItemDataPayload';
  items?: Maybe<Array<UpdateRelationshipItemDataResponse>>;
};

export type UpdateRelationshipItemDataPayloadResponse =
  | UpdateRelationshipItemDataPayload
  | GraphqlError;

export type UpdateRelationshipItemDataResponse =
  | UpdateRelationshipItemData
  | GraphqlError;

export type UpdateRoleInput = {
  name?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
};

export type UpdateTaskInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Int']>;
};

export type UpdateTimelineStepsInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  companyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateUspInput = {
  public?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type UpdateValueSellingInput = {
  objectivesAndSuccessMeasures?: Maybe<UpdateMethodologyQualifierStringInput>;
  costofInaction?: Maybe<UpdateMethodologyQualifierStringInput>;
  roi?: Maybe<UpdateMethodologyQualifierStringInput>;
  urgency?: Maybe<UpdateMethodologyQualifierStringInput>;
  ourProof?: Maybe<UpdateMethodologyQualifierStringInput>;
  competitiveDifferentiation?: Maybe<UpdateMethodologyQualifierStringInput>;
  decisionMakerEngagement?: Maybe<UpdateMethodologyQualifierStringInput>;
  influencerEngagement?: Maybe<UpdateMethodologyQualifierStringInput>;
  catalystInfluencerEngagement?: Maybe<UpdateMethodologyQualifierStringInput>;
  processUnderstandingAndAlignment?: Maybe<UpdateMethodologyQualifierStringInput>;
  purchaseRequirements?: Maybe<UpdateMethodologyQualifierStringInput>;
  demoQuality?: Maybe<UpdateMethodologyQualifierStringInput>;
  funding?: Maybe<UpdateMethodologyQualifierStringInput>;
  keyThreats?: Maybe<UpdateMethodologyQualifierStringInput>;
};

export type UploadAndAttachMediaInput = {
  uploadInput: UploadMediaInput;
  attachInput: AttachMediaInput;
};

export type UploadMediaInput = {
  link: Scalars['URL'];
  mediaType: MediaType;
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};

export type UpsPayloadResponse = UspPayload | GraphqlError;

export type User = IUser & {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyResponse>;
  userType: UserType;
  email?: Maybe<Scalars['EmailAddress']>;
  userCompaniesScope: Array<UserCompanyScope>;
  source: UserSource;
  status: UserStatus;
  linkedinData?: Maybe<LinkedinDataResponse>;
  avatar?: Maybe<Avatar>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityById?: Maybe<Scalars['String']>;
  activityBy?: Maybe<UserResponse>;
  role?: Maybe<RolePayloadResponse>;
  message?: Maybe<Scalars['String']>;
};

export type UserCompanyScope = {
  __typename?: 'UserCompanyScope';
  company?: Maybe<CompanyResponse>;
  userType?: Maybe<UserType>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserConnectionResponse = UserConnection | GraphqlError;

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UserResponse>;
};

export type UserEngagement = {
  __typename?: 'UserEngagement';
  id: Scalars['String'];
  mediaViewCount: Scalars['Int'];
  mspViewCount: Scalars['Int'];
  mediaDownloadCount: Scalars['Int'];
  commentCount: Scalars['Int'];
  user?: Maybe<UserResponse>;
  userTasksAnalytics?: Maybe<UserTaskAnalyticsResponse>;
};

export type UserEngagementConnection = {
  __typename?: 'UserEngagementConnection';
  edges?: Maybe<Array<Maybe<UserEngagementEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserEngagementConnectionResponse =
  | UserEngagementConnection
  | GraphqlError;

export type UserEngagementEdge = {
  __typename?: 'UserEngagementEdge';
  node?: Maybe<UserEngagementResponse>;
};

export type UserEngagementResponse = UserEngagement | GraphqlError;

export type UserIdMap = {
  __typename?: 'UserIdMap';
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
};

export type UserIdMapInput = {
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<AssigneeType>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  createUser: UserPayloadResponse;
  updateUser: UserPayloadResponse;
  deleteUser: BooleanResponse;
  transferUser: BooleanResponse;
};

export type UserMutationCreateUserArgs = {
  input: CreateUserInput;
};

export type UserMutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};

export type UserMutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type UserMutationTransferUserArgs = {
  input: TransferUserInput;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  items?: Maybe<Array<UserResponse>>;
};

export type UserPayloadResponse = UserPayload | GraphqlError;

export type UserQuery = {
  __typename?: 'UserQuery';
  me: UserResponse;
  getUsers: UserPayloadResponse;
  searchUser: UserConnectionResponse;
};

export type UserQueryGetUsersArgs = {
  ids: Array<Scalars['ID']>;
};

export type UserQuerySearchUserArgs = {
  pageConfiguration?: Maybe<PageConfiguration>;
  operations: Operations;
};

export type UserReferenceMspContext = IMsp & {
  __typename?: 'UserReferenceMspContext';
  id: Scalars['ID'];
  opportunityId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  isOwner?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserResponse>;
  startDate: Scalars['Int'];
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  economicImpact?: Maybe<Scalars['String']>;
  buyerCompany: CompanyResponse;
  tasks?: Maybe<TaskConnectionResponse>;
  roles?: Maybe<RoleConnectionResponse>;
  methodology?: Maybe<MethodologyPayload>;
};

export type UserReferenceMspContextPayload = {
  __typename?: 'UserReferenceMspContextPayload';
  items?: Maybe<Array<Maybe<UserReferenceMspContextResponse>>>;
};

export type UserReferenceMspContextResponse =
  | UserReferenceMspContext
  | GraphqlError;

export type UserReferenceResponse = {
  __typename?: 'UserReferenceResponse';
  mspsContext?: Maybe<UserReferenceMspContextPayload>;
};

export type UserReferencesPayloadResponse =
  | UserReferenceResponse
  | GraphqlError;

export type UserResponse = User | GraphqlError;

export enum UserSource {
  KEYCLOAK = 'KEYCLOAK',
  SALESFORCE = 'SALESFORCE',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type UserTaskAnalytics = {
  __typename?: 'UserTaskAnalytics';
  overdueTask?: Maybe<UserTaskStatusDetails>;
  completedTask?: Maybe<UserTaskStatusDetails>;
  assignedTask?: Maybe<UserTaskStatusDetails>;
};

export type UserTaskAnalyticsResponse = UserTaskAnalytics | GraphqlError;

export type UserTaskStatusDetails = {
  __typename?: 'UserTaskStatusDetails';
  count: Scalars['Int'];
};

export enum UserType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type Usp = {
  __typename?: 'Usp';
  id: Scalars['ID'];
  public: Scalars['Boolean'];
  passwordVerification: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  link: Scalars['String'];
};

export type UspMutation = {
  __typename?: 'UspMutation';
  createUsp: UpsPayloadResponse;
  updateUsp: UpsPayloadResponse;
};

export type UspMutationCreateUspArgs = {
  input: CreateUspInput;
};

export type UspMutationUpdateUspArgs = {
  id: Scalars['ID'];
  input: UpdateUspInput;
};

export type UspPayload = {
  __typename?: 'UspPayload';
  items?: Maybe<Array<UspResponse>>;
};

export type UspResponse = Usp | GraphqlError;

export type ValueSelling = Node & {
  __typename?: 'ValueSelling';
  id: Scalars['ID'];
  mspId: Scalars['ID'];
  objectivesAndSuccessMeasures?: Maybe<MethodologyStringQualifier>;
  costofInaction?: Maybe<MethodologyStringQualifier>;
  roi?: Maybe<MethodologyStringQualifier>;
  urgency?: Maybe<MethodologyStringQualifier>;
  ourProof?: Maybe<MethodologyStringQualifier>;
  competitiveDifferentiation?: Maybe<MethodologyStringQualifier>;
  decisionMakerEngagement?: Maybe<MethodologyStringQualifier>;
  influencerEngagement?: Maybe<MethodologyStringQualifier>;
  catalystInfluencerEngagement?: Maybe<MethodologyStringQualifier>;
  processUnderstandingAndAlignment?: Maybe<MethodologyStringQualifier>;
  purchaseRequirements?: Maybe<MethodologyStringQualifier>;
  demoQuality?: Maybe<MethodologyStringQualifier>;
  funding?: Maybe<MethodologyStringQualifier>;
  keyThreats?: Maybe<MethodologyStringQualifier>;
  analytics?: Maybe<MethodologyAnalyticsResponse>;
};

export type ValueSellingMutation = {
  __typename?: 'ValueSellingMutation';
  updateValueSelling: ValueSellingPayloadResponse;
  syncValueSelling: ValueSellingPayloadResponse;
};

export type ValueSellingMutationUpdateValueSellingArgs = {
  mspId: Scalars['ID'];
  input: UpdateValueSellingInput;
};

export type ValueSellingMutationSyncValueSellingArgs = {
  mspId: Scalars['ID'];
  input: SyncValueSellingInput;
};

export type ValueSellingPayload = {
  __typename?: 'ValueSellingPayload';
  items?: Maybe<Array<ValueSellingResponse>>;
};

export type ValueSellingPayloadResponse = ValueSellingPayload | GraphqlError;

export type ValueSellingQualifierComment = {
  __typename?: 'ValueSellingQualifierComment';
  qualifierId?: Maybe<Scalars['String']>;
  comments?: Maybe<CommentConnectionResponse>;
};

export type ValueSellingQualifierCommentPayload = {
  __typename?: 'ValueSellingQualifierCommentPayload';
  items?: Maybe<Array<Maybe<ValueSellingQualifierComment>>>;
};

export type ValueSellingQualifierCommentPayloadResponse =
  | ValueSellingQualifierCommentPayload
  | GraphqlError;

export type ValueSellingQuery = {
  __typename?: 'ValueSellingQuery';
  getValueSellingForMsp: ValueSellingPayloadResponse;
  getValueSellingComments?: Maybe<ValueSellingQualifierCommentPayloadResponse>;
};

export type ValueSellingQueryGetValueSellingForMspArgs = {
  mspId: Scalars['ID'];
};

export type ValueSellingQueryGetValueSellingCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type ValueSellingResponse = ValueSelling | GraphqlError;

export type Video = Media &
  Assets & {
    __typename?: 'Video';
    id: Scalars['ID'];
    orgId: Scalars['ID'];
    createdBy: UserResponse;
    name?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fileSize?: Maybe<Scalars['Int']>;
    description?: Maybe<Scalars['String']>;
    mediaType: MediaType;
    url: Scalars['URL'];
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    formats?: Maybe<Scalars['JSON']>;
    thumbnail?: Maybe<Thumbnail>;
    context?: Maybe<MediaContextResponse>;
    mimeType?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    createdAt: Scalars['Timestamp'];
    analytics?: Maybe<MediaAnalyticsResponse>;
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaReferences?: Maybe<MediaReferencesPayloadResponse>;
  };

export type VideoMediaReferencesArgs = {
  referenceTypes?: Maybe<Array<Maybe<MediaReferenceType>>>;
};

export type BulkAddAndDeleteReportsToInput = {
  addReportsTo?: Maybe<Array<Maybe<AddReporteeOrReportsTo>>>;
  removeReportsTo?: Maybe<Array<Maybe<RemoveReporteeOrReportsTo>>>;
};

export type GetOpportunityForOwnerAndCoOwnerInput = {
  opportunityFor?: Maybe<OpportunityForType>;
  pageConfiguration?: Maybe<PageConfiguration>;
  operations?: Maybe<Operations>;
};

export type GetCompanyInfoQueryVariables = Exact<{
  companyName: Scalars['String'];
}>;

export type GetCompanyInfoQuery = {
  __typename?: 'Query';
  companyQuery?: Maybe<{
    __typename?: 'CompanyQuery';
    getCompanyByName:
      | {
          __typename: 'CompanyPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Company';
                  companyName: string;
                  displayName: string;
                  id: string;
                  logo?: Maybe<
                    | {
                        __typename?: 'Logo';
                        id: string;
                        fileName?: Maybe<string>;
                        url: string;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organizationQuery?: Maybe<{
    __typename?: 'OrganizationQuery';
    getOrganization:
      | {
          __typename: 'OrganizationPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Organization';
                  orgId: string;
                  orgType: OrgType;
                  orgName: string;
                  state: CompanyState;
                  orgLogo?: Maybe<{
                    __typename?: 'OrgLogo';
                    original?: Maybe<string>;
                    favicon?: Maybe<string>;
                  }>;
                  configs: {
                    __typename?: 'OrgConfig';
                    others?: Maybe<{ [key: string]: any }>;
                    salesforce?: Maybe<{
                      __typename?: 'SalesforceConfig';
                      isEnabled?: Maybe<boolean>;
                      qualifiedStageNames?: Maybe<Array<Maybe<string>>>;
                    }>;
                    branding?: Maybe<{
                      __typename?: 'BrandingConfig';
                      header?: Maybe<{
                        __typename?: 'BrandingHeader';
                        color?: Maybe<string>;
                        textColor?: Maybe<string>;
                      }>;
                    }>;
                    methodology?: Maybe<{
                      __typename?: 'MethodologyConfig';
                      isEnabled?: Maybe<boolean>;
                      type?: Maybe<string>;
                      displayName?: Maybe<string>;
                    }>;
                  };
                }
              | {
                  __typename?: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  __typename?: 'Query';
  userQuery?: Maybe<{
    __typename?: 'UserQuery';
    me:
      | {
          __typename?: 'User';
          id?: Maybe<string>;
          primaryId?: Maybe<string>;
          firstName?: Maybe<string>;
          lastName?: Maybe<string>;
          title?: Maybe<string>;
          userType: UserType;
          email?: Maybe<string>;
          company?: Maybe<
            | {
                __typename?: 'Company';
                id: string;
                orgId: string;
                companyName: string;
                displayName: string;
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | { __typename?: 'GraphqlError' };
  }>;
};

export type GetActiveMilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveMilestonesQuery = {
  __typename?: 'Query';
  milestoneQuery?: Maybe<{
    __typename?: 'MilestoneQuery';
    activeMilestones: {
      __typename?: 'MilestonePayload';
      items?: Maybe<
        Array<
          | {
              __typename?: 'Milestone';
              id: string;
              name: string;
              startDate: number;
              endDate: number;
              analytics?: Maybe<
                | {
                    __typename?: 'MilestoneAnalytics';
                    milestoneTrack?: Maybe<
                      | {
                          __typename?: 'MilestoneTrack';
                          milestoneTrackStatus: MilestoneTrackStatus;
                          dueTasksCount?: Maybe<number>;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >;
            }
          | { __typename?: 'BaseMilestone' }
          | { __typename?: 'GraphqlError' }
        >
      >;
    };
  }>;
};

export type CompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  companyName: string;
  displayName: string;
  logo?: Maybe<
    | { __typename?: 'Logo'; id: string; fileName?: Maybe<string>; url: string }
    | { __typename?: 'GraphqlError' }
  >;
};

export type UserCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  companyName: string;
};

export type CoreErrorFieldsFragment = {
  __typename: 'GraphqlError';
  code: string;
  message: string;
  type: ErrorType;
  helpLink?: Maybe<string>;
};

export type GetMspForBuyerExperienceQueryVariables = Exact<{
  id: Scalars['ID'];
  options?: Maybe<GetMspAnalyticsOptions>;
}>;

export type GetMspForBuyerExperienceQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename?: 'MspQuery';
    getMsp:
      | {
          __typename: 'MspPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Msp';
                  id: string;
                  isPublished: boolean;
                  name: string;
                  isReverseTimeLineEnabled: boolean;
                  timelineOrder?: Maybe<TimelineOrder>;
                  closeDate: number;
                  createdBy?: Maybe<
                    | {
                        __typename: 'User';
                        id?: Maybe<string>;
                        primaryId?: Maybe<string>;
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        email?: Maybe<string>;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                  buyerCompany:
                    | {
                        __typename: 'Company';
                        id: string;
                        companyName: string;
                        displayName: string;
                        logo?: Maybe<
                          | {
                              __typename?: 'Logo';
                              id: string;
                              fileName?: Maybe<string>;
                              url: string;
                            }
                          | { __typename?: 'GraphqlError' }
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      };
                  sellerCompany:
                    | {
                        __typename: 'Company';
                        id: string;
                        companyName: string;
                        displayName: string;
                        logo?: Maybe<
                          | {
                              __typename?: 'Logo';
                              id: string;
                              fileName?: Maybe<string>;
                              url: string;
                            }
                          | { __typename?: 'GraphqlError' }
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      };
                  timelines?: Maybe<
                    | {
                        __typename?: 'TimelinePayload';
                        items?: Maybe<
                          Array<
                            | {
                                __typename?: 'Timeline';
                                id: string;
                                label: string;
                                date: number;
                              }
                            | {
                                __typename: 'GraphqlError';
                                code: string;
                                message: string;
                                type: ErrorType;
                                helpLink?: Maybe<string>;
                              }
                          >
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                  keyDates?: Maybe<
                    | {
                        __typename?: 'MspKeyDates';
                        goLiveDate?: Maybe<number>;
                        contractSignatureDate?: Maybe<number>;
                        vendorChoiceDate?: Maybe<number>;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                  userBridge?: Maybe<
                    | {
                        __typename?: 'MspUserBridge';
                        permissions:
                          | {
                              __typename: 'MspPermissionPayload';
                              items: Array<MspInvitePermission>;
                            }
                          | {
                              __typename: 'GraphqlError';
                              code: string;
                              message: string;
                              type: ErrorType;
                              helpLink?: Maybe<string>;
                            };
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                  subscribers:
                    | {
                        __typename?: 'SubscriberConnection';
                        edges?: Maybe<
                          Array<
                            Maybe<{
                              __typename?: 'SubscriberEdge';
                              node?: Maybe<
                                | {
                                    __typename: 'Subscriber';
                                    permission: MspInvitePermission;
                                    status: MspSubscriberStatus;
                                    user:
                                      | {
                                          __typename: 'User';
                                          id?: Maybe<string>;
                                          primaryId?: Maybe<string>;
                                          firstName?: Maybe<string>;
                                          lastName?: Maybe<string>;
                                          email?: Maybe<string>;
                                          userType: UserType;
                                          company?: Maybe<
                                            | {
                                                __typename: 'Company';
                                                id: string;
                                                companyName: string;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                code: string;
                                                message: string;
                                                type: ErrorType;
                                                helpLink?: Maybe<string>;
                                              }
                                          >;
                                        }
                                      | {
                                          __typename: 'GraphqlError';
                                          code: string;
                                          message: string;
                                          type: ErrorType;
                                          helpLink?: Maybe<string>;
                                        };
                                  }
                                | {
                                    __typename: 'GraphqlError';
                                    code: string;
                                    message: string;
                                    type: ErrorType;
                                    helpLink?: Maybe<string>;
                                  }
                              >;
                            }>
                          >
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      };
                  analytics?: Maybe<
                    | {
                        __typename?: 'MspAnalytics';
                        track?: Maybe<{
                          __typename: 'MspTrack';
                          mspTrackStatus: MspTrackStatus;
                        }>;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                }
              | { __typename?: 'BaseMsp' }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetAllTasksQueryVariables = Exact<{
  operations?: Maybe<Operations>;
}>;

export type GetAllTasksQuery = {
  __typename?: 'Query';
  taskQuery?: Maybe<{
    __typename?: 'TaskQuery';
    allTasksWithFilters:
      | {
          __typename: 'TaskConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'TaskEdge';
                node?: Maybe<
                  | {
                      __typename: 'Task';
                      id: string;
                      name: string;
                      dueDate: number;
                      status: TaskStatus;
                      isInternal: boolean;
                      description?: Maybe<string>;
                      assigneeType?: Maybe<AssigneeType>;
                      assignee?: Maybe<
                        | {
                            __typename: 'User';
                            id?: Maybe<string>;
                            firstName?: Maybe<string>;
                            lastName?: Maybe<string>;
                            email?: Maybe<string>;
                            userType: UserType;
                          }
                        | {
                            __typename: 'Placeholder';
                            name: string;
                            companyType?: Maybe<CompanyType>;
                            placeholderId: string;
                          }
                        | {
                            __typename: 'GraphqlError';
                            type: ErrorType;
                            code: string;
                            helpLink?: Maybe<string>;
                            message: string;
                          }
                      >;
                    }
                  | {
                      __typename: 'GraphqlError';
                      type: ErrorType;
                      code: string;
                      helpLink?: Maybe<string>;
                      message: string;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type AddAssigneeMutationVariables = Exact<{
  userIdMap: UserIdMapInput;
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AddAssigneeMutation = {
  __typename?: 'Mutation';
  taskMutation?: Maybe<{
    __typename?: 'TaskMutation';
    addAssignee:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateNewUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateNewUserMutation = {
  __typename?: 'Mutation';
  userMutation?: Maybe<{
    __typename?: 'UserMutation';
    createUser:
      | {
          __typename: 'UserPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'User';
                  id?: Maybe<string>;
                  firstName?: Maybe<string>;
                  lastName?: Maybe<string>;
                  email?: Maybe<string>;
                  userType: UserType;
                  primaryId?: Maybe<string>;
                }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetPlaceholderListQueryVariables = Exact<{
  operations?: Maybe<Operations>;
}>;

export type GetPlaceholderListQuery = {
  __typename?: 'Query';
  memberQuery?: Maybe<{
    __typename?: 'MemberQuery';
    listPlaceholders:
      | {
          __typename: 'PlaceholderConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'PlaceholderEdge';
                node?: Maybe<
                  | {
                      __typename: 'Placeholder';
                      id: string;
                      name: string;
                      description?: Maybe<string>;
                      companyType?: Maybe<CompanyType>;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type RemoveAssigneeMutationVariables = Exact<{
  assigneeId: Scalars['ID'];
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveAssigneeMutation = {
  __typename?: 'Mutation';
  taskMutation?: Maybe<{
    __typename?: 'TaskMutation';
    removeAssignee:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchUserAssigneeQueryVariables = Exact<{
  operations?: Maybe<Operations>;
}>;

export type SearchUserAssigneeQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename?: 'MspQuery';
    searchUsersInMsp:
      | {
          __typename: 'UserConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'UserEdge';
                node?: Maybe<
                  | {
                      __typename: 'User';
                      firstName?: Maybe<string>;
                      lastName?: Maybe<string>;
                      userType: UserType;
                      email?: Maybe<string>;
                      id?: Maybe<string>;
                      primaryId?: Maybe<string>;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateTaskDueDateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskInput;
}>;

export type UpdateTaskDueDateMutation = {
  __typename?: 'Mutation';
  taskMutation?: Maybe<{
    __typename?: 'TaskMutation';
    updateTask:
      | {
          __typename: 'TaskPayload';
          items: Array<
            | { __typename?: 'Task'; id: string; name: string; dueDate: number }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: Maybe<string>;
              }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateTaskNameMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskInput;
}>;

export type UpdateTaskNameMutation = {
  __typename?: 'Mutation';
  taskMutation?: Maybe<{
    __typename?: 'TaskMutation';
    updateTask:
      | {
          __typename: 'TaskPayload';
          items: Array<
            | {
                __typename?: 'Task';
                id: string;
                name: string;
                description?: Maybe<string>;
              }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: Maybe<string>;
              }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateTaskStatusMutationVariables = Exact<{
  input: ChangeTaskStatusInput;
}>;

export type UpdateTaskStatusMutation = {
  __typename?: 'Mutation';
  taskMutation?: Maybe<{
    __typename?: 'TaskMutation';
    changeTaskStatus:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetPrimaryStakeholderListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPrimaryStakeholderListQuery = {
  __typename?: 'Query';
  memberQuery?: Maybe<{
    __typename?: 'MemberQuery';
    getPrimaryStakeholders:
      | {
          __typename?: 'PrimaryStakeholder';
          buyerStakeholders?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'Stakeholder';
                    id?: Maybe<string>;
                    primaryId?: Maybe<string>;
                    lastName?: Maybe<string>;
                    firstName?: Maybe<string>;
                    title?: Maybe<string>;
                    company?: Maybe<
                      | {
                          __typename?: 'Company';
                          logo?: Maybe<
                            | {
                                __typename?: 'Logo';
                                id: string;
                                fileName?: Maybe<string>;
                                url: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
          sellerStakeholders?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'Stakeholder';
                    id?: Maybe<string>;
                    primaryId?: Maybe<string>;
                    lastName?: Maybe<string>;
                    firstName?: Maybe<string>;
                    email?: Maybe<string>;
                    title?: Maybe<string>;
                    company?: Maybe<
                      | {
                          __typename?: 'Company';
                          logo?: Maybe<
                            | {
                                __typename?: 'Logo';
                                id: string;
                                fileName?: Maybe<string>;
                                url: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
        }
      | { __typename?: 'GraphqlError' };
  }>;
};

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;

export type AddCommentMutation = {
  __typename?: 'Mutation';
  annotationMutation?: Maybe<{
    __typename?: 'AnnotationMutation';
    addComment:
      | {
          __typename: 'CommentPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Comment';
                  id: string;
                  messageData: {
                    __typename?: 'MessageData';
                    message: string;
                    isEdited: boolean;
                    editedAt?: Maybe<number>;
                  };
                  createdBy:
                    | {
                        __typename?: 'User';
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        id?: Maybe<string>;
                        email?: Maybe<string>;
                        userType: UserType;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      };
                  media?: Maybe<
                    | { __typename?: 'MediaConnection' }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | { __typename: 'GraphqlError' };
  }>;
};

export type GetNotesWithCommentsQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;

export type GetNotesWithCommentsQuery = {
  __typename?: 'Query';
  taskQuery?: Maybe<{
    __typename?: 'TaskQuery';
    getTaskNotes:
      | {
          __typename: 'Notes';
          id: string;
          comments?: Maybe<
            | {
                __typename?: 'CommentConnection';
                edges?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'CommentEdge';
                      node?: Maybe<
                        | {
                            __typename: 'Comment';
                            id: string;
                            createdAt: number;
                            messageData: {
                              __typename?: 'MessageData';
                              message: string;
                              isEdited: boolean;
                              editedAt?: Maybe<number>;
                            };
                            createdBy:
                              | {
                                  __typename?: 'User';
                                  firstName?: Maybe<string>;
                                  lastName?: Maybe<string>;
                                  id?: Maybe<string>;
                                  userType: UserType;
                                  email?: Maybe<string>;
                                }
                              | {
                                  __typename: 'GraphqlError';
                                  code: string;
                                  message: string;
                                  type: ErrorType;
                                  helpLink?: Maybe<string>;
                                };
                            media?: Maybe<
                              | {
                                  __typename?: 'MediaConnection';
                                  edges?: Maybe<
                                    Array<
                                      Maybe<{
                                        __typename?: 'MediaEdge';
                                        node?: Maybe<
                                          | { __typename?: 'MediaPayloadItem' }
                                          | {
                                              __typename: 'GraphqlError';
                                              code: string;
                                              message: string;
                                              type: ErrorType;
                                              helpLink?: Maybe<string>;
                                            }
                                        >;
                                      }>
                                    >
                                  >;
                                }
                              | {
                                  __typename: 'GraphqlError';
                                  code: string;
                                  message: string;
                                  type: ErrorType;
                                  helpLink?: Maybe<string>;
                                }
                            >;
                          }
                        | {
                            __typename: 'GraphqlError';
                            code: string;
                            message: string;
                            type: ErrorType;
                            helpLink?: Maybe<string>;
                          }
                      >;
                    }>
                  >
                >;
              }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: Maybe<string>;
              }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateMspFromTemplateMutationVariables = Exact<{
  input: CreateMspTemplateInput;
  templateId: Scalars['ID'];
}>;

export type CreateMspFromTemplateMutation = {
  __typename?: 'Mutation';
  templateMutation?: Maybe<{
    __typename?: 'TemplateMutation';
    createMspFromTemplate:
      | {
          __typename: 'MspPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Msp';
                  id: string;
                  name: string;
                  description?: Maybe<string>;
                  isPublished: boolean;
                  closeDate: number;
                }
              | { __typename: 'BaseMsp' }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateMspMutationVariables = Exact<{
  input: CreateMspInput;
}>;

export type CreateMspMutation = {
  __typename?: 'Mutation';
  mspMutation?: Maybe<{
    __typename?: 'MspMutation';
    createMsp:
      | {
          __typename: 'MspPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Msp';
                  id: string;
                  name: string;
                  description?: Maybe<string>;
                  isPublished: boolean;
                  closeDate: number;
                }
              | { __typename: 'BaseMsp' }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetMspAnalyticsHighlightsQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsHighlightsQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsHighlights:
      | {
          __typename: 'MspAnalyticsHighlights';
          insights?: Maybe<Array<Maybe<string>>>;
          milestones?: Maybe<{
            __typename?: 'MilestoneHighlights';
            activeMilestones?: Maybe<
              Array<
                Maybe<{
                  __typename?: 'AnalyticsMilestone';
                  milestone?: Maybe<{
                    __typename?: 'BaseMilestone';
                    name: string;
                    startDate: number;
                    endDate: number;
                  }>;
                  progress?: Maybe<{
                    __typename?: 'AnalyticsMilestoneProgress';
                    tasksCount?: Maybe<number>;
                    tasksCompleted?: Maybe<number>;
                    tasksOverdue?: Maybe<number>;
                  }>;
                }>
              >
            >;
          }>;
          tasks?: Maybe<{
            __typename?: 'TaskHighlights';
            upcomingTasks?: Maybe<
              Array<
                Maybe<{
                  __typename?: 'AnalyticsTask';
                  task?: Maybe<{
                    __typename?: 'AnalyticsBaseTask';
                    id: string;
                    name: string;
                    status: TaskStatus;
                    dueDate: number;
                  }>;
                  assignee?: Maybe<
                    | {
                        __typename?: 'AnalyticsUser';
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        userType: UserType;
                        email?: Maybe<string>;
                      }
                    | { __typename?: 'Placeholder'; name: string }
                  >;
                }>
              >
            >;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type ToggleMspsArchivalStatusMutationVariables = Exact<{
  input: ArchiveMspInput;
}>;

export type ToggleMspsArchivalStatusMutation = {
  __typename?: 'Mutation';
  mspMutation?: Maybe<{
    __typename?: 'MspMutation';
    toggleMspsArchivalStatus:
      | {
          __typename: 'ToggleMspArchivePayload';
          items?: Maybe<
            Array<{
              __typename?: 'ToggleMspArchiveResponse';
              id: string;
              mspStage: MspStage;
            }>
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetMspArchiveReasonListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMspArchiveReasonListQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename: 'MspQuery';
    listMspArchivalReasons:
      | { __typename?: 'FilterItemsPayload' }
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type ListAccountPlansQueryVariables = Exact<{
  input?: Maybe<ListAccountPlanInput>;
}>;

export type ListAccountPlansQuery = {
  __typename?: 'Query';
  accountPlanQuery?: Maybe<{
    __typename?: 'AccountPlanQuery';
    listAccountPlans:
      | {
          __typename?: 'AccountPlanConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'AccountPlanEdge';
                node?: Maybe<
                  | {
                      __typename?: 'AccountPlan';
                      id: string;
                      createdAt: number;
                      buyerCompany?: Maybe<
                        | {
                            __typename?: 'Company';
                            displayName: string;
                            companyName: string;
                          }
                        | { __typename?: 'GraphqlError' }
                      >;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchAccountPlanQueryVariables = Exact<{
  input?: Maybe<SearchAccountPlanInput>;
}>;

export type SearchAccountPlanQuery = {
  __typename?: 'Query';
  accountPlanQuery?: Maybe<{
    __typename?: 'AccountPlanQuery';
    searchAccountPlan:
      | {
          __typename?: 'AccountPlanConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'AccountPlanEdge';
                node?: Maybe<
                  | {
                      __typename?: 'AccountPlan';
                      id: string;
                      createdAt: number;
                      buyerCompany?: Maybe<
                        | {
                            __typename?: 'Company';
                            displayName: string;
                            companyName: string;
                          }
                        | { __typename?: 'GraphqlError' }
                      >;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchCompaniesQueryVariables = Exact<{
  operations: Operations;
}>;

export type SearchCompaniesQuery = {
  __typename?: 'Query';
  companyQuery?: Maybe<{
    __typename?: 'CompanyQuery';
    searchCompany:
      | {
          __typename: 'CompanyConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'CompanyEdge';
                node?: Maybe<
                  | {
                      __typename?: 'Company';
                      id: string;
                      orgId: string;
                      extId?: Maybe<string>;
                      displayName: string;
                      companyName: string;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetDashboardMspListQueryVariables = Exact<{
  mspOperations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
}>;

export type GetDashboardMspListQuery = {
  __typename?: 'Query';
  alignxQuery?: Maybe<{
    __typename?: 'AlignxQuery';
    dashboard:
      | {
          __typename: 'Alignx';
          msps:
            | {
                __typename?: 'MspConnection';
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: Maybe<string>;
                  beforeCursor?: Maybe<string>;
                };
                edges?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'MspEdge';
                      node?: Maybe<
                        | {
                            __typename?: 'Msp';
                            id: string;
                            name: string;
                            opportunity?: Maybe<
                              | {
                                  __typename?: 'Opportunity';
                                  id: string;
                                  name: string;
                                  stageName?: Maybe<string>;
                                  closeDate?: Maybe<number>;
                                }
                              | { __typename?: 'GraphqlError' }
                            >;
                          }
                        | { __typename?: 'BaseMsp' }
                        | {
                            __typename: 'GraphqlError';
                            code: string;
                            message: string;
                            type: ErrorType;
                            helpLink?: Maybe<string>;
                          }
                      >;
                    }>
                  >
                >;
              }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: Maybe<string>;
              };
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetOpportunityFiltersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOpportunityFiltersQuery = {
  __typename?: 'Query';
  alignxQuery?: Maybe<{
    __typename?: 'AlignxQuery';
    dashboard:
      | {
          __typename?: 'Alignx';
          dashboardFiltersV1?: Maybe<
            | {
                __typename: 'FilterItemsPayload';
                items?: Maybe<
                  Array<{
                    __typename?: 'FilterItems';
                    key: string;
                    displayName: string;
                    values?: Maybe<Array<{ [key: string]: any }>>;
                    isVisible: boolean;
                  }>
                >;
              }
            | { __typename: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetOpportunityListQueryVariables = Exact<{
  operations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
}>;

export type GetOpportunityListQuery = {
  __typename?: 'Query';
  opportunityQuery?: Maybe<{
    __typename?: 'OpportunityQuery';
    listOpportunity:
      | {
          __typename?: 'OpportunityConnection';
          totalCount?: Maybe<number>;
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            afterCursor?: Maybe<string>;
            beforeCursor?: Maybe<string>;
          };
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'OpportunityEdge';
                node?: Maybe<
                  | { __typename: 'Opportunity'; id: string; name: string }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetOwnerAndCoOwnerOpportunitiesQueryVariables = Exact<{
  input: GetOpportunityForOwnerAndCoOwnerInput;
}>;

export type GetOwnerAndCoOwnerOpportunitiesQuery = {
  __typename?: 'Query';
  opportunityQuery?: Maybe<{
    __typename?: 'OpportunityQuery';
    getOpportunityForOwnerAndCoOwner:
      | {
          __typename: 'OpportunityConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'OpportunityEdge';
                node?: Maybe<
                  | {
                      __typename: 'Opportunity';
                      id: string;
                      accountId: string;
                      name: string;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetPaginatedMspListQueryVariables = Exact<{
  mspOperations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
}>;

export type GetPaginatedMspListQuery = {
  __typename?: 'Query';
  alignxQuery?: Maybe<{
    __typename?: 'AlignxQuery';
    dashboard:
      | {
          __typename: 'Alignx';
          msps:
            | {
                __typename?: 'MspConnection';
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: Maybe<string>;
                  beforeCursor?: Maybe<string>;
                };
                edges?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'MspEdge';
                      node?: Maybe<
                        | {
                            __typename?: 'Msp';
                            id: string;
                            name: string;
                            opportunity?: Maybe<
                              | {
                                  __typename?: 'Opportunity';
                                  id: string;
                                  name: string;
                                  stageName?: Maybe<string>;
                                  closeDate?: Maybe<number>;
                                }
                              | { __typename?: 'GraphqlError' }
                            >;
                          }
                        | { __typename?: 'BaseMsp' }
                        | {
                            __typename: 'GraphqlError';
                            code: string;
                            message: string;
                            type: ErrorType;
                            helpLink?: Maybe<string>;
                          }
                      >;
                    }>
                  >
                >;
              }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: Maybe<string>;
              };
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateAccountPlanMutationVariables = Exact<{
  input: CreateAccountPlanInput;
}>;

export type CreateAccountPlanMutation = {
  __typename?: 'Mutation';
  accountPlanMutation?: Maybe<{
    __typename?: 'AccountPlanMutation';
    createAccountPlan:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'AccountPlan';
                    id: string;
                    extCompanyId: string;
                    companyId: string;
                    orgId: string;
                    createdAt: number;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetAccountContactsQueryVariables = Exact<{
  otherContactInput: OtherContactInput;
  defaultContactInput: DefaultContactInput;
}>;

export type GetAccountContactsQuery = {
  __typename?: 'Query';
  relationshipQuery?: Maybe<{
    __typename?: 'RelationshipQuery';
    leftSideRelationshipItemsList?: Maybe<
      | {
          __typename?: 'LeftSideRelationshipItems';
          otherContacts?: Maybe<
            | {
                __typename: 'RelationshipItemConnection';
                totalCount?: Maybe<number>;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: Maybe<string>;
                  beforeCursor?: Maybe<string>;
                };
                edges?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'RelationshipItemEdge';
                      cursor?: Maybe<string>;
                      node?: Maybe<{
                        __typename?: 'RelationshipContact';
                        id: string;
                        name?: Maybe<string>;
                        title?: Maybe<string>;
                        objectType?: Maybe<RelationshipObjectType>;
                        reportTo?: Maybe<
                          | {
                              __typename?: 'UserPayload';
                              items?: Maybe<
                                Array<
                                  | {
                                      __typename?: 'User';
                                      id?: Maybe<string>;
                                      primaryId?: Maybe<string>;
                                      firstName?: Maybe<string>;
                                      lastName?: Maybe<string>;
                                      title?: Maybe<string>;
                                      email?: Maybe<string>;
                                    }
                                  | { __typename?: 'GraphqlError' }
                                >
                              >;
                            }
                          | { __typename?: 'GraphqlError' }
                        >;
                        contactRoles?: Maybe<
                          | {
                              __typename?: 'ContactPayload';
                              items?: Maybe<
                                Array<
                                  | {
                                      __typename?: 'ContactRole';
                                      id: string;
                                      name: string;
                                    }
                                  | { __typename?: 'GraphqlError' }
                                >
                              >;
                            }
                          | {
                              __typename: 'GraphqlError';
                              code: string;
                              message: string;
                              type: ErrorType;
                              helpLink?: Maybe<string>;
                            }
                        >;
                        avatar?: Maybe<{
                          __typename?: 'Avatar';
                          url?: Maybe<string>;
                        }>;
                        buyerCompany?: Maybe<
                          | { __typename?: 'Company'; displayName: string }
                          | { __typename?: 'GraphqlError' }
                        >;
                      }>;
                    }>
                  >
                >;
              }
            | { __typename: 'GraphqlError'; type: ErrorType; message: string }
          >;
          defaultContacts?: Maybe<
            | {
                __typename: 'RelationshipItemConnection';
                totalCount?: Maybe<number>;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: Maybe<string>;
                  beforeCursor?: Maybe<string>;
                };
                edges?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'RelationshipItemEdge';
                      cursor?: Maybe<string>;
                      node?: Maybe<{
                        __typename?: 'RelationshipContact';
                        id: string;
                        name?: Maybe<string>;
                        title?: Maybe<string>;
                        objectType?: Maybe<RelationshipObjectType>;
                        reportTo?: Maybe<
                          | {
                              __typename?: 'UserPayload';
                              items?: Maybe<
                                Array<
                                  | {
                                      __typename?: 'User';
                                      id?: Maybe<string>;
                                      primaryId?: Maybe<string>;
                                      firstName?: Maybe<string>;
                                      lastName?: Maybe<string>;
                                      title?: Maybe<string>;
                                      email?: Maybe<string>;
                                    }
                                  | { __typename?: 'GraphqlError' }
                                >
                              >;
                            }
                          | { __typename?: 'GraphqlError' }
                        >;
                        contactRoles?: Maybe<
                          | {
                              __typename?: 'ContactPayload';
                              items?: Maybe<
                                Array<
                                  | {
                                      __typename?: 'ContactRole';
                                      id: string;
                                      name: string;
                                    }
                                  | { __typename?: 'GraphqlError' }
                                >
                              >;
                            }
                          | {
                              __typename: 'GraphqlError';
                              code: string;
                              message: string;
                              type: ErrorType;
                              helpLink?: Maybe<string>;
                            }
                        >;
                        avatar?: Maybe<{
                          __typename?: 'Avatar';
                          url?: Maybe<string>;
                        }>;
                        buyerCompany?: Maybe<
                          | { __typename?: 'Company'; displayName: string }
                          | { __typename?: 'GraphqlError' }
                        >;
                      }>;
                    }>
                  >
                >;
              }
            | { __typename: 'GraphqlError'; type: ErrorType; message: string }
          >;
        }
      | { __typename?: 'GraphqlError' }
    >;
  }>;
};

export type GetAccountPlanByExtCompanyIdQueryVariables = Exact<{
  extId: Scalars['ID'];
}>;

export type GetAccountPlanByExtCompanyIdQuery = {
  __typename?: 'Query';
  accountPlanQuery?: Maybe<{
    __typename?: 'AccountPlanQuery';
    getAccountPlanByExtCompanyId:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'AccountPlan';
                    id: string;
                    extCompanyId: string;
                    companyId: string;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetCompanyDetailsQueryVariables = Exact<{
  input: Scalars['ID'];
}>;

export type GetCompanyDetailsQuery = {
  __typename?: 'Query';
  accountPlanQuery?: Maybe<{
    __typename?: 'AccountPlanQuery';
    getAccountPlan:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'AccountPlan';
                    id: string;
                    companyId: string;
                    orgId: string;
                    sellerCompany?: Maybe<
                      | {
                          __typename?: 'Company';
                          id: string;
                          orgId: string;
                          companyName: string;
                          displayName: string;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                    buyerCompany?: Maybe<
                      | {
                          __typename?: 'Company';
                          id: string;
                          orgId: string;
                          companyName: string;
                          displayName: string;
                          logo?: Maybe<
                            | {
                                __typename?: 'Logo';
                                id: string;
                                fileName?: Maybe<string>;
                                url: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type AddReportsToMutationVariables = Exact<{
  input: AddReporteeOrReportsTo;
}>;

export type AddReportsToMutation = {
  __typename?: 'Mutation';
  managerMutation?: Maybe<{
    __typename?: 'ManagerMutation';
    addReportsTo:
      | {
          __typename: 'ManagerPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Manager';
                  id: string;
                  managerId?: Maybe<string>;
                  order?: Maybe<number>;
                }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                }
            >
          >;
        }
      | { __typename: 'GraphqlError' };
  }>;
};

export type BulkAddAndDeleteReportsToMutationVariables = Exact<{
  input: BulkAddAndDeleteReportsToInput;
}>;

export type BulkAddAndDeleteReportsToMutation = {
  __typename?: 'Mutation';
  managerMutation?: Maybe<{
    __typename?: 'ManagerMutation';
    bulkAddAndDeleteReportsTo:
      | {
          __typename: 'BooleanObject';
          success?: Maybe<boolean>;
          status?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
        };
  }>;
};

export type CloneRelationshipMapMutationVariables = Exact<{
  input: CloneRelationshipMapInput;
}>;

export type CloneRelationshipMapMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: Maybe<{
    __typename?: 'RelationshipMutation';
    cloneRelationshipMap:
      | {
          __typename?: 'RelationshipGraph';
          viewport?: Maybe<{ [key: string]: any }>;
          nodes?: Maybe<
            Array<
              Maybe<{
                __typename?: 'GraphNode';
                id: string;
                dragging?: Maybe<boolean>;
                type?: Maybe<string>;
                height?: Maybe<number>;
                width?: Maybe<number>;
                position?: Maybe<{ [key: string]: any }>;
                positionAbsolute?: Maybe<{ [key: string]: any }>;
                selected?: Maybe<boolean>;
                data?: Maybe<
                  | {
                      __typename?: 'GraphItemData';
                      id?: Maybe<string>;
                      dataPayload?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'DataPayload';
                            key?: Maybe<string>;
                            id?: Maybe<string>;
                            values?: Maybe<
                              Array<Maybe<{ [key: string]: any }>>
                            >;
                          }>
                        >
                      >;
                      metaData?: Maybe<{
                        __typename?: 'GraphItemDataMetaData';
                        structureId?: Maybe<string>;
                        structureType?: Maybe<StructureType>;
                      }>;
                    }
                  | { __typename?: 'GraphqlError' }
                >;
              }>
            >
          >;
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'GraphEdge';
                id: string;
                source?: Maybe<string>;
                sourceHandle?: Maybe<string>;
                type?: Maybe<string>;
                style?: Maybe<{ [key: string]: any }>;
                target?: Maybe<string>;
                targetHandle?: Maybe<string>;
                markerEnd?: Maybe<{ [key: string]: any }>;
                data?: Maybe<
                  | {
                      __typename?: 'GraphItemData';
                      id?: Maybe<string>;
                      dataPayload?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'DataPayload';
                            key?: Maybe<string>;
                            values?: Maybe<
                              Array<Maybe<{ [key: string]: any }>>
                            >;
                          }>
                        >
                      >;
                      metaData?: Maybe<{
                        __typename?: 'GraphItemDataMetaData';
                        structureId?: Maybe<string>;
                        structureType?: Maybe<StructureType>;
                      }>;
                    }
                  | { __typename?: 'GraphqlError' }
                >;
              }>
            >
          >;
        }
      | { __typename?: 'GraphqlError' };
  }>;
};

export type CreateInfluencerMapContactMutationVariables = Exact<{
  input: CreateInfluencerMapContactInput;
}>;

export type CreateInfluencerMapContactMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: Maybe<{
    __typename?: 'RelationshipMutation';
    createInfluencerMapContact:
      | {
          __typename: 'BooleanObject';
          success?: Maybe<boolean>;
          id?: Maybe<string>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
        };
  }>;
};

export type CreateInfluencerGraphMutationVariables = Exact<{
  input: CreateRelationshipGraphInput;
}>;

export type CreateInfluencerGraphMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: Maybe<{
    __typename?: 'RelationshipMutation';
    createRelationshipGraph:
      | {
          __typename: 'RelationshipGraph';
          viewport?: Maybe<{ [key: string]: any }>;
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'GraphEdge';
                id: string;
                source?: Maybe<string>;
                sourceHandle?: Maybe<string>;
                type?: Maybe<string>;
                style?: Maybe<{ [key: string]: any }>;
                target?: Maybe<string>;
                targetHandle?: Maybe<string>;
                markerEnd?: Maybe<{ [key: string]: any }>;
                data?: Maybe<
                  | {
                      __typename?: 'GraphItemData';
                      id?: Maybe<string>;
                      dataPayload?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'DataPayload';
                            id?: Maybe<string>;
                            key?: Maybe<string>;
                            values?: Maybe<
                              Array<Maybe<{ [key: string]: any }>>
                            >;
                          }>
                        >
                      >;
                      metaData?: Maybe<{
                        __typename?: 'GraphItemDataMetaData';
                        structureId?: Maybe<string>;
                        structureType?: Maybe<StructureType>;
                      }>;
                    }
                  | {
                      __typename?: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
          nodes?: Maybe<
            Array<
              Maybe<{
                __typename?: 'GraphNode';
                id: string;
                dragging?: Maybe<boolean>;
                type?: Maybe<string>;
                height?: Maybe<number>;
                width?: Maybe<number>;
                position?: Maybe<{ [key: string]: any }>;
                positionAbsolute?: Maybe<{ [key: string]: any }>;
                selected?: Maybe<boolean>;
                data?: Maybe<
                  | {
                      __typename?: 'GraphItemData';
                      id?: Maybe<string>;
                      dataPayload?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'DataPayload';
                            key?: Maybe<string>;
                            id?: Maybe<string>;
                            values?: Maybe<
                              Array<Maybe<{ [key: string]: any }>>
                            >;
                          }>
                        >
                      >;
                      metaData?: Maybe<{
                        __typename?: 'GraphItemDataMetaData';
                        structureId?: Maybe<string>;
                        structureType?: Maybe<StructureType>;
                      }>;
                    }
                  | {
                      __typename?: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateNewNoteMutationVariables = Exact<{
  input: AddNotesInput;
}>;

export type CreateNewNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: Maybe<{
    __typename?: 'AnnotationMutation';
    addNotes:
      | {
          __typename: 'NotesPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Notes';
                  id: string;
                  annotationType: AnnotationType;
                  createdAt: number;
                  reference: { __typename?: 'Reference'; id: string };
                  messageData: {
                    __typename?: 'MessageData';
                    message: string;
                    isEdited: boolean;
                    editedAt?: Maybe<number>;
                  };
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: Maybe<{
    __typename?: 'AnnotationMutation';
    removeNotes:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type RemoveReportsToMutationVariables = Exact<{
  input: RemoveReporteeOrReportsTo;
}>;

export type RemoveReportsToMutation = {
  __typename?: 'Mutation';
  managerMutation?: Maybe<{
    __typename?: 'ManagerMutation';
    removeReportsTo:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | { __typename: 'GraphqlError' };
  }>;
};

export type SearchCompanyQueryVariables = Exact<{
  operations: Operations;
}>;

export type SearchCompanyQuery = {
  __typename?: 'Query';
  companyQuery?: Maybe<{
    __typename?: 'CompanyQuery';
    searchCompany:
      | {
          __typename: 'CompanyConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'CompanyEdge';
                node?: Maybe<
                  | {
                      __typename?: 'Company';
                      id: string;
                      orgId: string;
                      displayName: string;
                      companyName: string;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchContactsQueryVariables = Exact<{
  searchInput: SearchRelationshipItemInput;
}>;

export type SearchContactsQuery = {
  __typename?: 'Query';
  relationshipQuery?: Maybe<{
    __typename?: 'RelationshipQuery';
    searchRelationshipItems:
      | {
          __typename?: 'RelationshipItemConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'RelationshipItemEdge';
                node?: Maybe<{
                  __typename?: 'RelationshipContact';
                  id: string;
                  name?: Maybe<string>;
                  title?: Maybe<string>;
                  objectType?: Maybe<RelationshipObjectType>;
                  reportTo?: Maybe<
                    | {
                        __typename?: 'UserPayload';
                        items?: Maybe<
                          Array<
                            | {
                                __typename?: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                title?: Maybe<string>;
                                email?: Maybe<string>;
                              }
                            | { __typename?: 'GraphqlError' }
                          >
                        >;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  contactRoles?: Maybe<
                    | {
                        __typename?: 'ContactPayload';
                        items?: Maybe<
                          Array<
                            | {
                                __typename?: 'ContactRole';
                                id: string;
                                name: string;
                              }
                            | { __typename?: 'GraphqlError' }
                          >
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      }
                  >;
                  avatar?: Maybe<{
                    __typename?: 'Avatar';
                    url?: Maybe<string>;
                  }>;
                  buyerCompany?: Maybe<
                    | { __typename?: 'Company'; displayName: string }
                    | { __typename?: 'GraphqlError' }
                  >;
                }>;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchMspsQueryVariables = Exact<{
  searchInput: SearchRelationshipMapsInput;
}>;

export type SearchMspsQuery = {
  __typename?: 'Query';
  relationshipQuery?: Maybe<{
    __typename?: 'RelationshipQuery';
    searchRelationshipMaps:
      | {
          __typename?: 'RelationshipGraphqlPayload';
          edges?: Maybe<
            Array<
              Maybe<
                | {
                    __typename?: 'RelationshipGraph';
                    id?: Maybe<string>;
                    displayName?: Maybe<string>;
                    scope?: Maybe<{
                      __typename?: 'SearchRelationshipMapsScope';
                      scopeId?: Maybe<string>;
                      scopeType?: Maybe<RelationshipScopeType>;
                    }>;
                    analytics?: Maybe<
                      | {
                          __typename?: 'RelationshipGraphAnalytics';
                          contactsCount?: Maybe<number>;
                          nodesCount?: Maybe<number>;
                        }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >
            >
          >;
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        }
      | { __typename?: 'GraphqlError' };
  }>;
};

export type UpdateContactFieldsMutationVariables = Exact<{
  input: UpdateRelationshipItemDataInput;
}>;

export type UpdateContactFieldsMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: Maybe<{
    __typename?: 'RelationshipMutation';
    updateRelationshipItemData:
      | {
          __typename: 'UpdateRelationshipItemDataPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'UpdateRelationshipItemData';
                  id?: Maybe<string>;
                  key?: Maybe<string>;
                  success: boolean;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateExistingNoteMutationVariables = Exact<{
  input: UpdateNotesInput;
}>;

export type UpdateExistingNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: Maybe<{
    __typename?: 'AnnotationMutation';
    updateNotes:
      | {
          __typename: 'NotesPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Notes';
                  id: string;
                  annotationType: AnnotationType;
                  createdAt: number;
                  reference: { __typename?: 'Reference'; id: string };
                  messageData: { __typename?: 'MessageData'; message: string };
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateExistingManagerMutationVariables = Exact<{
  addReporteeOrReportsTo: AddReporteeOrReportsTo;
}>;

export type UpdateExistingManagerMutation = {
  __typename?: 'Mutation';
  managerMutation?: Maybe<{
    __typename?: 'ManagerMutation';
    addReporteeAndRemoveExistingManager:
      | {
          __typename?: 'ManagerPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Manager';
                  id: string;
                  userId: string;
                  managerId?: Maybe<string>;
                  orgId?: Maybe<string>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename?: 'GraphqlError';
          type: ErrorType;
          code: string;
          message: string;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type CreateMilestoneV2MutationVariables = Exact<{
  input: CreateMilestoneInput;
}>;

export type CreateMilestoneV2Mutation = {
  __typename?: 'Mutation';
  milestoneMutation?: Maybe<{
    __typename?: 'MilestoneMutation';
    createMilestoneV2:
      | {
          __typename: 'MilestonePayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Milestone';
                  id: string;
                  name: string;
                  startDate: number;
                  createdAt: number;
                  endDate: number;
                  isInternal: boolean;
                  milestoneDetails?: Maybe<
                    | {
                        __typename?: 'MilestoneDetails';
                        milestoneId: string;
                        goals?: Maybe<string>;
                        riskDependencies?: Maybe<string>;
                        successCriteria?: Maybe<string>;
                      }
                    | {
                        __typename: 'GraphqlError';
                        type: ErrorType;
                        code: string;
                        helpLink?: Maybe<string>;
                        message: string;
                      }
                  >;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MilestoneAnalytics';
                        mediaResourceCount?: Maybe<
                          | {
                              __typename?: 'BaResourceCountObject';
                              internalCount: number;
                              externalCount: number;
                            }
                          | {
                              __typename: 'GraphqlError';
                              type: ErrorType;
                              code: string;
                              helpLink?: Maybe<string>;
                              message: string;
                            }
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        type: ErrorType;
                        code: string;
                        helpLink?: Maybe<string>;
                        message: string;
                      }
                  >;
                  tasks:
                    | {
                        __typename?: 'TaskConnection';
                        edges?: Maybe<
                          Array<
                            Maybe<{
                              __typename?: 'TaskEdge';
                              node?: Maybe<
                                | {
                                    __typename: 'Task';
                                    id: string;
                                    name: string;
                                    dueDate: number;
                                    status: TaskStatus;
                                    description?: Maybe<string>;
                                    isInternal: boolean;
                                    assignee?: Maybe<
                                      | {
                                          __typename?: 'User';
                                          id?: Maybe<string>;
                                          firstName?: Maybe<string>;
                                          lastName?: Maybe<string>;
                                          email?: Maybe<string>;
                                          userType: UserType;
                                        }
                                      | { __typename?: 'Placeholder' }
                                      | { __typename?: 'GraphqlError' }
                                    >;
                                  }
                                | {
                                    __typename: 'GraphqlError';
                                    code: string;
                                    message: string;
                                    type: ErrorType;
                                    helpLink?: Maybe<string>;
                                  }
                              >;
                            }>
                          >
                        >;
                      }
                    | {
                        __typename?: 'GraphqlError';
                        code: string;
                        type: ErrorType;
                        message: string;
                      };
                  media?: Maybe<
                    | {
                        __typename?: 'MediaConnection';
                        edges?: Maybe<
                          Array<
                            Maybe<{
                              __typename?: 'MediaEdge';
                              node?: Maybe<
                                | {
                                    __typename: 'MediaPayloadItem';
                                    item:
                                      | {
                                          __typename: 'Audio';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          url: string;
                                          fileAccessUrl?: Maybe<string>;
                                          fileDownloadUrl?: Maybe<string>;
                                          fileSize?: Maybe<number>;
                                          fileName?: Maybe<string>;
                                          source?: Maybe<string>;
                                          fileShareUrl?: Maybe<string>;
                                          isInternal?: Maybe<boolean>;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                          analytics?: Maybe<
                                            | {
                                                __typename: 'MediaAnalytics';
                                                belongsTo?: Maybe<
                                                  | {
                                                      __typename: 'MediaBelongsTo';
                                                      id: string;
                                                      name: string;
                                                      displayName: string;
                                                      objectType: string;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      type: ErrorType;
                                                      code: string;
                                                      helpLink?: Maybe<string>;
                                                      message: string;
                                                    }
                                                >;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              }
                                          >;
                                          context?: Maybe<
                                            | {
                                                __typename: 'MediaContext';
                                                isPin?: Maybe<boolean>;
                                                isFavorite?: Maybe<boolean>;
                                              }
                                            | { __typename: 'GraphqlError' }
                                          >;
                                        }
                                      | {
                                          __typename: 'Document';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          url: string;
                                          fileAccessUrl?: Maybe<string>;
                                          fileDownloadUrl?: Maybe<string>;
                                          fileSize?: Maybe<number>;
                                          fileName?: Maybe<string>;
                                          source?: Maybe<string>;
                                          fileShareUrl?: Maybe<string>;
                                          isInternal?: Maybe<boolean>;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                          analytics?: Maybe<
                                            | {
                                                __typename: 'MediaAnalytics';
                                                belongsTo?: Maybe<
                                                  | {
                                                      __typename: 'MediaBelongsTo';
                                                      id: string;
                                                      name: string;
                                                      displayName: string;
                                                      objectType: string;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      type: ErrorType;
                                                      code: string;
                                                      helpLink?: Maybe<string>;
                                                      message: string;
                                                    }
                                                >;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              }
                                          >;
                                          context?: Maybe<
                                            | {
                                                __typename: 'MediaContext';
                                                isPin?: Maybe<boolean>;
                                                isFavorite?: Maybe<boolean>;
                                              }
                                            | { __typename: 'GraphqlError' }
                                          >;
                                        }
                                      | {
                                          __typename: 'Image';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          url: string;
                                          fileAccessUrl?: Maybe<string>;
                                          fileDownloadUrl?: Maybe<string>;
                                          fileSize?: Maybe<number>;
                                          fileName?: Maybe<string>;
                                          source?: Maybe<string>;
                                          fileShareUrl?: Maybe<string>;
                                          isInternal?: Maybe<boolean>;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                          analytics?: Maybe<
                                            | {
                                                __typename: 'MediaAnalytics';
                                                belongsTo?: Maybe<
                                                  | {
                                                      __typename: 'MediaBelongsTo';
                                                      id: string;
                                                      name: string;
                                                      displayName: string;
                                                      objectType: string;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      type: ErrorType;
                                                      code: string;
                                                      helpLink?: Maybe<string>;
                                                      message: string;
                                                    }
                                                >;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              }
                                          >;
                                          context?: Maybe<
                                            | {
                                                __typename: 'MediaContext';
                                                isPin?: Maybe<boolean>;
                                                isFavorite?: Maybe<boolean>;
                                              }
                                            | { __typename: 'GraphqlError' }
                                          >;
                                        }
                                      | {
                                          __typename: 'Link';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          fileSize?: Maybe<number>;
                                          fileName?: Maybe<string>;
                                          source?: Maybe<string>;
                                          fileShareUrl?: Maybe<string>;
                                          fileAccessUrl?: Maybe<string>;
                                          fileDownloadUrl?: Maybe<string>;
                                          isInternal?: Maybe<boolean>;
                                          link: string;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                          analytics?: Maybe<
                                            | {
                                                __typename: 'MediaAnalytics';
                                                belongsTo?: Maybe<
                                                  | {
                                                      __typename: 'MediaBelongsTo';
                                                      id: string;
                                                      name: string;
                                                      displayName: string;
                                                      objectType: string;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      type: ErrorType;
                                                      code: string;
                                                      helpLink?: Maybe<string>;
                                                      message: string;
                                                    }
                                                >;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              }
                                          >;
                                          context?: Maybe<
                                            | {
                                                __typename: 'MediaContext';
                                                isPin?: Maybe<boolean>;
                                                isFavorite?: Maybe<boolean>;
                                              }
                                            | { __typename: 'GraphqlError' }
                                          >;
                                        }
                                      | {
                                          __typename: 'Logo';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                        }
                                      | {
                                          __typename: 'Thumbnail';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                        }
                                      | {
                                          __typename: 'Video';
                                          id: string;
                                          name?: Maybe<string>;
                                          orgId: string;
                                          description?: Maybe<string>;
                                          mediaType: MediaType;
                                          mimeType?: Maybe<string>;
                                          createdAt: number;
                                          url: string;
                                          fileAccessUrl?: Maybe<string>;
                                          fileDownloadUrl?: Maybe<string>;
                                          fileSize?: Maybe<number>;
                                          fileName?: Maybe<string>;
                                          source?: Maybe<string>;
                                          fileShareUrl?: Maybe<string>;
                                          isInternal?: Maybe<boolean>;
                                          createdBy:
                                            | {
                                                __typename: 'User';
                                                id?: Maybe<string>;
                                                firstName?: Maybe<string>;
                                                lastName?: Maybe<string>;
                                                email?: Maybe<string>;
                                                userType: UserType;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              };
                                          analytics?: Maybe<
                                            | {
                                                __typename: 'MediaAnalytics';
                                                belongsTo?: Maybe<
                                                  | {
                                                      __typename: 'MediaBelongsTo';
                                                      id: string;
                                                      name: string;
                                                      displayName: string;
                                                      objectType: string;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      type: ErrorType;
                                                      code: string;
                                                      helpLink?: Maybe<string>;
                                                      message: string;
                                                    }
                                                >;
                                              }
                                            | {
                                                __typename: 'GraphqlError';
                                                type: ErrorType;
                                                code: string;
                                                helpLink?: Maybe<string>;
                                                message: string;
                                              }
                                          >;
                                          context?: Maybe<
                                            | {
                                                __typename: 'MediaContext';
                                                isPin?: Maybe<boolean>;
                                                isFavorite?: Maybe<boolean>;
                                              }
                                            | { __typename: 'GraphqlError' }
                                          >;
                                        };
                                  }
                                | { __typename: 'GraphqlError' }
                              >;
                            }>
                          >
                        >;
                      }
                    | {
                        __typename: 'GraphqlError';
                        type: ErrorType;
                        code: string;
                        helpLink?: Maybe<string>;
                        message: string;
                      }
                  >;
                }
              | { __typename: 'BaseMilestone' }
              | {
                  __typename: 'GraphqlError';
                  type: ErrorType;
                  code: string;
                  helpLink?: Maybe<string>;
                  message: string;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type GetMspWithMilestonesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMspWithMilestonesQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename?: 'MspQuery';
    getMsp:
      | {
          __typename: 'MspPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Msp';
                  milestones:
                    | {
                        __typename?: 'MilestoneConnection';
                        totalCount?: Maybe<number>;
                        edges?: Maybe<
                          Array<
                            Maybe<{
                              __typename?: 'MilestoneEdge';
                              node?: Maybe<
                                | {
                                    __typename: 'Milestone';
                                    id: string;
                                    name: string;
                                    createdAt: number;
                                    startDate: number;
                                    endDate: number;
                                    isInternal: boolean;
                                    milestoneDetails?: Maybe<
                                      | {
                                          __typename?: 'MilestoneDetails';
                                          goals?: Maybe<string>;
                                          successCriteria?: Maybe<string>;
                                          riskDependencies?: Maybe<string>;
                                        }
                                      | {
                                          __typename?: 'GraphqlError';
                                          code: string;
                                          message: string;
                                          type: ErrorType;
                                          helpLink?: Maybe<string>;
                                        }
                                    >;
                                    tasks:
                                      | {
                                          __typename?: 'TaskConnection';
                                          edges?: Maybe<
                                            Array<
                                              Maybe<{
                                                __typename?: 'TaskEdge';
                                                node?: Maybe<
                                                  | {
                                                      __typename: 'Task';
                                                      id: string;
                                                      name: string;
                                                      dueDate: number;
                                                      status: TaskStatus;
                                                      milestoneId?: Maybe<string>;
                                                      isInternal: boolean;
                                                      analytics?: Maybe<
                                                        | {
                                                            __typename: 'TaskAnalytics';
                                                            commentCount?: Maybe<
                                                              | {
                                                                  __typename: 'IntObject';
                                                                  value: number;
                                                                }
                                                              | {
                                                                  __typename: 'GraphqlError';
                                                                }
                                                            >;
                                                          }
                                                        | {
                                                            __typename: 'GraphqlError';
                                                            code: string;
                                                            message: string;
                                                            type: ErrorType;
                                                            helpLink?: Maybe<string>;
                                                          }
                                                      >;
                                                      assignee?: Maybe<
                                                        | {
                                                            __typename?: 'User';
                                                            id?: Maybe<string>;
                                                            firstName?: Maybe<string>;
                                                            lastName?: Maybe<string>;
                                                            email?: Maybe<string>;
                                                            userType: UserType;
                                                          }
                                                        | {
                                                            __typename?: 'Placeholder';
                                                          }
                                                        | {
                                                            __typename?: 'GraphqlError';
                                                          }
                                                      >;
                                                    }
                                                  | {
                                                      __typename: 'GraphqlError';
                                                      code: string;
                                                      message: string;
                                                      type: ErrorType;
                                                      helpLink?: Maybe<string>;
                                                    }
                                                >;
                                              }>
                                            >
                                          >;
                                        }
                                      | {
                                          __typename?: 'GraphqlError';
                                          code: string;
                                          message: string;
                                          type: ErrorType;
                                          helpLink?: Maybe<string>;
                                        };
                                  }
                                | { __typename: 'BaseMilestone' }
                                | {
                                    __typename: 'GraphqlError';
                                    code: string;
                                    message: string;
                                    type: ErrorType;
                                    helpLink?: Maybe<string>;
                                  }
                              >;
                            }>
                          >
                        >;
                      }
                    | {
                        __typename?: 'GraphqlError';
                        code: string;
                        message: string;
                        type: ErrorType;
                        helpLink?: Maybe<string>;
                      };
                }
              | { __typename?: 'BaseMsp' }
              | {
                  __typename?: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: Maybe<string>;
                }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateMeddpiccForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateMeddpiccInput;
}>;

export type UpdateMeddpiccForMspMutation = {
  __typename?: 'Mutation';
  meddpiccMutation?: Maybe<{
    __typename?: 'MeddpiccMutation';
    updateMeddpicc:
      | {
          __typename: 'MeddpiccPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddpicc';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  paperProcess?: Maybe<{
                    __typename?: 'MethodologyTaskQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyTaskQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'Task';
                                id: string;
                                name: string;
                                dueDate: number;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                email?: Maybe<string>;
                                lastName?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  competition?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type SyncMeddpiccMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: SyncMeddpiccInput;
}>;

export type SyncMeddpiccMutation = {
  __typename?: 'Mutation';
  meddpiccMutation?: Maybe<{
    __typename?: 'MeddpiccMutation';
    syncMeddpicc:
      | {
          __typename: 'MeddpiccPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddpicc';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  paperProcess?: Maybe<{
                    __typename?: 'MethodologyTaskQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyTaskQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'Task';
                                id: string;
                                name: string;
                                dueDate: number;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  competition?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type UpdateMeddicForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateMeddicInput;
}>;

export type UpdateMeddicForMspMutation = {
  __typename?: 'Mutation';
  meddicMutation?: Maybe<{
    __typename?: 'MeddicMutation';
    updateMeddic:
      | {
          __typename: 'MeddicPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddic';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                email?: Maybe<string>;
                                lastName?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type SyncMeddicMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: SyncMeddicInput;
}>;

export type SyncMeddicMutation = {
  __typename?: 'Mutation';
  meddicMutation?: Maybe<{
    __typename?: 'MeddicMutation';
    syncMeddic:
      | {
          __typename: 'MeddicPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddic';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type UpdateMeddpiccpaForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateMeddpiccpaInput;
}>;

export type UpdateMeddpiccpaForMspMutation = {
  __typename?: 'Mutation';
  meddpiccpaMutation?: Maybe<{
    __typename: 'MeddpiccpaMutation';
    updateMeddpiccpa:
      | {
          __typename: 'MeddpiccpaPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'Meddpiccpa';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  paperProcess?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  competition?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  product?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  additionalInfo?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                }
              | { __typename: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type UpdatevalueSellingForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateValueSellingInput;
}>;

export type UpdatevalueSellingForMspMutation = {
  __typename?: 'Mutation';
  valueSellingMutation?: Maybe<{
    __typename: 'ValueSellingMutation';
    updateValueSelling:
      | {
          __typename: 'ValueSellingPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'ValueSelling';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename: 'GraphqlError' }
                  >;
                  objectivesAndSuccessMeasures?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  costofInaction?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  roi?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  urgency?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  ourProof?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  competitiveDifferentiation?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  decisionMakerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  influencerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  catalystInfluencerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  processUnderstandingAndAlignment?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  purchaseRequirements?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  demoQuality?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  funding?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  keyThreats?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                }
              | { __typename: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetMeddpiccForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMeddpiccForMspQuery = {
  __typename?: 'Query';
  meddpiccQuery?: Maybe<{
    __typename?: 'MeddpiccQuery';
    getMeddpiccForMsp:
      | {
          __typename?: 'MeddpiccPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddpicc';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  paperProcess?: Maybe<{
                    __typename?: 'MethodologyTaskQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyTaskQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'Task';
                                id: string;
                                name: string;
                                dueDate: number;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  competition?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetMeddicForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMeddicForMspQuery = {
  __typename?: 'Query';
  meddicQuery?: Maybe<{
    __typename?: 'MeddicQuery';
    getMeddicForMsp:
      | {
          __typename?: 'MeddicPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddic';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetMeddpiccpaForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMeddpiccpaForMspQuery = {
  __typename?: 'Query';
  meddpiccpaQuery?: Maybe<{
    __typename?: 'MeddpiccpaQuery';
    getMeddpiccpaForMsp:
      | {
          __typename?: 'MeddpiccpaPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'Meddpiccpa';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename?: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename?: 'GraphqlError' }
                  >;
                  metrics?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  paperProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  economicBuyer?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename?: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename?: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  decisionProcess?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  decisionCriteria?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  identifiedPain?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  champion?: Maybe<{
                    __typename?: 'MethodologyUserQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyUserQualifierData';
                      score?: Maybe<number>;
                      data?: Maybe<
                        Array<
                          Maybe<
                            | {
                                __typename?: 'User';
                                id?: Maybe<string>;
                                primaryId?: Maybe<string>;
                                firstName?: Maybe<string>;
                                lastName?: Maybe<string>;
                                email?: Maybe<string>;
                                userType: UserType;
                              }
                            | { __typename?: 'GraphqlError' }
                          >
                        >
                      >;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                  }>;
                  competition?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  product?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                  additionalInfo?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename?: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename?: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetValueSellingForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetValueSellingForMspQuery = {
  __typename?: 'Query';
  valueSellingQuery?: Maybe<{
    __typename: 'ValueSellingQuery';
    getValueSellingForMsp:
      | {
          __typename: 'ValueSellingPayload';
          items?: Maybe<
            Array<
              | {
                  __typename: 'ValueSelling';
                  id: string;
                  mspId: string;
                  analytics?: Maybe<
                    | {
                        __typename: 'MethodologyAnalytics';
                        isSyncEnabled: boolean;
                        syncDisableReason?: Maybe<string>;
                        lastSyncedAt?: Maybe<number>;
                        isSynced: boolean;
                        firstSyncDone: boolean;
                      }
                    | { __typename: 'GraphqlError' }
                  >;
                  objectivesAndSuccessMeasures?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  costofInaction?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  roi?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  urgency?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  ourProof?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  competitiveDifferentiation?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  decisionMakerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  influencerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  catalystInfluencerEngagement?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  processUnderstandingAndAlignment?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  purchaseRequirements?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  demoQuality?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  funding?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                  keyThreats?: Maybe<{
                    __typename: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename: 'MethodologyStringQualifierData';
                      data?: Maybe<string>;
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                    analytics?: Maybe<{
                      __typename: 'MethodologyQualifierAnalytics';
                      isSynced: boolean;
                    }>;
                    metadata?: Maybe<{
                      __typename: 'MethodologyQualifierMetadata';
                      isDataEditable?: Maybe<boolean>;
                      displayName?: Maybe<string>;
                      description?: Maybe<string>;
                      defaultData?: Maybe<string>;
                      score?: Maybe<{
                        __typename: 'MethodologyQualifierScoreSettings';
                        placeholder?: Maybe<string>;
                        options?: Maybe<
                          Array<{
                            __typename: 'MethodologyQualifierScoreOptions';
                            displayName: string;
                            order: number;
                            value: number;
                          }>
                        >;
                      }>;
                    }>;
                  }>;
                }
              | { __typename: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type SyncVvsMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: SyncValueSellingInput;
}>;

export type SyncVvsMutation = {
  __typename?: 'Mutation';
  valueSellingMutation?: Maybe<{
    __typename?: 'ValueSellingMutation';
    syncValueSelling:
      | {
          __typename?: 'ValueSellingPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'ValueSelling';
                  keyThreats?: Maybe<{
                    __typename?: 'MethodologyStringQualifier';
                    current?: Maybe<{
                      __typename?: 'MethodologyStringQualifierData';
                      score?: Maybe<number>;
                      notes: Array<
                        Maybe<{
                          __typename?: 'MethodologyQualifierNotes';
                          value: string;
                        }>
                      >;
                    }>;
                  }>;
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type CreateExternalLinkMutationMutationVariables = Exact<{
  input: ExternalLinkInput;
}>;

export type CreateExternalLinkMutationMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    createExternalLink:
      | { __typename: 'MediaExternalLink'; url?: Maybe<string> }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type FavoriteResourceMutationVariables = Exact<{
  input: FavoriteMediaInput;
}>;

export type FavoriteResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    favoriteMedia:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type PinResourceMutationVariables = Exact<{
  input: PinMediaInput;
}>;

export type PinResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    pinMedia:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type RenameFileMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;

export type RenameFileMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    updateMedia:
      | {
          __typename: 'MediaPayload';
          items?: Maybe<
            Array<
              | {
                  __typename?: 'MediaPayloadItem';
                  item:
                    | {
                        __typename?: 'Audio';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Document';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Image';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Link';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Logo';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Thumbnail';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      }
                    | {
                        __typename?: 'Video';
                        id: string;
                        name?: Maybe<string>;
                        description?: Maybe<string>;
                      };
                }
              | { __typename?: 'GraphqlError' }
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type RevokeExternalAccessMutationVariables = Exact<{
  input: RevokeMediaExternalAccessInput;
}>;

export type RevokeExternalAccessMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    revokeExternalAccess:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UnFavoriteResourceMutationVariables = Exact<{
  input: UnfavoriteMediaInput;
}>;

export type UnFavoriteResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    unfavoriteMedia:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UnpinResourceMutationVariables = Exact<{
  input: UnpinMediaInput;
}>;

export type UnpinResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: Maybe<{
    __typename?: 'MediaMutation';
    unpinMedia:
      | {
          __typename: 'BooleanObject';
          id?: Maybe<string>;
          success?: Maybe<boolean>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetStakeholderUserEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: Maybe<GetUserEngagementRequest>;
}>;

export type GetStakeholderUserEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getUserEngagement:
      | {
          __typename?: 'UserEngagementConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'UserEngagementEdge';
                node?: Maybe<
                  | {
                      __typename: 'UserEngagement';
                      id: string;
                      mspViewCount: number;
                      mediaViewCount: number;
                      mediaDownloadCount: number;
                      commentCount: number;
                      user?: Maybe<
                        | { __typename: 'User'; firstName?: Maybe<string> }
                        | { __typename: 'GraphqlError' }
                      >;
                      userTasksAnalytics?: Maybe<
                        | {
                            __typename?: 'UserTaskAnalytics';
                            overdueTask?: Maybe<{
                              __typename?: 'UserTaskStatusDetails';
                              count: number;
                            }>;
                            assignedTask?: Maybe<{
                              __typename?: 'UserTaskStatusDetails';
                              count: number;
                            }>;
                            completedTask?: Maybe<{
                              __typename?: 'UserTaskStatusDetails';
                              count: number;
                            }>;
                          }
                        | { __typename?: 'GraphqlError' }
                      >;
                    }
                  | { __typename: 'GraphqlError' }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type GetMspByExtOpportunityIdQueryVariables = Exact<{
  extId: Scalars['ID'];
}>;

export type GetMspByExtOpportunityIdQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename?: 'MspQuery';
    getMspByExtOpportunityId:
      | { __typename?: 'Msp'; id: string; name: string }
      | { __typename?: 'BaseMsp' }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetFilteredMspListQueryVariables = Exact<{
  mspOperations?: Maybe<Operations>;
  pageConfiguration?: Maybe<PageConfiguration>;
}>;

export type GetFilteredMspListQuery = {
  __typename?: 'Query';
  mspQuery?: Maybe<{
    __typename?: 'MspQuery';
    listMspsToLinkInCRM:
      | {
          __typename?: 'MspConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            afterCursor?: Maybe<string>;
            beforeCursor?: Maybe<string>;
          };
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'MspEdge';
                node?: Maybe<
                  | {
                      __typename?: 'Msp';
                      id: string;
                      name: string;
                      isOwner?: Maybe<boolean>;
                      buyerCompany:
                        | {
                            __typename: 'Company';
                            id: string;
                            logo?: Maybe<
                              | {
                                  __typename?: 'Logo';
                                  id: string;
                                  fileName?: Maybe<string>;
                                  url: string;
                                }
                              | { __typename?: 'GraphqlError' }
                            >;
                          }
                        | {
                            __typename: 'GraphqlError';
                            code: string;
                            message: string;
                            type: ErrorType;
                            helpLink?: Maybe<string>;
                          };
                    }
                  | { __typename?: 'BaseMsp' }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type UpdateMspOpportunityMutationVariables = Exact<{
  input: LinkOpportunityInput;
}>;

export type UpdateMspOpportunityMutation = {
  __typename?: 'Mutation';
  mspMutation?: Maybe<{
    __typename?: 'MspMutation';
    linkOpportunity:
      | {
          __typename: 'BooleanObject';
          success?: Maybe<boolean>;
          id?: Maybe<string>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type SearchOpportunityByExtIdQueryVariables = Exact<{
  opportunityOperation?: Maybe<Operations>;
}>;

export type SearchOpportunityByExtIdQuery = {
  __typename?: 'Query';
  opportunityQuery?: Maybe<{
    __typename?: 'OpportunityQuery';
    searchOpportunity:
      | {
          __typename?: 'OpportunityConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'OpportunityEdge';
                node?: Maybe<
                  | {
                      __typename: 'Opportunity';
                      id: string;
                      name: string;
                      accountId: string;
                    }
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: Maybe<string>;
                    }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: Maybe<string>;
        };
  }>;
};

export type GetMspActivityTimelineQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: Maybe<MspActivityTimeseriesQueryOptions>;
}>;

export type GetMspActivityTimelineQuery = {
  __typename?: 'Query';
  activityTimelineQuery?: Maybe<{
    __typename?: 'ActivityTimelineQuery';
    getMspActivityTimeseries:
      | {
          __typename?: 'MspActivityTimeseries';
          mspId: string;
          timeline?: Maybe<
            Array<
              Maybe<{
                __typename?: 'MspActivityTimeseriesData';
                timestamp: number;
                activityCount: number;
                buyerActivityCount?: Maybe<number>;
                sellerActivityCount?: Maybe<number>;
                userCount?: Maybe<number>;
                buyerUserIds?: Maybe<Array<string>>;
                sellerUserIds?: Maybe<Array<string>>;
                timeParameterDisplayName: string;
              }>
            >
          >;
          timelineUsers?: Maybe<
            | {
                __typename?: 'UserPayload';
                items?: Maybe<
                  Array<
                    | {
                        __typename?: 'User';
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        id?: Maybe<string>;
                        email?: Maybe<string>;
                      }
                    | {
                        __typename: 'GraphqlError';
                        type: ErrorType;
                        code: string;
                        message: string;
                      }
                  >
                >;
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

type AssetsFragment_Audio_Fragment = {
  __typename: 'Audio';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

type AssetsFragment_Document_Fragment = {
  __typename: 'Document';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

type AssetsFragment_Image_Fragment = {
  __typename: 'Image';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

type AssetsFragment_Video_Fragment = {
  __typename: 'Video';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type AssetsFragmentFragment =
  | AssetsFragment_Audio_Fragment
  | AssetsFragment_Document_Fragment
  | AssetsFragment_Image_Fragment
  | AssetsFragment_Video_Fragment;

export type ImageFragmentFragment = {
  __typename: 'Image';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type AudioFragmentFragment = {
  __typename: 'Audio';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type DocumentFragmentFragment = {
  __typename: 'Document';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type VideoFragmentFragment = {
  __typename: 'Video';
  url: string;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type LinkFragmentFragment = {
  __typename: 'Link';
  fileSize?: Maybe<number>;
  fileName?: Maybe<string>;
  source?: Maybe<string>;
  fileShareUrl?: Maybe<string>;
  fileAccessUrl?: Maybe<string>;
  fileDownloadUrl?: Maybe<string>;
  isInternal?: Maybe<boolean>;
  link: string;
  name?: Maybe<string>;
  analytics?: Maybe<
    | {
        __typename: 'MediaAnalytics';
        belongsTo?: Maybe<
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            }
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: Maybe<string>;
              message: string;
            }
        >;
      }
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: Maybe<string>;
        message: string;
      }
  >;
  context?: Maybe<
    | {
        __typename: 'MediaContext';
        isPin?: Maybe<boolean>;
        isFavorite?: Maybe<boolean>;
      }
    | { __typename: 'GraphqlError' }
  >;
};

export type GetMspEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMspEngagement?: Maybe<
      | {
          __typename?: 'MspEngagementDetails';
          internalUserEngagement?: Maybe<{
            __typename?: 'MspEngagement';
            lastActivity?: Maybe<{
              __typename?: 'LatestActivity';
              activityAt?: Maybe<number>;
              activityById?: Maybe<string>;
              activityBy?: Maybe<
                | {
                    __typename: 'User';
                    id?: Maybe<string>;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email?: Maybe<string>;
                    userType: UserType;
                  }
                | { __typename: 'GraphqlError' }
              >;
              role?: Maybe<
                | {
                    __typename?: 'RolePayload';
                    items?: Maybe<
                      Array<
                        | { __typename?: 'Role'; name: string }
                        | {
                            __typename: 'GraphqlError';
                            type: ErrorType;
                            code: string;
                            message: string;
                          }
                      >
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >;
            }>;
            user?: Maybe<{
              __typename?: 'MspEngagementUserDetails';
              count?: Maybe<number>;
            }>;
            msp?: Maybe<{
              __typename?: 'EngagementCount';
              viewCount?: Maybe<number>;
            }>;
            comment?: Maybe<{
              __typename?: 'EngagementCount';
              commentCount?: Maybe<number>;
            }>;
          }>;
          externalUserEngagement?: Maybe<{
            __typename?: 'MspEngagement';
            lastActivity?: Maybe<{
              __typename?: 'LatestActivity';
              activityAt?: Maybe<number>;
              activityById?: Maybe<string>;
              activityBy?: Maybe<
                | {
                    __typename: 'User';
                    id?: Maybe<string>;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email?: Maybe<string>;
                    userType: UserType;
                  }
                | { __typename: 'GraphqlError' }
              >;
              role?: Maybe<
                | {
                    __typename?: 'RolePayload';
                    items?: Maybe<
                      Array<
                        | { __typename?: 'Role'; name: string }
                        | {
                            __typename: 'GraphqlError';
                            type: ErrorType;
                            code: string;
                            message: string;
                          }
                      >
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >;
            }>;
            user?: Maybe<{
              __typename?: 'MspEngagementUserDetails';
              count?: Maybe<number>;
              engagementUsers?: Maybe<
                | {
                    __typename?: 'MspEngagmentUsersLatestActivity';
                    items?: Maybe<
                      Array<{
                        __typename?: 'UserActivity';
                        activityAt?: Maybe<number>;
                        activityById?: Maybe<string>;
                        activityBy?: Maybe<
                          | {
                              __typename: 'User';
                              id?: Maybe<string>;
                              firstName?: Maybe<string>;
                              lastName?: Maybe<string>;
                              email?: Maybe<string>;
                              userType: UserType;
                            }
                          | { __typename: 'GraphqlError' }
                        >;
                        role?: Maybe<
                          | {
                              __typename?: 'RolePayload';
                              items?: Maybe<
                                Array<
                                  | { __typename?: 'Role'; name: string }
                                  | {
                                      __typename: 'GraphqlError';
                                      type: ErrorType;
                                      code: string;
                                      message: string;
                                    }
                                >
                              >;
                            }
                          | { __typename?: 'GraphqlError' }
                        >;
                      }>
                    >;
                  }
                | { __typename?: 'GraphqlError' }
              >;
            }>;
            msp?: Maybe<{
              __typename?: 'EngagementCount';
              viewCount?: Maybe<number>;
            }>;
            comment?: Maybe<{
              __typename?: 'EngagementCount';
              commentCount?: Maybe<number>;
            }>;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        }
    >;
  }>;
};

export type GetMediaEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: Maybe<GetMediaEngagementRequest>;
}>;

export type GetMediaEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMediaEngagement:
      | {
          __typename?: 'MediaEngagementConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'MediaEngagementEdge';
                node?: Maybe<
                  | {
                      __typename: 'MediaEngagement';
                      id: string;
                      downloadCount: number;
                      viewCount: number;
                      media?: Maybe<
                        | {
                            __typename: 'MediaConnection';
                            edges?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'MediaEdge';
                                  node?: Maybe<
                                    | {
                                        __typename: 'MediaPayloadItem';
                                        item:
                                          | {
                                              __typename: 'Audio';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              url: string;
                                              fileAccessUrl?: Maybe<string>;
                                              fileDownloadUrl?: Maybe<string>;
                                              fileSize?: Maybe<number>;
                                              fileName?: Maybe<string>;
                                              source?: Maybe<string>;
                                              fileShareUrl?: Maybe<string>;
                                              isInternal?: Maybe<boolean>;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                              analytics?: Maybe<
                                                | {
                                                    __typename: 'MediaAnalytics';
                                                    belongsTo?: Maybe<
                                                      | {
                                                          __typename: 'MediaBelongsTo';
                                                          id: string;
                                                          name: string;
                                                          displayName: string;
                                                          objectType: string;
                                                        }
                                                      | {
                                                          __typename: 'GraphqlError';
                                                          type: ErrorType;
                                                          code: string;
                                                          helpLink?: Maybe<string>;
                                                          message: string;
                                                        }
                                                    >;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  }
                                              >;
                                              context?: Maybe<
                                                | {
                                                    __typename: 'MediaContext';
                                                    isPin?: Maybe<boolean>;
                                                    isFavorite?: Maybe<boolean>;
                                                  }
                                                | { __typename: 'GraphqlError' }
                                              >;
                                            }
                                          | {
                                              __typename: 'Document';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              url: string;
                                              fileAccessUrl?: Maybe<string>;
                                              fileDownloadUrl?: Maybe<string>;
                                              fileSize?: Maybe<number>;
                                              fileName?: Maybe<string>;
                                              source?: Maybe<string>;
                                              fileShareUrl?: Maybe<string>;
                                              isInternal?: Maybe<boolean>;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                              analytics?: Maybe<
                                                | {
                                                    __typename: 'MediaAnalytics';
                                                    belongsTo?: Maybe<
                                                      | {
                                                          __typename: 'MediaBelongsTo';
                                                          id: string;
                                                          name: string;
                                                          displayName: string;
                                                          objectType: string;
                                                        }
                                                      | {
                                                          __typename: 'GraphqlError';
                                                          type: ErrorType;
                                                          code: string;
                                                          helpLink?: Maybe<string>;
                                                          message: string;
                                                        }
                                                    >;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  }
                                              >;
                                              context?: Maybe<
                                                | {
                                                    __typename: 'MediaContext';
                                                    isPin?: Maybe<boolean>;
                                                    isFavorite?: Maybe<boolean>;
                                                  }
                                                | { __typename: 'GraphqlError' }
                                              >;
                                            }
                                          | {
                                              __typename: 'Image';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              url: string;
                                              fileAccessUrl?: Maybe<string>;
                                              fileDownloadUrl?: Maybe<string>;
                                              fileSize?: Maybe<number>;
                                              fileName?: Maybe<string>;
                                              source?: Maybe<string>;
                                              fileShareUrl?: Maybe<string>;
                                              isInternal?: Maybe<boolean>;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                              analytics?: Maybe<
                                                | {
                                                    __typename: 'MediaAnalytics';
                                                    belongsTo?: Maybe<
                                                      | {
                                                          __typename: 'MediaBelongsTo';
                                                          id: string;
                                                          name: string;
                                                          displayName: string;
                                                          objectType: string;
                                                        }
                                                      | {
                                                          __typename: 'GraphqlError';
                                                          type: ErrorType;
                                                          code: string;
                                                          helpLink?: Maybe<string>;
                                                          message: string;
                                                        }
                                                    >;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  }
                                              >;
                                              context?: Maybe<
                                                | {
                                                    __typename: 'MediaContext';
                                                    isPin?: Maybe<boolean>;
                                                    isFavorite?: Maybe<boolean>;
                                                  }
                                                | { __typename: 'GraphqlError' }
                                              >;
                                            }
                                          | {
                                              __typename: 'Link';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              fileSize?: Maybe<number>;
                                              fileName?: Maybe<string>;
                                              source?: Maybe<string>;
                                              fileShareUrl?: Maybe<string>;
                                              fileAccessUrl?: Maybe<string>;
                                              fileDownloadUrl?: Maybe<string>;
                                              isInternal?: Maybe<boolean>;
                                              link: string;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                              analytics?: Maybe<
                                                | {
                                                    __typename: 'MediaAnalytics';
                                                    belongsTo?: Maybe<
                                                      | {
                                                          __typename: 'MediaBelongsTo';
                                                          id: string;
                                                          name: string;
                                                          displayName: string;
                                                          objectType: string;
                                                        }
                                                      | {
                                                          __typename: 'GraphqlError';
                                                          type: ErrorType;
                                                          code: string;
                                                          helpLink?: Maybe<string>;
                                                          message: string;
                                                        }
                                                    >;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  }
                                              >;
                                              context?: Maybe<
                                                | {
                                                    __typename: 'MediaContext';
                                                    isPin?: Maybe<boolean>;
                                                    isFavorite?: Maybe<boolean>;
                                                  }
                                                | { __typename: 'GraphqlError' }
                                              >;
                                            }
                                          | {
                                              __typename: 'Logo';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                            }
                                          | {
                                              __typename: 'Thumbnail';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                            }
                                          | {
                                              __typename: 'Video';
                                              id: string;
                                              name?: Maybe<string>;
                                              orgId: string;
                                              description?: Maybe<string>;
                                              mediaType: MediaType;
                                              mimeType?: Maybe<string>;
                                              createdAt: number;
                                              url: string;
                                              fileAccessUrl?: Maybe<string>;
                                              fileDownloadUrl?: Maybe<string>;
                                              fileSize?: Maybe<number>;
                                              fileName?: Maybe<string>;
                                              source?: Maybe<string>;
                                              fileShareUrl?: Maybe<string>;
                                              isInternal?: Maybe<boolean>;
                                              createdBy:
                                                | {
                                                    __typename: 'User';
                                                    id?: Maybe<string>;
                                                    firstName?: Maybe<string>;
                                                    lastName?: Maybe<string>;
                                                    email?: Maybe<string>;
                                                    userType: UserType;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  };
                                              analytics?: Maybe<
                                                | {
                                                    __typename: 'MediaAnalytics';
                                                    belongsTo?: Maybe<
                                                      | {
                                                          __typename: 'MediaBelongsTo';
                                                          id: string;
                                                          name: string;
                                                          displayName: string;
                                                          objectType: string;
                                                        }
                                                      | {
                                                          __typename: 'GraphqlError';
                                                          type: ErrorType;
                                                          code: string;
                                                          helpLink?: Maybe<string>;
                                                          message: string;
                                                        }
                                                    >;
                                                  }
                                                | {
                                                    __typename: 'GraphqlError';
                                                    type: ErrorType;
                                                    code: string;
                                                    helpLink?: Maybe<string>;
                                                    message: string;
                                                  }
                                              >;
                                              context?: Maybe<
                                                | {
                                                    __typename: 'MediaContext';
                                                    isPin?: Maybe<boolean>;
                                                    isFavorite?: Maybe<boolean>;
                                                  }
                                                | { __typename: 'GraphqlError' }
                                              >;
                                            };
                                      }
                                    | { __typename: 'GraphqlError' }
                                  >;
                                }>
                              >
                            >;
                          }
                        | { __typename: 'GraphqlError' }
                      >;
                    }
                  | { __typename: 'GraphqlError' }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type GetUserEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: Maybe<GetUserEngagementRequest>;
}>;

export type GetUserEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getUserEngagement:
      | {
          __typename?: 'UserEngagementConnection';
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'UserEngagementEdge';
                node?: Maybe<
                  | {
                      __typename: 'UserEngagement';
                      id: string;
                      mspViewCount: number;
                      mediaViewCount: number;
                      mediaDownloadCount: number;
                      commentCount: number;
                      user?: Maybe<
                        | {
                            __typename: 'User';
                            firstName?: Maybe<string>;
                            lastName?: Maybe<string>;
                            email?: Maybe<string>;
                            userType: UserType;
                          }
                        | { __typename: 'GraphqlError' }
                      >;
                    }
                  | { __typename: 'GraphqlError' }
                >;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type GetMspAnalyticsMetricsQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsMetricsQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsMetrics:
      | {
          __typename: 'MspMetrics';
          mspTrack?: Maybe<{
            __typename?: 'MspTrack';
            mspTrackStatus: MspTrackStatus;
            mspSlip?: Maybe<number>;
            mspSlipDisplayTime?: Maybe<string>;
          }>;
          lastActivity?: Maybe<{
            __typename?: 'AnalyticsActivity';
            activityAt?: Maybe<number>;
            activityById?: Maybe<string>;
            message?: Maybe<string>;
            activityByUser?: Maybe<
              | {
                  __typename?: 'User';
                  firstName?: Maybe<string>;
                  lastName?: Maybe<string>;
                }
              | { __typename?: 'GraphqlError' }
            >;
          }>;
          lastExternalActivity?: Maybe<{
            __typename?: 'AnalyticsActivity';
            activityAt?: Maybe<number>;
            activityById?: Maybe<string>;
            activityByUser?: Maybe<
              | {
                  __typename?: 'User';
                  lastName?: Maybe<string>;
                  firstName?: Maybe<string>;
                }
              | { __typename?: 'GraphqlError' }
            >;
          }>;
          externalStakeholders?: Maybe<
            | {
                __typename?: 'MspAnalyticsStakeholders';
                stakeholders?: Maybe<
                  Array<
                    Maybe<{
                      __typename: 'AnalyticsUser';
                      id: string;
                      firstName?: Maybe<string>;
                      lastName?: Maybe<string>;
                      userType: UserType;
                      email?: Maybe<string>;
                    }>
                  >
                >;
                subscribers?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'AnalyticsUser';
                      id: string;
                      firstName?: Maybe<string>;
                      lastName?: Maybe<string>;
                      userType: UserType;
                      email?: Maybe<string>;
                    }>
                  >
                >;
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetActivityTimelineQueryVariables = Exact<{
  input: GetActivityTimelineRequestBody;
  pageConfiguration?: Maybe<PageConfiguration>;
}>;

export type GetActivityTimelineQuery = {
  __typename?: 'Query';
  activityTimelineQuery?: Maybe<{
    __typename?: 'ActivityTimelineQuery';
    getActivityTimeline:
      | {
          __typename?: 'ActivityTimelineConnection';
          pageInfo: {
            __typename: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            afterCursor?: Maybe<string>;
            beforeCursor?: Maybe<string>;
          };
          edges?: Maybe<
            Array<
              Maybe<{
                __typename?: 'ActivityTimelineEdge';
                node?: Maybe<
                  | {
                      __typename: 'ActivityTimeline';
                      message: string;
                      createdAt: number;
                      eventType: string;
                      authorizer: {
                        __typename?: 'ActivityTimelineAuthorizerDetails';
                        name?: Maybe<string>;
                        type?: Maybe<string>;
                      };
                      task?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      milestone?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      comment?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      media?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      role?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      stakeholder?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      methodology?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      opportunity?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      user?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      msp?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                      mspTemplate?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'ActivityTimelineResourceDetails';
                            name?: Maybe<string>;
                            type?: Maybe<string>;
                            updateFields?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'ActivityTimelineResourceUpdateFields';
                                  property?: Maybe<string>;
                                  value?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                    }
                  | { __typename: 'GraphqlError' }
                >;
              }>
            >
          >;
          activityTimelineHighlights?: Maybe<
            Array<{
              __typename?: 'ActivityTimelineHighlights';
              eventType: string;
              count: number;
            }>
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type GetMspAnalyticsTimelineQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsTimelineQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsTimeline:
      | {
          __typename: 'MspAnalyticsTimeline';
          mspId: string;
          lastUpdatedAt?: Maybe<number>;
          plannedCompletionDate?: Maybe<number>;
          timeline?: Maybe<
            Array<
              Maybe<{
                __typename?: 'MspAnalyticsTimelineData';
                timestamp: number;
                mspProgress?: Maybe<number>;
                plannedMspProgress?: Maybe<number>;
                timeParameterDisplayName: string;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  }>;
};

export type GetMspEngagementTimelineQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input: MspAnalyticsTimelineQueryOptions;
}>;

export type GetMspEngagementTimelineQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: Maybe<{
    __typename?: 'MspAnalyticsQuery';
    getMspEngagementTimeline:
      | {
          __typename?: 'MspEngagementTimeline';
          mspId: string;
          timeline?: Maybe<
            Array<
              Maybe<{
                __typename?: 'MspEngagementTimelineData';
                timestamp: number;
                engagementCount: number;
                buyerEngagementCount?: Maybe<number>;
                sellerEngagementCount?: Maybe<number>;
                userCount?: Maybe<number>;
                buyerUserIds?: Maybe<Array<string>>;
                sellerUserIds?: Maybe<Array<string>>;
                timeParameterDisplayName: string;
              }>
            >
          >;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: Maybe<string>;
          message: string;
        };
  }>;
};

export type CoreErrorFieldsFragmentFragment = {
  __typename?: 'GraphqlError';
  code: string;
  message: string;
  type: ErrorType;
  helpLink?: Maybe<string>;
};

export type MilestoneDetailsFragmentFragment = {
  __typename?: 'Milestone';
  id: string;
  name: string;
  createdAt: number;
  startDate: number;
  endDate: number;
  isInternal: boolean;
  milestoneDetails?: Maybe<
    | {
        __typename?: 'MilestoneDetails';
        goals?: Maybe<string>;
        successCriteria?: Maybe<string>;
        riskDependencies?: Maybe<string>;
      }
    | {
        __typename?: 'GraphqlError';
        code: string;
        message: string;
        type: ErrorType;
        helpLink?: Maybe<string>;
      }
  >;
  tasks:
    | {
        __typename?: 'TaskConnection';
        edges?: Maybe<
          Array<
            Maybe<{
              __typename?: 'TaskEdge';
              node?: Maybe<
                | {
                    __typename: 'Task';
                    id: string;
                    name: string;
                    dueDate: number;
                    status: TaskStatus;
                    milestoneId?: Maybe<string>;
                    isInternal: boolean;
                    analytics?: Maybe<
                      | {
                          __typename: 'TaskAnalytics';
                          commentCount?: Maybe<
                            | { __typename: 'IntObject'; value: number }
                            | { __typename: 'GraphqlError' }
                          >;
                        }
                      | {
                          __typename: 'GraphqlError';
                          code: string;
                          message: string;
                          type: ErrorType;
                          helpLink?: Maybe<string>;
                        }
                    >;
                    assignee?: Maybe<
                      | {
                          __typename?: 'User';
                          id?: Maybe<string>;
                          firstName?: Maybe<string>;
                          lastName?: Maybe<string>;
                          email?: Maybe<string>;
                          userType: UserType;
                        }
                      | { __typename?: 'Placeholder' }
                      | { __typename?: 'GraphqlError' }
                    >;
                  }
                | {
                    __typename: 'GraphqlError';
                    code: string;
                    message: string;
                    type: ErrorType;
                    helpLink?: Maybe<string>;
                  }
              >;
            }>
          >
        >;
      }
    | {
        __typename?: 'GraphqlError';
        code: string;
        message: string;
        type: ErrorType;
        helpLink?: Maybe<string>;
      };
};

export type TaskConnectionFragmentFragment = {
  __typename?: 'TaskConnection';
  edges?: Maybe<
    Array<
      Maybe<{
        __typename?: 'TaskEdge';
        node?: Maybe<
          | {
              __typename: 'Task';
              id: string;
              name: string;
              dueDate: number;
              status: TaskStatus;
              milestoneId?: Maybe<string>;
              isInternal: boolean;
              analytics?: Maybe<
                | {
                    __typename: 'TaskAnalytics';
                    commentCount?: Maybe<
                      | { __typename: 'IntObject'; value: number }
                      | { __typename: 'GraphqlError' }
                    >;
                  }
                | {
                    __typename: 'GraphqlError';
                    code: string;
                    message: string;
                    type: ErrorType;
                    helpLink?: Maybe<string>;
                  }
              >;
              assignee?: Maybe<
                | {
                    __typename?: 'User';
                    id?: Maybe<string>;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email?: Maybe<string>;
                    userType: UserType;
                  }
                | { __typename?: 'Placeholder' }
                | { __typename?: 'GraphqlError' }
              >;
            }
          | {
              __typename: 'GraphqlError';
              code: string;
              message: string;
              type: ErrorType;
              helpLink?: Maybe<string>;
            }
        >;
      }>
    >
  >;
};

export const CompanyFieldsFragmentDoc = gql`
  fragment CompanyFields on Company {
    id
    companyName
    displayName
    logo {
      ... on Logo {
        id
        fileName
        url
      }
    }
  }
`;
export const UserCompanyFieldsFragmentDoc = gql`
  fragment UserCompanyFields on Company {
    id
    companyName
  }
`;
export const CoreErrorFieldsFragmentDoc = gql`
  fragment CoreErrorFields on GraphqlError {
    __typename
    code
    message
    type
    helpLink
  }
`;
export const AssetsFragmentFragmentDoc = gql`
  fragment AssetsFragment on Assets {
    url
    fileAccessUrl
    fileDownloadUrl
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    __typename
  }
`;
export const ImageFragmentFragmentDoc = gql`
  fragment ImageFragment on Image {
    url
    fileAccessUrl
    fileDownloadUrl
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    __typename
  }
`;
export const AudioFragmentFragmentDoc = gql`
  fragment AudioFragment on Audio {
    url
    fileAccessUrl
    fileDownloadUrl
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    __typename
  }
`;
export const DocumentFragmentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    url
    fileAccessUrl
    fileDownloadUrl
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    __typename
  }
`;
export const VideoFragmentFragmentDoc = gql`
  fragment VideoFragment on Video {
    url
    fileAccessUrl
    fileDownloadUrl
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    __typename
  }
`;
export const LinkFragmentFragmentDoc = gql`
  fragment LinkFragment on Link {
    fileSize
    fileName
    source
    fileShareUrl
    fileAccessUrl
    fileDownloadUrl
    isInternal
    analytics {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaAnalytics {
        belongsTo {
          ... on GraphqlError {
            __typename
            type
            code
            helpLink
            message
          }
          ... on MediaBelongsTo {
            id
            name
            displayName
            objectType
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    context {
      ... on MediaContext {
        isPin
        isFavorite
        __typename
      }
      __typename
    }
    link
    name
    __typename
  }
`;
export const CoreErrorFieldsFragmentFragmentDoc = gql`
  fragment CoreErrorFieldsFragment on GraphqlError {
    code
    message
    type
    helpLink
  }
`;
export const TaskConnectionFragmentFragmentDoc = gql`
  fragment TaskConnectionFragment on TaskConnection {
    edges {
      node {
        __typename
        ...CoreErrorFieldsFragment
        ... on Task {
          id
          name
          dueDate
          status
          milestoneId
          isInternal
          analytics {
            __typename
            ...CoreErrorFieldsFragment
            ... on TaskAnalytics {
              commentCount {
                __typename
                ... on IntObject {
                  value
                }
              }
            }
          }
          assignee {
            ... on User {
              id
              firstName
              lastName
              email
              userType
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export const MilestoneDetailsFragmentFragmentDoc = gql`
  fragment MilestoneDetailsFragment on Milestone {
    id
    name
    createdAt
    startDate
    endDate
    isInternal
    milestoneDetails {
      ...CoreErrorFieldsFragment
      ... on MilestoneDetails {
        goals
        successCriteria
        riskDependencies
      }
    }
    tasks {
      ...CoreErrorFieldsFragment
      ...TaskConnectionFragment
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
  ${TaskConnectionFragmentFragmentDoc}
`;
export const GetCompanyInfoDocument = gql`
  query getCompanyInfo($companyName: String!) {
    companyQuery {
      getCompanyByName(companyName: $companyName) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on CompanyPayload {
          items {
            ... on Company {
              companyName
              displayName
              id
              logo {
                ... on Logo {
                  id
                  fileName
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInfoQuery({
 *   variables: {
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useGetCompanyInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyInfoQuery,
    GetCompanyInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(
    GetCompanyInfoDocument,
    options,
  );
}
export function useGetCompanyInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyInfoQuery,
    GetCompanyInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(
    GetCompanyInfoDocument,
    options,
  );
}
export type GetCompanyInfoQueryHookResult = ReturnType<
  typeof useGetCompanyInfoQuery
>;
export type GetCompanyInfoLazyQueryHookResult = ReturnType<
  typeof useGetCompanyInfoLazyQuery
>;
export type GetCompanyInfoQueryResult = Apollo.QueryResult<
  GetCompanyInfoQuery,
  GetCompanyInfoQueryVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization($orgId: ID!) {
    organizationQuery {
      getOrganization(orgId: $orgId) {
        __typename
        ... on GraphqlError {
          code
          message
          type
          helpLink
        }
        ... on OrganizationPayload {
          items {
            ... on GraphqlError {
              code
              message
              type
              helpLink
            }
            ... on Organization {
              __typename
              orgId
              orgType
              orgName
              state
              orgLogo {
                original
                favicon
              }
              configs {
                others
                salesforce {
                  isEnabled
                  qualifiedStageNames
                }
                branding {
                  header {
                    color
                    textColor
                  }
                }
                methodology {
                  isEnabled
                  type
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationQuery
>;
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetUserInfoDocument = gql`
  query getUserInfo {
    userQuery {
      me {
        ... on User {
          id
          primaryId
          firstName
          lastName
          title
          userType
          email
          company {
            ... on Company {
              id
              orgId
              companyName
              displayName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options,
  );
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options,
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoLazyQuery
>;
export type GetUserInfoQueryResult = Apollo.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
export const GetActiveMilestonesDocument = gql`
  query getActiveMilestones {
    milestoneQuery {
      activeMilestones {
        ... on MilestonePayload {
          items {
            ... on Milestone {
              id
              name
              startDate
              endDate
              analytics {
                ... on MilestoneAnalytics {
                  milestoneTrack {
                    ... on MilestoneTrack {
                      milestoneTrackStatus
                      dueTasksCount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetActiveMilestonesQuery__
 *
 * To run a query within a React component, call `useGetActiveMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveMilestonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveMilestonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveMilestonesQuery,
    GetActiveMilestonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveMilestonesQuery,
    GetActiveMilestonesQueryVariables
  >(GetActiveMilestonesDocument, options);
}
export function useGetActiveMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveMilestonesQuery,
    GetActiveMilestonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveMilestonesQuery,
    GetActiveMilestonesQueryVariables
  >(GetActiveMilestonesDocument, options);
}
export type GetActiveMilestonesQueryHookResult = ReturnType<
  typeof useGetActiveMilestonesQuery
>;
export type GetActiveMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetActiveMilestonesLazyQuery
>;
export type GetActiveMilestonesQueryResult = Apollo.QueryResult<
  GetActiveMilestonesQuery,
  GetActiveMilestonesQueryVariables
>;
export const GetMspForBuyerExperienceDocument = gql`
  query getMspForBuyerExperience($id: ID!, $options: GetMspAnalyticsOptions) {
    mspQuery {
      getMsp(id: $id) {
        __typename
        ...CoreErrorFields
        ... on MspPayload {
          items {
            ...CoreErrorFields
            ... on Msp {
              id
              isPublished
              name
              isReverseTimeLineEnabled
              timelineOrder
              createdBy {
                __typename
                ...CoreErrorFields
                ... on User {
                  id
                  primaryId
                  firstName
                  lastName
                  email
                }
              }
              buyerCompany {
                __typename
                ...CoreErrorFields
                ...CompanyFields
              }
              sellerCompany {
                __typename
                ...CoreErrorFields
                ...CompanyFields
              }
              closeDate
              timelines {
                ...CoreErrorFields
                ... on TimelinePayload {
                  items {
                    ...CoreErrorFields
                    ... on Timeline {
                      id
                      label
                      date
                    }
                  }
                }
              }
              keyDates {
                ...CoreErrorFields
                ... on MspKeyDates {
                  goLiveDate
                  contractSignatureDate
                  vendorChoiceDate
                }
              }
              userBridge {
                ...CoreErrorFields
                ... on MspUserBridge {
                  permissions {
                    __typename
                    ...CoreErrorFields
                    ... on MspPermissionPayload {
                      items
                    }
                  }
                }
              }
              subscribers {
                ...CoreErrorFields
                ... on SubscriberConnection {
                  edges {
                    node {
                      __typename
                      ...CoreErrorFields
                      ... on Subscriber {
                        user {
                          __typename
                          ...CoreErrorFields
                          ... on User {
                            id
                            primaryId
                            firstName
                            lastName
                            email
                            company {
                              __typename
                              ...CoreErrorFields
                              ...UserCompanyFields
                            }
                            userType
                          }
                        }
                        permission
                        status
                      }
                    }
                  }
                }
              }
              analytics(options: $options) {
                ...CoreErrorFields
                ... on MspAnalytics {
                  track {
                    __typename
                    ... on MspTrack {
                      mspTrackStatus
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
  ${CompanyFieldsFragmentDoc}
  ${UserCompanyFieldsFragmentDoc}
`;

/**
 * __useGetMspForBuyerExperienceQuery__
 *
 * To run a query within a React component, call `useGetMspForBuyerExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspForBuyerExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspForBuyerExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetMspForBuyerExperienceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspForBuyerExperienceQuery,
    GetMspForBuyerExperienceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspForBuyerExperienceQuery,
    GetMspForBuyerExperienceQueryVariables
  >(GetMspForBuyerExperienceDocument, options);
}
export function useGetMspForBuyerExperienceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspForBuyerExperienceQuery,
    GetMspForBuyerExperienceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspForBuyerExperienceQuery,
    GetMspForBuyerExperienceQueryVariables
  >(GetMspForBuyerExperienceDocument, options);
}
export type GetMspForBuyerExperienceQueryHookResult = ReturnType<
  typeof useGetMspForBuyerExperienceQuery
>;
export type GetMspForBuyerExperienceLazyQueryHookResult = ReturnType<
  typeof useGetMspForBuyerExperienceLazyQuery
>;
export type GetMspForBuyerExperienceQueryResult = Apollo.QueryResult<
  GetMspForBuyerExperienceQuery,
  GetMspForBuyerExperienceQueryVariables
>;
export const GetAllTasksDocument = gql`
  query getAllTasks($operations: Operations) {
    taskQuery {
      allTasksWithFilters(operations: $operations) {
        __typename
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on TaskConnection {
          edges {
            node {
              __typename
              ... on GraphqlError {
                __typename
                type
                code
                helpLink
                message
              }
              ... on Task {
                id
                name
                dueDate
                status
                isInternal
                description
                assigneeType
                assignee {
                  __typename
                  ... on GraphqlError {
                    __typename
                    type
                    code
                    helpLink
                    message
                  }
                  ... on User {
                    id
                    firstName
                    lastName
                    email
                    userType
                  }
                  ... on Placeholder {
                    placeholderId: id
                    name
                    companyType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllTasksQuery__
 *
 * To run a query within a React component, call `useGetAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTasksQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *   },
 * });
 */
export function useGetAllTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTasksQuery,
    GetAllTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(
    GetAllTasksDocument,
    options,
  );
}
export function useGetAllTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTasksQuery,
    GetAllTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(
    GetAllTasksDocument,
    options,
  );
}
export type GetAllTasksQueryHookResult = ReturnType<typeof useGetAllTasksQuery>;
export type GetAllTasksLazyQueryHookResult = ReturnType<
  typeof useGetAllTasksLazyQuery
>;
export type GetAllTasksQueryResult = Apollo.QueryResult<
  GetAllTasksQuery,
  GetAllTasksQueryVariables
>;
export const AddAssigneeDocument = gql`
  mutation addAssignee($userIdMap: UserIdMapInput!, $taskIds: [ID!]!) {
    taskMutation {
      addAssignee(userIdMap: $userIdMap, taskIds: $taskIds) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type AddAssigneeMutationFn = Apollo.MutationFunction<
  AddAssigneeMutation,
  AddAssigneeMutationVariables
>;

/**
 * __useAddAssigneeMutation__
 *
 * To run a mutation, you first call `useAddAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssigneeMutation, { data, loading, error }] = useAddAssigneeMutation({
 *   variables: {
 *      userIdMap: // value for 'userIdMap'
 *      taskIds: // value for 'taskIds'
 *   },
 * });
 */
export function useAddAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAssigneeMutation,
    AddAssigneeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssigneeMutation, AddAssigneeMutationVariables>(
    AddAssigneeDocument,
    options,
  );
}
export type AddAssigneeMutationHookResult = ReturnType<
  typeof useAddAssigneeMutation
>;
export type AddAssigneeMutationResult =
  Apollo.MutationResult<AddAssigneeMutation>;
export type AddAssigneeMutationOptions = Apollo.BaseMutationOptions<
  AddAssigneeMutation,
  AddAssigneeMutationVariables
>;
export const CreateNewUserDocument = gql`
  mutation createNewUser($input: CreateUserInput!) {
    userMutation {
      createUser(input: $input) {
        __typename
        ...CoreErrorFields
        ... on UserPayload {
          items {
            __typename
            ...CoreErrorFields
            ... on User {
              id
              firstName
              lastName
              email
              userType
              primaryId
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type CreateNewUserMutationFn = Apollo.MutationFunction<
  CreateNewUserMutation,
  CreateNewUserMutationVariables
>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
  >(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<
  typeof useCreateNewUserMutation
>;
export type CreateNewUserMutationResult =
  Apollo.MutationResult<CreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<
  CreateNewUserMutation,
  CreateNewUserMutationVariables
>;
export const GetPlaceholderListDocument = gql`
  query getPlaceholderList($operations: Operations) {
    memberQuery {
      listPlaceholders(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on PlaceholderConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Placeholder {
                id
                name
                description
                companyType
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetPlaceholderListQuery__
 *
 * To run a query within a React component, call `useGetPlaceholderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceholderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceholderListQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *   },
 * });
 */
export function useGetPlaceholderListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlaceholderListQuery,
    GetPlaceholderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlaceholderListQuery,
    GetPlaceholderListQueryVariables
  >(GetPlaceholderListDocument, options);
}
export function useGetPlaceholderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaceholderListQuery,
    GetPlaceholderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlaceholderListQuery,
    GetPlaceholderListQueryVariables
  >(GetPlaceholderListDocument, options);
}
export type GetPlaceholderListQueryHookResult = ReturnType<
  typeof useGetPlaceholderListQuery
>;
export type GetPlaceholderListLazyQueryHookResult = ReturnType<
  typeof useGetPlaceholderListLazyQuery
>;
export type GetPlaceholderListQueryResult = Apollo.QueryResult<
  GetPlaceholderListQuery,
  GetPlaceholderListQueryVariables
>;
export const RemoveAssigneeDocument = gql`
  mutation removeAssignee($assigneeId: ID!, $taskIds: [ID!]!) {
    taskMutation {
      removeAssignee(assigneeId: $assigneeId, taskIds: $taskIds) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type RemoveAssigneeMutationFn = Apollo.MutationFunction<
  RemoveAssigneeMutation,
  RemoveAssigneeMutationVariables
>;

/**
 * __useRemoveAssigneeMutation__
 *
 * To run a mutation, you first call `useRemoveAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssigneeMutation, { data, loading, error }] = useRemoveAssigneeMutation({
 *   variables: {
 *      assigneeId: // value for 'assigneeId'
 *      taskIds: // value for 'taskIds'
 *   },
 * });
 */
export function useRemoveAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssigneeMutation,
    RemoveAssigneeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAssigneeMutation,
    RemoveAssigneeMutationVariables
  >(RemoveAssigneeDocument, options);
}
export type RemoveAssigneeMutationHookResult = ReturnType<
  typeof useRemoveAssigneeMutation
>;
export type RemoveAssigneeMutationResult =
  Apollo.MutationResult<RemoveAssigneeMutation>;
export type RemoveAssigneeMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssigneeMutation,
  RemoveAssigneeMutationVariables
>;
export const SearchUserAssigneeDocument = gql`
  query searchUserAssignee($operations: Operations) {
    mspQuery {
      searchUsersInMsp(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on UserConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on User {
                firstName
                lastName
                userType
                email
                id
                primaryId
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchUserAssigneeQuery__
 *
 * To run a query within a React component, call `useSearchUserAssigneeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserAssigneeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserAssigneeQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *   },
 * });
 */
export function useSearchUserAssigneeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchUserAssigneeQuery,
    SearchUserAssigneeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchUserAssigneeQuery,
    SearchUserAssigneeQueryVariables
  >(SearchUserAssigneeDocument, options);
}
export function useSearchUserAssigneeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserAssigneeQuery,
    SearchUserAssigneeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchUserAssigneeQuery,
    SearchUserAssigneeQueryVariables
  >(SearchUserAssigneeDocument, options);
}
export type SearchUserAssigneeQueryHookResult = ReturnType<
  typeof useSearchUserAssigneeQuery
>;
export type SearchUserAssigneeLazyQueryHookResult = ReturnType<
  typeof useSearchUserAssigneeLazyQuery
>;
export type SearchUserAssigneeQueryResult = Apollo.QueryResult<
  SearchUserAssigneeQuery,
  SearchUserAssigneeQueryVariables
>;
export const UpdateTaskDueDateDocument = gql`
  mutation updateTaskDueDate($id: ID!, $input: UpdateTaskInput!) {
    taskMutation {
      updateTask(id: $id, input: $input) {
        __typename
        ...CoreErrorFields
        ... on TaskPayload {
          items {
            ...CoreErrorFields
            ... on Task {
              id
              name
              dueDate
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type UpdateTaskDueDateMutationFn = Apollo.MutationFunction<
  UpdateTaskDueDateMutation,
  UpdateTaskDueDateMutationVariables
>;

/**
 * __useUpdateTaskDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskDueDateMutation, { data, loading, error }] = useUpdateTaskDueDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskDueDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskDueDateMutation,
    UpdateTaskDueDateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskDueDateMutation,
    UpdateTaskDueDateMutationVariables
  >(UpdateTaskDueDateDocument, options);
}
export type UpdateTaskDueDateMutationHookResult = ReturnType<
  typeof useUpdateTaskDueDateMutation
>;
export type UpdateTaskDueDateMutationResult =
  Apollo.MutationResult<UpdateTaskDueDateMutation>;
export type UpdateTaskDueDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskDueDateMutation,
  UpdateTaskDueDateMutationVariables
>;
export const UpdateTaskNameDocument = gql`
  mutation UpdateTaskName($id: ID!, $input: UpdateTaskInput!) {
    taskMutation {
      updateTask(id: $id, input: $input) {
        __typename
        ...CoreErrorFields
        ... on TaskPayload {
          items {
            ...CoreErrorFields
            ... on Task {
              id
              name
              description
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type UpdateTaskNameMutationFn = Apollo.MutationFunction<
  UpdateTaskNameMutation,
  UpdateTaskNameMutationVariables
>;

/**
 * __useUpdateTaskNameMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNameMutation, { data, loading, error }] = useUpdateTaskNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskNameMutation,
    UpdateTaskNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskNameMutation,
    UpdateTaskNameMutationVariables
  >(UpdateTaskNameDocument, options);
}
export type UpdateTaskNameMutationHookResult = ReturnType<
  typeof useUpdateTaskNameMutation
>;
export type UpdateTaskNameMutationResult =
  Apollo.MutationResult<UpdateTaskNameMutation>;
export type UpdateTaskNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskNameMutation,
  UpdateTaskNameMutationVariables
>;
export const UpdateTaskStatusDocument = gql`
  mutation UpdateTaskStatus($input: ChangeTaskStatusInput!) {
    taskMutation {
      changeTaskStatus(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
  >(UpdateTaskStatusDocument, options);
}
export type UpdateTaskStatusMutationHookResult = ReturnType<
  typeof useUpdateTaskStatusMutation
>;
export type UpdateTaskStatusMutationResult =
  Apollo.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;
export const GetPrimaryStakeholderListDocument = gql`
  query getPrimaryStakeholderList {
    memberQuery {
      getPrimaryStakeholders {
        ... on PrimaryStakeholder {
          buyerStakeholders {
            ... on Stakeholder {
              id
              primaryId
              lastName
              firstName
              title
              company {
                ... on Company {
                  logo {
                    ... on Logo {
                      id
                      fileName
                      url
                    }
                  }
                }
              }
            }
          }
          sellerStakeholders {
            ... on Stakeholder {
              id
              primaryId
              lastName
              firstName
              email
              title
              company {
                ... on Company {
                  logo {
                    ... on Logo {
                      id
                      fileName
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPrimaryStakeholderListQuery__
 *
 * To run a query within a React component, call `useGetPrimaryStakeholderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryStakeholderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryStakeholderListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrimaryStakeholderListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPrimaryStakeholderListQuery,
    GetPrimaryStakeholderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPrimaryStakeholderListQuery,
    GetPrimaryStakeholderListQueryVariables
  >(GetPrimaryStakeholderListDocument, options);
}
export function useGetPrimaryStakeholderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrimaryStakeholderListQuery,
    GetPrimaryStakeholderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrimaryStakeholderListQuery,
    GetPrimaryStakeholderListQueryVariables
  >(GetPrimaryStakeholderListDocument, options);
}
export type GetPrimaryStakeholderListQueryHookResult = ReturnType<
  typeof useGetPrimaryStakeholderListQuery
>;
export type GetPrimaryStakeholderListLazyQueryHookResult = ReturnType<
  typeof useGetPrimaryStakeholderListLazyQuery
>;
export type GetPrimaryStakeholderListQueryResult = Apollo.QueryResult<
  GetPrimaryStakeholderListQuery,
  GetPrimaryStakeholderListQueryVariables
>;
export const AddCommentDocument = gql`
  mutation AddComment($input: AddCommentInput!) {
    annotationMutation {
      addComment(input: $input) {
        __typename
        ... on CommentPayload {
          items {
            ... on Comment {
              id
              messageData {
                message
                isEdited
                editedAt
              }
              createdBy {
                ...CoreErrorFields
                ... on User {
                  firstName
                  lastName
                  id
                  email
                  userType
                }
              }
              media {
                ... on MediaConnectionResponse {
                  ...CoreErrorFields
                  ... on MediaConnection {
                    edges {
                      node {
                        ...CoreErrorFields
                        ... on MediaResponse {
                          ...CoreErrorFields
                          ... on MediaPayloadItem {
                            item {
                              id
                              createdBy {
                                ... on User {
                                  id
                                  firstName
                                  lastName
                                }
                              }
                              fileName
                              url
                              analytics {
                                ... on MediaAnalyticsResponse {
                                  ...CoreErrorFields
                                  ... on MediaAnalytics {
                                    belongsTo {
                                      ... on BelongsToResponse {
                                        ...CoreErrorFields
                                        ... on MediaBelongsTo {
                                          id
                                          name
                                          displayName
                                          objectType
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type AddCommentMutationFn = Apollo.MutationFunction<
  AddCommentMutation,
  AddCommentMutationVariables
>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCommentMutation,
    AddCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(
    AddCommentDocument,
    options,
  );
}
export type AddCommentMutationHookResult = ReturnType<
  typeof useAddCommentMutation
>;
export type AddCommentMutationResult =
  Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCommentMutation,
  AddCommentMutationVariables
>;
export const GetNotesWithCommentsDocument = gql`
  query GetNotesWithComments($taskId: ID!) {
    taskQuery {
      getTaskNotes(taskId: $taskId) {
        __typename
        ...CoreErrorFields
        ... on Notes {
          id
          comments {
            ...CoreErrorFields
            ... on CommentConnection {
              edges {
                node {
                  __typename
                  ...CoreErrorFields
                  ... on Comment {
                    id
                    createdAt
                    messageData {
                      message
                      isEdited
                      editedAt
                    }
                    createdBy {
                      ...CoreErrorFields
                      ... on User {
                        firstName
                        lastName
                        id
                        userType
                        email
                      }
                    }
                    media {
                      ...CoreErrorFields
                      ... on MediaConnection {
                        edges {
                          node {
                            ... on GraphqlError {
                              code
                              message
                            }
                            ... on MediaResponse {
                              ...CoreErrorFields
                              ... on MediaPayloadItem {
                                item {
                                  id
                                  fileName
                                  url
                                  createdBy {
                                    ...CoreErrorFields
                                    ... on User {
                                      id
                                    }
                                  }
                                  analytics {
                                    ...CoreErrorFields
                                    ... on MediaAnalytics {
                                      belongsTo {
                                        ...CoreErrorFields
                                        ... on MediaBelongsTo {
                                          id
                                          name
                                          displayName
                                          objectType
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetNotesWithCommentsQuery__
 *
 * To run a query within a React component, call `useGetNotesWithCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesWithCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesWithCommentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetNotesWithCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotesWithCommentsQuery,
    GetNotesWithCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotesWithCommentsQuery,
    GetNotesWithCommentsQueryVariables
  >(GetNotesWithCommentsDocument, options);
}
export function useGetNotesWithCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotesWithCommentsQuery,
    GetNotesWithCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotesWithCommentsQuery,
    GetNotesWithCommentsQueryVariables
  >(GetNotesWithCommentsDocument, options);
}
export type GetNotesWithCommentsQueryHookResult = ReturnType<
  typeof useGetNotesWithCommentsQuery
>;
export type GetNotesWithCommentsLazyQueryHookResult = ReturnType<
  typeof useGetNotesWithCommentsLazyQuery
>;
export type GetNotesWithCommentsQueryResult = Apollo.QueryResult<
  GetNotesWithCommentsQuery,
  GetNotesWithCommentsQueryVariables
>;
export const CreateMspFromTemplateDocument = gql`
  mutation CreateMspFromTemplate(
    $input: CreateMspTemplateInput!
    $templateId: ID!
  ) {
    templateMutation {
      createMspFromTemplate(templateId: $templateId, input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on MspPayload {
          items {
            __typename
            ...CoreErrorFieldsFragment
            ... on Msp {
              id
              name
              description
              isPublished
              closeDate
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type CreateMspFromTemplateMutationFn = Apollo.MutationFunction<
  CreateMspFromTemplateMutation,
  CreateMspFromTemplateMutationVariables
>;

/**
 * __useCreateMspFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMspFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMspFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMspFromTemplateMutation, { data, loading, error }] = useCreateMspFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCreateMspFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMspFromTemplateMutation,
    CreateMspFromTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMspFromTemplateMutation,
    CreateMspFromTemplateMutationVariables
  >(CreateMspFromTemplateDocument, options);
}
export type CreateMspFromTemplateMutationHookResult = ReturnType<
  typeof useCreateMspFromTemplateMutation
>;
export type CreateMspFromTemplateMutationResult =
  Apollo.MutationResult<CreateMspFromTemplateMutation>;
export type CreateMspFromTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMspFromTemplateMutation,
  CreateMspFromTemplateMutationVariables
>;
export const CreateMspDocument = gql`
  mutation CreateMsp($input: CreateMspInput!) {
    mspMutation {
      createMsp(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on MspPayload {
          items {
            __typename
            ...CoreErrorFieldsFragment
            ... on Msp {
              id
              name
              description
              isPublished
              closeDate
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type CreateMspMutationFn = Apollo.MutationFunction<
  CreateMspMutation,
  CreateMspMutationVariables
>;

/**
 * __useCreateMspMutation__
 *
 * To run a mutation, you first call `useCreateMspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMspMutation, { data, loading, error }] = useCreateMspMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMspMutation,
    CreateMspMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMspMutation, CreateMspMutationVariables>(
    CreateMspDocument,
    options,
  );
}
export type CreateMspMutationHookResult = ReturnType<
  typeof useCreateMspMutation
>;
export type CreateMspMutationResult = Apollo.MutationResult<CreateMspMutation>;
export type CreateMspMutationOptions = Apollo.BaseMutationOptions<
  CreateMspMutation,
  CreateMspMutationVariables
>;
export const GetMspAnalyticsHighlightsDocument = gql`
  query getMspAnalyticsHighlights($mspId: ID!) {
    mspAnalyticsQuery {
      getMspAnalyticsHighlights(mspId: $mspId) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MspAnalyticsHighlights {
          milestones {
            ... on MilestoneHighlights {
              activeMilestones {
                ... on AnalyticsMilestone {
                  milestone {
                    ... on BaseMilestone {
                      name
                      startDate
                      endDate
                    }
                  }
                  progress {
                    ... on AnalyticsMilestoneProgress {
                      tasksCount
                      tasksCompleted
                      tasksOverdue
                    }
                  }
                }
              }
            }
          }
          tasks {
            ... on TaskHighlights {
              upcomingTasks {
                ... on AnalyticsTask {
                  task {
                    id
                    name
                    status
                    dueDate
                  }
                  assignee {
                    ... on AnalyticsUser {
                      firstName
                      lastName
                      userType
                      email
                    }
                    ... on Placeholder {
                      name
                    }
                  }
                }
              }
            }
          }
          insights
        }
      }
    }
  }
`;

/**
 * __useGetMspAnalyticsHighlightsQuery__
 *
 * To run a query within a React component, call `useGetMspAnalyticsHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspAnalyticsHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspAnalyticsHighlightsQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMspAnalyticsHighlightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspAnalyticsHighlightsQuery,
    GetMspAnalyticsHighlightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspAnalyticsHighlightsQuery,
    GetMspAnalyticsHighlightsQueryVariables
  >(GetMspAnalyticsHighlightsDocument, options);
}
export function useGetMspAnalyticsHighlightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspAnalyticsHighlightsQuery,
    GetMspAnalyticsHighlightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspAnalyticsHighlightsQuery,
    GetMspAnalyticsHighlightsQueryVariables
  >(GetMspAnalyticsHighlightsDocument, options);
}
export type GetMspAnalyticsHighlightsQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsHighlightsQuery
>;
export type GetMspAnalyticsHighlightsLazyQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsHighlightsLazyQuery
>;
export type GetMspAnalyticsHighlightsQueryResult = Apollo.QueryResult<
  GetMspAnalyticsHighlightsQuery,
  GetMspAnalyticsHighlightsQueryVariables
>;
export const ToggleMspsArchivalStatusDocument = gql`
  mutation toggleMspsArchivalStatus($input: ArchiveMspInput!) {
    mspMutation {
      toggleMspsArchivalStatus(input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on ToggleMspArchivePayload {
          items {
            id
            mspStage
          }
        }
      }
    }
  }
`;
export type ToggleMspsArchivalStatusMutationFn = Apollo.MutationFunction<
  ToggleMspsArchivalStatusMutation,
  ToggleMspsArchivalStatusMutationVariables
>;

/**
 * __useToggleMspsArchivalStatusMutation__
 *
 * To run a mutation, you first call `useToggleMspsArchivalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMspsArchivalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMspsArchivalStatusMutation, { data, loading, error }] = useToggleMspsArchivalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleMspsArchivalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMspsArchivalStatusMutation,
    ToggleMspsArchivalStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleMspsArchivalStatusMutation,
    ToggleMspsArchivalStatusMutationVariables
  >(ToggleMspsArchivalStatusDocument, options);
}
export type ToggleMspsArchivalStatusMutationHookResult = ReturnType<
  typeof useToggleMspsArchivalStatusMutation
>;
export type ToggleMspsArchivalStatusMutationResult =
  Apollo.MutationResult<ToggleMspsArchivalStatusMutation>;
export type ToggleMspsArchivalStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleMspsArchivalStatusMutation,
    ToggleMspsArchivalStatusMutationVariables
  >;
export const GetMspArchiveReasonListDocument = gql`
  query GetMspArchiveReasonList {
    mspQuery {
      __typename
      listMspArchivalReasons {
        ... on GraphqlError {
          code
          type
          message
        }
        ... on FilterItemsPayloadResponse {
          ... on GraphqlError {
            code
            type
            message
          }
          ... on FilterItemsPayload {
            items {
              ... on FilterItems {
                key
                displayName
                isVisible
                filterType
                values
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspArchiveReasonListQuery__
 *
 * To run a query within a React component, call `useGetMspArchiveReasonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspArchiveReasonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspArchiveReasonListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMspArchiveReasonListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMspArchiveReasonListQuery,
    GetMspArchiveReasonListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspArchiveReasonListQuery,
    GetMspArchiveReasonListQueryVariables
  >(GetMspArchiveReasonListDocument, options);
}
export function useGetMspArchiveReasonListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspArchiveReasonListQuery,
    GetMspArchiveReasonListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspArchiveReasonListQuery,
    GetMspArchiveReasonListQueryVariables
  >(GetMspArchiveReasonListDocument, options);
}
export type GetMspArchiveReasonListQueryHookResult = ReturnType<
  typeof useGetMspArchiveReasonListQuery
>;
export type GetMspArchiveReasonListLazyQueryHookResult = ReturnType<
  typeof useGetMspArchiveReasonListLazyQuery
>;
export type GetMspArchiveReasonListQueryResult = Apollo.QueryResult<
  GetMspArchiveReasonListQuery,
  GetMspArchiveReasonListQueryVariables
>;
export const ListAccountPlansDocument = gql`
  query listAccountPlans($input: ListAccountPlanInput) {
    accountPlanQuery {
      listAccountPlans(input: $input) {
        ...CoreErrorFields
        ... on AccountPlanConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on AccountPlan {
                id
                createdAt
                buyerCompany {
                  ... on Company {
                    displayName
                    companyName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useListAccountPlansQuery__
 *
 * To run a query within a React component, call `useListAccountPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAccountPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAccountPlansQuery,
    ListAccountPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAccountPlansQuery, ListAccountPlansQueryVariables>(
    ListAccountPlansDocument,
    options,
  );
}
export function useListAccountPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAccountPlansQuery,
    ListAccountPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAccountPlansQuery,
    ListAccountPlansQueryVariables
  >(ListAccountPlansDocument, options);
}
export type ListAccountPlansQueryHookResult = ReturnType<
  typeof useListAccountPlansQuery
>;
export type ListAccountPlansLazyQueryHookResult = ReturnType<
  typeof useListAccountPlansLazyQuery
>;
export type ListAccountPlansQueryResult = Apollo.QueryResult<
  ListAccountPlansQuery,
  ListAccountPlansQueryVariables
>;
export const SearchAccountPlanDocument = gql`
  query searchAccountPlan($input: SearchAccountPlanInput) {
    accountPlanQuery {
      searchAccountPlan(input: $input) {
        ...CoreErrorFields
        ... on AccountPlanConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on AccountPlan {
                id
                createdAt
                buyerCompany {
                  ... on Company {
                    displayName
                    companyName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchAccountPlanQuery__
 *
 * To run a query within a React component, call `useSearchAccountPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAccountPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAccountPlanQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAccountPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchAccountPlanQuery,
    SearchAccountPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAccountPlanQuery,
    SearchAccountPlanQueryVariables
  >(SearchAccountPlanDocument, options);
}
export function useSearchAccountPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAccountPlanQuery,
    SearchAccountPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAccountPlanQuery,
    SearchAccountPlanQueryVariables
  >(SearchAccountPlanDocument, options);
}
export type SearchAccountPlanQueryHookResult = ReturnType<
  typeof useSearchAccountPlanQuery
>;
export type SearchAccountPlanLazyQueryHookResult = ReturnType<
  typeof useSearchAccountPlanLazyQuery
>;
export type SearchAccountPlanQueryResult = Apollo.QueryResult<
  SearchAccountPlanQuery,
  SearchAccountPlanQueryVariables
>;
export const SearchCompaniesDocument = gql`
  query searchCompanies($operations: Operations!) {
    companyQuery {
      searchCompany(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on GraphqlError {
          code
          type
          message
        }
        ... on CompanyConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on Company {
                id
                orgId
                extId
                displayName
                companyName
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *   },
 * });
 */
export function useSearchCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCompaniesQuery,
    SearchCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompaniesQuery, SearchCompaniesQueryVariables>(
    SearchCompaniesDocument,
    options,
  );
}
export function useSearchCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCompaniesQuery,
    SearchCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchCompaniesQuery,
    SearchCompaniesQueryVariables
  >(SearchCompaniesDocument, options);
}
export type SearchCompaniesQueryHookResult = ReturnType<
  typeof useSearchCompaniesQuery
>;
export type SearchCompaniesLazyQueryHookResult = ReturnType<
  typeof useSearchCompaniesLazyQuery
>;
export type SearchCompaniesQueryResult = Apollo.QueryResult<
  SearchCompaniesQuery,
  SearchCompaniesQueryVariables
>;
export const GetDashboardMspListDocument = gql`
  query GetDashboardMspList(
    $mspOperations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    alignxQuery {
      dashboard {
        __typename
        ...CoreErrorFields
        ... on Alignx {
          msps(
            operations: $mspOperations
            pageConfiguration: $pageConfiguration
          ) {
            ...CoreErrorFields
            ... on MspConnection {
              pageInfo {
                hasNextPage
                hasPreviousPage
                afterCursor
                beforeCursor
              }
              edges {
                node {
                  ...CoreErrorFields
                  ... on Msp {
                    id
                    name
                    opportunity {
                      ... on Opportunity {
                        id
                        name
                        stageName
                        closeDate
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetDashboardMspListQuery__
 *
 * To run a query within a React component, call `useGetDashboardMspListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardMspListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardMspListQuery({
 *   variables: {
 *      mspOperations: // value for 'mspOperations'
 *      pageConfiguration: // value for 'pageConfiguration'
 *   },
 * });
 */
export function useGetDashboardMspListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardMspListQuery,
    GetDashboardMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardMspListQuery,
    GetDashboardMspListQueryVariables
  >(GetDashboardMspListDocument, options);
}
export function useGetDashboardMspListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardMspListQuery,
    GetDashboardMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardMspListQuery,
    GetDashboardMspListQueryVariables
  >(GetDashboardMspListDocument, options);
}
export type GetDashboardMspListQueryHookResult = ReturnType<
  typeof useGetDashboardMspListQuery
>;
export type GetDashboardMspListLazyQueryHookResult = ReturnType<
  typeof useGetDashboardMspListLazyQuery
>;
export type GetDashboardMspListQueryResult = Apollo.QueryResult<
  GetDashboardMspListQuery,
  GetDashboardMspListQueryVariables
>;
export const GetOpportunityFiltersDocument = gql`
  query getOpportunityFilters {
    alignxQuery {
      dashboard {
        ...CoreErrorFields
        ... on Alignx {
          dashboardFiltersV1 {
            __typename
            ... on FilterItemsPayload {
              items {
                key
                displayName
                values
                isVisible
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetOpportunityFiltersQuery__
 *
 * To run a query within a React component, call `useGetOpportunityFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpportunityFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOpportunityFiltersQuery,
    GetOpportunityFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpportunityFiltersQuery,
    GetOpportunityFiltersQueryVariables
  >(GetOpportunityFiltersDocument, options);
}
export function useGetOpportunityFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpportunityFiltersQuery,
    GetOpportunityFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpportunityFiltersQuery,
    GetOpportunityFiltersQueryVariables
  >(GetOpportunityFiltersDocument, options);
}
export type GetOpportunityFiltersQueryHookResult = ReturnType<
  typeof useGetOpportunityFiltersQuery
>;
export type GetOpportunityFiltersLazyQueryHookResult = ReturnType<
  typeof useGetOpportunityFiltersLazyQuery
>;
export type GetOpportunityFiltersQueryResult = Apollo.QueryResult<
  GetOpportunityFiltersQuery,
  GetOpportunityFiltersQueryVariables
>;
export const GetOpportunityListDocument = gql`
  query GetOpportunityList(
    $operations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    opportunityQuery {
      listOpportunity(
        operations: $operations
        pageConfiguration: $pageConfiguration
      ) {
        ...CoreErrorFields
        ... on OpportunityConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
          }
          totalCount
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Opportunity {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetOpportunityListQuery__
 *
 * To run a query within a React component, call `useGetOpportunityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityListQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *      pageConfiguration: // value for 'pageConfiguration'
 *   },
 * });
 */
export function useGetOpportunityListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOpportunityListQuery,
    GetOpportunityListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpportunityListQuery,
    GetOpportunityListQueryVariables
  >(GetOpportunityListDocument, options);
}
export function useGetOpportunityListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpportunityListQuery,
    GetOpportunityListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpportunityListQuery,
    GetOpportunityListQueryVariables
  >(GetOpportunityListDocument, options);
}
export type GetOpportunityListQueryHookResult = ReturnType<
  typeof useGetOpportunityListQuery
>;
export type GetOpportunityListLazyQueryHookResult = ReturnType<
  typeof useGetOpportunityListLazyQuery
>;
export type GetOpportunityListQueryResult = Apollo.QueryResult<
  GetOpportunityListQuery,
  GetOpportunityListQueryVariables
>;
export const GetOwnerAndCoOwnerOpportunitiesDocument = gql`
  query GetOwnerAndCoOwnerOpportunities(
    $input: getOpportunityForOwnerAndCoOwnerInput!
  ) {
    opportunityQuery {
      getOpportunityForOwnerAndCoOwner(input: $input) {
        __typename
        ...CoreErrorFields
        ... on OpportunityConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Opportunity {
                id
                accountId
                name
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetOwnerAndCoOwnerOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetOwnerAndCoOwnerOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerAndCoOwnerOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerAndCoOwnerOpportunitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOwnerAndCoOwnerOpportunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnerAndCoOwnerOpportunitiesQuery,
    GetOwnerAndCoOwnerOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOwnerAndCoOwnerOpportunitiesQuery,
    GetOwnerAndCoOwnerOpportunitiesQueryVariables
  >(GetOwnerAndCoOwnerOpportunitiesDocument, options);
}
export function useGetOwnerAndCoOwnerOpportunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerAndCoOwnerOpportunitiesQuery,
    GetOwnerAndCoOwnerOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOwnerAndCoOwnerOpportunitiesQuery,
    GetOwnerAndCoOwnerOpportunitiesQueryVariables
  >(GetOwnerAndCoOwnerOpportunitiesDocument, options);
}
export type GetOwnerAndCoOwnerOpportunitiesQueryHookResult = ReturnType<
  typeof useGetOwnerAndCoOwnerOpportunitiesQuery
>;
export type GetOwnerAndCoOwnerOpportunitiesLazyQueryHookResult = ReturnType<
  typeof useGetOwnerAndCoOwnerOpportunitiesLazyQuery
>;
export type GetOwnerAndCoOwnerOpportunitiesQueryResult = Apollo.QueryResult<
  GetOwnerAndCoOwnerOpportunitiesQuery,
  GetOwnerAndCoOwnerOpportunitiesQueryVariables
>;
export const GetPaginatedMspListDocument = gql`
  query GetPaginatedMspList(
    $mspOperations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    alignxQuery {
      dashboard {
        __typename
        ...CoreErrorFields
        ... on Alignx {
          msps(
            operations: $mspOperations
            pageConfiguration: $pageConfiguration
          ) {
            ...CoreErrorFields
            ... on MspConnection {
              pageInfo {
                hasNextPage
                hasPreviousPage
                afterCursor
                beforeCursor
              }
              edges {
                node {
                  ...CoreErrorFields
                  ... on Msp {
                    id
                    name
                    opportunity {
                      ... on Opportunity {
                        id
                        name
                        stageName
                        closeDate
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetPaginatedMspListQuery__
 *
 * To run a query within a React component, call `useGetPaginatedMspListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedMspListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedMspListQuery({
 *   variables: {
 *      mspOperations: // value for 'mspOperations'
 *      pageConfiguration: // value for 'pageConfiguration'
 *   },
 * });
 */
export function useGetPaginatedMspListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaginatedMspListQuery,
    GetPaginatedMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaginatedMspListQuery,
    GetPaginatedMspListQueryVariables
  >(GetPaginatedMspListDocument, options);
}
export function useGetPaginatedMspListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedMspListQuery,
    GetPaginatedMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaginatedMspListQuery,
    GetPaginatedMspListQueryVariables
  >(GetPaginatedMspListDocument, options);
}
export type GetPaginatedMspListQueryHookResult = ReturnType<
  typeof useGetPaginatedMspListQuery
>;
export type GetPaginatedMspListLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedMspListLazyQuery
>;
export type GetPaginatedMspListQueryResult = Apollo.QueryResult<
  GetPaginatedMspListQuery,
  GetPaginatedMspListQueryVariables
>;
export const CreateAccountPlanDocument = gql`
  mutation createAccountPlan($input: CreateAccountPlanInput!) {
    accountPlanMutation {
      createAccountPlan(input: $input) {
        ...CoreErrorFields
        ... on AccountPlanPayload {
          items {
            ... on AccountPlan {
              id
              extCompanyId
              companyId
              orgId
              createdAt
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type CreateAccountPlanMutationFn = Apollo.MutationFunction<
  CreateAccountPlanMutation,
  CreateAccountPlanMutationVariables
>;

/**
 * __useCreateAccountPlanMutation__
 *
 * To run a mutation, you first call `useCreateAccountPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountPlanMutation, { data, loading, error }] = useCreateAccountPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountPlanMutation,
    CreateAccountPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountPlanMutation,
    CreateAccountPlanMutationVariables
  >(CreateAccountPlanDocument, options);
}
export type CreateAccountPlanMutationHookResult = ReturnType<
  typeof useCreateAccountPlanMutation
>;
export type CreateAccountPlanMutationResult =
  Apollo.MutationResult<CreateAccountPlanMutation>;
export type CreateAccountPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountPlanMutation,
  CreateAccountPlanMutationVariables
>;
export const GetAccountContactsDocument = gql`
  query getAccountContacts(
    $otherContactInput: OtherContactInput!
    $defaultContactInput: DefaultContactInput!
  ) {
    relationshipQuery {
      leftSideRelationshipItemsList {
        ... on LeftSideRelationshipItems {
          otherContacts(input: $otherContactInput) {
            __typename
            ... on GraphqlError {
              type
              message
            }
            ... on RelationshipItemConnection {
              pageInfo {
                hasNextPage
                hasPreviousPage
                afterCursor
                beforeCursor
              }
              totalCount
              edges {
                cursor
                node {
                  ... on RelationshipContact {
                    id
                    name
                    title
                    objectType
                    reportTo {
                      ... on UserPayload {
                        items {
                          ... on User {
                            id
                            primaryId
                            firstName
                            lastName
                            title
                            email
                          }
                        }
                      }
                    }
                    contactRoles {
                      ...CoreErrorFields
                      ... on ContactPayload {
                        items {
                          ... on ContactRole {
                            id
                            name
                          }
                        }
                      }
                    }
                    avatar {
                      url
                    }
                    buyerCompany {
                      ... on Company {
                        displayName
                      }
                    }
                  }
                }
              }
            }
          }
          defaultContacts(input: $defaultContactInput) {
            __typename
            ... on GraphqlError {
              type
              message
            }
            ... on RelationshipItemConnection {
              pageInfo {
                hasNextPage
                hasPreviousPage
                afterCursor
                beforeCursor
              }
              totalCount
              edges {
                cursor
                node {
                  ... on RelationshipContact {
                    id
                    name
                    title
                    objectType
                    reportTo {
                      ... on UserPayload {
                        items {
                          ... on User {
                            id
                            primaryId
                            firstName
                            lastName
                            title
                            email
                          }
                        }
                      }
                    }
                    contactRoles {
                      ...CoreErrorFields
                      ... on ContactPayload {
                        items {
                          ... on ContactRole {
                            id
                            name
                          }
                        }
                      }
                    }
                    avatar {
                      url
                    }
                    buyerCompany {
                      ... on Company {
                        displayName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetAccountContactsQuery__
 *
 * To run a query within a React component, call `useGetAccountContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountContactsQuery({
 *   variables: {
 *      otherContactInput: // value for 'otherContactInput'
 *      defaultContactInput: // value for 'defaultContactInput'
 *   },
 * });
 */
export function useGetAccountContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountContactsQuery,
    GetAccountContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountContactsQuery,
    GetAccountContactsQueryVariables
  >(GetAccountContactsDocument, options);
}
export function useGetAccountContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountContactsQuery,
    GetAccountContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountContactsQuery,
    GetAccountContactsQueryVariables
  >(GetAccountContactsDocument, options);
}
export type GetAccountContactsQueryHookResult = ReturnType<
  typeof useGetAccountContactsQuery
>;
export type GetAccountContactsLazyQueryHookResult = ReturnType<
  typeof useGetAccountContactsLazyQuery
>;
export type GetAccountContactsQueryResult = Apollo.QueryResult<
  GetAccountContactsQuery,
  GetAccountContactsQueryVariables
>;
export const GetAccountPlanByExtCompanyIdDocument = gql`
  query getAccountPlanByExtCompanyId($extId: ID!) {
    accountPlanQuery {
      getAccountPlanByExtCompanyId(extCompanyId: $extId) {
        ...CoreErrorFields
        ... on AccountPlanPayload {
          items {
            ... on AccountPlan {
              id
              extCompanyId
              companyId
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetAccountPlanByExtCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetAccountPlanByExtCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPlanByExtCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPlanByExtCompanyIdQuery({
 *   variables: {
 *      extId: // value for 'extId'
 *   },
 * });
 */
export function useGetAccountPlanByExtCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountPlanByExtCompanyIdQuery,
    GetAccountPlanByExtCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountPlanByExtCompanyIdQuery,
    GetAccountPlanByExtCompanyIdQueryVariables
  >(GetAccountPlanByExtCompanyIdDocument, options);
}
export function useGetAccountPlanByExtCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountPlanByExtCompanyIdQuery,
    GetAccountPlanByExtCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountPlanByExtCompanyIdQuery,
    GetAccountPlanByExtCompanyIdQueryVariables
  >(GetAccountPlanByExtCompanyIdDocument, options);
}
export type GetAccountPlanByExtCompanyIdQueryHookResult = ReturnType<
  typeof useGetAccountPlanByExtCompanyIdQuery
>;
export type GetAccountPlanByExtCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetAccountPlanByExtCompanyIdLazyQuery
>;
export type GetAccountPlanByExtCompanyIdQueryResult = Apollo.QueryResult<
  GetAccountPlanByExtCompanyIdQuery,
  GetAccountPlanByExtCompanyIdQueryVariables
>;
export const GetCompanyDetailsDocument = gql`
  query getCompanyDetails($input: ID!) {
    accountPlanQuery {
      getAccountPlan(accountPlanId: $input) {
        ...CoreErrorFields
        ... on AccountPlanPayload {
          items {
            ... on AccountPlan {
              id
              companyId
              orgId
              sellerCompany {
                ... on Company {
                  id
                  orgId
                  companyName
                  displayName
                }
              }
              buyerCompany {
                ... on Company {
                  id
                  orgId
                  companyName
                  displayName
                  logo {
                    ... on Logo {
                      id
                      fileName
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >(GetCompanyDetailsDocument, options);
}
export function useGetCompanyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >(GetCompanyDetailsDocument, options);
}
export type GetCompanyDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsQuery
>;
export type GetCompanyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsLazyQuery
>;
export type GetCompanyDetailsQueryResult = Apollo.QueryResult<
  GetCompanyDetailsQuery,
  GetCompanyDetailsQueryVariables
>;
export const AddReportsToDocument = gql`
  mutation AddReportsTo($input: AddReporteeOrReportsTo!) {
    managerMutation {
      addReportsTo(input: $input) {
        __typename
        ... on ManagerPayload {
          items {
            __typename
            ... on Manager {
              id
              managerId
              order
            }
            ... on GraphqlError {
              code
              message
              type
            }
          }
        }
      }
    }
  }
`;
export type AddReportsToMutationFn = Apollo.MutationFunction<
  AddReportsToMutation,
  AddReportsToMutationVariables
>;

/**
 * __useAddReportsToMutation__
 *
 * To run a mutation, you first call `useAddReportsToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReportsToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReportsToMutation, { data, loading, error }] = useAddReportsToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReportsToMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReportsToMutation,
    AddReportsToMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddReportsToMutation,
    AddReportsToMutationVariables
  >(AddReportsToDocument, options);
}
export type AddReportsToMutationHookResult = ReturnType<
  typeof useAddReportsToMutation
>;
export type AddReportsToMutationResult =
  Apollo.MutationResult<AddReportsToMutation>;
export type AddReportsToMutationOptions = Apollo.BaseMutationOptions<
  AddReportsToMutation,
  AddReportsToMutationVariables
>;
export const BulkAddAndDeleteReportsToDocument = gql`
  mutation BulkAddAndDeleteReportsTo($input: bulkAddAndDeleteReportsToInput!) {
    managerMutation {
      bulkAddAndDeleteReportsTo(input: $input) {
        __typename
        ... on BooleanObject {
          success
          status
        }
        ... on GraphqlError {
          code
          message
          type
        }
      }
    }
  }
`;
export type BulkAddAndDeleteReportsToMutationFn = Apollo.MutationFunction<
  BulkAddAndDeleteReportsToMutation,
  BulkAddAndDeleteReportsToMutationVariables
>;

/**
 * __useBulkAddAndDeleteReportsToMutation__
 *
 * To run a mutation, you first call `useBulkAddAndDeleteReportsToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddAndDeleteReportsToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddAndDeleteReportsToMutation, { data, loading, error }] = useBulkAddAndDeleteReportsToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkAddAndDeleteReportsToMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkAddAndDeleteReportsToMutation,
    BulkAddAndDeleteReportsToMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkAddAndDeleteReportsToMutation,
    BulkAddAndDeleteReportsToMutationVariables
  >(BulkAddAndDeleteReportsToDocument, options);
}
export type BulkAddAndDeleteReportsToMutationHookResult = ReturnType<
  typeof useBulkAddAndDeleteReportsToMutation
>;
export type BulkAddAndDeleteReportsToMutationResult =
  Apollo.MutationResult<BulkAddAndDeleteReportsToMutation>;
export type BulkAddAndDeleteReportsToMutationOptions =
  Apollo.BaseMutationOptions<
    BulkAddAndDeleteReportsToMutation,
    BulkAddAndDeleteReportsToMutationVariables
  >;
export const CloneRelationshipMapDocument = gql`
  mutation cloneRelationshipMap($input: CloneRelationshipMapInput!) {
    relationshipMutation {
      cloneRelationshipMap(input: $input) {
        ... on RelationshipGraph {
          nodes {
            id
            dragging
            type
            height
            width
            position
            positionAbsolute
            selected
            data {
              ... on GraphItemData {
                id
                dataPayload {
                  key
                  id
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
          edges {
            id
            source
            sourceHandle
            type
            style
            target
            targetHandle
            markerEnd
            data {
              ... on GraphItemData {
                id
                dataPayload {
                  key
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
          viewport
        }
      }
    }
  }
`;
export type CloneRelationshipMapMutationFn = Apollo.MutationFunction<
  CloneRelationshipMapMutation,
  CloneRelationshipMapMutationVariables
>;

/**
 * __useCloneRelationshipMapMutation__
 *
 * To run a mutation, you first call `useCloneRelationshipMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneRelationshipMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneRelationshipMapMutation, { data, loading, error }] = useCloneRelationshipMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneRelationshipMapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneRelationshipMapMutation,
    CloneRelationshipMapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloneRelationshipMapMutation,
    CloneRelationshipMapMutationVariables
  >(CloneRelationshipMapDocument, options);
}
export type CloneRelationshipMapMutationHookResult = ReturnType<
  typeof useCloneRelationshipMapMutation
>;
export type CloneRelationshipMapMutationResult =
  Apollo.MutationResult<CloneRelationshipMapMutation>;
export type CloneRelationshipMapMutationOptions = Apollo.BaseMutationOptions<
  CloneRelationshipMapMutation,
  CloneRelationshipMapMutationVariables
>;
export const CreateInfluencerMapContactDocument = gql`
  mutation createInfluencerMapContact(
    $input: CreateInfluencerMapContactInput!
  ) {
    relationshipMutation {
      createInfluencerMapContact(input: $input) {
        __typename
        ... on GraphqlError {
          code
          message
          type
        }
        ... on BooleanObject {
          __typename
          success
          id
        }
      }
    }
  }
`;
export type CreateInfluencerMapContactMutationFn = Apollo.MutationFunction<
  CreateInfluencerMapContactMutation,
  CreateInfluencerMapContactMutationVariables
>;

/**
 * __useCreateInfluencerMapContactMutation__
 *
 * To run a mutation, you first call `useCreateInfluencerMapContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInfluencerMapContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInfluencerMapContactMutation, { data, loading, error }] = useCreateInfluencerMapContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInfluencerMapContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInfluencerMapContactMutation,
    CreateInfluencerMapContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInfluencerMapContactMutation,
    CreateInfluencerMapContactMutationVariables
  >(CreateInfluencerMapContactDocument, options);
}
export type CreateInfluencerMapContactMutationHookResult = ReturnType<
  typeof useCreateInfluencerMapContactMutation
>;
export type CreateInfluencerMapContactMutationResult =
  Apollo.MutationResult<CreateInfluencerMapContactMutation>;
export type CreateInfluencerMapContactMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInfluencerMapContactMutation,
    CreateInfluencerMapContactMutationVariables
  >;
export const CreateInfluencerGraphDocument = gql`
  mutation createInfluencerGraph($input: CreateRelationshipGraphInput!) {
    relationshipMutation {
      createRelationshipGraph(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on RelationshipGraph {
          edges {
            id
            source
            sourceHandle
            type
            style
            target
            targetHandle
            markerEnd
            data {
              ...CoreErrorFieldsFragment
              ... on GraphItemData {
                id
                dataPayload {
                  id
                  key
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
          viewport
          nodes {
            id
            dragging
            type
            height
            width
            position
            positionAbsolute
            selected
            data {
              ...CoreErrorFieldsFragment
              ... on GraphItemData {
                id
                dataPayload {
                  key
                  id
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type CreateInfluencerGraphMutationFn = Apollo.MutationFunction<
  CreateInfluencerGraphMutation,
  CreateInfluencerGraphMutationVariables
>;

/**
 * __useCreateInfluencerGraphMutation__
 *
 * To run a mutation, you first call `useCreateInfluencerGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInfluencerGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInfluencerGraphMutation, { data, loading, error }] = useCreateInfluencerGraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInfluencerGraphMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInfluencerGraphMutation,
    CreateInfluencerGraphMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInfluencerGraphMutation,
    CreateInfluencerGraphMutationVariables
  >(CreateInfluencerGraphDocument, options);
}
export type CreateInfluencerGraphMutationHookResult = ReturnType<
  typeof useCreateInfluencerGraphMutation
>;
export type CreateInfluencerGraphMutationResult =
  Apollo.MutationResult<CreateInfluencerGraphMutation>;
export type CreateInfluencerGraphMutationOptions = Apollo.BaseMutationOptions<
  CreateInfluencerGraphMutation,
  CreateInfluencerGraphMutationVariables
>;
export const CreateNewNoteDocument = gql`
  mutation createNewNote($input: AddNotesInput!) {
    annotationMutation {
      addNotes(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on NotesPayload {
          items {
            ... on Notes {
              id
              annotationType
              reference {
                id
              }
              messageData {
                message
                isEdited
                editedAt
              }
              createdAt
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type CreateNewNoteMutationFn = Apollo.MutationFunction<
  CreateNewNoteMutation,
  CreateNewNoteMutationVariables
>;

/**
 * __useCreateNewNoteMutation__
 *
 * To run a mutation, you first call `useCreateNewNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewNoteMutation, { data, loading, error }] = useCreateNewNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewNoteMutation,
    CreateNewNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewNoteMutation,
    CreateNewNoteMutationVariables
  >(CreateNewNoteDocument, options);
}
export type CreateNewNoteMutationHookResult = ReturnType<
  typeof useCreateNewNoteMutation
>;
export type CreateNewNoteMutationResult =
  Apollo.MutationResult<CreateNewNoteMutation>;
export type CreateNewNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNewNoteMutation,
  CreateNewNoteMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation deleteNote($id: ID!) {
    annotationMutation {
      removeNotes(id: $id) {
        __typename
        ...CoreErrorFieldsFragment
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options,
  );
}
export type DeleteNoteMutationHookResult = ReturnType<
  typeof useDeleteNoteMutation
>;
export type DeleteNoteMutationResult =
  Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const RemoveReportsToDocument = gql`
  mutation RemoveReportsTo($input: RemoveReporteeOrReportsTo!) {
    managerMutation {
      removeReportsTo(input: $input) {
        __typename
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;
export type RemoveReportsToMutationFn = Apollo.MutationFunction<
  RemoveReportsToMutation,
  RemoveReportsToMutationVariables
>;

/**
 * __useRemoveReportsToMutation__
 *
 * To run a mutation, you first call `useRemoveReportsToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReportsToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReportsToMutation, { data, loading, error }] = useRemoveReportsToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveReportsToMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReportsToMutation,
    RemoveReportsToMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveReportsToMutation,
    RemoveReportsToMutationVariables
  >(RemoveReportsToDocument, options);
}
export type RemoveReportsToMutationHookResult = ReturnType<
  typeof useRemoveReportsToMutation
>;
export type RemoveReportsToMutationResult =
  Apollo.MutationResult<RemoveReportsToMutation>;
export type RemoveReportsToMutationOptions = Apollo.BaseMutationOptions<
  RemoveReportsToMutation,
  RemoveReportsToMutationVariables
>;
export const SearchCompanyDocument = gql`
  query searchCompany($operations: Operations!) {
    companyQuery {
      searchCompany(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on GraphqlError {
          code
          type
          message
        }
        ... on CompanyConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on Company {
                id
                orgId
                displayName
                companyName
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchCompanyQuery__
 *
 * To run a query within a React component, call `useSearchCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyQuery({
 *   variables: {
 *      operations: // value for 'operations'
 *   },
 * });
 */
export function useSearchCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCompanyQuery,
    SearchCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(
    SearchCompanyDocument,
    options,
  );
}
export function useSearchCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCompanyQuery,
    SearchCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(
    SearchCompanyDocument,
    options,
  );
}
export type SearchCompanyQueryHookResult = ReturnType<
  typeof useSearchCompanyQuery
>;
export type SearchCompanyLazyQueryHookResult = ReturnType<
  typeof useSearchCompanyLazyQuery
>;
export type SearchCompanyQueryResult = Apollo.QueryResult<
  SearchCompanyQuery,
  SearchCompanyQueryVariables
>;
export const SearchContactsDocument = gql`
  query searchContacts($searchInput: SearchRelationshipItemInput!) {
    relationshipQuery {
      searchRelationshipItems(input: $searchInput) {
        ...CoreErrorFields
        ... on RelationshipItemConnection {
          edges {
            node {
              ... on RelationshipContact {
                id
                name
                title
                objectType
                reportTo {
                  ... on UserPayload {
                    items {
                      ... on User {
                        id
                        primaryId
                        firstName
                        lastName
                        title
                        email
                      }
                    }
                  }
                }
                contactRoles {
                  ...CoreErrorFields
                  ... on ContactPayload {
                    items {
                      ... on ContactRole {
                        id
                        name
                      }
                    }
                  }
                }
                avatar {
                  url
                }
                buyerCompany {
                  ... on Company {
                    displayName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchContactsQuery__
 *
 * To run a query within a React component, call `useSearchContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContactsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useSearchContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchContactsQuery,
    SearchContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchContactsQuery, SearchContactsQueryVariables>(
    SearchContactsDocument,
    options,
  );
}
export function useSearchContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchContactsQuery,
    SearchContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchContactsQuery, SearchContactsQueryVariables>(
    SearchContactsDocument,
    options,
  );
}
export type SearchContactsQueryHookResult = ReturnType<
  typeof useSearchContactsQuery
>;
export type SearchContactsLazyQueryHookResult = ReturnType<
  typeof useSearchContactsLazyQuery
>;
export type SearchContactsQueryResult = Apollo.QueryResult<
  SearchContactsQuery,
  SearchContactsQueryVariables
>;
export const SearchMspsDocument = gql`
  query searchMsps($searchInput: SearchRelationshipMapsInput!) {
    relationshipQuery {
      searchRelationshipMaps(input: $searchInput) {
        ... on RelationshipGraphqlPayload {
          edges {
            ... on RelationshipGraph {
              id
              displayName
              scope {
                scopeId
                scopeType
              }
              analytics {
                ... on RelationshipGraphAnalytics {
                  contactsCount
                  nodesCount
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

/**
 * __useSearchMspsQuery__
 *
 * To run a query within a React component, call `useSearchMspsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMspsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMspsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useSearchMspsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMspsQuery,
    SearchMspsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchMspsQuery, SearchMspsQueryVariables>(
    SearchMspsDocument,
    options,
  );
}
export function useSearchMspsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMspsQuery,
    SearchMspsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchMspsQuery, SearchMspsQueryVariables>(
    SearchMspsDocument,
    options,
  );
}
export type SearchMspsQueryHookResult = ReturnType<typeof useSearchMspsQuery>;
export type SearchMspsLazyQueryHookResult = ReturnType<
  typeof useSearchMspsLazyQuery
>;
export type SearchMspsQueryResult = Apollo.QueryResult<
  SearchMspsQuery,
  SearchMspsQueryVariables
>;
export const UpdateContactFieldsDocument = gql`
  mutation updateContactFields($input: UpdateRelationshipItemDataInput!) {
    relationshipMutation {
      updateRelationshipItemData(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on UpdateRelationshipItemDataPayload {
          items {
            ... on UpdateRelationshipItemData {
              id
              key
              success
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type UpdateContactFieldsMutationFn = Apollo.MutationFunction<
  UpdateContactFieldsMutation,
  UpdateContactFieldsMutationVariables
>;

/**
 * __useUpdateContactFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateContactFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactFieldsMutation, { data, loading, error }] = useUpdateContactFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactFieldsMutation,
    UpdateContactFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactFieldsMutation,
    UpdateContactFieldsMutationVariables
  >(UpdateContactFieldsDocument, options);
}
export type UpdateContactFieldsMutationHookResult = ReturnType<
  typeof useUpdateContactFieldsMutation
>;
export type UpdateContactFieldsMutationResult =
  Apollo.MutationResult<UpdateContactFieldsMutation>;
export type UpdateContactFieldsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactFieldsMutation,
  UpdateContactFieldsMutationVariables
>;
export const UpdateExistingNoteDocument = gql`
  mutation updateExistingNote($input: UpdateNotesInput!) {
    annotationMutation {
      updateNotes(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on NotesPayload {
          items {
            ... on Notes {
              id
              annotationType
              reference {
                id
              }
              messageData {
                message
              }
              createdAt
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type UpdateExistingNoteMutationFn = Apollo.MutationFunction<
  UpdateExistingNoteMutation,
  UpdateExistingNoteMutationVariables
>;

/**
 * __useUpdateExistingNoteMutation__
 *
 * To run a mutation, you first call `useUpdateExistingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingNoteMutation, { data, loading, error }] = useUpdateExistingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExistingNoteMutation,
    UpdateExistingNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExistingNoteMutation,
    UpdateExistingNoteMutationVariables
  >(UpdateExistingNoteDocument, options);
}
export type UpdateExistingNoteMutationHookResult = ReturnType<
  typeof useUpdateExistingNoteMutation
>;
export type UpdateExistingNoteMutationResult =
  Apollo.MutationResult<UpdateExistingNoteMutation>;
export type UpdateExistingNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateExistingNoteMutation,
  UpdateExistingNoteMutationVariables
>;
export const UpdateExistingManagerDocument = gql`
  mutation UpdateExistingManager(
    $addReporteeOrReportsTo: AddReporteeOrReportsTo!
  ) {
    managerMutation {
      addReporteeAndRemoveExistingManager(input: $addReporteeOrReportsTo) {
        ... on ManagerPayload {
          items {
            ... on Manager {
              id
              userId
              managerId
              orgId
            }
          }
        }
        ... on GraphqlError {
          type
          code
          message
          helpLink
        }
      }
    }
  }
`;
export type UpdateExistingManagerMutationFn = Apollo.MutationFunction<
  UpdateExistingManagerMutation,
  UpdateExistingManagerMutationVariables
>;

/**
 * __useUpdateExistingManagerMutation__
 *
 * To run a mutation, you first call `useUpdateExistingManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingManagerMutation, { data, loading, error }] = useUpdateExistingManagerMutation({
 *   variables: {
 *      addReporteeOrReportsTo: // value for 'addReporteeOrReportsTo'
 *   },
 * });
 */
export function useUpdateExistingManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExistingManagerMutation,
    UpdateExistingManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExistingManagerMutation,
    UpdateExistingManagerMutationVariables
  >(UpdateExistingManagerDocument, options);
}
export type UpdateExistingManagerMutationHookResult = ReturnType<
  typeof useUpdateExistingManagerMutation
>;
export type UpdateExistingManagerMutationResult =
  Apollo.MutationResult<UpdateExistingManagerMutation>;
export type UpdateExistingManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateExistingManagerMutation,
  UpdateExistingManagerMutationVariables
>;
export const CreateMilestoneV2Document = gql`
  mutation createMilestoneV2($input: CreateMilestoneInput!) {
    milestoneMutation {
      createMilestoneV2(input: $input) {
        __typename
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MilestonePayload {
          items {
            __typename
            ... on GraphqlError {
              __typename
              type
              code
              helpLink
              message
            }
            ... on Milestone {
              id
              name
              startDate
              createdAt
              endDate
              milestoneDetails {
                ... on GraphqlError {
                  __typename
                  type
                  code
                  helpLink
                  message
                }
                ... on MilestoneDetails {
                  milestoneId
                  goals
                  riskDependencies
                  successCriteria
                }
              }
              analytics {
                ... on GraphqlError {
                  __typename
                  type
                  code
                  helpLink
                  message
                }
                ... on MilestoneAnalytics {
                  mediaResourceCount {
                    ... on GraphqlError {
                      __typename
                      type
                      code
                      helpLink
                      message
                    }
                    ... on BaResourceCountObject {
                      internalCount
                      externalCount
                    }
                  }
                }
              }
              tasks {
                ... on GraphqlError {
                  code
                  type
                  message
                }
                ... on TaskConnection {
                  edges {
                    node {
                      __typename
                      ... on GraphqlError {
                        code
                        message
                        type
                        helpLink
                      }
                      ... on Task {
                        id
                        name
                        dueDate
                        status
                        description
                        isInternal
                        assignee {
                          ... on User {
                            id
                            firstName
                            lastName
                            email
                            userType
                          }
                        }
                      }
                    }
                  }
                }
              }
              media {
                ... on GraphqlError {
                  __typename
                  type
                  code
                  helpLink
                  message
                }
                ... on MediaConnection {
                  edges {
                    node {
                      __typename
                      ... on MediaPayloadItem {
                        item {
                          ... on Media {
                            id
                            name
                            orgId
                            createdBy {
                              ... on GraphqlError {
                                __typename
                                type
                                code
                                helpLink
                                message
                              }
                              ... on User {
                                id
                                firstName
                                lastName
                                email
                                userType
                                __typename
                              }
                              __typename
                            }
                            description
                            mediaType
                            mimeType
                            createdAt
                            ...AssetsFragment
                            ...ImageFragment
                            ...AudioFragment
                            ...DocumentFragment
                            ...VideoFragment
                            ...LinkFragment
                            __typename
                          }
                        }
                      }
                    }
                  }
                }
              }
              isInternal
            }
          }
        }
      }
    }
  }
  ${AssetsFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${AudioFragmentFragmentDoc}
  ${DocumentFragmentFragmentDoc}
  ${VideoFragmentFragmentDoc}
  ${LinkFragmentFragmentDoc}
`;
export type CreateMilestoneV2MutationFn = Apollo.MutationFunction<
  CreateMilestoneV2Mutation,
  CreateMilestoneV2MutationVariables
>;

/**
 * __useCreateMilestoneV2Mutation__
 *
 * To run a mutation, you first call `useCreateMilestoneV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneV2Mutation, { data, loading, error }] = useCreateMilestoneV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMilestoneV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMilestoneV2Mutation,
    CreateMilestoneV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMilestoneV2Mutation,
    CreateMilestoneV2MutationVariables
  >(CreateMilestoneV2Document, options);
}
export type CreateMilestoneV2MutationHookResult = ReturnType<
  typeof useCreateMilestoneV2Mutation
>;
export type CreateMilestoneV2MutationResult =
  Apollo.MutationResult<CreateMilestoneV2Mutation>;
export type CreateMilestoneV2MutationOptions = Apollo.BaseMutationOptions<
  CreateMilestoneV2Mutation,
  CreateMilestoneV2MutationVariables
>;
export const GetMspWithMilestonesDocument = gql`
  query GetMspWithMilestones($id: ID!) {
    mspQuery {
      getMsp(id: $id) {
        __typename
        ...CoreErrorFieldsFragment
        ... on MspPayload {
          items {
            ...CoreErrorFieldsFragment
            ... on Msp {
              milestones {
                ...CoreErrorFieldsFragment
                ... on MilestoneConnection {
                  edges {
                    node {
                      __typename
                      ...CoreErrorFieldsFragment
                      ...MilestoneDetailsFragment
                    }
                  }
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
  ${MilestoneDetailsFragmentFragmentDoc}
`;

/**
 * __useGetMspWithMilestonesQuery__
 *
 * To run a query within a React component, call `useGetMspWithMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspWithMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspWithMilestonesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMspWithMilestonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspWithMilestonesQuery,
    GetMspWithMilestonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspWithMilestonesQuery,
    GetMspWithMilestonesQueryVariables
  >(GetMspWithMilestonesDocument, options);
}
export function useGetMspWithMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspWithMilestonesQuery,
    GetMspWithMilestonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspWithMilestonesQuery,
    GetMspWithMilestonesQueryVariables
  >(GetMspWithMilestonesDocument, options);
}
export type GetMspWithMilestonesQueryHookResult = ReturnType<
  typeof useGetMspWithMilestonesQuery
>;
export type GetMspWithMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetMspWithMilestonesLazyQuery
>;
export type GetMspWithMilestonesQueryResult = Apollo.QueryResult<
  GetMspWithMilestonesQuery,
  GetMspWithMilestonesQueryVariables
>;
export const UpdateMeddpiccForMspDocument = gql`
  mutation updateMeddpiccForMsp($mspId: ID!, $input: UpdateMeddpiccInput!) {
    meddpiccMutation {
      updateMeddpicc(mspId: $mspId, input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddpiccPayload {
          items {
            ... on Meddpicc {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              paperProcess {
                current {
                  data {
                    __typename
                    ... on Task {
                      id
                      name
                      dueDate
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      email
                      lastName
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              competition {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type UpdateMeddpiccForMspMutationFn = Apollo.MutationFunction<
  UpdateMeddpiccForMspMutation,
  UpdateMeddpiccForMspMutationVariables
>;

/**
 * __useUpdateMeddpiccForMspMutation__
 *
 * To run a mutation, you first call `useUpdateMeddpiccForMspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeddpiccForMspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeddpiccForMspMutation, { data, loading, error }] = useUpdateMeddpiccForMspMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeddpiccForMspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeddpiccForMspMutation,
    UpdateMeddpiccForMspMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMeddpiccForMspMutation,
    UpdateMeddpiccForMspMutationVariables
  >(UpdateMeddpiccForMspDocument, options);
}
export type UpdateMeddpiccForMspMutationHookResult = ReturnType<
  typeof useUpdateMeddpiccForMspMutation
>;
export type UpdateMeddpiccForMspMutationResult =
  Apollo.MutationResult<UpdateMeddpiccForMspMutation>;
export type UpdateMeddpiccForMspMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeddpiccForMspMutation,
  UpdateMeddpiccForMspMutationVariables
>;
export const SyncMeddpiccDocument = gql`
  mutation syncMeddpicc($mspId: ID!, $input: SyncMeddpiccInput!) {
    meddpiccMutation {
      syncMeddpicc(mspId: $mspId, input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddpiccPayload {
          items {
            ... on Meddpicc {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              paperProcess {
                current {
                  data {
                    __typename
                    ... on Task {
                      id
                      name
                      dueDate
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              competition {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SyncMeddpiccMutationFn = Apollo.MutationFunction<
  SyncMeddpiccMutation,
  SyncMeddpiccMutationVariables
>;

/**
 * __useSyncMeddpiccMutation__
 *
 * To run a mutation, you first call `useSyncMeddpiccMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMeddpiccMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMeddpiccMutation, { data, loading, error }] = useSyncMeddpiccMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncMeddpiccMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncMeddpiccMutation,
    SyncMeddpiccMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncMeddpiccMutation,
    SyncMeddpiccMutationVariables
  >(SyncMeddpiccDocument, options);
}
export type SyncMeddpiccMutationHookResult = ReturnType<
  typeof useSyncMeddpiccMutation
>;
export type SyncMeddpiccMutationResult =
  Apollo.MutationResult<SyncMeddpiccMutation>;
export type SyncMeddpiccMutationOptions = Apollo.BaseMutationOptions<
  SyncMeddpiccMutation,
  SyncMeddpiccMutationVariables
>;
export const UpdateMeddicForMspDocument = gql`
  mutation updateMeddicForMsp($mspId: ID!, $input: UpdateMeddicInput!) {
    meddicMutation {
      updateMeddic(mspId: $mspId, input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddicPayload {
          items {
            ... on Meddic {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      email
                      lastName
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type UpdateMeddicForMspMutationFn = Apollo.MutationFunction<
  UpdateMeddicForMspMutation,
  UpdateMeddicForMspMutationVariables
>;

/**
 * __useUpdateMeddicForMspMutation__
 *
 * To run a mutation, you first call `useUpdateMeddicForMspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeddicForMspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeddicForMspMutation, { data, loading, error }] = useUpdateMeddicForMspMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeddicForMspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeddicForMspMutation,
    UpdateMeddicForMspMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMeddicForMspMutation,
    UpdateMeddicForMspMutationVariables
  >(UpdateMeddicForMspDocument, options);
}
export type UpdateMeddicForMspMutationHookResult = ReturnType<
  typeof useUpdateMeddicForMspMutation
>;
export type UpdateMeddicForMspMutationResult =
  Apollo.MutationResult<UpdateMeddicForMspMutation>;
export type UpdateMeddicForMspMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeddicForMspMutation,
  UpdateMeddicForMspMutationVariables
>;
export const SyncMeddicDocument = gql`
  mutation syncMeddic($mspId: ID!, $input: SyncMeddicInput!) {
    meddicMutation {
      syncMeddic(mspId: $mspId, input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddicPayload {
          items {
            ... on Meddic {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SyncMeddicMutationFn = Apollo.MutationFunction<
  SyncMeddicMutation,
  SyncMeddicMutationVariables
>;

/**
 * __useSyncMeddicMutation__
 *
 * To run a mutation, you first call `useSyncMeddicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMeddicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMeddicMutation, { data, loading, error }] = useSyncMeddicMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncMeddicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncMeddicMutation,
    SyncMeddicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncMeddicMutation, SyncMeddicMutationVariables>(
    SyncMeddicDocument,
    options,
  );
}
export type SyncMeddicMutationHookResult = ReturnType<
  typeof useSyncMeddicMutation
>;
export type SyncMeddicMutationResult =
  Apollo.MutationResult<SyncMeddicMutation>;
export type SyncMeddicMutationOptions = Apollo.BaseMutationOptions<
  SyncMeddicMutation,
  SyncMeddicMutationVariables
>;
export const UpdateMeddpiccpaForMspDocument = gql`
  mutation updateMeddpiccpaForMsp($mspId: ID!, $input: UpdateMeddpiccpaInput!) {
    meddpiccpaMutation {
      updateMeddpiccpa(mspId: $mspId, input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
          __typename
        }
        ... on MeddpiccpaPayload {
          items {
            ... on Meddpiccpa {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                  __typename
                }
                __typename
              }
              metrics {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              paperProcess {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                      __typename
                    }
                  }
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                __typename
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                      __typename
                    }
                  }
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                __typename
              }
              competition {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              product {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              additionalInfo {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;
export type UpdateMeddpiccpaForMspMutationFn = Apollo.MutationFunction<
  UpdateMeddpiccpaForMspMutation,
  UpdateMeddpiccpaForMspMutationVariables
>;

/**
 * __useUpdateMeddpiccpaForMspMutation__
 *
 * To run a mutation, you first call `useUpdateMeddpiccpaForMspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeddpiccpaForMspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeddpiccpaForMspMutation, { data, loading, error }] = useUpdateMeddpiccpaForMspMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeddpiccpaForMspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeddpiccpaForMspMutation,
    UpdateMeddpiccpaForMspMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMeddpiccpaForMspMutation,
    UpdateMeddpiccpaForMspMutationVariables
  >(UpdateMeddpiccpaForMspDocument, options);
}
export type UpdateMeddpiccpaForMspMutationHookResult = ReturnType<
  typeof useUpdateMeddpiccpaForMspMutation
>;
export type UpdateMeddpiccpaForMspMutationResult =
  Apollo.MutationResult<UpdateMeddpiccpaForMspMutation>;
export type UpdateMeddpiccpaForMspMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeddpiccpaForMspMutation,
  UpdateMeddpiccpaForMspMutationVariables
>;
export const UpdatevalueSellingForMspDocument = gql`
  mutation updatevalueSellingForMsp(
    $mspId: ID!
    $input: UpdateValueSellingInput!
  ) {
    valueSellingMutation {
      updateValueSelling(mspId: $mspId, input: $input) {
        ... on GraphqlError {
          code
          type
          message
          __typename
        }
        ... on ValueSellingPayload {
          items {
            ... on ValueSelling {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                  __typename
                }
                __typename
              }
              objectivesAndSuccessMeasures {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              costofInaction {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              roi {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              urgency {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              ourProof {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              competitiveDifferentiation {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              decisionMakerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              influencerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              catalystInfluencerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              processUnderstandingAndAlignment {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              purchaseRequirements {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              demoQuality {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              funding {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              keyThreats {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export type UpdatevalueSellingForMspMutationFn = Apollo.MutationFunction<
  UpdatevalueSellingForMspMutation,
  UpdatevalueSellingForMspMutationVariables
>;

/**
 * __useUpdatevalueSellingForMspMutation__
 *
 * To run a mutation, you first call `useUpdatevalueSellingForMspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatevalueSellingForMspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatevalueSellingForMspMutation, { data, loading, error }] = useUpdatevalueSellingForMspMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatevalueSellingForMspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatevalueSellingForMspMutation,
    UpdatevalueSellingForMspMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatevalueSellingForMspMutation,
    UpdatevalueSellingForMspMutationVariables
  >(UpdatevalueSellingForMspDocument, options);
}
export type UpdatevalueSellingForMspMutationHookResult = ReturnType<
  typeof useUpdatevalueSellingForMspMutation
>;
export type UpdatevalueSellingForMspMutationResult =
  Apollo.MutationResult<UpdatevalueSellingForMspMutation>;
export type UpdatevalueSellingForMspMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatevalueSellingForMspMutation,
    UpdatevalueSellingForMspMutationVariables
  >;
export const GetMeddpiccForMspDocument = gql`
  query getMeddpiccForMsp($mspId: ID!) {
    meddpiccQuery {
      getMeddpiccForMsp(mspId: $mspId) {
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddpiccPayload {
          items {
            ... on Meddpicc {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              paperProcess {
                current {
                  data {
                    __typename
                    ... on Task {
                      id
                      name
                      dueDate
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              competition {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMeddpiccForMspQuery__
 *
 * To run a query within a React component, call `useGetMeddpiccForMspQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeddpiccForMspQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeddpiccForMspQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMeddpiccForMspQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMeddpiccForMspQuery,
    GetMeddpiccForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMeddpiccForMspQuery,
    GetMeddpiccForMspQueryVariables
  >(GetMeddpiccForMspDocument, options);
}
export function useGetMeddpiccForMspLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeddpiccForMspQuery,
    GetMeddpiccForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeddpiccForMspQuery,
    GetMeddpiccForMspQueryVariables
  >(GetMeddpiccForMspDocument, options);
}
export type GetMeddpiccForMspQueryHookResult = ReturnType<
  typeof useGetMeddpiccForMspQuery
>;
export type GetMeddpiccForMspLazyQueryHookResult = ReturnType<
  typeof useGetMeddpiccForMspLazyQuery
>;
export type GetMeddpiccForMspQueryResult = Apollo.QueryResult<
  GetMeddpiccForMspQuery,
  GetMeddpiccForMspQueryVariables
>;
export const GetMeddicForMspDocument = gql`
  query getMeddicForMsp($mspId: ID!) {
    meddicQuery {
      getMeddicForMsp(mspId: $mspId) {
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddicPayload {
          items {
            ... on Meddic {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              champion {
                current {
                  data {
                    __typename
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMeddicForMspQuery__
 *
 * To run a query within a React component, call `useGetMeddicForMspQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeddicForMspQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeddicForMspQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMeddicForMspQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMeddicForMspQuery,
    GetMeddicForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeddicForMspQuery, GetMeddicForMspQueryVariables>(
    GetMeddicForMspDocument,
    options,
  );
}
export function useGetMeddicForMspLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeddicForMspQuery,
    GetMeddicForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeddicForMspQuery,
    GetMeddicForMspQueryVariables
  >(GetMeddicForMspDocument, options);
}
export type GetMeddicForMspQueryHookResult = ReturnType<
  typeof useGetMeddicForMspQuery
>;
export type GetMeddicForMspLazyQueryHookResult = ReturnType<
  typeof useGetMeddicForMspLazyQuery
>;
export type GetMeddicForMspQueryResult = Apollo.QueryResult<
  GetMeddicForMspQuery,
  GetMeddicForMspQueryVariables
>;
export const GetMeddpiccpaForMspDocument = gql`
  query getMeddpiccpaForMsp($mspId: ID!) {
    meddpiccpaQuery {
      getMeddpiccpaForMsp(mspId: $mspId) {
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MeddpiccpaPayload {
          items {
            ... on Meddpiccpa {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                }
              }
              metrics {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              paperProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              economicBuyer {
                current {
                  data {
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              decisionProcess {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              decisionCriteria {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              identifiedPain {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              champion {
                current {
                  data {
                    ... on User {
                      id
                      primaryId
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
              }
              competition {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              product {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
              additionalInfo {
                current {
                  data
                  notes {
                    value
                  }
                  score
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                  }
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMeddpiccpaForMspQuery__
 *
 * To run a query within a React component, call `useGetMeddpiccpaForMspQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeddpiccpaForMspQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeddpiccpaForMspQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMeddpiccpaForMspQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMeddpiccpaForMspQuery,
    GetMeddpiccpaForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMeddpiccpaForMspQuery,
    GetMeddpiccpaForMspQueryVariables
  >(GetMeddpiccpaForMspDocument, options);
}
export function useGetMeddpiccpaForMspLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeddpiccpaForMspQuery,
    GetMeddpiccpaForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeddpiccpaForMspQuery,
    GetMeddpiccpaForMspQueryVariables
  >(GetMeddpiccpaForMspDocument, options);
}
export type GetMeddpiccpaForMspQueryHookResult = ReturnType<
  typeof useGetMeddpiccpaForMspQuery
>;
export type GetMeddpiccpaForMspLazyQueryHookResult = ReturnType<
  typeof useGetMeddpiccpaForMspLazyQuery
>;
export type GetMeddpiccpaForMspQueryResult = Apollo.QueryResult<
  GetMeddpiccpaForMspQuery,
  GetMeddpiccpaForMspQueryVariables
>;
export const GetValueSellingForMspDocument = gql`
  query getValueSellingForMsp($mspId: ID!) {
    valueSellingQuery {
      getValueSellingForMsp(mspId: $mspId) {
        ... on GraphqlError {
          code
          type
          message
          __typename
        }
        ... on ValueSellingPayload {
          items {
            ... on ValueSelling {
              id
              mspId
              analytics {
                ... on MethodologyAnalytics {
                  isSyncEnabled
                  syncDisableReason
                  lastSyncedAt
                  isSynced
                  firstSyncDone
                  __typename
                }
                __typename
              }
              objectivesAndSuccessMeasures {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              costofInaction {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              roi {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              urgency {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              ourProof {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              competitiveDifferentiation {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              decisionMakerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              influencerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              catalystInfluencerEngagement {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              processUnderstandingAndAlignment {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              purchaseRequirements {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              demoQuality {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              funding {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              keyThreats {
                current {
                  data
                  notes {
                    value
                    __typename
                  }
                  score
                  __typename
                }
                analytics {
                  ... on MethodologyQualifierAnalytics {
                    isSynced
                    __typename
                  }
                  __typename
                }
                metadata {
                  ... on MethodologyQualifierMetadata {
                    isDataEditable
                    displayName
                    description
                    defaultData
                    score {
                      ... on MethodologyQualifierScoreSettings {
                        placeholder
                        options {
                          ... on MethodologyQualifierScoreOptions {
                            displayName
                            order
                            value
                            __typename
                          }
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetValueSellingForMspQuery__
 *
 * To run a query within a React component, call `useGetValueSellingForMspQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueSellingForMspQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueSellingForMspQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetValueSellingForMspQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetValueSellingForMspQuery,
    GetValueSellingForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetValueSellingForMspQuery,
    GetValueSellingForMspQueryVariables
  >(GetValueSellingForMspDocument, options);
}
export function useGetValueSellingForMspLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetValueSellingForMspQuery,
    GetValueSellingForMspQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetValueSellingForMspQuery,
    GetValueSellingForMspQueryVariables
  >(GetValueSellingForMspDocument, options);
}
export type GetValueSellingForMspQueryHookResult = ReturnType<
  typeof useGetValueSellingForMspQuery
>;
export type GetValueSellingForMspLazyQueryHookResult = ReturnType<
  typeof useGetValueSellingForMspLazyQuery
>;
export type GetValueSellingForMspQueryResult = Apollo.QueryResult<
  GetValueSellingForMspQuery,
  GetValueSellingForMspQueryVariables
>;
export const SyncVvsDocument = gql`
  mutation syncVvs($mspId: ID!, $input: SyncValueSellingInput!) {
    valueSellingMutation {
      syncValueSelling(mspId: $mspId, input: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on ValueSellingPayload {
          items {
            ... on ValueSelling {
              keyThreats {
                current {
                  score
                  notes {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SyncVvsMutationFn = Apollo.MutationFunction<
  SyncVvsMutation,
  SyncVvsMutationVariables
>;

/**
 * __useSyncVvsMutation__
 *
 * To run a mutation, you first call `useSyncVvsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncVvsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncVvsMutation, { data, loading, error }] = useSyncVvsMutation({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncVvsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncVvsMutation,
    SyncVvsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncVvsMutation, SyncVvsMutationVariables>(
    SyncVvsDocument,
    options,
  );
}
export type SyncVvsMutationHookResult = ReturnType<typeof useSyncVvsMutation>;
export type SyncVvsMutationResult = Apollo.MutationResult<SyncVvsMutation>;
export type SyncVvsMutationOptions = Apollo.BaseMutationOptions<
  SyncVvsMutation,
  SyncVvsMutationVariables
>;
export const CreateExternalLinkMutationDocument = gql`
  mutation createExternalLinkMutation($input: ExternalLinkInput!) {
    mediaMutation {
      createExternalLink(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on MediaExternalLink {
          url
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type CreateExternalLinkMutationMutationFn = Apollo.MutationFunction<
  CreateExternalLinkMutationMutation,
  CreateExternalLinkMutationMutationVariables
>;

/**
 * __useCreateExternalLinkMutationMutation__
 *
 * To run a mutation, you first call `useCreateExternalLinkMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalLinkMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalLinkMutationMutation, { data, loading, error }] = useCreateExternalLinkMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalLinkMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalLinkMutationMutation,
    CreateExternalLinkMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalLinkMutationMutation,
    CreateExternalLinkMutationMutationVariables
  >(CreateExternalLinkMutationDocument, options);
}
export type CreateExternalLinkMutationMutationHookResult = ReturnType<
  typeof useCreateExternalLinkMutationMutation
>;
export type CreateExternalLinkMutationMutationResult =
  Apollo.MutationResult<CreateExternalLinkMutationMutation>;
export type CreateExternalLinkMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExternalLinkMutationMutation,
    CreateExternalLinkMutationMutationVariables
  >;
export const FavoriteResourceDocument = gql`
  mutation favoriteResource($input: FavoriteMediaInput!) {
    mediaMutation {
      favoriteMedia(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type FavoriteResourceMutationFn = Apollo.MutationFunction<
  FavoriteResourceMutation,
  FavoriteResourceMutationVariables
>;

/**
 * __useFavoriteResourceMutation__
 *
 * To run a mutation, you first call `useFavoriteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteResourceMutation, { data, loading, error }] = useFavoriteResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavoriteResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FavoriteResourceMutation,
    FavoriteResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FavoriteResourceMutation,
    FavoriteResourceMutationVariables
  >(FavoriteResourceDocument, options);
}
export type FavoriteResourceMutationHookResult = ReturnType<
  typeof useFavoriteResourceMutation
>;
export type FavoriteResourceMutationResult =
  Apollo.MutationResult<FavoriteResourceMutation>;
export type FavoriteResourceMutationOptions = Apollo.BaseMutationOptions<
  FavoriteResourceMutation,
  FavoriteResourceMutationVariables
>;
export const PinResourceDocument = gql`
  mutation pinResource($input: PinMediaInput!) {
    mediaMutation {
      pinMedia(input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;
export type PinResourceMutationFn = Apollo.MutationFunction<
  PinResourceMutation,
  PinResourceMutationVariables
>;

/**
 * __usePinResourceMutation__
 *
 * To run a mutation, you first call `usePinResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinResourceMutation, { data, loading, error }] = usePinResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePinResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PinResourceMutation,
    PinResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PinResourceMutation, PinResourceMutationVariables>(
    PinResourceDocument,
    options,
  );
}
export type PinResourceMutationHookResult = ReturnType<
  typeof usePinResourceMutation
>;
export type PinResourceMutationResult =
  Apollo.MutationResult<PinResourceMutation>;
export type PinResourceMutationOptions = Apollo.BaseMutationOptions<
  PinResourceMutation,
  PinResourceMutationVariables
>;
export const RenameFileDocument = gql`
  mutation renameFile($input: UpdateMediaInput!) {
    mediaMutation {
      updateMedia(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on MediaPayload {
          items {
            ... on MediaPayloadItem {
              ... on MediaPayloadItem {
                item {
                  id
                  name
                  description
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type RenameFileMutationFn = Apollo.MutationFunction<
  RenameFileMutation,
  RenameFileMutationVariables
>;

/**
 * __useRenameFileMutation__
 *
 * To run a mutation, you first call `useRenameFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFileMutation, { data, loading, error }] = useRenameFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameFileMutation,
    RenameFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameFileMutation, RenameFileMutationVariables>(
    RenameFileDocument,
    options,
  );
}
export type RenameFileMutationHookResult = ReturnType<
  typeof useRenameFileMutation
>;
export type RenameFileMutationResult =
  Apollo.MutationResult<RenameFileMutation>;
export type RenameFileMutationOptions = Apollo.BaseMutationOptions<
  RenameFileMutation,
  RenameFileMutationVariables
>;
export const RevokeExternalAccessDocument = gql`
  mutation revokeExternalAccess($input: RevokeMediaExternalAccessInput!) {
    mediaMutation {
      revokeExternalAccess(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type RevokeExternalAccessMutationFn = Apollo.MutationFunction<
  RevokeExternalAccessMutation,
  RevokeExternalAccessMutationVariables
>;

/**
 * __useRevokeExternalAccessMutation__
 *
 * To run a mutation, you first call `useRevokeExternalAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeExternalAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeExternalAccessMutation, { data, loading, error }] = useRevokeExternalAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeExternalAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeExternalAccessMutation,
    RevokeExternalAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeExternalAccessMutation,
    RevokeExternalAccessMutationVariables
  >(RevokeExternalAccessDocument, options);
}
export type RevokeExternalAccessMutationHookResult = ReturnType<
  typeof useRevokeExternalAccessMutation
>;
export type RevokeExternalAccessMutationResult =
  Apollo.MutationResult<RevokeExternalAccessMutation>;
export type RevokeExternalAccessMutationOptions = Apollo.BaseMutationOptions<
  RevokeExternalAccessMutation,
  RevokeExternalAccessMutationVariables
>;
export const UnFavoriteResourceDocument = gql`
  mutation unFavoriteResource($input: UnfavoriteMediaInput!) {
    mediaMutation {
      unfavoriteMedia(input: $input) {
        __typename
        ...CoreErrorFieldsFragment
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentFragmentDoc}
`;
export type UnFavoriteResourceMutationFn = Apollo.MutationFunction<
  UnFavoriteResourceMutation,
  UnFavoriteResourceMutationVariables
>;

/**
 * __useUnFavoriteResourceMutation__
 *
 * To run a mutation, you first call `useUnFavoriteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFavoriteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFavoriteResourceMutation, { data, loading, error }] = useUnFavoriteResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnFavoriteResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnFavoriteResourceMutation,
    UnFavoriteResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnFavoriteResourceMutation,
    UnFavoriteResourceMutationVariables
  >(UnFavoriteResourceDocument, options);
}
export type UnFavoriteResourceMutationHookResult = ReturnType<
  typeof useUnFavoriteResourceMutation
>;
export type UnFavoriteResourceMutationResult =
  Apollo.MutationResult<UnFavoriteResourceMutation>;
export type UnFavoriteResourceMutationOptions = Apollo.BaseMutationOptions<
  UnFavoriteResourceMutation,
  UnFavoriteResourceMutationVariables
>;
export const UnpinResourceDocument = gql`
  mutation unpinResource($input: UnpinMediaInput!) {
    mediaMutation {
      unpinMedia(input: $input) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;
export type UnpinResourceMutationFn = Apollo.MutationFunction<
  UnpinResourceMutation,
  UnpinResourceMutationVariables
>;

/**
 * __useUnpinResourceMutation__
 *
 * To run a mutation, you first call `useUnpinResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinResourceMutation, { data, loading, error }] = useUnpinResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpinResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpinResourceMutation,
    UnpinResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpinResourceMutation,
    UnpinResourceMutationVariables
  >(UnpinResourceDocument, options);
}
export type UnpinResourceMutationHookResult = ReturnType<
  typeof useUnpinResourceMutation
>;
export type UnpinResourceMutationResult =
  Apollo.MutationResult<UnpinResourceMutation>;
export type UnpinResourceMutationOptions = Apollo.BaseMutationOptions<
  UnpinResourceMutation,
  UnpinResourceMutationVariables
>;
export const GetStakeholderUserEngagementDocument = gql`
  query getStakeholderUserEngagement(
    $mspId: ID!
    $input: GetUserEngagementRequest
  ) {
    mspAnalyticsQuery {
      getUserEngagement(mspId: $mspId, requestOptions: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on UserEngagementConnection {
          edges {
            node {
              __typename
              ... on UserEngagement {
                id
                mspViewCount
                mediaViewCount
                mediaDownloadCount
                commentCount
                user {
                  __typename
                  ... on User {
                    firstName
                  }
                }
                userTasksAnalytics {
                  ... on UserTaskAnalytics {
                    overdueTask {
                      ... on UserTaskStatusDetails {
                        count
                      }
                    }
                    assignedTask {
                      ... on UserTaskStatusDetails {
                        count
                      }
                    }
                    completedTask {
                      ... on UserTaskStatusDetails {
                        count
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStakeholderUserEngagementQuery__
 *
 * To run a query within a React component, call `useGetStakeholderUserEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStakeholderUserEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStakeholderUserEngagementQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStakeholderUserEngagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStakeholderUserEngagementQuery,
    GetStakeholderUserEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStakeholderUserEngagementQuery,
    GetStakeholderUserEngagementQueryVariables
  >(GetStakeholderUserEngagementDocument, options);
}
export function useGetStakeholderUserEngagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStakeholderUserEngagementQuery,
    GetStakeholderUserEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStakeholderUserEngagementQuery,
    GetStakeholderUserEngagementQueryVariables
  >(GetStakeholderUserEngagementDocument, options);
}
export type GetStakeholderUserEngagementQueryHookResult = ReturnType<
  typeof useGetStakeholderUserEngagementQuery
>;
export type GetStakeholderUserEngagementLazyQueryHookResult = ReturnType<
  typeof useGetStakeholderUserEngagementLazyQuery
>;
export type GetStakeholderUserEngagementQueryResult = Apollo.QueryResult<
  GetStakeholderUserEngagementQuery,
  GetStakeholderUserEngagementQueryVariables
>;
export const GetMspByExtOpportunityIdDocument = gql`
  query getMspByExtOpportunityId($extId: ID!) {
    mspQuery {
      getMspByExtOpportunityId(extId: $extId) {
        ...CoreErrorFields
        ... on Msp {
          id
          name
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetMspByExtOpportunityIdQuery__
 *
 * To run a query within a React component, call `useGetMspByExtOpportunityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspByExtOpportunityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspByExtOpportunityIdQuery({
 *   variables: {
 *      extId: // value for 'extId'
 *   },
 * });
 */
export function useGetMspByExtOpportunityIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspByExtOpportunityIdQuery,
    GetMspByExtOpportunityIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspByExtOpportunityIdQuery,
    GetMspByExtOpportunityIdQueryVariables
  >(GetMspByExtOpportunityIdDocument, options);
}
export function useGetMspByExtOpportunityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspByExtOpportunityIdQuery,
    GetMspByExtOpportunityIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspByExtOpportunityIdQuery,
    GetMspByExtOpportunityIdQueryVariables
  >(GetMspByExtOpportunityIdDocument, options);
}
export type GetMspByExtOpportunityIdQueryHookResult = ReturnType<
  typeof useGetMspByExtOpportunityIdQuery
>;
export type GetMspByExtOpportunityIdLazyQueryHookResult = ReturnType<
  typeof useGetMspByExtOpportunityIdLazyQuery
>;
export type GetMspByExtOpportunityIdQueryResult = Apollo.QueryResult<
  GetMspByExtOpportunityIdQuery,
  GetMspByExtOpportunityIdQueryVariables
>;
export const GetFilteredMspListDocument = gql`
  query GetFilteredMspList(
    $mspOperations: Operations
    $pageConfiguration: PageConfiguration
  ) {
    mspQuery {
      listMspsToLinkInCRM(
        operations: $mspOperations
        pageConfiguration: $pageConfiguration
      ) {
        ...CoreErrorFields
        ... on MspConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
          }
          edges {
            node {
              ...CoreErrorFields
              ... on Msp {
                id
                name
                isOwner
                buyerCompany {
                  __typename
                  ...CoreErrorFields
                  ... on Company {
                    id
                    logo {
                      ... on Logo {
                        id
                        fileName
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useGetFilteredMspListQuery__
 *
 * To run a query within a React component, call `useGetFilteredMspListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredMspListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredMspListQuery({
 *   variables: {
 *      mspOperations: // value for 'mspOperations'
 *      pageConfiguration: // value for 'pageConfiguration'
 *   },
 * });
 */
export function useGetFilteredMspListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFilteredMspListQuery,
    GetFilteredMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilteredMspListQuery,
    GetFilteredMspListQueryVariables
  >(GetFilteredMspListDocument, options);
}
export function useGetFilteredMspListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilteredMspListQuery,
    GetFilteredMspListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilteredMspListQuery,
    GetFilteredMspListQueryVariables
  >(GetFilteredMspListDocument, options);
}
export type GetFilteredMspListQueryHookResult = ReturnType<
  typeof useGetFilteredMspListQuery
>;
export type GetFilteredMspListLazyQueryHookResult = ReturnType<
  typeof useGetFilteredMspListLazyQuery
>;
export type GetFilteredMspListQueryResult = Apollo.QueryResult<
  GetFilteredMspListQuery,
  GetFilteredMspListQueryVariables
>;
export const UpdateMspOpportunityDocument = gql`
  mutation UpdateMspOpportunity($input: LinkOpportunityInput!) {
    mspMutation {
      linkOpportunity(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          success
          id
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;
export type UpdateMspOpportunityMutationFn = Apollo.MutationFunction<
  UpdateMspOpportunityMutation,
  UpdateMspOpportunityMutationVariables
>;

/**
 * __useUpdateMspOpportunityMutation__
 *
 * To run a mutation, you first call `useUpdateMspOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMspOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMspOpportunityMutation, { data, loading, error }] = useUpdateMspOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMspOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMspOpportunityMutation,
    UpdateMspOpportunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMspOpportunityMutation,
    UpdateMspOpportunityMutationVariables
  >(UpdateMspOpportunityDocument, options);
}
export type UpdateMspOpportunityMutationHookResult = ReturnType<
  typeof useUpdateMspOpportunityMutation
>;
export type UpdateMspOpportunityMutationResult =
  Apollo.MutationResult<UpdateMspOpportunityMutation>;
export type UpdateMspOpportunityMutationOptions = Apollo.BaseMutationOptions<
  UpdateMspOpportunityMutation,
  UpdateMspOpportunityMutationVariables
>;
export const SearchOpportunityByExtIdDocument = gql`
  query searchOpportunityByExtId($opportunityOperation: Operations) {
    opportunityQuery {
      searchOpportunity(operations: $opportunityOperation) {
        ...CoreErrorFields
        ... on OpportunityConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Opportunity {
                id
                name
                accountId
              }
            }
          }
        }
      }
    }
  }
  ${CoreErrorFieldsFragmentDoc}
`;

/**
 * __useSearchOpportunityByExtIdQuery__
 *
 * To run a query within a React component, call `useSearchOpportunityByExtIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOpportunityByExtIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOpportunityByExtIdQuery({
 *   variables: {
 *      opportunityOperation: // value for 'opportunityOperation'
 *   },
 * });
 */
export function useSearchOpportunityByExtIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchOpportunityByExtIdQuery,
    SearchOpportunityByExtIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchOpportunityByExtIdQuery,
    SearchOpportunityByExtIdQueryVariables
  >(SearchOpportunityByExtIdDocument, options);
}
export function useSearchOpportunityByExtIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchOpportunityByExtIdQuery,
    SearchOpportunityByExtIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchOpportunityByExtIdQuery,
    SearchOpportunityByExtIdQueryVariables
  >(SearchOpportunityByExtIdDocument, options);
}
export type SearchOpportunityByExtIdQueryHookResult = ReturnType<
  typeof useSearchOpportunityByExtIdQuery
>;
export type SearchOpportunityByExtIdLazyQueryHookResult = ReturnType<
  typeof useSearchOpportunityByExtIdLazyQuery
>;
export type SearchOpportunityByExtIdQueryResult = Apollo.QueryResult<
  SearchOpportunityByExtIdQuery,
  SearchOpportunityByExtIdQueryVariables
>;
export const GetMspActivityTimelineDocument = gql`
  query getMspActivityTimeline(
    $mspId: ID!
    $input: MspActivityTimeseriesQueryOptions
  ) {
    activityTimelineQuery {
      getMspActivityTimeseries(mspId: $mspId, requestOptions: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MspActivityTimeseries {
          mspId
          timeline {
            ... on MspActivityTimeseriesData {
              timestamp
              activityCount
              buyerActivityCount
              sellerActivityCount
              userCount
              buyerUserIds
              sellerUserIds
              timeParameterDisplayName
            }
          }
          timelineUsers {
            ... on UserPayload {
              items {
                ... on User {
                  firstName
                  lastName
                  id
                  email
                }
                ... on GraphqlError {
                  __typename
                  type
                  code
                  message
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspActivityTimelineQuery__
 *
 * To run a query within a React component, call `useGetMspActivityTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspActivityTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspActivityTimelineQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMspActivityTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspActivityTimelineQuery,
    GetMspActivityTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspActivityTimelineQuery,
    GetMspActivityTimelineQueryVariables
  >(GetMspActivityTimelineDocument, options);
}
export function useGetMspActivityTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspActivityTimelineQuery,
    GetMspActivityTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspActivityTimelineQuery,
    GetMspActivityTimelineQueryVariables
  >(GetMspActivityTimelineDocument, options);
}
export type GetMspActivityTimelineQueryHookResult = ReturnType<
  typeof useGetMspActivityTimelineQuery
>;
export type GetMspActivityTimelineLazyQueryHookResult = ReturnType<
  typeof useGetMspActivityTimelineLazyQuery
>;
export type GetMspActivityTimelineQueryResult = Apollo.QueryResult<
  GetMspActivityTimelineQuery,
  GetMspActivityTimelineQueryVariables
>;
export const GetMspEngagementDocument = gql`
  query getMspEngagement($mspId: ID!) {
    mspAnalyticsQuery {
      getMspEngagement(mspId: $mspId) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MspEngagementDetails {
          internalUserEngagement {
            lastActivity {
              ... on LatestActivity {
                activityAt
                activityById
                activityBy {
                  __typename
                  ... on User {
                    id
                    firstName
                    lastName
                    email
                    userType
                  }
                }
                role {
                  ... on RolePayload {
                    items {
                      ... on Role {
                        name
                      }
                      ... on GraphqlError {
                        __typename
                        type
                        code
                        message
                      }
                    }
                  }
                }
              }
            }
            user {
              ... on MspEngagementUserDetails {
                count
              }
            }
            msp {
              ... on EngagementCount {
                viewCount
              }
            }
            comment {
              ... on EngagementCount {
                commentCount
              }
            }
          }
          externalUserEngagement {
            lastActivity {
              ... on LatestActivity {
                activityAt
                activityById
                activityBy {
                  __typename
                  ... on User {
                    id
                    firstName
                    lastName
                    email
                    userType
                  }
                }
                role {
                  ... on RolePayload {
                    items {
                      ... on Role {
                        name
                      }
                      ... on GraphqlError {
                        __typename
                        type
                        code
                        message
                      }
                    }
                  }
                }
              }
            }
            user {
              ... on MspEngagementUserDetails {
                count
                engagementUsers {
                  ... on MspEngagmentUsersLatestActivity {
                    items {
                      ... on UserActivity {
                        activityAt
                        activityById
                        activityBy {
                          __typename
                          ... on User {
                            id
                            firstName
                            lastName
                            email
                            userType
                          }
                        }
                        role {
                          ... on RolePayload {
                            items {
                              ... on Role {
                                name
                              }
                              ... on GraphqlError {
                                __typename
                                type
                                code
                                message
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            msp {
              ... on EngagementCount {
                viewCount
              }
            }
            comment {
              ... on EngagementCount {
                commentCount
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspEngagementQuery__
 *
 * To run a query within a React component, call `useGetMspEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspEngagementQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMspEngagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspEngagementQuery,
    GetMspEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMspEngagementQuery, GetMspEngagementQueryVariables>(
    GetMspEngagementDocument,
    options,
  );
}
export function useGetMspEngagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspEngagementQuery,
    GetMspEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspEngagementQuery,
    GetMspEngagementQueryVariables
  >(GetMspEngagementDocument, options);
}
export type GetMspEngagementQueryHookResult = ReturnType<
  typeof useGetMspEngagementQuery
>;
export type GetMspEngagementLazyQueryHookResult = ReturnType<
  typeof useGetMspEngagementLazyQuery
>;
export type GetMspEngagementQueryResult = Apollo.QueryResult<
  GetMspEngagementQuery,
  GetMspEngagementQueryVariables
>;
export const GetMediaEngagementDocument = gql`
  query getMediaEngagement($mspId: ID!, $input: GetMediaEngagementRequest) {
    mspAnalyticsQuery {
      getMediaEngagement(mspId: $mspId, requestOptions: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaEngagementConnection {
          edges {
            node {
              __typename
              ... on MediaEngagement {
                id
                downloadCount
                viewCount
                media {
                  __typename
                  ... on MediaConnection {
                    edges {
                      node {
                        __typename
                        ... on MediaPayloadItem {
                          item {
                            ... on Media {
                              id
                              name
                              orgId
                              createdBy {
                                ... on GraphqlError {
                                  __typename
                                  type
                                  code
                                  helpLink
                                  message
                                }
                                ... on User {
                                  id
                                  firstName
                                  lastName
                                  email
                                  userType
                                  __typename
                                }
                                __typename
                              }
                              description
                              mediaType
                              mimeType
                              createdAt
                              ...AssetsFragment
                              ...ImageFragment
                              ...AudioFragment
                              ...DocumentFragment
                              ...VideoFragment
                              ...LinkFragment
                              __typename
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${AssetsFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${AudioFragmentFragmentDoc}
  ${DocumentFragmentFragmentDoc}
  ${VideoFragmentFragmentDoc}
  ${LinkFragmentFragmentDoc}
`;

/**
 * __useGetMediaEngagementQuery__
 *
 * To run a query within a React component, call `useGetMediaEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaEngagementQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMediaEngagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaEngagementQuery,
    GetMediaEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMediaEngagementQuery,
    GetMediaEngagementQueryVariables
  >(GetMediaEngagementDocument, options);
}
export function useGetMediaEngagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaEngagementQuery,
    GetMediaEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaEngagementQuery,
    GetMediaEngagementQueryVariables
  >(GetMediaEngagementDocument, options);
}
export type GetMediaEngagementQueryHookResult = ReturnType<
  typeof useGetMediaEngagementQuery
>;
export type GetMediaEngagementLazyQueryHookResult = ReturnType<
  typeof useGetMediaEngagementLazyQuery
>;
export type GetMediaEngagementQueryResult = Apollo.QueryResult<
  GetMediaEngagementQuery,
  GetMediaEngagementQueryVariables
>;
export const GetUserEngagementDocument = gql`
  query getUserEngagement($mspId: ID!, $input: GetUserEngagementRequest) {
    mspAnalyticsQuery {
      getUserEngagement(mspId: $mspId, requestOptions: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on UserEngagementConnection {
          edges {
            node {
              __typename
              ... on UserEngagement {
                id
                mspViewCount
                mediaViewCount
                mediaDownloadCount
                commentCount
                user {
                  __typename
                  ... on User {
                    firstName
                    lastName
                    email
                    userType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserEngagementQuery__
 *
 * To run a query within a React component, call `useGetUserEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEngagementQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserEngagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEngagementQuery,
    GetUserEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserEngagementQuery,
    GetUserEngagementQueryVariables
  >(GetUserEngagementDocument, options);
}
export function useGetUserEngagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEngagementQuery,
    GetUserEngagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserEngagementQuery,
    GetUserEngagementQueryVariables
  >(GetUserEngagementDocument, options);
}
export type GetUserEngagementQueryHookResult = ReturnType<
  typeof useGetUserEngagementQuery
>;
export type GetUserEngagementLazyQueryHookResult = ReturnType<
  typeof useGetUserEngagementLazyQuery
>;
export type GetUserEngagementQueryResult = Apollo.QueryResult<
  GetUserEngagementQuery,
  GetUserEngagementQueryVariables
>;
export const GetMspAnalyticsMetricsDocument = gql`
  query getMspAnalyticsMetrics($mspId: ID!) {
    mspAnalyticsQuery {
      getMspAnalyticsMetrics(mspId: $mspId) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MspMetrics {
          mspTrack {
            ... on MspTrack {
              mspTrackStatus
              mspSlip
              mspSlipDisplayTime
            }
          }
          lastActivity {
            ... on AnalyticsActivity {
              activityAt
              activityById
              message
              activityByUser {
                ... on User {
                  firstName
                  lastName
                }
              }
            }
          }
          lastExternalActivity {
            ... on AnalyticsActivity {
              activityAt
              activityById
              activityByUser {
                ... on User {
                  lastName
                  firstName
                }
              }
            }
          }
          externalStakeholders {
            ... on MspAnalyticsStakeholders {
              stakeholders {
                __typename
                ... on AnalyticsUser {
                  id
                  firstName
                  lastName
                  userType
                  email
                }
              }
              subscribers {
                id
                firstName
                lastName
                userType
                email
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspAnalyticsMetricsQuery__
 *
 * To run a query within a React component, call `useGetMspAnalyticsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspAnalyticsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspAnalyticsMetricsQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMspAnalyticsMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspAnalyticsMetricsQuery,
    GetMspAnalyticsMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspAnalyticsMetricsQuery,
    GetMspAnalyticsMetricsQueryVariables
  >(GetMspAnalyticsMetricsDocument, options);
}
export function useGetMspAnalyticsMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspAnalyticsMetricsQuery,
    GetMspAnalyticsMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspAnalyticsMetricsQuery,
    GetMspAnalyticsMetricsQueryVariables
  >(GetMspAnalyticsMetricsDocument, options);
}
export type GetMspAnalyticsMetricsQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsMetricsQuery
>;
export type GetMspAnalyticsMetricsLazyQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsMetricsLazyQuery
>;
export type GetMspAnalyticsMetricsQueryResult = Apollo.QueryResult<
  GetMspAnalyticsMetricsQuery,
  GetMspAnalyticsMetricsQueryVariables
>;
export const GetActivityTimelineDocument = gql`
  query getActivityTimeline(
    $input: GetActivityTimelineRequestBody!
    $pageConfiguration: PageConfiguration
  ) {
    activityTimelineQuery {
      getActivityTimeline(
        input: $input
        pageConfiguration: $pageConfiguration
      ) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on ActivityTimelineConnection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            afterCursor
            beforeCursor
            __typename
          }
          edges {
            node {
              __typename
              ... on ActivityTimeline {
                __typename
                message
                createdAt
                eventType
                authorizer {
                  ... on ActivityTimelineAuthorizerDetails {
                    name
                    type
                  }
                }
                task {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                milestone {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                comment {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                media {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                role {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                stakeholder {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                methodology {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                opportunity {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                user {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                msp {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
                mspTemplate {
                  ... on ActivityTimelineResourceDetails {
                    name
                    type
                    updateFields {
                      ... on ActivityTimelineResourceUpdateFields {
                        property
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          activityTimelineHighlights {
            ... on ActivityTimelineHighlights {
              eventType
              count
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetActivityTimelineQuery__
 *
 * To run a query within a React component, call `useGetActivityTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTimelineQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pageConfiguration: // value for 'pageConfiguration'
 *   },
 * });
 */
export function useGetActivityTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityTimelineQuery,
    GetActivityTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityTimelineQuery,
    GetActivityTimelineQueryVariables
  >(GetActivityTimelineDocument, options);
}
export function useGetActivityTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityTimelineQuery,
    GetActivityTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityTimelineQuery,
    GetActivityTimelineQueryVariables
  >(GetActivityTimelineDocument, options);
}
export type GetActivityTimelineQueryHookResult = ReturnType<
  typeof useGetActivityTimelineQuery
>;
export type GetActivityTimelineLazyQueryHookResult = ReturnType<
  typeof useGetActivityTimelineLazyQuery
>;
export type GetActivityTimelineQueryResult = Apollo.QueryResult<
  GetActivityTimelineQuery,
  GetActivityTimelineQueryVariables
>;
export const GetMspAnalyticsTimelineDocument = gql`
  query getMspAnalyticsTimeline($mspId: ID!) {
    mspAnalyticsQuery {
      getMspAnalyticsTimeline(mspId: $mspId) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on MspAnalyticsTimeline {
          mspId
          lastUpdatedAt
          plannedCompletionDate
          timeline {
            ... on MspAnalyticsTimelineData {
              timestamp
              mspProgress
              plannedMspProgress
              timeParameterDisplayName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspAnalyticsTimelineQuery__
 *
 * To run a query within a React component, call `useGetMspAnalyticsTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspAnalyticsTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspAnalyticsTimelineQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *   },
 * });
 */
export function useGetMspAnalyticsTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspAnalyticsTimelineQuery,
    GetMspAnalyticsTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspAnalyticsTimelineQuery,
    GetMspAnalyticsTimelineQueryVariables
  >(GetMspAnalyticsTimelineDocument, options);
}
export function useGetMspAnalyticsTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspAnalyticsTimelineQuery,
    GetMspAnalyticsTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspAnalyticsTimelineQuery,
    GetMspAnalyticsTimelineQueryVariables
  >(GetMspAnalyticsTimelineDocument, options);
}
export type GetMspAnalyticsTimelineQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsTimelineQuery
>;
export type GetMspAnalyticsTimelineLazyQueryHookResult = ReturnType<
  typeof useGetMspAnalyticsTimelineLazyQuery
>;
export type GetMspAnalyticsTimelineQueryResult = Apollo.QueryResult<
  GetMspAnalyticsTimelineQuery,
  GetMspAnalyticsTimelineQueryVariables
>;
export const GetMspEngagementTimelineDocument = gql`
  query getMspEngagementTimeline(
    $mspId: ID!
    $input: MspAnalyticsTimelineQueryOptions!
  ) {
    mspAnalyticsQuery {
      getMspEngagementTimeline(mspId: $mspId, requestOptions: $input) {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MspEngagementTimeline {
          mspId
          timeline {
            ... on MspEngagementTimelineData {
              timestamp
              engagementCount
              buyerEngagementCount
              sellerEngagementCount
              userCount
              buyerUserIds
              sellerUserIds
              timeParameterDisplayName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMspEngagementTimelineQuery__
 *
 * To run a query within a React component, call `useGetMspEngagementTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMspEngagementTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMspEngagementTimelineQuery({
 *   variables: {
 *      mspId: // value for 'mspId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMspEngagementTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMspEngagementTimelineQuery,
    GetMspEngagementTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMspEngagementTimelineQuery,
    GetMspEngagementTimelineQueryVariables
  >(GetMspEngagementTimelineDocument, options);
}
export function useGetMspEngagementTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMspEngagementTimelineQuery,
    GetMspEngagementTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMspEngagementTimelineQuery,
    GetMspEngagementTimelineQueryVariables
  >(GetMspEngagementTimelineDocument, options);
}
export type GetMspEngagementTimelineQueryHookResult = ReturnType<
  typeof useGetMspEngagementTimelineQuery
>;
export type GetMspEngagementTimelineLazyQueryHookResult = ReturnType<
  typeof useGetMspEngagementTimelineLazyQuery
>;
export type GetMspEngagementTimelineQueryResult = Apollo.QueryResult<
  GetMspEngagementTimelineQuery,
  GetMspEngagementTimelineQueryVariables
>;
