import Button from '@mui/material/Button';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../../common/redux-store';
import {
  BaDialog,
  InfiniteLoader,
  SearchAndSelect,
  Typography,
} from '../../../../components';
import {
  AccountPlan,
  AccountPlanPayload,
  Company,
  GraphqlError,
  Operation,
  Operator,
  Organization,
} from '../../../../generated-types';
import { ITabDetail } from '../../../msp-view/model';
import { TOption } from '../../../../components/ba-select/common';
import { useHistory } from 'react-router-dom';
import { setAccountDetails } from '../../../influencer-map-new/influencer-redux-store';
import {
  CreateAccountPlanMutation,
  useCreateAccountPlanMutation,
} from '../../../influencer-map-new/account/graphql/createAccountPlan.generated';
import { showToast } from '../../../../components/hooks/use-toastify';
import { useLazySearchCompaniesQuery } from './account-api';

const mapState = (state: RootState) => {
  const tabList = (state?.organization as Organization)?.configs?.others
    ?.dashboardTabs as ITabDetail[];
  const rmapTab = tabList?.find((tab) => tab.name === 'rMap');
  const title = rmapTab?.title || 'Relationship Map';

  return { rmapTitle: title };
};

const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ setAccountDetails }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function CreateNewRmapAccount(props: PropsFromRedux) {
  const { rmapTitle, setAccountDetails } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<TOption | null>(null);
  const history = useHistory();
  const [
    searchCompany,
    {
      data: companyList = [] as TOption[],
      isLoading: isSearchLoading,
      isFetching: isSearchFetching,
      isError: isSearchCompanyError,
      //   error: searchCompanyError,
    },
  ] = useLazySearchCompaniesQuery();

  const isSearchCompanyLoading = isSearchLoading || isSearchFetching;

  const onNameChange = useCallback(
    async (name: string | undefined) => {
      if (name) {
        await searchCompany({
          operations: {
            filter: {
              operator: Operator.AND,
              filters: [
                {
                  op: Operation.LIKE,
                  values: [name ?? ''],
                  field: 'company.searchField',
                },
                {
                  op: Operation.EXISTS,
                  values: ['true'],
                  field: 'company.extId',
                },
              ],
            },
          },
        });
      } else {
        await searchCompany({
          operations: {
            filter: {
              operator: Operator.AND,
              filters: [
                {
                  op: Operation.EXISTS,
                  values: ['true'],
                  field: 'company.extId',
                },
              ],
            },
          },
        });
      }
    },
    [searchCompany],
  );

  const setCompany = useCallback((company: TOption | null) => {
    setSelectedCompany(company);
  }, []);

  const rerouteFn = (accountId?: string) => {
    if (accountId) {
      const queryParams = new URLSearchParams(window.location.search);
      history.push({
        pathname: `/ui/alignx/ap/${accountId}/rmap`,
        search: queryParams.toString(),
      });
    }
  };

  const [createAccountPlan, { isLoading: createAccountLoading }] =
    useCreateAccountPlanMutation();

  const handleCreateAccount = async () => {
    try {
      const company =
        (companyList as Company[])?.find(
          ({ id }) => id === selectedCompany?.id,
        ) || ({} as Company);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const extId = company?.extId ?? '';
      const res = (await createAccountPlan({
        input: { extCompanyId: extId as string },
      })) as { data: CreateAccountPlanMutation };

      if (
        res?.data?.accountPlanMutation?.createAccountPlan?.__typename ===
        'GraphqlError'
      ) {
        const errorMsg = (
          res?.data?.accountPlanMutation?.createAccountPlan as GraphqlError
        )?.message;
        showToast(errorMsg, {
          variant: 'error',
        });
        return;
      } else {
        const account = (
          res?.data?.accountPlanMutation
            ?.createAccountPlan as AccountPlanPayload
        )?.items?.[0] as AccountPlan;

        setAccountDetails({ account });

        rerouteFn(account?.id);
      }
    } catch (err) {
      console.log(err);
      showToast('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
  };

  let content;

  if (createAccountLoading) {
    content = (
      <div className="grid h-[80vh] w-full place-items-center">
        <div className="flex flex-col items-center justify-center gap-2">
          <Typography variant="heading2" className="text-neutral">
            {`Creating new ${rmapTitle}`}
          </Typography>
          <InfiniteLoader width={150}></InfiniteLoader>
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className="mb-5 flex items-center justify-between">
          <Typography variant="heading2" className="text-neutral">
            {`Create new ${rmapTitle}`}
          </Typography>
          <BaDialog.Close asChild>
            <div>
              <FiXCircle className="h-4 w-4 cursor-pointer rounded-full p-1 text-mid-600 hover:bg-gray-100" />
            </div>
          </BaDialog.Close>
        </div>
        <Typography
          element="div"
          variant="body-13-regular"
          className="py-2 px-1 text-neutral-400"
        >
          Select Account
        </Typography>
        <SearchAndSelect
          placeholder="Select Account"
          disabled={isSearchCompanyLoading}
          isLoading={isSearchCompanyLoading}
          selectedItem={selectedCompany}
          optionList={companyList as TOption[]}
          setSelected={setCompany}
          noOptionsMessage="No accounts found"
          error={isSearchCompanyError ? 'Something went wrong.' : ''}
          onSearchChange={onNameChange}
        />
        <div className="ml-1 mt-10 flex space-x-3">
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedCompany?.id}
            onClick={handleCreateAccount}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            className="text-mid"
            color="inherit"
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  return (
    <BaDialog.Root
      open={isDrawerOpen}
      //   open
      onOpenChange={setIsDrawerOpen}
    >
      <BaDialog.Trigger asChild>
        <div>
          <Button variant="contained" color="primary">
            Create New
          </Button>
        </div>
      </BaDialog.Trigger>
      <BaDialog.Portal>
        <BaDialog.Overlay />
        <BaDialog.RightDrawer className="z-20 h-screen w-[560px] p-11">
          {content}
        </BaDialog.RightDrawer>
      </BaDialog.Portal>
    </BaDialog.Root>
  );
}

export default connector(CreateNewRmapAccount);
