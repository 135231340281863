import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useTopOffset = () => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [topOffset, setTopOffset] = useState(0);
  useLayoutEffect(() => {
    const rect = elementRef.current?.getBoundingClientRect();
    setTopOffset(rect?.top || 0);
  }, []);

  return { elementRef, topOffset };
};

export const useGetTopOffsetWithClassName = (
  className: string,
  isLoading: boolean,
) => {
  const [tableTopPosition, setTableTopPosition] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const table = document.getElementsByClassName(
        className,
      )[0] as HTMLElement;
      if (table) {
        const dimensions = table?.getBoundingClientRect();
        setTableTopPosition(dimensions?.top + 8);
      }
    }, 200);
  }, [className, isLoading]);

  return tableTopPosition;
};
