/* eslint-disable no-use-before-define */
import { api } from './base-api';

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: string;
  JSON: { [key: string]: any };
  Timestamp: any;
  URL: string;
};

export enum ActionType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export type ActivityTimeline = {
  __typename?: 'ActivityTimeline';
  authorizer: ActivityTimelineAuthorizerDetails;
  comment?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  createdAt: Scalars['Timestamp'];
  eventType: Scalars['String'];
  media?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  message: Scalars['String'];
  methodology?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  milestone?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  msp?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  mspTemplate?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  opportunity?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  role?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  stakeholder?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  task?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
  user?: Maybe<Array<Maybe<ActivityTimelineResourceDetails>>>;
};

export type ActivityTimelineAuthorizerDetails = {
  __typename?: 'ActivityTimelineAuthorizerDetails';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ActivityTimelineAuthorizerInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ActivityTimelineConnection = {
  __typename?: 'ActivityTimelineConnection';
  activityTimelineHighlights?: Maybe<Array<ActivityTimelineHighlights>>;
  edges?: Maybe<Array<Maybe<ActivityTimelineEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ActivityTimelineConnectionResponse =
  | ActivityTimelineConnection
  | GraphqlError;

export type ActivityTimelineEdge = {
  __typename?: 'ActivityTimelineEdge';
  node?: Maybe<ActivityTimelineResponse>;
};

export type ActivityTimelineHighlights = {
  __typename?: 'ActivityTimelineHighlights';
  count: Scalars['Int'];
  eventType: Scalars['String'];
};

export type ActivityTimelineQuery = {
  __typename?: 'ActivityTimelineQuery';
  getActivityTimeline: ActivityTimelineConnectionResponse;
};

export type ActivityTimelineQueryGetActivityTimelineArgs = {
  input: GetActivityTimelineRequestBody;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type ActivityTimelineReferenceInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BaResource>;
};

export type ActivityTimelineResourceDetails = {
  __typename?: 'ActivityTimelineResourceDetails';
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateFields?: Maybe<Array<Maybe<ActivityTimelineResourceUpdateFields>>>;
};

export type ActivityTimelineResourceUpdateFields = {
  __typename?: 'ActivityTimelineResourceUpdateFields';
  property?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ActivityTimelineResponse = ActivityTimeline | GraphqlError;

export type ActivityTimelineScopeInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AddCommentInput = {
  annotationType: AnnotationType;
  media?: InputMaybe<Array<CreateMediaInput>>;
  message: Scalars['String'];
  reference: ReferenceInput;
};

export type AddNotesInput = {
  annotationType: AnnotationType;
  message: Scalars['String'];
  reference: ReferenceInput;
  scope: AnnotationScopeInput;
};

export type AddOrRemovePlaceholderPayload = {
  __typename?: 'AddOrRemovePlaceholderPayload';
  items?: Maybe<Array<AddOrRemovePlaceholderResponse>>;
};

export type AddOrRemovePlaceholderResponse = {
  __typename?: 'AddOrRemovePlaceholderResponse';
  placeholderId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type AddOrRemovePlaceholderRoleResponse =
  | AddOrRemovePlaceholderPayload
  | GraphqlError;

export type AddOrRemoveStakeholderPayload = {
  __typename?: 'AddOrRemoveStakeholderPayload';
  items?: Maybe<Array<AddOrRemoveStakeholderResponse>>;
};

export type AddOrRemoveStakeholderResponse = {
  __typename?: 'AddOrRemoveStakeholderResponse';
  roleId: Scalars['ID'];
  stakeholderId: Scalars['ID'];
};

export type AddOrRemoveStakeholderRoleResponse =
  | AddOrRemoveStakeholderPayload
  | GraphqlError;

export type AddStakeHolderToRoleInput = {
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
  stakeholderIdMap: UserIdMapInput;
};

export type Alignx = {
  __typename?: 'Alignx';
  analytics: AlignxAnalyticsResponse;
  dashboardColumns?: Maybe<DashboardColumnPayloadResponse>;
  dashboardFilters?: Maybe<DashboardFilterPayloadResponse>;
  dashboardFiltersV1?: Maybe<FilterItemsPayloadResponse>;
  msps: MspConnectionResponse;
};

export type AlignxMspsArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type AlignxAnalytics = {
  __typename?: 'AlignxAnalytics';
  mspPublishStatus: MspPublishStatusResponse;
  mspTrackCounts: MspTrackCountsResponse;
  opportunityCountsAtStage: OpportunityCountsAtStagePayloadResponse;
};

export type AlignxAnalyticsOpportunityCountsAtStageArgs = {
  stageNames: Array<Scalars['String']>;
};

export type AlignxAnalyticsResponse = AlignxAnalytics | GraphqlError;

export type AlignxQuery = {
  __typename?: 'AlignxQuery';
  dashboard: AlignxResponse;
};

export type AlignxResponse = Alignx | GraphqlError;

export type AnalyticsActivity = {
  __typename?: 'AnalyticsActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityById?: Maybe<Scalars['String']>;
  activityByUser?: Maybe<UserResponse>;
  message?: Maybe<Scalars['String']>;
};

export type AnalyticsBaseTask = {
  __typename?: 'AnalyticsBaseTask';
  assigneeType?: Maybe<AssigneeType>;
  createdAt: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['Int'];
  id: Scalars['ID'];
  isInternal: Scalars['Boolean'];
  milestoneId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  status: TaskStatus;
};

export type AnalyticsMilestone = {
  __typename?: 'AnalyticsMilestone';
  milestone?: Maybe<BaseMilestone>;
  progress?: Maybe<AnalyticsMilestoneProgress>;
};

export type AnalyticsMilestoneProgress = {
  __typename?: 'AnalyticsMilestoneProgress';
  tasksCompleted?: Maybe<Scalars['Int']>;
  tasksCount?: Maybe<Scalars['Int']>;
  tasksOverdue?: Maybe<Scalars['Int']>;
};

export type AnalyticsOpportunity = {
  __typename?: 'AnalyticsOpportunity';
  opportunityCloseDate?: Maybe<Scalars['Timestamp']>;
  opportunityStage?: Maybe<Scalars['String']>;
};

export type AnalyticsTask = {
  __typename?: 'AnalyticsTask';
  assignee?: Maybe<AnalyticsTaskAssignee>;
  task?: Maybe<AnalyticsBaseTask>;
};

export type AnalyticsTaskAssignee = AnalyticsUser | Placeholder;

export type AnalyticsUser = {
  __typename?: 'AnalyticsUser';
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  primaryId?: Maybe<Scalars['ID']>;
  userType: UserType;
};

export type Annotation = {
  annotationType: AnnotationType;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  id: Scalars['ID'];
  reference: Reference;
  updatedAt: Scalars['Timestamp'];
};

export type AnnotationMutation = {
  __typename?: 'AnnotationMutation';
  addComment: CommentPayloadResponse;
  addNotes: NotesPayloadResponse;
  removeComment: BooleanResponse;
  removeNotes: BooleanResponse;
  updateComment: CommentPayloadResponse;
  updateNotes: NotesPayloadResponse;
};

export type AnnotationMutationAddCommentArgs = {
  input: AddCommentInput;
};

export type AnnotationMutationAddNotesArgs = {
  input: AddNotesInput;
};

export type AnnotationMutationRemoveCommentArgs = {
  id: Scalars['ID'];
};

export type AnnotationMutationRemoveNotesArgs = {
  id: Scalars['ID'];
};

export type AnnotationMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type AnnotationMutationUpdateNotesArgs = {
  input: UpdateNotesInput;
};

export type AnnotationQuery = {
  __typename?: 'AnnotationQuery';
  getNotes: NotesPayloadResponse;
};

export type AnnotationQueryGetNotesArgs = {
  input: GetNotesInput;
};

export type AnnotationScope = {
  __typename?: 'AnnotationScope';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type AnnotationScopeInput = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export enum AnnotationType {
  COMMENTS = 'COMMENTS',
  NOTES = 'NOTES',
}

export type Assets = {
  analytics?: Maybe<MediaAnalyticsResponse>;
  context?: Maybe<MediaContextResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileName?: Maybe<Scalars['String']>;
  fileShareUrl?: Maybe<Scalars['URL']>;
  fileSize?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isInternal?: Maybe<Scalars['Boolean']>;
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
  url: Scalars['URL'];
};

export type Assignee = GraphqlError | Placeholder | User;

export enum AssigneeType {
  PLACEHOLDER = 'PLACEHOLDER',
  USER = 'USER',
}

export type AttachMedia = {
  __typename?: 'AttachMedia';
  mediaId: Scalars['ID'];
  referenceId: Scalars['ID'];
  referenceType: ObjectType;
  scopeId: Scalars['ID'];
};

export type AttachMediaInput = {
  isInternal?: InputMaybe<Scalars['Boolean']>;
  referenceId: Scalars['ID'];
  referenceType: ObjectType;
  scopeId: Scalars['ID'];
};

export type AttachMediaPayload = {
  __typename?: 'AttachMediaPayload';
  items?: Maybe<Array<AttachMediaResponse>>;
};

export type AttachMediaPayloadResponse = AttachMediaPayload | GraphqlError;

export type AttachMediaResponse = AttachMedia | GraphqlError;

export type Audio = Assets &
  Media & {
    __typename?: 'Audio';
    analytics?: Maybe<MediaAnalyticsResponse>;
    context?: Maybe<MediaContextResponse>;
    createdAt: Scalars['Timestamp'];
    createdBy: UserResponse;
    description?: Maybe<Scalars['String']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileName?: Maybe<Scalars['String']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    fileSize?: Maybe<Scalars['Int']>;
    formats?: Maybe<Scalars['JSON']>;
    id: Scalars['ID'];
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaType: MediaType;
    mimeType?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    orgId: Scalars['ID'];
    source?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Thumbnail>;
    url: Scalars['URL'];
  };

export enum BaResource {
  ACCOUNT = 'ACCOUNT',
  COMMENTS = 'COMMENTS',
  COMPANY = 'COMPANY',
  GROUP = 'GROUP',
  MANAGER = 'MANAGER',
  MEDIA = 'MEDIA',
  METHODOLOGY = 'METHODOLOGY',
  MILESTONE = 'MILESTONE',
  MSP = 'MSP',
  NOTES = 'NOTES',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_PREFERENCE = 'NOTIFICATION_PREFERENCE',
  ORGANIZATION = 'ORGANIZATION',
  PLACEHOLDER = 'PLACEHOLDER',
  ROLE = 'ROLE',
  STAKEHOLDER = 'STAKEHOLDER',
  TASK = 'TASK',
  TEMPLATE = 'TEMPLATE',
  TIMELINE = 'TIMELINE',
  USER = 'USER',
  USER_NOTIFICATION_STATUS = 'USER_NOTIFICATION_STATUS',
}

export type BaResourceDetails = {
  type: BaResource;
};

export type BaseMilestone = IMilestone & {
  __typename?: 'BaseMilestone';
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
};

export type BaseMsp = IMsp & {
  __typename?: 'BaseMsp';
  buyerCompany: CompanyResponse;
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  opportunityId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Int'];
};

export type BelongsToResponse = GraphqlError | MediaBelongsTo;

export type BooleanObject = {
  __typename?: 'BooleanObject';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
};
export type BrandingConfig = {
  __typename?: 'BrandingConfig';
  header?: Maybe<BrandingHeader>;
};

export type BrandingConfigInput = {
  header?: InputMaybe<BrandingHeaderInput>;
};

export type BrandingHeader = {
  __typename?: 'BrandingHeader';
  color?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type BrandingHeaderInput = {
  color?: InputMaybe<Scalars['String']>;
  textColor?: InputMaybe<Scalars['String']>;
};

export type BulkUpdateNotificationStatusRequestBody = {
  startTimestamp: Scalars['Timestamp'];
  status?: InputMaybe<NotificationStatus>;
  userId: Scalars['String'];
};

export type ChangeTaskStatusInput = {
  taskIds: Array<Scalars['ID']>;
  taskStatus: TaskStatus;
};

export type Comment = Annotation & {
  __typename?: 'Comment';
  annotationType: AnnotationType;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  id: Scalars['ID'];
  isInternal?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaConnectionResponse>;
  messageData: MessageData;
  reference: Reference;
  updatedAt: Scalars['Timestamp'];
};

export type CommentMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CommentConnectionResponse = CommentConnection | GraphqlError;

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node?: Maybe<CommentResponse>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  items?: Maybe<Array<CommentResponse>>;
};

export type CommentPayloadResponse = CommentPayload | GraphqlError;

export type CommentResponse = Comment | GraphqlError;

export type Company = {
  __typename?: 'Company';
  companyName: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  logo?: Maybe<LogoResponse>;
  orgId: Scalars['ID'];
  originalSource: OriginalSourceForCompanyType;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CompanyConnectionResponse = CompanyConnection | GraphqlError;

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<CompanyResponse>;
};

export type CompanyMutation = {
  __typename?: 'CompanyMutation';
  createCompany: CompanyPayloadResponse;
  updateCompany: CompanyPayloadResponse;
};

export type CompanyMutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type CompanyMutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: UpdateCompanyInput;
};

export type CompanyPayload = {
  __typename?: 'CompanyPayload';
  items?: Maybe<Array<CompanyResponse>>;
};

export type CompanyPayloadResponse = CompanyPayload | GraphqlError;

export type CompanyQuery = {
  __typename?: 'CompanyQuery';
  getCompany: CompanyPayloadResponse;
  getCompanyByName: CompanyPayloadResponse;
  searchCompany: CompanyConnectionResponse;
};

export type CompanyQueryGetCompanyArgs = {
  id: Scalars['ID'];
};

export type CompanyQueryGetCompanyByNameArgs = {
  companyName: Scalars['String'];
};

export type CompanyQuerySearchCompanyArgs = {
  operations: Operations;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type CompanyResponse = Company | GraphqlError;

export enum CompanyState {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  MAINTENANCE = 'MAINTENANCE',
}

export enum CompanyType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type ContactPayload = {
  __typename?: 'ContactPayload';
  items?: Maybe<Array<ContactRoleResponse>>;
};

export type ContactPayloadResponse = ContactPayload | GraphqlError;

export type ContactRole = {
  __typename?: 'ContactRole';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ContactRoleMutation = {
  __typename?: 'ContactRoleMutation';
  createContactRole: ContactPayloadResponse;
  removeContactRole: BooleanResponse;
  updateContactRole: ContactPayloadResponse;
};

export type ContactRoleMutationCreateContactRoleArgs = {
  input: CreateContactRoleInput;
};

export type ContactRoleMutationRemoveContactRoleArgs = {
  id: Scalars['ID'];
};

export type ContactRoleMutationUpdateContactRoleArgs = {
  id: Scalars['ID'];
  input: UpdateContactRoleInput;
};

export type ContactRoleResponse = ContactRole | GraphqlError;

export type CreateAndAssignPlaceholderToRoleInput = {
  companyType: CompanyType;
  placeholderInput: PlaceholderInput;
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
};

export type CreateAndAssignStakeholderToRoleInput = {
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
  userInput: CreateUserInput;
};

export type CreateAndAttachMediaInput = {
  attachInput: AttachMediaInput;
  mediaInput: Array<CreateMediaInput>;
};

export type CreateAssetsInput = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  mimeType: Scalars['String'];
  path: Scalars['String'];
};

export type CreateCompanyInput = {
  companyName: Scalars['String'];
  extId?: InputMaybe<Scalars['String']>;
  originalSource: OriginalSourceForCompanyInput;
};

export type CreateContactRoleInput = {
  name: Scalars['String'];
};

export type CreateExecutiveSummaryInput = {
  description?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<CreateMediaInput>>;
};

export type CreateLinkInput = {
  link: Scalars['URL'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreateLovItemInput = {
  active: Scalars['Boolean'];
  displayName: Scalars['String'];
  displayValue: Scalars['String'];
  isDefaultValue: Scalars['Boolean'];
  name: Scalars['String'];
  originalSource: OriginalSourceJsonInput;
  scope?: InputMaybe<ScopeJsonInput>;
  type: ScopedUserInfoType;
  value: Scalars['String'];
};

export type CreateMediaInput = {
  assetsInput?: InputMaybe<CreateAssetsInput>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Int']>;
  linkInput?: InputMaybe<CreateLinkInput>;
  mediaType: MediaType;
  mimeType?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  uploadId?: InputMaybe<Scalars['ID']>;
};

export type CreateMilestoneInput = {
  endDate: Scalars['Int'];
  isInternal?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Array<CreateMediaInput>>;
  mspId: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
};

export type CreateMilestoneTemplateInput = {
  endDate?: InputMaybe<Scalars['Int']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  mspId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Int'];
};

export type CreateMspInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  buyerCompanyDisplayName?: InputMaybe<Scalars['String']>;
  buyerCompanyId?: InputMaybe<Scalars['ID']>;
  buyerCompanyLogo?: InputMaybe<CreateMediaInput>;
  buyerCompanyName?: InputMaybe<Scalars['String']>;
  closeDate: Scalars['Int'];
  decisionCriteria?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  economicImpact?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<CreateMediaInput>>;
  name: Scalars['String'];
  opportunityId?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  sellerCompanyId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Int'];
  summary?: InputMaybe<CreateExecutiveSummaryInput>;
  uploadBuyerCompanyLogo?: InputMaybe<UploadMediaInput>;
};

export type CreateMspTemplateInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  buyerCompanyDisplayName?: InputMaybe<Scalars['String']>;
  buyerCompanyId?: InputMaybe<Scalars['ID']>;
  buyerCompanyLogo?: InputMaybe<CreateMediaInput>;
  buyerCompanyName?: InputMaybe<Scalars['String']>;
  decisionCriteria?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  economicImpact?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<InputMaybe<CreateMediaInput>>>;
  name: Scalars['String'];
  opportunityId?: InputMaybe<Scalars['ID']>;
  sellerCompanyId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Int'];
  summary?: InputMaybe<CreateExecutiveSummaryInput>;
  uploadBuyerCompanyLogo?: InputMaybe<UploadMediaInput>;
};

export type CreateNotificationPreferenceInput = {
  event: Scalars['String'];
  preferences: NotificationScopePreferenceInput;
  scopeId: Scalars['String'];
  scopeType: NotificationScopeType;
};

export type CreateNotificationPreferenceRequestBody = {
  notificationPreferences: Array<CreateNotificationPreferenceInput>;
};

export type CreateOpportunityInput = {
  accountId: Scalars['String'];
  amount?: InputMaybe<Scalars['Int']>;
  closeDate?: InputMaybe<Scalars['Timestamp']>;
  contactId?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  description?: InputMaybe<Scalars['String']>;
  extCompanyId?: InputMaybe<Scalars['String']>;
  extId?: InputMaybe<Scalars['String']>;
  extOwnerId?: InputMaybe<Scalars['String']>;
  fiscal?: InputMaybe<Scalars['Timestamp']>;
  forecastCategory?: InputMaybe<Scalars['String']>;
  forecastCategoryName?: InputMaybe<Scalars['String']>;
  intCompanyId?: InputMaybe<Scalars['String']>;
  intOwnerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isWon?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orgId: Scalars['String'];
  originalSource?: InputMaybe<Scalars['JSON']>;
  ownerId?: InputMaybe<Scalars['String']>;
  sourceSystem?: InputMaybe<Scalars['String']>;
  stageName?: InputMaybe<Scalars['String']>;
  systemModStamp?: InputMaybe<Scalars['Timestamp']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  configs: OrgConfigInput;
  orgId: Scalars['ID'];
  orgLogo?: InputMaybe<OrgLogoInput>;
  orgName: Scalars['String'];
  orgType: OrgType;
  state: CompanyState;
};

export type CreateRelationshipGraphInput = {
  edges?: InputMaybe<Array<InputMaybe<GraphEdgeInput>>>;
  nodes?: InputMaybe<Array<InputMaybe<GraphNodeInput>>>;
  viewport: Scalars['JSON'];
};

export type CreateRoleInput = {
  companyType: CompanyType;
  description?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  scopeId: Scalars['ID'];
  scopeType: RoleScope;
};

export type CreateTaskInput = {
  description?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['Int'];
  isInternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreateTimelineStepsInput = {
  date: Scalars['Int'];
  label: Scalars['String'];
};

export type CreateUserInput = {
  companyId: Scalars['ID'];
  email: Scalars['EmailAddress'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type CreateUspInput = {
  mspId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  public: Scalars['Boolean'];
};

export type DashboardColumn = {
  __typename?: 'DashboardColumn';
  displayName: Scalars['String'];
  isSortable: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type DashboardColumnPayload = {
  __typename?: 'DashboardColumnPayload';
  items?: Maybe<Array<DashboardColumnResponse>>;
};

export type DashboardColumnPayloadResponse =
  | DashboardColumnPayload
  | GraphqlError;

export type DashboardColumnResponse = DashboardColumn | GraphqlError;

export type DashboardFilter = {
  __typename?: 'DashboardFilter';
  displayName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DashboardFilterPayload = {
  __typename?: 'DashboardFilterPayload';
  items?: Maybe<Array<DashboardFilterResponse>>;
};

export type DashboardFilterPayloadResponse =
  | DashboardFilterPayload
  | GraphqlError;

export type DashboardFilterResponse = DashboardFilter | GraphqlError;

export type DataPayload = {
  __typename?: 'DataPayload';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['ID']>;
  values?: Maybe<Array<Maybe<DataPayloadValue>>>;
};

export type DataPayloadValue = {
  __typename?: 'DataPayloadValue';
  value?: Maybe<Scalars['String']>;
};

export type DataSourceClient = {
  __typename?: 'DataSourceClient';
  config?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

export type DataSourceConfig = {
  __typename?: 'DataSourceConfig';
  client?: Maybe<Array<Maybe<DataSourceClient>>>;
  path?: Maybe<DataSourcePath>;
};

export type DataSourcePath = {
  __typename?: 'DataSourcePath';
  defaultValuePath?: Maybe<Array<Scalars['JSON']>>;
  valueSuggestionsPath?: Maybe<Array<Scalars['JSON']>>;
};

export type DeleteTimelineStepsInput = {
  timelineIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type DetachMedia = {
  __typename?: 'DetachMedia';
  mediaId: Scalars['ID'];
  /** @deprecated Pass mediaId only */
  referenceId?: Maybe<Scalars['ID']>;
};

export type DetachMediaInput = {
  mediaId: Scalars['ID'];
  referenceId?: InputMaybe<Scalars['ID']>;
};

export type DetachMediaResponse = DetachMedia | GraphqlError;

export type Document = Assets &
  Media & {
    __typename?: 'Document';
    analytics?: Maybe<MediaAnalyticsResponse>;
    context?: Maybe<MediaContextResponse>;
    createdAt: Scalars['Timestamp'];
    createdBy: UserResponse;
    description?: Maybe<Scalars['String']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileName?: Maybe<Scalars['String']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    fileSize?: Maybe<Scalars['Int']>;
    formats?: Maybe<Scalars['JSON']>;
    id: Scalars['ID'];
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaType: MediaType;
    mimeType?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    orgId: Scalars['ID'];
    source?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Thumbnail>;
    url: Scalars['URL'];
  };

export type EngagementCount = {
  __typename?: 'EngagementCount';
  commentCount?: Maybe<Scalars['Int']>;
  downloadCount?: Maybe<Scalars['Int']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type EngagementCountResponse = EngagementCount | GraphqlError;

export enum ErrorType {
  BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR',
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
}

export type ExecutiveSummary = {
  __typename?: 'ExecutiveSummary';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media?: Maybe<MediaConnectionResponse>;
};

export type ExecutiveSummaryMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type ExternalLinkInput = {
  mediaId: Scalars['ID'];
};

export type FavoriteMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type Filter = {
  field: Scalars['String'];
  op: Operation;
  values: Array<Scalars['String']>;
};

export type FilterItems = {
  __typename?: 'FilterItems';
  displayName: Scalars['String'];
  filterType: FilterType;
  id?: Maybe<Scalars['ID']>;
  isVisible: Scalars['Boolean'];
  key: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  values?: Maybe<Array<Scalars['JSON']>>;
};

export type FilterItemsCount = {
  __typename?: 'FilterItemsCount';
  count: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  values?: Maybe<Array<Scalars['JSON']>>;
};

export type FilterItemsCountPayload = {
  __typename?: 'FilterItemsCountPayload';
  items?: Maybe<Array<FilterItemsCount>>;
};

export type FilterItemsCountPayloadResponse =
  | FilterItemsCountPayload
  | GraphqlError;

export type FilterItemsPayload = {
  __typename?: 'FilterItemsPayload';
  items?: Maybe<Array<FilterItems>>;
};

export type FilterItemsPayloadResponse = FilterItemsPayload | GraphqlError;

export enum FilterType {
  DROP_DOWN_FILTER = 'DROP_DOWN_FILTER',
  QUICK_FILTER = 'QUICK_FILTER',
}

export type FiltersExpression = {
  childExpressions?: InputMaybe<Array<FiltersExpression>>;
  filters?: InputMaybe<Array<Filter>>;
  operator: Operator;
};

export type GetActivityTimelineRequestBody = {
  authorizers?: InputMaybe<Array<ActivityTimelineAuthorizerInput>>;
  createdAfterTimestamp?: InputMaybe<Scalars['Int']>;
  filterEventTypes?: InputMaybe<Array<Scalars['String']>>;
  getCountEventTypes?: InputMaybe<Array<Scalars['String']>>;
  orgId?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<ActivityTimelineReferenceInput>>;
  scopes?: InputMaybe<Array<ActivityTimelineScopeInput>>;
  userId: Scalars['String'];
};

export type GetMediaEngagementRequest = {
  mediaIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetMspAnalyticsOptions = {
  recalculateAnalytics?: InputMaybe<Scalars['Boolean']>;
};

export type GetMspEngagementUsersRequest = {
  endTimestamp: Scalars['Timestamp'];
  startTimestamp?: InputMaybe<Scalars['Timestamp']>;
  userType?: InputMaybe<UserType>;
};

export type GetNotesInput = {
  referenceIds: Array<Scalars['ID']>;
  scopeId: Scalars['ID'];
};

export type GetPushNotificationRequestBody = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  baResourceList?: InputMaybe<Array<BaResourceDetails>>;
  userId: Scalars['String'];
};

export type GetUserEngagementRequest = {
  userIds?: InputMaybe<Array<Scalars['String']>>;
  userType?: InputMaybe<UserType>;
};

export type GraphEdge = {
  __typename?: 'GraphEdge';
  clientId?: Maybe<Scalars['ID']>;
  data?: Maybe<GraphItemDataResponse>;
  id: Scalars['ID'];
  markerEnd?: Maybe<Scalars['JSON']>;
  selected?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['ID']>;
  sourceHandle?: Maybe<Scalars['ID']>;
  style?: Maybe<Scalars['JSON']>;
  target?: Maybe<Scalars['ID']>;
  targetHandle?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

export type GraphEdgeInput = {
  actionType?: InputMaybe<ActionType>;
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  markerEnd?: InputMaybe<Scalars['JSON']>;
  selected?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['ID']>;
  sourceHandle?: InputMaybe<Scalars['ID']>;
  style?: InputMaybe<Scalars['JSON']>;
  target?: InputMaybe<Scalars['ID']>;
  targetHandle?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type GraphItemData = {
  __typename?: 'GraphItemData';
  dataPayload?: Maybe<Array<Maybe<DataPayload>>>;
  id?: Maybe<Scalars['ID']>;
  metaData?: Maybe<GraphItemDataMetaData>;
};

export type GraphItemDataMetaData = {
  __typename?: 'GraphItemDataMetaData';
  structureId?: Maybe<Scalars['ID']>;
  structureType?: Maybe<StructureType>;
};

export type GraphItemDataResponse = GraphItemData | GraphqlError;

export type GraphNode = {
  __typename?: 'GraphNode';
  clientId?: Maybe<Scalars['ID']>;
  data?: Maybe<GraphItemDataResponse>;
  dragging?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  parentNode?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['JSON']>;
  positionAbsolute?: Maybe<Scalars['JSON']>;
  selected?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type GraphNodeInput = {
  actionType?: InputMaybe<ActionType>;
  data?: InputMaybe<Scalars['JSON']>;
  dragging?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  parentNode?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['JSON']>;
  positionAbsolute?: InputMaybe<Scalars['JSON']>;
  selected?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type GraphqlError = {
  __typename?: 'GraphqlError';
  code: Scalars['String'];
  helpLink?: Maybe<Scalars['URL']>;
  message: Scalars['String'];
  type: ErrorType;
};

export type IMilestone = {
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Int'];
};

export type IMsp = {
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  opportunityId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Int'];
};

export type IRelationshipBaseItem = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  objectType?: Maybe<RelationshipObjectType>;
};

export type IUser = {
  company?: Maybe<CompanyResponse>;
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Image = Assets &
  Media & {
    __typename?: 'Image';
    analytics?: Maybe<MediaAnalyticsResponse>;
    context?: Maybe<MediaContextResponse>;
    createdAt: Scalars['Timestamp'];
    createdBy: UserResponse;
    description?: Maybe<Scalars['String']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileName?: Maybe<Scalars['String']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    fileSize?: Maybe<Scalars['Int']>;
    formats?: Maybe<Scalars['JSON']>;
    id: Scalars['ID'];
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaType: MediaType;
    mimeType?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    orgId: Scalars['ID'];
    source?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Thumbnail>;
    url: Scalars['URL'];
  };

export type IntObject = {
  __typename?: 'IntObject';
  value: Scalars['Int'];
};

export type IntResponse = GraphqlError | IntObject;

export type InviteInput = {
  mspId: Scalars['ID'];
  notification?: InputMaybe<NotificationInput>;
  permission: MspInvitePermission;
  userIdMaps?: InputMaybe<Array<InputMaybe<UserIdMapInput>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type LatestActivity = {
  __typename?: 'LatestActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityBy?: Maybe<UserResponse>;
  activityById?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  role?: Maybe<RolePayloadResponse>;
};

export type Link = Media & {
  __typename?: 'Link';
  analytics?: Maybe<MediaAnalyticsResponse>;
  context?: Maybe<MediaContextResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileName?: Maybe<Scalars['String']>;
  fileShareUrl?: Maybe<Scalars['URL']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isInternal?: Maybe<Scalars['Boolean']>;
  link: Scalars['URL'];
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
  /** @deprecated Remove this field */
  url: Scalars['URL'];
};

export type LinkOpportunityInput = {
  accountId: Scalars['ID'];
  mspId: Scalars['ID'];
  opportunityId: Scalars['ID'];
};

export type Logo = Media & {
  __typename?: 'Logo';
  analytics?: Maybe<MediaAnalyticsResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileDownloadUrl?: Maybe<Scalars['URL']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** @deprecated Remove this field */
  isInternal?: Maybe<Scalars['Boolean']>;
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
};

export type LogoResponse = GraphqlError | Logo;

export type LovItem = {
  __typename?: 'LovItem';
  active: Scalars['Boolean'];
  createdAt: Scalars['Timestamp'];
  displayName: Scalars['String'];
  displayValue: Scalars['String'];
  id: Scalars['String'];
  isDefaultValue: Scalars['Boolean'];
  name: Scalars['String'];
  originalSource: OriginalSourceJson;
  scope?: Maybe<ScopeJson>;
  type: ScopedUserInfoType;
  value: Scalars['String'];
};

export type LovItemConnection = {
  __typename?: 'LovItemConnection';
  edges?: Maybe<Array<Maybe<LovItemEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LovItemConnectionResponse = GraphqlError | LovItemConnection;

export type LovItemEdge = {
  __typename?: 'LovItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<LovItemResponse>;
};

export type LovItemMutation = {
  __typename?: 'LovItemMutation';
  bulkCreateLovItems: LovItemPayloadResponse;
  createLovItem: LovItemResponse;
  updateLovItem: LovItemResponse;
};

export type LovItemMutationBulkCreateLovItemsArgs = {
  input: Array<CreateLovItemInput>;
};

export type LovItemMutationCreateLovItemArgs = {
  input: CreateLovItemInput;
};

export type LovItemMutationUpdateLovItemArgs = {
  id: Scalars['String'];
  input: UpdateLovItemInput;
};

export type LovItemPayload = {
  __typename?: 'LovItemPayload';
  items: Array<LovItemResponse>;
};

export type LovItemPayloadResponse = GraphqlError | LovItemPayload;

export type LovItemQuery = {
  __typename?: 'LovItemQuery';
  getLovItem: LovItemResponse;
  searchLovItems: LovItemConnectionResponse;
};

export type LovItemQueryGetLovItemArgs = {
  id: Scalars['ID'];
};

export type LovItemQuerySearchLovItemsArgs = {
  operations: Operations;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type LovItemResponse = GraphqlError | LovItem;

export type MarkMediaInternalInput = {
  isInternal: Scalars['Boolean'];
  mediaIds: Array<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['ID']>;
};

export type MarkMilestoneInternalInput = {
  isInternal: Scalars['Boolean'];
  milestoneIds: Array<Scalars['ID']>;
};

export type MarkTaskInternalInput = {
  isInternal: Scalars['Boolean'];
  taskIds: Array<Scalars['ID']>;
};

export type Meddic = Node & {
  __typename?: 'Meddic';
  analytics?: Maybe<MethodologyAnalyticsResponse>;
  champion?: Maybe<MethodologyUserQualifier>;
  decisionCriteria?: Maybe<MethodologyStringQualifier>;
  decisionProcess?: Maybe<MethodologyStringQualifier>;
  economicBuyer?: Maybe<MethodologyUserQualifier>;
  id: Scalars['ID'];
  identifiedPain?: Maybe<MethodologyStringQualifier>;
  metrics?: Maybe<MethodologyStringQualifier>;
  mspId: Scalars['ID'];
};

export type MeddicMutation = {
  __typename?: 'MeddicMutation';
  syncMeddic: MeddicPayloadResponse;
  updateMeddic: MeddicPayloadResponse;
};

export type MeddicMutationSyncMeddicArgs = {
  input: SyncMeddicInput;
  mspId: Scalars['ID'];
};

export type MeddicMutationUpdateMeddicArgs = {
  input: UpdateMeddicInput;
  mspId: Scalars['ID'];
};

export type MeddicPayload = {
  __typename?: 'MeddicPayload';
  items?: Maybe<Array<MeddicResponse>>;
};

export type MeddicPayloadResponse = GraphqlError | MeddicPayload;

export type MeddicQualifierComment = {
  __typename?: 'MeddicQualifierComment';
  comments?: Maybe<CommentConnectionResponse>;
  qualifierId?: Maybe<Scalars['String']>;
};

export type MeddicQualifierCommentPayload = {
  __typename?: 'MeddicQualifierCommentPayload';
  items?: Maybe<Array<Maybe<MeddicQualifierComment>>>;
};

export type MeddicQualifierCommentPayloadResponse =
  | GraphqlError
  | MeddicQualifierCommentPayload;

export type MeddicQuery = {
  __typename?: 'MeddicQuery';
  getMeddicComments?: Maybe<MeddicQualifierCommentPayloadResponse>;
  getMeddicForMsp: MeddicPayloadResponse;
};

export type MeddicQueryGetMeddicCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type MeddicQueryGetMeddicForMspArgs = {
  mspId: Scalars['ID'];
};

export type MeddicResponse = GraphqlError | Meddic;

export type Meddpicc = Node & {
  __typename?: 'Meddpicc';
  analytics?: Maybe<MethodologyAnalyticsResponse>;
  champion?: Maybe<MethodologyUserQualifier>;
  competition?: Maybe<MethodologyStringQualifier>;
  decisionCriteria?: Maybe<MethodologyStringQualifier>;
  decisionProcess?: Maybe<MethodologyStringQualifier>;
  economicBuyer?: Maybe<MethodologyUserQualifier>;
  id: Scalars['ID'];
  identifiedPain?: Maybe<MethodologyStringQualifier>;
  metrics?: Maybe<MethodologyStringQualifier>;
  mspId: Scalars['ID'];
  paperProcess?: Maybe<MethodologyTaskQualifier>;
};

export type MeddpiccMutation = {
  __typename?: 'MeddpiccMutation';
  syncMeddpicc: MeddpiccPayloadResponse;
  updateMeddpicc: MeddpiccPayloadResponse;
};

export type MeddpiccMutationSyncMeddpiccArgs = {
  input: SyncMeddpiccInput;
  mspId: Scalars['ID'];
};

export type MeddpiccMutationUpdateMeddpiccArgs = {
  input: UpdateMeddpiccInput;
  mspId: Scalars['ID'];
};

export type MeddpiccPayload = {
  __typename?: 'MeddpiccPayload';
  items?: Maybe<Array<MeddpiccResponse>>;
};

export type MeddpiccPayloadResponse = GraphqlError | MeddpiccPayload;

export type MeddpiccQualifierComment = {
  __typename?: 'MeddpiccQualifierComment';
  comments?: Maybe<CommentConnectionResponse>;
  qualifierId?: Maybe<Scalars['String']>;
};

export type MeddpiccQualifierCommentPayload = {
  __typename?: 'MeddpiccQualifierCommentPayload';
  items?: Maybe<Array<Maybe<MeddpiccQualifierComment>>>;
};

export type MeddpiccQualifierCommentPayloadResponse =
  | GraphqlError
  | MeddpiccQualifierCommentPayload;

export type MeddpiccQuery = {
  __typename?: 'MeddpiccQuery';
  getMeddpiccComments?: Maybe<MeddpiccQualifierCommentPayloadResponse>;
  getMeddpiccForMsp: MeddpiccPayloadResponse;
};

export type MeddpiccQueryGetMeddpiccCommentsArgs = {
  qualifierIds: Array<Scalars['ID']>;
};

export type MeddpiccQueryGetMeddpiccForMspArgs = {
  mspId: Scalars['ID'];
};

export type MeddpiccResponse = GraphqlError | Meddpicc;

export type Media = {
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {analytics}`. */
  analytics?: Maybe<MediaAnalyticsResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {fileName}`. */
  fileName?: Maybe<Scalars['String']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {fileSize}`. */
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {isInternal}`. */
  isInternal?: Maybe<Scalars['Boolean']>;
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {source}`. */
  source?: Maybe<Scalars['String']>;
  /** @deprecated Access this field on specific media type(Image, Audio, Video, ..) `...on Image {url}`. */
  url: Scalars['URL'];
};

export type MediaAnalytics = {
  __typename?: 'MediaAnalytics';
  belongsTo?: Maybe<BelongsToResponse>;
  engagementCount?: Maybe<EngagementCountResponse>;
};

export type MediaAnalyticsResponse = GraphqlError | MediaAnalytics;

export type MediaBelongsTo = {
  __typename?: 'MediaBelongsTo';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: Scalars['String'];
};

export type MediaConnection = {
  __typename?: 'MediaConnection';
  edges?: Maybe<Array<Maybe<MediaEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MediaConnectionResponse = GraphqlError | MediaConnection;

export type MediaContext = {
  __typename?: 'MediaContext';
  isFavorite?: Maybe<Scalars['Boolean']>;
  isPin?: Maybe<Scalars['Boolean']>;
};

export type MediaContextResponse = GraphqlError | MediaContext;

export type MediaEdge = {
  __typename?: 'MediaEdge';
  cursor: Scalars['String'];
  node?: Maybe<MediaResponse>;
};

export type MediaEngagement = {
  __typename?: 'MediaEngagement';
  downloadCount: Scalars['Int'];
  id: Scalars['String'];
  media?: Maybe<MediaConnectionResponse>;
  viewCount: Scalars['Int'];
};

export type MediaEngagementConnection = {
  __typename?: 'MediaEngagementConnection';
  edges?: Maybe<Array<Maybe<MediaEngagementEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MediaEngagementConnectionResponse =
  | GraphqlError
  | MediaEngagementConnection;

export type MediaEngagementEdge = {
  __typename?: 'MediaEngagementEdge';
  node?: Maybe<MediaEngagementResponse>;
};

export type MediaEngagementResponse = GraphqlError | MediaEngagement;

export type MediaExternalLink = {
  __typename?: 'MediaExternalLink';
  url?: Maybe<Scalars['URL']>;
};

export type MediaExternalLinkResponse = GraphqlError | MediaExternalLink;

export type MediaMutation = {
  __typename?: 'MediaMutation';
  attachMedia: AttachMediaPayloadResponse;
  createAndAttachMedia: MediaPayloadResponse;
  createExternalLink: MediaExternalLinkResponse;
  detachMedia: DetachMediaResponse;
  favoriteMedia: BooleanResponse;
  markMediaInternal: BooleanResponse;
  pinMedia: BooleanResponse;
  replaceAttachedMedia: MediaPayloadResponse;
  revokeExternalAccess: BooleanResponse;
  unfavoriteMedia: BooleanResponse;
  unpinMedia: BooleanResponse;
  updateMedia: MediaPayloadResponse;
  uploadAndAttachMedia: MediaPayloadResponse;
};

export type MediaMutationAttachMediaArgs = {
  input: AttachMediaInput;
  mediaIds: Array<Scalars['ID']>;
};

export type MediaMutationCreateAndAttachMediaArgs = {
  input: CreateAndAttachMediaInput;
};

export type MediaMutationCreateExternalLinkArgs = {
  input: ExternalLinkInput;
};

export type MediaMutationDetachMediaArgs = {
  input: DetachMediaInput;
};

export type MediaMutationFavoriteMediaArgs = {
  input: FavoriteMediaInput;
};

export type MediaMutationMarkMediaInternalArgs = {
  input: MarkMediaInternalInput;
};

export type MediaMutationPinMediaArgs = {
  input: PinMediaInput;
};

export type MediaMutationReplaceAttachedMediaArgs = {
  input: ReplaceAttachedMediaInput;
};

export type MediaMutationRevokeExternalAccessArgs = {
  input: RevokeMediaExternalAccessInput;
};

export type MediaMutationUnfavoriteMediaArgs = {
  input: UnfavoriteMediaInput;
};

export type MediaMutationUnpinMediaArgs = {
  input: UnpinMediaInput;
};

export type MediaMutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type MediaMutationUploadAndAttachMediaArgs = {
  input: UploadAndAttachMediaInput;
};

export type MediaPayload = {
  __typename?: 'MediaPayload';
  items?: Maybe<Array<MediaResponse>>;
};

export type MediaPayloadItem = {
  __typename?: 'MediaPayloadItem';
  item: Media;
};

export type MediaPayloadResponse = GraphqlError | MediaPayload;

export type MediaQuery = {
  __typename?: 'MediaQuery';
  getMediaEngagement?: Maybe<Media>;
  listMedia?: Maybe<MediaConnectionResponse>;
  mediaFilters: FilterItemsPayloadResponse;
  myFavoriteMedia?: Maybe<MediaConnectionResponse>;
  pinnedMedia?: Maybe<MediaConnectionResponse>;
};

export type MediaQueryGetMediaEngagementArgs = {
  mediaId: Scalars['ID'];
};

export type MediaQueryListMediaArgs = {
  operations: Operations;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MediaQueryMediaFiltersArgs = {
  operations: Operations;
};

export type MediaQueryMyFavoriteMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MediaQueryPinnedMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MediaResponse = GraphqlError | MediaPayloadItem;

export enum MediaType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  LOGO = 'LOGO',
  PDF = 'PDF',
  THUMBNAIL = 'THUMBNAIL',
  VIDEO = 'VIDEO',
  ZIP = 'ZIP',
}

export type MemberMutation = {
  __typename?: 'MemberMutation';
  addStakeholderToRole: AddOrRemoveStakeholderRoleResponse;
  addStakeholdersToRoles: AddOrRemoveStakeholderRoleResponse;
  createAndAssignPlaceholderToRole: AddOrRemovePlaceholderRoleResponse;
  createAndAssignPlaceholdersToRoles: AddOrRemovePlaceholderRoleResponse;
  createAndAssignStakeholderToRole: UserPayloadResponse;
  removePlaceholderFromRole: BooleanResponse;
  removePlaceholdersFromRoles: BooleanResponse;
  removeStakeholderFromRole: BooleanResponse;
  removeStakeholdersFromRoles: BooleanResponse;
  replacePlaceholderWithStakeholder: BooleanResponse;
  replaceStakeholder: BooleanResponse;
};

export type MemberMutationAddStakeholderToRoleArgs = {
  input: AddStakeHolderToRoleInput;
};

export type MemberMutationAddStakeholdersToRolesArgs = {
  input: Array<AddStakeHolderToRoleInput>;
};

export type MemberMutationCreateAndAssignPlaceholderToRoleArgs = {
  input: CreateAndAssignPlaceholderToRoleInput;
};

export type MemberMutationCreateAndAssignPlaceholdersToRolesArgs = {
  input: Array<CreateAndAssignPlaceholderToRoleInput>;
};

export type MemberMutationCreateAndAssignStakeholderToRoleArgs = {
  input: CreateAndAssignStakeholderToRoleInput;
};

export type MemberMutationRemovePlaceholderFromRoleArgs = {
  input: RemovePlaceholderFromRoleInput;
};

export type MemberMutationRemovePlaceholdersFromRolesArgs = {
  input: Array<RemovePlaceholderFromRoleInput>;
};

export type MemberMutationRemoveStakeholderFromRoleArgs = {
  input: RemoveStakeholderFromRoleInput;
};

export type MemberMutationRemoveStakeholdersFromRolesArgs = {
  input: Array<RemoveStakeholderFromRoleInput>;
};

export type MemberMutationReplacePlaceholderWithStakeholderArgs = {
  input: ReplacePlaceholderWithStakeholderInput;
};

export type MemberMutationReplaceStakeholderArgs = {
  input: ReplaceStakeholderInput;
};

export type MemberQuery = {
  __typename?: 'MemberQuery';
  listPlaceholders: PlaceholderConnectionResponse;
};

export type MemberQueryListPlaceholdersArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type Members = {
  __typename?: 'Members';
  placeholders?: Maybe<Array<PlaceholderResponse>>;
  stakeholders?: Maybe<Array<StakeholderResponse>>;
};

export type MembersResponse = GraphqlError | Members;

export type MessageData = {
  __typename?: 'MessageData';
  editedAt?: Maybe<Scalars['Timestamp']>;
  isEdited: Scalars['Boolean'];
  message: Scalars['String'];
};

export type MethodologyAnalytics = {
  __typename?: 'MethodologyAnalytics';
  firstSyncDone: Scalars['Boolean'];
  isSyncEnabled: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['Timestamp']>;
  lastUpdatedAt: Scalars['Timestamp'];
  syncDisableReason?: Maybe<Scalars['String']>;
};

export type MethodologyAnalyticsResponse = GraphqlError | MethodologyAnalytics;

export type MethodologyConfig = {
  __typename?: 'MethodologyConfig';
  displayName?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  templateId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export type MethodologyConfigInput = {
  displayName?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  templateId: Scalars['ID'];
  type?: InputMaybe<MethodologyTypes>;
};

export type MethodologyPayload = {
  __typename?: 'MethodologyPayload';
  items?: Maybe<Array<MethodologyResponse>>;
};

export type MethodologyQualifierAnalytics = {
  __typename?: 'MethodologyQualifierAnalytics';
  isSynced: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['Timestamp']>;
  lastUpdatedAt: Scalars['Timestamp'];
};

export type MethodologyQualifierData = {
  id: Scalars['ID'];
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type MethodologyQualifierNotes = {
  __typename?: 'MethodologyQualifierNotes';
  value: Scalars['String'];
};

export type MethodologyResponse = GraphqlError | Meddic | Meddpicc;

export type MethodologyStringQualifier = {
  __typename?: 'MethodologyStringQualifier';
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  comments?: Maybe<CommentConnectionResponse>;
  current?: Maybe<MethodologyStringQualifierData>;
  salesforce?: Maybe<MethodologyStringQualifierData>;
};

export type MethodologyStringQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyStringQualifierData';
  data?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type MethodologyTaskQualifier = {
  __typename?: 'MethodologyTaskQualifier';
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  comments?: Maybe<CommentConnectionResponse>;
  current?: Maybe<MethodologyTaskQualifierData>;
  salesforce?: Maybe<MethodologyTaskQualifierData>;
};

export type MethodologyTaskQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyTaskQualifierData';
  data?: Maybe<Array<Maybe<TaskResponse>>>;
  id: Scalars['ID'];
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export enum MethodologyTypes {
  MEDDIC = 'MEDDIC',
  MEDDPICC = 'MEDDPICC',
}

export type MethodologyUserQualifier = {
  __typename?: 'MethodologyUserQualifier';
  analytics?: Maybe<MethodologyQualifierAnalytics>;
  comments?: Maybe<CommentConnectionResponse>;
  current?: Maybe<MethodologyUserQualifierData>;
  salesforce?: Maybe<MethodologyUserQualifierData>;
};

export type MethodologyUserQualifierData = MethodologyQualifierData & {
  __typename?: 'MethodologyUserQualifierData';
  data?: Maybe<Array<Maybe<UserResponse>>>;
  id: Scalars['ID'];
  notes: Array<Maybe<MethodologyQualifierNotes>>;
  score?: Maybe<Scalars['Int']>;
};

export type Milestone = IMilestone & {
  __typename?: 'Milestone';
  analytics?: Maybe<MilestoneAnalyticsResponse>;
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['ID'];
  isInternal: Scalars['Boolean'];
  media?: Maybe<MediaConnectionResponse>;
  milestoneDetails?: Maybe<MilestoneDetailsResponse>;
  msp?: Maybe<MspResponse>;
  name: Scalars['String'];
  startDate: Scalars['Int'];
  tasks: TaskConnectionResponse;
};

export type MilestoneMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MilestoneTasksArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MilestoneAnalytics = {
  __typename?: 'MilestoneAnalytics';
  mediaCount?: Maybe<IntResponse>;
  progressStatus?: Maybe<MilestoneTasksProgressStatusResponse>;
};

export type MilestoneAnalyticsResponse = GraphqlError | MilestoneAnalytics;

export type MilestoneConnection = {
  __typename?: 'MilestoneConnection';
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MilestoneConnectionResponse = GraphqlError | MilestoneConnection;

export type MilestoneDetails = {
  __typename?: 'MilestoneDetails';
  goals?: Maybe<Scalars['String']>;
  milestoneId: Scalars['ID'];
  riskDependencies?: Maybe<Scalars['String']>;
  successCriteria?: Maybe<Scalars['String']>;
};

export type MilestoneDetailsInput = {
  goals?: InputMaybe<Scalars['String']>;
  riskDependencies?: InputMaybe<Scalars['String']>;
  successCriteria?: InputMaybe<Scalars['String']>;
};

export type MilestoneDetailsPayload = {
  __typename?: 'MilestoneDetailsPayload';
  items?: Maybe<Array<MilestoneDetailsResponse>>;
};

export type MilestoneDetailsPayloadResponse =
  | GraphqlError
  | MilestoneDetailsPayload;

export type MilestoneDetailsResponse = GraphqlError | MilestoneDetails;

export type MilestoneEdge = {
  __typename?: 'MilestoneEdge';
  cursor: Scalars['String'];
  node?: Maybe<MilestoneResponse>;
};

export type MilestoneHighlights = {
  __typename?: 'MilestoneHighlights';
  activeMilestones?: Maybe<Array<Maybe<AnalyticsMilestone>>>;
};

export type MilestoneMutation = {
  __typename?: 'MilestoneMutation';
  cloneMilestone: MilestonePayloadResponse;
  createMilestone: MilestonePayloadResponse;
  deleteMilestone: BooleanResponse;
  markMilestonesInternal: BooleanResponse;
  updateMilestone: MilestonePayloadResponse;
  upsertMilestoneDetails: MilestoneDetailsPayloadResponse;
};

export type MilestoneMutationCloneMilestoneArgs = {
  id: Scalars['ID'];
};

export type MilestoneMutationCreateMilestoneArgs = {
  input: CreateMilestoneInput;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
};

export type MilestoneMutationDeleteMilestoneArgs = {
  id: Scalars['ID'];
};

export type MilestoneMutationMarkMilestonesInternalArgs = {
  input?: InputMaybe<MarkMilestoneInternalInput>;
};

export type MilestoneMutationUpdateMilestoneArgs = {
  id: Scalars['ID'];
  input: UpdateMilestoneInput;
};

export type MilestoneMutationUpsertMilestoneDetailsArgs = {
  input: MilestoneDetailsInput;
  milestoneId: Scalars['ID'];
};

export type MilestonePayload = {
  __typename?: 'MilestonePayload';
  items?: Maybe<Array<MilestoneResponse>>;
};

export type MilestonePayloadResponse = GraphqlError | MilestonePayload;

export type MilestoneQuery = {
  __typename?: 'MilestoneQuery';
  getMilestoneDetails: MilestoneDetailsPayloadResponse;
  getMilestones: MilestonePayloadResponse;
  listMilestones: MilestoneConnectionResponse;
};

export type MilestoneQueryGetMilestoneDetailsArgs = {
  milestoneId: Scalars['ID'];
};

export type MilestoneQueryGetMilestonesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MilestoneQueryListMilestonesArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MilestoneResponse = BaseMilestone | GraphqlError | Milestone;

export type MilestoneTasksProgressStatus = {
  __typename?: 'MilestoneTasksProgressStatus';
  completed: Scalars['Int'];
  inprogress: Scalars['Int'];
  pending: Scalars['Int'];
  total: Scalars['Int'];
};

export type MilestoneTasksProgressStatusResponse =
  | GraphqlError
  | MilestoneTasksProgressStatus;

export type Msp = IMsp & {
  __typename?: 'Msp';
  analytics?: Maybe<MspAnalyticsResponse>;
  buyerCompany: CompanyResponse;
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  /** @deprecated Use `owner`. */
  createdBy: UserResponse;
  decisionCriteria?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  isPublished: Scalars['Boolean'];
  isReverseTimeLineEnabled: Scalars['Boolean'];
  keyDates?: Maybe<MspKeyDatesResponse>;
  media?: Maybe<MediaConnectionResponse>;
  milestones: MilestoneConnectionResponse;
  name: Scalars['String'];
  opportunity?: Maybe<OpportunityResponse>;
  /** @deprecated Use `opportunity=> id`. */
  opportunityId?: Maybe<Scalars['ID']>;
  owner?: Maybe<UserResponse>;
  sellerCompany: CompanyResponse;
  sharedBy?: Maybe<UserResponse>;
  startDate: Scalars['Int'];
  subscribers: SubscriberConnectionResponse;
  summary?: Maybe<ExecutiveSummary>;
  timelineOrder?: Maybe<TimelineOrder>;
  timelines?: Maybe<TimelinePayloadResponse>;
  userBridge?: Maybe<MspUserBridgeResponse>;
  usps: UpsPayloadResponse;
};

export type MspAnalyticsArgs = {
  options?: InputMaybe<GetMspAnalyticsOptions>;
};

export type MspMediaArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MspMilestonesArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MspSubscribersArgs = {
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MspAnalytics = {
  __typename?: 'MspAnalytics';
  lastActivity?: Maybe<LatestActivity>;
  /** @deprecated this can be read from lastExternalAccess */
  lastExternalAccessedAt?: Maybe<Scalars['Timestamp']>;
  lastExternalActivity?: Maybe<LatestActivity>;
  /** @deprecated this is moved to meta */
  lastTaskCompletedAt?: Maybe<Scalars['Timestamp']>;
  mspId: Scalars['ID'];
  mspProgress?: Maybe<Scalars['Int']>;
  mspStage?: Maybe<MspStage>;
  mspStatus?: Maybe<MspTrackStatus>;
  opportunity?: Maybe<AnalyticsOpportunity>;
  track?: Maybe<MspTrack>;
};

export type MspAnalyticsHighlights = {
  __typename?: 'MspAnalyticsHighlights';
  insights?: Maybe<Array<Maybe<Scalars['String']>>>;
  milestones?: Maybe<MilestoneHighlights>;
  tasks?: Maybe<TaskHighlights>;
};

export type MspAnalyticsHighlightsResponse =
  | GraphqlError
  | MspAnalyticsHighlights;

export type MspAnalyticsQuery = {
  __typename?: 'MspAnalyticsQuery';
  getMediaEngagement: MediaEngagementConnectionResponse;
  getMspAnalyticsHighlights: MspAnalyticsHighlightsResponse;
  getMspAnalyticsMetrics: MspMetricsResponse;
  getMspAnalyticsTimeline: MspAnalyticsTimelineResponse;
  getMspEngagement?: Maybe<MspEngagementDetailsResponse>;
  getMspEngagementTimeline: MspEngagementTimelineResponse;
  getMspEngagementTimelineUsers: MspEngagementUsersPayloadResponse;
  getUserEngagement: UserEngagementConnectionResponse;
};

export type MspAnalyticsQueryGetMediaEngagementArgs = {
  mspId: Scalars['ID'];
  requestOptions?: InputMaybe<GetMediaEngagementRequest>;
};

export type MspAnalyticsQueryGetMspAnalyticsHighlightsArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspAnalyticsMetricsArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspAnalyticsTimelineArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspEngagementArgs = {
  mspId: Scalars['ID'];
};

export type MspAnalyticsQueryGetMspEngagementTimelineArgs = {
  mspId: Scalars['ID'];
  requestOptions?: InputMaybe<MspAnalyticsTimelineQueryOptions>;
};

export type MspAnalyticsQueryGetMspEngagementTimelineUsersArgs = {
  mspId: Scalars['ID'];
  requestOptions: GetMspEngagementUsersRequest;
};

export type MspAnalyticsQueryGetUserEngagementArgs = {
  mspId: Scalars['ID'];
  requestOptions?: InputMaybe<GetUserEngagementRequest>;
};

export type MspAnalyticsResponse = GraphqlError | MspAnalytics;

export type MspAnalyticsStakeholders = {
  __typename?: 'MspAnalyticsStakeholders';
  stakeholders?: Maybe<Array<Maybe<AnalyticsUser>>>;
  subscribers?: Maybe<Array<Maybe<AnalyticsUser>>>;
};

export type MspAnalyticsStakeholdersResponse =
  | GraphqlError
  | MspAnalyticsStakeholders;

export type MspAnalyticsTimeline = {
  __typename?: 'MspAnalyticsTimeline';
  lastUpdatedAt: Scalars['Timestamp'];
  mspId: Scalars['ID'];
  plannedCompletionDate: Scalars['Timestamp'];
  timeline?: Maybe<Array<Maybe<MspAnalyticsTimelineData>>>;
};

export type MspAnalyticsTimelineData = {
  __typename?: 'MspAnalyticsTimelineData';
  mspProgress?: Maybe<Scalars['Int']>;
  mspSlip?: Maybe<Scalars['Int']>;
  plannedMspProgress?: Maybe<Scalars['Int']>;
  timeParameterDisplayName: Scalars['String'];
  timestamp: Scalars['Timestamp'];
};

export type MspAnalyticsTimelineFilterOptions = {
  userIds?: InputMaybe<Array<Scalars['String']>>;
  userType?: InputMaybe<UserType>;
};

export enum MspAnalyticsTimelineGroupByOptions {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type MspAnalyticsTimelineQueryOptions = {
  filter?: InputMaybe<MspAnalyticsTimelineFilterOptions>;
  groupBy?: InputMaybe<MspAnalyticsTimelineGroupByOptions>;
};

export type MspAnalyticsTimelineResponse = GraphqlError | MspAnalyticsTimeline;

export type MspConnection = {
  __typename?: 'MspConnection';
  edges?: Maybe<Array<Maybe<MspEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MspConnectionResponse = GraphqlError | MspConnection;

export type MspEdge = {
  __typename?: 'MspEdge';
  cursor: Scalars['String'];
  node?: Maybe<MspResponse>;
};

export type MspEngagement = {
  __typename?: 'MspEngagement';
  comment?: Maybe<EngagementCount>;
  lastActivity?: Maybe<LatestActivity>;
  media?: Maybe<EngagementCount>;
  msp?: Maybe<EngagementCount>;
  user?: Maybe<MspEngagementUserDetails>;
};

export type MspEngagementDetails = {
  __typename?: 'MspEngagementDetails';
  externalUserEngagement?: Maybe<MspEngagement>;
  internalUserEngagement?: Maybe<MspEngagement>;
};

export type MspEngagementDetailsResponse = GraphqlError | MspEngagementDetails;

export type MspEngagementTimeline = {
  __typename?: 'MspEngagementTimeline';
  mspId: Scalars['ID'];
  timeline?: Maybe<Array<Maybe<MspEngagementTimelineData>>>;
};

export type MspEngagementTimelineData = {
  __typename?: 'MspEngagementTimelineData';
  buyerEngagementCount?: Maybe<Scalars['Int']>;
  buyerUserIds?: Maybe<Array<Scalars['String']>>;
  engagementCount: Scalars['Int'];
  sellerEngagementCount?: Maybe<Scalars['Int']>;
  sellerUserIds?: Maybe<Array<Scalars['String']>>;
  timeParameterDisplayName: Scalars['String'];
  timestamp: Scalars['Timestamp'];
  userCount?: Maybe<Scalars['Int']>;
};

export type MspEngagementTimelineResponse =
  | GraphqlError
  | MspEngagementTimeline;

export type MspEngagementUserDetails = {
  __typename?: 'MspEngagementUserDetails';
  count: Scalars['Int'];
  engagementUsers?: Maybe<MspEngagmentUsersLatestActivityResponse>;
};

export type MspEngagementUsers = {
  __typename?: 'MspEngagementUsers';
  user?: Maybe<UserResponse>;
  userId: Scalars['String'];
};

export type MspEngagementUsersPayload = {
  __typename?: 'MspEngagementUsersPayload';
  items?: Maybe<Array<MspEngagementUsersRepsonse>>;
};

export type MspEngagementUsersPayloadResponse =
  | GraphqlError
  | MspEngagementUsersPayload;

export type MspEngagementUsersRepsonse = GraphqlError | MspEngagementUsers;

export type MspEngagmentUsersLatestActivity = {
  __typename?: 'MspEngagmentUsersLatestActivity';
  items?: Maybe<Array<UserActivity>>;
};

export type MspEngagmentUsersLatestActivityResponse =
  | GraphqlError
  | MspEngagmentUsersLatestActivity;

export type MspId = Node & {
  __typename?: 'MspId';
  id: Scalars['ID'];
};

export type MspIdResponse = GraphqlError | MspId;

export type MspInvite = GraphqlError | UserIdMap;

export type MspInviteMutation = {
  __typename?: 'MspInviteMutation';
  invite: MspInvitePayloadResponse;
  revokePermission: BooleanResponse;
  updatePermission: BooleanResponse;
};

export type MspInviteMutationInviteArgs = {
  input: InviteInput;
};

export type MspInviteMutationRevokePermissionArgs = {
  input: RevokePermissionInput;
};

export type MspInviteMutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MspInvitePayloadResponse = GraphqlError | MspInviteResponse;

export enum MspInvitePermission {
  ALL = 'ALL',
  EDIT = 'EDIT',
  NONE = 'NONE',
  VIEW = 'VIEW',
}

export type MspInviteResponse = {
  __typename?: 'MspInviteResponse';
  items?: Maybe<Array<Maybe<MspInvite>>>;
};

export type MspKeyDates = {
  __typename?: 'MspKeyDates';
  contractSignatureDate?: Maybe<Scalars['Int']>;
  goLiveDate?: Maybe<Scalars['Int']>;
  vendorChoiceDate?: Maybe<Scalars['Int']>;
};

export type MspKeyDatesResponse = GraphqlError | MspKeyDates;

export type MspMetrics = {
  __typename?: 'MspMetrics';
  externalStakeholders?: Maybe<MspAnalyticsStakeholdersResponse>;
  lastActivity?: Maybe<AnalyticsActivity>;
  lastExternalActivity?: Maybe<AnalyticsActivity>;
  mspId?: Maybe<Scalars['String']>;
  mspTrack?: Maybe<MspTrack>;
};

export type MspMetricsResponse = GraphqlError | MspMetrics;

export type MspMutation = {
  __typename?: 'MspMutation';
  createMsp: MspPayloadResponse;
  deleteMsp: BooleanResponse;
  linkOpportunity: BooleanResponse;
  publishMsp: BooleanResponse;
  transferOwnership: BooleanResponse;
  updateMsp: MspPayloadResponse;
};

export type MspMutationCreateMspArgs = {
  input: CreateMspInput;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
};

export type MspMutationDeleteMspArgs = {
  id: Scalars['ID'];
};

export type MspMutationLinkOpportunityArgs = {
  input: LinkOpportunityInput;
};

export type MspMutationPublishMspArgs = {
  id: Scalars['ID'];
};

export type MspMutationTransferOwnershipArgs = {
  id: Scalars['ID'];
  input: TransferOwnershipInput;
};

export type MspMutationUpdateMspArgs = {
  id: Scalars['ID'];
  input: UpdateMspInput;
};

export type MspPayload = {
  __typename?: 'MspPayload';
  items?: Maybe<Array<MspResponse>>;
};

export type MspPayloadResponse = GraphqlError | MspPayload;

export type MspPermissionPayload = {
  __typename?: 'MspPermissionPayload';
  items: Array<MspInvitePermission>;
};

export type MspPermissionResponse = GraphqlError | MspPermissionPayload;

export type MspPublishStatus = {
  __typename?: 'MspPublishStatus';
  drafts: Scalars['Int'];
  published: Scalars['Int'];
};

export type MspPublishStatusResponse = GraphqlError | MspPublishStatus;

export type MspQuery = {
  __typename?: 'MspQuery';
  getMsp: MspPayloadResponse;
  getMspIdForOpportunity: MspIdResponse;
  getUserReferences: UserReferencesPayloadResponse;
  listMsps: MspConnectionResponse;
  searchMspsFromEmails: MspsWithEmailsPayloadResponse;
  searchUsersInMsp: UserConnectionResponse;
};

export type MspQueryGetMspArgs = {
  id: Scalars['ID'];
};

export type MspQueryGetMspIdForOpportunityArgs = {
  oppId: Scalars['ID'];
};

export type MspQueryGetUserReferencesArgs = {
  operations?: InputMaybe<Operations>;
};

export type MspQueryListMspsArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MspQuerySearchMspsFromEmailsArgs = {
  emails: Array<Scalars['EmailAddress']>;
};

export type MspQuerySearchUsersInMspArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type MspResponse = BaseMsp | GraphqlError | Msp;

export enum MspStage {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum MspSubscriberStatus {
  ADDED = 'ADDED',
  INVITED = 'INVITED',
  OWNER = 'OWNER',
}

export type MspTrack = {
  __typename?: 'MspTrack';
  behindBy?: Maybe<Scalars['Timestamp']>;
  mspSlip?: Maybe<Scalars['Int']>;
  mspSlipDisplayTime?: Maybe<Scalars['String']>;
  mspTrackStatus: MspTrackStatus;
};

export type MspTrackCounts = {
  __typename?: 'MspTrackCounts';
  offTrack: Scalars['Int'];
  onTrack: Scalars['Int'];
  total: Scalars['Int'];
};

export type MspTrackCountsResponse = GraphqlError | MspTrackCounts;

export enum MspTrackStatus {
  OFF_TRACK = 'OFF_TRACK',
  ON_TRACK = 'ON_TRACK',
  UNKNOWN = 'UNKNOWN',
}

export type MspUserBridge = {
  __typename?: 'MspUserBridge';
  permissions: MspPermissionResponse;
};

export type MspUserBridgeResponse = GraphqlError | MspUserBridge;

export type MspWithEmail = {
  __typename?: 'MspWithEmail';
  email: Scalars['String'];
  msps: Msp;
};

export type MspsWithEmails = {
  __typename?: 'MspsWithEmails';
  items: Array<MspWithEmail>;
};

export type MspsWithEmailsPayloadResponse = GraphqlError | MspsWithEmails;

export type MutateTimelineStepsInput = {
  createTimelineSteps?: InputMaybe<Array<CreateTimelineStepsInput>>;
  deleteTimelineSteps?: InputMaybe<DeleteTimelineStepsInput>;
  updateTimelineSteps?: InputMaybe<Array<UpdateTimelineStepsInput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  annotationMutation?: Maybe<AnnotationMutation>;
  companyMutation?: Maybe<CompanyMutation>;
  contactRoleMutation?: Maybe<ContactRoleMutation>;
  lovItemMutation?: Maybe<LovItemMutation>;
  meddicMutation?: Maybe<MeddicMutation>;
  meddpiccMutation?: Maybe<MeddpiccMutation>;
  mediaMutation?: Maybe<MediaMutation>;
  memberMutation?: Maybe<MemberMutation>;
  milestoneMutation?: Maybe<MilestoneMutation>;
  mspInviteMutation?: Maybe<MspInviteMutation>;
  mspMutation?: Maybe<MspMutation>;
  opportunityMutation?: Maybe<OpportunityMutation>;
  organizationMutation?: Maybe<OrganizationMutation>;
  preferenceMutation?: Maybe<PreferenceMutation>;
  pushNotificationMutation?: Maybe<PushNotificationMutation>;
  relationshipMutation?: Maybe<RelationshipMutation>;
  roleMutation?: Maybe<RoleMutation>;
  taskMutation?: Maybe<TaskMutation>;
  templateMutation?: Maybe<TemplateMutation>;
  timelineMutation?: Maybe<TimelineMutation>;
  userMutation?: Maybe<UserMutation>;
  uspMutation?: Maybe<UspMutation>;
};

export type Node = {
  id: Scalars['ID'];
};

export type Notes = Annotation & {
  __typename?: 'Notes';
  annotationType: AnnotationType;
  comments?: Maybe<CommentConnectionResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  data?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  messageData: MessageData;
  reference: Reference;
  scope?: Maybe<AnnotationScope>;
  updatedAt: Scalars['Timestamp'];
};

export type NotesCommentsArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type NotesPayload = {
  __typename?: 'NotesPayload';
  items?: Maybe<Array<NotesResponse>>;
};

export type NotesPayloadResponse = GraphqlError | NotesPayload;

export type NotesResponse = GraphqlError | Notes;

export type NotificationChannelPreference = {
  __typename?: 'NotificationChannelPreference';
  isEnabled: Scalars['Boolean'];
  type: NotificationChannels;
};

export type NotificationChannelPreferenceInput = {
  isEnabled: Scalars['Boolean'];
  type: NotificationChannels;
};

export type NotificationChannelPreferencePayload = {
  __typename?: 'NotificationChannelPreferencePayload';
  items: Array<NotificationPreferenceResponse>;
};

export type NotificationChannelPreferencePayloadResponse =
  | GraphqlError
  | NotificationChannelPreferencePayload;

export type NotificationChannelPreferenceResponse = {
  __typename?: 'NotificationChannelPreferenceResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  type: NotificationChannels;
};

export enum NotificationChannels {
  APP_PUSH = 'APP_PUSH',
  EMAIL = 'EMAIL',
  SLACK = 'SLACK',
}

export type NotificationEntityPreference = {
  __typename?: 'NotificationEntityPreference';
  entityId: Scalars['String'];
  entityType: NotificationEntityType;
  isNotificationEnabled: Scalars['Boolean'];
};

export type NotificationEntityPreferenceInput = {
  entityId: Scalars['String'];
  entityType: NotificationEntityType;
  isNotificationEnabled: Scalars['Boolean'];
};

export enum NotificationEntityType {
  MSP = 'MSP',
}

export enum NotificationEventType {
  COMMENTS_ADDED = 'COMMENTS_ADDED',
  MANAGER_DAILY_DIGEST = 'MANAGER_DAILY_DIGEST',
  MANAGER_DASHBOARD_DIGEST = 'MANAGER_DASHBOARD_DIGEST',
  MSP_ENGAGEMENT = 'MSP_ENGAGEMENT',
  MSP_INVITE = 'MSP_INVITE',
  MSP_TRACK = 'MSP_TRACK',
  NOTIFICATION_EVENT = 'NOTIFICATION_EVENT',
  OWNER_DAILY_DIGEST = 'OWNER_DAILY_DIGEST',
  ROLE_ASSIGNED = 'ROLE_ASSIGNED',
  TASK_ASSIGNED = 'TASK_ASSIGNED',
  TASK_DUE_DATE_UPDATED = 'TASK_DUE_DATE_UPDATED',
  USER_DASHBOARD_DIGEST = 'USER_DASHBOARD_DIGEST',
  USER_MSP_DIGEST = 'USER_MSP_DIGEST',
}

export type NotificationInput = {
  copyMe: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  notify: Scalars['Boolean'];
};

export type NotificationPreferenceEntity = {
  __typename?: 'NotificationPreferenceEntity';
  createdAt: Scalars['Timestamp'];
  event: Scalars['String'];
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  preferences: NotificationScopePreference;
  scopeId: Scalars['ID'];
  scopeType: NotificationScopeType;
  updatedAt: Scalars['Timestamp'];
  version?: Maybe<Scalars['Int']>;
};

export type NotificationPreferenceEntityPayload = {
  __typename?: 'NotificationPreferenceEntityPayload';
  items: Array<NotificationPreferenceEntityResponse>;
};

export type NotificationPreferenceEntityPayloadResponse =
  | GraphqlError
  | NotificationPreferenceEntityPayload;

export type NotificationPreferenceEntityResponse =
  | GraphqlError
  | NotificationPreferenceEntity;

export type NotificationPreferenceRequest = {
  event: Scalars['String'];
  scopes: Array<NotificationPreferenceScopeInput>;
};

export type NotificationPreferenceResponse =
  | GraphqlError
  | NotificationChannelPreferenceResponse;

export type NotificationPreferenceScopeInput = {
  scopeId: Scalars['String'];
  scopeType: NotificationScopeType;
};

export type NotificationScopePreference = {
  __typename?: 'NotificationScopePreference';
  channels?: Maybe<Array<Maybe<NotificationChannelPreference>>>;
  entity?: Maybe<Array<Maybe<NotificationEntityPreference>>>;
};

export type NotificationScopePreferenceInput = {
  channels: Array<NotificationChannelPreferenceInput>;
  entity?: InputMaybe<Array<InputMaybe<NotificationEntityPreferenceInput>>>;
};

export enum NotificationScopeType {
  COMPANY = 'COMPANY',
  MSP = 'MSP',
  ORG = 'ORG',
  USER = 'USER',
}

export enum NotificationStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  READ = 'READ',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export enum ObjectForSource {
  ACCOUNT = 'ACCOUNT',
  COMPANY = 'COMPANY',
  ORGANIZATION = 'ORGANIZATION',
}

export enum ObjectType {
  COMMENT = 'COMMENT',
  COMPANY = 'COMPANY',
  MILESTONE = 'MILESTONE',
  MSP = 'MSP',
  MSP_SUMMARY = 'MSP_SUMMARY',
  TASK = 'TASK',
}

export enum Operation {
  EQ = 'EQ',
  EXISTS = 'EXISTS',
  GE = 'GE',
  GT = 'GT',
  IN = 'IN',
  LE = 'LE',
  LIKE = 'LIKE',
  LT = 'LT',
  MATCH = 'MATCH',
  NE = 'NE',
  NIN = 'NIN',
  NULL = 'NULL',
}

export type Operations = {
  filter?: InputMaybe<FiltersExpression>;
  sort?: InputMaybe<Array<SortExpression>>;
};

export enum Operator {
  AND = 'AND',
  OR = 'OR',
}

export type Opportunity = {
  __typename?: 'Opportunity';
  accountId: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  closeDate?: Maybe<Scalars['Timestamp']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  deletedAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  extCompanyId?: Maybe<Scalars['String']>;
  extId?: Maybe<Scalars['String']>;
  extOwnerId?: Maybe<Scalars['String']>;
  fiscal?: Maybe<Scalars['String']>;
  forecastCategory?: Maybe<Scalars['String']>;
  forecastCategoryName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intCompanyId?: Maybe<Scalars['String']>;
  intOwnerId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isWon?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orgId: Scalars['String'];
  originalSource?: Maybe<Scalars['JSON']>;
  ownerId?: Maybe<Scalars['String']>;
  sourceSystem?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  systemModStamp?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
};

export type OpportunityConnection = {
  __typename?: 'OpportunityConnection';
  edges?: Maybe<Array<Maybe<OpportunityEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OpportunityConnectionResponse =
  | GraphqlError
  | OpportunityConnection;

export type OpportunityCountsAtStage = {
  __typename?: 'OpportunityCountsAtStage';
  counts: Scalars['Int'];
  stageName: Scalars['String'];
};

export type OpportunityCountsAtStagePayload = {
  __typename?: 'OpportunityCountsAtStagePayload';
  items?: Maybe<Array<OpportunityCountsAtStageResponse>>;
};

export type OpportunityCountsAtStagePayloadResponse =
  | GraphqlError
  | OpportunityCountsAtStagePayload;

export type OpportunityCountsAtStageResponse =
  | GraphqlError
  | OpportunityCountsAtStage;

export type OpportunityEdge = {
  __typename?: 'OpportunityEdge';
  cursor: Scalars['String'];
  node?: Maybe<OpportunityResponse>;
};

export type OpportunityMutation = {
  __typename?: 'OpportunityMutation';
  createOpportunity: OpportunityResponse;
  deleteOpportunity: BooleanResponse;
  updateOpportunity: OpportunityResponse;
};

export type OpportunityMutationCreateOpportunityArgs = {
  input: CreateOpportunityInput;
};

export type OpportunityMutationDeleteOpportunityArgs = {
  id: Scalars['ID'];
};

export type OpportunityMutationUpdateOpportunityArgs = {
  id: Scalars['String'];
  input: UpdateOpportunityInput;
};

export type OpportunityQuery = {
  __typename?: 'OpportunityQuery';
  getOpportunity: OpportunityResponse;
  listOpportunity: OpportunityConnectionResponse;
  searchOpportunity: OpportunityConnectionResponse;
};

export type OpportunityQueryGetOpportunityArgs = {
  id: Scalars['ID'];
};

export type OpportunityQueryListOpportunityArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type OpportunityQuerySearchOpportunityArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type OpportunityResponse = GraphqlError | Opportunity;

export type OrgConfig = {
  __typename?: 'OrgConfig';
  branding?: Maybe<BrandingConfig>;
  methodology?: Maybe<MethodologyConfig>;
  others?: Maybe<Scalars['JSON']>;
  salesforce?: Maybe<SalesforceConfig>;
};

export type OrgConfigInput = {
  branding?: InputMaybe<BrandingConfigInput>;
  methodology?: InputMaybe<MethodologyConfigInput>;
  others?: InputMaybe<Scalars['JSON']>;
  salesforce?: InputMaybe<SalesforceConfigInput>;
};

export type OrgLogo = {
  __typename?: 'OrgLogo';
  favicon?: Maybe<Scalars['URL']>;
  original?: Maybe<Scalars['URL']>;
};

export type OrgLogoInput = {
  favicon?: InputMaybe<Scalars['URL']>;
  original?: InputMaybe<Scalars['URL']>;
};

export enum OrgType {
  CUSTOMER = 'CUSTOMER',
  DEV = 'DEV',
  PROD = 'PROD',
  TEST = 'TEST',
}

export type Organization = {
  __typename?: 'Organization';
  configs: OrgConfig;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  orgLogo?: Maybe<OrgLogo>;
  orgName: Scalars['String'];
  orgType: OrgType;
  state: CompanyState;
  updatedAt: Scalars['Timestamp'];
};

export type OrganizationMutation = {
  __typename?: 'OrganizationMutation';
  createOrganization: OrganizationPayloadResponse;
  updateOrganization: OrganizationPayloadResponse;
};

export type OrganizationMutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type OrganizationMutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
  orgId: Scalars['ID'];
};

export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  items?: Maybe<Array<OrganizationResponse>>;
};

export type OrganizationPayloadResponse = GraphqlError | OrganizationPayload;

export type OrganizationQuery = {
  __typename?: 'OrganizationQuery';
  getOrganization: OrganizationPayloadResponse;
};

export type OrganizationQueryGetOrganizationArgs = {
  orgId: Scalars['ID'];
};

export type OrganizationResponse = GraphqlError | Organization;

export type OriginalSourceForCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  object: ObjectForSource;
  system: SystemForSource;
};

export type OriginalSourceForCompanyType = {
  __typename?: 'OriginalSourceForCompanyType';
  id?: Maybe<Scalars['String']>;
  object: ObjectForSource;
  system: SystemForSource;
};

export type OriginalSourceJson = {
  __typename?: 'OriginalSourceJson';
  id?: Maybe<Scalars['String']>;
  source: SourceType;
};

export type OriginalSourceJsonInput = {
  id: Scalars['String'];
  source: SourceType;
};

export type PageConfiguration = {
  afterCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  afterCursor?: Maybe<Scalars['String']>;
  beforeCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type PinMediaInput = {
  contextId: Scalars['ID'];
  mediaIds: Array<Scalars['ID']>;
};

export type Placeholder = {
  __typename?: 'Placeholder';
  companyType?: Maybe<CompanyType>;
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orgId: Scalars['ID'];
};

export type PlaceholderConnection = {
  __typename?: 'PlaceholderConnection';
  edges?: Maybe<Array<Maybe<PlaceholderEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PlaceholderConnectionResponse =
  | GraphqlError
  | PlaceholderConnection;

export type PlaceholderEdge = {
  __typename?: 'PlaceholderEdge';
  cursor: Scalars['String'];
  node?: Maybe<PlaceholderResponse>;
};

export type PlaceholderInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PlaceholderResponse = GraphqlError | Placeholder;

export type PreferenceMutation = {
  __typename?: 'PreferenceMutation';
  createPreferences: NotificationPreferenceEntityPayloadResponse;
};

export type PreferenceMutationCreatePreferencesArgs = {
  input: CreateNotificationPreferenceRequestBody;
};

export type PreferenceQuery = {
  __typename?: 'PreferenceQuery';
  getPreferences: NotificationChannelPreferencePayloadResponse;
};

export type PreferenceQueryGetPreferencesArgs = {
  input: NotificationPreferenceRequest;
};

export type PushNotification = {
  __typename?: 'PushNotification';
  account: PushNotificationAccount;
  authorizer: PushNotificationAuthorizerDetails;
  createdAt: Scalars['Timestamp'];
  link: Scalars['String'];
  message: Scalars['String'];
  metaData?: Maybe<PushNotificationMetaData>;
  notificationType: NotificationEventType;
  status: Scalars['String'];
  userNotificationStatusId: Scalars['String'];
};

export type PushNotificationAccount = {
  __typename?: 'PushNotificationAccount';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PushNotificationAccountDetails = {
  __typename?: 'PushNotificationAccountDetails';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  unreadCount: Scalars['Int'];
};

export type PushNotificationAccountDetailsPayload = {
  __typename?: 'PushNotificationAccountDetailsPayload';
  items: Array<PushNotificationAccountDetailsRepsonse>;
};

export type PushNotificationAccountDetailsPayloadResponse =
  | GraphqlError
  | PushNotificationAccountDetailsPayload;

export type PushNotificationAccountDetailsRepsonse =
  | GraphqlError
  | PushNotificationAccountDetails;

export type PushNotificationAuthorizerDetails = {
  __typename?: 'PushNotificationAuthorizerDetails';
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PushNotificationConnection = {
  __typename?: 'PushNotificationConnection';
  edges?: Maybe<Array<Maybe<PushNotificationEdge>>>;
  newCount?: Maybe<Scalars['Int']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PushNotificationConnectionResponse =
  | GraphqlError
  | PushNotificationConnection;

export type PushNotificationEdge = {
  __typename?: 'PushNotificationEdge';
  cursor: Scalars['String'];
  node?: Maybe<PushNotificationResponse>;
};

export type PushNotificationMetaData = {
  __typename?: 'PushNotificationMetaData';
  additionalMessage?: Maybe<Scalars['String']>;
  references?: Maybe<Array<PushNotificationMetaDataReferences>>;
  resources?: Maybe<Array<PushNotificationMetaDataResources>>;
};

export type PushNotificationMetaDataReferences = {
  __typename?: 'PushNotificationMetaDataReferences';
  id: Scalars['String'];
  type: BaResource;
};

export type PushNotificationMetaDataResources = {
  __typename?: 'PushNotificationMetaDataResources';
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type PushNotificationMutation = {
  __typename?: 'PushNotificationMutation';
  bulkUpdatePushNotificationStatus: BooleanResponse;
  updatePushNotificationStatus: BooleanResponse;
};

export type PushNotificationMutationBulkUpdatePushNotificationStatusArgs = {
  input: BulkUpdateNotificationStatusRequestBody;
};

export type PushNotificationMutationUpdatePushNotificationStatusArgs = {
  input: UpdateNotificationStatusRequestBody;
};

export type PushNotificationQuery = {
  __typename?: 'PushNotificationQuery';
  getNotificationAccounts: PushNotificationAccountDetailsPayloadResponse;
  getPushNotifications: PushNotificationConnectionResponse;
  pollNotifications: PushNotificationConnectionResponse;
};

export type PushNotificationQueryGetNotificationAccountsArgs = {
  userId: Scalars['ID'];
};

export type PushNotificationQueryGetPushNotificationsArgs = {
  input: GetPushNotificationRequestBody;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type PushNotificationQueryPollNotificationsArgs = {
  userId: Scalars['ID'];
};

export type PushNotificationResponse = GraphqlError | PushNotification;

export type Query = {
  __typename?: 'Query';
  activityTimelineQuery?: Maybe<ActivityTimelineQuery>;
  alignxQuery?: Maybe<AlignxQuery>;
  annotationQuery?: Maybe<AnnotationQuery>;
  companyQuery?: Maybe<CompanyQuery>;
  lovItemQuery?: Maybe<LovItemQuery>;
  meddicQuery?: Maybe<MeddicQuery>;
  meddpiccQuery?: Maybe<MeddpiccQuery>;
  mediaQuery?: Maybe<MediaQuery>;
  memberQuery?: Maybe<MemberQuery>;
  milestoneQuery?: Maybe<MilestoneQuery>;
  mspAnalyticsQuery?: Maybe<MspAnalyticsQuery>;
  mspQuery?: Maybe<MspQuery>;
  opportunityQuery?: Maybe<OpportunityQuery>;
  organizationQuery?: Maybe<OrganizationQuery>;
  preferenceQuery?: Maybe<PreferenceQuery>;
  pushNotificationQuery?: Maybe<PushNotificationQuery>;
  relationshipQuery?: Maybe<RelationshipQuery>;
  roleQuery?: Maybe<RoleQuery>;
  structureQuery?: Maybe<StructureQuery>;
  taskQuery?: Maybe<TaskQuery>;
  templateQuery?: Maybe<TemplateQuery>;
  userQuery?: Maybe<UserQuery>;
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  type: ReferenceType;
};

export type ReferenceInput = {
  id: Scalars['ID'];
  type: ReferenceType;
};

export enum ReferenceType {
  EDGE = 'EDGE',
  METHODOLOGY_QUALIFIER = 'METHODOLOGY_QUALIFIER',
  NOTES = 'NOTES',
  TASK = 'TASK',
  USER = 'USER',
}

export type RelationshipContact = IRelationshipBaseItem & {
  __typename?: 'RelationshipContact';
  contactRoles?: Maybe<ContactPayloadResponse>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated user RelationshipContact=> strength */
  impactLevel?: Maybe<RelationshipContactImpactLevel>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<NotesPayload>;
  objectType?: Maybe<RelationshipObjectType>;
  reportTo?: Maybe<UserResponse>;
  stakeholderRoles?: Maybe<RolePayloadResponse>;
  strength?: Maybe<RelationshipContactStrengthResponse>;
  title?: Maybe<Scalars['String']>;
};

export type RelationshipContactImpactLevel = {
  __typename?: 'RelationshipContactImpactLevel';
  displayValue: Scalars['String'];
  value: Scalars['String'];
};

export type RelationshipContactStrength = {
  __typename?: 'RelationshipContactStrength';
  displayValue: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
};

export type RelationshipContactStrengthResponse =
  | GraphqlError
  | RelationshipContactStrength;

export type RelationshipGraph = {
  __typename?: 'RelationshipGraph';
  edges?: Maybe<Array<Maybe<GraphEdge>>>;
  nodes?: Maybe<Array<Maybe<GraphNode>>>;
  viewport?: Maybe<Scalars['JSON']>;
};

export type RelationshipGraphqlResponse = GraphqlError | RelationshipGraph;

export type RelationshipItemConnection = {
  __typename?: 'RelationshipItemConnection';
  edges?: Maybe<Array<Maybe<RelationshipItemEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RelationshipItemConnectionResponse =
  | GraphqlError
  | RelationshipItemConnection;

export type RelationshipItemEdge = {
  __typename?: 'RelationshipItemEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IRelationshipBaseItem>;
};

export type RelationshipItemPayload = {
  __typename?: 'RelationshipItemPayload';
  items?: Maybe<Array<IRelationshipBaseItem>>;
};

export type RelationshipItemPayloadResponse =
  | GraphqlError
  | RelationshipItemPayload;

export type RelationshipItemsFieldValue = {
  __typename?: 'RelationshipItemsFieldValue';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<RelationshipItemsFieldValueMetaData>;
  name?: Maybe<Scalars['String']>;
};

export type RelationshipItemsFieldValueMetaData = {
  __typename?: 'RelationshipItemsFieldValueMetaData';
  color?: Maybe<Scalars['JSON']>;
};

export type RelationshipItemsFieldValuePayload = {
  __typename?: 'RelationshipItemsFieldValuePayload';
  contactRoles?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  stakeholderRoles?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
  strength?: Maybe<Array<Maybe<RelationshipItemsFieldValue>>>;
};

export type RelationshipItemsFieldValueResponse =
  | GraphqlError
  | RelationshipItemsFieldValuePayload;

export type RelationshipMutation = {
  __typename?: 'RelationshipMutation';
  createRelationshipGraph: RelationshipGraphqlResponse;
  updateRelationshipItemData: UpdateRelationshipItemDataPayloadResponse;
};

export type RelationshipMutationCreateRelationshipGraphArgs = {
  input: CreateRelationshipGraphInput;
};

export type RelationshipMutationUpdateRelationshipItemDataArgs = {
  input: UpdateRelationshipItemDataInput;
};

export enum RelationshipObjectType {
  CONTACT = 'CONTACT',
}

export type RelationshipQuery = {
  __typename?: 'RelationshipQuery';
  getRelationshipItems: RelationshipItemPayloadResponse;
  getRelationshipItemsFieldValues: RelationshipItemsFieldValueResponse;
  listRelationshipItems: RelationshipItemConnectionResponse;
  relationshipGraph: RelationshipGraphqlResponse;
};

export type RelationshipQueryGetRelationshipItemsArgs = {
  ids: Array<Scalars['ID']>;
};

export type RelationshipQueryListRelationshipItemsArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type RelationshipQueryRelationshipGraphArgs = {
  id: Scalars['ID'];
};

export type RemovePlaceholderFromRoleInput = {
  placeholderId: Scalars['ID'];
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
};

export type RemoveStakeholderFromRoleInput = {
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
  stakeholderId: Scalars['ID'];
};

export type ReplaceAttachedMediaInput = {
  attachInput: AttachMediaInput;
  detachInput: DetachMediaInput;
  mediaInput: CreateMediaInput;
};

export type ReplacePlaceholderWithStakeholderInput = {
  placeholderId: Scalars['ID'];
  roleId: Scalars['ID'];
  scopeId: Scalars['ID'];
  stakeholderIdMap: UserIdMapInput;
};

export type ReplaceStakeholderInput = {
  currentStakeholderIdMap: UserIdMapInput;
  previousStakeholderIdMap: UserIdMapInput;
};

export type RevokeMediaExternalAccessInput = {
  mediaId: Scalars['ID'];
};

export type RevokePermissionInput = {
  mspId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type Role = {
  __typename?: 'Role';
  companyType: CompanyType;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHidden: Scalars['Boolean'];
  members?: Maybe<RoleMembersResponse>;
  name: Scalars['String'];
  order: Scalars['Int'];
  orgId: Scalars['ID'];
  scopeId: Scalars['ID'];
  scopeType: RoleScope;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RoleConnectionResponse = GraphqlError | RoleConnection;

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String'];
  node?: Maybe<RoleResponse>;
};

export type RoleMembers = {
  __typename?: 'RoleMembers';
  buyerMembers?: Maybe<MembersResponse>;
  sellerMembers?: Maybe<MembersResponse>;
};

export type RoleMembersResponse = GraphqlError | RoleMembers;

export type RoleMutation = {
  __typename?: 'RoleMutation';
  createRole: RolePayloadResponse;
  reOrderRole: BooleanResponse;
  removeRole: BooleanResponse;
  updateRole: RolePayloadResponse;
};

export type RoleMutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type RoleMutationReOrderRoleArgs = {
  roleId: Scalars['ID'];
  to: Scalars['Int'];
};

export type RoleMutationRemoveRoleArgs = {
  id: Scalars['ID'];
};

export type RoleMutationUpdateRoleArgs = {
  id: Scalars['ID'];
  input: UpdateRoleInput;
};

export type RolePayload = {
  __typename?: 'RolePayload';
  items?: Maybe<Array<RoleResponse>>;
};

export type RolePayloadResponse = GraphqlError | RolePayload;

export type RoleQuery = {
  __typename?: 'RoleQuery';
  listRoles: RoleConnectionResponse;
};

export type RoleQueryListRolesArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type RoleResponse = GraphqlError | Role;

export enum RoleScope {
  MSP = 'MSP',
  ORG = 'ORG',
  TEMPLATE = 'TEMPLATE',
}

export type SalesforceConfig = {
  __typename?: 'SalesforceConfig';
  isEnabled?: Maybe<Scalars['Boolean']>;
  qualifiedStageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesforceConfigInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  qualifiedStageNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ScopeJson = {
  __typename?: 'ScopeJson';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ScopeType>;
};

export type ScopeJsonInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ScopeType>;
};

export enum ScopeType {
  ORGANIZATION = 'ORGANIZATION',
}

export enum ScopedUserInfoType {
  COMPANY_USER_RELATIONSHIP_STRENGTH = 'COMPANY_USER_RELATIONSHIP_STRENGTH',
  COMPANY_USER_ROLE = 'COMPANY_USER_ROLE',
  MSP_USER_ROLE = 'MSP_USER_ROLE',
  AP_USER_ROLE = 'AP_USER_ROLE',
  AP_USER_SENTIMENTS = 'AP_USER_SENTIMENTS'
}

export type SortExpression = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SourceType {
  SALESFORCE = 'SALESFORCE',
  ZAPIER = 'ZAPIER',
}

export type Stakeholder = IUser & {
  __typename?: 'Stakeholder';
  analytics?: Maybe<StakeholderAnalyticsResponse>;
  company?: Maybe<CompanyResponse>;
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  primaryId?: Maybe<Scalars['ID']>;
};

export type StakeholderAnalytics = {
  __typename?: 'StakeholderAnalytics';
  engagementCount?: Maybe<EngagementCountResponse>;
};

export type StakeholderAnalyticsResponse = GraphqlError | StakeholderAnalytics;

export type StakeholderResponse = GraphqlError | Stakeholder;

export type Structure = {
  __typename?: 'Structure';
  dataSourceConfig?: Maybe<DataSourceConfig>;
  description?: Maybe<Scalars['String']>;
  fields: Array<StructureField>;
  id: Scalars['ID'];
  name: Scalars['String'];
  structureType: StructureType;
  style?: Maybe<Scalars['JSON']>;
};

export type StructureField = {
  __typename?: 'StructureField';
  fieldProperty?: Maybe<StructureFieldProperty>;
  style?: Maybe<Scalars['JSON']>;
};

export type StructureFieldProperty = {
  __typename?: 'StructureFieldProperty';
  className?: Maybe<Scalars['String']>;
  dataSourceConfig?: Maybe<DataSourceConfig>;
  defaultValue?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fieldContextType?: Maybe<Scalars['String']>;
  fieldType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  values?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type StructureQuery = {
  __typename?: 'StructureQuery';
  listStructure: StructureResponsePayloadResponse;
};

export type StructureQueryListStructureArgs = {
  operations?: InputMaybe<Operations>;
};

export type StructureResponse = GraphqlError | Structure;

export type StructureResponsePayload = {
  __typename?: 'StructureResponsePayload';
  items?: Maybe<Array<Maybe<StructureResponse>>>;
};

export type StructureResponsePayloadResponse =
  | GraphqlError
  | StructureResponsePayload;

export enum StructureType {
  CONTACT_CARD = 'CONTACT_CARD',
  CONTACT_FORM = 'CONTACT_FORM',
  EDGE = 'EDGE',
  NOTES = 'NOTES',
}

export type Subscriber = {
  __typename?: 'Subscriber';
  permission?: Maybe<MspInvitePermission>;
  status: MspSubscriberStatus;
  user: UserResponse;
};

export type SubscriberConnection = {
  __typename?: 'SubscriberConnection';
  edges?: Maybe<Array<Maybe<SubscriberEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriberConnectionResponse = GraphqlError | SubscriberConnection;

export type SubscriberEdge = {
  __typename?: 'SubscriberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubscriberResponse>;
};

export type SubscriberResponse = GraphqlError | Subscriber;

export type SyncMeddicInput = {
  qualifiers: Array<InputMaybe<Scalars['String']>>;
};

export type SyncMeddpiccInput = {
  qualifiers: Array<InputMaybe<Scalars['String']>>;
};

export enum SystemForSource {
  HUBSPOT = 'HUBSPOT',
  INTERNAL = 'INTERNAL',
  SALESFORCE = 'SALESFORCE',
  ZAPIER = 'ZAPIER',
}

export type Task = Node & {
  __typename?: 'Task';
  analytics?: Maybe<TaskAnalyticsResponse>;
  assignee?: Maybe<Assignee>;
  assigneeType?: Maybe<AssigneeType>;
  createdAt: Scalars['Int'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['Int'];
  id: Scalars['ID'];
  isInternal: Scalars['Boolean'];
  milestone: MilestoneResponse;
  milestoneId?: Maybe<Scalars['ID']>;
  msp: MspResponse;
  name: Scalars['String'];
  notes?: Maybe<NotesResponse>;
  order: Scalars['Int'];
  status: TaskStatus;
  subscribers: UserConnectionResponse;
};

export type TaskSubscribersArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TaskAnalytics = {
  __typename?: 'TaskAnalytics';
  /** @deprecated Use taskCommentCount field */
  commentCount?: Maybe<IntResponse>;
  taskCommentCount?: Maybe<Scalars['Int']>;
  taskMediaCount?: Maybe<IntResponse>;
  taskTrack?: Maybe<TaskTrack>;
};

export type TaskAnalyticsResponse = GraphqlError | TaskAnalytics;

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TaskConnectionResponse = GraphqlError | TaskConnection;

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['String'];
  node?: Maybe<TaskResponse>;
};

export type TaskHighlights = {
  __typename?: 'TaskHighlights';
  upcomingTasks?: Maybe<Array<Maybe<AnalyticsTask>>>;
};

export type TaskMutation = {
  __typename?: 'TaskMutation';
  addAssignee: BooleanResponse;
  bulkCreateTasks: TaskPayloadResponse;
  bulkUpdateTasks: TaskPayloadResponse;
  changeTaskStatus: BooleanResponse;
  cloneTasks: TaskPayloadResponse;
  createTask: TaskPayloadResponse;
  createUserAndAssignToTasks: UserPayloadResponse;
  deleteTask: BooleanResponse;
  markAllTasksInMilestoneComplete: BooleanResponse;
  markAllTasksInMilestonePending: BooleanResponse;
  markTasksComplete: BooleanResponse;
  markTasksInternal: BooleanResponse;
  markTasksPending: BooleanResponse;
  moveTasksInMilestone: Array<MilestoneResponse>;
  reOrderTask: BooleanResponse;
  removeAssignee: BooleanResponse;
  updateTask: TaskPayloadResponse;
};

export type TaskMutationAddAssigneeArgs = {
  taskIds: Array<Scalars['ID']>;
  userIdMap?: InputMaybe<UserIdMapInput>;
};

export type TaskMutationBulkCreateTasksArgs = {
  input: Array<CreateTaskInput>;
  milestoneId: Scalars['ID'];
};

export type TaskMutationBulkUpdateTasksArgs = {
  input: Array<UpdateTaskInput>;
};

export type TaskMutationChangeTaskStatusArgs = {
  input?: InputMaybe<ChangeTaskStatusInput>;
};

export type TaskMutationCloneTasksArgs = {
  milestoneId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationCreateTaskArgs = {
  input: CreateTaskInput;
  milestoneId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
};

export type TaskMutationCreateUserAndAssignToTasksArgs = {
  input: CreateUserInput;
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type TaskMutationMarkAllTasksInMilestoneCompleteArgs = {
  milestoneId: Scalars['ID'];
};

export type TaskMutationMarkAllTasksInMilestonePendingArgs = {
  milestoneId: Scalars['ID'];
};

export type TaskMutationMarkTasksCompleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskMutationMarkTasksInternalArgs = {
  input?: InputMaybe<MarkTaskInternalInput>;
};

export type TaskMutationMarkTasksPendingArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskMutationMoveTasksInMilestoneArgs = {
  milestoneId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationReOrderTaskArgs = {
  taskId: Scalars['ID'];
  to: Scalars['Int'];
};

export type TaskMutationRemoveAssigneeArgs = {
  assigneeId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type TaskMutationUpdateTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInput;
};

export type TaskPayload = {
  __typename?: 'TaskPayload';
  items: Array<TaskResponse>;
};

export type TaskPayloadResponse = GraphqlError | TaskPayload;

export type TaskQuery = {
  __typename?: 'TaskQuery';
  allTaskFilters?: Maybe<FilterItemsPayloadResponse>;
  allTaskFiltersCount: FilterItemsCountPayloadResponse;
  allTasksWithFilters: TaskConnectionResponse;
  getTaskNotes: NotesResponse;
  getTasks: TaskPayloadResponse;
  listTasks: TaskConnectionResponse;
  myTaskFilters?: Maybe<FilterItemsPayloadResponse>;
  myTasks: TaskConnectionResponse;
  overdueTasks: TaskConnectionResponse;
};

export type TaskQueryAllTaskFiltersCountArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskQueryAllTasksWithFiltersArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TaskQueryGetTaskNotesArgs = {
  taskId: Scalars['ID'];
};

export type TaskQueryGetTasksArgs = {
  ids: Array<Scalars['ID']>;
};

export type TaskQueryListTasksArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TaskQueryMyTasksArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TaskQueryOverdueTasksArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TaskResponse = GraphqlError | Task;

export enum TaskStatus {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
}

export type TaskTrack = {
  __typename?: 'TaskTrack';
  behindBy?: Maybe<Scalars['Timestamp']>;
  overdueByDays?: Maybe<Scalars['Int']>;
  taskTrackStatus: TaskTrackStatus;
};

export enum TaskTrackStatus {
  ON_TRACK = 'ON_TRACK',
  OVERDUE = 'OVERDUE',
}

export type TemplateMutation = {
  __typename?: 'TemplateMutation';
  createMilestoneFromTemplate: MilestonePayloadResponse;
  createMspFromTemplate: MspPayloadResponse;
  createTemplateFromMilestone: MilestonePayloadResponse;
  createTemplateFromMsp: MspPayloadResponse;
};

export type TemplateMutationCreateMilestoneFromTemplateArgs = {
  input: CreateMilestoneTemplateInput;
  templateId: Scalars['ID'];
};

export type TemplateMutationCreateMspFromTemplateArgs = {
  input: CreateMspTemplateInput;
  templateId: Scalars['ID'];
};

export type TemplateMutationCreateTemplateFromMilestoneArgs = {
  milestoneId: Scalars['ID'];
};

export type TemplateMutationCreateTemplateFromMspArgs = {
  mspId: Scalars['ID'];
};

export type TemplateQuery = {
  __typename?: 'TemplateQuery';
  searchMilestoneTemplates: MilestoneConnectionResponse;
  searchMspTemplates: MspConnectionResponse;
};

export type TemplateQuerySearchMilestoneTemplatesArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type TemplateQuerySearchMspTemplatesArgs = {
  operations?: InputMaybe<Operations>;
  pageConfiguration?: InputMaybe<PageConfiguration>;
};

export type Thumbnail = Media & {
  __typename?: 'Thumbnail';
  analytics?: Maybe<MediaAnalyticsResponse>;
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  fileAccessUrl?: Maybe<Scalars['URL']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** @deprecated Remove this field */
  isInternal?: Maybe<Scalars['Boolean']>;
  mediaType: MediaType;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
};

export type Timeline = {
  __typename?: 'Timeline';
  createdAt: Scalars['Timestamp'];
  createdBy: UserResponse;
  date: Scalars['Int'];
  id: Scalars['ID'];
  label: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type TimelineMutation = {
  __typename?: 'TimelineMutation';
  mutateTimelineSteps: TimelineStepsPayloadResponse;
};

export type TimelineMutationMutateTimelineStepsArgs = {
  input?: InputMaybe<MutateTimelineStepsInput>;
};

export enum TimelineOrder {
  FORWARD = 'FORWARD',
  REVERSE = 'REVERSE',
}

export type TimelinePayload = {
  __typename?: 'TimelinePayload';
  items?: Maybe<Array<TimelineResponse>>;
};

export type TimelinePayloadResponse = GraphqlError | TimelinePayload;

export type TimelineResponse = GraphqlError | Timeline;

export type TimelineStepsPayload = {
  __typename?: 'TimelineStepsPayload';
  createTimelineSteps?: Maybe<Array<TimelineResponse>>;
  deleteTimelineSteps?: Maybe<Array<BooleanResponse>>;
  updateTimelineSteps?: Maybe<Array<TimelineResponse>>;
};

export type TimelineStepsPayloadResponse = GraphqlError | TimelineStepsPayload;

export type TransferOwnershipInput = {
  transfereeId: Scalars['ID'];
  transferredToId: Scalars['ID'];
};

export type TransferUserInput = {
  transfereeId: Scalars['String'];
  transferredToId: Scalars['String'];
};

export type UnfavoriteMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type UnpinMediaInput = {
  contextId: Scalars['ID'];
  mediaIds: Array<Scalars['ID']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  media: Array<CreateMediaInput>;
  message: Scalars['String'];
};

export type UpdateCompanyInput = {
  companyName?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  extId?: InputMaybe<Scalars['String']>;
};

export type UpdateContactRoleInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateExecutiveSummaryInput = {
  description: Scalars['String'];
};

export type UpdateLovItemInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  displayName?: InputMaybe<Scalars['String']>;
  displayValue?: InputMaybe<Scalars['String']>;
  isDefaultValue?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<ScopeJsonInput>;
  type?: InputMaybe<ScopedUserInfoType>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateMeddicInput = {
  champion?: InputMaybe<UpdateMethodologyQualifierUserInput>;
  decisionCriteria?: InputMaybe<UpdateMethodologyQualifierStringInput>;
  decisionProcess?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
  economicBuyer?: InputMaybe<UpdateMethodologyQualifierUserInput>;
  identifiedPain?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
  metrics?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
};

export type UpdateMeddpiccInput = {
  champion?: InputMaybe<UpdateMethodologyQualifierUserInput>;
  competition?: InputMaybe<UpdateMethodologyQualifierStringInput>;
  decisionCriteria?: InputMaybe<UpdateMethodologyQualifierStringInput>;
  decisionProcess?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
  economicBuyer?: InputMaybe<UpdateMethodologyQualifierUserInput>;
  identifiedPain?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
  metrics?: InputMaybe<UpdateMethodologyQualifierAttributesInput>;
  paperProcess?: InputMaybe<UpdateMethodologyQualifierTaskInput>;
};

export type UpdateMediaInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierAttributesInput = {
  notes?: InputMaybe<Array<InputMaybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: InputMaybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierNotesInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierStringInput = {
  data?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<InputMaybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: InputMaybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierTaskDataInputPayload = {
  id?: InputMaybe<Scalars['String']>;
};

export type UpdateMethodologyQualifierTaskInput = {
  data?: InputMaybe<
    Array<InputMaybe<UpdateMethodologyQualifierTaskDataInputPayload>>
  >;
  notes?: InputMaybe<Array<InputMaybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: InputMaybe<Scalars['Int']>;
};

export type UpdateMethodologyQualifierUserDataInputPayload = {
  id?: InputMaybe<Scalars['ID']>;
  primaryId?: InputMaybe<Scalars['ID']>;
};

export type UpdateMethodologyQualifierUserInput = {
  data?: InputMaybe<
    Array<InputMaybe<UpdateMethodologyQualifierUserDataInputPayload>>
  >;
  notes?: InputMaybe<Array<InputMaybe<UpdateMethodologyQualifierNotesInput>>>;
  score?: InputMaybe<Scalars['Int']>;
};

export type UpdateMilestoneInput = {
  endDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Int']>;
};

export type UpdateMspInput = {
  closeDate?: InputMaybe<Scalars['Int']>;
  contractSignatureDate?: InputMaybe<Scalars['Int']>;
  decisionCriteria?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  economicImpact?: InputMaybe<Scalars['String']>;
  goLiveDate?: InputMaybe<Scalars['Int']>;
  isReverseTimeLineEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<UpdateExecutiveSummaryInput>;
  timelineOrder?: InputMaybe<TimelineOrder>;
  vendorChoiceDate?: InputMaybe<Scalars['Int']>;
};

export type UpdateNotesInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type UpdateNotificationStatusRequestBody = {
  status: NotificationStatus;
  userNotificationStatusId: Scalars['String'];
};

export type UpdateOpportunityInput = {
  amount?: InputMaybe<Scalars['Int']>;
  closeDate?: InputMaybe<Scalars['Timestamp']>;
  contactId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  extCompanyId?: InputMaybe<Scalars['String']>;
  extId?: InputMaybe<Scalars['String']>;
  extOwnerId?: InputMaybe<Scalars['String']>;
  fiscal?: InputMaybe<Scalars['Timestamp']>;
  intCompanyId?: InputMaybe<Scalars['String']>;
  intOwnerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isWon?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  stageName?: InputMaybe<Scalars['String']>;
  systemModStamp?: InputMaybe<Scalars['Timestamp']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  configs: OrgConfigInput;
  orgLogo?: InputMaybe<OrgLogoInput>;
  orgType?: InputMaybe<OrgType>;
  state?: InputMaybe<CompanyState>;
};

export type UpdatePermissionInput = {
  mspId: Scalars['ID'];
  permission: MspInvitePermission;
  userId: Scalars['ID'];
};

export type UpdateRelationshipItemData = {
  __typename?: 'UpdateRelationshipItemData';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateRelationshipItemDataFieldInput = {
  data: Scalars['JSON'];
  fieldContextType: Scalars['String'];
  key: Scalars['String'];
};

export type UpdateRelationshipItemDataInput = {
  fields: Array<UpdateRelationshipItemDataFieldInput>;
  id: Scalars['ID'];
  structureId: Scalars['ID'];
};

export type UpdateRelationshipItemDataPayload = {
  __typename?: 'UpdateRelationshipItemDataPayload';
  items?: Maybe<Array<UpdateRelationshipItemDataResponse>>;
};

export type UpdateRelationshipItemDataPayloadResponse =
  | GraphqlError
  | UpdateRelationshipItemDataPayload;

export type UpdateRelationshipItemDataResponse =
  | GraphqlError
  | UpdateRelationshipItemData;

export type UpdateRoleInput = {
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskInput = {
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTimelineStepsInput = {
  date?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['EmailAddress']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateUspInput = {
  password?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
};

export type UploadAndAttachMediaInput = {
  attachInput: AttachMediaInput;
  uploadInput: UploadMediaInput;
};

export type UploadMediaInput = {
  fileName: Scalars['String'];
  link: Scalars['URL'];
  mediaType: MediaType;
  mimeType: Scalars['String'];
};

export type UpsPayloadResponse = GraphqlError | UspPayload;

export type User = IUser & {
  __typename?: 'User';
  company?: Maybe<CompanyResponse>;
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  primaryId?: Maybe<Scalars['ID']>;
  source: UserSource;
  title?: Maybe<Scalars['String']>;
  userType: UserType;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  activityAt?: Maybe<Scalars['Timestamp']>;
  activityBy?: Maybe<UserResponse>;
  activityById?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  role?: Maybe<RolePayloadResponse>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserConnectionResponse = GraphqlError | UserConnection;

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UserResponse>;
};

export type UserEngagement = {
  __typename?: 'UserEngagement';
  commentCount: Scalars['Int'];
  id: Scalars['String'];
  mediaDownloadCount: Scalars['Int'];
  mediaViewCount: Scalars['Int'];
  mspViewCount: Scalars['Int'];
  user?: Maybe<UserResponse>;
  userTasksAnalytics?: Maybe<UserTaskAnalyticsResponse>;
};

export type UserEngagementConnection = {
  __typename?: 'UserEngagementConnection';
  edges?: Maybe<Array<Maybe<UserEngagementEdge>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserEngagementConnectionResponse =
  | GraphqlError
  | UserEngagementConnection;

export type UserEngagementEdge = {
  __typename?: 'UserEngagementEdge';
  node?: Maybe<UserEngagementResponse>;
};

export type UserEngagementResponse = GraphqlError | UserEngagement;

export type UserIdMap = {
  __typename?: 'UserIdMap';
  id?: Maybe<Scalars['ID']>;
  primaryId?: Maybe<Scalars['ID']>;
};

export type UserIdMapInput = {
  assigneeType?: InputMaybe<AssigneeType>;
  id?: InputMaybe<Scalars['ID']>;
  primaryId?: InputMaybe<Scalars['ID']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  createUser: UserPayloadResponse;
  deleteUser: BooleanResponse;
  transferUser: BooleanResponse;
  updateUser: UserPayloadResponse;
};

export type UserMutationCreateUserArgs = {
  input: CreateUserInput;
};

export type UserMutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type UserMutationTransferUserArgs = {
  input: TransferUserInput;
};

export type UserMutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  items?: Maybe<Array<UserResponse>>;
};

export type UserPayloadResponse = GraphqlError | UserPayload;

export type UserQuery = {
  __typename?: 'UserQuery';
  getUsers: UserPayloadResponse;
};

export type UserQueryGetUsersArgs = {
  ids: Array<Scalars['ID']>;
};

export type UserReferenceMspContext = IMsp & {
  __typename?: 'UserReferenceMspContext';
  buyerCompany: CompanyResponse;
  closeDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: UserResponse;
  description?: Maybe<Scalars['String']>;
  economicImpact?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  isPublished: Scalars['Boolean'];
  methodology?: Maybe<MethodologyPayload>;
  name: Scalars['String'];
  opportunityId?: Maybe<Scalars['ID']>;
  roles?: Maybe<RoleConnectionResponse>;
  startDate: Scalars['Int'];
  tasks?: Maybe<TaskConnectionResponse>;
};

export type UserReferenceMspContextPayload = {
  __typename?: 'UserReferenceMspContextPayload';
  items?: Maybe<Array<Maybe<UserReferenceMspContextResponse>>>;
};

export type UserReferenceMspContextResponse =
  | GraphqlError
  | UserReferenceMspContext;

export type UserReferenceResponse = {
  __typename?: 'UserReferenceResponse';
  mspsContext?: Maybe<UserReferenceMspContextPayload>;
};

export type UserReferencesPayloadResponse =
  | GraphqlError
  | UserReferenceResponse;

export type UserResponse = GraphqlError | User;

export enum UserSource {
  KEYCLOAK = 'KEYCLOAK',
  SALESFORCE = 'SALESFORCE',
}

export type UserTaskAnalytics = {
  __typename?: 'UserTaskAnalytics';
  assignedTask?: Maybe<UserTaskStatusDetails>;
  completedTask?: Maybe<UserTaskStatusDetails>;
  overdueTask?: Maybe<UserTaskStatusDetails>;
};

export type UserTaskAnalyticsResponse = GraphqlError | UserTaskAnalytics;

export type UserTaskStatusDetails = {
  __typename?: 'UserTaskStatusDetails';
  count: Scalars['Int'];
};

export enum UserType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type Usp = {
  __typename?: 'Usp';
  id: Scalars['ID'];
  link: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  passwordVerification: Scalars['Boolean'];
  public: Scalars['Boolean'];
};

export type UspMutation = {
  __typename?: 'UspMutation';
  createUsp: UpsPayloadResponse;
  updateUsp: UpsPayloadResponse;
};

export type UspMutationCreateUspArgs = {
  input: CreateUspInput;
};

export type UspMutationUpdateUspArgs = {
  id: Scalars['ID'];
  input: UpdateUspInput;
};

export type UspPayload = {
  __typename?: 'UspPayload';
  items?: Maybe<Array<UspResponse>>;
};

export type UspResponse = GraphqlError | Usp;

export type Video = Assets &
  Media & {
    __typename?: 'Video';
    analytics?: Maybe<MediaAnalyticsResponse>;
    context?: Maybe<MediaContextResponse>;
    createdAt: Scalars['Timestamp'];
    createdBy: UserResponse;
    description?: Maybe<Scalars['String']>;
    fileAccessUrl?: Maybe<Scalars['URL']>;
    fileDownloadUrl?: Maybe<Scalars['URL']>;
    fileName?: Maybe<Scalars['String']>;
    fileShareUrl?: Maybe<Scalars['URL']>;
    fileSize?: Maybe<Scalars['Int']>;
    formats?: Maybe<Scalars['JSON']>;
    id: Scalars['ID'];
    isInternal?: Maybe<Scalars['Boolean']>;
    mediaType: MediaType;
    mimeType?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    orgId: Scalars['ID'];
    source?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Thumbnail>;
    url: Scalars['URL'];
  };

export type GetOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organizationQuery?: {
    __typename?: 'OrganizationQuery';
    getOrganization:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'OrganizationPayload';
          items?: Array<
            | {
                __typename?: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'Organization';
                orgId: string;
                orgType: OrgType;
                orgName: string;
                state: CompanyState;
                orgLogo?: {
                  __typename?: 'OrgLogo';
                  original?: string;
                  favicon?: string;
                };
                configs: {
                  __typename?: 'OrgConfig';
                  others?: { [key: string]: any };
                  salesforce?: {
                    __typename?: 'SalesforceConfig';
                    isEnabled?: boolean;
                    qualifiedStageNames?: Array<string>;
                  };
                  branding?: {
                    __typename?: 'BrandingConfig';
                    header?: {
                      __typename?: 'BrandingHeader';
                      color?: string;
                      textColor?: string;
                    };
                  };
                  methodology?: {
                    __typename?: 'MethodologyConfig';
                    isEnabled?: boolean;
                    type?: string;
                    displayName?: string;
                  };
                };
              }
          >;
        };
  };
};

export type CreateMspFromTemplateMutationVariables = Exact<{
  input: CreateMspTemplateInput;
  templateId: Scalars['ID'];
}>;

export type CreateMspFromTemplateMutation = {
  __typename?: 'Mutation';
  templateMutation?: {
    __typename?: 'TemplateMutation';
    createMspFromTemplate:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'MspPayload';
          items?: Array<
            | { __typename: 'BaseMsp' }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'Msp';
                id: string;
                name: string;
                description?: string;
                isPublished: boolean;
                closeDate: number;
              }
          >;
        };
  };
};

export type CreateMspMutationVariables = Exact<{
  input: CreateMspInput;
}>;

export type CreateMspMutation = {
  __typename?: 'Mutation';
  mspMutation?: {
    __typename?: 'MspMutation';
    createMsp:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'MspPayload';
          items?: Array<
            | { __typename: 'BaseMsp' }
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'Msp';
                id: string;
                name: string;
                description?: string;
                isPublished: boolean;
                closeDate: number;
              }
          >;
        };
  };
};

export type GetMspAnalyticsHighlightsQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsHighlightsQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsHighlights:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MspAnalyticsHighlights';
          insights?: Array<string>;
          milestones?: {
            __typename?: 'MilestoneHighlights';
            activeMilestones?: Array<{
              __typename?: 'AnalyticsMilestone';
              milestone?: {
                __typename?: 'BaseMilestone';
                name: string;
                startDate: number;
                endDate: number;
              };
              progress?: {
                __typename?: 'AnalyticsMilestoneProgress';
                tasksCount?: number;
                tasksCompleted?: number;
                tasksOverdue?: number;
              };
            }>;
          };
          tasks?: {
            __typename?: 'TaskHighlights';
            upcomingTasks?: Array<{
              __typename?: 'AnalyticsTask';
              task?: {
                __typename?: 'AnalyticsBaseTask';
                id: string;
                name: string;
                status: TaskStatus;
                dueDate: number;
              };
              assignee?:
                | {
                    __typename?: 'AnalyticsUser';
                    firstName?: string;
                    lastName?: string;
                    userType: UserType;
                    email?: string;
                  }
                | { __typename?: 'Placeholder'; name: string };
            }>;
          };
        };
  };
};

export type CreateInfluencerGraphMutationVariables = Exact<{
  input: CreateRelationshipGraphInput;
}>;

export type CreateInfluencerGraphMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: {
    __typename?: 'RelationshipMutation';
    createRelationshipGraph:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'RelationshipGraph';
          viewport?: { [key: string]: any };
          edges?: Array<{
            __typename?: 'GraphEdge';
            id: string;
            source?: string;
            sourceHandle?: string;
            type?: string;
            style?: { [key: string]: any };
            target?: string;
            targetHandle?: string;
            markerEnd?: { [key: string]: any };
            data?:
              | {
                  __typename?: 'GraphItemData';
                  id?: string;
                  dataPayload?: Array<{
                    __typename?: 'DataPayload';
                    key?: string;
                    values?: Array<{
                      __typename?: 'DataPayloadValue';
                      value?: string;
                    }>;
                  }>;
                  metaData?: {
                    __typename?: 'GraphItemDataMetaData';
                    structureId?: string;
                    structureType?: StructureType;
                  };
                }
              | {
                  __typename?: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: string;
                };
          }>;
          nodes?: Array<{
            __typename?: 'GraphNode';
            id: string;
            dragging?: boolean;
            type?: string;
            height?: number;
            width?: number;
            position?: { [key: string]: any };
            positionAbsolute?: { [key: string]: any };
            selected?: boolean;
            data?:
              | {
                  __typename?: 'GraphItemData';
                  id?: string;
                  dataPayload?: Array<{
                    __typename?: 'DataPayload';
                    key?: string;
                    id?: string;
                    values?: Array<{
                      __typename?: 'DataPayloadValue';
                      value?: string;
                    }>;
                  }>;
                  metaData?: {
                    __typename?: 'GraphItemDataMetaData';
                    structureId?: string;
                    structureType?: StructureType;
                  };
                }
              | {
                  __typename?: 'GraphqlError';
                  code: string;
                  message: string;
                  type: ErrorType;
                  helpLink?: string;
                };
          }>;
        };
  };
};

export type CreateNewNoteMutationVariables = Exact<{
  input: AddNotesInput;
}>;

export type CreateNewNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: {
    __typename?: 'AnnotationMutation';
    addNotes:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'NotesPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Notes';
                id: string;
                annotationType: AnnotationType;
                createdAt: any;
                reference: { __typename?: 'Reference'; id: string };
                messageData: {
                  __typename?: 'MessageData';
                  message: string;
                  isEdited: boolean;
                  editedAt?: any;
                };
              }
          >;
        };
  };
};

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: {
    __typename?: 'AnnotationMutation';
    removeNotes:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        };
  };
};

export type UpdateContactFieldsMutationVariables = Exact<{
  input: UpdateRelationshipItemDataInput;
}>;

export type UpdateContactFieldsMutation = {
  __typename?: 'Mutation';
  relationshipMutation?: {
    __typename?: 'RelationshipMutation';
    updateRelationshipItemData:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'UpdateRelationshipItemDataPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'UpdateRelationshipItemData';
                id?: string;
                key?: string;
                success: boolean;
              }
          >;
        };
  };
};

export type UpdateExistingNoteMutationVariables = Exact<{
  input: UpdateNotesInput;
}>;

export type UpdateExistingNoteMutation = {
  __typename?: 'Mutation';
  annotationMutation?: {
    __typename?: 'AnnotationMutation';
    updateNotes:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'NotesPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Notes';
                id: string;
                annotationType: AnnotationType;
                createdAt: any;
                reference: { __typename?: 'Reference'; id: string };
                messageData: { __typename?: 'MessageData'; message: string };
              }
          >;
        };
  };
};

export type GetMspWithMilestonesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMspWithMilestonesQuery = {
  __typename?: 'Query';
  mspQuery?: {
    __typename?: 'MspQuery';
    getMsp:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'MspPayload';
          items?: Array<
            | { __typename?: 'BaseMsp' }
            | {
                __typename?: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename?: 'Msp';
                milestones:
                  | {
                      __typename?: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: string;
                    }
                  | {
                      __typename?: 'MilestoneConnection';
                      totalCount?: number;
                      edges?: Array<{
                        __typename?: 'MilestoneEdge';
                        node?:
                          | { __typename: 'BaseMilestone' }
                          | {
                              __typename: 'GraphqlError';
                              code: string;
                              message: string;
                              type: ErrorType;
                              helpLink?: string;
                            }
                          | {
                              __typename: 'Milestone';
                              id: string;
                              name: string;
                              createdAt: number;
                              startDate: number;
                              endDate: number;
                              isInternal: boolean;
                              milestoneDetails?:
                                | {
                                    __typename?: 'GraphqlError';
                                    code: string;
                                    message: string;
                                    type: ErrorType;
                                    helpLink?: string;
                                  }
                                | {
                                    __typename?: 'MilestoneDetails';
                                    goals?: string;
                                    successCriteria?: string;
                                    riskDependencies?: string;
                                  };
                              tasks:
                                | {
                                    __typename?: 'GraphqlError';
                                    code: string;
                                    message: string;
                                    type: ErrorType;
                                    helpLink?: string;
                                  }
                                | {
                                    __typename?: 'TaskConnection';
                                    edges?: Array<{
                                      __typename?: 'TaskEdge';
                                      node?:
                                        | {
                                            __typename: 'GraphqlError';
                                            code: string;
                                            message: string;
                                            type: ErrorType;
                                            helpLink?: string;
                                          }
                                        | {
                                            __typename: 'Task';
                                            id: string;
                                            name: string;
                                            dueDate: number;
                                            status: TaskStatus;
                                            milestoneId?: string;
                                            isInternal: boolean;
                                            analytics?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  code: string;
                                                  message: string;
                                                  type: ErrorType;
                                                  helpLink?: string;
                                                }
                                              | {
                                                  __typename: 'TaskAnalytics';
                                                  commentCount?:
                                                    | {
                                                        __typename: 'GraphqlError';
                                                      }
                                                    | {
                                                        __typename: 'IntObject';
                                                        value: number;
                                                      };
                                                };
                                            assignee?:
                                              | { __typename?: 'GraphqlError' }
                                              | { __typename?: 'Placeholder' }
                                              | {
                                                  __typename?: 'User';
                                                  id?: string;
                                                  firstName?: string;
                                                  lastName?: string;
                                                  email: string;
                                                  userType: UserType;
                                                };
                                          };
                                    }>;
                                  };
                            };
                      }>;
                    };
              }
          >;
        };
  };
};

export type UpdateMeddpiccForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateMeddpiccInput;
}>;

export type UpdateMeddpiccForMspMutation = {
  __typename?: 'Mutation';
  meddpiccMutation?: {
    __typename?: 'MeddpiccMutation';
    updateMeddpicc:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MeddpiccPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddpicc';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                paperProcess?: {
                  __typename?: 'MethodologyTaskQualifier';
                  current?: {
                    __typename?: 'MethodologyTaskQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'Task';
                          id: string;
                          name: string;
                          dueDate: number;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          email: string;
                          lastName?: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                competition?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type SyncMeddpiccMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: SyncMeddpiccInput;
}>;

export type SyncMeddpiccMutation = {
  __typename?: 'Mutation';
  meddpiccMutation?: {
    __typename?: 'MeddpiccMutation';
    syncMeddpicc:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MeddpiccPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddpicc';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                paperProcess?: {
                  __typename?: 'MethodologyTaskQualifier';
                  current?: {
                    __typename?: 'MethodologyTaskQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'Task';
                          id: string;
                          name: string;
                          dueDate: number;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                competition?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type UpdateMeddicForMspMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: UpdateMeddicInput;
}>;

export type UpdateMeddicForMspMutation = {
  __typename?: 'Mutation';
  meddicMutation?: {
    __typename?: 'MeddicMutation';
    updateMeddic:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MeddicPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddic';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          email: string;
                          lastName?: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type SyncMeddicMutationVariables = Exact<{
  mspId: Scalars['ID'];
  input: SyncMeddicInput;
}>;

export type SyncMeddicMutation = {
  __typename?: 'Mutation';
  meddicMutation?: {
    __typename?: 'MeddicMutation';
    syncMeddic:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MeddicPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddic';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type GetMeddpiccForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMeddpiccForMspQuery = {
  __typename?: 'Query';
  meddpiccQuery?: {
    __typename?: 'MeddpiccQuery';
    getMeddpiccForMsp:
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename?: 'MeddpiccPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddpicc';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                paperProcess?: {
                  __typename?: 'MethodologyTaskQualifier';
                  current?: {
                    __typename?: 'MethodologyTaskQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'Task';
                          id: string;
                          name: string;
                          dueDate: number;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                competition?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type GetMeddicForMspQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMeddicForMspQuery = {
  __typename?: 'Query';
  meddicQuery?: {
    __typename?: 'MeddicQuery';
    getMeddicForMsp:
      | {
          __typename?: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename?: 'MeddicPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Meddic';
                id: string;
                mspId: string;
                analytics?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'MethodologyAnalytics';
                      isSyncEnabled: boolean;
                      syncDisableReason?: string;
                      lastSyncedAt?: any;
                      isSynced: boolean;
                      firstSyncDone: boolean;
                    };
                metrics?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                economicBuyer?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionProcess?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                decisionCriteria?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                identifiedPain?: {
                  __typename?: 'MethodologyStringQualifier';
                  current?: {
                    __typename?: 'MethodologyStringQualifierData';
                    data?: string;
                    score?: number;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
                champion?: {
                  __typename?: 'MethodologyUserQualifier';
                  current?: {
                    __typename?: 'MethodologyUserQualifierData';
                    score?: number;
                    data?: Array<
                      | { __typename: 'GraphqlError' }
                      | {
                          __typename: 'User';
                          id?: string;
                          primaryId?: string;
                          firstName?: string;
                          lastName?: string;
                          email: string;
                        }
                    >;
                    notes: Array<{
                      __typename?: 'MethodologyQualifierNotes';
                      value: string;
                    }>;
                  };
                  analytics?: {
                    __typename?: 'MethodologyQualifierAnalytics';
                    isSynced: boolean;
                  };
                };
              }
          >;
        };
  };
};

export type ListRolesQueryVariables = Exact<{ [key: string]: never }>;

export type ListRolesQuery = {
  __typename?: 'Query';
  roleQuery?: {
    __typename?: 'RoleQuery';
    listRoles:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'RoleConnection';
          edges?: Array<{
            __typename?: 'RoleEdge';
            node?:
              | { __typename?: 'GraphqlError' }
              | {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description?: string;
                  orgId: string;
                  scopeId: string;
                  scopeType: RoleScope;
                  companyType: CompanyType;
                  isHidden: boolean;
                  members?:
                    | { __typename?: 'GraphqlError' }
                    | {
                        __typename?: 'RoleMembers';
                        sellerMembers?:
                          | { __typename?: 'GraphqlError' }
                          | {
                              __typename?: 'Members';
                              stakeholders?: Array<
                                | { __typename?: 'GraphqlError' }
                                | {
                                    __typename?: 'Stakeholder';
                                    id?: string;
                                    firstName?: string;
                                    lastName?: string;
                                    email: string;
                                  }
                              >;
                              placeholders?: Array<
                                | { __typename?: 'GraphqlError' }
                                | {
                                    __typename?: 'Placeholder';
                                    id: string;
                                    name: string;
                                    description?: string;
                                    orgId: string;
                                  }
                              >;
                            };
                        buyerMembers?:
                          | { __typename?: 'GraphqlError' }
                          | {
                              __typename?: 'Members';
                              stakeholders?: Array<
                                | { __typename?: 'GraphqlError' }
                                | {
                                    __typename?: 'Stakeholder';
                                    id?: string;
                                    firstName?: string;
                                    lastName?: string;
                                    email: string;
                                  }
                              >;
                              placeholders?: Array<
                                | { __typename?: 'GraphqlError' }
                                | {
                                    __typename?: 'Placeholder';
                                    id: string;
                                    name: string;
                                    description?: string;
                                    orgId: string;
                                  }
                              >;
                            };
                      };
                };
          }>;
        };
  };
};

export type CreateAndAssignPlaceholderToRolesMutationVariables = Exact<{
  input:
    | Array<CreateAndAssignPlaceholderToRoleInput>
    | CreateAndAssignPlaceholderToRoleInput;
}>;

export type CreateAndAssignPlaceholderToRolesMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    createAndAssignPlaceholdersToRoles:
      | {
          __typename: 'AddOrRemovePlaceholderPayload';
          items?: Array<{
            __typename?: 'AddOrRemovePlaceholderResponse';
            roleId: string;
            placeholderId: string;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type CreateAndAssignPlaceholderToRoleMutationVariables = Exact<{
  input: CreateAndAssignPlaceholderToRoleInput;
}>;

export type CreateAndAssignPlaceholderToRoleMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    createAndAssignPlaceholderToRole:
      | {
          __typename: 'AddOrRemovePlaceholderPayload';
          items?: Array<{
            __typename?: 'AddOrRemovePlaceholderResponse';
            roleId: string;
            placeholderId: string;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type RemovePlaceholderFromRoleMutationVariables = Exact<{
  input: RemovePlaceholderFromRoleInput;
}>;

export type RemovePlaceholderFromRoleMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    removePlaceholderFromRole:
      | { __typename: 'BooleanObject'; id: string }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  roleMutation?: {
    __typename?: 'RoleMutation';
    createRole:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'RolePayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'Role';
                id: string;
                name: string;
                description?: string;
                scopeId: string;
                scopeType: RoleScope;
                createdAt: any;
              }
          >;
        };
  };
};

export type AddStakeholdersToRolesMutationVariables = Exact<{
  input: Array<AddStakeHolderToRoleInput> | AddStakeHolderToRoleInput;
}>;

export type AddStakeholdersToRolesMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    addStakeholdersToRoles:
      | {
          __typename: 'AddOrRemoveStakeholderPayload';
          items?: Array<{
            __typename?: 'AddOrRemoveStakeholderResponse';
            roleId: string;
            stakeholderId: string;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type AddStakeholderToRoleMutationVariables = Exact<{
  input: AddStakeHolderToRoleInput;
}>;

export type AddStakeholderToRoleMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    addStakeholderToRole:
      | {
          __typename: 'AddOrRemoveStakeholderPayload';
          items?: Array<{
            __typename?: 'AddOrRemoveStakeholderResponse';
            roleId: string;
            stakeholderId: string;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type ReplacePlaceholderWithStakeholderMutationVariables = Exact<{
  input: ReplacePlaceholderWithStakeholderInput;
}>;

export type ReplacePlaceholderWithStakeholderMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    replacePlaceholderWithStakeholder:
      | { __typename: 'BooleanObject'; id: string }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type RemoveStakeholderFromRoleMutationVariables = Exact<{
  input: RemoveStakeholderFromRoleInput;
}>;

export type RemoveStakeholderFromRoleMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    removeStakeholderFromRole:
      | { __typename: 'BooleanObject'; id: string }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type CreateAndAssignStakeholderToRoleMutationVariables = Exact<{
  input: CreateAndAssignStakeholderToRoleInput;
}>;

export type CreateAndAssignStakeholderToRoleMutation = {
  __typename?: 'Mutation';
  memberMutation?: {
    __typename?: 'MemberMutation';
    createAndAssignStakeholderToRole:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'UserPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | { __typename?: 'User'; id?: string }
          >;
        };
  };
};

export type CreateExternalLinkMutationMutationVariables = Exact<{
  input: ExternalLinkInput;
}>;

export type CreateExternalLinkMutationMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    createExternalLink:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | { __typename: 'MediaExternalLink'; url?: string };
  };
};

export type FavoriteResourceMutationVariables = Exact<{
  input: FavoriteMediaInput;
}>;

export type FavoriteResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    favoriteMedia:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        };
  };
};

export type PinResourceMutationVariables = Exact<{
  input: PinMediaInput;
}>;

export type PinResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    pinMedia:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type RenameFileMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;

export type RenameFileMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    updateMedia:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'MediaPayload';
          items?: Array<
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'MediaPayloadItem';
                item:
                  | {
                      __typename?: 'Audio';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Document';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Image';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Link';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Logo';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Thumbnail';
                      id: string;
                      name?: string;
                      description?: string;
                    }
                  | {
                      __typename?: 'Video';
                      id: string;
                      name?: string;
                      description?: string;
                    };
              }
          >;
        };
  };
};

export type RevokeExternalAccessMutationVariables = Exact<{
  input: RevokeMediaExternalAccessInput;
}>;

export type RevokeExternalAccessMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    revokeExternalAccess:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        };
  };
};

export type UnFavoriteResourceMutationVariables = Exact<{
  input: UnfavoriteMediaInput;
}>;

export type UnFavoriteResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    unfavoriteMedia:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        };
  };
};

export type UnpinResourceMutationVariables = Exact<{
  input: UnpinMediaInput;
}>;

export type UnpinResourceMutation = {
  __typename?: 'Mutation';
  mediaMutation?: {
    __typename?: 'MediaMutation';
    unpinMedia:
      | { __typename: 'BooleanObject'; id: string; success: boolean }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        };
  };
};

export type GetStakeholderUserEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: InputMaybe<GetUserEngagementRequest>;
}>;

export type GetStakeholderUserEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getUserEngagement:
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        }
      | {
          __typename?: 'UserEngagementConnection';
          edges?: Array<{
            __typename?: 'UserEngagementEdge';
            node?:
              | { __typename: 'GraphqlError' }
              | {
                  __typename: 'UserEngagement';
                  id: string;
                  mspViewCount: number;
                  mediaViewCount: number;
                  mediaDownloadCount: number;
                  commentCount: number;
                  user?:
                    | { __typename: 'GraphqlError' }
                    | { __typename: 'User'; firstName?: string };
                  userTasksAnalytics?:
                    | { __typename?: 'GraphqlError' }
                    | {
                        __typename?: 'UserTaskAnalytics';
                        overdueTask?: {
                          __typename?: 'UserTaskStatusDetails';
                          count: number;
                        };
                        assignedTask?: {
                          __typename?: 'UserTaskStatusDetails';
                          count: number;
                        };
                        completedTask?: {
                          __typename?: 'UserTaskStatusDetails';
                          count: number;
                        };
                      };
                };
          }>;
        };
  };
};

type AssetsFragmentAudioFragment = {
  __typename: 'Audio';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

type AssetsFragmentDocumentFragment = {
  __typename: 'Document';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

type AssetsFragmentImageFragment = {
  __typename: 'Image';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

type AssetsFragmentVideoFragment = {
  __typename: 'Video';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type AssetsFragmentFragment =
  | AssetsFragmentAudioFragment
  | AssetsFragmentDocumentFragment
  | AssetsFragmentImageFragment
  | AssetsFragmentVideoFragment;

export type ImageFragmentFragment = {
  __typename: 'Image';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type AudioFragmentFragment = {
  __typename: 'Audio';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type DocumentFragmentFragment = {
  __typename: 'Document';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type VideoFragmentFragment = {
  __typename: 'Video';
  url: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  isInternal?: boolean;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type LinkFragmentFragment = {
  __typename: 'Link';
  fileSize?: number;
  fileName?: string;
  source?: string;
  fileShareUrl?: string;
  fileAccessUrl?: string;
  fileDownloadUrl?: string;
  isInternal?: boolean;
  link: string;
  name?: string;
  analytics?:
    | {
        __typename: 'GraphqlError';
        type: ErrorType;
        code: string;
        helpLink?: string;
        message: string;
      }
    | {
        __typename: 'MediaAnalytics';
        belongsTo?:
          | {
              __typename: 'GraphqlError';
              type: ErrorType;
              code: string;
              helpLink?: string;
              message: string;
            }
          | {
              __typename: 'MediaBelongsTo';
              id: string;
              name: string;
              displayName: string;
              objectType: string;
            };
      };
  context?:
    | { __typename: 'GraphqlError' }
    | { __typename: 'MediaContext'; isPin?: boolean; isFavorite?: boolean };
};

export type GetMspEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMspEngagement?:
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        }
      | {
          __typename?: 'MspEngagementDetails';
          internalUserEngagement?: {
            __typename?: 'MspEngagement';
            lastActivity?: {
              __typename?: 'LatestActivity';
              activityAt?: any;
              activityById?: string;
              activityBy?:
                | { __typename: 'GraphqlError' }
                | {
                    __typename: 'User';
                    id?: string;
                    firstName?: string;
                    lastName?: string;
                    email: string;
                    userType: UserType;
                  };
              role?:
                | { __typename?: 'GraphqlError' }
                | {
                    __typename?: 'RolePayload';
                    items?: Array<
                      | {
                          __typename: 'GraphqlError';
                          type: ErrorType;
                          code: string;
                          message: string;
                        }
                      | { __typename?: 'Role'; name: string }
                    >;
                  };
            };
            user?: { __typename?: 'MspEngagementUserDetails'; count: number };
            msp?: { __typename?: 'EngagementCount'; viewCount?: number };
            comment?: { __typename?: 'EngagementCount'; commentCount?: number };
          };
          externalUserEngagement?: {
            __typename?: 'MspEngagement';
            lastActivity?: {
              __typename?: 'LatestActivity';
              activityAt?: any;
              activityById?: string;
              activityBy?:
                | { __typename: 'GraphqlError' }
                | {
                    __typename: 'User';
                    id?: string;
                    firstName?: string;
                    lastName?: string;
                    email: string;
                    userType: UserType;
                  };
              role?:
                | { __typename?: 'GraphqlError' }
                | {
                    __typename?: 'RolePayload';
                    items?: Array<
                      | {
                          __typename: 'GraphqlError';
                          type: ErrorType;
                          code: string;
                          message: string;
                        }
                      | { __typename?: 'Role'; name: string }
                    >;
                  };
            };
            user?: {
              __typename?: 'MspEngagementUserDetails';
              count: number;
              engagementUsers?:
                | { __typename?: 'GraphqlError' }
                | {
                    __typename?: 'MspEngagmentUsersLatestActivity';
                    items?: Array<{
                      __typename?: 'UserActivity';
                      activityAt?: any;
                      activityById?: string;
                      activityBy?:
                        | { __typename: 'GraphqlError' }
                        | { __typename: 'User'; firstName?: string };
                      role?:
                        | { __typename?: 'GraphqlError' }
                        | {
                            __typename?: 'RolePayload';
                            items?: Array<
                              | {
                                  __typename: 'GraphqlError';
                                  type: ErrorType;
                                  code: string;
                                  message: string;
                                }
                              | { __typename?: 'Role'; name: string }
                            >;
                          };
                    }>;
                  };
            };
            msp?: { __typename?: 'EngagementCount'; viewCount?: number };
            comment?: { __typename?: 'EngagementCount'; commentCount?: number };
          };
        };
  };
};

export type GetMediaEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: InputMaybe<GetMediaEngagementRequest>;
}>;

export type GetMediaEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMediaEngagement:
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        }
      | {
          __typename?: 'MediaEngagementConnection';
          edges?: Array<{
            __typename?: 'MediaEngagementEdge';
            node?:
              | { __typename: 'GraphqlError' }
              | {
                  __typename: 'MediaEngagement';
                  id: string;
                  downloadCount: number;
                  viewCount: number;
                  media?:
                    | { __typename: 'GraphqlError' }
                    | {
                        __typename: 'MediaConnection';
                        edges?: Array<{
                          __typename?: 'MediaEdge';
                          node?:
                            | { __typename: 'GraphqlError' }
                            | {
                                __typename: 'MediaPayloadItem';
                                item:
                                  | {
                                      __typename: 'Audio';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      url: string;
                                      fileAccessUrl?: string;
                                      fileDownloadUrl?: string;
                                      fileSize?: number;
                                      fileName?: string;
                                      source?: string;
                                      fileShareUrl?: string;
                                      isInternal?: boolean;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                      analytics?:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'MediaAnalytics';
                                            belongsTo?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  type: ErrorType;
                                                  code: string;
                                                  helpLink?: string;
                                                  message: string;
                                                }
                                              | {
                                                  __typename: 'MediaBelongsTo';
                                                  id: string;
                                                  name: string;
                                                  displayName: string;
                                                  objectType: string;
                                                };
                                          };
                                      context?:
                                        | { __typename: 'GraphqlError' }
                                        | {
                                            __typename: 'MediaContext';
                                            isPin?: boolean;
                                            isFavorite?: boolean;
                                          };
                                    }
                                  | {
                                      __typename: 'Document';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      url: string;
                                      fileAccessUrl?: string;
                                      fileDownloadUrl?: string;
                                      fileSize?: number;
                                      fileName?: string;
                                      source?: string;
                                      fileShareUrl?: string;
                                      isInternal?: boolean;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                      analytics?:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'MediaAnalytics';
                                            belongsTo?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  type: ErrorType;
                                                  code: string;
                                                  helpLink?: string;
                                                  message: string;
                                                }
                                              | {
                                                  __typename: 'MediaBelongsTo';
                                                  id: string;
                                                  name: string;
                                                  displayName: string;
                                                  objectType: string;
                                                };
                                          };
                                      context?:
                                        | { __typename: 'GraphqlError' }
                                        | {
                                            __typename: 'MediaContext';
                                            isPin?: boolean;
                                            isFavorite?: boolean;
                                          };
                                    }
                                  | {
                                      __typename: 'Image';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      url: string;
                                      fileAccessUrl?: string;
                                      fileDownloadUrl?: string;
                                      fileSize?: number;
                                      fileName?: string;
                                      source?: string;
                                      fileShareUrl?: string;
                                      isInternal?: boolean;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                      analytics?:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'MediaAnalytics';
                                            belongsTo?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  type: ErrorType;
                                                  code: string;
                                                  helpLink?: string;
                                                  message: string;
                                                }
                                              | {
                                                  __typename: 'MediaBelongsTo';
                                                  id: string;
                                                  name: string;
                                                  displayName: string;
                                                  objectType: string;
                                                };
                                          };
                                      context?:
                                        | { __typename: 'GraphqlError' }
                                        | {
                                            __typename: 'MediaContext';
                                            isPin?: boolean;
                                            isFavorite?: boolean;
                                          };
                                    }
                                  | {
                                      __typename: 'Link';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      fileSize?: number;
                                      fileName?: string;
                                      source?: string;
                                      fileShareUrl?: string;
                                      fileAccessUrl?: string;
                                      fileDownloadUrl?: string;
                                      isInternal?: boolean;
                                      link: string;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                      analytics?:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'MediaAnalytics';
                                            belongsTo?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  type: ErrorType;
                                                  code: string;
                                                  helpLink?: string;
                                                  message: string;
                                                }
                                              | {
                                                  __typename: 'MediaBelongsTo';
                                                  id: string;
                                                  name: string;
                                                  displayName: string;
                                                  objectType: string;
                                                };
                                          };
                                      context?:
                                        | { __typename: 'GraphqlError' }
                                        | {
                                            __typename: 'MediaContext';
                                            isPin?: boolean;
                                            isFavorite?: boolean;
                                          };
                                    }
                                  | {
                                      __typename: 'Logo';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                    }
                                  | {
                                      __typename: 'Thumbnail';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                    }
                                  | {
                                      __typename: 'Video';
                                      id: string;
                                      name?: string;
                                      orgId: string;
                                      description?: string;
                                      mediaType: MediaType;
                                      mimeType?: string;
                                      createdAt: any;
                                      url: string;
                                      fileAccessUrl?: string;
                                      fileDownloadUrl?: string;
                                      fileSize?: number;
                                      fileName?: string;
                                      source?: string;
                                      fileShareUrl?: string;
                                      isInternal?: boolean;
                                      createdBy:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'User';
                                            id?: string;
                                            firstName?: string;
                                            lastName?: string;
                                            email: string;
                                            userType: UserType;
                                          };
                                      analytics?:
                                        | {
                                            __typename: 'GraphqlError';
                                            type: ErrorType;
                                            code: string;
                                            helpLink?: string;
                                            message: string;
                                          }
                                        | {
                                            __typename: 'MediaAnalytics';
                                            belongsTo?:
                                              | {
                                                  __typename: 'GraphqlError';
                                                  type: ErrorType;
                                                  code: string;
                                                  helpLink?: string;
                                                  message: string;
                                                }
                                              | {
                                                  __typename: 'MediaBelongsTo';
                                                  id: string;
                                                  name: string;
                                                  displayName: string;
                                                  objectType: string;
                                                };
                                          };
                                      context?:
                                        | { __typename: 'GraphqlError' }
                                        | {
                                            __typename: 'MediaContext';
                                            isPin?: boolean;
                                            isFavorite?: boolean;
                                          };
                                    };
                              };
                        }>;
                      };
                };
          }>;
        };
  };
};

export type GetUserEngagementQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input?: InputMaybe<GetUserEngagementRequest>;
}>;

export type GetUserEngagementQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getUserEngagement:
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        }
      | {
          __typename?: 'UserEngagementConnection';
          edges?: Array<{
            __typename?: 'UserEngagementEdge';
            node?:
              | { __typename: 'GraphqlError' }
              | {
                  __typename: 'UserEngagement';
                  id: string;
                  mspViewCount: number;
                  mediaViewCount: number;
                  mediaDownloadCount: number;
                  commentCount: number;
                  user?:
                    | { __typename: 'GraphqlError' }
                    | {
                        __typename: 'User';
                        firstName?: string;
                        lastName?: string;
                        email: string;
                        userType: UserType;
                      };
                };
          }>;
        };
  };
};

export type GetMspAnalyticsMetricsQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsMetricsQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsMetrics:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MspMetrics';
          mspTrack?: {
            __typename?: 'MspTrack';
            mspTrackStatus: MspTrackStatus;
            mspSlip?: number;
            mspSlipDisplayTime?: string;
          };
          lastActivity?: {
            __typename?: 'AnalyticsActivity';
            activityAt?: any;
            activityById?: string;
            message?: string;
            activityByUser?:
              | { __typename?: 'GraphqlError' }
              | { __typename?: 'User'; firstName?: string; lastName?: string };
          };
          lastExternalActivity?: {
            __typename?: 'AnalyticsActivity';
            activityAt?: any;
            activityById?: string;
            activityByUser?:
              | { __typename?: 'GraphqlError' }
              | { __typename?: 'User'; lastName?: string; firstName?: string };
          };
          externalStakeholders?:
            | { __typename?: 'GraphqlError' }
            | {
                __typename?: 'MspAnalyticsStakeholders';
                stakeholders?: Array<{
                  __typename: 'AnalyticsUser';
                  id?: string;
                  firstName?: string;
                  lastName?: string;
                  userType: UserType;
                  email?: string;
                }>;
                subscribers?: Array<{
                  __typename?: 'AnalyticsUser';
                  id?: string;
                  firstName?: string;
                  lastName?: string;
                  userType: UserType;
                  email?: string;
                }>;
              };
        };
  };
};

export type GetActivityTimelineQueryVariables = Exact<{
  input: GetActivityTimelineRequestBody;
  pageConfiguration?: InputMaybe<PageConfiguration>;
}>;

export type GetActivityTimelineQuery = {
  __typename?: 'Query';
  activityTimelineQuery?: {
    __typename?: 'ActivityTimelineQuery';
    getActivityTimeline:
      | {
          __typename?: 'ActivityTimelineConnection';
          pageInfo: {
            __typename: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            afterCursor?: string;
            beforeCursor?: string;
          };
          edges?: Array<{
            __typename?: 'ActivityTimelineEdge';
            node?:
              | {
                  __typename: 'ActivityTimeline';
                  message: string;
                  createdAt: any;
                  eventType: string;
                  authorizer: {
                    __typename?: 'ActivityTimelineAuthorizerDetails';
                    name?: string;
                    type?: string;
                  };
                  task?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  milestone?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  comment?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  media?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  role?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  stakeholder?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  methodology?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  opportunity?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  user?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  msp?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                  mspTemplate?: Array<{
                    __typename?: 'ActivityTimelineResourceDetails';
                    name?: string;
                    type?: string;
                    updateFields?: Array<{
                      __typename?: 'ActivityTimelineResourceUpdateFields';
                      property?: string;
                      value?: string;
                    }>;
                  }>;
                }
              | { __typename: 'GraphqlError' };
          }>;
          activityTimelineHighlights?: Array<{
            __typename?: 'ActivityTimelineHighlights';
            eventType: string;
            count: number;
          }>;
        }
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        };
  };
};

export type GetMspAnalyticsTimelineQueryVariables = Exact<{
  mspId: Scalars['ID'];
}>;

export type GetMspAnalyticsTimelineQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMspAnalyticsTimeline:
      | {
          __typename: 'GraphqlError';
          code: string;
          type: ErrorType;
          message: string;
        }
      | {
          __typename: 'MspAnalyticsTimeline';
          mspId: string;
          lastUpdatedAt: any;
          plannedCompletionDate: any;
          timeline?: Array<{
            __typename?: 'MspAnalyticsTimelineData';
            timestamp: any;
            mspProgress?: number;
            plannedMspProgress?: number;
            timeParameterDisplayName: string;
          }>;
        };
  };
};

export type GetMspEngagementTimelineQueryVariables = Exact<{
  mspId: Scalars['ID'];
  input: MspAnalyticsTimelineQueryOptions;
}>;

export type GetMspEngagementTimelineQuery = {
  __typename?: 'Query';
  mspAnalyticsQuery?: {
    __typename?: 'MspAnalyticsQuery';
    getMspEngagementTimeline:
      | {
          __typename: 'GraphqlError';
          type: ErrorType;
          code: string;
          helpLink?: string;
          message: string;
        }
      | {
          __typename?: 'MspEngagementTimeline';
          mspId: string;
          timeline?: Array<{
            __typename?: 'MspEngagementTimelineData';
            timestamp: any;
            engagementCount: number;
            buyerEngagementCount?: number;
            sellerEngagementCount?: number;
            userCount?: number;
            buyerUserIds?: Array<string>;
            sellerUserIds?: Array<string>;
            timeParameterDisplayName: string;
          }>;
        };
  };
};

export type CoreErrorFieldsFragmentFragment = {
  __typename?: 'GraphqlError';
  code: string;
  message: string;
  type: ErrorType;
  helpLink?: string;
};

export type MilestoneDetailsFragmentFragment = {
  __typename?: 'Milestone';
  id: string;
  name: string;
  createdAt: number;
  startDate: number;
  endDate: number;
  isInternal: boolean;
  milestoneDetails?:
    | {
        __typename?: 'GraphqlError';
        code: string;
        message: string;
        type: ErrorType;
        helpLink?: string;
      }
    | {
        __typename?: 'MilestoneDetails';
        goals?: string;
        successCriteria?: string;
        riskDependencies?: string;
      };
  tasks:
    | {
        __typename?: 'GraphqlError';
        code: string;
        message: string;
        type: ErrorType;
        helpLink?: string;
      }
    | {
        __typename?: 'TaskConnection';
        edges?: Array<{
          __typename?: 'TaskEdge';
          node?:
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'Task';
                id: string;
                name: string;
                dueDate: number;
                status: TaskStatus;
                milestoneId?: string;
                isInternal: boolean;
                analytics?:
                  | {
                      __typename: 'GraphqlError';
                      code: string;
                      message: string;
                      type: ErrorType;
                      helpLink?: string;
                    }
                  | {
                      __typename: 'TaskAnalytics';
                      commentCount?:
                        | { __typename: 'GraphqlError' }
                        | { __typename: 'IntObject'; value: number };
                    };
                assignee?:
                  | { __typename?: 'GraphqlError' }
                  | { __typename?: 'Placeholder' }
                  | {
                      __typename?: 'User';
                      id?: string;
                      firstName?: string;
                      lastName?: string;
                      email: string;
                      userType: UserType;
                    };
              };
        }>;
      };
};

export type TaskConnectionFragmentFragment = {
  __typename?: 'TaskConnection';
  edges?: Array<{
    __typename?: 'TaskEdge';
    node?:
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: ErrorType;
          helpLink?: string;
        }
      | {
          __typename: 'Task';
          id: string;
          name: string;
          dueDate: number;
          status: TaskStatus;
          milestoneId?: string;
          isInternal: boolean;
          analytics?:
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: ErrorType;
                helpLink?: string;
              }
            | {
                __typename: 'TaskAnalytics';
                commentCount?:
                  | { __typename: 'GraphqlError' }
                  | { __typename: 'IntObject'; value: number };
              };
          assignee?:
            | { __typename?: 'GraphqlError' }
            | { __typename?: 'Placeholder' }
            | {
                __typename?: 'User';
                id?: string;
                firstName?: string;
                lastName?: string;
                email: string;
                userType: UserType;
              };
        };
  }>;
};

export const AssetsFragmentFragmentDoc = `
    fragment AssetsFragment on Assets {
  url
  fileAccessUrl
  fileDownloadUrl
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  __typename
}
    `;
export const ImageFragmentFragmentDoc = `
    fragment ImageFragment on Image {
  url
  fileAccessUrl
  fileDownloadUrl
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  __typename
}
    `;
export const AudioFragmentFragmentDoc = `
    fragment AudioFragment on Audio {
  url
  fileAccessUrl
  fileDownloadUrl
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  __typename
}
    `;
export const DocumentFragmentFragmentDoc = `
    fragment DocumentFragment on Document {
  url
  fileAccessUrl
  fileDownloadUrl
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  __typename
}
    `;
export const VideoFragmentFragmentDoc = `
    fragment VideoFragment on Video {
  url
  fileAccessUrl
  fileDownloadUrl
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  __typename
}
    `;
export const LinkFragmentFragmentDoc = `
    fragment LinkFragment on Link {
  fileSize
  fileName
  source
  fileShareUrl
  fileAccessUrl
  fileDownloadUrl
  isInternal
  analytics {
    ... on GraphqlError {
      __typename
      type
      code
      helpLink
      message
    }
    ... on MediaAnalytics {
      belongsTo {
        ... on GraphqlError {
          __typename
          type
          code
          helpLink
          message
        }
        ... on MediaBelongsTo {
          id
          name
          displayName
          objectType
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  context {
    ... on MediaContext {
      isPin
      isFavorite
      __typename
    }
    __typename
  }
  link
  name
  __typename
}
    `;
export const CoreErrorFieldsFragmentFragmentDoc = `
    fragment CoreErrorFieldsFragment on GraphqlError {
  code
  message
  type
  helpLink
}
    `;
export const TaskConnectionFragmentFragmentDoc = `
    fragment TaskConnectionFragment on TaskConnection {
  edges {
    node {
      __typename
      ...CoreErrorFieldsFragment
      ... on Task {
        id
        name
        dueDate
        status
        milestoneId
        isInternal
        analytics {
          __typename
          ...CoreErrorFieldsFragment
          ... on TaskAnalytics {
            commentCount {
              __typename
              ... on IntObject {
                value
              }
            }
          }
        }
        assignee {
          ... on User {
            id
            firstName
            lastName
            email
            userType
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const MilestoneDetailsFragmentFragmentDoc = `
    fragment MilestoneDetailsFragment on Milestone {
  id
  name
  createdAt
  startDate
  endDate
  isInternal
  milestoneDetails {
    ...CoreErrorFieldsFragment
    ... on MilestoneDetails {
      goals
      successCriteria
      riskDependencies
    }
  }
  tasks {
    ...CoreErrorFieldsFragment
    ...TaskConnectionFragment
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}
${TaskConnectionFragmentFragmentDoc}`;
export const GetOrganizationDocument = `
    query GetOrganization($orgId: ID!) {
  organizationQuery {
    getOrganization(orgId: $orgId) {
      __typename
      ... on GraphqlError {
        code
        message
        type
        helpLink
      }
      ... on OrganizationPayload {
        items {
          ... on GraphqlError {
            code
            message
            type
            helpLink
          }
          ... on Organization {
            __typename
            orgId
            orgType
            orgName
            state
            orgLogo {
              original
              favicon
            }
            configs {
              others
              salesforce {
                isEnabled
                qualifiedStageNames
              }
              branding {
                header {
                  color
                  textColor
                }
              }
              methodology {
                isEnabled
                type
                displayName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const CreateMspFromTemplateDocument = `
    mutation CreateMspFromTemplate($input: CreateMspTemplateInput!, $templateId: ID!) {
  templateMutation {
    createMspFromTemplate(templateId: $templateId, input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on MspPayload {
        items {
          __typename
          ...CoreErrorFieldsFragment
          ... on Msp {
            id
            name
            description
            isPublished
            closeDate
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const CreateMspDocument = `
    mutation CreateMsp($input: CreateMspInput!) {
  mspMutation {
    createMsp(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on MspPayload {
        items {
          __typename
          ...CoreErrorFieldsFragment
          ... on Msp {
            id
            name
            description
            isPublished
            closeDate
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const GetMspAnalyticsHighlightsDocument = `
    query getMspAnalyticsHighlights($mspId: ID!) {
  mspAnalyticsQuery {
    getMspAnalyticsHighlights(mspId: $mspId) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MspAnalyticsHighlights {
        milestones {
          ... on MilestoneHighlights {
            activeMilestones {
              ... on AnalyticsMilestone {
                milestone {
                  ... on BaseMilestone {
                    name
                    startDate
                    endDate
                  }
                }
                progress {
                  ... on AnalyticsMilestoneProgress {
                    tasksCount
                    tasksCompleted
                    tasksOverdue
                  }
                }
              }
            }
          }
        }
        tasks {
          ... on TaskHighlights {
            upcomingTasks {
              ... on AnalyticsTask {
                task {
                  id
                  name
                  status
                  dueDate
                }
                assignee {
                  ... on AnalyticsUser {
                    firstName
                    lastName
                    userType
                    email
                  }
                  ... on Placeholder {
                    name
                  }
                }
              }
            }
          }
        }
        insights
      }
    }
  }
}
    `;
export const CreateInfluencerGraphDocument = `
    mutation createInfluencerGraph($input: CreateRelationshipGraphInput!) {
  relationshipMutation {
    createRelationshipGraph(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on RelationshipGraph {
        edges {
          id
          source
          sourceHandle
          type
          style
          target
          targetHandle
          markerEnd
          data {
            ...CoreErrorFieldsFragment
            ... on GraphItemData {
              id
              dataPayload {
                key
                values {
                  value
                }
              }
              metaData {
                structureId
                structureType
              }
            }
          }
        }
        viewport
        nodes {
          id
          dragging
          type
          height
          width
          position
          positionAbsolute
          selected
          data {
            ...CoreErrorFieldsFragment
            ... on GraphItemData {
              id
              dataPayload {
                key
                id
                values {
                  value
                }
              }
              metaData {
                structureId
                structureType
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const CreateNewNoteDocument = `
    mutation createNewNote($input: AddNotesInput!) {
  annotationMutation {
    addNotes(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on NotesPayload {
        items {
          ... on Notes {
            id
            annotationType
            reference {
              id
            }
            messageData {
              message
              isEdited
              editedAt
            }
            createdAt
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const DeleteNoteDocument = `
    mutation deleteNote($id: ID!) {
  annotationMutation {
    removeNotes(id: $id) {
      __typename
      ...CoreErrorFieldsFragment
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const UpdateContactFieldsDocument = `
    mutation updateContactFields($input: UpdateRelationshipItemDataInput!) {
  relationshipMutation {
    updateRelationshipItemData(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on UpdateRelationshipItemDataPayload {
        items {
          ... on UpdateRelationshipItemData {
            id
            key
            success
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const UpdateExistingNoteDocument = `
    mutation updateExistingNote($input: UpdateNotesInput!) {
  annotationMutation {
    updateNotes(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on NotesPayload {
        items {
          ... on Notes {
            id
            annotationType
            reference {
              id
            }
            messageData {
              message
            }
            createdAt
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const GetMspWithMilestonesDocument = `
    query GetMspWithMilestones($id: ID!) {
  mspQuery {
    getMsp(id: $id) {
      __typename
      ...CoreErrorFieldsFragment
      ... on MspPayload {
        items {
          ...CoreErrorFieldsFragment
          ... on Msp {
            milestones {
              ...CoreErrorFieldsFragment
              ... on MilestoneConnection {
                edges {
                  node {
                    __typename
                    ...CoreErrorFieldsFragment
                    ...MilestoneDetailsFragment
                  }
                }
                totalCount
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}
${MilestoneDetailsFragmentFragmentDoc}`;
export const UpdateMeddpiccForMspDocument = `
    mutation updateMeddpiccForMsp($mspId: ID!, $input: UpdateMeddpiccInput!) {
  meddpiccMutation {
    updateMeddpicc(mspId: $mspId, input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddpiccPayload {
        items {
          ... on Meddpicc {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            paperProcess {
              current {
                data {
                  __typename
                  ... on Task {
                    id
                    name
                    dueDate
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    email
                    lastName
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            competition {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const SyncMeddpiccDocument = `
    mutation syncMeddpicc($mspId: ID!, $input: SyncMeddpiccInput!) {
  meddpiccMutation {
    syncMeddpicc(mspId: $mspId, input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddpiccPayload {
        items {
          ... on Meddpicc {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            paperProcess {
              current {
                data {
                  __typename
                  ... on Task {
                    id
                    name
                    dueDate
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            competition {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const UpdateMeddicForMspDocument = `
    mutation updateMeddicForMsp($mspId: ID!, $input: UpdateMeddicInput!) {
  meddicMutation {
    updateMeddic(mspId: $mspId, input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddicPayload {
        items {
          ... on Meddic {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    email
                    lastName
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const SyncMeddicDocument = `
    mutation syncMeddic($mspId: ID!, $input: SyncMeddicInput!) {
  meddicMutation {
    syncMeddic(mspId: $mspId, input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddicPayload {
        items {
          ... on Meddic {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMeddpiccForMspDocument = `
    query getMeddpiccForMsp($mspId: ID!) {
  meddpiccQuery {
    getMeddpiccForMsp(mspId: $mspId) {
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddpiccPayload {
        items {
          ... on Meddpicc {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            paperProcess {
              current {
                data {
                  __typename
                  ... on Task {
                    id
                    name
                    dueDate
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            competition {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMeddicForMspDocument = `
    query getMeddicForMsp($mspId: ID!) {
  meddicQuery {
    getMeddicForMsp(mspId: $mspId) {
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MeddicPayload {
        items {
          ... on Meddic {
            id
            mspId
            analytics {
              ... on MethodologyAnalytics {
                isSyncEnabled
                syncDisableReason
                lastSyncedAt
                isSynced
                firstSyncDone
              }
            }
            metrics {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            economicBuyer {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionProcess {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            decisionCriteria {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            identifiedPain {
              current {
                data
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
            champion {
              current {
                data {
                  __typename
                  ... on User {
                    id
                    primaryId
                    firstName
                    lastName
                    email
                  }
                }
                notes {
                  value
                }
                score
              }
              analytics {
                ... on MethodologyQualifierAnalytics {
                  isSynced
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const ListRolesDocument = `
    query listRoles {
  roleQuery {
    listRoles {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on RoleConnection {
        edges {
          node {
            ... on Role {
              id
              name
              description
              orgId
              scopeId
              scopeType
              companyType
              isHidden
              members {
                ... on RoleMembers {
                  sellerMembers {
                    ... on Members {
                      stakeholders {
                        ... on Stakeholder {
                          id
                          firstName
                          lastName
                          email
                        }
                      }
                      placeholders {
                        ... on Placeholder {
                          id
                          name
                          description
                          orgId
                        }
                      }
                    }
                  }
                  buyerMembers {
                    ... on Members {
                      stakeholders {
                        ... on Stakeholder {
                          id
                          firstName
                          lastName
                          email
                        }
                      }
                      placeholders {
                        ... on Placeholder {
                          id
                          name
                          description
                          orgId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const CreateAndAssignPlaceholderToRolesDocument = `
    mutation createAndAssignPlaceholderToRoles($input: [CreateAndAssignPlaceholderToRoleInput!]!) {
  memberMutation {
    createAndAssignPlaceholdersToRoles(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on AddOrRemovePlaceholderPayload {
        items {
          roleId
          placeholderId
        }
      }
    }
  }
}
    `;
export const CreateAndAssignPlaceholderToRoleDocument = `
    mutation createAndAssignPlaceholderToRole($input: CreateAndAssignPlaceholderToRoleInput!) {
  memberMutation {
    createAndAssignPlaceholderToRole(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on AddOrRemovePlaceholderPayload {
        items {
          roleId
          placeholderId
        }
      }
    }
  }
}
    `;
export const RemovePlaceholderFromRoleDocument = `
    mutation removePlaceholderFromRole($input: RemovePlaceholderFromRoleInput!) {
  memberMutation {
    removePlaceholderFromRole(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on BooleanObject {
        id
      }
    }
  }
}
    `;
export const CreateRoleDocument = `
    mutation createRole($input: CreateRoleInput!) {
  roleMutation {
    createRole(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on RolePayload {
        items {
          ... on Role {
            id
            name
            description
            scopeId
            scopeType
            description
            createdAt
          }
        }
      }
    }
  }
}
    `;
export const AddStakeholdersToRolesDocument = `
    mutation addStakeholdersToRoles($input: [AddStakeHolderToRoleInput!]!) {
  memberMutation {
    addStakeholdersToRoles(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on AddOrRemoveStakeholderPayload {
        items {
          roleId
          stakeholderId
        }
      }
    }
  }
}
    `;
export const AddStakeholderToRoleDocument = `
    mutation addStakeholderToRole($input: AddStakeHolderToRoleInput!) {
  memberMutation {
    addStakeholderToRole(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on AddOrRemoveStakeholderPayload {
        items {
          roleId
          stakeholderId
        }
      }
    }
  }
}
    `;
export const ReplacePlaceholderWithStakeholderDocument = `
    mutation replacePlaceholderWithStakeholder($input: ReplacePlaceholderWithStakeholderInput!) {
  memberMutation {
    replacePlaceholderWithStakeholder(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on BooleanObject {
        id
      }
    }
  }
}
    `;
export const RemoveStakeholderFromRoleDocument = `
    mutation removeStakeholderFromRole($input: RemoveStakeholderFromRoleInput!) {
  memberMutation {
    removeStakeholderFromRole(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on BooleanObject {
        id
      }
    }
  }
}
    `;
export const CreateAndAssignStakeholderToRoleDocument = `
    mutation createAndAssignStakeholderToRole($input: CreateAndAssignStakeholderToRoleInput!) {
  memberMutation {
    createAndAssignStakeholderToRole(input: $input) {
      __typename
      ... on UserPayload {
        items {
          ... on User {
            id
          }
        }
      }
      ... on GraphqlError {
        code
        type
        message
      }
    }
  }
}
    `;
export const CreateExternalLinkMutationDocument = `
    mutation createExternalLinkMutation($input: ExternalLinkInput!) {
  mediaMutation {
    createExternalLink(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on MediaExternalLink {
        url
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const FavoriteResourceDocument = `
    mutation favoriteResource($input: FavoriteMediaInput!) {
  mediaMutation {
    favoriteMedia(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const PinResourceDocument = `
    mutation pinResource($input: PinMediaInput!) {
  mediaMutation {
    pinMedia(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    `;
export const RenameFileDocument = `
    mutation renameFile($input: UpdateMediaInput!) {
  mediaMutation {
    updateMedia(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on MediaPayload {
        items {
          ... on MediaPayloadItem {
            ... on MediaPayloadItem {
              item {
                id
                name
                description
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const RevokeExternalAccessDocument = `
    mutation revokeExternalAccess($input: RevokeMediaExternalAccessInput!) {
  mediaMutation {
    revokeExternalAccess(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const UnFavoriteResourceDocument = `
    mutation unFavoriteResource($input: UnfavoriteMediaInput!) {
  mediaMutation {
    unfavoriteMedia(input: $input) {
      __typename
      ...CoreErrorFieldsFragment
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    ${CoreErrorFieldsFragmentFragmentDoc}`;
export const UnpinResourceDocument = `
    mutation unpinResource($input: UnpinMediaInput!) {
  mediaMutation {
    unpinMedia(input: $input) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on BooleanObject {
        id
        success
      }
    }
  }
}
    `;
export const GetStakeholderUserEngagementDocument = `
    query getStakeholderUserEngagement($mspId: ID!, $input: GetUserEngagementRequest) {
  mspAnalyticsQuery {
    getUserEngagement(mspId: $mspId, requestOptions: $input) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on UserEngagementConnection {
        edges {
          node {
            __typename
            ... on UserEngagement {
              id
              mspViewCount
              mediaViewCount
              mediaDownloadCount
              commentCount
              user {
                __typename
                ... on User {
                  firstName
                }
              }
              userTasksAnalytics {
                ... on UserTaskAnalytics {
                  overdueTask {
                    ... on UserTaskStatusDetails {
                      count
                    }
                  }
                  assignedTask {
                    ... on UserTaskStatusDetails {
                      count
                    }
                  }
                  completedTask {
                    ... on UserTaskStatusDetails {
                      count
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMspEngagementDocument = `
    query getMspEngagement($mspId: ID!) {
  mspAnalyticsQuery {
    getMspEngagement(mspId: $mspId) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MspEngagementDetails {
        internalUserEngagement {
          lastActivity {
            ... on LatestActivity {
              activityAt
              activityById
              activityBy {
                __typename
                ... on User {
                  id
                  firstName
                  lastName
                  email
                  userType
                }
              }
              role {
                ... on RolePayload {
                  items {
                    ... on Role {
                      name
                    }
                    ... on GraphqlError {
                      __typename
                      type
                      code
                      message
                    }
                  }
                }
              }
            }
          }
          user {
            ... on MspEngagementUserDetails {
              count
            }
          }
          msp {
            ... on EngagementCount {
              viewCount
            }
          }
          comment {
            ... on EngagementCount {
              commentCount
            }
          }
        }
        externalUserEngagement {
          lastActivity {
            ... on LatestActivity {
              activityAt
              activityById
              activityBy {
                __typename
                ... on User {
                  id
                  firstName
                  lastName
                  email
                  userType
                }
              }
              role {
                ... on RolePayload {
                  items {
                    ... on Role {
                      name
                    }
                    ... on GraphqlError {
                      __typename
                      type
                      code
                      message
                    }
                  }
                }
              }
            }
          }
          user {
            ... on MspEngagementUserDetails {
              count
              engagementUsers {
                ... on MspEngagmentUsersLatestActivity {
                  items {
                    ... on UserActivity {
                      activityAt
                      activityById
                      activityBy {
                        __typename
                        ... on User {
                          firstName
                        }
                      }
                      role {
                        ... on RolePayload {
                          items {
                            ... on Role {
                              name
                            }
                            ... on GraphqlError {
                              __typename
                              type
                              code
                              message
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          msp {
            ... on EngagementCount {
              viewCount
            }
          }
          comment {
            ... on EngagementCount {
              commentCount
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMediaEngagementDocument = `
    query getMediaEngagement($mspId: ID!, $input: GetMediaEngagementRequest) {
  mspAnalyticsQuery {
    getMediaEngagement(mspId: $mspId, requestOptions: $input) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MediaEngagementConnection {
        edges {
          node {
            __typename
            ... on MediaEngagement {
              id
              downloadCount
              viewCount
              media {
                __typename
                ... on MediaConnection {
                  edges {
                    node {
                      __typename
                      ... on MediaPayloadItem {
                        item {
                          ... on Media {
                            id
                            name
                            orgId
                            createdBy {
                              ... on GraphqlError {
                                __typename
                                type
                                code
                                helpLink
                                message
                              }
                              ... on User {
                                id
                                firstName
                                lastName
                                email
                                userType
                                __typename
                              }
                              __typename
                            }
                            description
                            mediaType
                            mimeType
                            createdAt
                            ...AssetsFragment
                            ...ImageFragment
                            ...AudioFragment
                            ...DocumentFragment
                            ...VideoFragment
                            ...LinkFragment
                            __typename
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AssetsFragmentFragmentDoc}
${ImageFragmentFragmentDoc}
${AudioFragmentFragmentDoc}
${DocumentFragmentFragmentDoc}
${VideoFragmentFragmentDoc}
${LinkFragmentFragmentDoc}`;
export const GetUserEngagementDocument = `
    query getUserEngagement($mspId: ID!, $input: GetUserEngagementRequest) {
  mspAnalyticsQuery {
    getUserEngagement(mspId: $mspId, requestOptions: $input) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on UserEngagementConnection {
        edges {
          node {
            __typename
            ... on UserEngagement {
              id
              mspViewCount
              mediaViewCount
              mediaDownloadCount
              commentCount
              user {
                __typename
                ... on User {
                  firstName
                  lastName
                  email
                  userType
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMspAnalyticsMetricsDocument = `
    query getMspAnalyticsMetrics($mspId: ID!) {
  mspAnalyticsQuery {
    getMspAnalyticsMetrics(mspId: $mspId) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MspMetrics {
        mspTrack {
          ... on MspTrack {
            mspTrackStatus
            mspSlip
            mspSlipDisplayTime
          }
        }
        lastActivity {
          ... on AnalyticsActivity {
            activityAt
            activityById
            message
            activityByUser {
              ... on User {
                firstName
                lastName
              }
            }
          }
        }
        lastExternalActivity {
          ... on AnalyticsActivity {
            activityAt
            activityById
            activityByUser {
              ... on User {
                lastName
                firstName
              }
            }
          }
        }
        externalStakeholders {
          ... on MspAnalyticsStakeholders {
            stakeholders {
              __typename
              ... on AnalyticsUser {
                id
                firstName
                lastName
                userType
                email
              }
            }
            subscribers {
              id
              firstName
              lastName
              userType
              email
            }
          }
        }
      }
    }
  }
}
    `;
export const GetActivityTimelineDocument = `
    query getActivityTimeline($input: GetActivityTimelineRequestBody!, $pageConfiguration: PageConfiguration) {
  activityTimelineQuery {
    getActivityTimeline(input: $input, pageConfiguration: $pageConfiguration) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on ActivityTimelineConnection {
        pageInfo {
          hasNextPage
          hasPreviousPage
          afterCursor
          beforeCursor
          __typename
        }
        edges {
          node {
            __typename
            ... on ActivityTimeline {
              __typename
              message
              createdAt
              eventType
              authorizer {
                ... on ActivityTimelineAuthorizerDetails {
                  name
                  type
                }
              }
              task {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              milestone {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              comment {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              media {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              role {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              stakeholder {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              methodology {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              opportunity {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              user {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              msp {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
              mspTemplate {
                ... on ActivityTimelineResourceDetails {
                  name
                  type
                  updateFields {
                    ... on ActivityTimelineResourceUpdateFields {
                      property
                      value
                    }
                  }
                }
              }
            }
          }
        }
        activityTimelineHighlights {
          ... on ActivityTimelineHighlights {
            eventType
            count
          }
        }
      }
    }
  }
}
    `;
export const GetMspAnalyticsTimelineDocument = `
    query getMspAnalyticsTimeline($mspId: ID!) {
  mspAnalyticsQuery {
    getMspAnalyticsTimeline(mspId: $mspId) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on MspAnalyticsTimeline {
        mspId
        lastUpdatedAt
        plannedCompletionDate
        timeline {
          ... on MspAnalyticsTimelineData {
            timestamp
            mspProgress
            plannedMspProgress
            timeParameterDisplayName
          }
        }
      }
    }
  }
}
    `;
export const GetMspEngagementTimelineDocument = `
    query getMspEngagementTimeline($mspId: ID!, $input: MspAnalyticsTimelineQueryOptions!) {
  mspAnalyticsQuery {
    getMspEngagementTimeline(mspId: $mspId, requestOptions: $input) {
      ... on GraphqlError {
        __typename
        type
        code
        helpLink
        message
      }
      ... on MspEngagementTimeline {
        mspId
        timeline {
          ... on MspEngagementTimelineData {
            timestamp
            engagementCount
            buyerEngagementCount
            sellerEngagementCount
            userCount
            buyerUserIds
            sellerUserIds
            timeParameterDisplayName
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetOrganization: build.query<
      GetOrganizationQuery,
      GetOrganizationQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationDocument, variables }),
    }),
    CreateMspFromTemplate: build.mutation<
      CreateMspFromTemplateMutation,
      CreateMspFromTemplateMutationVariables
    >({
      query: (variables) => ({
        document: CreateMspFromTemplateDocument,
        variables,
      }),
    }),
    CreateMsp: build.mutation<CreateMspMutation, CreateMspMutationVariables>({
      query: (variables) => ({ document: CreateMspDocument, variables }),
    }),
    getMspAnalyticsHighlights: build.query<
      GetMspAnalyticsHighlightsQuery,
      GetMspAnalyticsHighlightsQueryVariables
    >({
      query: (variables) => ({
        document: GetMspAnalyticsHighlightsDocument,
        variables,
      }),
    }),
    createInfluencerGraph: build.mutation<
      CreateInfluencerGraphMutation,
      CreateInfluencerGraphMutationVariables
    >({
      query: (variables) => ({
        document: CreateInfluencerGraphDocument,
        variables,
      }),
    }),
    createNewNote: build.mutation<
      CreateNewNoteMutation,
      CreateNewNoteMutationVariables
    >({
      query: (variables) => ({ document: CreateNewNoteDocument, variables }),
    }),
    deleteNote: build.mutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
      {
        query: (variables) => ({ document: DeleteNoteDocument, variables }),
      },
    ),
    updateContactFields: build.mutation<
      UpdateContactFieldsMutation,
      UpdateContactFieldsMutationVariables
    >({
      query: (variables) => ({
        document: UpdateContactFieldsDocument,
        variables,
      }),
    }),
    updateExistingNote: build.mutation<
      UpdateExistingNoteMutation,
      UpdateExistingNoteMutationVariables
    >({
      query: (variables) => ({
        document: UpdateExistingNoteDocument,
        variables,
      }),
    }),
    GetMspWithMilestones: build.query<
      GetMspWithMilestonesQuery,
      GetMspWithMilestonesQueryVariables
    >({
      query: (variables) => ({
        document: GetMspWithMilestonesDocument,
        variables,
      }),
    }),
    updateMeddpiccForMsp: build.mutation<
      UpdateMeddpiccForMspMutation,
      UpdateMeddpiccForMspMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMeddpiccForMspDocument,
        variables,
      }),
    }),
    syncMeddpicc: build.mutation<
      SyncMeddpiccMutation,
      SyncMeddpiccMutationVariables
    >({
      query: (variables) => ({ document: SyncMeddpiccDocument, variables }),
    }),
    updateMeddicForMsp: build.mutation<
      UpdateMeddicForMspMutation,
      UpdateMeddicForMspMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMeddicForMspDocument,
        variables,
      }),
    }),
    syncMeddic: build.mutation<SyncMeddicMutation, SyncMeddicMutationVariables>(
      {
        query: (variables) => ({ document: SyncMeddicDocument, variables }),
      },
    ),
    getMeddpiccForMsp: build.query<
      GetMeddpiccForMspQuery,
      GetMeddpiccForMspQueryVariables
    >({
      query: (variables) => ({
        document: GetMeddpiccForMspDocument,
        variables,
      }),
    }),
    getMeddicForMsp: build.query<
      GetMeddicForMspQuery,
      GetMeddicForMspQueryVariables
    >({
      query: (variables) => ({ document: GetMeddicForMspDocument, variables }),
    }),
    listRoles: build.query<ListRolesQuery, ListRolesQueryVariables | void>({
      query: (variables) => ({ document: ListRolesDocument, variables }),
    }),
    createAndAssignPlaceholderToRoles: build.mutation<
      CreateAndAssignPlaceholderToRolesMutation,
      CreateAndAssignPlaceholderToRolesMutationVariables
    >({
      query: (variables) => ({
        document: CreateAndAssignPlaceholderToRolesDocument,
        variables,
      }),
    }),
    createAndAssignPlaceholderToRole: build.mutation<
      CreateAndAssignPlaceholderToRoleMutation,
      CreateAndAssignPlaceholderToRoleMutationVariables
    >({
      query: (variables) => ({
        document: CreateAndAssignPlaceholderToRoleDocument,
        variables,
      }),
    }),
    removePlaceholderFromRole: build.mutation<
      RemovePlaceholderFromRoleMutation,
      RemovePlaceholderFromRoleMutationVariables
    >({
      query: (variables) => ({
        document: RemovePlaceholderFromRoleDocument,
        variables,
      }),
    }),
    createRole: build.mutation<CreateRoleMutation, CreateRoleMutationVariables>(
      {
        query: (variables) => ({ document: CreateRoleDocument, variables }),
      },
    ),
    addStakeholdersToRoles: build.mutation<
      AddStakeholdersToRolesMutation,
      AddStakeholdersToRolesMutationVariables
    >({
      query: (variables) => ({
        document: AddStakeholdersToRolesDocument,
        variables,
      }),
    }),
    addStakeholderToRole: build.mutation<
      AddStakeholderToRoleMutation,
      AddStakeholderToRoleMutationVariables
    >({
      query: (variables) => ({
        document: AddStakeholderToRoleDocument,
        variables,
      }),
    }),
    replacePlaceholderWithStakeholder: build.mutation<
      ReplacePlaceholderWithStakeholderMutation,
      ReplacePlaceholderWithStakeholderMutationVariables
    >({
      query: (variables) => ({
        document: ReplacePlaceholderWithStakeholderDocument,
        variables,
      }),
    }),
    removeStakeholderFromRole: build.mutation<
      RemoveStakeholderFromRoleMutation,
      RemoveStakeholderFromRoleMutationVariables
    >({
      query: (variables) => ({
        document: RemoveStakeholderFromRoleDocument,
        variables,
      }),
    }),
    createAndAssignStakeholderToRole: build.mutation<
      CreateAndAssignStakeholderToRoleMutation,
      CreateAndAssignStakeholderToRoleMutationVariables
    >({
      query: (variables) => ({
        document: CreateAndAssignStakeholderToRoleDocument,
        variables,
      }),
    }),
    createExternalLinkMutation: build.mutation<
      CreateExternalLinkMutationMutation,
      CreateExternalLinkMutationMutationVariables
    >({
      query: (variables) => ({
        document: CreateExternalLinkMutationDocument,
        variables,
      }),
    }),
    favoriteResource: build.mutation<
      FavoriteResourceMutation,
      FavoriteResourceMutationVariables
    >({
      query: (variables) => ({ document: FavoriteResourceDocument, variables }),
    }),
    pinResource: build.mutation<
      PinResourceMutation,
      PinResourceMutationVariables
    >({
      query: (variables) => ({ document: PinResourceDocument, variables }),
    }),
    renameFile: build.mutation<RenameFileMutation, RenameFileMutationVariables>(
      {
        query: (variables) => ({ document: RenameFileDocument, variables }),
      },
    ),
    revokeExternalAccess: build.mutation<
      RevokeExternalAccessMutation,
      RevokeExternalAccessMutationVariables
    >({
      query: (variables) => ({
        document: RevokeExternalAccessDocument,
        variables,
      }),
    }),
    unFavoriteResource: build.mutation<
      UnFavoriteResourceMutation,
      UnFavoriteResourceMutationVariables
    >({
      query: (variables) => ({
        document: UnFavoriteResourceDocument,
        variables,
      }),
    }),
    unpinResource: build.mutation<
      UnpinResourceMutation,
      UnpinResourceMutationVariables
    >({
      query: (variables) => ({ document: UnpinResourceDocument, variables }),
    }),
    getStakeholderUserEngagement: build.query<
      GetStakeholderUserEngagementQuery,
      GetStakeholderUserEngagementQueryVariables
    >({
      query: (variables) => ({
        document: GetStakeholderUserEngagementDocument,
        variables,
      }),
    }),
    getMspEngagement: build.query<
      GetMspEngagementQuery,
      GetMspEngagementQueryVariables
    >({
      query: (variables) => ({ document: GetMspEngagementDocument, variables }),
    }),
    getMediaEngagement: build.query<
      GetMediaEngagementQuery,
      GetMediaEngagementQueryVariables
    >({
      query: (variables) => ({
        document: GetMediaEngagementDocument,
        variables,
      }),
    }),
    getUserEngagement: build.query<
      GetUserEngagementQuery,
      GetUserEngagementQueryVariables
    >({
      query: (variables) => ({
        document: GetUserEngagementDocument,
        variables,
      }),
    }),
    getMspAnalyticsMetrics: build.query<
      GetMspAnalyticsMetricsQuery,
      GetMspAnalyticsMetricsQueryVariables
    >({
      query: (variables) => ({
        document: GetMspAnalyticsMetricsDocument,
        variables,
      }),
    }),
    getActivityTimeline: build.query<
      GetActivityTimelineQuery,
      GetActivityTimelineQueryVariables
    >({
      query: (variables) => ({
        document: GetActivityTimelineDocument,
        variables,
      }),
    }),
    getMspAnalyticsTimeline: build.query<
      GetMspAnalyticsTimelineQuery,
      GetMspAnalyticsTimelineQueryVariables
    >({
      query: (variables) => ({
        document: GetMspAnalyticsTimelineDocument,
        variables,
      }),
    }),
    getMspEngagementTimeline: build.query<
      GetMspEngagementTimelineQuery,
      GetMspEngagementTimelineQueryVariables
    >({
      query: (variables) => ({
        document: GetMspEngagementTimelineDocument,
        variables,
      }),
    }),
    createAndAssignPlaceholderToRoles: build.mutation<
      CreateAndAssignPlaceholderToRolesMutation,
      CreateAndAssignPlaceholderToRolesMutationVariables
    >({
      query: (variables) => ({
        document: CreateAndAssignPlaceholderToRolesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useCreateMspFromTemplateMutation,
  useCreateMspMutation,
  useGetMspAnalyticsHighlightsQuery,
  useLazyGetMspAnalyticsHighlightsQuery,
  useCreateInfluencerGraphMutation,
  useCreateNewNoteMutation,
  useDeleteNoteMutation,
  useUpdateContactFieldsMutation,
  useUpdateExistingNoteMutation,
  useGetMspWithMilestonesQuery,
  useLazyGetMspWithMilestonesQuery,
  useUpdateMeddpiccForMspMutation,
  useSyncMeddpiccMutation,
  useUpdateMeddicForMspMutation,
  useSyncMeddicMutation,
  useGetMeddpiccForMspQuery,
  useLazyGetMeddpiccForMspQuery,
  useGetMeddicForMspQuery,
  useLazyGetMeddicForMspQuery,
  useListRolesQuery,
  useLazyListRolesQuery,
  useCreateAndAssignPlaceholderToRolesMutation,
  useCreateAndAssignPlaceholderToRoleMutation,
  useRemovePlaceholderFromRoleMutation,
  useCreateRoleMutation,
  useAddStakeholdersToRolesMutation,
  useAddStakeholderToRoleMutation,
  useReplacePlaceholderWithStakeholderMutation,
  useRemoveStakeholderFromRoleMutation,
  useCreateAndAssignStakeholderToRoleMutation,
  useCreateExternalLinkMutationMutation,
  useFavoriteResourceMutation,
  usePinResourceMutation,
  useRenameFileMutation,
  useRevokeExternalAccessMutation,
  useUnFavoriteResourceMutation,
  useUnpinResourceMutation,
  useGetStakeholderUserEngagementQuery,
  useLazyGetStakeholderUserEngagementQuery,
  useGetMspEngagementQuery,
  useLazyGetMspEngagementQuery,
  useGetMediaEngagementQuery,
  useLazyGetMediaEngagementQuery,
  useGetUserEngagementQuery,
  useLazyGetUserEngagementQuery,
  useGetMspAnalyticsMetricsQuery,
  useLazyGetMspAnalyticsMetricsQuery,
  useGetActivityTimelineQuery,
  useLazyGetActivityTimelineQuery,
  useGetMspAnalyticsTimelineQuery,
  useLazyGetMspAnalyticsTimelineQuery,
  useGetMspEngagementTimelineQuery,
  useLazyGetMspEngagementTimelineQuery,
} = injectedRtkApi;
