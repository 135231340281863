import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Milestone, Maybe } from '../../../generated-types';

const initialState = {
  milestoneTemplateList: [] as Maybe<Milestone>[],
};

const milestoneTemplateSlice = createSlice({
  name: 'milestone-template',
  initialState,
  reducers: {
    setMilestoneTemplateList: (
      state,
      action: PayloadAction<Maybe<Milestone>[]>,
    ) => {
      state.milestoneTemplateList = action.payload;
    },
  },
});

export const { setMilestoneTemplateList } = milestoneTemplateSlice.actions;

export default milestoneTemplateSlice.reducer;
