import { ApolloClient } from '@apollo/client';
import type {
  ActionCreatorWithPayload,
  ActionCreatorWithPreparedPayload,
  Dispatch,
} from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import {
  AddOrRemovePlaceholderPayload,
  AddOrRemovePlaceholderRoleResponse,
  AddOrRemoveStakeholderRoleResponse,
  BooleanObject,
  BooleanResponse,
  CompanyType,
  PlaceholderInput,
  Role,
  RoleConnectionResponse,
  RolePayload,
  RolePayloadResponse,
  Stakeholder,
  UpdateRoleInput,
} from '../../../generated-types';
import { EMspSaveIndicator } from '../model';
import { GET_ROLE_LIST } from './graphql/container';
import {
  CREATE_ASSIGN_PLACEHOLDER,
  REMOVE_PLACEHOLDER,
  REPLACE_PLACEHOLDER_WITH_STAKEHOLDER,
} from './graphql/placeholder';
import { UPDATE_ROLE } from './graphql/role';
import { REMOVE_STAKEHOLDER } from './graphql/stakeholder';

type ContextType = {
  setStakeholderModal: React.Dispatch<React.SetStateAction<any>>;
};
export const AnchorContext = createContext({} as ContextType);

export enum ModalType {
  AVATAR_GROUP = 'AVATAR_GROUP',
  PROFILE = 'PROFILE',
  ADD_STAKEHOLDER = 'ADD_STAKEHOLDER',
  MORE_ACTION = 'MORE_ACTION',
}

export type ResultListRoles = {
  roleQuery: {
    listRoles: RoleConnectionResponse;
  };
};

export type UpdateRoleResult = {
  roleMutation: {
    updateRole: RolePayloadResponse;
  };
};

export type CreateRoleResult = {
  roleMutation: {
    createRole: RolePayloadResponse;
  };
};

export type RemoveStakeholderResult = {
  memberMutation: {
    removeStakeholderFromRole: BooleanResponse;
  };
};

export type RemovePlaceholderResult = {
  memberMutation: {
    removePlaceholderFromRole: BooleanResponse;
  };
};

export type ReorderRoleResult = {
  roleMutation: {
    reOrderRole: BooleanResponse;
  };
};

export type AddStakeholderResult = {
  memberMutation: {
    addStakeholdersToRoles: AddOrRemoveStakeholderRoleResponse;
  };
};

export type AssignPlaceholderResult = {
  memberMutation: {
    createAndAssignPlaceholderToRole: AddOrRemovePlaceholderRoleResponse;
  };
};

export type ReplacePlaceholderResult = {
  memberMutation: {
    replacePlaceholderWithStakeholder: BooleanResponse;
  };
};

export interface InputProps {
  client: ApolloClient<object>;
  showToast: any;
  dispatch: Dispatch<any>;
}

export const fetchRoleList = createAsyncThunk(
  'msp/get-roles',
  async (
    {
      client,
    }: {
      client: ApolloClient<object>;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultListRoles>({
        query: GET_ROLE_LIST,
      });
      if (data.roleQuery.listRoles.__typename === 'GraphqlError') {
        throw new Error('Something went wrong');
      }
      return { data };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const updateRole = createAsyncThunk(
  'msp/update-role',
  async (
    {
      client,
      roleId,
      mspId,
      input,
      showToast,
      removeFromSavingList,
    }: {
      client: ApolloClient<object>;
      roleId: string;
      mspId: string;
      input: UpdateRoleInput;
      showToast: (
        message: JSX.Element | string,
        options: {
          variant: string;
        },
      ) => void;
      removeFromSavingList: ActionCreatorWithPayload<string, string>;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<UpdateRoleResult>({
        query: UPDATE_ROLE,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          id: roleId,
          input,
        },
      });
      if (
        data.roleMutation.updateRole.__typename === 'GraphqlError' ||
        (data.roleMutation.updateRole as RolePayload)?.items?.[0].__typename ===
          'GraphqlError'
      ) {
        throw new Error('Something went wrong');
      }
      removeFromSavingList(EMspSaveIndicator.STAKEHOLDER_UPDATE_ROLE);
      return {
        data: (data.roleMutation.updateRole as RolePayload)?.items?.[0] as Role,
      };
    } catch (error) {
      showToast(
        <FormattedMessage
          id="MspTeamView.updateRoleError"
          defaultMessage="Role could not be updated, please try again."
        />,
        {
          variant: 'error',
        },
      );
      removeFromSavingList(EMspSaveIndicator.STAKEHOLDER_CREATE_ROLE);
      return rejectWithValue((error as Error).message);
    }
  },
);

export const removeStakeholder = createAsyncThunk(
  'msp/remove-stakeholder',
  async (
    {
      client,
      roleId,
      mspId,
      stakeholderId,
      companyType,
      trackEvent,
      removeFromSavingList,
      showToast,
    }: {
      client: ApolloClient<object>;
      roleId: string;
      mspId: string;
      stakeholderId: string;
      companyType: CompanyType;
      trackEvent: ActionCreatorWithPreparedPayload<
        any,
        { type: string; to: string }
      >;
      removeFromSavingList: ActionCreatorWithPayload<string, string>;
      showToast: (
        message: JSX.Element | string,
        options: {
          variant: string;
        },
      ) => void;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<RemoveStakeholderResult>({
        query: REMOVE_STAKEHOLDER,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            roleId,
            scopeId: mspId,
            stakeholderId,
          },
        },
      });
      if (
        data.memberMutation.removeStakeholderFromRole.__typename ===
          'GraphqlError' ||
        !(data.memberMutation.removeStakeholderFromRole as BooleanObject)
          .success
      ) {
        throw new Error('Something went wrong');
      }
      trackEvent(TRACKING_CONSTANTS.STAKEHOLDER_REMOVED);
      showToast(
        <FormattedMessage
          id="MspTeamView.deleteStakeholder"
          defaultMessage="Assignee removed successfully"
        />,
        {
          variant: 'success',
        },
      );
      return { roleId, stakeholderId, companyType };
    } catch (error) {
      showToast(
        <FormattedMessage
          id="MspTeamView.deleteStakeholderError"
          defaultMessage="Stakeholder could not be deleted, please try again."
        />,
        {
          variant: 'error',
        },
      );
      return rejectWithValue((error as Error).message);
    }
  },
);

export const removePlaceholder = createAsyncThunk(
  'msp/remove-placeholder',
  async (
    {
      client,
      roleId,
      mspId,
      placeholderId,
      companyType,
      trackEvent,
      removeFromSavingList,
      showToast,
    }: {
      client: ApolloClient<object>;
      roleId: string;
      mspId: string;
      placeholderId: string;
      companyType: CompanyType;
      trackEvent: ActionCreatorWithPreparedPayload<
        any,
        { type: string; to: string }
      >;
      removeFromSavingList: ActionCreatorWithPayload<string, string>;
      showToast: (
        message: JSX.Element | string,
        options: {
          variant: string;
        },
      ) => void;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<RemovePlaceholderResult>({
        query: REMOVE_PLACEHOLDER,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            roleId,
            scopeId: mspId,
            placeholderId,
          },
        },
      });
      if (
        data.memberMutation.removePlaceholderFromRole.__typename ===
          'GraphqlError' ||
        !(data.memberMutation.removePlaceholderFromRole as BooleanObject)
          .success
      ) {
        throw new Error('Something went wrong');
      }
      trackEvent(TRACKING_CONSTANTS.PLACEHOLDER_REMOVED);
      showToast(
        <FormattedMessage
          id="MspTeamView.deletePlaceholder"
          defaultMessage="Removed Placeholder successfully"
        />,
        {
          variant: 'success',
        },
      );
      return { roleId, placeholderId, companyType };
    } catch (error) {
      showToast(
        <FormattedMessage
          id="MspTeamView.deletePlaceholderError"
          defaultMessage="Placeholder could not be deleted, please try again."
        />,
        {
          variant: 'error',
        },
      );
      return rejectWithValue((error as Error).message);
    }
  },
);

export const assignPlaceholder = createAsyncThunk(
  'msp/assign-placeholder-to-role',
  async (
    {
      client,
      roleId,
      mspId,
      placeholderInput,
      companyType,
      trackEvent,
      removeFromSavingList,
      showToast,
    }: {
      client: ApolloClient<object>;
      roleId: string;
      mspId: string;
      placeholderInput: PlaceholderInput;
      companyType: CompanyType;
      trackEvent: ActionCreatorWithPreparedPayload<
        any,
        { type: string; to: string }
      >;
      removeFromSavingList: ActionCreatorWithPayload<string, string>;
      showToast: (
        message: JSX.Element | string,
        options: {
          variant: string;
        },
      ) => void;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<AssignPlaceholderResult>({
        query: CREATE_ASSIGN_PLACEHOLDER,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            scopeId: mspId,
            roleId,
            companyType,
            placeholderInput,
          },
        },
      });
      if (
        data.memberMutation.createAndAssignPlaceholderToRole.__typename ===
        'GraphqlError'
      ) {
        throw new Error('Something went wrong');
      }
      const res = (data?.memberMutation?.createAndAssignPlaceholderToRole ||
        {}) as AddOrRemovePlaceholderPayload;
      const arr = res?.items || [];
      const placeholderId = arr[0]?.placeholderId;
      trackEvent(TRACKING_CONSTANTS.PLACEHOLDER_ADDED, {
        type: 'single',
        to: 'role',
      });
      showToast(
        <FormattedMessage
          id="MspTeamView.assignPlaceholder"
          defaultMessage="Added Placeholder successfully"
        />,
        {
          variant: 'success',
        },
      );
      return {
        name: placeholderInput.name,
        roleId,
        companyType,
        placeholderId,
      };
    } catch (error) {
      showToast(
        <FormattedMessage
          id="MspTeamView.assignPlaceholderError"
          defaultMessage="Placeholder could not be created, please try again."
        />,
        {
          variant: 'error',
        },
      );
      return rejectWithValue((error as Error).message);
    }
  },
);

export const replacePlaceholderWithStakeholder = createAsyncThunk(
  'msp/replace-placeholder-with-stakeholder',
  async (
    {
      client,
      roleId,
      mspId,
      placeholderId,
      user,
      companyType,
      removeFromSavingList,
      showToast,
    }: {
      client: ApolloClient<object>;
      roleId: string;
      mspId: string;
      placeholderId: string;
      user: Stakeholder;
      companyType: CompanyType;
      removeFromSavingList: ActionCreatorWithPayload<string, string>;
      showToast: (
        message: JSX.Element | string,
        options: {
          variant: string;
        },
      ) => void;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ReplacePlaceholderResult>({
        query: REPLACE_PLACEHOLDER_WITH_STAKEHOLDER,
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          input: {
            roleId,
            scopeId: mspId,
            placeholderId,
            stakeholderIdMap: { id: user.id, primaryId: user.primaryId },
          },
        },
      });
      if (
        data.memberMutation.replacePlaceholderWithStakeholder.__typename ===
          'GraphqlError' ||
        !(
          data.memberMutation.replacePlaceholderWithStakeholder as BooleanObject
        ).success
      ) {
        throw new Error('Something went wrong');
      }
      removeFromSavingList(
        EMspSaveIndicator.REPLACE_PLACEHOLDER_WITH_STAKEHOLDER,
      );
      return {
        companyType,
        placeholderId,
        user: {
          ...user,
          id:
            user.id ??
            (
              data.memberMutation
                .replacePlaceholderWithStakeholder as BooleanObject
            ).id,
        },
        roleId,
      };
    } catch (error) {
      removeFromSavingList(
        EMspSaveIndicator.REPLACE_PLACEHOLDER_WITH_STAKEHOLDER,
      );
      showToast(
        <FormattedMessage
          id="MspTeamView.replacePlaceholderError"
          defaultMessage="Stakeholder could not be assigned, please try again."
        />,
        {
          variant: 'error',
        },
      );
      return rejectWithValue((error as Error).message);
    }
  },
);
