import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  Comment,
  MediaConnection,
  MediaEdge,
  MediaPayloadItem,
} from '../../../generated-types';
import { IComment } from './models';

const initialState = { loading: true } as IComment & { loading: boolean };

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        noteId?: string;
        commentList: Comment[];
      }>,
    ) => {
      const { taskId, noteId, commentList } = action.payload;
      const commentDetails = state[taskId];
      if (noteId) {
        const details = { noteId, commentList };

        state[taskId] = details;
      } else if (Array.isArray(state[taskId]?.commentList)) {
        state[taskId]?.commentList.push(...commentList);
      } else if (commentDetails) commentDetails.commentList = commentList || [];
    },
    addOptimisticComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        comment: Comment;
      }>,
    ) => {
      const { taskId, comment } = action.payload;
      const commentDetails = state[taskId];
      if (Array.isArray(state[taskId]?.commentList)) {
        state[taskId]?.commentList.push(comment);
      } else if (commentDetails) commentDetails.commentList = [comment];
    },
    addComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        tempId: string;
        comment: Comment;
      }>,
    ) => {
      const { taskId, comment, tempId } = action.payload;
      const commentDetails = state[taskId];
      const index = commentDetails.commentList.findIndex(
        (item) => item?.id === tempId,
      );
      if (index > -1) commentDetails.commentList.splice(index, 1, comment);
    },
    updateComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        commentMedia?: any;
        commentId: string;
        commentText?: string;
      }>,
    ) => {
      const {
        taskId, commentText, commentId, commentMedia,
      } = action.payload;
      const commentList = state[taskId]?.commentList;
      if (Array.isArray(commentList)) {
        const index = state[taskId]?.commentList.findIndex(
          (comment) => comment?.id === commentId,
        );
        if (index > -1) {
          const msg = commentList[index]?.messageData;
          const msgMedia = commentList[index]?.media;
          if (commentMedia && msgMedia) {
            const updatedMedia = (msgMedia as MediaConnection)?.edges
              ? (msgMedia as MediaConnection)?.edges?.concat(commentMedia)
              : commentMedia;
            (msgMedia as MediaConnection).edges = updatedMedia;
          }
          if (msg) {
            msg.message = commentText ?? '';
            msg.isEdited = true;
            msg.editedAt = dayjs().unix() * 1000;
          }
        }
      }
    },
    removeCommentAsset: (
      state,
      action: PayloadAction<{
        assetId: string;
        commentId: string;
        taskId: string;
      }>,
    ) => {
      const { assetId, commentId, taskId } = action.payload;
      const commentList = state[taskId]?.commentList;
      if (Array.isArray(commentList)) {
        const commentIndex = state[taskId]?.commentList.findIndex(
          (comment) => comment?.id === commentId,
        );

        const assetList = (commentList?.[commentIndex]?.media as MediaConnection)?.edges ?? [];
        const assetIndex = assetList.findIndex(
          (asset) => (asset.node as MediaPayloadItem).item.id === assetId,
        );
        assetList.splice(assetIndex, 1);
      }
    },
    deleteComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        commentId: string;
      }>,
    ) => {
      const { taskId, commentId } = action.payload;
      const commentList = state[taskId]?.commentList;
      if (Array.isArray(commentList)) {
        const index = state[taskId]?.commentList.findIndex(
          (comment) => comment?.id === commentId,
        );

        commentList.splice(index, 1);
      }
    },
    removeCommentAssetFromRootAssets: (
      state,
      action: PayloadAction<{ assetId: string; commentId: string }>,
    ) => {
      const { assetId, commentId } = action.payload;
      const taskIds = Object.keys(state);
      if (taskIds.length) {
        for (let taskIndex = 0; taskIndex < taskIds.length; taskIndex += 1) {
          const { commentList } = state[taskIds[taskIndex]];
          if (!commentList) break;
          const commentIndex = commentList?.findIndex(
            (comment) => comment.id === commentId,
          );

          if (commentIndex === -1) break;
          const MediaList = (commentList?.[commentIndex]?.media as MediaConnection)?.edges
            ?? [];

          const assetIndex = MediaList?.findIndex(
            (media: MediaEdge) =>
              (media?.node as MediaPayloadItem)?.item?.id === assetId,
          );
          if (assetIndex > -1) {
            MediaList?.splice(assetIndex, 1);
          }
        }
      }
    },
    addMediaToComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        commentId: string;
        media: MediaEdge[];
      }>,
    ) => {
      const { taskId, media, commentId } = action.payload;
      const commentList = state[taskId]?.commentList;
      if (Array.isArray(commentList)) {
        const index = state[taskId]?.commentList.findIndex(
          (comment) => comment?.id === commentId,
        );
        if (index > -1) {
          const msg = commentList[index]?.messageData;
          if (msg) {
            msg.isEdited = true;
            msg.editedAt = dayjs(new Date()).unix() * 1000;
            const existingMedia = (commentList[index].media as MediaConnection).edges ?? [];
            (commentList[index].media as MediaConnection).edges = existingMedia.concat(media);
          }
        }
      }
    },
    resetMediaForComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        commentId: string;
        media: MediaEdge[];
      }>,
    ) => {
      const { taskId, media, commentId } = action.payload;
      const commentList = state[taskId]?.commentList;
      if (Array.isArray(commentList)) {
        const index = state[taskId]?.commentList.findIndex(
          (comment) => comment?.id === commentId,
        );
        if (index > -1) {
          const msg = commentList[index]?.messageData;
          if (msg) {
            msg.isEdited = true;
            msg.editedAt = dayjs(new Date()).unix() * 1000;
            (commentList?.[index]?.media as MediaConnection).edges = [] as MediaEdge[];
            (commentList?.[index]?.media as MediaConnection).edges = media;
          }
        }
      }
    },
    setCommentLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    removeOptimisticComment: (
      state,
      action: PayloadAction<{
        taskId: string;
        commentId: string;
      }>,
    ) => {
      const { taskId, commentId } = action.payload;
      if (Array.isArray(state[taskId]?.commentList)) {
        const commentIndex = state[taskId]?.commentList.findIndex(
          (comment) => comment.id === commentId,
        );
        if (commentIndex > 0) {
          state[taskId]?.commentList.splice(commentIndex, 1);
        }
      }
    },
  },
});

export const {
  setComment,
  addComment,
  updateComment,
  deleteComment,
  removeCommentAsset,
  addOptimisticComment,
  removeCommentAssetFromRootAssets,
  addMediaToComment,
  resetMediaForComment,
  setCommentLoading,
  removeOptimisticComment,
} = commentSlice.actions;

export default commentSlice.reducer;
