import { Route } from 'react-router-dom';
import { CircularIndeterminate } from '../../../components';
import { useKeycloakContext } from '../authentication-context';

interface IPrivateRouteProps {
  path: string;
  exact: boolean;
  children?: JSX.Element;
}

const ProtectedRoute: React.FC<IPrivateRouteProps> = ({
  path,
  exact,
  children,
}) => {
  const { keycloak, loginOptions } = useKeycloakContext();

  // for local development
  if (process.env.STAGE === 'local' && process.env.LOCAL_TO_REMOTE === 'mock') {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  }

  if (keycloak.authenticated) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  }

  keycloak.login(loginOptions);

  return <CircularIndeterminate />;
};

export default ProtectedRoute;
