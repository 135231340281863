import { ApolloClient, gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Company, CompanyPayload, CompanyQuery } from '../../generated-types';

const SearchCompanyByName = gql`
  query searchCompanyByName($companyName: String!) {
    companyQuery {
      getCompanyByName(companyName: $companyName) {
        __typename
        ... on GraphqlError {
          code
          type
          message
        }
        ... on CompanyPayload {
          items {
            ... on Company {
              companyName
              displayName
              id
              logo {
                ... on Logo {
                  id
                  fileName
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

type ResultCompanyDetailsQuery = {
  companyQuery: CompanyQuery;
};

export const fetchCompanyByName = createAsyncThunk(
  'org/get-company-by-name',
  async (
    {
      client,
      companyName,
    }: { client: ApolloClient<object>; companyName: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultCompanyDetailsQuery>({
        query: SearchCompanyByName,
        variables: {
          companyName,
        },
      });
      if (data?.companyQuery?.getCompanyByName?.__typename === 'GraphqlError') {
        throw new Error('Something went wrong while getting opportunities.');
      }

      const company = (data?.companyQuery?.getCompanyByName as CompanyPayload)
        ?.items?.[0];

      if (company) return company;

      return {} as Company;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);
