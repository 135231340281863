import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import {
  IFilterConfig,
  IFilterDetails,
} from '../../../components/filters/model';
import {
  Filter,
  FiltersExpression,
  MspInvitePermission,
  Operation,
  TaskStatus,
} from '../../../generated-types';
import { BoxFilterFields, BoxFilterType } from '../models';
import { ITaskResponse } from './all-tasks-redux-store';
import { fetchAllTasksList, TaskType } from './api-call';

const permissionFilters = {
  op: Operation.IN,
  values: [
    MspInvitePermission.EDIT,
    MspInvitePermission.ALL,
    MspInvitePermission?.VIEW,
  ],
  field: 'mspSubscriber.permission',
};
const taskStatusFilters = {
  op: Operation.IN,
  values: [TaskStatus.PENDING, TaskStatus.INPROGRESS],
  field: 'task.status',
};

const allTasksFilters = [permissionFilters] as Filter[];

export function useGetAllTasks(userId: string) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const filterMapper = {
    [BoxFilterType?.dueSevenDaysFilters]: {
      operator: 'AND',
      filters: [
        {
          op: Operation.GE,
          values: [dayjs().startOf('day').unix().toString()],
          field: 'task.dueDate',
        },
        {
          op: Operation.LE,
          values: [dayjs().add(7, 'day').endOf('day').unix().toString()],
          field: 'task.dueDate',
        },
      ],
    } as FiltersExpression,
    [BoxFilterType?.assigneedToMeFilters]: [
      {
        op: Operation.IN,
        values: [userId],
        field: 'task.assigneeId',
      },
    ] as Filter[],
    dueTomorrowFilters: {
      operator: 'AND',
      filters: [
        {
          op: Operation.GE,
          values: [dayjs().add(1, 'day').startOf('day').unix().toString()],
          field: 'task.dueDate',
        },
        {
          op: Operation.LE,
          values: [dayjs().add(1, 'day').endOf('day').unix().toString()],
          field: 'task.dueDate',
        },
      ] as Filter[],
    } as FiltersExpression,

    [BoxFilterType?.dueTodayFilters]: {
      operator: 'AND',
      filters: [
        {
          op: Operation.GE,
          values: [dayjs().startOf('day').unix().toString()],
          field: 'task.dueDate',
        },
        {
          op: Operation.LE,
          values: [dayjs().endOf('day').unix().toString()],
          field: 'task.dueDate',
        },
      ] as Filter[],
    } as FiltersExpression,
    [BoxFilterType?.overdueFilters]: {
      operator: 'AND',
      filters: [
        {
          op: Operation.LE,
          values: [dayjs().startOf('day').unix().toString()],
          field: 'task.dueDate',
        },
      ] as Filter[],
    } as FiltersExpression,
  } as Record<string, FiltersExpression | Filter[]>;

  const getAllTasks = useCallback(
    ({
      taskList,
      searchTerm,
      selectedFilters,
      filterDetails,
      boxFilterDetails,
    }: {
      taskList: ITaskResponse[];
      searchTerm?: string;
      selectedFilters?: IFilterDetails;
      filterDetails?: IFilterConfig;
      boxFilterDetails?: { [key in BoxFilterType]: BoxFilterFields };
    }) => {
      const filterKeys = selectedFilters ? Object.keys(selectedFilters) : null;
      let filters = [] as Filter[];
      const childExpressionFilters = [] as FiltersExpression[];

      if (selectedFilters && filterKeys) {
        filters = filterKeys.reduce((acc, key) => {
          const arr = selectedFilters[key];
          const fieldName = filterDetails && filterDetails[key]?.key;
          const values = arr
            .filter(({ isSelected, value }) => isSelected && value)
            .map(({ value }) => value);

          if (values.length && fieldName) {
            acc.push({
              op: 'IN',
              values,
              field: fieldName,
            } as Filter);
          }
          return acc;
        }, [] as Filter[]);
      }

      if (boxFilterDetails) {
        const boxFilterKeys = Object.keys(boxFilterDetails) as BoxFilterType[];
        boxFilterKeys.forEach((key) => {
          if (
            boxFilterDetails[key].isSelected &&
            boxFilterDetails[key].field !== 'task.assigneeId'
          ) {
            childExpressionFilters.push(filterMapper[key] as FiltersExpression);
          }
        });
        if (
          boxFilterDetails?.assigneedToMeFilters?.isSelected &&
          boxFilterDetails?.assigneedToMeFilters?.field === 'task.assigneeId'
        ) {
          const isAssigneeFilterAdded = filters.find(
            ({ field }) => field === 'task.assigneeId',
          );
          if (isAssigneeFilterAdded) {
            filters.forEach((item) => {
              if (item.field === 'task.assigneeId') {
                item.values.push(userId);
              }
            });
          } else {
            filters = filters.concat(
              filterMapper?.assigneedToMeFilters as Filter[],
            );
          }
        }
        if (childExpressionFilters && childExpressionFilters.length > 0) {
          filters = filters.concat([taskStatusFilters] as Filter[]);
        }
      }
      if ((selectedFilters && filterKeys) || boxFilterDetails) {
        dispatch(
          trackEvent(TRACKING_CONSTANTS.ACCESSED_FILTERS, {
            filters,
            from: 'All Task Tab',
          }),
        );
      }

      if (taskList?.length === 0 || searchTerm) {
        if (searchTerm) {
          dispatch(trackEvent(TRACKING_CONSTANTS.TASK_SEARCH, { searchTerm }));
        }

        dispatch(
          fetchAllTasksList({
            client,
            taskType: TaskType.ALLTASKS,
            filters: allTasksFilters.concat(filters),
            searchTerm,
            childExpressionFilters,
          }),
        );
      }
    },
    [],
  );

  return { getAllTasks };
}
