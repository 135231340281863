import {
  BaResource,
  GraphqlError,
  Maybe,
  NotificationEventType,
  PageInfo,
  PushNotificationMetaData,
} from '../../../generated-types/index';

export interface INotification {
  notificationType: NotificationEventType;
  accountId: string;
  accountName: string;
  userNotificationStatusId: string;
  message: string;
  createdAt: string;
  link: string;
  status: string;
  authorizerName: string;
  authorizerType?: string;
  metaData?: Maybe<PushNotificationMetaData>;
  additionalMessage?: string;
  tableKey: string;
  type: string;
}

export interface IAccountList {
  accountId: string;
  accountName: string;
  unreadCount: number;
}

export interface GqlError extends GraphqlError {
  userNotificationStatusId: string;
  name: string;
  tableKey: string;
}

export type INotificationFilterDetails = {
  notificationType: string;
  accountId?: string;
  baResourceList?: BaResource[];
};

export type TNotification = GqlError | INotification;

export type TAccountList = GqlError | IAccountList;

export interface INotificationState {
  notificationList: TNotification[];
  loading: boolean;
  error: unknown;
  pageInfo: PageInfo | null;
  isNextPageLoading: boolean;
  newCount: number;
  accountList: TAccountList[];
  notificationFilterDetails: INotificationFilterDetails;
  totalCount: number;
}

export enum NotificationTabType {
  TIMELINE = 'Timeline',
  BY_ACCOUNT = 'By Account',
  BY_TYPE = 'By Type',
}

export enum NotificationCardType {
  NOTIFICATION = 'Notification',
  END_NOTIFICATION = 'End Notification',
  INSIGHT_NOTIFICATION = 'Insight Notification',
}
