/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated-types/index';

import { api } from 'api/base-api';
module.hot?.accept();
export type GetCompanyInfoQueryVariables = Types.Exact<{
  companyName: Types.Scalars['String'];
}>;

export type GetCompanyInfoQuery = {
  __typename?: 'Query';
  companyQuery?: {
    __typename?: 'CompanyQuery';
    getCompanyByName:
      | {
          __typename: 'CompanyPayload';
          items?: Array<
            | {
                __typename?: 'Company';
                companyName: string;
                displayName: string;
                id: string;
                logo?:
                  | { __typename?: 'GraphqlError' }
                  | {
                      __typename?: 'Logo';
                      id: string;
                      fileName?: string;
                      url: string;
                    };
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: Types.ErrorType;
          message: string;
        };
  };
};

export const GetCompanyInfoDocument = `
    query getCompanyInfo($companyName: String!) {
  companyQuery {
    getCompanyByName(companyName: $companyName) {
      __typename
      ... on GraphqlError {
        code
        type
        message
      }
      ... on CompanyPayload {
        items {
          ... on Company {
            companyName
            displayName
            id
            logo {
              ... on Logo {
                id
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getCompanyInfo: build.query<
      GetCompanyInfoQuery,
      GetCompanyInfoQueryVariables
    >({
      query: (variables) => ({ document: GetCompanyInfoDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCompanyInfoQuery, useLazyGetCompanyInfoQuery } =
  injectedRtkApi;
