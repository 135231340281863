import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Connection, Edge, EdgeChange, Node, NodeChange } from 'reactflow';
import {
  MarkerType,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  updateEdge,
} from 'reactflow';
import { v4 as uuid } from 'uuid';
import { colors } from '../../common/theme-config';
import {
  AccountPlan,
  ActionType,
  ContactRole,
  GraphEdgeInput,
  GraphNodeInput,
  IRelationshipBaseItem,
  RelationshipContact,
  RelationshipGraphFetchType,
  RelationshipScopeType,
  StructureType,
  User,
} from '../../generated-types';
// eslint-disable-next-line import/no-cycle
import {
  getFormFieldValues,
  getInfluencerMapData,
  getStructureDetails,
  getUserCardDetail,
  getUserDetail,
} from './api-influencer';
import {
  DataPayload,
  EContactAttribute,
  EContactCard,
  ECreateContactAttribute,
  EEdgeAttribute,
  EFreeFlowCard,
  EUpdateContactAttribute,
  FGraphItemData,
  FieldProperty,
  GraphItemData,
  IAvatar,
  IFormField,
  IInfluencerState,
  ILinkedInData,
  IManager,
  TContactRole,
  TDropdownOptions,
  TNoteType,
  TNotesType,
  UserState,
  ValueType,
} from './models';
import { getFormFields, transformIntoStructuredNode } from './utils-influencer';
import { checkForReportsToChange } from './check-reportsTo-change';

export interface IBatchAction {
  actionType: ActionType;
  batchNumber: number;
}

function addEdgeActionType(
  edge: Edge | undefined,
  // batchNumber: number,
  // edgeActionList: Record<string, IBatchAction[]>,
) {
  if (edge) {
    const currentActionType =
      (edge as GraphEdgeInput).actionType === ActionType.CREATE
        ? ActionType.CREATE
        : ActionType.UPDATE;
    (edge as GraphEdgeInput).actionType = currentActionType;
    // const edgeId = edge.id;

    // if (!edgeActionList[edgeId]) {
    //   edgeActionList[edgeId] = [];
    // }
    // const actionList = edgeActionList[edgeId];
    // if (Array.isArray(actionList)) {
    //   actionList.push({
    //     actionType: currentActionType,
    //     batchNumber,
    //   });
    // }
  }
}

function addNodeActionType(
  node: Node,
  // batchNumber: number,
  // nodeActionList: Record<string, IBatchAction[]>,
) {
  if (node) {
    const currentActionType =
      (node as GraphNodeInput).actionType === ActionType.CREATE
        ? ActionType.CREATE
        : ActionType.UPDATE;
    (node as GraphNodeInput).actionType = currentActionType;
    // const nodeId = node.id;

    // if (!nodeActionList[nodeId]) {
    //   nodeActionList[nodeId] = [];
    // }
    // const actionList = nodeActionList[nodeId];
    // if (Array.isArray(actionList)) {
    //   actionList.push({
    //     actionType: currentActionType,
    //     batchNumber,
    //   });
    // }
  }
}

const influencerSlice = createSlice({
  name: 'influencer-slice-new',
  initialState: {
    batchNumber: 0,
    firstLoad: false,
    // isUserSideNavOpen: true,
    // nodeActionList: {} as Record<string, IBatchAction[]>,
    // edgeActionList: {} as Record<string, IBatchAction[]>,
  } as IInfluencerState,
  reducers: {
    onNodeChange: (
      state,
      action: PayloadAction<{ newNodes: NodeChange[] }>,
    ) => {
      const { newNodes } = action.payload;
      const prevNodes = state.nodes;
      const mergedNodes = applyNodeChanges(newNodes, prevNodes);
      const nodeIdList = newNodes.map((node) => (node as Node).id);

      mergedNodes.forEach((node) => {
        if (state.firstLoad && nodeIdList.includes(node.id))
          addNodeActionType(node);

        return node;
      });
      state.firstLoad = true;
      state.nodes = mergedNodes;
    },
    addNewNode: (state, action: PayloadAction<{ newNode: Node }>) => {
      const { newNode } = action.payload;
      state.nodes.push(newNode);
    },
    onEdgeChange: (
      state,
      action: PayloadAction<{ newEdges: EdgeChange[] }>,
    ) => {
      const { newEdges } = action.payload;
      const prevEdges = state.edges;
      const mergedEdges = applyEdgeChanges(newEdges, prevEdges);
      state.edges = mergedEdges;
    },
    onEdgeConnect: (state, action: PayloadAction<{ newEdge: Edge }>) => {
      const { newEdge } = action.payload;
      const prevEdges = state.edges;

      const edge = {
        ...newEdge,
        id: uuid(),
        actionType: ActionType.CREATE,
        data: { id: uuid(), dataPayload: [], metaData: null },
        style: { stroke: colors.mid[500] },
        markerEnd: { type: MarkerType.ArrowClosed },
      };
      const mergedEdges = addEdge(edge, prevEdges);
      state.edges = mergedEdges;
    },
    onEdgeUpdate: (
      state,
      action: PayloadAction<{ newConnection: Connection; oldEdge: Edge }>,
    ) => {
      const { newConnection, oldEdge } = action.payload;
      const prevEdges = state.edges;
      const mergedEdges = updateEdge(oldEdge, newConnection, prevEdges);
      state.edges = mergedEdges;
    },
    updateEdgeColor: (
      state,
      action: PayloadAction<{ edgeId: string; color: string }>,
    ) => {
      const { edgeId, color } = action.payload;
      const edgeList = state.edges;
      const index = edgeList.findIndex((edge) => edge.id === edgeId);
      if (index > -1) {
        edgeList[index] = {
          ...edgeList[index],
          style: { stroke: color },
          markerEnd: { type: MarkerType.ArrowClosed, color },
        };
        addEdgeActionType(
          edgeList[index],
          // state.batchNumber,
          // state.edgeActionList,
        );
      }
    },
    animateEdges: (state, action: PayloadAction<{ nodeId: string }>) => {
      const { nodeId } = action.payload;
      const edgeList = state.edges;
      edgeList.map((edge) => {
        if (
          edge.type === 'commentEdge' &&
          (edge.source === nodeId || edge.target === nodeId)
        ) {
          edge.animated = true;
        }
        if (
          edge.type === 'orgLine' &&
          (edge.source === nodeId || edge.target === nodeId)
        ) {
          edge.style = { ...edge.style, strokeWidth: 2.5 };
        }
        return edge;
      });
      state.edges = edgeList;
    },
    removeAnimateEdges: (state) => {
      state.edges = state.edges.map((edge) => ({
        ...edge,
        animated: false,
        style: { ...edge.style, strokeWidth: 1.5 },
      }));
    },
    toggleNodeHandlesVisibility: (state) => {
      state.isNodeHandlersVisible = !state?.isNodeHandlersVisible;
    },
    openEditDrawer: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.editingProfileId = id;
      state.isEditDrawerOpen = true;
    },
    closeEditDrawer: (state) => {
      if (
        state.editingProfileId &&
        Object.prototype.hasOwnProperty.call(
          state.userData,
          state.editingProfileId,
        )
      )
        delete state.userData[state.editingProfileId];

      state.editingProfileId = null;
      state.isEditDrawerOpen = false;
    },
    deleteEdge: (state, action: PayloadAction<{ edgeId: string }>) => {
      const { edgeId } = action.payload;
      const remainingEdges = state?.edges?.filter(
        (edge) => edge?.id !== edgeId,
      );
      state.edges = remainingEdges;
    },
    deleteMultipleEdges: (
      state,
      action: PayloadAction<{ edgeIdList: string[] }>,
    ) => {
      const { edgeIdList } = action.payload;
      const remainingEdges = state?.edges?.filter(
        (edge) => !edgeIdList?.includes(edge?.id),
      );
      state.edges = remainingEdges;
    },
    resetInfluencerData: (state) => {
      state.edges = [];
      state.nodes = [];
    },
    updateEditValues: (
      state,
      action: PayloadAction<{
        userId: string;
        fieldName: string;
        value: ValueType;
      }>,
    ) => {
      const { userId, fieldName, value } = action.payload;
      const { userData } = state;
      const values = userData[userId]?.fields;

      if (values && Object.prototype.hasOwnProperty.call(values, fieldName))
        values[fieldName] = value;
    },
    updateEdgeNote: (
      state,
      action: PayloadAction<{ edgeId: string; note: string; noteId?: string }>,
    ) => {
      const { edgeId, note, noteId } = action.payload;
      const edge = state?.edges?.find((edge) => edge?.id === edgeId);
      const dataPayload = (edge?.data as GraphItemData)?.dataPayload?.find(
        (data) => data.key === EEdgeAttribute.NOTES,
      );
      if (dataPayload) {
        dataPayload.id = noteId;
      }
      const value = dataPayload?.values?.[0];
      if (value) {
        value.value = note;
      }
      if (edge && noteId) {
        (edge.data as { resource: { id: string; type: string } }).resource = {
          id: noteId,
          type: 'NOTES',
        };
      }
      addEdgeActionType(edge);
    },
    addNewEdgeNote: (state, action: PayloadAction<{ edgeId: string }>) => {
      const { edgeId } = action.payload;
      const edge = state?.edges?.find((edge) => edge?.id === edgeId);
      if (edge && !edge?.data) {
        edge.data = {
          dataPayload: [
            {
              key: EEdgeAttribute.NOTES,
              values: [{ value: '' }],
              __typename: 'new-note',
            },
          ],
        };
      } else if (
        Array.isArray(edge && (edge?.data as GraphItemData)?.dataPayload)
      ) {
        (edge?.data as FGraphItemData).dataPayload?.push({
          key: EEdgeAttribute.NOTES,
          values: [{ value: '' }],
          __typename: 'new-note',
        });
      }
      addEdgeActionType(edge);
    },
    deleteEdgeNote: (state, action: PayloadAction<{ edgeId: string }>) => {
      const { edgeId } = action.payload;
      const edge = state?.edges?.find((edge) => edge?.id === edgeId);
      if (edge) {
        const noteIndex =
          (edge?.data as GraphItemData)?.dataPayload?.findIndex(
            (data) => data.key === EEdgeAttribute.NOTES,
          ) ?? -1;
        if (noteIndex > -1) {
          (edge?.data as GraphItemData)?.dataPayload?.splice(noteIndex, 1);
        }
        // addEdgeActionType(edge, state.batchNumber, state.edgeActionList);
      }
    },
    addGraphData: (
      state,
      action: PayloadAction<{ nodes: Node[]; edges: Edge[] }>,
    ) => {
      const { nodes, edges } = action.payload;

      const contactIds = nodes?.map((node) => {
        const contactId = (node?.data as GraphItemData)?.dataPayload?.find(
          (item) => item?.key === EContactCard.NAME,
        )?.id as string;

        return contactId || '';
      });

      if (contactIds.includes(state?.newContactIdOnCanvas as string)) {
        state.newContactIdOnCanvas = null;
      }

      state.nodes = nodes || [];
      state.edges = edges || [];
    },
    deleteUserNode: (state, action: PayloadAction<{ nodeId: string }>) => {
      const { nodeId } = action.payload;
      const nodeIndex = state?.nodes?.findIndex((node) => node?.id === nodeId);
      if (nodeIndex > -1) {
        state.nodes.splice(nodeIndex, 1);
        const updatedEdges = state.edges.filter(
          (edge) => !(edge.source === nodeId || edge.target === nodeId),
        );
        state.edges = updatedEdges;
      }
    },
    toggleReadOnly: (state) => {
      state.isReadonly = !state.isReadonly;
    },
    setReadOnly: (state) => {
      state.isReadonly = true;
    },
    crossUpdateGraphData: (
      state,
      action: PayloadAction<{ contactId: string; noteId?: string }>,
    ) => {
      try {
        const { contactId, noteId } = action.payload;
        const details = state.userData[contactId]?.fields;
        if (noteId && details) {
          const note = details[EUpdateContactAttribute.CARD_NOTES];
          if (note) {
            (note as TNoteType).id = noteId;
          }
        }
        if (details) {
          const entries = Object.entries(details);
          const nodeIndex = state?.nodes?.findIndex((node) => {
            const dataPayloadList = (node?.data as GraphItemData)?.dataPayload;
            const dataIndex =
              dataPayloadList?.findIndex((data) => data?.id === contactId) ??
              -1;
            return dataIndex > -1;
          });

          if (nodeIndex > -1) {
            const attributeList = [] as DataPayload[];
            for (let i = 0; i < entries?.length; i += 1) {
              const field = {} as DataPayload;
              const entry = entries[i];
              const [, key] = entry[0]?.split(':');
              const nodeValue = entry[1];
              field.key = `${StructureType.CONTACT_CARD}:${key}`;
              if (nodeValue) {
                field.id =
                  typeof nodeValue === 'string'
                    ? contactId
                    : (nodeValue as TDropdownOptions)?.id;

                const value =
                  key === EContactAttribute.AVATAR
                    ? (nodeValue as IAvatar)?.url
                    : typeof nodeValue === 'string'
                    ? nodeValue
                    : (nodeValue as TDropdownOptions)?.name ??
                      `${(nodeValue as User)?.firstName || ''}  ${
                        (nodeValue as User)?.lastName || ''
                      }` ??
                      (nodeValue as TNotesType)?.messageData?.message;

                field.values =
                  key === EContactAttribute.CONTACT_ROLE
                    ? (nodeValue as Record<string, string>[])?.map(
                        ({ id, name }) => ({ id, value: name }),
                      )
                    : key === EContactAttribute.LINKEDIN_URL
                    ? typeof nodeValue === 'string'
                      ? [
                          {
                            profileUrl: nodeValue,
                          } as ILinkedInData,
                        ]
                      : [nodeValue as ILinkedInData]
                    : [{ value, __typename: 'DataPayloadValue' }];
              } else {
                field.values = [];
              }
              field.__typename = 'DataPayload';

              attributeList.push(field);
            }
            addNodeActionType(state.nodes[nodeIndex]);

            (state.nodes[nodeIndex].data as GraphItemData).dataPayload =
              attributeList;
          }

          const userIndex = state?.userContactList?.findIndex(
            (contact) => contact?.id === contactId,
          );
          if (userIndex > -1) {
            const user = state.userContactList[userIndex];
            (user as IRelationshipBaseItem & { title: string }).title = details[
              EUpdateContactAttribute.TITLE
            ] as string;
            (
              user as IRelationshipBaseItem & {
                contactRoles: { items: ContactRole[] };
              }
            ).contactRoles = {
              items: [
                details[EUpdateContactAttribute.CONTACT_ROLE] as ContactRole,
              ],
            };
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    crossUpdateNodeData: (
      state,
      action: PayloadAction<{
        contactId: string;
        nodeId: string;
        fieldName: string;
        value: ValueType | ContactRole;
      }>,
    ) => {
      try {
        const { contactId, value: nodeValue, fieldName } = action.payload;

        const nodeIndex = state?.nodes?.findIndex((node) => {
          const dataPayloadList = (node?.data as GraphItemData)?.dataPayload;
          const dataIndex =
            dataPayloadList?.findIndex((data) => data?.id === contactId) ?? -1;
          return dataIndex > -1;
        });

        if (nodeIndex > -1) {
          const dataPayloadList = (
            state?.nodes[nodeIndex]?.data as GraphItemData
          )?.dataPayload;
          const dataItemIndex =
            dataPayloadList?.findIndex((data) => data?.key === fieldName) ?? -1;
          addNodeActionType(state.nodes[nodeIndex]);

          if (dataItemIndex > -1) {
            const dataPayload = (state.nodes[nodeIndex].data as GraphItemData)
              .dataPayload;

            if (dataPayload && dataPayload[dataItemIndex]) {
              const value =
                fieldName === EContactCard.LINKEDIN_URL
                  ? (nodeValue as ILinkedInData)
                  : typeof nodeValue === 'string' ||
                    typeof nodeValue === 'boolean'
                  ? nodeValue
                  : (nodeValue as TDropdownOptions)?.name ??
                    (nodeValue as TNotesType)?.messageData?.message;

              dataPayload[dataItemIndex].values =
                fieldName === EContactCard.CONTACT_ROLE
                  ? (nodeValue as TContactRole[])
                  : fieldName === EContactCard.LINKEDIN_URL
                  ? [
                      {
                        profileUrl: nodeValue,
                      } as ILinkedInData,
                    ]
                  : [{ value, __typename: 'DataPayloadValue' }];
            }
          } else {
            const field = {} as DataPayload;

            field.key = fieldName;
            if (nodeValue) {
              field.id =
                typeof nodeValue === 'string'
                  ? contactId
                  : (nodeValue as TDropdownOptions)?.id;
              const value =
                typeof nodeValue === 'string' || typeof nodeValue === 'boolean'
                  ? nodeValue
                  : (nodeValue as TDropdownOptions)?.name ??
                    (nodeValue as TNotesType)?.messageData?.message;
              field.values =
                fieldName === EContactCard.CONTACT_ROLE
                  ? (nodeValue as TContactRole[])
                  : [{ value, __typename: 'DataPayloadValue' }];
            } else {
              field.values = [];
            }
            field.__typename = 'DataPayload';

            // addNodeActionType(state.nodes[nodeIndex], state.batchNumber);
            (state.nodes[nodeIndex].data as GraphItemData).dataPayload = [
              ...((state.nodes[nodeIndex].data as GraphItemData).dataPayload ||
                []),
              field,
            ];
          }
        }

        if (fieldName === EUpdateContactAttribute.CONTACT_ROLE) {
          const userIndex = state?.userContactList?.findIndex(
            (contact) => contact?.id === contactId,
          );
          if (userIndex > -1) {
            const user = state.userContactList[userIndex];
            (
              user as IRelationshipBaseItem & {
                contactRoles: { items: ContactRole[] };
              }
            ).contactRoles = {
              items: [nodeValue as ContactRole],
            };
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    updateCreateUserValues: (
      state,
      action: PayloadAction<{
        fieldName: string;
        value: ValueType;
      }>,
    ) => {
      const { fieldName, value } = action.payload;
      if (
        state.createUserData &&
        Object.prototype.hasOwnProperty.call(state.createUserData, fieldName)
      ) {
        state.createUserData[fieldName] = value;
      } else {
        state.createUserData = { ...state.createUserData, [fieldName]: value };
      }
    },
    clearCreateUserValues: (state) => {
      state.createUserData = {};
    },
    crossUpdateUserList: (
      state,
      action: PayloadAction<{
        id: string;
        user: Record<string, ValueType>;
      }>,
    ) => {
      const { id, user } = action.payload;

      const formattedUser = Object.keys(user).reduce((curr, next) => {
        const [, fieldName] = next?.split(':');
        curr[fieldName] = user[next];
        return curr;
      }, {} as Record<string, ValueType | Record<string, string>>);

      const firstName = user[ECreateContactAttribute.FIRST_NAME] as string;
      const lastName = user[ECreateContactAttribute.LAST_NAME] as string;
      const email = user[ECreateContactAttribute.EMAIL] as string;
      const combinedName = `${firstName || ''}${
        lastName ? ` ${lastName}` : ''
      }`;
      const nameFromEmail = email?.split('@')?.[0];
      const name = combinedName || nameFromEmail || '';

      const selectedContactRole = formattedUser[EContactAttribute.CONTACT_ROLE];
      const formattedContactRole = {
        items: [
          {
            id: (selectedContactRole as Record<string, string>)?.id,
            name: (selectedContactRole as Record<string, string>)?.name,
          },
        ],
      };

      const newUser = {
        id,
        name,
        title: formattedUser?.[EContactAttribute.TITLE] || undefined,
        objectType: 'CONTACT',
        contactRoles: selectedContactRole ? formattedContactRole : undefined,
        description: 'NEW_CONTACT',
      } as RelationshipContact;

      state.userContactList = [newUser].concat(state.userContactList);
    },
    updateContactInitialFields: (
      state,
      action: PayloadAction<{
        contactId: string;
        initialFields: IFormField;
      }>,
    ) => {
      const { contactId, initialFields } = action.payload;
      if (
        Object.prototype.hasOwnProperty.call(state?.userData, contactId) &&
        state?.userData?.[contactId]?.initialFields
      )
        state.userData[contactId].initialFields = initialFields;
    },
    setFilters: (
      state,
      action: PayloadAction<{
        filterName: string;
        filterValue: string[] | boolean;
      }>,
    ) => {
      const { filterName, filterValue } = action.payload;

      state.filters = {
        ...(state.filters || {}),
        [filterName]: filterValue,
      };
    },
    setKeyStakeholderFilter: (
      state,
      action: PayloadAction<{
        isKeyStakeholderFiltered: boolean;
      }>,
    ) => {
      const { isKeyStakeholderFiltered } = action.payload;
      state.filterKeyStakeholders = isKeyStakeholderFiltered;
    },
    resetFilters: (state) => {
      state.filters = {};
      state.filterKeyStakeholders = false;
    },
    clearFilter: (
      state,
      action: PayloadAction<{
        filterName: string;
      }>,
    ) => {
      const { filterName } = action.payload;
      delete state.filters?.[filterName];
    },
    setScopeDetails: (
      state,
      action: PayloadAction<{ id: string; type: RelationshipScopeType }>,
    ) => {
      const { id, type } = action.payload;
      state.scopeId = id;
      state.scopeType = type;
    },
    setAccountDetails: (
      state,
      action: PayloadAction<{ account: AccountPlan }>,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { account } = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.accountDetails = account;
    },
    resetContactList: (state) => {
      state.userContactList = [];
    },
    setListCompanyNameVisibility: (
      state,
      action: PayloadAction<{ isListCompanyNameVisible: boolean }>,
    ) => {
      const { isListCompanyNameVisible } = action.payload;

      state.isListCompanyNameVisible = isListCompanyNameVisible;
    },
    setNodeCompanyNameVisibility: (
      state,
      action: PayloadAction<{ isNodeCompanyNameVisible: boolean }>,
    ) => {
      const { isNodeCompanyNameVisible } = action.payload;

      state.isNodeCompanyNameVisible = isNodeCompanyNameVisible;
    },
    setNodeDraggingFromList: (
      state,
      action: PayloadAction<{
        status: boolean;
        reportToId?: string | null;
        managerName?: string;
      }>,
    ) => {
      const { status, reportToId = null, managerName = '' } = action.payload;
      state.isNodeDraggingFromList = status;
      state.reportToIdDraggingFromList = reportToId;
      if (managerName) state.draggingCardManagerName = managerName;
      const userIdList = state.nodes.map((node) => {
        const dataPayload = (node?.data as FGraphItemData)?.dataPayload;
        const userId = dataPayload?.find(
          (item) => item?.key === EContactCard.NAME,
        )?.id as string;

        return userId;
      });
      if (userIdList?.includes(reportToId ?? '')) {
        state.isManagerPresentOnCanvas = true;
      }
    },
    clearNodeDraggingFromList: (state) => {
      state.isNodeDraggingFromList = false;
      state.reportToIdDraggingFromList = null;
      state.isManagerPresentOnCanvas = false;
    },
    clearIsManagerPresentOnCanvas: (state) => {
      state.isManagerPresentOnCanvas = false;
    },
    updateFloatingText: (
      state,
      action: PayloadAction<{ nodeId: string; text: string }>,
    ) => {
      const { text, nodeId } = action.payload;
      const node = state?.nodes?.find((node) => node?.id === nodeId);
      const dataPayload = (node?.data as GraphItemData)?.dataPayload?.find(
        (data) => data.key === EFreeFlowCard.TEXT,
      );
      const value = dataPayload?.values?.[0];
      if (value) {
        value.value = text;
      }
    },
    setIntroDialogVisibility: (state, action: PayloadAction<boolean>) => {
      state.isIntroDialogVisible = action.payload;
    },
    setImportDialogVisibility: (state, action: PayloadAction<boolean>) => {
      state.isImportDialogVisible = action.payload;
    },
    setIsCloningLoading: (state, action: PayloadAction<boolean>) => {
      state.isCloningLoading = action.payload;
    },
    setIsTextModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isTextModeEnabled = action.payload;
    },
    setSelectionEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSelectionEnabled = action.payload;
      state.isPanOnDragEnabled = !action.payload;
    },
    setPanOnDragStatus: (state, action: PayloadAction<boolean>) => {
      state.isPanOnDragEnabled = action.payload;
    },
    updateDraggableNodeStatus: (
      state,
      action: PayloadAction<{ nodeId: string; status: boolean }>,
    ) => {
      const { nodeId, status } = action.payload;
      const node = state?.nodes?.find((node) => node?.id === nodeId);
      if (node) {
        node.draggable = status;
      }
    },
    setZoomOnDoubleClickStatus: (state, action: PayloadAction<boolean>) => {
      state.isZoomOnDoubleClickEnabled = action.payload;
    },
    addNewEdge: (state, action: PayloadAction<{ newEdge: Edge }>) => {
      const { newEdge } = action.payload;
      state.edges.push(newEdge);
    },
    addMultipleEdges: (state, action: PayloadAction<{ edgeList: Edge[] }>) => {
      const { edgeList } = action.payload;
      state?.edges.push(...edgeList);
    },
    setUserSideNavStatus: (state, action: PayloadAction<boolean>) => {
      state.isUserSideNavOpen = action.payload;
    },
    incrementBatchNumber: (state) => {
      state.batchNumber += 1;
    },
    clearActionTypes: (state) => {
      try {
        state.nodes?.forEach((node) => {
          delete (node as GraphNodeInput).actionType;
        });
        state.edges?.forEach((edge) => {
          delete (edge as GraphEdgeInput).actionType;
        });
      } catch {}
      state.nodeActionList = {};
      state.edgeActionList = {};
    },
    mergeActionBatch: (state) => {
      state.nodes?.forEach((node) => {
        const actionList = state.nodeActionList[node.id] || [];

        const createActionIndex =
          actionList?.findIndex(
            (action) => action.actionType === ActionType.CREATE,
          ) ?? -1;

        // const deleteActionIndex =
        //   actionList?.findIndex(
        //     (action) => action.actionType === ActionType.DELETE,
        //   ) || -1;
        // if (deleteActionIndex > createActionIndex) {
        //   (node as GraphNodeInput).actionType = ActionType.DELETE;
        // } else

        if (createActionIndex > -1) {
          (node as GraphNodeInput).actionType = ActionType.CREATE;
        } else if (actionList?.length) {
          (node as GraphNodeInput).actionType = ActionType.UPDATE;
        }
      });

      state.edges?.forEach((edge) => {
        const actionList = state.edgeActionList[edge.id] || [];

        const createActionIndex =
          actionList?.findIndex(
            (action) => action.actionType === ActionType.CREATE,
          ) ?? -1;

        if (createActionIndex > -1) {
          (edge as GraphEdgeInput).actionType = ActionType.CREATE;
        } else if (actionList?.length) {
          (edge as GraphEdgeInput).actionType = ActionType.UPDATE;
        }
      });
    },
    setIntersectingNodeId: (state, action: PayloadAction<string>) => {
      state.intersectingNodeId = action.payload;
    },
    setDraggingNodeId: (state, action: PayloadAction<string>) => {
      state.draggingNodeId = action.payload;
    },
    saveDistinctTreeSet: (state, action: PayloadAction<string[][]>) => {
      state.distinctTreeSet = action.payload;
    },
    setManagerMismatchNodeId: (state, action: PayloadAction<string>) => {
      state.managerMismatchNodeId = action.payload;
    },
    setReportsToForNode: (
      state,
      action: PayloadAction<{ nodeId: string; managerDetails: IManager }>,
    ) => {
      const { nodeId, managerDetails } = action.payload;
      const userNode = state.nodes.find((node) => node.id === nodeId);
      if (userNode) {
        const userDataPayload = (userNode?.data as FGraphItemData)?.dataPayload;

        const userReportsTo = userDataPayload?.find(
          (item) => item?.key === EContactCard.REPORTS_TO,
        );
        if (userReportsTo) userReportsTo.values = [managerDetails];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerMapData.fulfilled, (state, { payload }) => {
        const { nodes, edges, fetchType } = payload;

        state.nodes = (nodes || []) as Node[];
        // state.edges = (edges || []) as Edge[];
        const updatedEdges = checkForReportsToChange({ nodes, edges });

        const edgeSetList: Set<string>[] = [];
        state.edges = updatedEdges?.filter(({ source = '', target = '' }) => {
          const present = edgeSetList.find(
            (set) => set.has(source) && set.has(target),
          );

          if (present) return false;

          edgeSetList.push(new Set([source, target]));
          return true;
        }) as Edge[];
        state.influencerDataError = null;
        state.influencerDataLoading = false;
        if (fetchType === RelationshipGraphFetchType.STATIC) {
          state.influencerDataStaticSuccess = true;
        }
      })
      .addCase(getInfluencerMapData.pending, (state) => {
        state.influencerDataLoading = true;
        // state.isReadonly = true;
      })
      .addCase(getInfluencerMapData.rejected, (state, { payload }) => {
        state.influencerDataError = (payload as Error)?.message;
        state.influencerDataLoading = false;
      })
      .addCase(getUserDetail.fulfilled, (state, { payload }) => {
        const { id, data } = payload;
        const contactFormStructure = state?.structureList?.find(
          (detail) => detail.structureType === StructureType.CONTACT_FORM,
        );
        if (contactFormStructure) {
          const fields = getFormFields(contactFormStructure, data);
          if (
            state?.userData &&
            Object.prototype.hasOwnProperty.call(state.userData, id)
          ) {
            state.userData[id].fields = fields;
            state.userData[id].initialFields = fields;
            state.userData[id].error = null;
            state.userData[id].loading = false;
          }
        }
      })
      .addCase(getUserDetail.pending, (state, payload) => {
        const id = payload?.meta?.arg?.id;
        if (!state?.userData) {
          state.userData = {};
        }
        state.userData[id] = {} as UserState;
        state.userData[id].loading = true;
      })
      .addCase(getUserDetail.rejected, (state, { payload }) => {
        const { id, message } = payload as {
          id: string;
          message: string;
        };
        if (state?.userData && state.userData[id]) {
          state.userData[id].error = message || 'Something went wrong';
          state.userData[id].loading = false;
        }
      })
      .addCase(getUserCardDetail.fulfilled, (state, { payload }) => {
        const { id, data, nodeId } = payload;
        const contactCardStructure = state?.structureList?.find(
          (detail) => detail.structureType === StructureType.CONTACT_CARD,
        );
        if (contactCardStructure) {
          const contactDetails = transformIntoStructuredNode({
            structure: contactCardStructure,
            input: data,
          });
          const nodeIndex = state?.nodes?.findIndex(
            (node) => node.id === nodeId,
          );
          if (nodeIndex > -1) {
            state.nodes[nodeIndex].data = {
              id: uuid(),
              resource: {
                id,
                type: 'CONTACT',
              },
              dataPayload: contactDetails.map(({ key, value }) => {
                if (typeof value === 'object') {
                  const newValue = value as {
                    id: string;
                    name: string;
                  };

                  const displayValue =
                    key === EContactCard.AVATAR
                      ? (value as IAvatar)?.url
                      : newValue?.name ??
                        (value as TDropdownOptions)?.displayName ??
                        (((value as User)?.firstName
                          ? `${(value as User)?.firstName || ''}  ${
                              (value as User)?.lastName || ''
                            }`
                          : '') ||
                          (newValue as unknown as TNotesType)?.messageData
                            ?.message);

                  const values =
                    key === EContactCard.CONTACT_ROLE
                      ? newValue
                      : key === EContactCard.LINKEDIN_URL
                      ? [value]
                      : [
                          {
                            value: displayValue,
                          },
                        ];

                  return {
                    key,
                    id: newValue?.id,
                    values,
                  };
                }
                return { key, id, values: [{ value }] };
              }),
              metaData: {
                structureId: contactCardStructure.id,
                structureType: contactCardStructure.structureType,
              },
            };

            state.newContactIdOnCanvas = id;
          }
        }
      })
      .addCase(getUserCardDetail.rejected, (state, { payload }) => {
        const { id, message } = payload as {
          id: string;
          message: string;
        };
        if (state?.userData && state.userData[id]) {
          state.userData[id].error = message || 'Something went wrong';
          state.userData[id].loading = false;
        }
      })
      .addCase(getStructureDetails.fulfilled, (state, { payload }) => {
        const { structureList } = payload;
        state.structureList = structureList;
        state.structureError = null;
        state.structureLoading = false;
      })
      .addCase(getStructureDetails.pending, (state) => {
        state.structureLoading = true;
      })
      .addCase(getStructureDetails.rejected, (state, { payload }) => {
        state.structureError = (payload as Error)?.message;
        state.structureLoading = false;
      })
      .addCase(getFormFieldValues.fulfilled, (state, { payload }) => {
        const { fieldValues } = payload;
        state.formFieldValuesList = fieldValues as FieldProperty;
        state.formFieldValuesError = null;
        state.formFieldValuesLoading = false;
      })
      .addCase(getFormFieldValues.pending, (state) => {
        state.formFieldValuesLoading = true;
      })
      .addCase(getFormFieldValues.rejected, (state, { payload }) => {
        state.formFieldValuesError = (payload as Error)?.message;
        state.formFieldValuesLoading = false;
      });
  },
});

export const {
  onNodeChange,
  onEdgeChange,
  onEdgeConnect,
  addNewNode,
  resetInfluencerData,
  onEdgeUpdate,
  updateEdgeColor,
  toggleNodeHandlesVisibility,
  deleteEdge,
  updateEdgeNote,
  openEditDrawer,
  addNewEdgeNote,
  deleteEdgeNote,
  updateEditValues,
  addGraphData,
  deleteUserNode,
  crossUpdateGraphData,
  toggleReadOnly,
  animateEdges,
  removeAnimateEdges,
  setReadOnly,
  closeEditDrawer,
  updateCreateUserValues,
  clearCreateUserValues,
  updateContactInitialFields,
  crossUpdateNodeData,
  setFilters,
  resetFilters,
  clearFilter,
  setKeyStakeholderFilter,
  crossUpdateUserList,
  setScopeDetails,
  setAccountDetails,
  resetContactList,
  setListCompanyNameVisibility,
  setNodeCompanyNameVisibility,
  setNodeDraggingFromList,
  updateFloatingText,
  setIntroDialogVisibility,
  setImportDialogVisibility,
  setIsCloningLoading,
  setIsTextModeEnabled,
  setSelectionEnabled,
  updateDraggableNodeStatus,
  setPanOnDragStatus,
  setZoomOnDoubleClickStatus,
  addNewEdge,
  setUserSideNavStatus,
  incrementBatchNumber,
  mergeActionBatch,
  clearActionTypes,
  setIntersectingNodeId,
  clearNodeDraggingFromList,
  clearIsManagerPresentOnCanvas,
  addMultipleEdges,
  saveDistinctTreeSet,
  deleteMultipleEdges,
  setDraggingNodeId,
  setManagerMismatchNodeId,
  setReportsToForNode,
} = influencerSlice.actions;

export default influencerSlice.reducer;
