import { useLocation } from 'react-router-dom';

export default function useGetQueryParams() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export function getGetQueryParamsWithTaskId(taskId: string) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.append('taskId', taskId);

  return queryParams.toString();
}

export function getGetQueryParamsWithMilestoneId(milestoneId: string) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.append('milestoneId', milestoneId);

  return queryParams.toString();
}
