import { HTMLAttributes } from 'react';

interface IDropDownItemProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
}

export const DROP_DOWN_LAYOUT = 'flex items-center space-x-2 px-4 py-2';
export function DropDownItem({
  children,
  className,
  ...props
}: IDropDownItemProps) {
  return (
    <div
      className={`cursor-pointer hover:bg-primary-100 ${DROP_DOWN_LAYOUT} ${
        className ?? ''
      }`}
      {...props}
    >
      {children}
    </div>
  );
}
export default DropDownItem;
