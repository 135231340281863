import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Popover from '@mui/material/Popover';
import { ErrorMessage } from '../milestone-drawer/milestone-create-drawer/milestone-search/common';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../common/redux-store';
import { Typography } from '../../components';

type MessageType = {
  id: string;
  defaultMessage: string;
};

const mapState = (state: RootState) => ({
  archivalReasonList: state?.dashboard?.archivalReasonList,
});

const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PopoverProps {
  anchorEl: Element | null;
  warningTitle: MessageType;
  onClose: () => void;
  onSubmit: () => void;
  archivalReasonValue: string;
  setArchivalReasonValue: React.Dispatch<React.SetStateAction<string>>;
  otherArchivalReasonValue: string;
  setOtherArchivalReasonValue: React.Dispatch<React.SetStateAction<string>>;
}

function MspArchivalPopover(props: PopoverProps & PropsFromRedux) {
  const {
    anchorEl,
    warningTitle,
    onClose,
    onSubmit,
    archivalReasonList,
    archivalReasonValue,
    setArchivalReasonValue,
    otherArchivalReasonValue,
    setOtherArchivalReasonValue,
  } = props;
  const handleChange = useCallback((event: { target: { value: string } }) => {
    setOtherArchivalReasonValue('');
    setArchivalReasonValue(event?.target?.value);
  }, []);
  const [isError, setIsError] = useState(false);
  const reasonTextAreaClasses =
    'border border-solid border-[#DCE0E3] rounded w-[22rem] outline-0	px-[11px] min-h-[80px] content-start items-start justify-start py-2';

  const checkFormValidation = useCallback(() => {
    if (
      archivalReasonValue !== 'OTHER' ||
      (archivalReasonValue === 'OTHER' && otherArchivalReasonValue)
    ) {
      onSubmit();
    } else {
      setIsError(true);
    }
  }, [onSubmit, setIsError]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => {
        setArchivalReasonValue(archivalReasonList?.[0]?.id);
        setOtherArchivalReasonValue('');
        setIsError(false);
        onClose();
      }}
      classes={{
        paper: `!top-0 border-t-0 border-t-transparent border-[#EDF1F5] w-[400px] border border-solid bg-white cursor-default rounded-t-none !shadow-[0_4px_24px_0px_#00000012]`,
      }}
      BackdropProps={{ style: { backgroundColor: '#000000', opacity: 0.2 } }}
    >
      <div className="flex flex-row justify-start">
        <div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <Typography
                  variant="heading5-bold"
                  className="mt-2
                leading-6 tracking-[-0.0112em] text-warning"
                >
                  <FormattedMessage
                    id={warningTitle?.id}
                    defaultMessage={warningTitle?.defaultMessage}
                  />
                </Typography>
              </div>
              <div className="mt-2">
                <RadioGroup
                  name="use-radio-group"
                  defaultValue={archivalReasonList?.[0]?.id}
                  onChange={handleChange}
                >
                  {archivalReasonList.map(({ id, displayName }) => (
                    <FormControlLabel
                      key={id}
                      value={id}
                      label={displayName}
                      control={
                        <Radio
                          sx={{
                            color: 'LightGray',
                            '& .MuiSvgIcon-root': {
                              borderRadius: '50%',
                              width: 22,
                              height: 22,
                              background: 'rgba(5, 16, 51, 0.0001)',
                            },
                          }}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </div>
              {archivalReasonValue === archivalReasonList?.[2]?.id && (
                <>
                  <div className="mt-2">
                    <TextField
                      multiline
                      variant="outlined"
                      className="meddic-notes"
                      fullWidth
                      InputProps={{
                        classes: {
                          multiline: reasonTextAreaClasses,
                        },
                      }}
                      defaultValue="Small"
                      size="small"
                      placeholder="Type reason here..."
                      value={otherArchivalReasonValue}
                      onChange={(e) => {
                        setIsError(false);
                        setOtherArchivalReasonValue(e.target.value);
                      }}
                    />
                  </div>
                  {isError && <ErrorMessage error="Please enter a reason" />}
                </>
              )}
            </DialogContentText>
          </DialogContent>
        </div>
      </div>

      <DialogActions className="mb-4">
        <Button
          className="mr-1 cursor-pointer border border-solid border-mid-200 px-9 text-mid-500"
          onClick={() => {
            setArchivalReasonValue(archivalReasonList?.[0]?.id);
            setOtherArchivalReasonValue('');
            setIsError(false);
            onClose();
          }}
        >
          <FormattedMessage id="Button.cancel" defaultMessage="Cancel" />
        </Button>

        <Button
          onClick={checkFormValidation}
          className="cursor-pointer bg-warning px-9 text-white hover:bg-warning hover:text-white"
        >
          <FormattedMessage id="Button.archive" defaultMessage="Archive" />
        </Button>
      </DialogActions>
    </Popover>
  );
}

export default connector(MspArchivalPopover);
