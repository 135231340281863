import Tooltip from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
    padding: '6px',
  },
}));
interface CustomTooltipProps {
  delay?: number;
  showTruncated?: boolean;
}

interface IRefProps {
  closeTooltip: () => void;
}

let disableHoverToolTip = false;
type CustomTooltipPropsAll = JSX.IntrinsicAttributes &
  TooltipProps &
  CustomTooltipProps;

// eslint-disable-next-line react/display-name
export const CustomToolTip = forwardRef<IRefProps, CustomTooltipPropsAll>(
  (props, ref) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
      disableHoverToolTip = false;
    };
    const handleOpen = (e: React.ChangeEvent<unknown>) => {
      setOpen(true);
      if (e.type === 'focus' || e.type === 'blur') {
        disableHoverToolTip = true;
        setOpen(false);
      }
      disableHoverToolTip = false;
    };
    const classes = useStylesBootstrap();

    const { title, children, showTruncated = false, ...rest } = props;
    const [isTooltipVisible, setTooltipVisibility] = useState(true);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (showTruncated) {
        const childNode = contentRef?.current?.childNodes[0]?.childNodes[0];
        // eslint-disable-next-line
        const horizontalSroll = childNode?.offsetWidth < childNode?.scrollWidth;
        setTooltipVisibility(horizontalSroll);
      }
    }, [showTruncated]);

    useImperativeHandle(ref, () => ({
      closeTooltip() {
        handleClose();
      },
    }));

    return (
      <Tooltip
        open={open}
        disableHoverListener={disableHoverToolTip}
        onClose={handleClose}
        onOpen={handleOpen}
        classes={classes}
        arrow
        placement="top"
        title={isTooltipVisible ? title : ''}
        {...rest}
      >
        <div
          ref={contentRef}
          className={clsx(showTruncated && 'truncate', 'flex')}
        >
          {children}
        </div>
      </Tooltip>
    );
  },
);
