import { Button, Dialog, Tab, Tabs } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { Dispatch } from '@reduxjs/toolkit';
import { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import CreateNewUser from '../../alignx/msp-view/milestone-details/new-create-user';
import { AssigneeSearchTabType } from '../../alignx/msp-view/model';
import { colorPalette } from '../../common/newTheme';
import { RootState } from '../../common/redux-store';
import { setSelectedTabValue } from '../../common/redux-store/reducers/search-user-reducer';
import { Company, User } from '../../generated-types';
import { IoIosCloseCircleOutline } from '../icons';
import { Typography } from '../typography/index';
import { useSearchUser } from './api-hooks';
import SearchAddUser from './search-component';
import { MilestoneAssigneeCardContext } from '../../alignx/msp-view/milestone-details/api-hook';
import { getFullName } from '../../common/utilities/utils';

export enum SearchType {
  STAKEHOLDER = 'STAKEHOLDER',
  ADDTASK = 'ADDTASK',
  MEDDIC = 'MEDDIC',
}

interface IAssignSearchUser {
  companyDetails?: Company | undefined;
  title: string;
  userList: User[];
  initialUserSelected?: User[];
  onSubmit: (selectedUser: User[]) => void;
  onClose: () => void;
  userLimit: number;
  searchType: SearchType;
  addPlaceholder?: () => void;
  tabList?: { label: string; value: string }[];
  isMulti?: boolean;
  isPlaceHolderExist?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  selectedUser: state.searchComponent.selectedUser,
  searchText: state.searchComponent.searchText,
  selectedTab: state.searchComponent.taskAssigneeSelectedTab,
  isNewUserAdded: state?.searchComponent?.isNewUserAdded,
  mspId: state?.searchComponent?.mspId,
});

const buttonMessage = {
  id: 'Button.addUser',
  defaultMessage: 'Add new user',
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedTabValue,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector> & IAssignSearchUser;

function AssignSearchUser(props: PropsFromRedux) {
  const {
    userList,
    searchText,
    onSubmit,
    searchType,
    title,
    onClose,
    initialUserSelected,
    userLimit = 1,
    companyDetails,
    selectedUser,
    addPlaceholder,
    tabList,
    isMulti = false,
    isPlaceHolderExist = false,
    setSelectedTabValue,
    selectedTab,
    isNewUserAdded,
    mspId,
  } = props;
  const { setMilestoneAssigneeData, milestoneAssigneeData } = useContext(
    MilestoneAssigneeCardContext,
  );

  const onNewUserOpen = useCallback(() => {
    setMilestoneAssigneeData?.({
      ...milestoneAssigneeData,
      isAddUserCardOpen: true,
    });
    setNewUserFlag(true);
  }, []);
  const onNewUserClose = useCallback(() => {
    setMilestoneAssigneeData?.({
      ...milestoneAssigneeData,
      isAddUserCardOpen: false,
    });
    setNewUserFlag(false);
  }, []);

  const {
    searchList,
    placeholderSearchList,
    onSearch,
    createUser,
    loading = false,
    error,
    onPlaceholderSearch,
  } = useSearchUser({
    companyDetails,
    onNewUserClose,
    selectedUser,
    mspId,
    onSubmit,
    onClose,
    searchType,
  });

  const isPlaceholderTabSelected =
    selectedTab === AssigneeSearchTabType.PLACEHOLDER;
  let userSearchList = searchList;
  if (isPlaceholderTabSelected) {
    userSearchList = placeholderSearchList;
  }
  const [isAddNewUser, setNewUserFlag] = useState(false);
  const [isAssignButtonVisible, setAssignButtonVisibility] = useState(true);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: AssigneeSearchTabType,
  ) => {
    event.stopPropagation();
    setSelectedTabValue(newValue);
  };

  const filterUserList = (userList: User[]) => {
    return userList
      ?.slice()
      ?.sort((firstUser, secondUser) =>
        getFullName(firstUser) > getFullName(secondUser) ? 1 : -1,
      );
  };

  // It will hide only if the variable is true make it true
  // Make it false when addded
  useEffect(() => {
    const isUserAssignButtonVisible =
      userLimit === 1 && searchType === SearchType.ADDTASK && !isNewUserAdded;
    setAssignButtonVisibility(isUserAssignButtonVisible);
  }, [searchType, userLimit, searchText, isNewUserAdded]);

  const onClickPlaceholder = useCallback(() => {
    if (addPlaceholder) {
      addPlaceholder();
    }
  }, []);

  const onAssign = useCallback(() => {
    onSubmit(selectedUser);
  }, [onSubmit, selectedUser]);

  return (
    <>
      {isAddNewUser && (
        <Dialog open={isAddNewUser}>
          <CreateNewUser
            loading={false}
            onBack={onNewUserClose}
            onSubmit={createUser}
            companyId={companyDetails?.id ?? ''}
            buttonMessage={buttonMessage}
          />
        </Dialog>
      )}
      <div className="p-4">
        {searchType !== SearchType?.ADDTASK && (
          <div className="flex items-center justify-between px-1">
            <Typography
              variant="body-14-bold"
              className="m-2.5 mb-4 text-mid-600"
            >
              {title}
            </Typography>
            <IoIosCloseCircleOutline
              className="cursor-pointer pb-2"
              color={colorPalette.mid.main}
              onClick={() => onClose()}
              size={20}
            />
          </div>
        )}
        {searchType === SearchType.ADDTASK && tabList?.length !== 0 && (
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="dashboard tabs"
            className="dashboard-tab-section mb-3 pl-5"
          >
            {tabList?.map(({ label, value }) => (
              <Tab data-test={label} value={value} key={label} label={label} />
            ))}
          </Tabs>
        )}
        <SearchAddUser
          userList={searchText ? userSearchList : filterUserList(userList)}
          isLoading={loading}
          onSearch={onSearch}
          onPlaceholderSearch={onPlaceholderSearch}
          userLimit={userLimit}
          error={error}
          initialSelectedUsers={initialUserSelected as User[]}
          isMulti={isMulti}
          searchType={searchType}
          onUserPlaceholderAssignInTask={onSubmit}
          onNewUserOpen={onNewUserOpen}
          inputPlaceholderMessage={
            selectedTab === AssigneeSearchTabType.PLACEHOLDER
              ? 'Search placeholder'
              : 'Search Assignee'
          }
        />
        <div
          className={`flex ${
            searchType === SearchType.STAKEHOLDER && !isPlaceHolderExist
              ? `${
                  selectedTab === AssigneeSearchTabType.PLACEHOLDER
                    ? 'justify-end'
                    : 'justify-between'
                }`
              : 'flex-row-reverse'
          } m-2`}
        >
          {searchType === SearchType.STAKEHOLDER && !isPlaceHolderExist && (
            <Button variant="text" color="primary" onClick={onClickPlaceholder}>
              + Placeholder
            </Button>
          )}
          {!isAssignButtonVisible && (
            <Button
              variant="contained"
              color="primary"
              onClick={onAssign}
              disabled={!selectedUser.length}
            >
              Assign
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default connector(AssignSearchUser);
