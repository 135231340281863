import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { showToast } from '../../components/hooks/use-toastify';
import { User } from '../../generated-types';

export const customEllipses = (text: string, limit: number) => {
  if (!text) return '';
  if (text.length < limit) {
    return text;
  }
  const dotIndex = text.lastIndexOf('.');
  const endsWith = text.slice(dotIndex - 2, dotIndex);
  const initials = text.slice(0, limit);
  if (initials.length >= dotIndex - 2) {
    return text;
  }
  return `${initials}...${endsWith}`;
};

export const getTruncatedName = (text: string, limit: number) => {
  if (!text) return '';
  if (text.length < limit) {
    return text;
  }
  const lastIndex = text.length - 1;
  const endsWith = text.slice(lastIndex - 2);
  const initials = text.slice(0, limit);
  if (initials.length >= lastIndex - 2) {
    return text;
  }
  return `${initials}...${endsWith}`;
};

export const capitalizeString = (text: string) =>
  text?.replace(/\w\S*/g, (w) => w?.replace(/^\w/, (c) => c?.toUpperCase()));

export function sortArray<type>(
  arr: Array<type>,
  key: any,
  order: 'asc' | 'desc',
) {
  return ([] as type[]).concat(arr).sort((a, b) => {
    const c = a?.[key as keyof typeof a] ?? '';
    const d = b?.[key as keyof typeof b] ?? '';

    if (order === 'asc') {
      if (typeof c === 'string' && typeof d === 'string') {
        return c?.trim()?.localeCompare(d?.trim());
      }
      if (typeof c === 'number' && typeof d === 'number') {
        return c - d;
      }

      return 1;
    }
    if (typeof c === 'string' && typeof d === 'string') {
      return c?.trim()?.localeCompare(d?.trim()) * -1;
    }
    if (typeof c === 'number' && typeof d === 'number') {
      return d - c;
    }

    return 1;
  });
}

export function useInterval(callback: CallableFunction, delay: number) {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    // eslint-disable-next-line react/display-name
    return () => null;
  }, [delay]);
}

type IPaginationProps = {
  handleEndReached: () => void;
};

export function usePaginationHooks(props: IPaginationProps) {
  const { handleEndReached } = props;
  const [lastElement, setLastElement] = useState(null);

  const handleObserver: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries?.[0];
      if (target.isIntersecting) {
        handleEndReached();
      }
    },
    [handleEndReached],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (lastElement) observer.observe(lastElement);
  }, [handleObserver, lastElement]);

  return { setLastElement };
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string) => email && emailRegex.test(email);

export const copyLink = (props: {
  uspId: string;
  milestoneId?: string;
  taskId?: string;
}) => {
  const { uspId, milestoneId, taskId } = props;
  const queryParams = new URLSearchParams(location.search);
  const queryMilestoneId = queryParams.get('milestoneId');
  const queryTaskId = queryParams.get('taskId');

  if (taskId && !queryTaskId) {
    queryParams.append('taskId', taskId);
  }
  if (milestoneId && !queryMilestoneId) {
    queryParams.append('milestoneId', milestoneId);
  }
  if (uspId) {
    queryParams.append('uspid', uspId);
  }

  const link = `${window.location.origin}${window.location.pathname}${
    queryParams.toString() ? `?${queryParams.toString()}` : ''
  }`;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  navigator.clipboard.writeText(link);
  showToast(
    <FormattedMessage id="Snackbar.linkCopied" defaultMessage="URL copied" />,
    {
      variant: 'info',
    },
  );
  trackEvent(TRACKING_CONSTANTS.COPIED_SHARE_ASSIGNEE_PRIVATE_LINK, {
    link,
    milestoneId,
    taskId,
  });
};

export function getFullName(user: User) {
  const { firstName, lastName, email } = user;
  return `${firstName ?? ''}${lastName ?? ''}`
    ? `${firstName ?? ''} ${lastName ?? ''}`
    : email.split('@')?.[0] ?? '';
}

export function isVvsInstance() {
  const url = window.location.href;
  return url.includes('https://vvs.') || url.includes('https://originals.');
}

export function getChunksFromString(str: string, size: number) {
  return str.match(new RegExp(`.{1,${size}}`, 'g'));
}

const LINKEDIN_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(https:\/\/)?[a-z]{2,3}.linkedin.com\/in\/\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/;

export function isValidLinkedInLink(profileLink: string) {
  return profileLink && LINKEDIN_URL_REGEX.test(profileLink);
}

export function openUrlInNewTab(url: string) {
  window.open(url, '_blank');
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string) {
  try {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  } catch (e) {
    console.warn(e);
    fallbackCopyTextToClipboard(text);
  }
}
