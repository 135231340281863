import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CustomToolTip,
  Divider,
  NewAvatar,
  Typography,
} from '../../../components';
import { TaskStatusIcon } from '../../../components/task-status-menu';
import {
  AnalyticsTaskAssignee,
  AnalyticsUser,
  Placeholder,
  TaskStatus,
} from '../../../generated-types';
import AddAssigneeSvg from '../../../assets/add-assignee.svg';
import { toolTip } from '../../../common-language';

interface ListItemInterface {
  taskId: string;
  taskStatus: TaskStatus;
  itemDetail: string;
  userDetail: AnalyticsTaskAssignee;
  dueDate: string;
  mspId: string;
}

export default function UpcomingTaskListItem({
  taskId,
  taskStatus,
  itemDetail,
  userDetail,
  dueDate,
  mspId,
}: ListItemInterface) {
  const history = useHistory();
  const location = useLocation();
  const navigateToTaskDetailPage = useCallback(
    (mspId: string, taskId: string) => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.append('taskId', taskId);
      history.push({
        pathname: `/ui/alignx/msp/${mspId}/plan`,
        search: queryParams.toString(),
      });
      // history.push(`/ui/alignx/msp/${mspId}/plan?taskId=${taskId}`);
    },
    [history],
  );
  const userName = (userDetail as Placeholder)?.name
    ? (userDetail as Placeholder).name
    : `${
        (userDetail as AnalyticsUser)?.firstName ??
        (userDetail as AnalyticsUser)?.email.split('@')?.[0]
      } ${(userDetail as AnalyticsUser)?.lastName ?? ''}`;
  const userType = (userDetail as AnalyticsUser)?.userType ?? '';
  return (
    <>
      <div
        className="grid cursor-pointer grid-cols-12 px-8 pt-2 pb-2 hover:bg-gray-100"
        onClick={() => navigateToTaskDetailPage(mspId, taskId)}
        role="presentation"
      >
        <div className="col-span-7">
          <div className="flex items-center space-x-3">
            <div className="col-span-1">
              <TaskStatusIcon taskStatus={taskStatus} />
            </div>
            <CustomToolTip
              showTruncated
              arrow
              placement="top"
              title={itemDetail}
            >
              <div className="truncate">
                <Typography
                  variant="body-13-medium"
                  className="mt-0.5 w-full items-center truncate text-[#445561]"
                >
                  {itemDetail}
                </Typography>
              </div>
            </CustomToolTip>
          </div>
        </div>
        <div className="col-span-3 col-start-8 flex justify-center">
          {userDetail && userType && (
            <CustomToolTip arrow placement="top-start" title={userName}>
              <NewAvatar
                size="small"
                fullName={userName}
                email={(userDetail as AnalyticsUser)?.email ?? ''}
                className=" mr-14 text-neutral-300"
                userType={userType}
              />
            </CustomToolTip>
          )}
          {userDetail && !userType && (
            <CustomToolTip arrow placement="top" title={userName}>
              <NewAvatar
                size="small"
                fullName={userName}
                email={(userDetail as AnalyticsUser)?.email ?? ''}
                className=" mr-14 text-neutral-300"
              />
            </CustomToolTip>
          )}
          {!userDetail && (
            <div className="mr-14 opacity-50">
              <img alt="add-assignee" src={AddAssigneeSvg as string} />
            </div>
          )}
        </div>
        <div className="col-span-2 col-start-11">
          <div className="flex items-center justify-end space-x-2">
            <CustomToolTip title={toolTip['MspPlanView.taskDueDate']}>
              <Typography variant="body-13-medium" className="text-[#879FB7]">
                {dueDate}
              </Typography>
            </CustomToolTip>
          </div>
        </div>
      </div>

      <Divider />
    </>
  );
}
