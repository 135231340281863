import {
  api as generatedApi,
  MspAnalyticsHighlights,
  Query,
} from '../../../../api';

export const extendedApi = generatedApi.enhanceEndpoints({
  endpoints: {
    getMspAnalyticsHighlights: {
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 3600,
      transformResponse: (response: Query) => {
        const data = response?.mspAnalyticsQuery?.getMspAnalyticsHighlights;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as MspAnalyticsHighlights;
      },
    },
  },
});

export const {
  useGetMspAnalyticsHighlightsQuery,
  useLazyGetMspAnalyticsHighlightsQuery,
} = extendedApi;
