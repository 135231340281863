import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const CREATE_ROLE = gql`
  ${CORE_GRAPH_ERROR}
  mutation createRole($input: CreateRoleInput!) {
    roleMutation {
      createRole(input: $input) {
        __typename
        ...CoreErrorFields
        ... on RolePayload {
          items {
            ... on Role {
              id
              name
              description
              scopeId
              scopeType
              description
              createdAt
              companyType
              isHidden
              members {
                ... on RoleMembers {
                  sellerMembers {
                    ... on Members {
                      stakeholders {
                        ... on Stakeholder {
                          id
                          firstName
                          lastName
                          email
                        }
                      }
                      placeholders {
                        ... on Placeholder {
                          id
                          name
                          description
                          orgId
                        }
                      }
                    }
                  }
                  buyerMembers {
                    ... on Members {
                      stakeholders {
                        ... on Stakeholder {
                          id
                          firstName
                          lastName
                          email
                        }
                      }
                      placeholders {
                        ... on Placeholder {
                          id
                          name
                          description
                          orgId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  ${CORE_GRAPH_ERROR}
  mutation UpdateRole($id: ID!, $input: UpdateRoleInput!) {
    roleMutation {
      updateRole(id: $id, input: $input) {
        __typename
        ...CoreErrorFields
        ... on RolePayload {
          items {
            ... on Role {
              id
              name
              description
              scopeId
              scopeType
              description
              createdAt
              isHidden
              companyType
            }
          }
        }
      }
    }
  }
`;
