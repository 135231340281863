import { TOption } from './../../components/ba-select/common';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { checkNonSpacePresent } from '../../common/methods/check-non-space-present';
import { Company, Msp, Opportunity } from '../../generated-types';
import {
  fetchCompanies,
  fetchOpportunities,
  fetchTemplates,
  fetchCompanyDetails,
} from './api';
import { IMspCreationStore, lastTemplateOption, NETWORK_ERROR } from './models';

export const initialState: IMspCreationStore = {
  opportunityList: undefined,
  selectedOpportunity: undefined,
  opportunityLoading: false,
  opportunityError: undefined as unknown,
  templateLoading: false,
  templateError: undefined as unknown,
  selectedTemplate: undefined,
  templateList: undefined,
  companyLoading: false,
  companyError: undefined,
  selectedCompany: null,
  companyList: undefined,
  selectedDate: dayjs().unix(),
  displayName: '',
  displayNameError: '',
  selectedDateError: '',
  isFromValid: false,
  companyDetailsError: undefined as unknown,
  companyDetailsLoading: false,
  companyDetails: undefined,
  companySearchedTerm: undefined,
  isOpportunityDisabled: false,
};

const mspCreationSlice = createSlice({
  name: 'msp-creation',
  initialState,
  reducers: {
    setSelectedOpportunity: (
      state,
      action: PayloadAction<Opportunity | undefined>,
    ) => {
      state.selectedOpportunity = action.payload;
      if (
        action.payload?.name === 'Opportunity not listed here' ||
        !action.payload
      ) {
        state.companyDetails = undefined;
      }
    },
    setSelectedTemplate: (state, action: PayloadAction<TOption | null>) => {
      state.selectedTemplate = action.payload as Msp;
      state.templateError = '';
    },
    resetSelectedOpportunity: (state) => {
      state.selectedOpportunity = undefined;
      state.opportunityError = '';
    },
    setSelectedCompany: (state, action: PayloadAction<TOption | null>) => {
      state.selectedCompany = action.payload;
      state.companyError = '';
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.selectedDate = action.payload;
      state.selectedDateError = '';
    },
    setDisplayName: (state, action: PayloadAction<string | undefined>) => {
      state.displayName = action.payload;
      state.displayNameError = '';
    },
    validateForm: (state) => {
      if (!state.selectedTemplate) {
        state.templateError = 'Please select a template';
        state.isFromValid = false;
      }
      if (!state.selectedCompany && !state.companySearchedTerm) {
        state.companyError = 'Please select a company';
        state.isFromValid = false;
      }
      const isDisplayNameValid = checkNonSpacePresent(state.displayName);
      if (!isDisplayNameValid) {
        state.displayNameError = 'Please enter display name';
        state.isFromValid = false;
      }
      if (!state.selectedDate) {
        state.selectedDateError = 'Please select a start date';
        state.isFromValid = false;
      }
      if (
        state.selectedTemplate &&
        state.selectedCompany &&
        isDisplayNameValid &&
        state.selectedDate
      ) {
        state.isFromValid = true;
      }
    },
    resetToInitialState: (state) => ({
      ...initialState,
      opportunityList: state.opportunityList,
      templateList: state.templateList,
    }),
    resetCompanyList: (state) => {
      state.companyList = undefined;
      state.companySearchedTerm = '';
    },
    setOpportunityDisabledStatus: (state, action: PayloadAction<boolean>) => {
      state.isOpportunityDisabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpportunities.fulfilled, (state, { payload }) => {
        state.opportunityList = payload as Opportunity[];
        state.opportunityError = null;
        state.opportunityLoading = false;
      })
      .addCase(fetchOpportunities.pending, (state) => {
        state.opportunityLoading = true;
      })
      .addCase(fetchOpportunities.rejected, (state) => {
        state.opportunityError = NETWORK_ERROR;
        state.opportunityLoading = false;
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload }) => {
        state.templateList = payload;
        state.templateLoading = false;
        state.templateError = null;
        // eslint-disable-next-line prefer-destructuring
        if (payload?.length === 1) state.selectedTemplate = payload[0];
        if (payload?.length === 0) {
          state.selectedTemplate = lastTemplateOption as Msp;
        }
      })
      .addCase(fetchTemplates.pending, (state) => {
        state.templateLoading = true;
      })
      .addCase(fetchTemplates.rejected, (state) => {
        state.templateError = NETWORK_ERROR;
        state.templateLoading = false;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companySearchedTerm = action.meta.arg.companyName;
        state.companyList = action.payload;
        state.companyError = null;
        state.companyLoading = false;
      })
      .addCase(fetchCompanies.pending, (state, action) => {
        state.companySearchedTerm = action.meta.arg.companyName;
        state.companyLoading = true;
        state.selectedCompany = null;
      })
      .addCase(fetchCompanies.rejected, (state) => {
        state.companyError = NETWORK_ERROR;
        state.companyLoading = false;
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, { payload }) => {
        state.companyDetails = payload as Company;
        state.companyDetailsLoading = false;
      })
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.companyDetailsLoading = true;
      })
      .addCase(fetchCompanyDetails.rejected, (state) => {
        state.companyDetailsError = NETWORK_ERROR;
        state.companyDetailsLoading = false;
      });
  },
});

export const {
  resetSelectedOpportunity,
  setSelectedOpportunity,
  setSelectedTemplate,
  setSelectedCompany,
  setSelectedDate,
  setDisplayName,
  validateForm,
  resetToInitialState,
  resetCompanyList,
  setOpportunityDisabledStatus,
} = mspCreationSlice.actions;

export default mspCreationSlice.reducer;
