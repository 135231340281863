import React from 'react';
import {
  FONT_WEIGHT_CLASSES,
  TElements,
  TVariants,
  VARIANT_RECORDS,
} from './default-typography-variants';

export type TypographyProps = {
  /** underlying html element ("h1", "h2", etc) */
  element?: TElements;
  variant: TVariants;
  children?: React.ReactNode;
  className?: string;
  target?: string;
  href?: string;
} & React.HTMLAttributes<HTMLElement>;

export function Typography(props: TypographyProps) {
  const { element, children, variant, className, ...rest } = props;
  const variantDetails =
    VARIANT_RECORDS[variant] ?? VARIANT_RECORDS['body-13-regular'];
  const Element = element ?? variantDetails?.element ?? 'div';

  return (
    <Element
      className={`m-0 ${className ?? ''} ${
        FONT_WEIGHT_CLASSES[variantDetails.fontWeight]
      } ${variantDetails.fontSizeClass}`}
      data-id={`text/${Element}`}
      {...rest}
    >
      {children}
    </Element>
  );
}

export default Typography;
