export function getMspId(url: string) {
  const first = url.split('/ui/alignx/msp/')[1];
  if (first) return first.split('/')[0];
  return '';
}

export function getMspIdFromUspLink(url: string) {
  const first = url.split('/ui/alignx/msp/')[1];
  if (first) return first.split('?')[0];
  return '';
}
