import { KeycloakInstance, KeycloakLoginOptions } from 'keycloak-js';

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export function getToken(): string | null {
  return window.sessionStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken(): string | null {
  return window.sessionStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setToken(token?: string, refresh?: string): void {
  if (token) window.sessionStorage.setItem(TOKEN_KEY, token);
  if (refresh) window.sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh);
}

export function clearToken(): void {
  window.sessionStorage.removeItem(TOKEN_KEY);
  window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
}

export const AuthSuccessHandler = (keycloak: KeycloakInstance, cb?: CallableFunction) => async () => {
  setToken(keycloak.token, keycloak.refreshToken);
  if (cb) cb(keycloak.token);
  await keycloak.updateToken(30);
};

export const AuthRefreshSuccessHandler = (keycloak: KeycloakInstance, cb?: CallableFunction) => () => {
  setToken(keycloak.token, keycloak.refreshToken);
  if (cb) cb(keycloak.token);
};

export const TokenExpiredHandler = (keycloak: KeycloakInstance) => async () => {
  await keycloak.updateToken(30);
};

export const AuthRefreshErrorHandler = (keycloak: KeycloakInstance, loginOptions?: KeycloakLoginOptions) => () => {
  keycloak.login(loginOptions);
};

export const AuthLogoutHandler = () => {
  clearToken();
};
