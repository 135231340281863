import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FilterItemsPayloadResponse,
  MediaConnectionResponse,
  ObjectType,
  Operation,
  Operator,
} from '../../../generated-types';
import { GET_MEDIA_FILTER, GET_RESOURCE_LIST } from './gql-quries';

export type ResultResourceFilters = {
  mediaQuery: { mediaFilters: FilterItemsPayloadResponse };
};
export type ResultResourceList = {
  mediaQuery: { listMedia: MediaConnectionResponse };
};

export const fetchResourceFilters = createAsyncThunk(
  'msp/get-resource-filters',
  async (
    {
      client,
      mspId,
      parentId,
    }: { client: ApolloClient<object>; mspId: string; parentId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultResourceFilters>({
        query: GET_MEDIA_FILTER,
        variables: {
          operations: {
            filter: {
              operator: Operator.AND,
              filters: [
                {
                  op: Operation.EQ,
                  values: [mspId],
                  field: 'scopeId',
                },
              ],
            },
          },
        },
      });
      return { parentId, filters: data };
    } catch (error) {
      const err = { parentId, message: (error as Error)?.message };
      return rejectWithValue(err);
    }
  },
);

export const fetchResourceList = createAsyncThunk(
  'msp/get-resource-list',
  async (
    {
      client,
      parentId,
      loggedInUserId,
      // selectedFilters,
      // filterDetails,
      type,
    }: // afterCursor,
    {
      client: ApolloClient<object>;
      parentId: string;
      loggedInUserId: string;
      // searchTerm?: string;
      // selectedFilters?: IFilterDetails;
      // filterDetails?: IFilterConfig;
      type: ObjectType;
      // afterCursor?: string,
    },
    { rejectWithValue },
  ) => {
    try {
      // const filterKeys = selectedFilters ? Object.keys(selectedFilters) : null;
      // let filters = [] as IFilterQuery[];
      // if (selectedFilters && filterKeys) {
      //   filters = filterKeys.reduce((acc, key) => {
      //     const arr = selectedFilters[key];
      //     const fieldName = filterDetails && filterDetails[key]?.key;
      //     const values = arr
      //       .filter(({ isSelected }) => isSelected)
      //       .map(({ value }) => value);

      //     if (values.length) {
      //       acc.push({
      //         op: 'EQ',
      //         values,
      //         field: fieldName,
      //       });
      //     }

      //     return acc;
      //   }, [] as IFilterQuery[]);
      // }
      //  if (searchTerm) {
      //    filters.push({
      //      op: 'LIKE',
      //      values: [searchTerm],
      //      field: 'text',
      //    });
      //  }

      const { data } = await client.query<ResultResourceList>({
        query: GET_RESOURCE_LIST,
        variables: {
          operations: {
            filter: {
              operator: Operator.AND,
              filters: [
                // ...filters,
                {
                  op: Operation.EQ,
                  values: [parentId],
                  field: type === ObjectType.MSP ? 'scopeId' : 'referenceId',
                },
              ],
            },
            sort: [
              {
                field: 'createdAt',
                order: 'DESC',
              },
            ],
          },
        },
      });

      return { response: data, loggedInUserId, parentId };
    } catch (error: unknown) {
      const err = { parentId, message: (error as Error)?.message };
      return rejectWithValue(err);
    }
  },
);
