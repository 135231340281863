import { useApolloClient } from '@apollo/client';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import 'react-base-table/styles.css';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { useDebounce } from '../../../common/methods/debounce';
import { RootState } from '../../../common/redux-store';
import {
  IFilterConfig,
  IFilterDetails,
} from '../../../components/filters/model';
import { BoxFilterFields, BoxFilterType } from '../models';
import AllTasksHeader from './all-task-header';
import { fetchAllTasksFilters } from './api-call';
import { useGetAllTasks } from './api-hooks';
import TaskTable from './task-table';

const mapState = (state: RootState) => ({
  taskList: state?.allTasks?.allTasks.taskList,
  filterDetails: state.allTasks.filterDetails,
  searchText: state?.allTasks.searchText,
  selectedFilters: state?.filters?.selectedFilters?.allTasks,
  userId: state?.userInfo?.userDetails?.userId,
  boxFilterDetails: state?.allTasks?.additionalBoxFilters,
  isFilterLoading: state?.allTasks?.filterLoading,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAllTasksFilters,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IMyTasksProps = PropsFromRedux;
let firstLoad = false;

function AllTasks(props: IMyTasksProps) {
  const {
    taskList,
    fetchAllTasksFilters,
    filterDetails,
    searchText,
    selectedFilters,
    userId,
    boxFilterDetails,
  } = props;
  const { getAllTasks } = useGetAllTasks(userId as string);
  const client = useApolloClient();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackEvent(TRACKING_CONSTANTS.ACCESSED_ALL_TASK));
    getAllTasks({
      taskList,
      searchTerm: searchText,
      selectedFilters,
      filterDetails,
    });
    if (Object.keys(filterDetails)?.length === 0) {
      fetchAllTasksFilters({ client });
    }
    return () => {
      firstLoad = false;
    };
  }, []);

  const debouncedCall = useDebounce(
    (
      selectedFilters: IFilterDetails,
      searchText: string,
      filterDetails: IFilterConfig,
      boxFilterDetails: { [key in BoxFilterType]: BoxFilterFields },
    ) => {
      getAllTasks({
        taskList: [],
        searchTerm: searchText,
        selectedFilters,
        filterDetails,
        boxFilterDetails,
      });
    },
    500,
  );

  useEffect(() => {
    if (firstLoad) {
      debouncedCall(
        selectedFilters,
        searchText,
        filterDetails,
        boxFilterDetails,
      );
    }
    if (boxFilterDetails && Object.keys(boxFilterDetails)?.length !== 0) {
      firstLoad = true;
    }
  }, [selectedFilters, searchText, boxFilterDetails]);

  return (
    <>
      <AllTasksHeader />
      <TaskTable />
    </>
  );
}

export default connector(AllTasks);
