import { useThrottle } from 'rooks';

let mouseLeaveTimer: unknown | number;

export function useShareMsp(props: {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}) {
  const { setAnchorEl } = props;
  const handleMouseleave = (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  ) => {
    mouseLeaveTimer = setTimeout(() => {
      setAnchorEl(null);
    }, 420);
  };
  const clearMouseLeaveEvent = () => {
    clearTimeout(mouseLeaveTimer as number);
  };
  const [handleMouseEnter] = useThrottle(
    (
      targetElem: HTMLElement,
      setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    ) => {
      mouseLeaveTimer = setAnchorEl(targetElem);
    },
    400,
  );
  const handleOnMouseEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    clearTimeout(mouseLeaveTimer as number);
    const eventType = event?.type;
    const targetElem = event?.currentTarget;
    if (eventType === 'mouseleave') {
      handleMouseleave(setAnchorEl);
    } else {
      handleMouseEnter(targetElem, setAnchorEl);
    }
  };
  return {
    handleOnMouseEvent,
    clearMouseLeaveEvent,
  };
}
