import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IUserFormData } from '../../alignx/msp-view/milestone-details/new-create-user';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import {
  setNewUserAddedStatus,
  setSelectedUserList,
} from '../../common/redux-store/reducers/search-user-reducer';
import {
  Company,
  GraphqlError,
  User,
  UserConnection,
  UserConnectionResponse,
  UserMutationCreateUserArgs,
  UserPayload,
  UserResponse,
} from '../../generated-types';
import { ADD_USER, AddUserResult } from '../../graphql/mutation/user';
import { SEARCH_USER } from '../../graphql/search-user';
import { showToast } from '../hooks/use-toastify';
import { SearchType } from './search-component';

type UseSearchUserProps = {
  companyDetails: Company | undefined;
  onNewUserClose: () => void;
  selectedUser: User[];
  mspId?: string;
  onSubmit?: (selectedUser: User[]) => void;
  onClose?: () => void;
  searchType?: SearchType;
  isInShareFlow?: boolean;
};

type ResultSearchUsersInMsp = {
  mspQuery: { searchUsersInMsp: UserConnectionResponse };
};

export function useSearchUser(props: UseSearchUserProps) {
  const {
    companyDetails,
    onNewUserClose,
    selectedUser,
    mspId,
    onSubmit,
    onClose,
    searchType,
    isInShareFlow,
  } = props;
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState<User[]>();
  const [placeholderSearchList, setPlaceholderList] = useState<User[]>();

  const [searchUserAPI, { loading, error }] =
    useLazyQuery<ResultSearchUsersInMsp>(SEARCH_USER, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        if (res.mspQuery.searchUsersInMsp.__typename !== 'GraphqlError') {
          const apiUserList = (
            res?.mspQuery?.searchUsersInMsp as UserConnection
          )?.edges
            ?.map((item) => {
              const userData = item?.node as User;
              return {
                ...userData,
                firstName: userData?.firstName
                  ? userData?.firstName
                  : userData?.email?.split('@')?.[0] || '',
              };
            })
            ?.filter((user) => (isInShareFlow ? user.email : true));
          setSearchList(apiUserList);
        }
      },
    });
  const includesTerm = (input: string, term: string) =>
    input.toLowerCase().includes(term.toLowerCase());

  const onPlaceholderSearch = useCallback(
    (term: string, placeholderList: User[]) => {
      const filteredList =
        placeholderList &&
        placeholderList.filter(({ firstName }) => {
          if (includesTerm(firstName as string, term)) return true;
          return false;
        });
      setPlaceholderList(filteredList);
    },
    [setPlaceholderList],
  );

  const onSearch = useCallback(
    (term: string) => {
      const filters = ['user.firstName', 'user.lastName', 'user.email'].map(
        (field) => ({
          op: 'LIKE',
          values: [term],
          field,
        }),
      );
      // if (isInShareFlow)
      //   filters.push({
      //     op: Operation.EXISTS,
      //     values: ['true'],
      //     field: 'user.email',
      //   });
      const searchVariables = {
        context: {
          headers: {
            MspId: mspId,
          },
        },
        variables: {
          operations: {
            filter: {
              operator: 'OR',
              childExpressions: [] as unknown[],

              filters,
            },
          },
        },
      };
      if (companyDetails && companyDetails?.id) {
        searchVariables.variables.operations.filter.childExpressions.push({
          operator: 'AND',
          filters: {
            op: 'EQ',
            values: [companyDetails.id],
            field: 'user.userCompanyScope.companyId',
          },
        });
      }

      searchUserAPI(searchVariables);
    },
    [companyDetails?.id, searchUserAPI, companyDetails, isInShareFlow],
  );

  const [addUser] = useMutation<AddUserResult, UserMutationCreateUserArgs>(
    ADD_USER,
  );

  const createUser = useCallback(
    (data: IUserFormData) => {
      addUser({
        variables: {
          input: data,
        },
      })
        .then((resp) => {
          if (
            resp.data?.userMutation.createUser.__typename === 'GraphqlError' ||
            (
              (resp.data?.userMutation.createUser as UserPayload)
                .items as UserResponse[]
            )?.[0]?.__typename === 'GraphqlError'
          ) {
            throw new Error(
              (resp.data?.userMutation.createUser as GraphqlError)?.code ||
                (
                  (resp.data?.userMutation.createUser as UserPayload)
                    .items?.[0] as GraphqlError
                )?.code,
            );
          } else {
            const tempUser = [...selectedUser];
            tempUser.push(
              (
                (resp.data?.userMutation.createUser as UserPayload)
                  .items as User[]
              )[0],
            );
            dispatch(setSelectedUserList(tempUser));
            dispatch(setNewUserAddedStatus(true));
            dispatch(
              trackEvent(TRACKING_CONSTANTS.NEW_USER_ADDED, {
                from: searchType,
              }),
            );
            onNewUserClose();
            showToast(
              <FormattedMessage
                id="Snackbar.userAddSucess"
                defaultMessage="New User added successfully"
              />,
              {
                variant: 'success',
              },
            );
            if (searchType === SearchType.ADDTASK) {
              onSubmit?.(tempUser);
              onClose?.();
            }
          }
        })
        .catch((errorCode: Error) => {
          showToast(
            <FormattedMessage
              id={`ErrorCode.${errorCode?.message ?? ''}`}
              defaultMessage="Something went wrong, please try again"
            />,
            {
              variant: 'error',
            },
          );
        });
    },
    [addUser, selectedUser, dispatch, onNewUserClose, showToast],
  );

  return {
    onSearch,
    searchList,
    createUser,
    loading,
    error,
    onPlaceholderSearch,
    placeholderSearchList,
  };
}
