import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_MY_TASKS = gql`
  ${CORE_GRAPH_ERROR}
  query myTasks($operations: Operations) {
    taskQuery {
      myTasks(operations: $operations) {
        __typename
        ...CoreErrorFields
        ... on TaskConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Task {
                id
                name
                dueDate
                status
                description
                isInternal
                milestone {
                  __typename
                  ...CoreErrorFields
                  ... on Milestone {
                    id
                    name
                  }
                }
                msp {
                  __typename
                  ...CoreErrorFields
                  ... on Msp {
                    id
                    name
                    buyerCompany {
                      __typename
                      ...CoreErrorFields
                      ... on Company {
                        id
                        companyName
                        displayName
                      }
                    }
                  }
                }
                assignee {
                  ... on User {
                    id
                    firstName
                    lastName
                    email
                    userType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MY_TASKS_FILTERS = gql`
  ${CORE_GRAPH_ERROR}
  query myTaskFilters {
    taskQuery {
      myTaskFilters {
        ...CoreErrorFields
        ... on FilterItemsPayload {
          items {
            key
            displayName
            values
            isVisible
          }
        }
      }
    }
  }
`;
