import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
  IFilterDetails,
  IShowMapping,
} from '../../../components/filters/model';
import {
  Assignee,
  BaResourceCountObject,
  CompanyType,
  GraphqlError,
  IntObject,
  Maybe,
  MediaConnection,
  MediaPayloadItem,
  Milestone,
  MilestoneAnalytics,
  MilestoneConnection,
  MilestoneDetails,
  MilestoneEdge,
  Placeholder,
  PlaceholderConnection,
  PlaceholderEdge,
  PlaceholderResponse,
  Task,
  TaskAnalytics,
  TaskConnection,
  TaskEdge,
  TaskResponse,
  TaskStatus,
  UpdateTaskInput,
  User,
  UserType,
} from '../../../generated-types';
import {
  IAssigneeModal,
  IMilestone,
  IMilestoneOverDueDetail,
  IMspStore,
  IPlaceholder,
  IPlaceholderList,
} from '../model';
import {
  fetchOverdueMilestoneList,
  ResultOffTrackTask,
} from '../msp-overdue/api-call';
import { fetchPlaceholderList, ResultPlaceholderList } from './api-call';

const defaultOverDueMilestone: IMilestoneOverDueDetail = {
  milestoneOverdueList: [],
  milestoneOverdueDetails: {},
  taskDetails: {},
  userDetails: {},
  error: undefined,
  loading: true,
};

const defaultPlaceholderList: IPlaceholderList = {
  placeholderList: [],
  error: undefined,
  loading: true,
};

function transformOverdueTasksResponse(response: ResultOffTrackTask) {
  try {
    const offTrackResultList = response?.taskQuery?.overdueTasks;
    if (offTrackResultList?.__typename === 'GraphqlError') {
      return { ...defaultOverDueMilestone, error: 'Something went wrong' };
    }
    let {
      milestoneOverdueDetails,
      taskDetails,
      userDetails,
      milestoneOverdueList,
    } = defaultOverDueMilestone;
    const edges = (offTrackResultList as TaskConnection)?.edges as TaskEdge[];
    for (let i = 0; i < edges?.length; i += 1) {
      const node = edges[i]?.node as TaskResponse;
      if (node?.__typename === 'GraphqlError' || !node) {
        const node = edges[i]?.node as GraphqlError;
        const milestoneId = `milestoneerror${uuid()}`;
        const taskId = `taskerror${uuid()}`;
        const userId = `usererror${uuid()}`;
        milestoneOverdueList = milestoneOverdueList.concat(milestoneId);
        milestoneOverdueDetails = {
          ...milestoneOverdueDetails,
          [milestoneId]: {
            name: node?.message || 'Something Went Wrong',
            taskList: [taskId],
            id: milestoneId,
            isInternal: false,
            isMarkedCompleteEnabled: true,
          },
        };
        taskDetails = {
          ...taskDetails,
          [taskId]: {
            name: node?.message || 'Something Went Wrong',
            status: null,
            dueDate: null,
            id: taskId,
            userList: [userId],
            isInternal: false,
            assigneeType: null,
          },
        };
        userDetails = {
          ...userDetails,
          [userId]: {
            firstName: null,
            lastName: null,
            id: userId,
            email: null,
            userType: UserType.SELLER,
          },
        };
      } else {
        const node = edges[i]?.node as Task;
        const { milestone, milestoneId, assignee, id, isInternal } = node;
        let userId;
        if (assignee !== null && assignee !== undefined) {
          userId =
            (assignee as User)?.id || (assignee as IPlaceholder)?.placeholderId;
        }
        const milestoneID = node?.milestoneId as string;
        if (node?.milestone?.__typename === 'GraphqlError') {
          if (!(milestoneID in milestoneOverdueDetails)) {
            milestoneOverdueList = milestoneOverdueList.concat(
              milestoneId as string,
            );
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                name:
                  (milestone as GraphqlError)?.message ||
                  'Something went wrong',
                taskList: [id],
                id: milestoneId,
                isInternal: false,
                isMarkedCompleteEnabled: true,
              },
            };
          } else {
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                ...milestoneOverdueDetails[milestoneId as string],
                taskList:
                  milestoneOverdueDetails[
                    milestoneId as string
                  ].taskList.concat(id),
              },
            };
          }
          taskDetails = {
            ...taskDetails,
            [id]: {
              name: node?.name,
              status: node?.status,
              dueDate: node?.dueDate,
              id: node?.id,
              userList:
                node?.assignee === null
                  ? []
                  : [
                      ((node?.assignee as User)?.id as string) ||
                        ((node?.assignee as IPlaceholder)
                          ?.placeholderId as string),
                    ],
              isInternal: node?.isInternal,
              assigneeType: node?.assigneeType,
            },
          };
          if (assignee !== null && assignee !== undefined) {
            userDetails = {
              ...userDetails,
              [userId as string]: {
                firstName:
                  (assignee as User)?.firstName ||
                  (assignee as Placeholder)?.name,
                lastName: (assignee as User)?.lastName,
                id:
                  (assignee as User)?.id ||
                  (assignee as IPlaceholder)?.placeholderId,
                email: (assignee as User)?.email || '',
                userType:
                  (assignee as User)?.userType ||
                  (assignee as Placeholder)?.companyType,
              },
            };
          }
        } else if (node?.assignee?.__typename === 'GraphqlError') {
          const userId = `usererror${uuid()}`;
          if (!(milestoneID in milestoneOverdueDetails)) {
            milestoneOverdueList = milestoneOverdueList.concat(
              milestoneId as string,
            );
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                name: (milestone as Milestone)?.name,
                taskList: [id],
                id: milestoneId,
                isInternal: (milestone as Milestone)?.isInternal,
                startDate: (milestone as Milestone)?.startDate,
                endDate: (milestone as Milestone)?.endDate,
                isMarkedCompleteEnabled: true,
              },
            };
          } else {
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                ...milestoneOverdueDetails[milestoneId as string],
                taskList:
                  milestoneOverdueDetails[
                    milestoneId as string
                  ].taskList.concat(id),
              },
            };
          }
          taskDetails = {
            ...taskDetails,
            [id]: {
              name: node?.name,
              status: node?.status,
              dueDate: node?.dueDate,
              id: node?.id,
              userList: [userId],
              isInternal: node?.isInternal,
              assigneeType: node?.assigneeType,
            },
          };
          userDetails = {
            ...userDetails,
            [userId]: {
              firstName: null,
              lastName: null,
              id: userId,
              email: null,
              userType: UserType.SELLER,
            },
          };
        } else if (
          (node?.assignee as Assignee)?.__typename === 'GraphqlError'
          // && node?.milestone?.__typename === 'GraphqlError'
        ) {
          const userId = `usererror${uuid()}`;
          if (!(milestoneID in milestoneOverdueDetails)) {
            milestoneOverdueList = milestoneOverdueList.concat(
              milestoneId as string,
            );
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                name:
                  (milestone as GraphqlError)?.message ||
                  'Something went wrong',
                taskList: [id],
                id: milestoneId,
                isInternal: false,
                isMarkedCompleteEnabled: true,
              },
            };
          } else {
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                ...milestoneOverdueDetails[milestoneId as string],
                taskList:
                  milestoneOverdueDetails[
                    milestoneId as string
                  ].taskList.concat(id),
              },
            };
          }
          taskDetails = {
            ...taskDetails,
            [id]: {
              name: node?.name,
              status: node?.status,
              dueDate: node?.dueDate,
              id: node?.id,
              userList: [userId],
              isInternal: node?.isInternal,
              assigneeType: node?.assigneeType,
            },
          };
          userDetails = {
            ...userDetails,
            [userId]: {
              firstName: null,
              lastName: null,
              email: null,
              id: userId,
              userType: UserType.SELLER,
            },
          };
        } else {
          if (!(milestoneID in milestoneOverdueDetails)) {
            milestoneOverdueList = milestoneOverdueList.concat(
              milestoneId as string,
            );
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                name: (milestone as Milestone)?.name,
                taskList: [id],
                id: milestoneId,
                isInternal: (milestone as Milestone)?.isInternal,
                isMarkedCompleteEnabled: true,
                startDate: (milestone as Milestone)?.startDate,
                endDate: (milestone as Milestone)?.endDate,
              },
            };
          } else {
            milestoneOverdueDetails = {
              ...milestoneOverdueDetails,
              [milestoneId as string]: {
                ...milestoneOverdueDetails[milestoneId as string],
                taskList:
                  milestoneOverdueDetails[
                    milestoneId as string
                  ].taskList.concat(id),
              },
            };
          }
          taskDetails = {
            ...taskDetails,
            [id]: {
              name: node?.name,
              status: node?.status,
              dueDate: node?.dueDate,
              id: node?.id,
              userList:
                node?.assignee === null
                  ? []
                  : [
                      ((node?.assignee as User)?.id as string) ||
                        ((node?.assignee as IPlaceholder)
                          ?.placeholderId as string),
                    ],
              isInternal: node?.isInternal,
              assigneeType: node?.assigneeType,
            },
          };
          if (assignee !== null && assignee !== undefined) {
            userDetails = {
              ...userDetails,
              [userId as string]: {
                firstName:
                  (assignee as User)?.firstName ||
                  (assignee as IPlaceholder)?.name,
                lastName: (assignee as User)?.lastName,
                id:
                  (assignee as User)?.id ||
                  (assignee as IPlaceholder)?.placeholderId,
                email: (assignee as User)?.email || '',
                userType:
                  (assignee as User)?.userType ||
                  (assignee as Placeholder)?.companyType,
              },
            };
          }
        }
      }
    }
    const data = {
      milestoneOverdueList,
      milestoneOverdueDetails,
      taskDetails,
      userDetails,
      loading: false,
    };
    return data;
  } catch (err: unknown) {
    return { ...defaultOverDueMilestone, error: err, loading: false };
  }
}

function transformPlaceholderListResponse(response: ResultPlaceholderList) {
  try {
    const listPlaceholdersResult = response?.memberQuery?.listPlaceholders;
    if (listPlaceholdersResult.__typename === 'GraphqlError') {
      return { ...defaultPlaceholderList, error: 'Something went wrong' };
    }
    let { placeholderList } = defaultPlaceholderList;
    const edges = (listPlaceholdersResult as PlaceholderConnection)
      ?.edges as PlaceholderEdge[];
    for (let i = 0; i < edges?.length; i += 1) {
      const node = edges[i]?.node as PlaceholderResponse;
      if (node && node?.__typename !== 'GraphqlError') {
        const node = edges[i]?.node as Placeholder;
        const userName = node?.name.split('-');
        const firstName = `${userName?.[1]} - ${userName?.[0]}`;
        const placeholderData = {
          id: node?.id,
          firstName,
          email: '',
          userType: node?.companyType,
        };
        placeholderList = [...placeholderList, placeholderData];
      }
    }

    return {
      placeholderList,
      loading: false,
      error: undefined,
    };
  } catch (err: unknown) {
    return { ...defaultPlaceholderList, error: err };
  }
}

export enum CommentsAction {
  ADDED,
  REMOVED,
}
export enum ResourceAction {
  ADDED,
  REMOVED,
}

export const initialState = {
  milestones: {} as MilestoneConnection,
  overDueMilestones: defaultOverDueMilestone,
  accordionOpenState: {
    expandedAll: false,
    collapsedAll: false,
  },
  assigneeModal: {
    isOpen: false,
    mspIndex: 0,
    taskIndex: 0,
    taskId: '',
    isTaskInternal: false,
  } as IAssigneeModal,
  showMapping: {} as IShowMapping,
  isFilterApplied: false,
  isTaskLoading: false,
  milestonePreviousState: {},
  placeholderData: defaultPlaceholderList,
  shouldMilestoneDataReloaded: false,
  milestoneFirstLoad: false,
  commentFirstLoad: false,
  taskFirstLoad: false,
  isMilestoneResourceHighlight: false,
  isMilestoneCreateDrawer: false,
} as IMspStore;

const mspSlice = createSlice({
  name: 'msp',
  initialState,
  reducers: {
    setMilestones: (
      state,
      action: PayloadAction<{
        milestones: MilestoneConnection;
        isUrlTaskIdPresent?: boolean;
        isSeller?: boolean;
        isCustomerViewEnabled?: boolean;
      }>,
    ) => {
      const {
        isUrlTaskIdPresent = true,
        milestones,
        isSeller = true,
        isCustomerViewEnabled = false,
      } = action.payload;

      const tempMilestones = {
        edges: milestones?.edges?.map(({ node }) => ({
          node: {
            ...node,
            resourceCount:
              isSeller && !isCustomerViewEnabled
                ? (
                    ((node as Milestone)?.analytics as MilestoneAnalytics)
                      ?.mediaResourceCount as BaResourceCountObject
                  )?.internalCount +
                  (
                    ((node as Milestone)?.analytics as MilestoneAnalytics)
                      ?.mediaResourceCount as BaResourceCountObject
                  )?.externalCount
                : (
                    ((node as Milestone)?.analytics as MilestoneAnalytics)
                      ?.mediaResourceCount as BaResourceCountObject
                  )?.externalCount || 0,
          } as IMilestone,
        })),
        totalCount: milestones?.totalCount,
      } as MilestoneConnection;
      state.milestones = tempMilestones;
      if (!isUrlTaskIdPresent) {
        state.isTaskLoading = true;
      }
    },
    expandedAll: (state) => {
      state.accordionOpenState = {
        expandedAll: true,
        collapsedAll: false,
      };
    },
    collapsedAll: (state) => {
      state.accordionOpenState = {
        expandedAll: false,
        collapsedAll: true,
      };
    },
    setMilestoneName: (
      state,
      action: PayloadAction<{ index: number; name: string }>,
    ) => {
      const { index, name } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && arr[index]?.node;
      if (milestone) (milestone as Milestone).name = name;
    },
    setTaskName: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        mspIndex: number;
        taskName: string;
        taskDueDate?: number;
        id?: string;
        isTaskInternal?: boolean;
        taskStatus?: TaskStatus;
      }>,
    ) => {
      const {
        taskIndex,
        taskName,
        mspIndex,
        id,
        taskDueDate,
        isTaskInternal,
        taskStatus,
      } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const taskObj = task && (task[taskIndex]?.node as Task);
      if (taskObj) taskObj.name = taskName;
      if (taskObj && taskDueDate) taskObj.dueDate = taskDueDate;
      if (id && taskObj) taskObj.id = id;
      if (taskObj && isTaskInternal) taskObj.isInternal = isTaskInternal;
      if (taskObj && taskStatus) taskObj.status = taskStatus;
    },
    setTaskDescription: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        mspIndex: number;
        description: string;
      }>,
    ) => {
      const { taskIndex, mspIndex, description } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const taskObj = task && (task[taskIndex]?.node as Task);
      if (taskObj) taskObj.description = description;
    },
    setMilestonesList: (
      state,
      action: PayloadAction<Maybe<Maybe<MilestoneEdge>[]>>,
    ) => {
      (state.milestones as MilestoneConnection).edges = action.payload;
    },
    addNewTask: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        mspIndex: number;
        id: string;
      }>,
    ) => {
      const { mspIndex, taskIndex, id } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const newTask = {
        node: { name: '', id },
      } as TaskEdge;
      if (taskList) {
        let isNewTaskLoading = false;
        for (let i = taskIndex; i < taskList?.length; i += 1) {
          const id = (taskList[i]?.node as Task)?.id;
          if (id.includes('newTask')) {
            isNewTaskLoading = true;
            break;
          }
        }
        if (!isNewTaskLoading) {
          taskList?.splice(taskIndex + 1, 0, newTask);
        }
      }
    },
    setTaskStatus: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        mspIndex: number;
        taskStatus: TaskStatus;
      }>,
    ) => {
      const { taskIndex, taskStatus, mspIndex } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const taskObj = task && (task[taskIndex]?.node as Task);
      if (taskObj) taskObj.status = taskStatus;
    },
    markMilestone: (
      state,
      action: PayloadAction<{
        id: string | undefined;
        isMarkedInternal: boolean;
      }>,
    ) => {
      const { id, isMarkedInternal } = action.payload;
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const index =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof index === 'number' && index >= 0)
        milestone = arr && (arr[index]?.node as Milestone);
      if (milestone) milestone.isInternal = isMarkedInternal;
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const updatedTask =
        task &&
        task.map((taskElem) => ({
          ...taskElem,
          node: { ...taskElem?.node, isInternal: isMarkedInternal },
        }));
      if (updatedTask && milestone)
        (milestone.tasks as TaskConnection).edges = updatedTask as TaskEdge[];
    },
    setBulkTaskDueDates: (
      state,
      action: PayloadAction<{
        milestoneId: string | undefined;
        bulkUpdateDueDateInput: UpdateTaskInput[];
      }>,
    ) => {
      const { milestoneId, bulkUpdateDueDateInput } = action.payload;
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const index =
        arr &&
        arr?.findIndex((item) => (item?.node as Milestone)?.id === milestoneId);
      if (typeof index === 'number' && index >= 0)
        milestone = arr && (arr[index]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const taskList =
        (task &&
          task.filter(
            (task) =>
              (task?.node as Task)?.status === TaskStatus?.INPROGRESS ||
              (task?.node as Task)?.status === TaskStatus?.PENDING,
          )) ??
        [];
      if (taskList && taskList.length > 0) {
        taskList.forEach((taskElem) => {
          const isTaskIdPresent = bulkUpdateDueDateInput.find(
            (tasks) => tasks?.id === (taskElem?.node as Task)?.id,
          );
          if (isTaskIdPresent) {
            (taskElem.node as Task).dueDate =
              isTaskIdPresent?.dueDate as number;
          }
        });
      }
    },
    removeBuyerAssigneeInMilestone: (
      state,
      action: PayloadAction<{
        id: string | undefined;
      }>,
    ) => {
      const { id } = action.payload;
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const index =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof index === 'number' && index >= 0)
        milestone = arr && (arr[index]?.node as Milestone);
      const task = milestone && (milestone?.tasks as TaskConnection)?.edges;
      const updatedTask =
        task &&
        task.map((taskElem) => {
          const assigneType =
            ((taskElem?.node as Task)?.assignee as User)?.userType ||
            ((taskElem?.node as Task)?.assignee as Placeholder)?.companyType;
          if (assigneType === UserType.BUYER) {
            return { ...taskElem, node: { ...taskElem?.node, assignee: null } };
          }
          return { ...taskElem };
        });
      if (updatedTask && milestone)
        (milestone.tasks as TaskConnection).edges = updatedTask as TaskEdge[];
    },
    removeBuyerAssigneeInTask: (
      state,
      action: PayloadAction<{
        id: string | undefined;
        taskId: string | undefined;
      }>,
    ) => {
      const { id, taskId } = action.payload;
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestoneIndex =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof milestoneIndex === 'number' && milestoneIndex >= 0)
        milestone = arr && (arr[milestoneIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index = taskList?.findIndex(
        (item) => (item?.node as Task)?.id === taskId,
      );
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (
          taskObj &&
          ((taskObj.assignee as User)?.userType === UserType.BUYER ||
            (taskObj.assignee as Placeholder)?.companyType ===
              CompanyType?.BUYER)
        )
          taskObj.assignee = undefined;
      }
    },
    markTask: (
      state,
      action: PayloadAction<{
        taskId: string | undefined;
        id: string | undefined;
        isMarkedInternal: boolean;
      }>,
    ) => {
      const { id, taskId, isMarkedInternal } = action.payload;
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestoneIndex =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof milestoneIndex === 'number' && milestoneIndex >= 0)
        milestone = arr && (arr[milestoneIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index = taskList?.findIndex(
        (item) => (item?.node as Task)?.id === taskId,
      );
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (taskObj) taskObj.isInternal = isMarkedInternal;
      }
    },
    setOverDueTaskStatus: (
      state,
      action: PayloadAction<{
        taskId: string | undefined;
        id: string | undefined;
        taskStatus: TaskStatus;
      }>,
    ) => {
      const { id, taskId, taskStatus } = action.payload;
      const { milestoneOverdueDetails, taskDetails } = state?.overDueMilestones;
      if (
        milestoneOverdueDetails[id as string] &&
        taskDetails[taskId as string]
      ) {
        taskDetails[taskId as string].status = taskStatus;
        if (
          taskStatus === TaskStatus?.PENDING ||
          taskStatus === TaskStatus?.INPROGRESS
        ) {
          milestoneOverdueDetails[id as string].isMarkedCompleteEnabled = true;
        }
      }
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestoneIndex =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof milestoneIndex === 'number' && milestoneIndex >= 0)
        milestone = arr && (arr[milestoneIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index = taskList?.findIndex(
        (item) => (item?.node as Task)?.id === taskId,
      );
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (taskObj) {
          taskObj.status = taskStatus;
        }
      }
    },
    markAllOverDueTasksComplete: (
      state,
      action: PayloadAction<{
        id: string | undefined;
        taskIds: string[];
        taskStatus: TaskStatus;
      }>,
    ) => {
      const { id, taskStatus, taskIds } = action.payload;
      const { milestoneOverdueDetails, taskDetails } = state?.overDueMilestones;
      if (milestoneOverdueDetails && taskIds && taskIds.length > 0) {
        milestoneOverdueDetails[id as string] = {
          ...milestoneOverdueDetails[id as string],
          isMarkedCompleteEnabled: false,
        };
        for (let i = 0; i < taskIds.length; i += 1) {
          const id = taskIds[i];
          taskDetails[id] = { ...taskDetails[id], status: taskStatus };
        }
      }
      let milestone;
      const arr = (state.milestones as MilestoneConnection).edges;
      const index =
        arr && arr?.findIndex((item) => (item?.node as Milestone)?.id === id);
      if (typeof index === 'number' && index >= 0)
        milestone = arr && (arr[index]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const updatedTask =
        task &&
        task.map((taskElem) => {
          const data = {
            ...taskElem,
            node: { ...taskElem?.node, status: TaskStatus?.COMPLETED },
          };
          const isIdPresent =
            taskIds &&
            taskIds.find((taskId) => taskId === (taskElem?.node as Task)?.id);
          if (isIdPresent) {
            return data;
          }
          return taskElem;
        });
      if (updatedTask && milestone)
        (milestone.tasks as TaskConnection).edges = updatedTask as TaskEdge[];
    },
    moveTask: (
      state,
      action: PayloadAction<{
        sourceTaskIndex: number;
        sourceMilestoneIndex: number;
        destinationTaskIndex: number;
        destinationMilestoneIndex: number;
      }>,
    ) => {
      const {
        sourceTaskIndex,
        sourceMilestoneIndex,
        destinationTaskIndex,
        destinationMilestoneIndex,
      } = action.payload;
      const milestoneList = (state.milestones as MilestoneConnection).edges;
      const sourceMilestone =
        milestoneList &&
        (milestoneList[sourceMilestoneIndex]?.node as Milestone);
      const destinationMilestone =
        milestoneList &&
        (milestoneList[destinationMilestoneIndex]?.node as Milestone);
      const sourceTaskList = (sourceMilestone?.tasks as TaskConnection)?.edges;
      const destinationTaskList = (
        destinationMilestone?.tasks as TaskConnection
      )?.edges;

      // delete task from source task List in selected milestone
      const movedTask = sourceTaskList?.splice(sourceTaskIndex, 1);
      // add task to destination milestoneList
      if (movedTask) {
        destinationTaskList?.splice(destinationTaskIndex, 0, movedTask[0]);
      }
    },
    deleteTask: (
      state,
      action: PayloadAction<{
        mspIndex: number;
        taskId: string | undefined;
      }>,
    ) => {
      const { taskId, mspIndex } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index =
        taskList &&
        taskList?.findIndex((item) => (item?.node as Task)?.id === taskId);
      if (typeof index === 'number' && index >= 0 && taskList) {
        taskList?.splice(index, 1);
      }
    },
    duplicateTask: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        mspIndex: number;
        oldId: string;
      }>,
    ) => {
      const { taskIndex, mspIndex, oldId } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const task = taskList && (taskList[taskIndex]?.node as Task);
      if (task) {
        const newNode = {
          node: {
            name: task?.name,
            id: oldId,
            dueDate: task?.dueDate,
            isInternal: task?.isInternal,
          },
        } as TaskEdge;
        taskList?.push(newNode);
      }
    },
    applyFilters: (
      state,
      action: PayloadAction<{
        filters: IFilterDetails;
        isFilterApplied: boolean;
      }>,
    ) => {
      const { filters, isFilterApplied } = action.payload;
      const mapping = {} as IShowMapping;

      const status = filters.allStatus?.map(({ value }) => value) ?? null;
      const selectedStatus =
        Array.isArray(status) && status?.length ? status : null;
      const assignee = filters.allUsers?.map(({ value }) => value) ?? null;
      const selectedAssignee =
        Array.isArray(assignee) && assignee?.length ? assignee : null;

      (state?.milestones as MilestoneConnection)?.edges?.forEach(
        (milestone, mIndex) => {
          mapping[mIndex] = [];

          (
            (milestone?.node as Milestone)?.tasks as TaskConnection
          )?.edges?.forEach((item, tIndex) => {
            const task = item?.node as Task;
            const status = task?.status;
            const assignee = (task?.assignee as User)?.id || 'UNASSIGNED_USER';

            if (selectedAssignee === null && selectedStatus === null) {
              mapping[mIndex][tIndex] = true;
            } else {
              mapping[mIndex][tIndex] = !!(
                (selectedStatus === null || selectedStatus?.includes(status)) &&
                (selectedAssignee === null ||
                  selectedAssignee?.includes(assignee))
              );
            }
          });
        },
      );

      state.showMapping = mapping;
      state.isFilterApplied = isFilterApplied;
    },
    setMilestoneDates: (
      state,
      action: PayloadAction<{
        milestoneId: string;
        startDate?: number;
        endDate?: number;
      }>,
    ) => {
      const { milestoneId, startDate, endDate } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone =
        arr &&
        (arr.find(
          (milestone) => (milestone.node as Milestone)?.id === milestoneId,
        )?.node as Milestone);

      if (milestone && startDate) {
        milestone.startDate = action.payload.startDate || 0;
      }

      if (milestone && endDate) milestone.endDate = action.payload.endDate || 0;
      // (state.milestones as MilestoneConnection).edges?.sort(
      //   (a, b) =>
      //     ((a?.node as Milestone)?.startDate ?? 0) -
      //       ((b?.node as Milestone)?.startDate ?? 0) ||
      //     ((a?.node as Milestone)?.endDate ?? 0) -
      //       ((b?.node as Milestone)?.endDate ?? 0),
      // );
    },
    setTaskDueDate: (
      state,
      action: PayloadAction<{
        taskIndex: number;
        milestoneIndex: number;
        taskDueDate: number;
        id?: string;
      }>,
    ) => {
      const { taskIndex, taskDueDate, milestoneIndex, id } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[milestoneIndex]?.node as Milestone);
      const task = (milestone?.tasks as TaskConnection)?.edges;
      const taskObj = task && (task[taskIndex]?.node as Task);
      if (taskObj) taskObj.dueDate = taskDueDate;
      if (id && taskObj) taskObj.id = id;
    },
    openAssigneeModal: (
      state,
      action: PayloadAction<{
        mspIndex: number;
        taskIndex: number;
      }>,
    ) => {
      const { taskIndex, mspIndex } = action.payload;
      state.assigneeModal.isOpen = true;
      state.assigneeModal.mspIndex = mspIndex;
      state.assigneeModal.taskIndex = taskIndex;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const taskId = taskList && (taskList[taskIndex]?.node as Task)?.id;
      const isInternal =
        taskList && (taskList[taskIndex]?.node as Task)?.isInternal;
      state.assigneeModal.taskId = taskId ?? '';
      state.assigneeModal.isTaskInternal = isInternal ?? false;
    },
    setTaskInternalState: (
      state,
      action: PayloadAction<{
        isTaskInternal: boolean;
      }>,
    ) => {
      const { isTaskInternal } = action.payload;
      state.assigneeModal.isTaskInternal = isTaskInternal ?? false;
    },
    closeAssigneeModal: (state) => {
      state.assigneeModal.isOpen = false;
    },
    addAssigneeToTask: (
      state,
      action: PayloadAction<{
        user: User | undefined;
        taskId: string | undefined;
        mspIndex: number;
      }>,
    ) => {
      const { taskId, mspIndex, user } = action.payload;

      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index =
        taskList &&
        taskList?.findIndex((item) => (item?.node as Task)?.id === taskId);
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (taskObj) taskObj.assignee = user;
      }
    },

    setMilestoneDetails: (
      state,
      action: PayloadAction<{
        index: number;
        milestoneDetails: MilestoneDetails | undefined;
      }>,
    ) => {
      const { index, milestoneDetails } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[index]?.node as Milestone);
      if (milestone) milestone.milestoneDetails = milestoneDetails;
    },
    setParticularMilestoneDetail: (
      state,
      action: PayloadAction<{
        index: number;
        milestoneText: string;
        detailsTypeName: keyof MilestoneDetails;
      }>,
    ) => {
      const defaultValues = {
        goals: '',
        successCriteria: '',
        riskDependencies: '',
        milestoneId: '',
      };
      const { index, detailsTypeName, milestoneText } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[index]?.node as Milestone);
      const details = milestone?.milestoneDetails as MilestoneDetails;
      const id = milestone?.id ?? '';
      state.milestonePreviousState[id] =
        milestone?.milestoneDetails as MilestoneDetails;

      if (milestone) {
        milestone.milestoneDetails = {
          ...(details || defaultValues),
          [detailsTypeName]: milestoneText,
        };
      }
    },
    updateTaskId: (
      state,
      action: PayloadAction<{
        mspIndex: number;
        oldTaskId: string | undefined;
        newTaskId: string;
      }>,
    ) => {
      const { mspIndex, oldTaskId, newTaskId } = action.payload;

      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index =
        taskList &&
        taskList?.findIndex((item) => (item?.node as Task)?.id === oldTaskId);
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (taskObj) taskObj.id = newTaskId;
      }
    },
    removeAssignee: (
      state,
      action: PayloadAction<{
        mspIndex: number;
        taskId: string | undefined;
        assigneeType?: string | undefined;
      }>,
    ) => {
      const { mspIndex, taskId } = action.payload;

      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && (arr[mspIndex]?.node as Milestone);
      const taskList = (milestone?.tasks as TaskConnection)?.edges;
      const index = taskList?.findIndex(
        (item) => (item?.node as Task)?.id === taskId,
      );
      if (typeof index === 'number' && index >= 0) {
        const taskObj = taskList && (taskList[index]?.node as Task);
        if (taskObj) taskObj.assignee = undefined;
      }
    },
    setMspTasks: (state, action: PayloadAction<TaskConnection>) => {
      (state.milestones as MilestoneConnection).edges = (
        state.milestones as MilestoneConnection
      )?.edges?.map(
        (milestone) =>
          ({
            ...milestone,
            node: {
              ...milestone.node,
              tasks: {
                ...(milestone.node as Milestone).tasks,
                edges: action.payload?.edges?.filter(
                  (task) =>
                    (task?.node as Task)?.milestoneId ===
                    (milestone?.node as Milestone)?.id,
                ),
              },
            },
          } as MilestoneEdge),
      );
      state.isTaskLoading = false;
    },
    setMilestoneAssets: (
      state,
      action: PayloadAction<{ index: number; assets: MediaConnection }>,
    ) => {
      const { index, assets } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr && arr[index]?.node;
      if (milestone) (milestone as Milestone).media = assets;
    },
    setMilestoneDataFlag: (
      state,
      action: PayloadAction<{ shouldMilestoneDataBeRefetched: boolean }>,
    ) => {
      const { shouldMilestoneDataBeRefetched } = action.payload;
      state.shouldMilestoneDataReloaded = shouldMilestoneDataBeRefetched;
    },
    setMilestoneDrawerFlag: (state, action: PayloadAction<boolean>) => {
      // const { ismilestoneCreate } = action.payload;
      state.isMilestoneCreateDrawer = action.payload;
    },
    removeMilestoneAsset: (
      state,
      action: PayloadAction<{ assetId: string; mileStoneIndex: number }>,
    ) => {
      const { assetId, mileStoneIndex } = action.payload;
      const mediaArray = (
        (
          (state.milestones as MilestoneConnection)?.edges?.[mileStoneIndex]
            ?.node as Milestone
        )?.media as MediaConnection
      )?.edges;
      if (mediaArray) {
        const index = mediaArray.findIndex(
          (each) => (each?.node as MediaPayloadItem)?.item.id === assetId,
        );
        mediaArray.splice(index, 1);
      }
    },
    removeMileStoneAssetFromRootAssets: (
      state,
      action: PayloadAction<{ assetId: string; milestoneId: string }>,
    ) => {
      const { assetId, milestoneId } = action.payload;
      const milestones =
        (state?.milestones as MilestoneConnection)?.edges || [];
      const milestoneIndex = milestones.findIndex(
        (milestone) => (milestone?.node as Milestone)?.id === milestoneId,
      );
      if (milestoneIndex >= 0) {
        const milestone = (state.milestones as MilestoneConnection)?.edges?.[
          milestoneIndex
        ]?.node as IMilestone;
        const mediaArray = (milestone?.media as MediaConnection)?.edges;
        milestone.resourceCount -= 1;
        if (mediaArray) {
          const index = mediaArray.findIndex(
            (asset) => (asset?.node as MediaPayloadItem)?.item.id === assetId,
          );
          mediaArray.splice(index, 1);
        }
      }
    },
    setMilestoneResourceCount: (
      state,
      action: PayloadAction<{ milestoneId: string; type: ResourceAction }>,
    ) => {
      const { milestoneId, type } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr?.find(
        (milestone) => (milestone?.node as IMilestone)?.id === milestoneId,
      )?.node;
      if (milestone && type === ResourceAction.ADDED) {
        (milestone as IMilestone).resourceCount += 1;
      } else if (milestone && type === ResourceAction.REMOVED) {
        (milestone as IMilestone).resourceCount -= 1;
      }
    },
    updateResourceCount: (
      state,
      action: PayloadAction<{ milestoneId: string; count: number }>,
    ) => {
      const { milestoneId, count } = action.payload;
      const arr = (state.milestones as MilestoneConnection).edges;
      const milestone = arr?.find(
        (milestone) => (milestone?.node as IMilestone)?.id === milestoneId,
      )?.node;
      (milestone as IMilestone).resourceCount = count;
    },
    updateCommentCount: (
      state,
      action: PayloadAction<{ taskId: string; type: CommentsAction }>,
    ) => {
      (state.milestones as MilestoneConnection)?.edges?.map((milestone) => {
        const task =
          (
            (milestone.node as Milestone)?.tasks as TaskConnection
          )?.edges?.filter(
            (task) => (task?.node as Task).id === action.payload.taskId,
          ) ?? [];
        const analytics = (task[0]?.node as Task)?.analytics as TaskAnalytics;
        if (
          analytics &&
          analytics?.taskCommentCount &&
          action.payload.type === CommentsAction.ADDED
        ) {
          analytics.taskCommentCount += 1;
        } else if (
          analytics &&
          analytics?.taskCommentCount &&
          action.payload.type === CommentsAction.REMOVED
        ) {
          analytics.taskCommentCount -= 1;
        } else if (
          task[0]?.node &&
          action.payload.type === CommentsAction.ADDED
        ) {
          task[0].node = {
            ...(task[0].node as Task),
            analytics: { taskCommentCount: 1 } as TaskAnalytics,
          };
        }
        return milestone;
      });
    },
    setTaskResourceCount: (
      state,
      action: PayloadAction<{ taskId: string; type: ResourceAction }>,
    ) => {
      const { taskId, type } = action.payload;
      const arr = (state?.milestones as MilestoneConnection)?.edges;
      if (arr && arr.length > 0) {
        arr.every((milestone) => {
          const taskList = (
            (milestone.node as Milestone)?.tasks as TaskConnection
          )?.edges;
          const index = taskList?.findIndex(
            (item) => (item?.node as Task)?.id === taskId,
          );
          if (typeof index === 'number' && index >= 0) {
            const taskObj = taskList && (taskList[index]?.node as Task);
            if (taskObj) {
              if (type === ResourceAction.ADDED) {
                const { taskMediaCount } =
                  (taskObj?.analytics as TaskAnalytics) ??
                  ({} as TaskAnalytics);
                if ((taskMediaCount as IntObject)?.value) {
                  (taskMediaCount as IntObject).value += 1;
                } else {
                  taskObj.analytics = { taskMediaCount: { value: 1 } };
                }
              } else if (type === ResourceAction.REMOVED) {
                (
                  (taskObj.analytics as TaskAnalytics)
                    .taskMediaCount as IntObject
                ).value -= 1;
              }
            }
            return false;
          }
          return true;
        });
      }
    },
    setMilestoneFirstLoadFlag: (state, action: PayloadAction<boolean>) => {
      state.milestoneFirstLoad = action.payload;
    },
    setTaskFirstLoadFlag: (state, action: PayloadAction<boolean>) => {
      state.taskFirstLoad = action.payload;
    },
    setCommentFirstLoadFlag: (state, action: PayloadAction<boolean>) => {
      state.commentFirstLoad = action.payload;
    },
    setMilestoneResourceHeighlightFlag: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isMilestoneResourceHighlight = action.payload;
    },
    resetToMilestoneInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverdueMilestoneList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const overDueTaskData = transformOverdueTasksResponse(
          data,
        ) as IMilestoneOverDueDetail;
        state.overDueMilestones = overDueTaskData;
      })
      .addCase(fetchOverdueMilestoneList.pending, (state) => {
        state.overDueMilestones.loading = true;
      })
      .addCase(fetchOverdueMilestoneList.rejected, (state, { payload }) => {
        state.overDueMilestones.error = payload;
        state.overDueMilestones.loading = false;
      })
      .addCase(fetchPlaceholderList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const placeholderData = transformPlaceholderListResponse(
          data,
        ) as IPlaceholderList;
        state.placeholderData = placeholderData;
      })
      .addCase(fetchPlaceholderList.pending, (state) => {
        state.placeholderData.loading = true;
      })
      .addCase(fetchPlaceholderList.rejected, (state, { payload }) => {
        state.overDueMilestones.error = payload;
        state.placeholderData.loading = false;
      });

    // sellerMembers?.placeholders?.forEach((placeholder) => {
    //     const stakeholder = {
    //       id: (placeholder as Placeholder).id,
    //       firstName: (placeholder as Placeholder).name,
    //       orgId: (placeholder as Placeholder).orgId,
    //       email: '',
    //     };
  },
});

export const {
  setMilestones,
  expandedAll,
  collapsedAll,
  setMilestoneName,
  setTaskName,
  setMilestonesList,
  addNewTask,
  setTaskStatus,
  moveTask,
  duplicateTask,
  deleteTask,
  applyFilters,
  setMilestoneDates,
  setTaskDueDate,
  closeAssigneeModal,
  openAssigneeModal,
  addAssigneeToTask,
  setMilestoneDetails,
  updateTaskId,
  removeAssignee,
  setMilestoneAssets,
  removeMilestoneAsset,
  removeMileStoneAssetFromRootAssets,
  resetToMilestoneInitialState,
  setMspTasks,
  setParticularMilestoneDetail,
  updateCommentCount,
  updateResourceCount,
  markTask,
  markMilestone,
  removeBuyerAssigneeInMilestone,
  removeBuyerAssigneeInTask,
  setTaskInternalState,
  setOverDueTaskStatus,
  markAllOverDueTasksComplete,
  setMilestoneDataFlag,
  setMilestoneFirstLoadFlag,
  setCommentFirstLoadFlag,
  setTaskFirstLoadFlag,
  setBulkTaskDueDates,
  setTaskDescription,
  setMilestoneResourceCount,
  setTaskResourceCount,
  setMilestoneResourceHeighlightFlag,
  setMilestoneDrawerFlag,
} = mspSlice.actions;

export default mspSlice.reducer;
