// import { MilestoneMutation } from '@buyerassist/graphql-generated-types';
// import { api } from './graphql/create-milestone.generated';

import {
  FilterItems,
  FilterItemsPayload,
  MspQuery,
} from '../../../generated-types';
import { api } from '../graphql/archival-reason-list.generated';

export const extendedApi = api.enhanceEndpoints({
  endpoints: {
    GetMspArchiveReasonList: {
      transformResponse: (response: MspQuery) => {
        const data = (
          (
            (response?.mspQuery as MspQuery)
              ?.listMspArchivalReasons as FilterItemsPayload
          )?.items as FilterItems[]
        )?.[0]?.values;

        if (
          (response?.mspQuery as MspQuery)?.listMspArchivalReasons
            ?.__typename === 'GraphqlError'
        ) {
          throw new Error('Service error');
        }
        return data;
      },
    },
  },
});

export const { useGetMspArchiveReasonListQuery } = extendedApi;
