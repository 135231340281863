/* eslint-disable @typescript-eslint/no-unsafe-call */
import { RootState } from '../../common/redux-store/redux-types';
import {
  Company,
  CompanyPayload,
  OrganizationPayload,
  OrganizationResponse,
} from '../../generated-types';
import {
  api as companyAPi,
  GetCompanyInfoQuery,
} from './gql/get-comapny-info.generated';
import { api, GetOrganizationQuery } from './gql/get-org.generated';
import { setCompany, setOrganization } from './new-organization-store';

export const extendedApi = api.enhanceEndpoints({
  endpoints: {
    GetOrganization: {
      keepUnusedDataFor: 3600,
      transformResponse: (res: GetOrganizationQuery) => {
        // const data = response?.organizationQuery?.getOrganization;
        // if (data?.__typename === 'GraphqlError') {
        //   throw new Error(data.type);
        // }
        // console.log(data);
        const orgResponse = res?.organizationQuery
          ?.getOrganization as OrganizationPayload;

        if (
          res?.organizationQuery?.getOrganization.__typename !== 'GraphqlError'
        ) {
          const organization = (
            orgResponse?.items as OrganizationResponse[]
          )[0];

          return organization;
        } else {
          throw new Error('Organization api is not available');
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: organization } = await queryFulfilled;
          dispatch(setOrganization(organization as OrganizationResponse));

          const { name, email, userId, company, userType } = (
            getState() as RootState
          )?.userInfo?.userDetails;

          if (userId || email) {
            // eslint-disable-next-line no-undef
            analytics.identify(userId || email);
            if (window.smartlook) {
              window.smartlook('identify', userId, {
                company,
                type: userType,
                name,
                email,
              });
              window.smartlook('record', {
                forms: true,
                numbers: true,
                emails: true,
                ips: true,
              });
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useGetOrganizationQuery, useLazyGetOrganizationQuery } =
  extendedApi;

export const companyExtendedApi = companyAPi.enhanceEndpoints({
  endpoints: {
    getCompanyInfo: {
      keepUnusedDataFor: 3600,
      transformResponse: (data: GetCompanyInfoQuery) => {
        if (
          data?.companyQuery?.getCompanyByName?.__typename === 'GraphqlError'
        ) {
          throw new Error('Something went wrong while getting opportunities.');
        }

        const company = (data?.companyQuery?.getCompanyByName as CompanyPayload)
          ?.items?.[0];

        if (company) return company;

        return {} as Company;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setCompany(data as Company));
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useGetCompanyInfoQuery } = companyExtendedApi;
