import OpportunityHeader from './opportunity-header';
import OpportunityTable from './opportunity-table';

function OpportunityTab() {
  return (
    <div>
      <OpportunityHeader />
      <OpportunityTable />
    </div>
  );
}

export default OpportunityTab;
