/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type SearchCompaniesQueryVariables = Types.Exact<{
  operations: Types.Operations;
}>;

export type SearchCompaniesQuery = {
  __typename?: 'Query';
  companyQuery?: {
    __typename?: 'CompanyQuery';
    searchCompany:
      | {
          __typename: 'CompanyConnection';
          edges?: Array<{
            __typename?: 'CompanyEdge';
            node?:
              | {
                  __typename?: 'Company';
                  id: string;
                  orgId: string;
                  extId?: string;
                  displayName: string;
                  companyName: string;
                }
              | {
                  __typename: 'GraphqlError';
                  code: string;
                  message: string;
                  type: Types.ErrorType;
                  helpLink?: string;
                };
          }>;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          type: Types.ErrorType;
          message: string;
          helpLink?: string;
        };
  };
};

export const SearchCompaniesDocument = `
    query searchCompanies($operations: Operations!) {
  companyQuery {
    searchCompany(operations: $operations) {
      __typename
      ...CoreErrorFields
      ... on GraphqlError {
        code
        type
        message
      }
      ... on CompanyConnection {
        edges {
          node {
            ...CoreErrorFields
            ... on Company {
              id
              orgId
              extId
              displayName
              companyName
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    searchCompanies: build.query<
      SearchCompaniesQuery,
      SearchCompaniesQueryVariables
    >({
      query: (variables) => ({ document: SearchCompaniesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchCompaniesQuery, useLazySearchCompaniesQuery } =
  injectedRtkApi;
