/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ApolloClient, gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GraphqlError,
  IRelationshipBaseItem,
  RelationshipGraph,
  RelationshipItemPayload,
  RelationshipItemsFieldValue,
  RelationshipItemsFieldValuePayload,
  RelationshipItemsFieldValueResponse,
  RelationshipMutation,
  RelationshipQuery,
  Structure,
  StructureQuery,
  StructureResponsePayload,
} from '../../generated-types';
import { CORE_GRAPH_ERROR } from '../../graphql/fragments/error';

export type ResultUsers = {
  relationshipQuery: RelationshipQuery;
};

const GET_INFLUENCER_MAP_DATA = gql`
  ${CORE_GRAPH_ERROR}
  query GetInfluencerMapData($input: GetRelationshipGraphInput!) {
    relationshipQuery {
      relationshipGraph(input: $input) {
        __typename
        ...CoreErrorFields
        ... on RelationshipGraph {
          edges {
            id
            source
            sourceHandle
            type
            style
            target
            targetHandle
            markerEnd
            data {
              ... on GraphItemData {
                id
                dataPayload {
                  key
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
          viewport
          nodes {
            id
            dragging
            type
            height
            width
            position
            positionAbsolute
            selected
            data {
              ...CoreErrorFields
              ... on GraphItemData {
                id
                dataPayload {
                  key
                  id
                  values
                }
                metaData {
                  structureId
                  structureType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type ResultInfluencerMapData = {
  relationshipQuery: RelationshipQuery;
};

export const getInfluencerMapData = createAsyncThunk(
  'influencer/getInfluencerMapData',
  async (
    {
      client,
      scopeId,
      scopeType,
      fetchType,
    }: {
      client: ApolloClient<object>;
      fetchType: string;
      scopeId: string;
      scopeType: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data, errors } = await client.query<ResultInfluencerMapData>({
        query: GET_INFLUENCER_MAP_DATA,
        variables: {
          input: {
            scope: {
              id: scopeId,
              type: scopeType,
            },
            fetchType,
          },
        },
      });

      if (errors) {
        const code = (errors?.[0] as unknown as GraphqlError)?.code;
        throw new Error(code);
      }

      const graphData = data?.relationshipQuery?.relationshipGraph;
      if (!graphData || graphData?.__typename === 'GraphqlError') {
        throw new Error('Something went wrong');
      }
      const graphDataWithType = { ...graphData, fetchType };
      return graphDataWithType as RelationshipGraph & { fetchType: string };
      // return graphResponse.data.relationshipQuery.relationshipGraph;
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  },
);

const GET_USER_DETAIL = gql`
  ${CORE_GRAPH_ERROR}
  query GetUserDetail($input: GetRelationshipItemsInput!) {
    relationshipQuery {
      getRelationshipItems(input: $input) {
        ...CoreErrorFields
        ... on RelationshipItemPayload {
          items {
            id
            name
            objectType
            ... on RelationshipContact {
              id
              title
              strength {
                ...CoreErrorFields
                ... on RelationshipContactStrength {
                  id: displayValue
                  name: value
                }
              }
              sentiments {
                ... on RelationshipContactSentimentsPayload {
                  items {
                    ... on RelationshipContactSentiments {
                      id
                      displayValue
                      name
                    }
                  }
                }
              }
              contactRoles {
                ...CoreErrorFields
                ... on ContactPayload {
                  items {
                    ... on ContactRole {
                      id
                      name
                    }
                  }
                }
              }
              stakeholderRoles {
                __typename
                ... on RolePayload {
                  items {
                    __typename
                    ... on Role {
                      id
                      name
                    }
                  }
                }
              }
              notes {
                items {
                  ... on Notes {
                    id
                    messageData {
                      message
                    }
                  }
                }
              }
              isKeyStakeholder {
                __typename
                ... on GraphqlError {
                  type
                  message
                }
                ... on BooleanObject {
                  id
                  status
                }
              }
              linkedinData {
                ... on LinkedinData {
                  name
                  title
                  summary
                  avatarUrl
                  profileUrl
                }
              }
              assignees {
                __typename
                ... on UserPayload {
                  items {
                    __typename
                    ... on User {
                      id: primaryId
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
              avatar {
                url
              }
              buyerCompany {
                ... on Company {
                  displayName
                }
                ... on GraphqlError {
                  type
                  message
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type ResultUserDetail = {
  relationshipQuery: RelationshipQuery;
};

export const getUserDetail = createAsyncThunk(
  'influencer/getUserDetail',
  async (
    {
      client,
      id,
      scopeType,
      scopeId,
    }: {
      client: ApolloClient<object>;
      id: string;
      scopeType: string;
      scopeId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data, errors } = await client.query<ResultUsers>({
        query: GET_USER_DETAIL,
        variables: {
          input: {
            itemIds: [id],
            scope: {
              id: scopeId,
              type: scopeType,
            },
          },
        },
      });
      if (errors) {
        const code = (errors?.[0] as unknown as GraphqlError)?.code;
        throw new Error(code);
      }
      const userResponse = data?.relationshipQuery?.getRelationshipItems;

      if (!userResponse || userResponse?.__typename === 'GraphqlError') {
        throw new Error('Something went wrong');
      }
      const userDetail = (userResponse as RelationshipItemPayload)?.items?.[0];

      return { id, data: userDetail as IRelationshipBaseItem };
    } catch (error: unknown) {
      const err = { id, message: (error as Error)?.message };
      return rejectWithValue(err);
    }
  },
);

export const getUserCardDetail = createAsyncThunk(
  'influencer/getUserCardDetail',
  async (
    {
      client,
      id,
      setLoading,
      nodeId,
      scopeType,
      scopeId,
    }: {
      client: ApolloClient<object>;
      id: string;
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      nodeId: string;
      scopeType: string;
      scopeId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data, errors } = await client.query<ResultUsers>({
        query: GET_USER_DETAIL,
        variables: {
          input: {
            itemIds: [id],
            scope: {
              id: scopeId,
              type: scopeType,
            },
          },
        },
      });
      if (errors) {
        const code =
          (errors?.[0]?.extensions as unknown as GraphqlError)?.code ||
          'Something went wrong';
        throw new Error(code);
      }

      const userResponse = data?.relationshipQuery?.getRelationshipItems;

      if (!userResponse || userResponse?.__typename === 'GraphqlError') {
        throw new Error('Something went wrong');
      }
      const userDetail = (userResponse as RelationshipItemPayload)?.items?.[0];

      if (typeof setLoading === 'function') setLoading(false);

      return { id, data: userDetail as IRelationshipBaseItem, nodeId };
    } catch (error: unknown) {
      const err = { id, message: (error as Error)?.message, nodeId };
      return rejectWithValue(err);
    }
  },
);

const GET_STRUCTURE_DETAILS = gql`
  ${CORE_GRAPH_ERROR}
  query GetStructureDetails($operation: Operations) {
    structureQuery {
      listStructure(operations: $operation) {
        __typename
        ...CoreErrorFields
        ... on StructureResponsePayload {
          items {
            ... on Structure {
              id
              name
              structureType
              fields {
                style
                fieldProperty {
                  id
                  key
                  fieldType
                  label
                  order
                  isHidden
                  isDisabled
                  dataSourceConfig {
                    path {
                      defaultValuePath
                      valueSuggestionsPath
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type TStructureResponse = {
  structureQuery: StructureQuery;
};

export const getStructureDetails = createAsyncThunk(
  'influencer/getStructureDetails',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query<TStructureResponse>({
        query: GET_STRUCTURE_DETAILS,
        variables: {
          operation: {
            filter: {
              operator: 'AND',
              filters: [
                {
                  op: 'EQ',
                  values: ['CONTACT_FORM', 'CONTACT_CARD'],
                  field: 'scopeId',
                },
              ],
            },
          },
        },
      });
      if (errors) {
        const code = (errors?.[0] as unknown as GraphqlError)?.code;
        throw new Error(code);
      }
      const structureList = (
        data?.structureQuery?.listStructure as StructureResponsePayload
      )?.items?.filter(
        ({ __typename }) => __typename !== 'GraphqlError',
      ) as Structure[];
      if (!structureList) {
        throw new Error('Something went wrong');
      }

      return { structureList };
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  },
);

const GET_FORM_FIELD_VALUES = gql`
  ${CORE_GRAPH_ERROR}
  query GetFormFieldValues($input: GetRelationshipItemsFieldValuesInput) {
    relationshipQuery {
      getRelationshipItemsFieldValues(input: $input) {
        ...CoreErrorFields
        ... on RelationshipItemsFieldValuePayload {
          stakeholderRoles {
            id
            name
            displayName
            meta {
              color
            }
          }
          contactRoles {
            id
            name
            displayName
            meta {
              color
            }
          }
          strength {
            id
            name
            displayName
            meta {
              color
            }
          }
          sentiments {
            id
            name
            displayName
            meta {
              color
            }
          }
          stakeholderRoles {
            id
            name
            displayName
            meta {
              color
            }
          }
          assignees {
            edges {
              node {
                ... on RelationshipItemsFieldValue {
                  id
                  displayName
                  name
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type TFormFieldValues = {
  relationshipQuery: RelationshipQuery;
};

export const getFormFieldValues = createAsyncThunk(
  'influencer/getFormFieldValues',
  async (
    {
      client,
      scopeType,
      scopeId,
    }: { client: ApolloClient<object>; scopeId: string; scopeType: string },
    { rejectWithValue },
  ) => {
    try {
      const { data, errors } = await client.query<TFormFieldValues>({
        query: GET_FORM_FIELD_VALUES,
        variables: { input: { scope: { id: scopeId, type: scopeType } } },
      });

      if (errors) {
        const code = (errors?.[0] as unknown as GraphqlError)?.code;
        throw new Error(code);
      }

      const formFieldsResponse = data?.relationshipQuery
        ?.getRelationshipItemsFieldValues as RelationshipItemsFieldValuePayload;

      const assignees = (
        data?.relationshipQuery
          ?.getRelationshipItemsFieldValues as RelationshipItemsFieldValuePayload
      )?.assignees?.edges?.map((item) => {
        const node = item?.node as RelationshipItemsFieldValue;
        const [firstName, lastName] = (node?.displayName || '')?.split(' ');

        const user = {
          firstName,
          lastName,
          primaryId: node?.id,
          id: node?.id,
          email: node?.email,
        };

        return user;
      });

      if (
        !formFieldsResponse ||
        (formFieldsResponse as RelationshipItemsFieldValueResponse)
          ?.__typename === 'GraphqlError'
      ) {
        throw new Error('Something went wrong');
      }

      return {
        fieldValues: { ...formFieldsResponse, assignees },
      };
    } catch (error: unknown) {
      const err = { message: (error as Error)?.message };
      return rejectWithValue(err);
    }
  },
);

export type TUpdateFields = {
  relationshipMutation: RelationshipMutation;
};
