import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectedFilters, TFilterItem } from './model';

export const initialState = {
  selectedFilters: {} as ISelectedFilters,
};
const mspFilter = createSlice({
  name: 'msp-filter',
  initialState,
  reducers: {
    setSelectedFilters: (
      state,
      action: PayloadAction<{
        name: string;
        list: TFilterItem[];
        sectionName: string;
      }>,
    ) => {
      const { list, name, sectionName } = action.payload;
      const isAnyFilterSelected = list?.reduce((acc, cur) => acc || cur?.isSelected, false);
      if (!isAnyFilterSelected) {
        delete state?.selectedFilters[sectionName][name];
      } else {
        if (!state.selectedFilters[sectionName]) state.selectedFilters[sectionName] = {};

        state.selectedFilters[sectionName][action.payload.name] = action.payload.list;
      }
    },
    unSelectFilter: (state, action: PayloadAction<{ value: string; key: string; sectionName: string}>) => {
      const { sectionName, value: id, key } = action.payload;

      const section = state.selectedFilters[sectionName];
      const filterList = section[key];
      const index = filterList.findIndex(({ value }) => value === id);
      const filter = filterList[index];
      if (filter) filter.isSelected = false;

      const isAnyFilterSelected = filterList?.reduce((acc, cur) => acc || cur?.isSelected, false);
      if (!isAnyFilterSelected) { delete state?.selectedFilters[sectionName][key]; }
    },
    resetFilters: (state, action: PayloadAction<{ sectionName: string}>) => {
      const { sectionName } = action.payload;

      state.selectedFilters[sectionName] = {};
    },
    clearAllFilters: (state, action: PayloadAction<{ filterType: string, sectionName: string}>) => {
      const { filterType, sectionName } = action.payload;

      delete state.selectedFilters[sectionName][filterType];
    },
    resetToInitialState: () => initialState,
  },
});

export const {
  setSelectedFilters,
  resetFilters,
  clearAllFilters,
  resetToInitialState,
  unSelectFilter,
} = mspFilter.actions;

export default mspFilter.reducer;
