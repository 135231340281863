import { useApolloClient } from '@apollo/client';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import 'react-base-table/styles.css';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import type { Dispatch } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import MyTaskSVG from '../../../assets/my-tasks.svg';
import NoDataFoundSVG from '../../../assets/no-data-found.svg';
import { useSectionHeight } from '../../../common/hooks/chip-height';
import '../../../common/react-base-table.css';
import { RootState } from '../../../common/redux-store';
import { CircularIndeterminate, Typography } from '../../../components';
import { PageInfo } from '../../../generated-types';
import { columnDefinition } from './all-task-table-config';
import { fetchAllTasksList, taskPagination } from './api-call';

const mapState = (state: RootState) => ({
  taskList: state?.allTasks.allTasks.taskList,
  loadingState: state?.allTasks?.allTasksLoading,
  searchText: state?.allTasks?.searchText,
  errorMessage: state?.allTasks?.error,
  isNextPageLoading: state?.allTasks?.isNextPageLoading,
  afterCursor: (state?.allTasks.pageInfo as PageInfo)?.afterCursor,
  hasNextPage: (state?.allTasks.pageInfo as PageInfo)?.hasNextPage,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAllTasksList,
      taskPagination,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IMyTasksProps = PropsFromRedux;

const OverlayLoading = ({
  isLoading,
  isEmpty,
  searchText,
  errorMessage,
  isNextPageLoading,
}: {
  isLoading: boolean;
  isEmpty: boolean;
  searchText: string;
  errorMessage: unknown;
  isNextPageLoading: boolean;
}) => {
  if (isNextPageLoading) {
    return (
      <div className="absolute bottom-0">
        <div className="flex items-center justify-center ">
          <div className="m-8 flex w-60 items-center justify-center space-x-6 border border-solid border-mid-50 bg-white p-3 shadow-100">
            <Typography className="text-mid" variant="body-13-regular">
              Getting more Tasks
            </Typography>
            <CircularProgress size={30} />
          </div>
        </div>
        <div className="h-1.5 w-screen" />
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center bg-mid-50 bg-opacity-50">
        <CircularIndeterminate size={36} />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">
          Something went wrong.
        </Typography>
      </div>
    );
  }

  if (isEmpty && !searchText) {
    return (
      <div className="flex h-full w-full flex-col items-center bg-opacity-50 py-20">
        <img src={MyTaskSVG as string} height={230} width={240} alt="my-Task" />
        <Typography variant="heading2" className="font-bold text-mid">
          <FormattedMessage
            id="MyTask.blankStateMsg"
            defaultMessage="You don’t have any new tasks now."
          />
        </Typography>
      </div>
    );
  }

  if (isEmpty && searchText) {
    return (
      <div className="flex h-full w-full flex-col items-center space-y-4 bg-opacity-50 py-20">
        <img
          src={NoDataFoundSVG as string}
          height={220}
          width={320}
          alt="my-Task"
        />
        <Typography variant="heading2" className="font-bold text-mid">
          <FormattedMessage
            id="MyTask.noDataTitle"
            defaultMessage="No matching Tasks found"
          />
        </Typography>
        <Typography className="text-mid-300" variant="body-14-regular">
          <FormattedMessage
            id="MyTask.noDataDescription"
            defaultMessage="Modify the search criteria. We will look through the Tasks again."
          />
        </Typography>
      </div>
    );
  }

  return null;
};

function TaskTable(props: IMyTasksProps) {
  const {
    taskList,
    loadingState,
    searchText,
    errorMessage,
    hasNextPage,
    afterCursor,
    isNextPageLoading,
    taskPagination,
  } = props;
  const sectionHeight = useSectionHeight({
    filterSectionClass: 'my-task-search-filter-section',
    classList: [
      'ba-header-section',
      'dashboard-title-section',
      'dashboard-tab-section',
    ],
  });
  const isEmpty = taskList?.length === 0;
  const loading = loadingState;
  const client = useApolloClient();
  const dispatch = useDispatch();
  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      dispatch(trackEvent(TRACKING_CONSTANTS.LOADING_MORE_TASK));
      taskPagination({ client, afterCursor });
    }
  }, [afterCursor, client, hasNextPage, taskPagination]);

  return (
    <div
      className="w-full"
      style={{ height: window.innerHeight - (sectionHeight + 27) }}
    >
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width - 2}
            height={height}
            className="font-lato"
            loading={loading}
            data={taskList ?? []}
            onEndReachedThreshold={100}
            onEndReached={handleEndReached}
            overlayRenderer={
              <OverlayLoading
                isLoading={loading}
                isEmpty={isEmpty}
                searchText={searchText}
                errorMessage={errorMessage}
                isNextPageLoading={isNextPageLoading}
              />
            }
            rowKey="id"
          >
            {columnDefinition.map(({ dataKey, ...restProps }) => (
              <Column key={dataKey} dataKey={dataKey} {...restProps} />
            ))}
          </BaseTable>
        )}
      </AutoResizer>
    </div>
  );
}

export default connector(TaskTable);
