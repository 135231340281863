/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetOpportunityFiltersQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOpportunityFiltersQuery = {
  __typename?: 'Query';
  alignxQuery?: {
    __typename?: 'AlignxQuery';
    dashboard:
      | {
          __typename?: 'Alignx';
          dashboardFiltersV1?:
            | {
                __typename: 'FilterItemsPayload';
                items?: Array<{
                  __typename?: 'FilterItems';
                  key: string;
                  displayName: string;
                  values?: Array<{ [key: string]: any }>;
                  isVisible: boolean;
                }>;
              }
            | { __typename: 'GraphqlError' };
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const GetOpportunityFiltersDocument = `
    query getOpportunityFilters {
  alignxQuery {
    dashboard {
      ...CoreErrorFields
      ... on Alignx {
        dashboardFiltersV1 {
          __typename
          ... on FilterItemsPayload {
            items {
              key
              displayName
              values
              isVisible
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getOpportunityFilters: build.query<
      GetOpportunityFiltersQuery,
      GetOpportunityFiltersQueryVariables | void
    >({
      query: (variables) => ({
        document: GetOpportunityFiltersDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOpportunityFiltersQuery,
  useLazyGetOpportunityFiltersQuery,
} = injectedRtkApi;
