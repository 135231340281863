import dayjs from 'dayjs';
import { TaskStatus } from '../../generated-types';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export function checkAttentionRequire(date: number, taskStatus: TaskStatus) {
  const oneDayBefore = dayjs().startOf('day').subtract(1, 'day');
  const oneDayAfter = dayjs().endOf('day').add(1, 'day');
  const isAttentionRequire = dayjs
    .unix(date)
    .isBetween(oneDayBefore, oneDayAfter);

  if (isAttentionRequire && taskStatus !== TaskStatus?.COMPLETED) {
    return isAttentionRequire;
  }
  return null;
}
