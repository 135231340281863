/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type CreateAccountPlanMutationVariables = Types.Exact<{
  input: Types.CreateAccountPlanInput;
}>;

export type CreateAccountPlanMutation = {
  __typename?: 'Mutation';
  accountPlanMutation?: {
    __typename?: 'AccountPlanMutation';
    createAccountPlan:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Array<
            | {
                __typename?: 'AccountPlan';
                id: string;
                extCompanyId: string;
                companyId: string;
                orgId: string;
                createdAt: number;
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const CreateAccountPlanDocument = `
    mutation createAccountPlan($input: CreateAccountPlanInput!) {
  accountPlanMutation {
    createAccountPlan(input: $input) {
      ...CoreErrorFields
      ... on AccountPlanPayload {
        items {
          ... on AccountPlan {
            id
            extCompanyId
            companyId
            orgId
            createdAt
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    createAccountPlan: build.mutation<
      CreateAccountPlanMutation,
      CreateAccountPlanMutationVariables
    >({
      query: (variables) => ({
        document: CreateAccountPlanDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateAccountPlanMutation } = injectedRtkApi;
