import { Chip } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { IStakeholder } from '../../alignx/msp-view/stakeholders/stakeholder-redux-store';
import newTheme from '../../common/newTheme';
import { CommonAvatar } from '../avatar/new-avatar';
import { CrossIcon } from '../icons';
import { Typography } from '../typography';

const useStyles = makeStyles(() => ({
  avatar: {
    fontSize: '8px !important',
  },
  root: {
    height: (props: { height: number }) => props.height,
  },
}));

const UserChip = (props: {
  member: IStakeholder;
  onClickRemove: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
    email: string,
    firstName: string,
  ) => void;
  height?: number;
}) => {
  const { member, onClickRemove, height = 32 } = props;
  const { firstName = '', lastName = '', email = '', userType, id } = member;
  const classes = useStyles({ height });
  return (
    <ThemeProvider theme={newTheme}>
      <Chip
        avatar={
          <CommonAvatar
            fullName={`${firstName || email} ${lastName ?? ''}`}
            email={email}
            userType={userType}
            fontSize={14}
          />
        }
        key={id}
        onDelete={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          onClickRemove(event, id ?? '', email, firstName)
        }
        label={
          <Typography
            variant="body-13-bold"
            className="w-20 truncate pl-2 capitalize text-neutral-400"
          >
            {firstName || email?.split('@')?.[0]} {lastName}
          </Typography>
        }
        className="capitalize"
        classes={{ avatar: classes.avatar, root: classes.root }}
        variant="outlined"
        deleteIcon={<CrossIcon className="font-12" />}
      />
    </ThemeProvider>
  );
};

export default UserChip;
