import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { KeycloakTokenParsed } from 'keycloak-js';
import { lazy, Suspense } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Layout from '../alignx/Header/Layout';
import CompanyContainer from '../alignx/influencer-map-new/account/company-container';
import { useKeycloakContext } from '../common/auth/authentication-context';
import ProtectedRoute from '../common/auth/utils/protected-route';
import ErrorPages, { ErrorCodes } from '../common/methods/error-index';
import { setUserDetails } from '../common/redux-store/reducers/user-details';
import { RootState } from '../common/redux-store/redux-types';
import SideNavigation, {
  NAV_DETAILS,
} from '../common/side-navigation/side-navigation';
import { CircularIndeterminate, InfiniteLoader } from '../components';
import { UserType } from '../generated-types';
import { lazyRetry } from '../utils/code-splitting';
import { LiteLexicalEditor } from '../ba-components';
import { useGetUserInfoQuery } from '../alignx/Header/user-info-api';
import { useGetOrganizationQuery } from '../alignx/Header/org-api';
import { orgIdExtractor } from '../utils';
import { Organization } from '../api';
import { ITabDetail } from '../alignx/msp-view/model';
import { useSortedTabList } from '../common/hooks/use-sorted-tabs';

const DashboardContainer = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackPreload: true */ '../alignx/dashboard/dashboard-container'
      ),
  ),
);

const IntegrationLogin = lazy(() =>
  lazyRetry(() => import(/* webpackPrefetch: true */ '../integration-login')),
);

const SlackLogin = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackPrefetch: true */ '../integration-login/integration-me'),
  ),
);

const OpportunityContainer = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackPrefetch: true */ '../alignx/opportunity/opportunity-container'
      ),
  ),
);

const Settings = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackPreload: true */ '../alignx/settings/settings-container'
      ),
  ),
);

const AccountPlanContainer = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackPreload: true */ '../alignx/influencer-map-new/account/account-plan-container'
      ),
  ),
);

const SharableUSPRoute = lazy(
  // eslint-disable-next-line import/no-cycle
  () =>
    lazyRetry(
      () => import('../alignx/msp-view/shareable-msp/sharable-usp-route'),
    ),
);
export interface TokenParsed extends KeycloakTokenParsed {
  sub: string;
  name: string;
  email: string;
  company: string;
  userType: UserType;
  companyId: string;
}

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.appState.isSidebarOpen,
  isBuyer: state.userInfo?.userDetails?.userType === UserType.BUYER,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setUserDetails }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Routing(props: PropsFromRedux) {
  const { isSidebarOpen, isBuyer } = props;
  const { keycloak } = useKeycloakContext();
  const { authenticated } = keycloak;

  const queryParams = new URLSearchParams(location.search);
  const isSidenavHidden = queryParams.get('sidenav') === 'false';

  const { isLoading } = useGetUserInfoQuery(undefined, {
    skip: !authenticated,
  });
  const { isLoading: isOrgLoading, data } = useGetOrganizationQuery(
    {
      orgId: orgIdExtractor(),
    },
    {
      skip: !authenticated,
    },
  );
  const tabList = (data as Organization)?.configs?.others
    ?.dashboardTabs as ITabDetail[];

  const sortedTabList = useSortedTabList({ tabList, isBuyer });
  const defaultTab = sortedTabList?.find((tab) => tab?.isDefault) as ITabDetail;
  const path =
    NAV_DETAILS.get(defaultTab?.name)?.path || '/ui/alignx/dashboard';

  if (isLoading || isOrgLoading) {
    return (
      <div className="grid h-screen w-full place-items-center">
        <InfiniteLoader width={160} />
      </div>
    );
  }

  return (
    <Suspense fallback={<CircularIndeterminate />}>
      <Router>
        {authenticated && !isSidenavHidden && <SideNavigation />}
        <div
          className={clsx(
            !isSidenavHidden &&
              authenticated &&
              (isSidebarOpen ? 'ml-60' : 'ml-14'),
          )}
        >
          <Switch>
            <Route exact path="/ui/alignx/">
              <Redirect to={path} />
            </Route>
            <Route exact path="/">
              <Redirect to={path} />
            </Route>

            <Route path="/ui/alignx/msp/:mspId/:tab?">
              <SharableUSPRoute />
            </Route>

            <ProtectedRoute exact path="/admin">
              <Layout>
                <IntegrationLogin />
              </Layout>
            </ProtectedRoute>

            <ProtectedRoute exact path="/admin/integrations">
              <Layout>
                <IntegrationLogin />
              </Layout>
            </ProtectedRoute>

            <ProtectedRoute exact path="/me/integrations">
              <Layout>
                <SlackLogin />
              </Layout>
            </ProtectedRoute>

            {process.env.STAGE !== 'prod' && (
              <ProtectedRoute exact path="/settings/:pageId">
                <Settings />
              </ProtectedRoute>
            )}
            {process.env.STAGE !== 'prod' && (
              <ProtectedRoute exact path="/lex">
                <div className="p-10">
                  <LiteLexicalEditor />
                </div>
              </ProtectedRoute>
            )}
            <ProtectedRoute exact path="/ui/alignx/:tab">
              <Layout>
                <DashboardContainer />
              </Layout>
            </ProtectedRoute>

            <ProtectedRoute exact path="/ui/alignx/company/:companyId/rmap">
              <Layout>
                <CompanyContainer />
              </Layout>
            </ProtectedRoute>
            <ProtectedRoute exact path="/ui/alignx/ap/:accountId/rmap">
              <Layout>
                <AccountPlanContainer />
              </Layout>
            </ProtectedRoute>

            <ProtectedRoute exact path="/ui/alignx/opportunity/:opportunityId">
              <Layout>
                <OpportunityContainer />
              </Layout>
            </ProtectedRoute>

            <Route exact path="/unauthorized">
              <Layout>
                <ErrorPages errorCode={ErrorCodes.ACCESS_DENIED} />
              </Layout>
            </Route>

            <Route>
              <Layout>
                <ErrorPages errorCode={ErrorCodes.PAGE_NOT_FOUND} />
              </Layout>
            </Route>
          </Switch>
        </div>
      </Router>
    </Suspense>
  );
}

export default connector(Routing);
