import { useMemo, useState } from 'react';
import { BaTabs } from '../../../components';
import OpportunityTab from './opportunity/opportunity-tab';
import AccountTab from './account/account-tab';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../common/redux-store';
import { Organization, UserType } from '../../../generated-types';
import { ITabDetail } from '../../msp-view/model';

function getComponents(value: string) {
  switch (value) {
    case 'accounts':
      return <AccountTab />;
    case 'opportunities':
      return <OpportunityTab />;
    default:
      return <div>Not Found</div>;
  }
}

const mapState = (state: RootState) => {
  const tabList = (state?.organization as Organization)?.configs?.others
    ?.dashboardTabs as ITabDetail[];
  const rmapTab = tabList?.find((tab) => tab.name === 'rMap');
  const subTabs = rmapTab?.subTabs || ([] as ITabDetail[]);

  return {
    subTabs,
    isBuyer: state.userInfo?.userDetails?.userType === UserType.BUYER,
  };
};
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function RmapDashboardContainer(props: PropsFromRedux) {
  const { subTabs, isBuyer } = props;
  const [selectedTab, setSelectedTab] = useState(subTabs?.[0]?.label);

  const sortedTabList = useMemo(() => {
    const copyList = [...subTabs]
      ?.filter((tab) => tab?.isEnabled && (isBuyer ? !tab?.isInternal : true))
      ?.sort((a, b) => Number(a?.order ?? 0) - Number(b?.order ?? 0));
    const defaultTab = copyList?.find((tab) => tab?.isDefault);
    setSelectedTab(defaultTab?.label || copyList?.[0]?.label);
    return copyList;
  }, [subTabs, isBuyer]);

  const tabList = sortedTabList?.map((tab) => tab?.label);

  return (
    <BaTabs.Root
      className="flex grow flex-col"
      value={selectedTab}
      onValueChange={setSelectedTab}
    >
      <BaTabs.List className="pl-6">
        {tabList.map((tab) => (
          <BaTabs.Tab key={tab} tabName={tab} selectedTab={selectedTab} />
        ))}
      </BaTabs.List>
      <section>
        {sortedTabList?.map((tab) => (
          <BaTabs.Content key={tab?.label} value={tab?.label}>
            {getComponents(tab?.value || '')}
          </BaTabs.Content>
        ))}
      </section>
    </BaTabs.Root>
  );
}

export default connector(RmapDashboardContainer);
