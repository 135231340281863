import { useApolloClient } from '@apollo/client';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { FiCalendar } from 'react-icons/fi';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { RootState } from '../../../common/redux-store';
import { AssigneeIcon, OverDueTaskIcon } from '../../../components/icons';
import { Typography } from '../../../components/typography';
import { BoxFilterType } from '../models';
import { setBoxFilterDetails } from './all-tasks-redux-store';
import { fetchAllTasksFiltersCount } from './api-call';

const mapIcon = {
  assigneeIcon: <AssigneeIcon />,
  overdueIcon: <OverDueTaskIcon />,
  calendarIcon: <FiCalendar />,
} as Record<string, unknown>;

const mapState = (state: RootState) => ({
  additionalBoxFiltersDetails: state?.allTasks?.additionalBoxFilters,
  isFilterLoading: state?.allTasks?.filterLoading,
  allTaskFilterCount: state?.allTasks?.allTaskFilterCount,
  filterCountIds: state?.allTasks?.filterCountIds,
});
const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

function AllTaskFilter(props: PropsFromRedux) {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const {
    additionalBoxFiltersDetails,
    isFilterLoading,
    allTaskFilterCount,
    filterCountIds,
  } = props;
  const filterListData = Object.values(additionalBoxFiltersDetails);
  const handeleBoxFilters = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const filterType = event.currentTarget.id as BoxFilterType;
      dispatch(setBoxFilterDetails({ filterType }));
      dispatch(trackEvent(`Clicked Task ${filterType}`));
    },
    [],
  );
  useEffect(() => {
    if (filterCountIds && filterCountIds.length > 0) {
      dispatch(fetchAllTasksFiltersCount({ client, ids: filterCountIds }));
    }
  }, [client, dispatch, filterCountIds]);
  if (isFilterLoading) return null;
  return (
    <div className="mr-1 flex items-center justify-between space-x-4">
      {filterListData.map(({ iconType, label, filterType, id }) => (
        <div
          role="presentation"
          onClick={handeleBoxFilters}
          id={filterType}
          key={iconType + label}
          className={clsx(
            'flex cursor-pointer items-center justify-between space-x-1 rounded border border-solid p-1 px-2 hover:border-primary hover:bg-primary-100 hover:text-primary',
            additionalBoxFiltersDetails?.[filterType]?.isSelected
              ? 'bg-primary-100 text-primary'
              : 'border-mid-500 text-mid-500',
          )}
        >
          {mapIcon[iconType]}
          <Typography variant="body-13-regular" element="p">
            {Object.keys(allTaskFilterCount).length === 0
              ? label
              : `${label} (${allTaskFilterCount[id]?.count})`}
          </Typography>
        </div>
      ))}
    </div>
  );
}
export default connector(AllTaskFilter);
