import type { TypeOptions } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import { ConnectedUppyAggregator } from './uppy-aggregator';

const SnackbarComponent = () => {
  const commonSnackbarClass = 'border border-solid rounded w-auto p-1';
  const contextClass = {
    success: `bg-green-50 border-green-500 text-green-500 ${commonSnackbarClass} shadow-toast-success`,
    error: `bg-red-50 border-red-300 text-red-500 ${commonSnackbarClass} shadow-toast-error`,
    info: `bg-blue-50 border-blue-400 text-blue-500 ${commonSnackbarClass} shadow-toast-info`,
    warning: `bg-orange-50 border-orange-300 text-orange-600 ${commonSnackbarClass} shadow-toast-warning`,
    default: `bg-indigo-600 border border-solid ${commonSnackbarClass} shadow-100`,
    dark: 'bg-white-600 font-gray-300 border border-solid',
  } as Record<TypeOptions, string>;
  return (
    <>
      <ConnectedUppyAggregator>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          toastClassName={(toast): string =>
            contextClass[toast?.type || 'default']
          }
          hideProgressBar
          className="w-fit max-w-sm"
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          closeButton={false}
          draggable={false}
          pauseOnHover
        />
        <Routes />
      </ConnectedUppyAggregator>
    </>
  );
};

export default SnackbarComponent;
