import { gql, useMutation } from '@apollo/client';
import {
  BooleanResponse,
  TaskMutationAddAssigneeArgs,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../fragments/error';

type AddAssigneeInput = {
  variables: TaskMutationAddAssigneeArgs;
};

export const ADD_ASSIGNEE = gql`
  ${CORE_GRAPH_ERROR}
  mutation AddAssignee($userIdMap: UserIdMapInput!, $taskIds: [ID!]!) {
    taskMutation {
      addAssignee(userIdMap: $userIdMap, taskIds: $taskIds) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export type AddAssignResult = {
  taskMutation: { addAssignee: BooleanResponse };
};

export const AddAssigneeMutation = (
  onCompletedAddAssignee: (data: AddAssignResult) => void,
): ((addAssignee: AddAssigneeInput) => any
) => {
  const [resultAddAssignee] = useMutation(ADD_ASSIGNEE, {
    onCompleted(data: AddAssignResult) {
      onCompletedAddAssignee(data);
    },
  });
  return resultAddAssignee;
};
