import { Skeleton } from '@mui/material';
import React from 'react';
import { ActivityItem } from '../../../features/analytics/components/molecules';
import {
  ActivityTimeline,
  ActivityTimelineEdge,
} from '../../../generated-types';
import SubSectionHeader from './sub-section-header';

interface RecentChangesViewProps {
  data: [ActivityTimelineEdge];
  isLoading: boolean;
  isError: boolean;
}

function RecentChangesView(props: RecentChangesViewProps) {
  const { data, isLoading, isError } = props;
  if (isLoading) {
    return (
      <>
        <SubSectionHeader content="Recent Activities" />
        <div className="flex flex-row items-center justify-between px-8">
          <div className="flex flex-row justify-start space-x-2">
            <Skeleton
              variant="rectangular"
              width={20}
              height={20}
              animation="wave"
            />
            <Skeleton animation="wave" variant="text" width={270} />
          </div>

          <Skeleton animation="wave" variant="text" width={70} />
        </div>
      </>
    );
  }
  if (Array.isArray(data)) {
    return (
      <>
        <SubSectionHeader content="Recent Activities" />
        <div className="relative h-full w-full break-all px-8">
          {/* <hr className="m-0 w-0 border-0 border-l border-solid border-gray-500 border-opacity-60 h-3/4 absolute ml-3 mt-1" /> */}
          {data?.length > 0 ? (
            data?.map((activity) => (
              <ActivityItem
                key={(activity?.node as ActivityTimeline)?.createdAt}
                message={(activity?.node as ActivityTimeline)?.message}
                userType={
                  (activity?.node as ActivityTimeline)?.authorizer
                    .type as string
                }
                eventType={(activity?.node as ActivityTimeline)?.eventType}
                createdAt={(activity?.node as ActivityTimeline)?.createdAt}
                hLayout
              />
            ))
          ) : (
            <div>No activities in the last 30 days</div>
          )}
        </div>
      </>
    );
  }
  return null;
}

export default RecentChangesView;
