/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetCompanyDetailsQueryVariables = Types.Exact<{
  input: Types.Scalars['ID'];
}>;

export type GetCompanyDetailsQuery = {
  __typename?: 'Query';
  accountPlanQuery?: {
    __typename?: 'AccountPlanQuery';
    getAccountPlan:
      | {
          __typename?: 'AccountPlanPayload';
          items?: Array<
            | {
                __typename?: 'AccountPlan';
                id: string;
                companyId: string;
                orgId: string;
                sellerCompany?:
                  | {
                      __typename?: 'Company';
                      id: string;
                      orgId: string;
                      companyName: string;
                      displayName: string;
                    }
                  | { __typename?: 'GraphqlError' };
                buyerCompany?:
                  | {
                      __typename?: 'Company';
                      id: string;
                      orgId: string;
                      companyName: string;
                      displayName: string;
                      logo?:
                        | { __typename?: 'GraphqlError' }
                        | {
                            __typename?: 'Logo';
                            id: string;
                            fileName?: string;
                            url: string;
                          };
                    }
                  | { __typename?: 'GraphqlError' };
              }
            | { __typename?: 'GraphqlError' }
          >;
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const GetCompanyDetailsDocument = `
    query getCompanyDetails($input: ID!) {
  accountPlanQuery {
    getAccountPlan(accountPlanId: $input) {
      ...CoreErrorFields
      ... on AccountPlanPayload {
        items {
          ... on AccountPlan {
            id
            companyId
            orgId
            sellerCompany {
              ... on Company {
                id
                orgId
                companyName
                displayName
              }
            }
            buyerCompany {
              ... on Company {
                id
                orgId
                companyName
                displayName
                logo {
                  ... on Logo {
                    id
                    fileName
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getCompanyDetails: build.query<
      GetCompanyDetailsQuery,
      GetCompanyDetailsQueryVariables
    >({
      query: (variables) => ({
        document: GetCompanyDetailsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCompanyDetailsQuery, useLazyGetCompanyDetailsQuery } =
  injectedRtkApi;
