import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  Company,
  GraphqlError,
  OrganizationResponse,
} from '../../generated-types';
import { NETWORK_ERROR } from '../create-msp/models';
import { fetchCompanyByName } from './get-company-name';

const orgSlice = createSlice({
  name: 'organization',
  initialState: {} as OrganizationResponse & {
    company?: GraphqlError | Company;
    companyError?: string;
    companyLoading?: boolean;
  },
  reducers: {
    setOrganization: (state, action: PayloadAction<OrganizationResponse>) =>
      action.payload,
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyByName.fulfilled, (state, { payload }) => {
        state.company = payload;
      })
      .addCase(fetchCompanyByName.pending, (state) => {
        state.companyLoading = true;
      })
      .addCase(fetchCompanyByName.rejected, (state) => {
        state.companyError = NETWORK_ERROR;
        state.companyLoading = false;
      });
  },
});

export const { setOrganization, setCompany } = orgSlice.actions;

export default orgSlice.reducer;
