import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { ReactNode } from 'react';

const Root: React.FC<Popover.PopoverProps> = Popover.Root;

const Anchor: React.ForwardRefExoticComponent<
  Popover.PopoverAnchorProps & React.RefAttributes<HTMLDivElement>
> = Popover.Anchor;

const Trigger: React.ForwardRefExoticComponent<
  Popover.PopoverTriggerProps & React.RefAttributes<HTMLButtonElement>
> = Popover.Trigger;

const Arrow = ({
  fillColorClass = 'fill-white',
}: {
  fillColorClass?: 'fill-white' | 'fill-black';
}) => <Popover.Arrow className={clsx('shadow-100', fillColorClass)} />;

const Close: React.ForwardRefExoticComponent<
  Popover.PopoverCloseProps & React.RefAttributes<HTMLButtonElement>
> = Popover.Close;

const Content = ({
  children,
  className,
  bgColorClass = 'bg-white',
  paddingclass = 'px-3 py-2',
  ...props
}: {
  children: ReactNode;
  className?: string;
  bgColorClass?: 'bg-black' | 'bg-white';
  paddingclass?: string;
} & Popover.PopoverContentProps) => (
  <Popover.Portal>
    <Popover.Content
      {...props}
      className={clsx(
        'rounded-md shadow-400',
        className,
        bgColorClass,
        paddingclass,
      )}
    >
      {children}
    </Popover.Content>
  </Popover.Portal>
);

export const BaPopover = {
  Root,
  Arrow,
  Close,
  Content,
  Trigger,
  Anchor,
};
