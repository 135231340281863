import { grey } from '@mui/material/colors';
import { theme } from './theme';

function CompletionStatus({
  diameter,
  completionPercentage = 0,
  isAnalyticsProgress = false,
}: {
  diameter: number;
  completionPercentage: number;
  isAnalyticsProgress?: boolean;
}) {
  const strokeWidth = diameter / 9;
  const radius = diameter / 2 - strokeWidth;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (completionPercentage / 100) * circumference;

  return (
    <svg className="progress-ring" width={diameter} height={diameter}>
      <circle
        stroke={grey[300]}
        strokeWidth={strokeWidth}
        fill={`${isAnalyticsProgress ? 'transparent' : 'white'}`}
        strokeDashoffset="0"
        r={radius}
        cx={diameter / 2}
        cy={diameter / 2}
      />
      <circle
        className="progress-ring__circle"
        stroke={theme.palette.green.main}
        strokeWidth={strokeWidth}
        fill="transparent"
        strokeLinecap="round"
        r={radius}
        cx={diameter / 2}
        cy={diameter / 2}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
      />
    </svg>
  );
}
export default CompletionStatus;
