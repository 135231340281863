import { BiDislike, BiLike } from 'react-icons/bi';
import { Typography } from '../../../components';
import { StatusCardLabel } from '../../../features/analytics/components/atoms';
import { MspTrackStatus } from '../../../generated-types';

interface SummaryStatusCardProps {
  label: string;
  content: MspTrackStatus;
  secondaryContent: string;
}

function SummaryStatusCard({
  label,
  content,
  secondaryContent,
}: SummaryStatusCardProps) {
  const contentColorClass = content === MspTrackStatus.ON_TRACK ? 'text-green-500' : 'text-warning';
  const formattedContent = content === MspTrackStatus.ON_TRACK ? 'On Track' : 'Off Track';

  return (
    <div className="pl-4 w-[180px] pb-3 rounded border border-solid border-[#DCE3EA]">
      <StatusCardLabel label={label} />
      <div
        className="mb-2 leading-9 flex items-center space-x-1.5"
      >
        <Typography variant="heading2-bold" className={`text-lg ${contentColorClass}`}>{formattedContent}</Typography>
        <div className={`flex ${contentColorClass}`}>
          {content === MspTrackStatus.ON_TRACK ? <BiLike size={20} /> : <BiDislike size={20} />}
        </div>
      </div>

      <Typography
        variant="label-12-medium"
        className="pt-3 mt-5 text-[10px] text-mid"
      >
        {secondaryContent}
      </Typography>
    </div>
  );
}
export default SummaryStatusCard;
