import React from 'react';
import { Typography } from '../../../components';
import { StatusCardLabel } from '../../../features/analytics/components/atoms';

interface LastUpdateCardProps {
    label: string
    content: string;
    secondaryContent: string;
  }

function LastActivityCard({ label, content, secondaryContent }:LastUpdateCardProps) {
  return (
    <div className="pl-4 w-[180px] pb-3 rounded border border-solid border-[#DCE3EA]">
      <StatusCardLabel label={label} />
      <Typography
        variant="heading3-bold"
        className="mb-2 text-mid"
      >
        {content}
      </Typography>
      <Typography variant="label-12-medium" className="pt-3 mt-5 text-[10px] text-mid">
        {secondaryContent}
      </Typography>
    </div>
  );
}
export default LastActivityCard;
