import { diff } from 'deep-object-diff';
import { JSONPath } from 'jsonpath-plus';
import { useCallback, useState } from 'react';
import {
  GraphItemData,
  GraphNode,
  IRelationshipBaseItem,
  Operation,
  Operator,
  RelationshipContact,
  RelationshipGraph,
  RelationshipItemEdge,
  RelationshipScopeType,
  Structure,
  StructureField,
  StructureFieldProperty,
} from '../../generated-types';
import {
  EContactCard,
  ECreateContactAttribute,
  EFieldTypes,
  EUpdateContactAttribute,
  IAssigneeUser,
  IContact,
  IFormField,
  IFormOptionDetails,
  JsonRecord,
  TContactRole,
  TNotesType,
  ValueType,
} from './models';

export function transformContactList(list: RelationshipItemEdge[]) {
  const result = [] as IRelationshipBaseItem[];

  for (let i = 0; i < list?.length; i += 1) {
    const item = list[i]?.node;
    if (item) result.push(item);
  }

  return result;
}

export function transformIntoStructuredNode({
  structure,
  input,
}: {
  structure: Structure;
  input: IRelationshipBaseItem;
}) {
  const fieldPropertyList: StructureFieldProperty[] = JSONPath({
    path: '$.fields[*].fieldProperty',
    json: structure,
  });
  const pathList = fieldPropertyList.reduce((acc, cur) => {
    const path: string[] = JSONPath({
      path: 'dataSourceConfig.path.defaultValuePath[*].value',
      json: cur,
    });
    if (path?.length && cur?.key) {
      acc.push({
        key: cur?.key,
        path: path[0].split('items[*].').slice(1).join('items[*].'),
      });
    }

    return acc;
  }, [] as { key: string; path: string }[]);

  const result = pathList.map(({ key, path }) => {
    const pathValue: ValueType = JSONPath({
      path,
      json: input,
    });
    const value: ValueType =
      key === EContactCard.CONTACT_ROLE
        ? (pathValue as JsonRecord[]).map(({ id, name }) => ({
            id,
            value: name,
          }))
        : ((pathValue as JsonRecord[])?.[0] as ValueType);

    return { key, value };
  });

  return result;
}

export function transformIntoStructuredForm({
  structure,
  input,
}: {
  structure: Structure;
  input: IRelationshipBaseItem;
}) {
  const fieldPropertyList: StructureFieldProperty[] = JSONPath({
    path: '$.fields[*].fieldProperty',
    json: structure,
  });
  const pathList = fieldPropertyList.reduce((acc, cur) => {
    const path: string[] = JSONPath({
      path: 'dataSourceConfig.path.defaultValuePath[*].value',
      json: cur,
    });

    if (path?.length && cur?.key) {
      acc.push({
        key: cur?.key,
        path: path[0].split('items[*].').slice(1).join('items[*].'),
      });
    }

    return acc;
  }, [] as { key: string; path: string }[]);

  const result = pathList.map(({ key, path }) => {
    const pathValue: ValueType = JSONPath({ path, json: input });
    const value =
      key === EUpdateContactAttribute.CONTACT_ROLE
        ? (pathValue as TContactRole[])
        : ((pathValue as Record<string, string>[])?.[0] as ValueType);
    return { key, value: value || null };
  });

  return result;
}

export function useNoteModal<T>() {
  const [noteEl, setNoteEl] = useState<T | null>(null);
  const openNoteModal = useCallback((event: React.MouseEvent<T>) => {
    setNoteEl(event.currentTarget);
  }, []);
  const closeNoteModal = useCallback(() => {
    setNoteEl(null);
  }, []);

  return {
    noteEl,
    setNoteEl,
    openNoteModal,
    closeNoteModal,
  };
}

export function getFormFields(
  contactFormStructure: Structure,
  data: IRelationshipBaseItem,
) {
  const formFieldDetails = transformIntoStructuredForm({
    structure: contactFormStructure,
    input: data,
  });
  const fields = formFieldDetails.reduce(
    (acc, { key = '', value = '' }) => Object.assign(acc, { [key]: value }),
    {},
  );

  return fields as IFormField;
}

const blackListRegEx =
  /(^[{][}])|({"(height|selected|actionType|animated)":\s?([\\]?"\w+[\\]?"|\w+)})|({[\\]?"(height|width)[\\]?":\w+,\s?[\\]?"(height|width)[\\]?":\w+})/i;

function checkNestedChanges<T extends object>(diff: T) {
  let isChanged = false;
  if (Object.keys(diff).length > 0) {
    Object.entries(diff).forEach(([, value]) => {
      const str = JSON.stringify(value);
      const isBlackListed = blackListRegEx.test(str);
      if (!isBlackListed) {
        isChanged = isChanged || true;
      }
    });
  }
  return isChanged;
}

export function getChangeStatus<N, E>({
  newNodes,
  newEdges,
  oldNodes,
  oldEdges,
}: {
  newNodes: N[];
  newEdges: E[];
  oldNodes: N[];
  oldEdges: E[];
}) {
  const nodeDiff = diff(newNodes, oldNodes);
  const edgeDiff = diff(newEdges, oldEdges);
  let isChanged = false;

  isChanged = isChanged || checkNestedChanges(nodeDiff);
  isChanged = isChanged || checkNestedChanges(edgeDiff);

  return isChanged;
}

export const reduceColors = (
  acc: { [key: string]: string },
  cur: IFormOptionDetails,
) => {
  acc[cur.displayName] = cur?.meta?.color?.rgb;
  return acc;
};

function getDataJSON<T>(
  type: string,
  userId: string,
  value: T | TNotesType | IAssigneeUser,
  key: string,
) {
  switch (type) {
    case EFieldTypes.TEXT:
      return key === EUpdateContactAttribute.LINKEDIN_URL
        ? { id: userId, profileUrl: value }
        : { id: userId, title: value };
    case EFieldTypes.DROPDOWN: {
      const { id: relationId, name } = (value || {}) as {
        id: string;
        name: string;
      };
      return key === EUpdateContactAttribute.CONTACT_ROLE
        ? value
        : key === EUpdateContactAttribute.STAKEHOLDER_ROLE
        ? { id: relationId }
        : relationId && name
        ? { id: relationId, value: name }
        : { value: null };
    }
    case EFieldTypes.RICH_TEXT: {
      return {
        id: (value as TNotesType)?.id || null,
        message: (value as TNotesType)?.messageData?.message ?? '',
      };
    }
    case EFieldTypes.ASSIGNEES: {
      return { value: (value as IAssigneeUser)?.id || null };
    }
    case EFieldTypes.CHECK_BOX: {
      return { value };
    }
    default:
      return {};
  }
}

export function formatCardUpdatedValues<T>(
  userId: string,
  editedValues: { [x: string]: T | ValueType },
  formStructure: Structure,
) {
  const { id: structureId, fields } = formStructure;

  const fieldTypes = fields?.reduce(
    (acc, { fieldProperty: { key = '', fieldType = '' } = {} }) =>
      Object.assign(acc, { [key]: fieldType }),
    {},
  ) as { [key: string]: string };

  const fieldsToUpdate = Object.keys(editedValues).map((keyName) => {
    const isContactRole = keyName === EUpdateContactAttribute.CONTACT_ROLE;

    const dataJson = getDataJSON(
      fieldTypes[keyName],
      userId,
      editedValues[keyName],
      keyName,
    );
    const data = isContactRole ? dataJson : [dataJson];

    return {
      key: keyName,
      fieldContextType: '',
      data,
    };
  });

  const updatedValue = { id: userId, structureId, fields: fieldsToUpdate };

  return updatedValue;
}

export const NONE_OPTION: IFormOptionDetails = {
  id: '',
  name: 'None',
  displayName: 'None',
  meta: {
    color: {
      rgb: 'white',
      hex: 'white',
    },
  },
  __typename: 'RelationshipItemsFieldValue',
};

export const sortFieldsByOrder = (
  first: StructureField,
  second: StructureField,
) => {
  const { order: firstOrder } = first?.fieldProperty as StructureFieldProperty;
  const { order: secondOrder } =
    second?.fieldProperty as StructureFieldProperty;

  return (firstOrder as number) - (secondOrder as number);
};

const IS_LINKEDIN_VIEWED = 'isLinkedInIntroViewed';

export const setLinkedInIntroViewed = () =>
  localStorage.setItem(IS_LINKEDIN_VIEWED, 'true');

export const isLinkedInIntroViewed = () =>
  localStorage.getItem(IS_LINKEDIN_VIEWED) === 'true' || false;

export const getUserIdsFromNodes = (nodes: GraphNode[]) =>
  nodes?.map((node) => {
    const payload = (node?.data as GraphItemData)?.dataPayload;
    const nameDetails = payload?.find(({ key }) => key === EContactCard.NAME);
    const userId = nameDetails?.id || '';
    return userId;
  });

export function getContactListWithTick(
  contactList: RelationshipContact[],
  nodes: GraphNode[],
) {
  const nodeUserIds = getUserIdsFromNodes(nodes);
  const contactListWithTick = contactList?.map((contact) => ({
    ...contact,
    isPresentInGraph: nodeUserIds?.includes(contact?.id),
  }));

  return contactListWithTick;
}

export function getAccountContactsVariables({
  scopeId,
  scopeType,
}: {
  scopeId: string;
  scopeType: RelationshipScopeType;
}) {
  return {
    otherContactInput: {
      pageConfiguration: {
        limit: 10,
        afterCursor: '',
      },
      operations: {},
      scope: {
        id: scopeId,
        type: scopeType,
      },
    },
    defaultContactInput: {
      pageConfiguration: {
        limit: 10,
        afterCursor: '',
      },
      operations: {},
      scope: {
        id: scopeId,
        type: scopeType,
      },
    },
  };
}

export function getSearchContactsVariables({
  scopeId,
  scopeType,
  searchTerm,
}: {
  scopeId: string;
  scopeType: RelationshipScopeType;
  searchTerm: string;
}) {
  return {
    scope: {
      id: scopeId,
      type: scopeType,
    },
    operations: {
      filter: {
        operator: Operator.OR,
        filters: [
          {
            op: Operation.LIKE,
            values: [searchTerm],
            field: 'user.firstName',
          },
          {
            op: Operation.LIKE,
            values: [searchTerm],
            field: 'user.lastName',
          },
          {
            op: Operation.LIKE,
            values: [searchTerm],
            field: 'user.email',
          },
        ],
      },
    },
  };
}

export function getUserFromCreateFrom({
  userId,
  fromData,
}: {
  userId: string;
  fromData: Record<string, ValueType>;
}) {
  const contactRoles = fromData?.[ECreateContactAttribute.CONTACT_ROLE];
  const email = fromData?.[ECreateContactAttribute.EMAIL] as string;
  const title = fromData?.[ECreateContactAttribute.TITLE] || undefined;

  const firstName = fromData?.[ECreateContactAttribute.FIRST_NAME] as string;
  const lastName = fromData?.[ECreateContactAttribute.LAST_NAME] as string;
  const companyName = fromData?.[ECreateContactAttribute.COMPANY] as string;

  const name = `${firstName || ''} ${lastName || ''}`;

  const newUser = {
    id: userId,
    name: firstName && lastName ? name : email?.split('@')?.[0],
    title,
    contactRoles,
    objectType: 'CONTACT',
    description: 'NEW_CONTACT',
    isPresentInGraph: false,
    buyerCompany: {
      displayName: companyName || '',
    },
  } as IContact;

  return newUser;
}

export function sortImportList(list: RelationshipGraph[]) {
  const sortedList = list
    .slice()
    .sort((a, b) =>
      (a?.scope?.scopeType as RelationshipScopeType).localeCompare(
        b?.scope?.scopeType as RelationshipScopeType,
      ),
    );

  return sortedList;
}

const SEEN_INTRO_INDEX = 'seenIntroIndex';

export const setSeenIntroIndex = (seenIntroIndex: number) =>
  localStorage.setItem(SEEN_INTRO_INDEX, String(seenIntroIndex));

export const getSeenIntroIndex: () => number = () => {
  const seenIntroIndex = localStorage.getItem(SEEN_INTRO_INDEX);
  const seenIntroIndexNum = Number(seenIntroIndex);

  return !isNaN(seenIntroIndexNum) ? seenIntroIndexNum : 0;
};
