/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePageViews } from '../../common/hooks/use-page-views';
import { RootState } from '../../common/redux-store';
import {
  GraphqlError,
  MspPermissionPayload,
  MspUserBridge,
  Organization,
} from '../../generated-types';
import {
  ResultNotification,
  fetchNotification,
} from '../msp-view/notification/api-call';
import { POLL_NOTIFICATION_LIST } from '../msp-view/notification/graphql';
import { setPollingNotificationData } from '../msp-view/notification/notification-redux-store';
import { useGetCompanyInfoQuery, useGetOrganizationQuery } from './org-api';
import { orgIdExtractor } from '../../utils';

function getPageFromRoute(path: string) {
  switch (path) {
    case '/ui/alignx':
      return 'Dashboard';
    case '/admin/integration':
      return 'Admin';
    default:
      return ' Dashboard';
  }
}

export type userDetails = {
  name: string;
  email: string;
  userId: string;
};

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    Appcues: any;
    smartlook: any;
    DD_RUM: any;
  }
}

const mapStateToProps = (state: RootState) => ({
  userDetails: state.userInfo.userDetails,
  organization: state.organization,
  notificationList: state.notification.notificationList,
  mspPermissions: (
    (state?.msp?.mspDetail?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchNotification,
      setPollingNotificationData,
    },
    dispatch,
  );
const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
const mspViewPath = '/ui/alignx/msp/';

function Header(props: PropsFromRedux) {
  const {
    organization,
    userDetails,
    notificationList,
    fetchNotification,
    setPollingNotificationData,
  } = props;

  usePageViews();
  const { pathname: currentPath } = useLocation();

  const checkMspPath = currentPath.includes(mspViewPath);

  const org = organization as Organization;

  if (organization?.__typename === 'GraphqlError') {
    const error = organization as GraphqlError;
    throw new Error(`${error.code}: ${error.message}`);
  }

  const { name: userName, email, userId, company, userType } = userDetails;

  const orgFaviconURL = org?.orgLogo?.favicon;
  const client = useApolloClient();

  const companyName = org?.orgName;
  useGetCompanyInfoQuery(
    {
      companyName,
    },
    { skip: !companyName },
  );

  const [notificationPolling, { data, error: pollingError, stopPolling }] =
    useLazyQuery<ResultNotification>(POLL_NOTIFICATION_LIST, {
      pollInterval: 60000,
      variables: {
        userId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (data) setPollingNotificationData(data);
  }, [data, setPollingNotificationData]);

  if (pollingError) {
    stopPolling?.();
  }

  useEffect(() => {
    if (process.env.STAGE !== 'local') {
      if (!notificationList?.length && userId) {
        fetchNotification({ client, userId });
      }
      if (userId) {
        notificationPolling();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page();
      window.Appcues.identify(userId, {
        name: userName,
        email,
      });
    }
  }, [currentPath]);

  useEffect(() => {
    if (window.smartlook) {
      window.smartlook('identify', userId, {
        name: userName,
        email,
        company,
        type: userType,
      });
    }
  }, [company, email, userId, userName, userType]);

  useEffect(() => {
    if (window.DD_RUM) {
      datadogRum.setUser({
        id: userId,
        name: userName,
        email,
        userType,
        company,
      });
    }
  }, [company, email, userId, userName, userType]);

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.identify(userId, {
        name: userName,
        email,
        stage: process.env.STAGE,
      });
    }
  }, [window.Appcues]);

  useGetOrganizationQuery({
    orgId: orgIdExtractor(),
  });

  let title = 'BuyerAssist';
  const page = getPageFromRoute(window.location.pathname);
  if (page) {
    title = `${title} | ${page}`;
  }

  if (checkMspPath) return null;

  return (
    <section className="ba-header-section sticky top-0 z-50">
      <Helmet>
        <link rel="icon" href={orgFaviconURL} />
        <title>{title}</title>
      </Helmet>
      {/* <header className="bottom-divider box-border flex h-13 flex-row items-center justify-between bg-white px-7 shadow-80">
        {!CompanyIcon ? (
          <Skeleton width="10%" height={50} animation="wave" />
        ) : (
          <img
            aria-hidden="true"
            onClick={() => history.push('/ui/alignx')}
            className="block h-10 w-auto max-w-3xl cursor-pointer rounded-md p-2"
            src={CompanyIcon}
            alt={`${org?.orgName || 'Home'}`}
          />
        )}
        <div className="flex items-center justify-around">
          <Notification />
          <UserMenu />
        </div>
      </header> */}
    </section>
  );
}

export default connector(Header);
