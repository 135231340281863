export const orgIdExtractor = (url: string = window.location.host) => {
  const orgId = url.split('.');
  return orgId[0];
};
export const buildDomain = () => {
  const track = process.env.TRACK;
  const localToRemote = process.env.LOCAL_TO_REMOTE;
  let domain = 'buyerassist.io';
  let stage = process.env.STAGE || '';
  if (stage === 'prod') {
    return domain;
  }
  if (track === 'track' || track === 'staging') {
    domain = `${track}.${domain}`;
  }
  if (stage === 'local' && localToRemote) stage = localToRemote;
  domain = `${stage}.${domain}`;
  return domain;
};

export const getAPIUrl = () => {
  if (process.env.LOCAL_TO_REMOTE === 'mock') {
    return 'http://localhost:30003/local/graphql';
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/graphql`;
  // console.log('getAPIUrl', url);
  return url;
};

export const getLoginUrl = () => {
  const url = `https://login.${buildDomain()}/auth`;

  // console.log('getLoginUrl', url);
  return url;
};

export const getRedirectUrl = () => {
  const destination =
    window.location.pathname === '/' ? '/ui/alignx' : window.location.pathname;

  const queryParams = new URLSearchParams(location.search).toString();

  let baseUrl = getBaseUrl();
  if (process.env.STAGE === 'local') {
    baseUrl = `https://${orgIdExtractor()}.local.buyerassist.io`;
  }
  const redirectUrl = `${baseUrl}${destination}?${queryParams}`;

  // console.log('getRedirectUrl', { redirectUrl, baseUrl });
  return redirectUrl;
};

export const getBaseUrl = () => {
  const baseUrl = `https://${orgIdExtractor()}.${buildDomain()}`;
  // console.log('getBaseUrl', { baseUrl });
  return baseUrl;
};

export const getCompanionUrl = () => {
  const companionUrl = `https://companion.${buildDomain()}`;
  return companionUrl;
};

export const getCompanyDefaultLogoUrl = () => {
  const cloudfrontUrl = 'https://content.buyerassist.io';
  const url = `${cloudfrontUrl}/public/default/logo/company/logo.svg`;
  return url;
};

export const getDefaultAvatarUrl = () => {
  const cloudfrontUrl = 'https://content.buyerassist.io';
  const url = `${cloudfrontUrl}/public/default/logo/company/logo.svg`;
  // return url;
  return 'https://static.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry';
};

export const getLinkedInBackdropUrl = () => {
  const cloudfrontUrl = 'https://content.buyerassist.io';
  const url = `${cloudfrontUrl}/public/default/logo/company/logo.svg`;
  // return url;
  return 'https://static.licdn.com/sc/h/5q92mjc5c51bjlwaj3rs9aa82';
};
