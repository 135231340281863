import {
  AiFillThunderbolt, AiOutlineCloudUpload, AiOutlineDelete, AiOutlineUserSwitch,
} from 'react-icons/ai';
import {
  BiEditAlt, BiLink, BiUserPlus,
} from 'react-icons/bi';
import { FaRegClone, FaRegEye } from 'react-icons/fa';
import {
  FiUserCheck, FiUserMinus, FiUserPlus,
} from 'react-icons/fi';
import { HiDownload } from 'react-icons/hi';
import { IoIosShareAlt, IoMdAdd, IoMdCreate } from 'react-icons/io';
import { IoEyeOffOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import {
  MdChecklist,
  MdFileDownloadDone, MdOutlineDescription, MdOutlineTimelapse,
} from 'react-icons/md';
import { RiAttachment2, RiChat3Line } from 'react-icons/ri';
import { TbCopy, TbPin, TbPinnedOff } from 'react-icons/tb';
import { TiLink } from 'react-icons/ti';
import { MspVisitsWhiteIcon } from '../icons';

export function ActivityIcon({ eventType }: {eventType: string}) {
  switch (eventType) {
    case 'COMMENTS_ADDED':
    case 'COMMENTS_REMOVED':
    case 'COMMENTS_UPDATED':
      return <RiChat3Line />;
    case 'MEDIA_ATTACH':
    case 'MEDIA_CREATE_AND_ATTACH':
      return <RiAttachment2 />;
    case 'MEDIA_EXTERNAL_LINK_CREATE':
    case 'MEDIA_EXTERNAL_LINK_REVOKE':
      return <BiLink />;
    // case 'MEDIA_DETACH':
    case 'MEDIA_UNPIN':
      return <TbPinnedOff />;
    case 'MEDIA_PIN':
      return <TbPin />;
    // case 'MEDIA_UPDATE':
    case 'MEDIA_EXTERNAL_LINK_ACCESS':
      return <TiLink />;
    case 'MEDIA_DOWNLOAD':
      return <HiDownload />;
    case 'MEDIA_VIEW':
      return <FaRegEye />;
    case 'MILESTONE_CLONE':
      return <TbCopy />;
    case 'MILESTONE_CREATE':
    case 'MILESTONE_CREATE_FROM_TEMPLATE':
    case 'MSP_CREATE':
    case 'MSP_CREATE_FROM_TEMPLATE':
    case 'TASK_BULK_CREATE':
    case 'TASK_CREATE':
      return <IoMdCreate />;
    case 'MILESTONE_DELETE':
    case 'MSP_DELETE':
    case 'TASK_DELETE':
    case 'TIMELINE_BULK_DELETE':
      return <AiOutlineDelete />;
    // case 'MILESTONE_DETAILS_UPSERT':
    case 'MILESTONE_UPDATE':
      return <MdChecklist />;
    case 'MSP_ENGAGEMENT':
      return <img src={MspVisitsWhiteIcon} alt="msp-visits" />;
    case 'MSP_INVITE':
      return <IoIosShareAlt />;
    case 'MSP_PUBLISH':
      return <AiOutlineCloudUpload />;
    case 'MSP_UPDATE':
    case 'TIMELINE_BULK_UPDATE':
      return <BiEditAlt />;
    // case 'OPPORTUNITY_LINK':
    case 'OWNER_TRANSFER':
      return <AiOutlineUserSwitch />;
    // case 'PLACEHOLDER_BULK_REMOVE':
    // case 'PLACEHOLDER_BULK_CREATE_AND_ASSIGN':
    // case 'PLACEHOLDER_REMOVE':
    case 'ROLE_CREATE':
      return <IoMdAdd />;
    case 'ROLE_REMOVE':
      return <IoRemoveCircleOutline />;
    // case 'ROLE_UPDATE':
    case 'PLACEHOLDER_CREATE_AND_ASSIGN':
    case 'REPLACE_PLACEHOLDER_WITH_STAKEHOLDER':
    case 'STAKEHOLDER_ADD':
    case 'BULK_STAKEHOLDER_ADD':
    case 'TASK_ADD_ASSIGNEE':
      return <FiUserPlus />;
    case 'STAKEHOLDER_BULK_REMOVE':
    case 'STAKEHOLDER_REMOVE':
    case 'TASK_REMOVE_ASSIGNEE':
      return <FiUserMinus />;
    case 'STAKEHOLDER_CREATE_AND_ASSIGN':
      return <FiUserCheck />;
    // case 'TASK_BULK_UPDATE':
    case 'TASK_CLONE':
      return <FaRegClone />;
    case 'TASK_STATUS_UPDATE':
    case 'TASK_UPDATE':
      return <MdOutlineDescription />;
    case 'TASK_MARK_COMPLETE':
      return <MdFileDownloadDone />;
    case 'MILESTONE_MARK_INTERNAL':
    case 'MEDIA_MARK_INTERNAL':
    case 'TASK_MARK_INTERNAL':
      return <IoEyeOffOutline fontSize={16} className="text-gray-500" />;
    // case 'TASK_REINSTATE':
    case 'TASK_MARK_PENDING':
      return <MdOutlineTimelapse />;
    case 'USER_CREATE_AND_TASK_ASSIGN':
      return <BiUserPlus />;
    default:
      return <AiFillThunderbolt />;
  }
}
