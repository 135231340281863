import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { boxShadow } from './theme-config';
// const all = require('./theme-config');

// console.log({ all });

export const fontSize = {
  12: ['12px', 1.4],
  13: ['13px', 1.4],
  14: ['14px', 1.4],
  16: ['16px', 1.5],
  18: ['18px', 1.5],
  20: ['20px', 1.5],
  24: ['24px', 1.5],
};

export const colorPalette = {
  primary: {
    100: '#EDEFFE',
    200: '#B6BEF9',
    300: '#919DF6',
    400: '#6D7DF3',
    500: '#485cf0',
    main: '#485cf0',
    700: '#2D3FC6',
  },
  secondary: {
    50: '#EAFBFE',
    100: '#D4F6FD',
    200: '#AAEDFB',
    300: '#7FE4F8',
    400: '#55DBF6',
    500: '#2ad2f4',
    main: '#2ad2f4',
    600: '#209EB7',
  },
  warning: {
    main: '#FD4954',
    dark: '#FD4954',
    light: 'rgba(253,73,84, 0.1)',
  },
  success: {
    main: '#3FBF62',
  },
  error: {
    main: '#EE224E',
  },
  mid: {
    10: 'rgba(250, 250, 250, 1)',
    20: 'rgba(227, 227, 227, 1)',
    30: '#E8EEF2',
    50: '#EFF2F4',
    100: '#DEE3E8',
    200: '#BDC8D0',
    300: '#9BACB9',
    400: '#7A91A1',
    500: '#59758A',
    main: '#59758A',
    600: '#435868',
    650: '#203B50',
  },
  neutral: {
    50: '#E9EAEB',
    100: '#D1D3D5',
    200: '#A4A8AC',
    300: '#767C82',
    400: '#495159',
    500: '#1B252F',
    main: '#1B252F',
    600: '#141C23',
  },
  greenShadow: {
    main: '0px 3px 15px 2px rgba(39, 174, 96, 0.1)',
    dark: '#59758A',
  },
  green: {
    100: '#A7F3D0',
    200: '#6EE7B7',
    light: '#6EE7B7',
    300: '#34D399',
    400: '#10B981',
    500: '#059669',
    600: '#047857',
    dark: '#047857',
    main: '#059669',
  },
  'light-bg': {
    main: 'rgba(248, 250, 252, 1)',
  },
  orange: {
    light: '#fc755710',
    main: '#fc7557',
    mid: '#FFBE40',
    dark: '#F2994A',
  },
  lightPink: {
    main: '#F0D6DF',
    dark: '#FD4954',
    contrastText: '#67263B',
  },
  lightBlue: {
    main: '#D6DCF0',
    light: '#E8F4FE',
    dark: '#485cf0',
    contrastText: '#273B80',
  },
  grey: {
    50: '#F6F8FA',
    300: '#929292',
    700: '#E3E3E3',
    900: '#FAFAFA',
  },
  yellow: {
    light: '#F89E63 ',
  },
  lightGrey: {
    main: '#F4F5FA',
  },
  disabledTextColor: { main: '#25213B' },
  warningUnderline: { main: '#E92935' },
  primaryColors: [
    { main: '#F0D6DF', dark: '#FD4954', contrastText: '#58565E' },
    { main: '#F5FFEF', contrastText: '#58565E' },
    { main: '#D4FED0', contrastText: '#58565E' },
    { main: '#B0D9FE', contrastText: '#58565E' },
    { main: '#FECC91', contrastText: '#58565E' },
  ],
  secondaryColors: [
    { main: '#D6DCF0', dark: '#485cf0', contrastText: '#58565E' },
    { main: '#D0F6FE', contrastText: '#58565E' },
    { main: '#E1B1FE', contrastText: '#58565E' },
    { main: '#FD91B1', contrastText: '#58565E' },
    { main: '#EFF3FF', contrastText: '#58565E' },
  ],
};

const newTheme = createTheme(
  adaptV4Theme({
    palette: colorPalette,
    // shadows: new Array(26).fill('none'),
    typography: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      poster: {
        fontWeight: 600,
        fontSize: 12,
      },
      h2: {
        fontWeight: 600,
        fontSize: 40,
      },
      h3: {
        fontWeight: 600,
        fontSize: 36,
      },
      h4: {
        fontWeight: 600,
        fontSize: 24,
      },
      h5: {
        fontWeight: 600,
        fontSize: 20,
      },
      h6: {
        fontWeight: 600,
        fontSize: 18,
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '20px',
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 14,
      },
      button: {
        fontWeight: 600,
        fontSize: 14,
      },
      body1: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
      },
      body2: {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },

      MuiListItem: {
        dense: {
          paddingTop: 16,
          paddingBottom: 16,
        },
        gutters: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },

      MuiDialogActions: {
        root: {
          paddingRight: 24,
          paddingLeft: 24,
        },
      },

      MuiListItemIcon: {
        root: {
          minWidth: 'unset',
        },
      },
      MuiTableRow: {
        hover: {
          '&$hover:hover': {
            backgroundColor: '#F0F8FF',
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none', // css style to remove capitalization
          fontWeight: 500,
          fontSize: 14,
          lineHeight: fontSize[14][1],
          borderRadius: 4,
          boxShadow: 'none',
          minHeight: 36,
          padding: '8px 20px',
          transition: 'all .2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: 'none',
          },
        },
        text: {
          padding: '4px 8px',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none', // css style to remove capitalization
          fontWeight: 500,
        },
      },
      MuiDrawer: {
        paper: {
          borderLeft: '1px solid #EEEEEE',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#000',
          padding: '10px 20px',
          fontSize: 13,
          fontWeight: 400,
        },
      },
      MuiPopover: {
        paper: {
          boxShadow: boxShadow[20],
          borderRadius: 6,
        },
        root: {
          borderRadius: 6,
        },
      },
      MuiChip: {
        root: {
          fontSize: 12,
          lineHeight: 1.2,
          fontWeight: 600,
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: colorPalette.mid[50],
          },
        },
        outlined: {
          borderColor: colorPalette.mid[100],
        },
        deleteIcon: {
          width: 16,
          '&:hover': {
            color: colorPalette.error.main,
          },
        },
      },
    },
    transitions: {
      duration: {
        shortest: 75,
        shorter: 100,
        short: 125,
        standard: 150,
        complex: 200,
        enteringScreen: 110,
        leavingScreen: 95,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  }),
);

export default newTheme;
