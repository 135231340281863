import {
  MspMutation,
  ToggleMspArchivePayload,
} from '../../../../generated-types';
import { api } from '../graphql/msp-archival-toggle.generated';

export const extendedApi = api.enhanceEndpoints({
  endpoints: {
    toggleMspsArchivalStatus: {
      transformResponse: (response) => {
        const data = (response?.mspMutation as MspMutation)
          ?.toggleMspsArchivalStatus;
        if (data?.__typename === 'GraphqlError') {
          throw new Error(data.type);
        }
        return data as ToggleMspArchivePayload;
      },
    },
  },
});

export const { useToggleMspsArchivalStatusMutation } = extendedApi;
