/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AccountImg from '../../../assets/create-account-plan.svg';
import ErrorPages, { ErrorCodes } from '../../../common/methods/error-index';
import { InfiniteLoader, Typography } from '../../../components';
import {
  AccountPlan,
  AccountPlanPayload,
  GraphqlError,
} from '../../../generated-types';
import { setAccountDetails } from '../influencer-redux-store';
import { useGetAccountPlanByExtCompanyIdQuery } from './account-api';
import AccountErrorPage from './account-error-page';
import {
  CreateAccountPlanMutation,
  useCreateAccountPlanMutation,
} from './graphql/createAccountPlan.generated';
import { showToast } from '../../../components/hooks/use-toastify';

function CompanyContainer() {
  const { companyId } = useParams<{ companyId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, isLoading, isError, error } =
    useGetAccountPlanByExtCompanyIdQuery(
      { extId: companyId },
      {
        skip: !companyId,
      },
    );

  const [createAccountPlan, { isLoading: createAccountLoading }] =
    useCreateAccountPlanMutation();

  const rerouteFn = (accountId?: string) => {
    if (accountId) {
      const queryParams = new URLSearchParams(window.location.search);
      history.push({
        pathname: `/ui/alignx/ap/${accountId}/rmap`,
        search: queryParams.toString(),
      });
    }
  };

  const handleCreateAccount = async () => {
    try {
      const res = (await createAccountPlan({
        input: { extCompanyId: companyId },
      })) as { data: CreateAccountPlanMutation };

      if (
        res?.data?.accountPlanMutation?.createAccountPlan?.__typename ===
        'GraphqlError'
      ) {
        const errorMsg = (
          res?.data?.accountPlanMutation?.createAccountPlan as GraphqlError
        )?.message;
        showToast(errorMsg, {
          variant: 'error',
        });
        return;
      } else {
        const account = (
          res?.data?.accountPlanMutation
            ?.createAccountPlan as AccountPlanPayload
        )?.items?.[0] as AccountPlan;

        dispatch(setAccountDetails({ account }));

        rerouteFn(account?.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    rerouteFn((data as AccountPlan)?.id);
  }, [data]);

  let content;

  if (isLoading || createAccountLoading) {
    content = <InfiniteLoader width={160} />;
  } else if (isError) {
    const [type, code] = (error?.message || '')?.split(':');
    if (code === 'COMPANY_NOT_FOUND') {
      content = <AccountErrorPage />;
    } else if (code === 'ACCOUNT_PLAN_NOT_FOUND') {
      content = (
        <div className="flex flex-col items-center space-y-6">
          <img
            src={AccountImg as string}
            alt="create new relationship map"
            className="h-60"
          />
          <div className="flex flex-col items-center space-y-2">
            <Typography variant="heading5-semibold">Get Started</Typography>
            <Typography variant="body-13-medium" className="text-mid">
              Create new relationship map to understand more about your
            </Typography>
            <Typography variant="body-13-medium" className="text-mid">
              buyer and plan with your team
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateAccount}
          >
            Create Relationship Map
          </Button>
        </div>
      );
    } else if (type === 'FORBIDDEN_ERROR') {
      content = <ErrorPages errorCode={ErrorCodes.ACCESS_DENIED}></ErrorPages>;
    } else {
      content = (
        <Typography variant="body-13-regular" color="error">
          Something went wrong.
        </Typography>
      );
    }
  }

  return (
    <div className="grid h-screen w-full place-items-center">{content}</div>
  );
}

export default CompanyContainer;
