import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Typography } from '../typography';

interface ICommonProps {
  children: ReactNode;
  className?: string;
}

const Root: React.ForwardRefExoticComponent<
  Tabs.TabsProps & React.RefAttributes<HTMLDivElement>
> = Tabs.Root;

const Content: React.ForwardRefExoticComponent<
  Tabs.TabsContentProps & React.RefAttributes<HTMLDivElement>
> = Tabs.Content;

const Tab = ({
  tabName,
  selectedTab,
  key,
}: {
  tabName: string;
  selectedTab: string;
  key: string;
}) => (
  <Tabs.Trigger
    key={key}
    value={tabName}
    asChild
    className={clsx(
      'ba-transition-50 cursor-pointer',
      'border-0 border-b-2 border-solid px-2 py-1',
      tabName === selectedTab
        ? 'border-primary text-primary'
        : 'border-white text-mid-300',
    )}
  >
    <Typography variant="body-13-regular">{tabName}</Typography>
  </Tabs.Trigger>
);
const List = ({ children, className }: ICommonProps) => (
  <Tabs.List className={clsx('flex space-x-4', className)} loop>
    {children}
  </Tabs.List>
);

export const BaTabs = {
  Root,
  Tab,
  Content,
  List,
};
