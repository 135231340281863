import { useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SideDrawerHeader from './side-drawer-header';
import {
  ActivityTimelineEdge,
  AnalyticsMilestone,
  AnalyticsTask,
  MspMetrics,
} from '../../../generated-types';
import { useGetMspAnalyticsMetricsQuery } from '../../../features/analytics/api';
import { useGetMspAnalyticsHighlightsQuery } from './api';
import ActivityMilestoneView from './activity-milestone-view';
import UpcomingTaskView from './upcoming-task-view';
import NeedAttentionBlockView from './need-attention-block-view';
import DashboardMetricCards from './dashboard-metric-cards';
import {
  MspAnalyticsHighlights,
  ActivityTimelineConnection,
  useGetActivityTimelineQuery,
} from '../../../api';
import { RootState } from '../../../common/redux-store';
import RecentChangesView from './recent-changes-view';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { trackEvent } from '../../../analytics';

type InsightViewProps = {
  onClose: () => void;
  mspId?: string;
  mspName?: string;
};

export function DashboardInsightView(props: InsightViewProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { mspId = '', mspName, onClose } = props;
  const userId =
    useSelector((state: RootState) => state.userInfo.userDetails.userId) ?? '';

  const handleViewMspAnalytics = useCallback(() => {
    onClose();
    dispatch(
      trackEvent(TRACKING_CONSTANTS.VIEW_MSP_ANALYTICS, {
        from: 'dashboard-insights',
      }),
    );
    history.push(`/ui/alignx/msp/${mspId}/analytics`);
  }, [history, mspId, onClose]);
  const {
    data: metricsData,
    isLoading,
    isError,
  } = useGetMspAnalyticsMetricsQuery(
    { mspId },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: { activityMilestoneData, upcomingTaskData, insightData },
    isLoading: isDashboardInsightLoading,
    isError: isDashboardInsightError,
  } = useGetMspAnalyticsHighlightsQuery(
    { mspId },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => {
        const activityMilestoneData = (data as MspAnalyticsHighlights)
          ?.milestones?.activeMilestones;
        const upcomingTaskData = (data as MspAnalyticsHighlights)?.tasks
          ?.upcomingTasks;
        const insightData = (data as MspAnalyticsHighlights)?.insights;
        return {
          data: {
            activityMilestoneData,
            upcomingTaskData,
            insightData,
          },
          isLoading,
          isError,
        };
      },
    },
  );

  const {
    data: timelineData,
    isLoading: isActivityTimelineLoading,
    isError: isActivityTimelineError,
  } = useGetActivityTimelineQuery(
    {
      input: {
        userId,
        references: [
          {
            id: mspId,
          },
        ],
      },
      pageConfiguration: {
        limit: 3,
      },
    },
    { refetchOnMountOrArgChange: true },
  );

  const activityTimeLineList = (timelineData as ActivityTimelineConnection)
    ?.edges;

  return (
    <>
      <div className="my-1 box-border w-[650px] pb-8">
        <div className="sticky top-0 z-50 bg-white px-8 py-4">
          <SideDrawerHeader mspName={mspName!} onClose={onClose} />
        </div>
        <div className="relative">
          <div className="px-8">
            <DashboardMetricCards
              data={metricsData as MspMetrics}
              isLoading={isLoading}
              isError={isError}
            />
          </div>
          <ActivityMilestoneView
            activityMilestoneData={
              activityMilestoneData as [AnalyticsMilestone]
            }
            mspId={mspId}
            isLoading={isDashboardInsightLoading}
            isError={isDashboardInsightError}
          />

          <UpcomingTaskView
            data={upcomingTaskData as [AnalyticsTask]}
            mspId={mspId}
            isLoading={isDashboardInsightLoading}
            isError={isDashboardInsightError}
          />

          <RecentChangesView
            data={activityTimeLineList as [ActivityTimelineEdge]}
            isLoading={isActivityTimelineLoading}
            isError={isActivityTimelineError}
          />

          {/* <NeedAttentionBlockView
            data={insightData!}
            isLoading={isDashboardInsightLoading}
            isError={isDashboardInsightError}
          /> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="normal-case"
            onClick={handleViewMspAnalytics}
            sx={{
              textTransform: 'none',
              width: 200,
              margin: '0 auto',
              display: 'flex',
              marginY: 5,
            }}
          >
            <FormattedMessage
              id="Button.viewMspAnalytics"
              defaultMessage="View MSP Analytics"
            />
          </Button>
          <span />
        </div>
      </div>
    </>
  );
}
