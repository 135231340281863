import clsx from 'clsx';
import { forwardRef, KeyboardEvent, ReactNode, useRef } from 'react';
import { FiSearch as SearchIcon, FiXCircle } from 'react-icons/fi';
import { Typography } from '../typography';
import { useDebouncedSearch } from './use-debounced-search';

interface IInputProps {
  parentValue: string | undefined;
  onChange?: (text: string | undefined) => void;
  placeholder: string;
  type?: 'search';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  inputTextClassName?: string;
  hasBackground?: boolean;
  debounceTime?: number;
  isAutoFocused?: boolean;
  hideCloseIcon?: boolean;
  disabled?: boolean;
  inputType?: 'email' | 'search' | 'text' | 'password';
  required?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  error?: string;
  name?: string;
}

export const BaInput = forwardRef<HTMLInputElement, IInputProps>(function Input(
  props,
  ref,
) {
  const {
    parentValue,
    className = '',
    onChange,
    placeholder,
    hasBackground = false,
    startIcon,
    debounceTime = 0,
    type,
    isAutoFocused = false,
    inputTextClassName = '',
    hideCloseIcon,
    disabled,
    inputType = 'text',
    onBlur,
    onFocus,
    onKeyPress,
    error,
    name = '',
    endIcon,
    required,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const { text, handleChange, clearText } = useDebouncedSearch({
    parentValue,
    onChange,
    debounceTime,
    inputRef: ref as React.MutableRefObject<HTMLInputElement>,
  });

  return (
    <>
      <div
        className={clsx(
          'box-border flex min-w-[225px] flex-wrap items-center space-x-1.5 space-y-0.5 pl-2',
          hasBackground ? ' bg-light-bg' : 'bg-white',
          className || '',
          'rounded border border-solid focus-within:border-primary-300 focus:border',
          disabled ? ' bg-mid-50' : '',
          error ? 'border-error-500' : 'border-mid-30',
        )}
      >
        {type === 'search' && <SearchIcon className="text-13 text-mid-400" />}
        {startIcon}
        <div className="flex flex-grow items-center">
          <input
            value={text}
            type={inputType}
            ref={ref || inputRef}
            name={name}
            autoFocus={isAutoFocused}
            onChange={handleChange}
            placeholder={placeholder}
            className={clsx(
              inputTextClassName || 'text-12',
              'box-border h-auto min-w-100 flex-1 truncate py-2 text-neutral placeholder:font-extralight',
              'placeholder-mid-400 placeholder:text-12 ',
              'rounded border-0 focus:outline-none',
              hasBackground ? ' bg-light-bg' : 'bg-white',
              disabled ? ' bg-mid-50 text-gray-500' : '',
            )}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            required={required}
          />
          {endIcon}
          {!!text?.length && !hideCloseIcon && !disabled && (
            <FiXCircle
              onClick={clearText}
              className="cursor-pointer pr-2 text-16 text-mid-200 hover:text-warning"
            />
          )}
        </div>
      </div>
      {error && (
        <Typography
          variant="body-13-regular"
          element="div"
          className="mx-1 my-1 font-semibold text-error-300"
        >
          {error}
        </Typography>
      )}
    </>
  );
});
