import { useApolloClient } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import 'react-base-table/styles.css';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import DashboardLoaderSvg from '../../assets/dashboard-loader-icon.svg';
import { useSortOnTable } from '../../common/hooks/useSortOnTable';
import '../../common/react-base-table.css';
import { RootState } from '../../common/redux-store';
import { CircularIndeterminate } from '../../components';
import { Typography } from '../../components/typography';
import {
  MspPermissionPayload,
  MspUserBridge,
  Organization,
} from '../../generated-types';
import { mspPagination } from './api-call';
import { getCellRenderer, getHeaderRenderer } from './table-cells';
import { useGetTopOffsetWithClassName } from '../../common/hooks/useTopOffset';

const mapState = (state: RootState) => ({
  loading: state?.dashboard?.loading,
  filterLoading: state?.dashboard?.filterLoading,
  errorMessage: state?.dashboard?.error,
  isNextPageLoading: state?.dashboard?.isNextPageLoading,
  mspList: state?.dashboard?.mspList,
  afterCursor: state?.dashboard?.pageInfo?.afterCursor,
  hasNextPage: state?.dashboard?.pageInfo?.hasNextPage,
  mspPermissions: (
    (state?.msp?.mspDetail?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items,
  isSalesEnabled: (state?.organization as Organization)?.configs?.salesforce
    ?.isEnabled,
  dashboardColumns: state?.dashboard?.dashboardColumns?.map((column) => ({
    ...column,
    cellRenderer: getCellRenderer(column.dataKey),
    headerRenderer: getHeaderRenderer(column?.dataKey),
  })),
});

const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mspPagination,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IMspTableProps = PropsFromRedux;

export const OverlayLoading = ({
  isLoading,
  isEmpty,
  isNextPageLoading,
  errorMessage,
}: {
  isLoading: boolean;
  isEmpty: boolean;
  isNextPageLoading: boolean;
  errorMessage: unknown;
}) => {
  if (isNextPageLoading) {
    return (
      <div className="absolute bottom-0 w-screen">
        <div className="flex items-center justify-center ">
          <div className="m-8 flex w-60 items-center justify-center space-x-6 border border-solid border-mid-50 bg-white p-3 shadow-100">
            <Typography className="text-mid" variant="body-13-regular">
              Getting more MSPs
            </Typography>
            <CircularProgress size={30} />
          </div>
        </div>
        {/* <div className="h-1.5 w-screen animate-pulse bg-secondary-600" /> */}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center bg-mid-50 bg-opacity-50 pt-36">
        <div className="m-5">
          <img alt="dashboard-loader" src={DashboardLoaderSvg as string} />
        </div>
        <div className="text-center text-slate-500">
          <Typography variant="heading3">
            <FormattedMessage
              id="Dashboard.loadingMessageTitle"
              defaultMessage="Data And Coffee,"
            />
          </Typography>
          <Typography variant="heading5">
            <FormattedMessage
              id="Dashboard.loadingSupportMessage"
              defaultMessage="both taste best when they're brewed fresh!"
            />
          </Typography>
        </div>
        <CircularIndeterminate padding={3} size={36} />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">
          Something went wrong.
        </Typography>
      </div>
    );
  }

  if (!isLoading && isEmpty) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">
          <FormattedMessage
            id="Dashboard.blankMsgMspTitle"
            defaultMessage="No matching MSPs found"
          />
        </Typography>
        <Typography className="text-mid-300" variant="body-14-regular">
          <FormattedMessage
            id="Dashboard.blankMsgMspDescription"
            defaultMessage="Modify the search criteria. We will look through the MSPs again."
          />
        </Typography>
      </div>
    );
  }

  return null;
};

function MspTable(props: IMspTableProps) {
  const {
    loading,
    filterLoading,
    isNextPageLoading,
    mspList,
    dashboardColumns,
    afterCursor,
    mspPagination,
    hasNextPage,
    errorMessage,
  } = props;

  const client = useApolloClient();
  const dispatch = useDispatch();
  const { sortBy, data, isEmpty, onColumnSort } = useSortOnTable(mspList);

  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      dispatch(trackEvent(TRACKING_CONSTANTS.LOADING_MORE_MSP));
      mspPagination({ client, afterCursor });
    }
  }, [afterCursor, client, hasNextPage, mspPagination]);

  const topOffset = useGetTopOffsetWithClassName(
    'ba-msp-dashboard-table',
    loading,
  );

  return (
    <div
      className="ba-msp-dashboard-table w-full"
      style={{ height: window.innerHeight - topOffset }}
    >
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width - 2}
            height={height}
            loading={loading || filterLoading}
            data={data}
            overlayRenderer={
              <OverlayLoading
                isNextPageLoading={isNextPageLoading}
                isLoading={loading || filterLoading}
                isEmpty={isEmpty}
                errorMessage={errorMessage}
              />
            }
            rowKey="tableKey"
            sortBy={sortBy}
            onColumnSort={onColumnSort}
            onEndReachedThreshold={100}
            onEndReached={handleEndReached}
            rowClassName="group"
          >
            {dashboardColumns.map(({ dataKey, ...restProps }) => (
              // eslint-disable-next-line react/jsx-key
              <Column dataKey={dataKey} {...restProps} />
            ))}
          </BaseTable>
        )}
      </AutoResizer>
    </div>
  );
}

export default connector(MspTable);
