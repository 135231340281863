import Keycloak, { KeycloakInstance } from 'keycloak-js';
import {
  getBaseUrl,
  getLoginUrl,
  getRedirectUrl,
  orgIdExtractor,
} from '../../../utils';
import {
  AuthLogoutHandler,
  AuthRefreshErrorHandler,
  AuthSuccessHandler,
  TokenExpiredHandler,
} from '../authentication-service';

export const getKeycloakInstance = () => {
  const orgIdExtracted = orgIdExtractor();

  const url = getLoginUrl();

  const keycloakConfig = {
    url,
    realm: orgIdExtracted,
    clientId: 'Authentication',
  };

  const keycloak = Keycloak(keycloakConfig);

  const redirectUri = getRedirectUrl();

  keycloak.onAuthSuccess = AuthSuccessHandler(keycloak);

  keycloak.onTokenExpired = TokenExpiredHandler(keycloak);

  keycloak.onAuthRefreshError = AuthRefreshErrorHandler(keycloak, {
    redirectUri,
  });

  keycloak.onAuthLogout = AuthLogoutHandler;

  return keycloak;
};

export const initKeycloak = (
  keycloak: KeycloakInstance,
  onInitializedCallback: CallableFunction,
) => {
  let baseUrl = getBaseUrl();
  if (process.env.STAGE === 'local') {
    baseUrl = `https://${orgIdExtractor()}.local.buyerassist.io`;
  }

  if (process.env.STAGE === 'local' && process.env.LOCAL_TO_REMOTE === 'mock') {
    onInitializedCallback();
    return;
  }
  try {
    keycloak
      .init({
        onLoad: 'check-sso',
        // checkLoginIframe: true,
        silentCheckSsoRedirectUri: `${baseUrl}/public/silent-check-sso.html`,
        enableLogging: process.env.STAGE !== 'prod',
      })
      .then(() => {
        onInitializedCallback();
      })
      .catch((error: string) => {
        throw new Error(error);
      });
  } catch (error: unknown) {
    throw new Error(
      `Error occurred during keycloak initialization${error as string}`,
    );
  }
};
