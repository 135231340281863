/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated-types/index';

import { api } from 'api/base-api';
module.hot?.accept();
export type GetUserInfoQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  __typename?: 'Query';
  userQuery?: {
    __typename?: 'UserQuery';
    me:
      | { __typename?: 'GraphqlError' }
      | {
          __typename?: 'User';
          id?: string;
          primaryId?: string;
          firstName?: string;
          lastName?: string;
          title?: string;
          userType: Types.UserType;
          email?: string;
          company?:
            | {
                __typename?: 'Company';
                id: string;
                orgId: string;
                companyName: string;
                displayName: string;
              }
            | { __typename?: 'GraphqlError' };
        };
  };
};

export const GetUserInfoDocument = `
    query getUserInfo {
  userQuery {
    me {
      ... on User {
        id
        primaryId
        firstName
        lastName
        title
        userType
        email
        company {
          ... on Company {
            id
            orgId
            companyName
            displayName
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    getUserInfo: build.query<
      GetUserInfoQuery,
      GetUserInfoQueryVariables | void
    >({
      query: (variables) => ({ document: GetUserInfoDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserInfoQuery, useLazyGetUserInfoQuery } = injectedRtkApi;
