import { ApolloClient, useApolloClient } from '@apollo/client';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { useDebounce } from '../../common/methods/debounce';
import { RootState } from '../../common/redux-store';
import { IFilterConfig, IFilterDetails } from '../../components/filters/model';
import { IFilterQuery, fetchMspFilters, fetchMspList } from './api-call';
import { useGetMspArchiveReasonListQuery } from './api/archivalReason';
import { resetMspList, setArchivalReasonList } from './dashboard-redux-store';
import DashboardHeader from './dashbord-header';
import { ArchivalReasonListType } from './models';
import MspTable from './msp-table';

interface IMspFileterDebounceArgs {
  apolloClient: ApolloClient<object>;
  searchText?: string;
  currentSelectedFilters?: IFilterDetails;
  currentFilterDetails?: IFilterConfig;
}

const mapState = (state: RootState) => ({
  mspList: state?.dashboard?.mspList,
  filterDetails: state?.dashboard?.filterDetails,
  searchTerm: state?.dashboard?.search,
  selectedFilters: state?.filters?.selectedFilters?.dashboard,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchMspList,
      fetchMspFilters,
      resetMspList,
      setArchivalReasonList,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
let firstLoad = false;

function MspDashboardContainer(props: PropsFromRedux) {
  const {
    fetchMspList,
    mspList,
    fetchMspFilters,
    filterDetails,
    selectedFilters,
    searchTerm,
    resetMspList,
    setArchivalReasonList,
  } = props;
  const client = useApolloClient();
  const dispatch = useDispatch();

  const { data: archivalReasonListData = [] } =
    useGetMspArchiveReasonListQuery();

  useEffect(() => {
    const archivalOtherReason = {
      id: 'OTHER',
      name: 'OTHER',
      displayName: 'Other',
    };
    const tempArchivalReasonData = [
      ...archivalReasonListData,
    ] as ArchivalReasonListType[];
    if (tempArchivalReasonData?.length > 0) {
      tempArchivalReasonData.push(archivalOtherReason);
      setArchivalReasonList(tempArchivalReasonData);
    }
  }, [archivalReasonListData]);

  useEffect(() => {
    if (mspList?.length === 0) {
      fetchMspList({ client });
    }
    if (Object.keys(filterDetails)?.length === 0) {
      fetchMspFilters({ client });
    }
    return () => {
      firstLoad = false;
    };
  }, []);

  const debouncedGetFilteredMspList = useDebounce(
    ({
      apolloClient,
      searchText,
      currentSelectedFilters,
      currentFilterDetails,
    }: IMspFileterDebounceArgs) => {
      const filterKeys = currentSelectedFilters
        ? Object.keys(currentSelectedFilters)
        : null;
      let filters = [] as IFilterQuery[];
      if (currentSelectedFilters && filterKeys) {
        filters = filterKeys.reduce((acc, key) => {
          const arr = currentSelectedFilters[key];
          const fieldName =
            currentFilterDetails && currentFilterDetails[key]?.key;
          const values = arr
            .filter(({ isSelected }) => isSelected)
            .map(({ value }) => value);

          if (values.length) {
            acc.push({
              values,
              field: fieldName,
            });
          }
          return acc;
        }, [] as IFilterQuery[]);
      }
      dispatch(
        trackEvent(TRACKING_CONSTANTS.ACCESSED_FILTERS, {
          filters,
        }),
      );
      fetchMspList({
        client: apolloClient,
        selectedFilters: currentSelectedFilters,
        filterDetails: currentFilterDetails,
        searchTerm: searchText,
      });
    },
    2000,
  );

  useEffect(() => {
    if (firstLoad) {
      resetMspList();
      debouncedGetFilteredMspList({
        apolloClient: client,
        currentSelectedFilters: selectedFilters,
        currentFilterDetails: filterDetails,
        searchText: searchTerm?.trim(),
      });
    }
    firstLoad = true;
  }, [selectedFilters, searchTerm]);

  return (
    <>
      <DashboardHeader />
      <MspTable />
    </>
  );
}

export default connector(MspDashboardContainer);
