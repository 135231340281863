import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { Divider } from '../../../components';
import { AnalyticsTask } from '../../../generated-types';
import SubSectionHeader from './sub-section-header';
import UpcomingTaskListItem from './upcoming-task-list-item';

interface UpcomingTaskViewProps {
  data: [AnalyticsTask];
  isLoading: boolean;
  isError: boolean;
  mspId: string;
}

function UpcomingTaskView(props: UpcomingTaskViewProps) {
  const { data, isLoading, isError, mspId } = props;

  if (isLoading) {
    return (
      <div>
        <SubSectionHeader content="Next Steps" />
        <Divider />
        <div className="grid grid-cols-12 px-8 pt-2 pb-2">
          <div className="col-span-7 flex justify-start space-x-1">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton animation="wave" variant="text" width={50} />
          </div>
          <div className="col-span-3 col-start-8">
            <Skeleton animation="wave" variant="text" width={70} />
          </div>
          <div className="col-span-2 col-start-11 flex justify-end ">
            <Skeleton animation="wave" variant="text" width={70} />
          </div>
        </div>
      </div>
    );
  }
  if (data) {
    return (
      <div>
        <SubSectionHeader content="Next Steps" />
        <Divider />

        {data?.length > 0 ? (
          data?.map((data) => (
            <div key={data?.task?.id}>
              <UpcomingTaskListItem
                taskId={data?.task?.id}
                taskStatus={data?.task?.status}
                itemDetail={data?.task?.name}
                userDetail={data?.assignee}
                dueDate={dayjs(data?.task?.dueDate * 1000).format('MMM DD')}
                mspId={mspId}
              />
            </div>
          ))
        ) : (
          <div className="px-8">
            No Next Steps to address within the next week
          </div>
        )}
      </div>
    );
  }

  return null;
}

export default UpcomingTaskView;
