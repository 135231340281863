import { TOption } from '../../../../components/ba-select/common';
import {
  Opportunity,
  OpportunityConnection,
} from '../../../../generated-types';
import {
  api as opportunityApi,
  GetOwnerAndCoOwnerOpportunitiesQuery,
} from '../gql/get-owner-opportunities.generated';

export const extendedOpportunityApi = opportunityApi.enhanceEndpoints({
  endpoints: {
    GetOwnerAndCoOwnerOpportunities: {
      keepUnusedDataFor: 1800,
      transformResponse: (response: GetOwnerAndCoOwnerOpportunitiesQuery) => {
        if (
          response?.opportunityQuery?.getOpportunityForOwnerAndCoOwner
            ?.__typename === 'GraphqlError'
        ) {
          throw new Error('Service error');
        }

        const oppList: TOption[] | Opportunity[] =
          (
            response?.opportunityQuery
              ?.getOpportunityForOwnerAndCoOwner as OpportunityConnection
          )?.edges?.map((edge) => {
            const opportunity = edge?.node as Opportunity;
            if (opportunity) {
              return {
                id: opportunity?.id,
                name: opportunity?.name,
                accountId: opportunity?.accountId,
                isOptionDisabled: !opportunity?.accountId,
              };
            }

            return {} as Opportunity;
          }) || [];

        return oppList;
      },
    },
  },
});

export const { useGetOwnerAndCoOwnerOpportunitiesQuery } =
  extendedOpportunityApi;
