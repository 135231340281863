import { Link } from 'react-router-dom';
import { CircularIndeterminate, Typography } from '../../../../components';
import { TMsp } from '../../models';
import {
  NameHeaderRenderer,
  TableCell,
  headerRenderer,
  normalDateRenderer,
} from '../../table-cells';
import { IRMapMsp } from '../rmap-dashboard-api';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../analytics';
import DashboardLoaderSvg from '../../../../assets/dashboard-loader-icon.svg';
import { TRACKING_CONSTANTS } from '../../../../analytics/constants/trackingConstants';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

export const OpportunityNameCell = ({
  cellData,
  rowData,
}: {
  cellData?: NonNullable<React.ReactNode>;
  rowData?: IRMapMsp;
}) => {
  // const logo = rowData?.buyerLogo;
  const dispatch = useDispatch();

  try {
    if (rowData?.id?.includes('error'))
      return (
        <Typography
          variant="body-13-semibold"
          className="primary-highlight w-full truncate text-neutral"
        >
          {cellData}
        </Typography>
      );
    return (
      <>
        <div
          className="flex w-full items-center justify-between"
          key={rowData?.id}
        >
          <div className="flex cursor-pointer flex-row items-center truncate">
            <Link
              to={{
                pathname: `/ui/alignx/msp/${rowData?.id ?? ''}/rmap`,
                search: new URLSearchParams(window.location.search).toString(),
              }}
              className="mr-1 truncate pl-4 no-underline"
            >
              <div className="flex items-center space-x-3 ">
                {/* {logo ? (
                    <img
                      src={logo}
                      alt=""
                      className="h-6 w-6 rounded border border-solid border-mid-100 bg-white object-cover object-left p-[3px]"
                    />
                  ) : (
                    <HiOutlineOfficeBuilding className="rounded border border-solid border-mid-100 p-[3px] text-16 text-mid" />
                  )} */}

                <div
                  className="truncate"
                  onClick={() => {
                    dispatch(
                      trackEvent(TRACKING_CONSTANTS.ACCESSED_MSP, {
                        from: 'Dashboard',
                        mspId: rowData?.id,
                      }),
                    );
                  }}
                >
                  <Typography
                    variant="body-13-semibold"
                    className="primary-highlight truncate capitalize text-neutral"
                  >
                    {cellData}
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  } catch {
    return null;
  }
};

export const opportunityTableCols = [
  {
    dataKey: 'opportunityName',
    title: 'Opportunity Name',
    width: '50%',
    // width: 230,
    sortable: false,
    cellRenderer: ({
      cellData,
      rowData,
    }: {
      cellData: NonNullable<React.ReactNode>;
      rowData: TMsp;
    }) => <OpportunityNameCell cellData={cellData} rowData={rowData} />,
    headerRenderer: NameHeaderRenderer,
    flexGrow: 1,
  },
  {
    dataKey: 'stageName',
    title: 'Opportunity Stage',
    width: 100,
    headerRenderer,
    cellRenderer: TableCell,
    flexGrow: 1,
  },
  {
    dataKey: 'OpportunityCloseDate',
    title: 'Opportunity Close Date',
    width: 100,
    sortable: false,
    flexGrow: 1,
    cellRenderer: normalDateRenderer,
    headerRenderer,
  },
];

export const RmapOverlayLoading = ({
  isLoading,
  isEmpty,
  isNextPageLoading,
  isError,
}: {
  isLoading: boolean;
  isEmpty: boolean;
  isNextPageLoading: boolean;
  isError: unknown;
}) => {
  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center bg-mid-50 bg-opacity-50 pt-36">
        <div className="m-5">
          <img alt="dashboard-loader" src={DashboardLoaderSvg as string} />
        </div>
        <div className="text-center text-slate-500">
          <Typography variant="heading3">
            <FormattedMessage
              id="Dashboard.loadingMessageTitle"
              defaultMessage="Data And Coffee,"
            />
          </Typography>
          <Typography variant="heading5">
            <FormattedMessage
              id="Dashboard.loadingSupportMessage"
              defaultMessage="both taste best when they're brewed fresh!"
            />
          </Typography>
        </div>
        <CircularIndeterminate padding={3} size={36} />
      </div>
    );
  }

  if (isNextPageLoading) {
    return (
      <div className="absolute bottom-0 w-screen">
        <div className="flex items-center justify-center ">
          <div className="m-8 flex w-64 items-center justify-center space-x-6 border border-solid border-mid-50 bg-white p-3 shadow-100">
            <Typography className="text-mid" variant="body-13-regular">
              Getting more opportunities...
            </Typography>
            <CircularProgress size={30} />
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">
          Something went wrong.
        </Typography>
      </div>
    );
  }

  if (!isLoading && isEmpty) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">
          No matching Opportunities found
        </Typography>
        <Typography className="text-mid-300" variant="body-14-regular">
          Modify the search criteria. We will look through the Opportunities
          again.
        </Typography>
      </div>
    );
  }

  return null;
};
