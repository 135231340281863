/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateMediaInput,
  CreateTaskInput,
  Milestone,
  MilestoneDetailsInput,
} from '../../../generated-types';
import { MilestoneDateType } from './api/milestone-common-enum';
import { ICreateMilestone } from './model';

const initialState = {} as ICreateMilestone;

const milestoneCreateSlice = createSlice({
  name: 'milestonecreate',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.milestoneNameError = '';
    },
    setSelectedTemplate: (state, action: PayloadAction<Milestone>) => {
      state.selectedTemplate = action.payload;
    },
    setMilestoneWithoutTemplate: (state, action: PayloadAction<boolean>) => {
      state.isWithoutTemplate = action.payload;
    },
    setDate: (state, action: PayloadAction<MilestoneDateType>) => {
      state.date = action.payload;
      state.milestoneDateError = '';
    },
    setInternal: (state, action: PayloadAction<boolean>) => {
      state.isInternal = action.payload;
    },
    setTask: (state, action: PayloadAction<CreateTaskInput[]>) => {
      state.tasks = action.payload;
    },
    setMilestoneCreateDetails: (
      state,
      action: PayloadAction<MilestoneDetailsInput>,
    ) => {
      state.milestoneDetails = action.payload;
    },
    setMedia: (state, action: PayloadAction<CreateMediaInput[]>) => {
      state.media = action.payload;
    },
    validateForm: (state) => {
      if (!state.name) {
        state.milestoneNameError = 'Please enter a Milestone Name';
        state.isFromValid = false;
      }
      if (
        !state?.date?.startDate ||
        !state?.date?.endDate ||
        state?.date?.endDate === 'Invalid Date'
      ) {
        state.milestoneDateError = 'Please select the Milestone Duration';
        state.isFromValid = false;
      }
      if (
        state.name &&
        state?.date?.startDate &&
        state?.date?.endDate &&
        state?.date?.endDate !== 'Invalid Date'
      ) {
        state.isFromValid = true;
      }
    },
  },
});

export const {
  setName,
  setSelectedTemplate,
  setMilestoneWithoutTemplate,
  setDate,
  setInternal,
  setTask,
  setMilestoneCreateDetails,
  setMedia,
  validateForm,
} = milestoneCreateSlice.actions;

export default milestoneCreateSlice.reducer;
