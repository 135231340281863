import * as Card from '@radix-ui/react-hover-card';
import clsx from 'clsx';
import { ReactNode } from 'react';

const Root = Card.Root;

const Trigger = Card.Trigger;

const Arrow = ({
  fillColorClass = 'fill-white',
}: {
  fillColorClass?: 'fill-white' | 'fill-black';
}) => <Card.Arrow className={clsx('shadow-100', fillColorClass)} />;

const Content = ({
  children,
  className,
  bgColorClass = 'bg-white',
  ...props
}: {
  children: ReactNode;
  className?: string;
  bgColorClass?: string;
  paddingclass?: string;
} & Card.HoverCardContentProps) => (
  <Card.Portal>
    <Card.Content
      {...props}
      className={clsx('rounded-md shadow-80', className, bgColorClass)}
    >
      {children}
    </Card.Content>
  </Card.Portal>
);

export const BaCard = { Root, Trigger, Arrow, Content };
