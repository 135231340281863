import Button from '@mui/material/Button';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../analytics';
import { TRACKING_CONSTANTS } from '../../../../analytics/constants/trackingConstants';
import { RootState } from '../../../../common/redux-store';
import {
  BaDialog,
  BaSelect,
  InfiniteLoader,
  Typography,
} from '../../../../components';
import { TOption } from '../../../../components/ba-select/common';
import { showToast } from '../../../../components/hooks/use-toastify';
import {
  Msp,
  MspPayload,
  MspResponse,
  Opportunity,
  Organization,
  SortOrder,
  useCreateMspMutation,
} from '../../../../generated-types';
import { ITabDetail } from '../../../msp-view/model';
import { useGetOwnerAndCoOwnerOpportunitiesQuery } from './opportunity-api';

const mapState = (state: RootState) => {
  const tabList = (state?.organization as Organization)?.configs?.others
    ?.dashboardTabs as ITabDetail[];
  const rmapTab = tabList?.find((tab) => tab.name === 'rMap');
  const title = rmapTab?.title || 'Relationship Map';

  return { rmapTitle: title };
};

const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const opportunityVariables = {
  input: {
    operations: {
      sort: [
        {
          order: SortOrder.ASC,
          field: 'opportunity.forecastCategory',
        },
      ],
    },
  },
};

function CreateNewRmapWithOpportunity(props: PropsFromRedux) {
  const { rmapTitle } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<TOption | null>(null);
  const history = useHistory();
  const {
    data: opportunityList = [] as TOption[],
    isLoading,
    isFetching,
    isError,
    //   error: searchOpportunityError,
  } = useGetOwnerAndCoOwnerOpportunitiesQuery(opportunityVariables);

  console.log(selectedOpportunity);
  const isOpportunityLoading = isFetching || isLoading;

  const setOpportunity = useCallback((opportunity: TOption | null) => {
    setSelectedOpportunity(opportunity);
  }, []);

  const navigateToMSPDetails = useCallback(
    (mspId: string) => {
      const queryParams = new URLSearchParams(location.search);
      const redirectPath = queryParams.get('redirectPath') || '';

      queryParams?.delete('redirectPath');
      history.push({
        pathname: redirectPath
          ? `/ui/alignx/msp/${mspId}/${redirectPath}`
          : `/ui/alignx/msp/${mspId}/rmap?sidenav=false&header=false`,
        search: queryParams.toString(),
      });
    },
    [history],
  );

  const onSuccess = useCallback((mspId: string) => {
    showToast(`${rmapTitle} created successfully.`, {
      variant: 'success',
    });
    trackEvent(TRACKING_CONSTANTS.CREATED_MSP_FROM_SCRATCH, {
      mspId,
    });
    navigateToMSPDetails(mspId);
  }, []);

  const onError = useCallback(() => {
    trackEvent(TRACKING_CONSTANTS.CREATE_MSP_FAILED);
    showToast(
      <FormattedMessage
        id="Snackbar.somethingWentWrong"
        defaultMessage="Something went wrong, please try again"
      />,
      {
        variant: 'error',
      },
    );
  }, []);

  const [createMspRmap, { loading: createMspLoading }] = useCreateMspMutation();

  const onCreateRMapMsp = () => {
    const opportunity = (opportunityList as Opportunity[])?.find(
      (opportunity) => opportunity.id === selectedOpportunity?.id,
    );
    const input = {
      name: `${selectedOpportunity?.name ?? ''} Relationship Map`,
      opportunityId: selectedOpportunity?.id ?? '',
      closeDate: dayjs().unix(), // TODO:  rm later
      startDate: dayjs().unix(),
      accountId: opportunity?.accountId,
    };

    createMspRmap({
      variables: {
        input,
      },
    })
      .then((resp) => {
        if (
          resp?.data?.mspMutation?.createMsp.__typename === 'GraphqlError' ||
          (
            (resp?.data?.mspMutation?.createMsp as MspPayload)
              .items as MspResponse[]
          )[0].__typename === 'GraphqlError'
        ) {
          throw new Error('Something went wrong');
        } else {
          const mspId = (
            (resp.data?.mspMutation?.createMsp as MspPayload).items as Msp[]
          )[0]?.id;

          trackEvent(TRACKING_CONSTANTS.CREATED_MSP_FROM_SCRATCH, {
            mspId,
          });
          onSuccess(mspId);
        }
      })
      .catch(() => {
        onError();
      });
  };

  let content;

  if (createMspLoading) {
    content = (
      <div className="grid h-[80vh] w-full place-items-center">
        <div className="flex flex-col items-center justify-center gap-2">
          <Typography variant="heading2" className="text-neutral">
            {`Creating new ${rmapTitle}`}
          </Typography>
          <InfiniteLoader width={150}></InfiniteLoader>
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className="mb-5 flex items-center justify-between">
          <Typography variant="heading2" className="text-neutral">
            {`Create new ${rmapTitle}`}
          </Typography>
          <BaDialog.Close asChild>
            <div>
              <FiXCircle className="h-4 w-4 cursor-pointer rounded-full p-1 text-mid-600 hover:bg-gray-100" />
            </div>
          </BaDialog.Close>
        </div>
        <Typography
          element="div"
          variant="body-13-regular"
          className="py-2 px-1 text-neutral-400"
        >
          Select Opportunity
        </Typography>
        <BaSelect
          placeholder="Select Opportunity"
          optionList={opportunityList as TOption[]}
          setSelected={setOpportunity}
          selectedItem={selectedOpportunity}
          isLoading={isOpportunityLoading}
          // onRefetch={onFetchOpportunity}
          // disabled={isLoading}
          // isNetworkError={opportunityError === NETWORK_ERROR}
          error={isError ? 'Something went wrong.' : ''}
          component="Opportunity"
          disabledOptionMessage="Account not linked"
          noOptionsMessage="No matching opportunity found"
        />
        <div className="ml-1 mt-10 flex space-x-3">
          <Button
            disabled={!selectedOpportunity?.id}
            variant="contained"
            color="primary"
            onClick={onCreateRMapMsp}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            className="text-mid"
            color="inherit"
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  return (
    <BaDialog.Root
      open={isDrawerOpen}
      //   open
      onOpenChange={setIsDrawerOpen}
    >
      <BaDialog.Trigger asChild>
        <div>
          <Button variant="contained" color="primary">
            Create New
          </Button>
        </div>
      </BaDialog.Trigger>
      <BaDialog.Portal>
        <BaDialog.Overlay />
        <BaDialog.RightDrawer className="z-20 h-screen w-[560px] p-11">
          {content}
        </BaDialog.RightDrawer>
      </BaDialog.Portal>
    </BaDialog.Root>
  );
}

export default connector(CreateNewRmapWithOpportunity);
