/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IUserInfo } from '../../common/redux-store/reducers/models';
import { setUserDetails } from '../../common/redux-store/reducers/user-details';
import { Company, User } from '../../generated-types';
import { GetUserInfoQuery, api as userAPi } from './gql/me.generated';

export const userExtendedApi = userAPi.enhanceEndpoints({
  endpoints: {
    getUserInfo: {
      keepUnusedDataFor: 3600,
      transformResponse: (data: GetUserInfoQuery) => {
        if (data?.userQuery?.me?.__typename === 'GraphqlError') {
          throw new Error('Something went wrong while getting opportunities.');
        }

        const user = data?.userQuery?.me as User;

        if (user) {
          const {
            company = '',
            email = '',
            firstName = '',
            lastName = '',
            id: userId,
            ...rest
          } = user;
          const { companyName = '', id = '' } = (company || {}) as Company;

          const name =
            firstName || lastName
              ? `${firstName} ${lastName}`
              : email
              ? email.split('@')[0].replace(/\W/gi, ' ')
              : 'Anonymous';

          return {
            ...rest,
            userId,
            id: userId,
            email,
            name,
            firstName,
            lastName,
            company: companyName,
            companyId: id,
          };
        }

        return {} as IUserInfo;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUserDetails(data as IUserInfo));
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useGetUserInfoQuery } = userExtendedApi;
