import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TaskStatus } from '../../generated-types';
import { Typography } from '../typography';
import MarkTaskProgress from '../../assets/micro-task-progress.svg';
import MarkTaskPending from '../../assets/micro-task-pending.svg';
import MarkTaskCompleted from '../../assets/micro-task-completed.svg';
import useUpdateTaskStatus from './api-hook';

export enum COMPONENTYPE {
  MYTASK = 'MYTASK',
  PLANVIEW = 'PLANVIEW',
  ALLTASK = 'ALLTASK',
}
interface TaskStatusMenuProps{
  onTaskStatusChange:(requestedStatus:TaskStatus) => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  componentType:COMPONENTYPE;
  selectedTaskStatus?: TaskStatus
}

function TaskStatusMenu(props:TaskStatusMenuProps) {
  const {
    onTaskStatusChange, setAnchorEl, componentType, selectedTaskStatus,
  } = props;
  const { clearMouseLeaveEvent } = useUpdateTaskStatus();
  const handleTaskStatusClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event?.currentTarget?.id !== selectedTaskStatus) { onTaskStatusChange(event?.currentTarget?.id as TaskStatus); }
  };

  const taskMenuList = [
    {
      containerId: TaskStatus.PENDING, imgAlt: 'task-pending', imgSrc: MarkTaskPending, msgId: 'MspPlanView.taskStatusPending', defaultMessage: 'Not Started',
    },
    {
      containerId: TaskStatus.INPROGRESS, imgAlt: 'task-progress', imgSrc: MarkTaskProgress, msgId: 'MspPlanView.taskStatusProgress"', defaultMessage: 'In Progress',
    },
    {
      containerId: TaskStatus.COMPLETED, imgAlt: 'task-completed', imgSrc: MarkTaskCompleted, msgId: 'MspPlanView.taskStatusCompleted', defaultMessage: 'Completed',
    },
  ];
  return (

    <div onMouseEnter={() => clearMouseLeaveEvent()} onMouseLeave={() => setAnchorEl(null)} className="flex flex-col justify-center cursor-pointer rounded pb-1">
      {taskMenuList.map(({
        containerId, imgAlt, imgSrc, msgId, defaultMessage,
      }) => (
        <div
          role="presentation"
          id={containerId}
          key={containerId + imgSrc}
          onClick={handleTaskStatusClick}
          data-test={`task-${containerId}`}
          className="flex items-center px-5 py-3 space-x-3 hover:text-primary hover:bg-primary-100 hover:font-semibold"
        >
          <img alt={imgAlt} src={imgSrc} />
          <Typography className="pr-4" variant="body-13-regular">
            <FormattedMessage
              id={msgId}
              defaultMessage={defaultMessage}
            />
          </Typography>
        </div>
      ))}

    </div>
  );
}
export default TaskStatusMenu;
