/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { RootState } from '../../../common/redux-store/redux-types';
import {
  AccountPlan,
  AccountPlanPayload,
  Company,
  GraphNode,
  GraphqlError,
  IRelationshipBaseItem,
  LeftSideRelationshipItems,
  RelationshipItemConnection,
  RelationshipItemEdge,
  RelationshipScopeInput,
  RelationshipScopeType,
} from '../../../generated-types';
import {
  setAccountDetails,
  setListCompanyNameVisibility,
} from '../influencer-redux-store';
import { IContactsResponse } from '../models';
import { getUserIdsFromNodes, transformContactList } from '../utils-influencer';
import {
  api as accountContactsApi,
  GetAccountContactsQuery,
} from './graphql/getAccountContacts.generated';
import {
  api as accountApi,
  GetAccountPlanByExtCompanyIdQuery,
} from './graphql/getAccountPlanByExtCompanyId.generated';
import {
  api as companyApi,
  GetCompanyDetailsQuery,
} from './graphql/getCompanyDetails.generated';

export const extendedApi = accountApi.enhanceEndpoints({
  endpoints: {
    getAccountPlanByExtCompanyId: {
      transformResponse: (response: GetAccountPlanByExtCompanyIdQuery) => {
        //   const list = transformAllTasksResponse(response);
        if (
          response?.accountPlanQuery?.getAccountPlanByExtCompanyId
            ?.__typename === 'GraphqlError'
        ) {
          const { code, type } = response?.accountPlanQuery
            ?.getAccountPlanByExtCompanyId as GraphqlError;
          throw new Error(`${type}:${code}`);
        }
        const account = (
          response?.accountPlanQuery
            ?.getAccountPlanByExtCompanyId as AccountPlanPayload
        )?.items?.[0];

        return account;
      },
      // transformErrorResponse:
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setAccountDetails({ account: result?.data as AccountPlan }));
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useGetAccountPlanByExtCompanyIdQuery } = extendedApi;

export const extendedAccountContactApi = accountContactsApi.enhanceEndpoints({
  endpoints: {
    getAccountContacts: {
      transformResponse: (response: GetAccountContactsQuery, _, args) => {
        const scopeType = (
          args?.otherContactInput?.scope as RelationshipScopeInput
        )?.type;
        const hasOnlyOtherContacts = scopeType !== RelationshipScopeType.MSP;

        if (
          response?.relationshipQuery?.leftSideRelationshipItemsList
            ?.__typename === 'GraphqlError'
        ) {
          throw new Error('Service error');
        }
        const {
          otherContacts: otherContactsResponse,
          defaultContacts: defaultContactsResponse,
        } =
          (response?.relationshipQuery
            ?.leftSideRelationshipItemsList as LeftSideRelationshipItems) || {};

        const { edges: otherContacts } =
          otherContactsResponse as RelationshipItemConnection;
        const { edges: defaultContacts } =
          defaultContactsResponse as RelationshipItemConnection;

        const defaultContactList =
          defaultContacts && defaultContacts.length > 0
            ? transformContactList(defaultContacts)
            : [];

        // FIXME: remove below code after a fix from backend
        const defaultContactIds = defaultContactList?.map(({ id }) => id);
        const otherContactListDistinct =
          otherContacts?.length && otherContacts?.length > 0
            ? otherContacts?.filter(({ node }) => {
                const { id } = node as IRelationshipBaseItem;
                return !defaultContactIds.includes(id);
              })
            : [];
        const otherContactList = hasOnlyOtherContacts
          ? transformContactList(otherContacts as RelationshipItemEdge[])
          : transformContactList(otherContactListDistinct);

        return {
          defaultContactList,
          otherContactList,
          hasOnlyOtherContacts,
        } as IContactsResponse;
      },
      async onQueryStarted(
        args,
        { dispatch, getState, queryFulfilled, updateCachedData },
      ) {
        try {
          await queryFulfilled;

          const nodes = (getState() as RootState)?.influencerMapNew?.nodes;

          const nodeUserIds = getUserIdsFromNodes(nodes as GraphNode[]);

          updateCachedData((draft) => {
            const {
              defaultContactList,
              otherContactList,
              hasOnlyOtherContacts,
            } = draft as IContactsResponse;

            (draft as IContactsResponse).defaultContactList =
              defaultContactList?.map((contact) => ({
                ...contact,
                isPresentInGraph: nodeUserIds?.includes(contact?.id),
              }));
            (draft as IContactsResponse).otherContactList =
              otherContactList?.map((contact) => ({
                ...contact,
                isPresentInGraph: nodeUserIds?.includes(contact?.id),
              }));

            const allContacts = hasOnlyOtherContacts
              ? otherContactList
              : defaultContactList?.concat(otherContactList);

            const firstContactCompanyName = (
              allContacts?.[0]?.buyerCompany as Company
            )?.displayName;

            const isAllCompaniesSame = allContacts?.every(
              ({ buyerCompany }) =>
                (buyerCompany as Company)?.displayName ===
                firstContactCompanyName,
            );

            dispatch(
              setListCompanyNameVisibility({
                isListCompanyNameVisible: !isAllCompaniesSame,
              }),
            );
          });
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});

export const { useGetAccountContactsQuery, useLazyGetAccountContactsQuery } =
  extendedAccountContactApi;

export const extendedCompanyApi = companyApi.enhanceEndpoints({
  endpoints: {
    getCompanyDetails: {
      transformResponse: (response: GetCompanyDetailsQuery) => {
        //   const list = transformAllTasksResponse(response);
        if (
          response?.accountPlanQuery?.getAccountPlan?.__typename ===
          'GraphqlError'
        ) {
          const { code, type } = response?.accountPlanQuery
            ?.getAccountPlan as GraphqlError;
          throw new Error(`${type}:${code}`);
        }
        const account = (
          response?.accountPlanQuery?.getAccountPlan as AccountPlanPayload
        )?.items?.[0];

        return account;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setAccountDetails({ account: result?.data as AccountPlan }));
        } catch (err) {
          console.error(err);
        }
      },
      // keepUnusedDataFor: 60 * 30,
    },
  },
});

export const { useGetCompanyDetailsQuery } = extendedCompanyApi;
