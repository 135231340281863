import { Button, Checkbox } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Popover from '@mui/material/Popover';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colorPalette } from '../../common/newTheme';
import { FormattedMessage } from 'react-intl';
import checkIcon from '../../assets/check.svg';
import checkBoxIcon from '../../assets/checkbox.svg';
import { Typography } from '../../components';

type MessageType = {
  id: string;
  defaultMessage: string;
};

interface PopoverProps {
  anchorEl: Element | null;
  warningTitle: MessageType;
  warningMessage?: MessageType;
  actionCheckMessage?: MessageType;
  isActionCheck?: boolean;
  onActionButtonClick?: (event: React.MouseEvent) => void;
  onClose: (event: React.MouseEvent) => void;
  onSubmit: (event: React.MouseEvent<Element, MouseEvent>) => void;
  datatest?: string | undefined;
  variant?: string | undefined;
  buttonLabel?: string;
  cancelButtonMessage?: MessageType;
  confirmButtonMessage?: MessageType;
  cancelButtonVariant?: string | undefined;
  warningTitleValue?: { [key: string]: string };
  container?: Element | HTMLElement | null;
  isMspArchivalWarning?: boolean;
  warningMessageValue?: { [key: string]: string };
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '420px',
    height: 'auto',
    background: theme.palette.primary.contrastText,
    border: '1px solid #EDF1F5',
    borderRadius: '10px',
    padding: '10px',
  },
  removeButton: {
    cursor: 'pointer !important',
    color: 'white',
    backgroundColor: theme.palette.warning.dark,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.warning.dark,
    },
    padding: '0 35px 0 35px',
  },
  primaryButton: {
    cursor: 'pointer !important',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    padding: '0 35px 0 35px',
  },
  cancel: {
    cursor: 'pointer !important',
    color: `${colorPalette.mid[500]}`,
    border: `1px solid ${colorPalette.mid[200]}`,
    marginRight: '5px',
    padding: '0 35px 0 35px',
  },
}));

function CustomWarning(props: PopoverProps) {
  const classes = useStyles();
  const {
    anchorEl,
    warningTitle,
    warningMessage,
    actionCheckMessage,
    isActionCheck,
    onActionButtonClick,
    onClose,
    onSubmit,
    datatest = '',
    buttonLabel,
    variant,
    warningTitleValue,
    cancelButtonMessage,
    confirmButtonMessage,
    cancelButtonVariant,
    container,
    isMspArchivalWarning,
    warningMessageValue,
  } = props;
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      classes={{ paper: `${classes.root} cursor-default` }}
      container={container}
    >
      <div className="mt-2 flex flex-row justify-start">
        <div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <Typography
                  variant="heading5-bold"
                  className={`${
                    isMspArchivalWarning ? 'text-warning' : 'text-neutral'
                  } leading-6 tracking-[-0.0112em]`}
                >
                  <FormattedMessage
                    id={warningTitle?.id}
                    defaultMessage={warningTitle?.defaultMessage}
                    values={warningTitleValue}
                  />
                </Typography>
              </div>
              {warningMessage && (
                <div className="mt-2">
                  <Typography
                    variant="body-14-regular"
                    className="leading-6 tracking-[-0.0112em]"
                  >
                    <FormattedMessage
                      id={warningMessage?.id}
                      defaultMessage={warningMessage?.defaultMessage}
                      values={warningMessageValue}
                    />
                  </Typography>
                </div>
              )}
              {actionCheckMessage && (
                <div className="mt-2 -ml-2.5 flex items-center">
                  <Checkbox
                    checked={isActionCheck}
                    color="primary"
                    size="small"
                    onClick={onActionButtonClick}
                    data-test={`${datatest}-password-checkbox`}
                    icon={
                      <img
                        src={checkBoxIcon as string}
                        height={18}
                        width={18}
                      />
                    }
                    checkedIcon={
                      <img src={checkIcon as string} height={18} width={18} />
                    }
                    classes={{
                      root: 'hover:bg-transparent pb-1.5',
                      checked: 'bg-transparent',
                    }}
                  />
                  <div className="mt-0.5 items-center">
                    <Typography
                      variant="body-14-regular"
                      className="leading-6 tracking-[-0.0112em]"
                    >
                      <FormattedMessage
                        id={actionCheckMessage?.id}
                        defaultMessage={actionCheckMessage?.defaultMessage}
                      />
                    </Typography>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
        </div>
      </div>
      <DialogActions>
        <Button
          className={`${
            cancelButtonVariant !== 'primary'
              ? classes.cancel
              : classes.primaryButton
          }`}
          onClick={onClose}
        >
          {cancelButtonMessage ? (
            <FormattedMessage
              id={cancelButtonMessage?.id}
              defaultMessage={cancelButtonMessage?.defaultMessage}
            />
          ) : (
            <FormattedMessage id="Button.cancel" defaultMessage="Cancel" />
          )}
        </Button>

        <Button
          onClick={onSubmit}
          data-test={`${datatest}-btn`}
          className={`${
            variant !== 'primary' ? classes.removeButton : classes.primaryButton
          }`}
        >
          {confirmButtonMessage ? (
            <FormattedMessage
              id={confirmButtonMessage?.id}
              defaultMessage={confirmButtonMessage?.defaultMessage}
            />
          ) : (
            <FormattedMessage
              id={
                buttonLabel === 'Assign'
                  ? 'Button.assignConfirm'
                  : 'Button.confirm'
              }
              defaultMessage={buttonLabel === 'Assign' ? 'Assign' : 'Confirm'}
            />
          )}
        </Button>
      </DialogActions>
    </Popover>
  );
}

export default CustomWarning;
