import { v4 as uuid } from 'uuid';
import {
  Alignx,
  FilterItemsPayload,
  GraphqlError,
  Msp,
  MspConnection,
  Opportunity,
  PageInfo,
} from '../../../generated-types';
import { ResultMsp } from '../api-call';

import {
  GetDashboardMspListQuery,
  api as opportunityApi,
} from './gql/get-dashboard-opportunity.generated';
import {
  GetPaginatedMspListQuery,
  api as paginatedOpportunityApi,
} from './gql/get-paginated-opportunity.generated';

import {
  GetOpportunityFiltersQuery,
  api as opportunityFilterApi,
} from './gql/get-opportunity-filters.generated';

import { RootState } from '../../../common/redux-store/redux-types';
import { IFilterConfig, TFilterItem } from '../../../components/filters/model';
import { getOpportunityFilterVariable } from './opportunity/opportunity-table';

export interface IRMapMsp {
  id?: string;
  name?: string;
  opportunityId?: string;
  opportunityName?: string;
  tableKey?: string;
  OpportunityCloseDate?: number;
  stageName?: string;
  // lastActivitydAt?: number;
  // lastExternalActivityAt?: number;
  // mspStage?: string;
  // buyerLogo?: string;
}

export interface GqlError extends GraphqlError {
  id: string;
}

export type TOpportunityMsp = GqlError | IRMapMsp;
export type TOpportunityResponse = {
  opportunityList: TOpportunityMsp[];
  pageInfo: PageInfo;
};

const defaultMsp = { pageInfo: null, opportunityList: [] as TOpportunityMsp[] };
function transformMspResponse(response: ResultMsp) {
  try {
    const dashboard = response?.alignxQuery?.dashboard;
    if (dashboard?.__typename === 'GraphqlError') {
      return defaultMsp;
    }
    const msps = (dashboard as Alignx)?.msps;
    if (msps?.__typename === 'GraphqlError') {
      return defaultMsp;
    }
    const edges = (msps as MspConnection)?.edges;
    const pageInfo = (msps as MspConnection)?.pageInfo;
    if (!edges) return defaultMsp;

    const opportunityList = [] as Array<TOpportunityMsp>;
    let msp = {} as TOpportunityMsp;

    for (let i = 0; i < edges?.length; i += 1) {
      msp = {} as TOpportunityMsp;
      const node = edges[i]?.node;

      if (node?.__typename === 'GraphqlError' || !node) {
        const id = `error${uuid()}`;
        msp = {
          id,
          ...node,
          name: (node as GraphqlError)?.message || 'Something went wrong',
        };
        opportunityList.push(msp);
      } else {
        const { id, opportunity, ...rest } = node as Msp;

        msp = { ...rest, id, tableKey: `${id}${i}` };

        if (opportunity?.__typename !== 'GraphqlError' && opportunity) {
          msp = {
            ...msp,
            opportunityId: (opportunity as Opportunity).id,
            opportunityName: (opportunity as Opportunity).name,
            OpportunityCloseDate: (opportunity as Opportunity).closeDate,
            stageName: (opportunity as Opportunity).stageName,
          };
        }

        if ((opportunity as Opportunity)?.id) opportunityList.push(msp);
      }
    }

    const result = { opportunityList, pageInfo };

    return result;
  } catch (err: unknown) {
    return {
      opportunityList: [
        { id: `error${uuid()}`, name: 'Something went wrong' },
      ] as TOpportunityMsp[],
      pageInfo: null,
    };
  }
}

export const extendedOpportunityApi = opportunityApi.enhanceEndpoints({
  endpoints: {
    GetDashboardMspList: {
      transformResponse: (response: GetDashboardMspListQuery) => {
        if (response?.alignxQuery?.dashboard?.__typename === 'GraphqlError') {
          const { code, type } = response?.alignxQuery
            ?.dashboard as GraphqlError;
          throw new Error(`${type}:${code}`);
        }
        const opportunityList = transformMspResponse(response as ResultMsp);

        return opportunityList;
      },
      keepUnusedDataFor: 60 * 30,
    },
  },
});

export const { useGetDashboardMspListQuery, useLazyGetDashboardMspListQuery } =
  extendedOpportunityApi;

const displayFilters = [
  'opportunity.fiscal',
  'opportunity.forecastCategory',
  'opportunity.stageName',
  'msp.createdById',
];
export const extendedOpportunityFilterApi =
  opportunityFilterApi.enhanceEndpoints({
    endpoints: {
      getOpportunityFilters: {
        transformResponse: (response: GetOpportunityFiltersQuery) => {
          if (response?.alignxQuery?.dashboard?.__typename === 'GraphqlError') {
            const { code, type } = response?.alignxQuery
              ?.dashboard as GraphqlError;
            throw new Error(`${type}:${code}`);
          }
          const filterList =
            (
              ((response as ResultMsp)?.alignxQuery?.dashboard as Alignx)
                ?.dashboardFiltersV1 as FilterItemsPayload
            )?.items
              ?.filter((item) => displayFilters.includes(item?.key))
              ?.reduce((accumulator: IFilterConfig, current) => {
                const { displayName, key, values } = current;

                if (key && displayName && values) {
                  const filterArr = values as unknown as {
                    id: string;
                    displayName: string;
                  }[];
                  const filterList = filterArr?.reduce((acc, cur) => {
                    const { id, displayName } = cur;
                    if (id && displayName) {
                      const filterItem = {
                        value: id,
                        label: displayName,
                        isSelected: false,
                        listKey: id,
                      };
                      acc.push(filterItem);
                    }
                    return acc;
                  }, [] as TFilterItem[]);
                  const val = {
                    key,
                    labelInfo: {
                      title: displayName,
                    },
                    trackEventMessage: `Clicked ${displayName}`,
                    list: filterList,
                  };
                  accumulator[displayName] = val;
                }
                return accumulator;
              }, {} as IFilterConfig) ?? {};

          return filterList;
        },
        keepUnusedDataFor: 60 * 30,
      },
    },
  });

export const { useGetOpportunityFiltersQuery } = extendedOpportunityFilterApi;

export const extendedPaginatedOpportunityApi =
  paginatedOpportunityApi.enhanceEndpoints({
    endpoints: {
      GetPaginatedMspList: {
        transformResponse: (response: GetPaginatedMspListQuery) => {
          if (response?.alignxQuery?.dashboard?.__typename === 'GraphqlError') {
            const { code, type } = response?.alignxQuery
              ?.dashboard as GraphqlError;
            throw new Error(`${type}:${code}`);
          }
          const opportunityList = transformMspResponse(response as ResultMsp);

          return opportunityList;
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
          try {
            const { data } = await queryFulfilled;
            const { opportunityList, pageInfo } = data as TOpportunityResponse;
            const filters = (getState() as RootState)?.api?.queries?.[
              'getOpportunityFilters(undefined)'
            ]?.data as IFilterConfig;

            const selectedFilters = (getState() as RootState)?.filters
              ?.selectedFilters?.['rmap-opportunity'];
            const opportunitySearch = (getState() as RootState)?.dashboard
              ?.opportunitySearch;

            const patchResult = dispatch(
              extendedOpportunityApi.util.updateQueryData(
                'GetDashboardMspList',
                getOpportunityFilterVariable(
                  opportunitySearch,
                  selectedFilters,
                  filters,
                ),
                (draft) => {
                  (draft as TOpportunityResponse).pageInfo = pageInfo;
                  (draft as TOpportunityResponse).opportunityList.push(
                    ...opportunityList,
                  );
                },
              ),
            );
            try {
              await queryFulfilled;
            } catch (err) {
              console.error(err);
              patchResult.undo();
            }
          } catch (err) {
            console.error(err);
          }
        },
        keepUnusedDataFor: 60 * 30,
      },
    },
  });

export const { useLazyGetPaginatedMspListQuery } =
  extendedPaginatedOpportunityApi;
