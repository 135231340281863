import dayjs, { Dayjs } from 'dayjs';
import {
  useCallback, useEffect, useState, ReactNode,
} from 'react';
import type { DatePickerProps } from 'antd/es/date-picker';
import 'antd/dist/antd.css';
import './antd-date-picker.css';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { colorPalette } from '../../common/newTheme';
import DatePicker from './dayjs-date-picker';

type IDateOptions = Dayjs | number | string | undefined | null;

enum DatePickerClasses{
  selectedDateWarning = 'start-date-warning',
  viewMode = 'view-only',
}

export type BaDatePickerProps = {
  selectedDate: IDateOptions;
  handleDateChange: any;
  format: string;
  size?: 'small' | 'middle' | 'large'
  dropDownclassName?: string;
  disabled?: boolean;
  isStartDate?: boolean;
  isShowWarning?: boolean;
  isViewModeEnabled?: boolean;
  fontSize?: number;
  textColor?: string;
  paddingClass?:string;
  onClose?:()=>void;
  suffixIcon?: ReactNode;
  type?:string
  placement?:'bottomLeft'| 'bottomRight'| 'topLeft' | 'topRight'
  pickerClassname?:string
  bordered?: boolean;
};
type SingleDatePickerProps = BaDatePickerProps & DatePickerProps;

export function BaDatePicker(props: SingleDatePickerProps) {
  const {
    handleDateChange,
    selectedDate,
    format,
    disabled = false,
    isViewModeEnabled = false,
    isShowWarning = false,
    size,
    onClose,
    placement,
    dropDownclassName,
    pickerClassname,
    ...datePickerProps
  } = props;
  const [localSelectedDate, setDate] = useState<Dayjs>();
  useEffect(() => {
    setDate((selectedDate as Dayjs || null));
  }, [selectedDate, localSelectedDate]);

  const classNameGenerator = () => {
    if (isViewModeEnabled) {
      const pickerInputClass = DatePickerClasses?.viewMode;
      return pickerInputClass;
    }
    if (isShowWarning) {
      const pickerInputClass = DatePickerClasses?.selectedDateWarning as string;
      return pickerInputClass;
    }
    return '';
  };
  const onDateSelection = useCallback((date: Dayjs, dateString:string[]) => {
    handleDateChange(date, selectedDate);
    setDate(date);
  }, [handleDateChange, selectedDate]) as unknown as DatePickerProps['onChange'];
  const onCloseDatePicker = useCallback((open:boolean) => {
    if (open === false && onClose && typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  return (
    <ConfigProvider locale={enUS}>
      <DatePicker
        size={size}
        onChange={onDateSelection}
        disabled={disabled}
        allowClear={false}
        getPopupContainer={(trigger) => trigger}
        bordered={false}
        dropdownClassName={`antd-single-picker ${dropDownclassName}`}
        dorpdownStyle={{ zIndex: 9999 }}
        style={{
          maxHeight: 'auto',
          width: 'fit-content',
          color: colorPalette?.mid?.main,
          maxWidth: 'auto',
        }}
        onOpenChange={(open) => onCloseDatePicker(open)}
        value={localSelectedDate as unknown as any}
        className={`${classNameGenerator()} ${pickerClassname} antd-date-picker`}
        format={format || 'MMM DD'}
        inputReadOnly
        suffixIcon={null}
        placement={placement || 'bottomLeft'}
        {...datePickerProps}
      />
    </ConfigProvider>
  );
}
