import Skeleton from '@mui/material/Skeleton';
import React from 'react';

function SearchShimmerLoading({ key }: { key: number }) {
  return (
    <div className="flex items-center justify-between px-5 py-0.5">
      <div className="flex items-center space-x-2">
        <Skeleton key={key} variant="text" width={25} height={40} />
        <Skeleton key={key} variant="text" width={150} height={12} />
      </div>
      <Skeleton key={key} variant="text" width={120} height={12} />
    </div>
  );
}

export default SearchShimmerLoading;
