import { useState, useEffect, useCallback } from 'react';
import { SortOrder } from 'react-base-table';
import type { ColumnShape } from 'react-base-table';
import { TMsp } from '../../alignx/dashboard/models';
import { sortArray } from '../utilities/utils';

export function useSortOnTable<type>(initialList: Array<type>) {
  const [data, setData] = useState(initialList);
  const [sortBy, setSortBy] = useState<{
    key: string | number;
    order: SortOrder;
  }>({ key: 'name', order: 'asc' });

  const isEmpty = !data?.length;

  const onColumnSort = useCallback(
    ({
      key,
      order,
    }: {
      column: ColumnShape<TMsp>;
      key: string | number;
      order: SortOrder;
    }) => {
      setSortBy({ key, order });
      const sortedArr = sortArray(data, key, order);
      setData(sortedArr);
    },
    [data],
  );

  useEffect(() => {
    if (initialList) {
      setData(initialList);
    }
  }, [initialList]);

  return {
    sortBy,
    data,
    isEmpty,
    onColumnSort,
  };
}
