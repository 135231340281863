import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  Company,
  CompanyResponse,
  Logo,
  Maybe,
  Media,
  MediaConnection,
  MediaEdge,
  MediaPayloadItem,
  Msp,
  MspAnalytics,
  MspInvitePermission,
  MspPayload,
  MspPayloadResponse,
  MspPermissionPayload,
  MspResponse,
  MspSubscriberStatus,
  MspTrack,
  MspTrackStatus,
  MspUserBridge,
  Opportunity,
  Subscriber,
  SubscriberConnection,
  SubscriberEdge,
  TimelineOrder,
  User,
} from '../../generated-types';
import { IMsp, IMspReduxStore } from './model';
import { fetchMspTrackStatus } from './msp-overdue/api-call';
import { EPermissions } from './shareable-msp/models';
import { TimelineData } from './timeline/custom-timeline';

const initialState = {
  mspDetail: {},
  opportunityList: [] as Opportunity[],
  mspList: [] as Msp[],
  isCustomerViewEnabled: false,
  isViewModeEnabled: false,
  insideSalesforceStatus: false,
  mspPermission: 'NONE',
  uspPermission: {
    hasFullPermission: false,
    permissions: EPermissions.NONE,
    isPasswordRequired: false,
    isEmailRequired: false,
    isEmailOptional: false,
    isUserInfoModalOpen: false,
    loadingAccess: true,
    loadingUSPData: false,
    loadingUSPPermission: true,
    hasAccessError: '',
    hasUSPDataError: '',
    hasUSPPermissionError: '',
  },
  isSidebarOpen: false,
} as IMspReduxStore;

const mspSlice = createSlice({
  name: 'msp',
  initialState,
  reducers: {
    setMspDetail: (state, action: PayloadAction<IMsp>) => {
      state.mspDetail = { ...state.mspDetail, ...action.payload };
      const mspPermissions = (
        ((state?.mspDetail as Msp)?.userBridge as MspUserBridge)
          ?.permissions as MspPermissionPayload
      )?.items;
      let permission;
      if (mspPermissions) {
        permission = mspPermissions[0];
        state.mspPermission = permission;
      }
      state.isViewModeEnabled =
        (permission &&
          ![MspInvitePermission.ALL, MspInvitePermission.EDIT].includes(
            permission,
          )) ||
        state.mspDetail.isArchived ||
        state?.uspPermission?.permissions === EPermissions.VIEW;
    },
    setMspName: (state, action: PayloadAction<string>) => {
      state.mspDetail.name = action.payload;
    },
    setIsPublishedFlag: (state, action: PayloadAction<boolean>) => {
      state.mspDetail.isPublished = action.payload;
    },
    setIsArchivedFlag: (state, action: PayloadAction<boolean>) => {
      const mspPermissions = (
        ((state?.mspDetail as Msp)?.userBridge as MspUserBridge)
          ?.permissions as MspPermissionPayload
      )?.items;
      let permission;
      if (mspPermissions) {
        permission = mspPermissions[0];
        state.mspPermission = permission;
      }
      state.isViewModeEnabled =
        (permission &&
          ![MspInvitePermission.ALL, MspInvitePermission.EDIT].includes(
            permission,
          )) ||
        state?.uspPermission?.permissions === EPermissions.VIEW;
      state.mspDetail.isArchived = action.payload;
    },
    setMspExecutiveSummary: (state, action: PayloadAction<Maybe<string>>) => {
      state.mspDetail.summary.description = action.payload ?? '';
    },
    setMspDecisionCriteria: (state, action: PayloadAction<Maybe<string>>) => {
      state.mspDetail.decisionCriteria = action.payload ?? '';
    },
    setSubscribersList: (
      state,
      action: PayloadAction<Maybe<SubscriberEdge>[]>,
    ) => {
      (state.mspDetail.subscribers as SubscriberConnection).edges =
        action.payload;
    },
    setDashboardRequiredDetails: (
      state,
      action: PayloadAction<{
        mspId: string;
        buyerCompany: Company;
        sellerCompany: Company;
      }>,
    ) => {
      const { buyerCompany, sellerCompany } = action.payload;
      state.mspDetail.buyerCompany = buyerCompany;
      state.mspDetail.sellerCompany = sellerCompany;
    },
    removeExecutiveSummaryMedia: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      const { id } = action.payload;
      const media =
        (state?.mspDetail?.summary?.media as MediaConnection)?.edges ?? [];
      const index = media.findIndex(
        (each: MediaEdge) => (each.node as MediaPayloadItem).item.id === id,
      );
      media.splice(index, 1);
    },
    addExecutiveSummaryMedia: (
      state,
      action: PayloadAction<{ newMedia: MediaEdge[] }>,
    ) => {
      const { newMedia } = action.payload;
      // let media = state?.mspDetail?.summary?.media.edges;
      if ((state?.mspDetail?.summary?.media as MediaConnection)?.edges) {
        const existingMedia =
          (state?.mspDetail?.summary?.media as MediaConnection) ?? [];
        existingMedia.edges = existingMedia.edges?.concat(newMedia);
      }
    },
    setMspAssets: (
      state,
      action: PayloadAction<{
        assets: MediaConnection | { node: { item: Media }[] };
      }>,
    ) => {
      const { assets } = action.payload;
      const { mspDetail } = state;
      if (mspDetail) mspDetail.assets = assets;
    },
    setTimelineSteps: (state, action: PayloadAction<TimelineData[]>) => {
      const { mspDetail } = state;
      if (mspDetail) mspDetail.timelines.items = action.payload;
    },

    setChildAssetToMspAssets: (
      state,
      action: PayloadAction<{
        assets: { node: { item: Media } }[];
      }>,
    ) => {
      const { assets } = action.payload;
      const mspAssets = state.mspDetail?.assets;
      if (mspAssets) {
        const existingAssets = state.mspDetail?.assets?.edges || [];

        // filtering assets for edit comment to add attachment(clicking save after upload is complete)
        const newAssets = assets.filter((asset: { node: { item: Media } }) => {
          const assetIndex = mspAssets.edges.findIndex(
            (mspAsset: { node: { item: Media } }) =>
              mspAsset.node?.item?.id ===
              ((asset as MediaEdge)?.node as MediaPayloadItem)?.item.id,
          );
          return assetIndex === -1;
        });

        const updatedAssets = newAssets?.concat(existingAssets);
        state.mspDetail.assets.edges = updatedAssets;
        // state.mspDetail.assets.edges = existingAssets;
      }
    },
    removeChildAssetFromMspAssets: (
      state,
      action: PayloadAction<{ assetIds: string | string[] }>,
    ) => {
      const { assetIds } = action.payload;
      const media = state?.mspDetail?.assets?.edges;
      if (Array.isArray(assetIds)) {
        assetIds.forEach((assetId: string) => {
          const index =
            media &&
            media.findIndex(
              (eachMedia: { node: { item: Media } }) =>
                eachMedia.node.item.id === assetId,
            );
          if (media) media.splice(index, 1);
        });
      } else {
        const index =
          media &&
          media.findIndex(
            (eachMedia: { node: { item: Media } }) =>
              eachMedia.node.item.id === assetIds,
          );
        if (media) media.splice(index, 1);
      }
    },
    removeSummaryAssetFromRootAssets: (
      state,
      action: PayloadAction<{ assetId: string; summaryId: string }>,
    ) => {
      const { assetId } = action.payload;
      const summaryAssets =
        (state.mspDetail?.summary?.media as MediaConnection)?.edges ?? [];
      const assetIndex = summaryAssets.findIndex(
        (media) => (media?.node as MediaPayloadItem)?.item?.id === assetId,
      );
      if (assetIndex >= 0) {
        summaryAssets.splice(assetIndex, 1);
      }
    },
    setSubscribersListAfterAssignUser: (
      state,
      action: PayloadAction<{
        user: User | undefined;
      }>,
    ) => {
      const subList =
        (state.mspDetail?.subscribers as SubscriberConnection)?.edges ?? [];
      const subscriber = {
        node: {
          user: action.payload.user,
          status: MspSubscriberStatus.ADDED,
          permission: MspInvitePermission.NONE,
        },
      } as SubscriberEdge;

      const userIndex = subList?.findIndex(
        (subscriber) =>
          ((subscriber?.node as Subscriber)?.user as User)?.primaryId ===
          action.payload.user?.primaryId,
      );
      if (userIndex === -1) {
        (state.mspDetail?.subscribers as SubscriberConnection)?.edges?.push(
          subscriber,
        );
      }
    },
    setMspLoader: (state, action: PayloadAction<boolean>) => {
      state.isMspLoading = action.payload;
    },
    setCustomerViewStatus: (state, action: PayloadAction<boolean>) => {
      state.isCustomerViewEnabled = action.payload;
    },
    setEconomicImpact: (state, action: PayloadAction<string>) => {
      state.mspDetail.economicImpact = action.payload;
    },
    setMspReverseTimeLineStatus: (
      state,
      action: PayloadAction<{
        isTimelineEnable: boolean;
        timelineOrder: TimelineOrder;
      }>,
    ) => {
      state.mspDetail.isReverseTimeLineEnabled =
        action.payload.isTimelineEnable;
      state.mspDetail.timelineOrder = action.payload.timelineOrder;
    },
    deleteCompanyLogo: (state) => {
      (state.mspDetail.buyerCompany as Company).logo = undefined;
    },
    setMspBuyerLogo: (
      state,
      action: PayloadAction<{
        id: string;
        url: string;
        fileName: string;
      }>,
    ) => {
      (state.mspDetail.buyerCompany as Company).logo = action.payload as Logo;
    },
    setTeam: (
      state,
      action: PayloadAction<{
        buyerOrg: CompanyResponse;
        sellerOrg: CompanyResponse;
      }>,
    ) => {
      state.mspDetail.buyerCompany = action.payload.buyerOrg;
      state.mspDetail.sellerCompany = action.payload.sellerOrg;
    },
    setOpportunityList: (state, action: PayloadAction<Opportunity[]>) => {
      state.opportunityList = action.payload;
    },
    setSelectedOpportunity: (state, action: PayloadAction<Opportunity>) => {
      state.mspDetail.opportunity = action.payload;
    },
    setMspList: (state, action: PayloadAction<Opportunity[]>) => {
      state.opportunityList = action.payload;
    },
    resetToInitialState: () => initialState,
    setLoadingAccessSuccess: (state) => {
      state.uspPermission.loadingAccess = false;
      state.uspPermission.hasFullPermission = true;
    },
    setAccessLoading: (state, action: PayloadAction<boolean>) => {
      state.uspPermission.loadingAccess = action.payload;
    },
    setUspDataLoading: (state, action: PayloadAction<boolean>) => {
      state.uspPermission.loadingUSPData = action.payload;
    },
    setUspPermissionLoading: (state, action: PayloadAction<boolean>) => {
      state.uspPermission.loadingUSPPermission = action.payload;
    },
    setUspDataAndPermissionLoading: (state) => {
      state.uspPermission.loadingUSPPermission = false;
      state.uspPermission.loadingUSPData = true;
    },
    setUspPermissions: (state, action: PayloadAction<EPermissions>) => {
      state.uspPermission.loadingUSPPermission = false;
      state.uspPermission.permissions = action.payload;
    },
    setUserInfoModal: (state, action: PayloadAction<boolean>) => {
      state.uspPermission.isUserInfoModalOpen = action.payload;
    },
    setRequiredFormFields: (
      state,
      action: PayloadAction<{
        isEmailOptional?: boolean;
        isEmailRequired?: boolean;
        isPasswordRequired?: boolean;
      }>,
    ) => {
      const { isEmailRequired, isPasswordRequired, isEmailOptional } =
        action.payload;
      state.uspPermission.loadingUSPData = false;
      state.uspPermission.isPasswordRequired = isPasswordRequired as boolean;
      state.uspPermission.isEmailRequired = isEmailRequired as boolean;
      state.uspPermission.isEmailOptional = isEmailOptional as boolean;
      state.uspPermission.isUserInfoModalOpen = (isEmailRequired ||
        isPasswordRequired ||
        isEmailOptional) as boolean;
    },
    setAccessError: (state, action: PayloadAction<string>) => {
      state.uspPermission.loadingAccess = false;
      state.uspPermission.hasAccessError = action.payload;
    },
    setUspPermissionError: (state, action: PayloadAction<string>) => {
      state.uspPermission.loadingUSPPermission = false;
      state.uspPermission.hasUSPPermissionError = action.payload;
    },
    setUspDataError: (state, action: PayloadAction<string>) => {
      state.uspPermission.loadingUSPData = false;
      state.uspPermission.hasUSPDataError = action.payload;
    },
    setAccess: (state, action: PayloadAction<boolean>) => {
      state.uspPermission.hasFullPermission = action.payload;
    },
    setSalesforceStatus: (state, action: PayloadAction<boolean>) => {
      state.insideSalesforceStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMspTrackStatus.fulfilled, (state, { payload }) => {
        const { data: response } = payload;
        if (
          response?.mspQuery?.getMsp?.__typename !== 'GraphqlError' ||
          (response?.mspQuery?.getMsp as MspPayloadResponse)?.__typename !==
            'GraphqlError' ||
          (
            (response?.mspQuery?.getMsp as MspPayload)?.items as MspResponse[]
          )[0]?.__typename !== 'GraphqlError'
        ) {
          (
            (state?.mspDetail?.analytics as MspAnalytics)?.track as MspTrack
          ).mspTrackStatus = (
            (
              ((response?.mspQuery?.getMsp as MspPayload)?.items?.[0] as Msp)
                ?.analytics as MspAnalytics
            )?.track as MspTrack
          )?.mspTrackStatus;
        } else {
          (
            (state?.mspDetail?.analytics as MspAnalytics).track as MspTrack
          ).mspTrackStatus = MspTrackStatus?.OFF_TRACK;
        }
      })
      // .addCase(fetchMspTrackStatus.pending, () => {})
      .addCase(fetchMspTrackStatus.rejected, (state) => {
        (
          (state?.mspDetail?.analytics as MspAnalytics).track as MspTrack
        ).mspTrackStatus = MspTrackStatus?.OFF_TRACK;
      });
  },
});

export const {
  setMspDetail,
  setMspName,
  setIsPublishedFlag,
  setIsArchivedFlag,
  setMspExecutiveSummary,
  setMspDecisionCriteria,
  setSubscribersList,
  resetToInitialState,
  setMspAssets,
  setChildAssetToMspAssets,
  removeChildAssetFromMspAssets,
  removeSummaryAssetFromRootAssets,
  removeExecutiveSummaryMedia,
  addExecutiveSummaryMedia,
  setSubscribersListAfterAssignUser,
  setMspLoader,
  setEconomicImpact,
  deleteCompanyLogo,
  setMspBuyerLogo,
  setTeam,
  setMspReverseTimeLineStatus,
  setOpportunityList,
  setSelectedOpportunity,
  setMspList,
  setCustomerViewStatus,
  setTimelineSteps,
  setDashboardRequiredDetails,
  setAccess,
  setUspDataError,
  setUspPermissionError,
  setAccessError,
  setRequiredFormFields,
  setUserInfoModal,
  setUspPermissions,
  setUspDataAndPermissionLoading,
  setUspPermissionLoading,
  setUspDataLoading,
  setAccessLoading,
  setLoadingAccessSuccess,
  setSalesforceStatus,
} = mspSlice.actions;

export default mspSlice.reducer;
