import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../analytics';
import { TRACKING_CONSTANTS } from '../../analytics/constants/trackingConstants';
import { RootState } from '../../common/redux-store';
import { CustomToolTip, Typography } from '../../components';
import { DropDownMenu } from '../../components/drop-down-menu/drop-down-elements';
import { showToast } from '../../components/hooks/use-toastify';
import { ToggleMspArchivePayload } from '../../generated-types';
import { useToggleMspsArchivalStatusMutation } from '../dashboard/dashboard-analytics/api/msp-archival';
import { EMspSaveIndicator } from '../msp-view/model';
import {
  addToSavingList,
  removeFromSavingList,
} from '../save-indicator/save-redux';
import { setMspStage } from './dashboard-redux-store';
import MspArchivalPopover from './msp-archival-popover';

const mapState = (state: RootState) => ({
  archivalReasonList: state?.dashboard?.archivalReasonList,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setMspStage,
      addToSavingList,
      removeFromSavingList,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MspArchivalProps = {
  mspId: string;
  mspStage: string;
  ref: React.MutableRefObject<null>;
  isInsightIconAvailable: boolean;
  isOwner: boolean;
};

const warningTitle = {
  id: 'MspPlanView.archiveMsp',
  defaultMessage: 'How do you want to archive this MSP?',
};

const warningMessage = {
  id: 'MspPlanView.archiveMspMessage',
  defaultMessage:
    'Archived MSPs are read-only and visible only to Internal users.',
};

const archiveDropdownClassName =
  'group-action h-8 w-28 flex items-center justify-center px-4 hover:bg-primary-100 hover:font-semibold py-2';

function MspArchival(props: PropsFromRedux & MspArchivalProps) {
  const {
    mspId,
    mspStage,
    setMspStage,
    addToSavingList,
    removeFromSavingList,
    isInsightIconAvailable,
    isOwner,
    archivalReasonList,
  } = props;
  const [isArchive, setIsarchive] = useState(true);
  const [archivalReasonValue, setArchivalReasonValue] = useState(
    archivalReasonList?.[0]?.id,
  );
  const [otherArchivalReasonValue, setOtherArchivalReasonValue] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorConfirmationEl, setAnchorConfirmationEl] =
    useState<Element | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMenuOpen) {
      window.sessionStorage.setItem('mspid', mspId);
    }
  }, [isMenuOpen, mspId]);

  const [toggleMspsArchivalStatus] = useToggleMspsArchivalStatusMutation();
  const ref = useRef(null);

  const setAnchorEl = useCallback(() => {
    setAnchorConfirmationEl(ref?.current);
    dispatch(
      trackEvent(TRACKING_CONSTANTS.CLICKED_MSP_ARCHIVE, {
        mspId,
      }),
    );
  }, [isMenuOpen, setAnchorConfirmationEl]);

  const onClose = useCallback(() => {
    window.sessionStorage.removeItem('mspid');
    setAnchorConfirmationEl(null);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      window.sessionStorage.setItem('mspid', mspId);
    }
  }, [isMenuOpen, mspId]);

  const onMarkingArchive = useCallback(() => {
    addToSavingList({
      saving: true,
      id: EMspSaveIndicator.MSP_ARCHIVE,
    });
    toggleMspsArchivalStatus({
      input: {
        ids: [mspId],
        isArchived: true,
        reason:
          archivalReasonValue === 'OTHER'
            ? otherArchivalReasonValue
            : archivalReasonValue,
      },
    })
      .then((resp) => {
        onClose();
        removeFromSavingList(EMspSaveIndicator.MSP_ARCHIVE);
        if (
          (resp?.data as ToggleMspArchivePayload)?.__typename === 'GraphqlError'
        ) {
          throw new Error('Something went wrong');
        } else {
          dispatch(
            trackEvent(TRACKING_CONSTANTS.MSP_ARCHIVED, {
              mspId: (resp?.data as ToggleMspArchivePayload)?.items?.[0]?.id,
              archivalReasonValue,
            }),
          );
          const mspId = (resp?.data as ToggleMspArchivePayload)?.items?.[0]?.id;
          const mspStage = (resp?.data as ToggleMspArchivePayload).items?.[0]
            ?.mspStage;
          setMspStage({ mspId, mspStage });
          showToast(
            <FormattedMessage
              id={warningMessage.id}
              defaultMessage="Archived MSPs are read-only and visible only to Internal users."
            />,
            {
              variant: 'info',
            },
          );
        }
      })
      .catch(() => {
        onClose();
        showToast(
          <FormattedMessage
            id="Snackbar.somethingWentWrong"
            defaultMessage="Something went wrong, please try again"
          />,
          {
            variant: 'error',
          },
        );

        removeFromSavingList(EMspSaveIndicator.MSP_ARCHIVE);
      });
  }, [isArchive, setIsarchive, archivalReasonValue, otherArchivalReasonValue]);

  return (
    <div className="" ref={ref}>
      <DropDownMenu.Root
        open={isOwner ? isMenuOpen : false}
        onOpenChange={setIsMenuOpen}
      >
        <CustomToolTip
          arrow
          placement="top"
          title={`${
            isOwner
              ? 'More Actions'
              : 'You do not have permission. Only the MSP Owner can access this.'
          }`}
        >
          <div className="">
            <DropDownMenu.Trigger asChild>
              <div
                role="presentation"
                className={clsx(
                  'mr-2 flex cursor-pointer items-center justify-center !rounded p-1',
                  isMenuOpen && isOwner
                    ? 'visible items-center bg-gray-300 p-1 text-primary'
                    : `invisible ${
                        isOwner
                          ? ' text-mid-500 hover:bg-gray-300 hover:text-primary'
                          : 'bg-gray-200 text-mid-300 hover:bg-gray-200 hover:text-mid-300'
                      } hover:p-1  group-hover:visible`,
                  isMenuOpen && isInsightIconAvailable && mspStage !== 'DRAFT'
                    ? 'ml-10 group-hover:ml-2'
                    : 'ml-2',
                )}
              >
                <FiMoreHorizontal fontSize={18} />
              </div>
            </DropDownMenu.Trigger>
          </div>
        </CustomToolTip>
        <MspArchivalPopover
          anchorEl={anchorConfirmationEl}
          warningTitle={warningTitle}
          onSubmit={() => {
            setAnchorConfirmationEl(null);
            onMarkingArchive();
          }}
          onClose={onClose}
          archivalReasonValue={archivalReasonValue}
          setArchivalReasonValue={setArchivalReasonValue}
          otherArchivalReasonValue={otherArchivalReasonValue}
          setOtherArchivalReasonValue={setOtherArchivalReasonValue}
        />
        <DropDownMenu.Portal className="m-0">
          <DropDownMenu.Content className="!shadow-[0_5px_12px_0px_rgba(0, 0, 0, 0.31)] !flex !h-10 !rounded !py-1">
            <DropDownMenu.Item
              className={archiveDropdownClassName}
              onClick={setAnchorEl}
            >
              <Typography variant="body-13-medium" className="text-neutral">
                <FormattedMessage
                  id="MoreAction.mspArchiveMessage"
                  defaultMessage="Archive Msp"
                />
              </Typography>
            </DropDownMenu.Item>

            <DropDownMenu.WhiteArrow />
          </DropDownMenu.Content>
        </DropDownMenu.Portal>
      </DropDownMenu.Root>
    </div>
  );
}

export default connector(MspArchival);
