import { Button } from '@mui/material';
import MobileViewImg from './assets/mobile-view.svg';
import { Typography } from './components';

const MobileView = (props: {
  setMobileView: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setMobileView } = props;
  return (
    <div
      className="space-y-4 items-center flex flex-col w-4/5 m-auto my-[25%]"
    >
      <Typography variant="body-14-semibold" className="text-center">
        For a good experience on BuyerAssist,
        we recommend accessing the Mutual Success Plans
        on a bigger screen - tab / laptop / desktop
      </Typography>
      <img src={MobileViewImg} alt="mobile-view" width={220} height={300} />
      <Button
        variant="contained"
        color="primary"
        className="normal-case"
        sx={{ textTransform: 'none' }}
        onClick={() => setMobileView(false)}
      >
        Continue Anyway
      </Button>
    </div>
  );
};

export default MobileView;
