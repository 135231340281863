import { gql, useLazyQuery, useMutation } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { message } from '../../../common-language/index';
import { useDebounce } from '../../../common/methods/debounce';
import ErrorPages from '../../../common/methods/error-index';
import { RootState } from '../../../common/redux-store';
import CircularIndeterminate from '../../../components/circular-loader/index';
import { showToast } from '../../../components/hooks/use-toastify';
import { CheckIcon } from '../../../components/icons';
import { BaInput } from '../../../components/input';
import { CustomToolTip } from '../../../components/tool-tip/tooltip';
import { Typography } from '../../../components/typography';
import CustomWarning from '../../../components/warning-popover';
import {
  BooleanObject,
  GraphqlError,
  MspMutationLinkOpportunityArgs,
  Operation,
  Opportunity,
  OpportunityConnection,
  OpportunityQuery,
  Organization,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../../graphql/fragments/error';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import { EMspSaveIndicator, LinkOpportunityMspResult } from '../model';
import { setOpportunityList, setSelectedOpportunity } from '../msp-redux-store';
import { LINK_OPPORTUNITY } from './search-msp';

const GET_OPPORTUNITIES = gql`
  ${CORE_GRAPH_ERROR}
  query GetOpportunities($input: getOpportunityForOwnerAndCoOwnerInput!) {
    opportunityQuery {
      getOpportunityForOwnerAndCoOwner(input: $input) {
        __typename
        ...CoreErrorFields
        ... on OpportunityConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Opportunity {
                id
                accountId
                name
                stageName
              }
            }
          }
        }
      }
    }
  }
`;

const warningTitle = {
  id: 'WarningTitle.opportunity',
  defaultMessage: 'Assign Opportunity',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey['50'],
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

function includesTerm(input: string, term: string) {
  return input.toLowerCase().includes(term.toLowerCase());
}
export type ResultOpportunityQuery = {
  opportunityQuery: OpportunityQuery;
};
interface OpportunityObj {
  opportunityList: Opportunity[] | undefined;
  filterOppList: Opportunity[] | undefined;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOpportunityList,
      addToSavingList,
      removeFromSavingList,
      setSelectedOpportunity,
    },
    dispatch,
  );

const mapStateToProps = (state: RootState) => ({
  opportunityList: state.msp.opportunityList,
  selectedOpportunity: state.msp.mspDetail.opportunity,
  mspId: state.msp.mspDetail?.id,
  stageNames: (state?.organization as Organization)?.configs?.salesforce
    ?.qualifiedStageNames,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SearchOpportunity(
  props: PropsFromRedux & { refetchMspdata?: () => void },
) {
  const [linkOpportunity] = useMutation<
    LinkOpportunityMspResult,
    MspMutationLinkOpportunityArgs
  >(LINK_OPPORTUNITY);

  const [selectedOpportunityDetails, setOpportunityDetails] =
    React.useState<Opportunity>();
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const {
    stageNames,
    setOpportunityList,
    addToSavingList,
    removeFromSavingList,
    setSelectedOpportunity,
    mspId,
    refetchMspdata,
  } = props;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [opportunity, setOpportunity] = useState<OpportunityObj>({
    opportunityList: [],
    filterOppList: [],
  });
  const dispatch = useDispatch();
  const { filterOppList = [], opportunityList = [] } = opportunity;
  const [getOpportunity, { loading, error, data }] =
    useLazyQuery<ResultOpportunityQuery>(GET_OPPORTUNITIES, {
      variables: {
        input: {
          operations: {
            filter: {
              operator: 'AND',
              filters: {
                values: stageNames,
                field: 'opportunity.stageName',
                op: Operation.IN,
              },
            },
          },
        },
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        if (
          res?.opportunityQuery?.getOpportunityForOwnerAndCoOwner
            ?.__typename !== 'GraphqlError'
        ) {
          const oppList =
            (
              res?.opportunityQuery
                ?.getOpportunityForOwnerAndCoOwner as OpportunityConnection
            )?.edges?.map((edge) => edge?.node as Opportunity) ?? [];
          setOpportunityList(oppList);
          setOpportunity({
            opportunityList: oppList,
            filterOppList: oppList,
          });
        }
      },
    });

  const debouncedListFilter = useDebounce(
    (list: Opportunity[], term: string) => {
      const filteredList =
        list &&
        list.filter(({ name, stageName = '' }) => {
          if (includesTerm(name, term) || includesTerm(stageName, term))
            return true;

          return false;
        });
      setOpportunity({ filterOppList: filteredList, opportunityList: list });
    },
    500,
  );
  const onSelectingOpportunity = useCallback(async () => {
    try {
      const { id, accountId } = selectedOpportunityDetails as Opportunity;
      trackEvent(TRACKING_CONSTANTS.OPPORTUNITY_SELECTED, {
        selectedOpportunityDetails,
      });
      if (id) {
        const resp = await linkOpportunity({
          variables: {
            input: { mspId, opportunityId: id, accountId },
          },
        });
        if (
          resp.data?.mspMutation?.linkOpportunity.__typename === 'GraphqlError'
        ) {
          throw new Error('Opportunity Could Not Be Linked');
        } else if (
          (resp.data?.mspMutation?.linkOpportunity as BooleanObject).success
        )
          trackEvent(TRACKING_CONSTANTS.OPPORTUNITY_LINKED_TO_MSP, {
            mspId,
          });
        if (selectedOpportunityDetails)
          setSelectedOpportunity(selectedOpportunityDetails);
        removeFromSavingList(EMspSaveIndicator.MSP_OPPORTUNITY_UPDATE);
        if (refetchMspdata && typeof refetchMspdata === 'function')
          refetchMspdata();
      }
    } catch {
      showToast(
        <FormattedMessage
          id="MspPlanView.opportunityUpdateAPIError"
          defaultMessage={
            'Uh oh! We were unable to assign that Opportunity. Please try again.'
          }
        />,
        {
          variant: 'error',
        },
      );
      removeFromSavingList(EMspSaveIndicator.MSP_OPPORTUNITY_UPDATE);
      setSelectedOpportunity(null);
    }
  }, [
    removeFromSavingList,
    mspId,
    selectedOpportunityDetails,
    setSelectedOpportunity,
    linkOpportunity,
    refetchMspdata,
  ]);

  const onClose = useCallback(() => {
    setOpportunityDetails(null);
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSubmit = useCallback(async () => {
    addToSavingList({
      saving: true,
      id: EMspSaveIndicator.MSP_OPPORTUNITY_UPDATE,
    });

    setAnchorEl(null);
    await onSelectingOpportunity();
  }, [addToSavingList, setAnchorEl, onSelectingOpportunity]);

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== null)
      if (searchTerm) {
        dispatch(
          trackEvent(TRACKING_CONSTANTS.OPPORTUNITY_SEARCH, {
            mspId,
            searchTerm,
          }),
        );
      }
    debouncedListFilter(opportunityList, searchTerm);
  }, [searchTerm, opportunityList, debouncedListFilter]);

  useEffect(() => {
    if (props?.opportunityList?.length > 0) {
      setOpportunity({
        opportunityList: props?.opportunityList,
        filterOppList: props?.opportunityList,
      });
    }
  }, []);

  useEffect(() => {
    if (stageNames) getOpportunity();
  }, [getOpportunity, stageNames]);

  const warningMessage = {
    id: 'WarningTitle.opportunity',
    defaultMessage: `Are you sure you want to assign this opportunity ${
      selectedOpportunityDetails?.name ?? ''
    } to this Mutual Success Plan?`,
  };

  if (loading || !stageNames) return <CircularIndeterminate />;
  if (
    error ||
    data?.opportunityQuery?.getOpportunityForOwnerAndCoOwner?.__typename ===
      'GraphqlError'
  ) {
    return (
      <ErrorPages
        errorCode={
          (
            data?.opportunityQuery
              ?.getOpportunityForOwnerAndCoOwner as GraphqlError
          )?.code
        }
      />
    );
  }
  return (
    <>
      <div className="create-success-container w-auto overflow-y-auto px-3">
        <div className="relative w-full">
          <BaInput
            parentValue=""
            onChange={(text) => setSearchTerm(text || '')}
            placeholder={message['MspPlanView.searchOpportunityPlaceHolder']}
            debounceTime={500}
            type="search"
          />
          {/* <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
            className="w-full border border-solid border-gray-300 bg-primary-50 text-gray-600 shadow rounded border-0 py-3 pl-10 placeholder-gray-500 focus:outline-none focus:ring-offset-indigo-400"
            placeholder={message['MspPlanView.searchOpportunityPlaceHolder']}
          />
          <SearchIcon className="absolute top-2 left-2 text-gray-500" /> */}
        </div>
        {filterOppList?.length === 0 ? (
          <div className="my-4 w-full text-center text-base text-gray-400">
            No Opportunity found
          </div>
        ) : (
          <div className="w-full">
            <List>
              {filterOppList?.map((opportunity) => {
                const { id, accountId, name } = opportunity;
                const isAccountNotLinked = !accountId;

                return (
                  <ListItem
                    key={id}
                    onClick={(event) => {
                      setOpportunityDetails(opportunity);
                      setAnchorEl(event?.currentTarget);
                    }}
                    className={clsx(
                      isAccountNotLinked && classes.disabled,
                      id === selectedOpportunityDetails?.id ? classes.root : '',
                    )}
                    button
                    divider
                    dense
                    disableGutters={false}
                  >
                    <div className="flex w-full items-center justify-between">
                      <CustomToolTip
                        placement="top"
                        arrow
                        title={name}
                        showTruncated
                      >
                        <div className="truncate">
                          <Typography
                            variant="body-14-bold"
                            className={clsx(
                              'primary-highlight w-full truncate text-neutral',
                              isAccountNotLinked && 'opacity-50',
                            )}
                          >
                            {name}
                          </Typography>
                        </div>
                      </CustomToolTip>

                      <div
                        className={
                          id === selectedOpportunityDetails?.id ? '' : 'hidden'
                        }
                      >
                        <CheckIcon size={20} />
                      </div>
                      {isAccountNotLinked && (
                        <Typography
                          element="div"
                          className="col-span-3 truncate text-right text-error-300"
                          variant="label-12-regular"
                        >
                          Account not linked
                        </Typography>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </div>
      <CustomWarning
        anchorEl={anchorEl}
        warningTitle={warningTitle}
        warningMessage={warningMessage}
        onSubmit={onSubmit}
        onClose={onClose}
        datatest="opportunity-confrirm"
        variant="primary"
        buttonLabel="Assign"
      />
    </>
  );
}

export default connector(SearchOpportunity);
