export interface Response {
  access: boolean;
}

export interface IHasAccessResponse {
  response: Response;
}

export interface IErrorDetails {
  message: string;
  detail?: string;
}
export interface IApiError {
  error: IErrorDetails;
}
export interface ResponsePermission {
  permission: string;
}
export interface IPermissionResponse {
  response: ResponsePermission;
}

export enum EPermissions {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  NONE = 'NONE',
  ALL = 'ALL',
}
