import type { Edge, Node, Viewport } from 'reactflow';
import { IEntityUser } from '../../common/redux-store/entity-store/entity-types';
import { TOption } from '../../components/ba-select/common';
import {
  AccountPlan,
  DataPayloadResource,
  DataSourcePath,
  GraphItemDataMetaData,
  IRelationshipBaseItem,
  Maybe,
  PageInfo,
  RelationshipContact,
  RelationshipScopeType,
  Scalars,
  Structure,
  User,
} from '../../generated-types';
import { CreateInfluencerMapContactMutation } from './graphql/create-contact.generated';
import { IBatchAction } from './influencer-redux-store';

export type TNoteType = { id: string; message: string };
export type TNotesType = { id: string; messageData: { message: string } };

export interface IColor {
  rgb: string;
  hex: string;
}

export interface IMeta {
  color: IColor;
}

export type TDropdownOptions = {
  id: string;
  name: string;
  displayName: string;
  meta?: IMeta;
};

export type TLinkedInMutationType = {
  url: string;
};

export interface ILinkedInData {
  name?: string;
  title?: string;
  summary?: string;
  profileUrl: string;
  avatarUrl?: string;
}

export interface IAvatar {
  url: string;
}

export type TClassName = React.ComponentProps<'div'>['className'];

export interface TContactRole {
  id: string;
  value: string;
}

export type JsonRecord = Record<string, string>;

export type ValueType =
  | string
  | Record<string, unknown>
  | Record<string, unknown>[]
  | TDropdownOptions
  | null
  | TNoteType
  | User
  | boolean
  | ILinkedInData
  | TLinkedInMutationType
  | IAvatar
  | TContactRole[];

export interface UserDetail {
  key: string;
  value: ValueType;
}

export interface IFormField {
  [key: string]: ValueType;
}

export interface UserState {
  fields: IFormField;
  initialFields: IFormField;
  loading: boolean;
  error: string | null;
}
export interface CreateUserState {
  fields: Record<string, IFormField>;
}

export interface UserListData {
  [key: string]: UserState;
}

export type NodeData = {
  id: string;
  name: string;
  team: string;
  stakeholderRole: string;
  role: string;
  impact: string;
  __typename?: string;
};

export enum EContactAttribute {
  'NAME' = 'NAME',
  'TITLE' = 'TITLE',
  'STAKEHOLDER_ROLE' = 'STAKEHOLDER_ROLE',
  'CONTACT_ROLE' = 'CONTACT_ROLE',
  'CARD_NOTES' = 'NOTES',
  'STRENGTH' = 'STRENGTH',
  'SENTIMENTS' = 'SENTIMENTS',
  'ASSIGNEES' = 'ASSIGNEES',
  'KEY_STAKEHOLDER' = 'KEY_STAKEHOLDER',
  'LINKEDIN_URL' = 'LINKEDIN_URL',
  'AVATAR' = 'AVATAR',
  'COMPANY_NAME' = 'COMPANY_NAME',
}

export enum EContactCard {
  'NAME' = 'CONTACT_CARD:NAME',
  'TITLE' = 'CONTACT_CARD:TITLE',
  'STAKEHOLDER_ROLE' = 'CONTACT_CARD:STAKEHOLDER_ROLE',
  'CONTACT_ROLE' = 'CONTACT_CARD:CONTACT_ROLE',
  'CARD_NOTES' = 'CONTACT_CARD:NOTES',
  'STRENGTH' = 'CONTACT_CARD:STRENGTH',
  'LINKEDIN_URL' = 'CONTACT_CARD:LINKEDIN_URL',
  'AVATAR' = 'CONTACT_CARD:AVATAR',
  'COMPANY_NAME' = 'CONTACT_CARD:COMPANY_NAME',
  'REPORTS_TO' = 'CONTACT_CARD:REPORTS_TO',
}

export enum EUpdateContactAttribute {
  NAME = 'CONTACT_FORM:NAME',
  TITLE = 'CONTACT_FORM:TITLE',
  STAKEHOLDER_ROLE = 'CONTACT_FORM:STAKEHOLDER_ROLE',
  CONTACT_ROLE = 'CONTACT_FORM:CONTACT_ROLE',
  CARD_NOTES = 'CONTACT_FORM:NOTES',
  STRENGTH = 'CONTACT_FORM:STRENGTH',
  SENTIMENTS = 'CONTACT_FORM:SENTIMENTS',
  ASSIGNEES = 'CONTACT_FORM:ASSIGNEES',
  KEY_STAKEHOLDER = 'CONTACT_FORM:KEY_STAKEHOLDER',
  LINKEDIN_URL = 'CONTACT_FORM:LINKEDIN_URL',
  AVATAR = 'CONTACT_FORM:AVATAR',
  COMPANY = 'CONTACT_FORM:COMPANY',
}

export enum ECreateContactAttribute {
  FIRST_NAME = 'CREATE_CONTACT_FORM:FIRST_NAME',
  LAST_NAME = 'CREATE_CONTACT_FORM:LAST_NAME',
  EMAIL = 'CREATE_CONTACT_FORM:EMAIL',
  TITLE = 'CREATE_CONTACT_FORM:TITLE',
  STAKEHOLDER_ROLE = 'CREATE_CONTACT_FORM:STAKEHOLDER_ROLE',
  CONTACT_ROLE = 'CREATE_CONTACT_FORM:CONTACT_ROLE',
  CARD_NOTES = 'CREATE_CONTACT_FORM:NOTES',
  STRENGTH = 'CREATE_CONTACT_FORM:STRENGTH',
  COMPANY = 'CREATE_CONTACT_FORM:COMPANY',
  SENTIMENTS = 'CREATE_CONTACT_FORM:SENTIMENTS',
  ASSIGNEES = 'CREATE_CONTACT_FORM:ASSIGNEES',
  KEY_STAKEHOLDER = 'CREATE_CONTACT_FORM:KEY_STAKEHOLDER',
  LINKEDIN_URL = 'CREATE_CONTACT_FORM:LINKEDIN_URL',
}

export enum EFreeFlowCard {
  'TEXT' = 'FREE_FLOW_CARD:TEXT',
}

export enum ENodeTypes {
  'USER' = 'user',
  'FLOATING_TEXT' = 'floating-text',
}

export enum EFieldTypes {
  TEXT = 'text',
  DROPDOWN = 'dropdown',
  RICH_TEXT = 'rich_text',
  EMAIL = 'email',
  ASSIGNEES = 'avatars',
  CHECK_BOX = 'checkbox',
}

export enum EEdgeAttribute {
  'NOTES' = 'EDGE:NOTES',
}

export interface DefaultValuePath {
  value: string;
}
export interface Path {
  defaultValuePath?: DefaultValuePath[];
  __typename?: string;
}
export interface DataSourceConfig {
  path?: DataSourcePath;
  __typename?: string;
}
export interface UserFormDetail {
  key?: string;
  value?: ValueType;
  id?: string | null;
  label?: string | undefined;
  fieldType?: string | undefined;
  dataSourceConfig?: DataSourceConfig | Record<string, unknown> | undefined;
  isDisabled?: boolean;
}

export interface ValueClass {
  id?: string;
  name?: string;
  __typename?: string;
  displayValue?: string;
  value?: string;
}

export enum FieldValueTypename {
  RelationshipItemsFieldValue = 'RelationshipItemsFieldValue',
}

export enum Hex {
  The3C3C3C = '#3c3c3c',
}

export enum RGB {
  RGB210210210 = 'rgb(210, 210, 210)',
}
export interface Color {
  rgb: RGB;
  hex: Hex;
}

export interface Meta {
  color: Color;
  __typename?: string;
}

export enum MetaTypename {
  RelationshipItemsFieldValueMetaData = 'RelationshipItemsFieldValueMetaData',
}
export interface FieldValue {
  id: string;
  name: string;
  meta: Meta;
}

export interface Style {
  colour?: string;
}
export interface IGraphData {
  nodes?: Node[];
  edges?: Edge[];
  viewport?: Viewport;
}

export const removeTypeNameRegex =
  /(,?"__typename":"\w+")|("__typename":"\w+",?)/g;

export interface IFormOptionDetails {
  id: string;
  name: string;
  displayName: string;
  meta: IMeta;
  __typename: string;
}

export interface FieldProperty {
  id?: string;
  key?: string;
  fieldType?: string;
  label?: string;
  dataSourceConfig?: DataSourceConfig;
  __typename?: string;
  value?: ValueClass | string | ValueType;
  contactRoles?: IFormOptionDetails[];
  strength?: IFormOptionDetails[];
  sentiments?: IFormOptionDetails[];
  stakeholderRoles?: IFormOptionDetails[];
  assignees?: IEntityUser[];
  isHidden?: boolean;
  isEditable?: boolean;
  isDisabled?: boolean;
}

export interface IFormFieldsWithValue {
  style?: Style;
  __typename?: string;
  fieldProperty?: FieldProperty;
  fieldValues?:
    | FieldValue[]
    | IFormOptionDetails[]
    | string
    | Record<string, unknown>
    | null
    | DataSourceConfig
    | boolean
    | ValueType
    | IEntityUser[];
}

export interface IContact extends RelationshipContact {
  isPresentInGraph?: boolean;
  isExpanded?: boolean;
}

export interface IInfluencerState {
  nodes: Node[];
  edges: Edge[];
  viewport: Viewport;
  isNodeHandlersVisible: boolean;
  actionableEdgeId: string | null;
  userContactList: RelationshipContact[];
  userContactError: string | null;
  userContactLoading: boolean;
  influencerDataError: string | null;
  influencerDataLoading: boolean;
  influencerDataStaticSuccess: boolean;
  isEditDrawerOpen: boolean;
  editingProfileId: string | null;
  structureLoading: boolean;
  structureError: string | null;
  structureList: Structure[];
  userData: UserListData;
  formFieldValuesLoading: boolean;
  formFieldValuesError: string | null;
  formFieldValuesList: FieldProperty;
  isReadonly: boolean;
  createUserData: Record<string, ValueType>;
  assigneeList: IRelationshipBaseItem[];
  assigneeError: string | null;
  assigneeLoading: boolean;
  filters: Record<string, string[] | boolean>;
  filterKeyStakeholders: boolean;
  newContactIdOnCanvas: string | null;
  scopeId: string;
  scopeType: RelationshipScopeType;
  accountDetails: AccountPlan;
  isListCompanyNameVisible: boolean;
  isNodeCompanyNameVisible: boolean;
  isNodeDraggingFromList: boolean;
  isIntroDialogVisible: boolean;
  isImportDialogVisible: boolean;
  isCloningLoading: boolean;
  isTextModeEnabled: boolean;
  isSelectionEnabled: boolean;
  isPanOnDragEnabled: boolean;
  isZoomOnDoubleClickEnabled: boolean;
  isUserSideNavOpen: boolean;
  batchNumber: number;
  firstLoad: boolean;
  nodeActionList: Record<string, IBatchAction[]>;
  edgeActionList: Record<string, IBatchAction[]>;
  intersectingNodeId: string;
  reportToIdDraggingFromList: string | null | undefined;
  isManagerPresentOnCanvas: boolean;
  distinctTreeSet: string[][];
  draggingNodeId: string;
  managerMismatchNodeId: string;
  draggingCardManagerName: string;
}
export interface UserCompaniesScope {
  companyId: string;
  userType: string;
}
export interface IManager {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userCompaniesScope: UserCompaniesScope[];
  title: string;
}

export type FDataPayload = {
  __typename?: string;
  id?: Maybe<Scalars['ID']>;
  values?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  key?: Maybe<Scalars['ID']>;
};

export type FGraphItemData = {
  __typename?: 'GraphItemData';
  id?: Maybe<Scalars['ID']>;
  resource?: Maybe<DataPayloadResource>;
  dataPayload?: Maybe<Array<Maybe<FDataPayload>>>;
  metaData?: Maybe<GraphItemDataMetaData>;
};

export interface ICreateInfluencerMapContactMutationResponse {
  data: CreateInfluencerMapContactMutation;
}
export interface IAssigneeUser {
  id: string;
  name: string;
}

export enum EContactRoles {
  'CHAMPION' = 'Champion',
  'ECONOMIC_BUYER' = 'Economic Buyer',
}

export type DataPayload = {
  __typename?: 'DataPayload';
  id?: Maybe<Scalars['ID']>;
  values?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  key?: Maybe<Scalars['ID']>;
};

export type GraphItemData = {
  __typename?: 'GraphItemData';
  id?: Maybe<Scalars['ID']>;
  resource?: Maybe<DataPayloadResource>;
  dataPayload?: Maybe<Array<Maybe<DataPayload>>>;
  metaData?: Maybe<GraphItemDataMetaData>;
};

export interface IFilterItem {
  id: string;
  name: string;
  color: string;
}

export enum EStakeholderFilterOptions {
  'ALL_STAKEHOLDERS' = 'ALL_STAKEHOLDERS',
  'KEY_STAKEHOLDERS' = 'KEY_STAKEHOLDERS',
}

export interface IContactsResponse {
  otherContactList: IContact[];
  defaultContactList: IContact[];
  pageInfo?: PageInfo;
  hasOnlyOtherContacts?: boolean;
}

export interface ISearchContactsResponse {
  searchResultList: IContact[];
  // isSearchResultCompanyVisible?: boolean;
}

export interface ISearchCompanyResponse {
  companies: TOption[];
}

export interface IScope {
  id: string;
  type: string;
}

export enum EDialogOpenLocation {
  canvas = 'canvas',
  sidePanel = 'sidePanel',
}

export const DONT_SHOW_OVERRIDE_MESSAGE = 'DONT_SHOW_OVERRIDE_MESSAGE';
export enum EOverrideStatus {
  'on' = 'on',
  'off' = 'off',
}
