/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import * as z from 'zod';
import { RootState } from '../../../common/redux-store';
import { CircularIndeterminate, Typography } from '../../../components';
import {
  Company,
  Logo,
  Subscriber,
  SubscriberConnection,
  User,
  UserType,
} from '../../../generated-types';
import { ComponentType } from '../model';
import UserCompany from './user-company-select';

const initialUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  companyId: '',
};

// export type IUserFormData = typeof initialUserForm;

export type IUserFormData = {
  firstName: string;
  lastName: string;
  email?: string;
  companyId: string;
};
interface UserFields {
  id: string;
  placeholder: string;
  name: keyof IUserFormData;
  datatest?: string;
  userId?: string;
}
const userFields: UserFields[] = [
  {
    id: 'Form.email',
    placeholder: 'Email',
    name: 'email',
    datatest: 'email',
  },
  {
    id: 'Form.firstName',
    placeholder: 'First Name',
    name: 'firstName',
    datatest: 'first-name',
  },
  {
    id: 'Form.lastName',
    placeholder: 'Last Name',
    name: 'lastName',
    datatest: 'last-name',
  },
];

const mapStateToProps = (state: RootState) => ({
  buyerCompany: state?.msp?.mspDetail?.buyerCompany,
  sellerCompany: state?.msp?.mspDetail?.sellerCompany,
  buyerCompanyLogo: (
    (state.msp?.mspDetail?.buyerCompany as Company)?.logo as Logo
  )?.url,
  sellerCompanyLogo: (
    (state.msp?.mspDetail?.sellerCompany as Company)?.logo as Logo
  )?.url,
  subscriberList:
    (state.msp?.mspDetail?.subscribers as SubscriberConnection)?.edges ?? [],
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;
type ICreateUser = {
  onSubmit: (data: IUserFormData) => void;
  onBack: () => void;
  loading: boolean;
  buttonMessage: {
    id: string;
    defaultMessage: string;
  };
  companyName?: string;
  companyId?: string;
  isTaskInternal?: boolean;
  componentType?: ComponentType;
  userId?: string;
  className?: string;
  isInShareFlow?: boolean;
};
type ICreateUserProps = PropsFromRedux & ICreateUser;

function CreateNewUser(
  props: ICreateUserProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    onBack,
    onSubmit,
    loading,
    buttonMessage,
    buyerCompany,
    sellerCompany,
    companyId,
    isTaskInternal,
    componentType,
    buyerCompanyLogo,
    sellerCompanyLogo,
    subscriberList,
    userId,
    className,
    isInShareFlow,
    ...otherProps
  } = props;
  const intl = useIntl();

  const [selectedCompanyId, setCompanyId] = useState(
    companyId ?? (buyerCompany as Company)?.id,
  );

  const isSeller = selectedCompanyId === (sellerCompany as Company)?.id;
  const isEmailRequired = isInShareFlow || isSeller;

  let zodSchema: any = null;

  if (!userId) {
    zodSchema = z.object({
      firstName: z.string().nonempty({ message: 'First Name Required' }),
      lastName: z.string().nonempty({ message: 'Last Name Required' }),
      email: isEmailRequired
        ? z
            .string()
            .nonempty({ message: 'Email Address Required' })
            .email({ message: 'Incorrect Email Address' })
        : z
            .string()
            .email({ message: 'Incorrect Email Address' })
            .optional()
            .or(z.literal('')),
    });
  } else if (userId) {
    zodSchema = z.object({
      firstName: z.string().nonempty({ message: 'First Name Required' }),
      lastName: z.string().nonempty({ message: 'Last Name Required' }),
      email: z.string().optional(),
    });
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, reset } = useForm<IUserFormData>({
    mode: 'onTouched',
    defaultValues: initialUserForm,
    resolver: zodResolver(zodSchema),
  });

  useEffect(() => {
    if (userId) {
      const subscriber = subscriberList.find(
        (sub) => ((sub.node as Subscriber)?.user as User)?.id === userId,
      );
      if (subscriber?.node) {
        const {
          firstName = '',
          lastName = '',
          email = '',
          userType,
        } = (subscriber.node as Subscriber).user as User;
        reset(
          {
            firstName,
            lastName,
            email,
            companyId:
              userType === UserType.SELLER
                ? (sellerCompany as Company)?.id
                : (buyerCompany as Company)?.id,
          },
          { dirtyFields: false, isDirty: false, isValid: true },
        );
        setCompanyId(
          userType === UserType.SELLER
            ? (sellerCompany as Company)?.id
            : (buyerCompany as Company)?.id,
        );
      }
    }
  }, [userId, reset, setCompanyId]);

  const options = [
    {
      value: (buyerCompany as Company)?.id,
      label:
        (buyerCompany as Company)?.displayName ||
        (buyerCompany as Company)?.companyName,
      datatest:
        (buyerCompany as Company)?.displayName ||
        (buyerCompany as Company)?.companyName,
      logo: buyerCompanyLogo,
    },
    {
      value: (sellerCompany as Company)?.id,
      label:
        (sellerCompany as Company)?.displayName ||
        (sellerCompany as Company)?.companyName,
      datatest:
        (sellerCompany as Company)?.displayName ||
        (sellerCompany as Company)?.companyName,
      logo: sellerCompanyLogo,
    },
  ];

  useEffect(() => {
    if (!companyId && !userId) {
      setCompanyId((buyerCompany as Company)?.id);
    }
  }, [companyId, userId, buyerCompany, setCompanyId]);

  useEffect(
    () => () => {
      onBack();
    },
    [],
  );
  if (isTaskInternal && componentType === ComponentType?.TASK) {
    options.shift();
  }

  return (
    <div {...otherProps} ref={ref}>
      {loading ? (
        <div className={clsx('mx-6 space-y-3 p-2 py-3 pt-5', className)}>
          <Typography variant="label-12-semibold">
            {userId ? (
              <FormattedMessage
                id="MspPlanView.updateUser"
                defaultMessage="User Profile"
              />
            ) : (
              <FormattedMessage
                id="MspPlanView.createNewUser"
                defaultMessage="New User"
              />
            )}
          </Typography>
          <CircularIndeterminate padding={12} />
        </div>
      ) : (
        <>
          <div className={clsx('mx-6 space-y-3 p-2 py-3 pt-5', className)}>
            <Typography variant="label-12-semibold">
              {userId ? (
                <FormattedMessage
                  id="MspPlanView.updateUser"
                  defaultMessage="User Profile"
                />
              ) : (
                <FormattedMessage
                  id="MspPlanView.createNewUser"
                  defaultMessage="New User"
                />
              )}
            </Typography>
            <div className="mt-2 space-y-4">
              <UserCompany
                options={options}
                selectedCompanyId={selectedCompanyId}
                setCompanyId={setCompanyId}
                userId={userId}
                companyId={companyId}
              />
              <div className="grid w-full grid-cols-9 items-center space-y-2">
                {userFields.map(({ id, placeholder, name, datatest }) => (
                  <div
                    className={clsx(
                      'mb-2 flex flex-col',
                      id === 'Form.email'
                        ? 'col-span-9'
                        : id === 'Form.firstName'
                        ? 'col-span-4 col-start-1'
                        : 'col-span-4 col-start-6',
                    )}
                    key={id}
                  >
                    <Typography
                      variant="label-11-regular"
                      className="mb-1 text-neutral-300"
                    >
                      {intl.formatMessage({
                        id:
                          id === 'Form.email'
                            ? isEmailRequired
                              ? 'Form.requiredEmail'
                              : 'Form.optionalEmail'
                            : id,
                        defaultMessage: placeholder,
                        description: placeholder,
                      })}
                    </Typography>
                    <TextField
                      key={id}
                      name={name}
                      inputRef={register}
                      size="small"
                      data-test={datatest}
                      disabled={id === 'Form.email' ? !!userId : false}
                      fullWidth
                      variant="standard"
                      className={`w-full rounded ${
                        errors[name]
                          ? 'border-warning'
                          : 'border-mid focus-within:border-primary'
                      }`}
                      error={!!errors[name]}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        className: `p-2 rounded border border-solid text-[11px] ${
                          errors[name] ? 'border-warning' : 'border-gray-200'
                        }`,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-end space-x-3 py-3 px-8 pb-5">
            <Button
              onClick={onBack}
              className="p-1.25 border border-solid border-mid-300 bg-white px-4 text-13 text-mid-400 hover:bg-white"
            >
              <FormattedMessage id="Button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              data-test="add-new-user"
              onClick={handleSubmit((data) => {
                const { email, ...otherFields } = data;
                const formData: IUserFormData = otherFields;
                if (email) formData.email = email;

                onSubmit({
                  ...formData,
                  companyId: selectedCompanyId,
                });
              })}
            >
              <Typography variant="label-12-semibold">
                {userId ? (
                  'Save Changes'
                ) : (
                  <FormattedMessage
                    id={buttonMessage.id}
                    defaultMessage={buttonMessage.defaultMessage}
                  />
                )}
              </Typography>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default connector(forwardRef(CreateNewUser));
