import { useCallback } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
  CompletionStatus, CustomToolTip, Divider, Typography,
} from '../../../components';
import { CommonActionCountLayout } from '../../msp-view/milestone-details/task-common-layout';

interface ListItemInterface {
  itemDetail: string;
  dateRange: string;
  completedTasks: number;
  totalTasks: number;
  overdueTask: number;
  mspId: string
}

export default function ActivityMilestoneListItem({
  itemDetail,
  dateRange,
  completedTasks,
  totalTasks,
  overdueTask,
  mspId,
}: ListItemInterface) {
  const history = useHistory();

  const navigateToPlanPage = useCallback(
    (mspId: string) => {
      history.push(`/ui/alignx/msp/${mspId}/plan`);
    },
    [history],
  );

  return (
    <>
      <div className="grid grid-cols-12 px-8 pt-2 pb-2 hover:bg-gray-100 cursor-pointer" onClick={() => navigateToPlanPage(mspId)} role="presentation">
        <div className="col-span-7 flex items-center space-x-2">
          <CustomToolTip showTruncated arrow placement="top" title={itemDetail}>
            <div className="truncate">
              <Typography variant="body-13-semibold" className="col-span-5 text-[#384955] leading-4 w-full truncate">
                {itemDetail}
              </Typography>
            </div>
          </CustomToolTip>
          {overdueTask !== 0 && (
            <div className="col-span-2 pr-4">
              <CustomToolTip arrow placement="top" title={`${overdueTask} overdue tasks`}>
                <CommonActionCountLayout value={overdueTask} isDueDate isVisibleByDefault>
                  <FaExclamation fontSize={11} />
                </CommonActionCountLayout>
              </CustomToolTip>
            </div>
          )}
        </div>

        <div className="col-start-8 col-span-3">
          <div className="label-13-medium text-mid mr-8">
            {dateRange}
          </div>

        </div>
        <div className="col-start-11 col-span-2">
          <div className="flex items-center space-x-2 justify-end ">
            <CompletionStatus
              diameter={20}
              completionPercentage={
                (completedTasks / (totalTasks)) * 100
              }
              isAnalyticsProgress
            />

            <Typography variant="label-12-regular" className="font-medium text-mid">
              {`${completedTasks ?? 0}/${totalTasks ?? 0}`}
            </Typography>

          </div>

        </div>
      </div>
      <Divider />
    </>
  );
}
