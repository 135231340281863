import { ActivityIcon } from './activity-icon';

interface ActivityAvatarProps{
  userType: string;
  eventType: string;
}

function getBgColor(userType: string) {
  switch (userType) {
    case 'BUYER':
      return 'bg-green-400';
    case 'SELLER':
      return 'bg-primary-300';
    default:
      return 'bg-mid-300';
  }
}

export default function ActivityAvatar(props: ActivityAvatarProps) {
  const { userType, eventType } = props;

  return (
    <div className={`h-6 w-6 relative self-start mt-1 flex justify-center rounded-md ${getBgColor(userType)}`}>
      <div className="text-white flex items-center">
        <ActivityIcon eventType={eventType} />
      </div>
    </div>
  );
}
