import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createBrowserHistory } from 'history';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import { combineReducers } from 'redux';
// import 'redux-segment/typings/redux-segment';
import { createTracker } from 'redux-segment';
import allTasksReduxStore from '../../alignx/dashboard/all-tasks/all-tasks-redux-store';
// import { reducer as uppyReducer } from '@uppy/store-redux';
import appState from './reducers/app-reducer';
import createMspReducer from '../../alignx/create-msp/msp-creation-store';
import dashboardReducer from '../../alignx/dashboard/dashboard-redux-store';
import myTasksReduxStore from '../../alignx/dashboard/my-tasks/my-tasks-redux-store';
import newOrganizationReducer from '../../alignx/Header/new-organization-store';
import influencerMapNew from '../../alignx/influencer-map-new/influencer-redux-store';
import milestoneCreateReducer from '../../alignx/milestone-drawer/milestone-create-drawer/milestone-redux-store';
import commentReducer from '../../alignx/msp-view/comments/comments-redux-store';
import commentLoadingReducer from '../../alignx/msp-view/comments/loading-redux-store';
import milestoneReducer from '../../alignx/msp-view/milestone-details/milestone-redux-store';
import meddicReducer from '../../alignx/msp-view/msp-meddic/meddic-redux-store';
import mspReduxStore from '../../alignx/msp-view/msp-redux-store';
import notificationReducer from '../../alignx/msp-view/notification/notification-redux-store';
import resourceReducer from '../../alignx/msp-view/resources/resource-redux';
import shareMspReducer from '../../alignx/msp-view/share-workflow/share-msp-redux';
import stakeholderReducer from '../../alignx/msp-view/stakeholders/stakeholder-redux-store';
import mediaUploadingReducer from '../../alignx/msp-view/uploading-redux-store';
import saveIndicator from '../../alignx/save-indicator/save-redux';
import { api } from '../../api';
import selectedFilters from '../../components/filters/filter-redux';
import activityReducer from '../../features/analytics/reducers';
import authReducer from '../auth/auth-redux';
import entitySlice from './entity-store/entities-store';
import milestoneTemplateReducer from './reducers/milestone-template';
import notesReducer from './reducers/notes';
import searchComponentReducer from './reducers/search-user-reducer';
import userInfo from './reducers/user-details';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  entities: entitySlice,
  notes: notesReducer,
  milestoneTemplate: milestoneTemplateReducer,
  userInfo,
  milestoneDetail: milestoneReducer,
  filters: selectedFilters,
  saveIndicator,
  comments: commentReducer,
  commentLoading: commentLoadingReducer,
  mediaUploading: mediaUploadingReducer,
  shareMsp: shareMspReducer,
  msp: mspReduxStore,
  organization: newOrganizationReducer,
  dashboard: dashboardReducer,
  myTasks: myTasksReduxStore,
  allTasks: allTasksReduxStore,
  meddic: meddicReducer,
  mspStakeholder: stakeholderReducer,
  searchComponent: searchComponentReducer,
  notification: notificationReducer,
  // uppy: uppyReducer,
  resources: resourceReducer,
  auth: authReducer,
  createMsp: createMspReducer,
  [api.reducerPath]: api.reducer,
  activity: activityReducer,
  influencerMapNew,
  createMilestone: milestoneCreateReducer,
  appState,
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const tracker = createTracker();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'resources/addInProgressResource',
          'resources/addInProgressResource',
          'media-uploading/setMediaUploadingStatus',
          'media-uploading/addFileToUploads',
          'media-uploading/updateFileProgressInUploads',
          'media-uploading/removeFileFromUploads',
          'Analytics Event',
          'resources/updateErrorResource',
        ],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    }).concat([api.middleware, tracker]),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const state = store.getState();
