export function Divider({ className }: { className?: string }) {
  return (
    <div
      className={`border-0 border-t border-solid border-mid-50 ${
        className ?? ''
      }`}
    />
  );
}

export default Divider;
