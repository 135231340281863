import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { FileProgress, UppyFile } from '@uppy/core';

const mediaUploadingSlice = createSlice({
  name: 'media-uploading',
  initialState: { uploading: false, files: [] as UppyFile[], uploads: {} },
  reducers: {
    setMediaUploadingStatus: (
      state,
      action: PayloadAction<{
        uploading: boolean;
      }>,
    ) => {
      const { uploading } = action.payload;
      state.uploading = uploading;
    },
    addFileToUploads: (
      state,
      action: PayloadAction<{
        id: string;
        fileIDs: string[];
      }>,
    ) => {
      const { id, fileIDs } = action.payload;
      const filesProgress = {};
      fileIDs.forEach((fileID) => {
        filesProgress[fileID] = { progress: 0 };
      });

      state.uploads = filesProgress;
    },
    removeFileFromUploads: (
      state,
      actions: PayloadAction<{
        id: string;
      }>,
    ) => {
      const { id: fileID } = actions.payload;
      const currentUploads = state.uploads;
      delete currentUploads[fileID];
      state.uploads = currentUploads;
    },
    updateFileProgressInUploads: (
      state,
      action: PayloadAction<{
        file: UppyFile;
        progress: FileProgress;
      }>,
    ) => {
      const { file, progress } = action.payload;
      const newFile = { ...file, progress };
      state.uploads[file.fileId] = newFile;
    },
  },
});

export const {
  setMediaUploadingStatus,
  addFileToUploads,
  removeFileFromUploads,
  updateFileProgressInUploads,
} = mediaUploadingSlice.actions;

export default mediaUploadingSlice.reducer;
