/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../generated-types/index';

import { CoreErrorFieldsFragmentDoc } from '../../../buyer-experience/graphql/error.fragment.generated';
import { api } from 'api/base-api';
module.hot?.accept();
export type GetDashboardMspListQueryVariables = Types.Exact<{
  mspOperations?: Types.InputMaybe<Types.Operations>;
  pageConfiguration?: Types.InputMaybe<Types.PageConfiguration>;
}>;

export type GetDashboardMspListQuery = {
  __typename?: 'Query';
  alignxQuery?: {
    __typename?: 'AlignxQuery';
    dashboard:
      | {
          __typename: 'Alignx';
          msps:
            | {
                __typename: 'GraphqlError';
                code: string;
                message: string;
                type: Types.ErrorType;
                helpLink?: string;
              }
            | {
                __typename?: 'MspConnection';
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  hasPreviousPage: boolean;
                  afterCursor?: string;
                  beforeCursor?: string;
                };
                edges?: Array<{
                  __typename?: 'MspEdge';
                  node?:
                    | { __typename?: 'BaseMsp' }
                    | {
                        __typename: 'GraphqlError';
                        code: string;
                        message: string;
                        type: Types.ErrorType;
                        helpLink?: string;
                      }
                    | {
                        __typename?: 'Msp';
                        id: string;
                        name: string;
                        opportunity?:
                          | { __typename?: 'GraphqlError' }
                          | {
                              __typename?: 'Opportunity';
                              id: string;
                              name: string;
                              stageName?: string;
                              closeDate?: number;
                            };
                      };
                }>;
              };
        }
      | {
          __typename: 'GraphqlError';
          code: string;
          message: string;
          type: Types.ErrorType;
          helpLink?: string;
        };
  };
};

export const GetDashboardMspListDocument = `
    query GetDashboardMspList($mspOperations: Operations, $pageConfiguration: PageConfiguration) {
  alignxQuery {
    dashboard {
      __typename
      ...CoreErrorFields
      ... on Alignx {
        msps(operations: $mspOperations, pageConfiguration: $pageConfiguration) {
          ...CoreErrorFields
          ... on MspConnection {
            pageInfo {
              hasNextPage
              hasPreviousPage
              afterCursor
              beforeCursor
            }
            edges {
              node {
                ...CoreErrorFields
                ... on Msp {
                  id
                  name
                  opportunity {
                    ... on Opportunity {
                      id
                      name
                      stageName
                      closeDate
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${CoreErrorFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetDashboardMspList: build.query<
      GetDashboardMspListQuery,
      GetDashboardMspListQueryVariables | void
    >({
      query: (variables) => ({
        document: GetDashboardMspListDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetDashboardMspListQuery, useLazyGetDashboardMspListQuery } =
  injectedRtkApi;
