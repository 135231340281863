import { gql } from '@apollo/client';
import { CORE_GRAPH_ERROR } from '../../../../graphql/fragments/error';

export const GET_ROLE_LIST = gql`
  ${CORE_GRAPH_ERROR}
  query listRoles {
    roleQuery {
      listRoles {
        __typename
        ...CoreErrorFields
        ... on RoleConnection {
          edges {
            node {
              ... on Role {
                id
                name
                description
                orgId
                scopeId
                scopeType
                companyType
                isHidden
                order
                members {
                  ... on RoleMembers {
                    sellerMembers {
                      ... on Members {
                        stakeholders {
                          ... on Stakeholder {
                            id
                            firstName
                            lastName
                            email
                            primaryId
                            analytics {
                            ... on StakeholderAnalytics {
                              engagementCount {
                                ... on EngagementCount {
                                  viewCount
                                  downloadCount
                                  commentCount
                                }
                              }
                            }
                          }
                          }
                        }
                        placeholders {
                          ... on Placeholder {
                            id
                            name
                            description
                            orgId
                          }
                        }
                      }
                    }
                    buyerMembers {
                      ... on Members {
                        stakeholders {
                          ... on Stakeholder {
                            id
                            firstName
                            lastName
                            email
                            primaryId
                            analytics {
                            ... on StakeholderAnalytics {
                              engagementCount {
                                ... on EngagementCount {
                                  viewCount
                                  downloadCount
                                  commentCount
                                }
                              }
                            }
                          }
                          }
                        }
                        placeholders {
                          ... on Placeholder {
                            id
                            name
                            description
                            orgId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REORDER_ROLE = gql`
  ${CORE_GRAPH_ERROR}
  mutation reorderRole($roleId: ID!, $to: Int!) {
    roleMutation {
      reOrderRole(roleId: $roleId, to: $to) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;
