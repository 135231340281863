import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../redux-store/redux-types';
import { Typography } from '../../components';
import { BaCard } from '../../ba-components';

const ICON_CLASS = 'h-5';
const ICON_CONTAINER_CLASS = 'flex w-5 items-center justify-center';
interface INavItem {
  title: string;
  iconSrc?: string;
  iconComponent?: JSX.Element;
  isSelected?: boolean;
}

interface INavLink extends INavItem {
  pathname?: string;
  className?: string;
}

export const NavItem = ({
  title,
  iconSrc,
  iconComponent,
  isSelected,
}: INavItem) => {
  const isSidebarOpen = useSelector(
    (state: RootState) => state.appState.isSidebarOpen,
  );

  return (
    <div
      className={clsx(
        isSelected && 'bg-primary',
        'ba-transition-50',
        'relative mx-1.5 flex items-center space-x-3 rounded-10 p-3 hover:text-primary-300',
      )}
    >
      <div className={ICON_CONTAINER_CLASS}>
        {iconComponent || (
          <img
            src={iconSrc}
            alt={title + ' icon'}
            className={clsx(ICON_CLASS, !isSelected && 'opacity-70')}
          />
        )}
      </div>
      <Typography
        variant="body-13-regular"
        className={clsx(
          'truncate text-white',
          isSidebarOpen ? 'flex' : 'hidden',
        )}
      >
        {title}
      </Typography>
    </div>
  );
};
export function NavTitleCard({ title }: { title: string }) {
  return (
    <BaCard.Content
      bgColorClass=""
      side="right"
      paddingclass="p-0"
      sideOffset={10}
    >
      <Typography
        element="div"
        variant="body-13-regular"
        className="whitespace-nowrap rounded-md bg-neutral-700 py-2 px-4 text-white"
      >
        {title}
      </Typography>
    </BaCard.Content>
  );
}

export function NavLink({
  title,
  iconSrc,
  pathname,
  className,
  iconComponent,
}: INavLink) {
  const queryParams = new URLSearchParams(location.search).toString();
  const isSidebarOpen = useSelector(
    (state: RootState) => state.appState.isSidebarOpen,
  );
  const { pathname: currentPath } = useLocation();

  return (
    <BaCard.Root openDelay={0} closeDelay={0}>
      <BaCard.Trigger asChild>
        <div
          className={clsx(
            'flex cursor-pointer items-center hover:bg-white-opacity-10',
            className,
          )}
        >
          {pathname ? (
            <Link
              className="w-full no-underline"
              to={{
                pathname,
                search: queryParams,
              }}
            >
              <NavItem
                title={title}
                iconSrc={iconSrc}
                iconComponent={iconComponent}
                isSelected={pathname === currentPath}
              />
            </Link>
          ) : (
            <div>
              <NavItem
                title={title}
                iconSrc={iconSrc}
                iconComponent={iconComponent}
              />
            </div>
          )}
        </div>
      </BaCard.Trigger>
      {!isSidebarOpen && <NavTitleCard title={title} />}
    </BaCard.Root>
  );
}
