import { ApolloProvider } from '@apollo/client';
import type { Theme } from '@mui/material/styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useKeycloakContext } from './common/auth/authentication-context';
import newTheme from './common/newTheme';
import { BaTooltip } from './components';
import useApolloClient from './graphql/apollo-client';
import MobileView from './mobile-view';
import SnackbarComponent from './routing/snackbar';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ApolloApp = () => {
  const apolloClient = useApolloClient();
  const [isMobileView, setMobileView] = useState(isMobile);
  const { keycloak } = useKeycloakContext();

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', function (ev) {
      // if (document.visibilityState === 'visible')
      keycloak
        .updateToken(30)
        .then(() => console.log('token updated'))
        .catch((err) => console.error(err));
    });
  }, []);

  return (
    <div>
      <BaTooltip.Provider>
        {isMobileView ? (
          <MobileView setMobileView={setMobileView} />
        ) : (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={newTheme}>
              <ApolloProvider client={apolloClient}>
                <SnackbarComponent />
              </ApolloProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </BaTooltip.Provider>
    </div>
  );
};

export default ApolloApp;
