/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  GraphqlError,
  MspPermissionPayload,
  MspUserBridge,
} from '@buyerassist/graphql-generated-types';
import type { Dispatch } from '@reduxjs/toolkit';
import process from 'process';
import { FiLogOut as LogOut } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent } from '../../analytics';
import { BaPopover } from '../../ba-components';
import { useKeycloakContext } from '../../common/auth/authentication-context';
import { RootState } from '../../common/redux-store';
import { Divider, DropDownItem, NewAvatar, Typography } from '../../components';
import { DROP_DOWN_LAYOUT } from '../../components/drop-down/drop-down-item';

const mapStateToProps = (state: RootState) => ({
  mspId: state?.msp?.mspDetail?.id,
  userDetails: state.userInfo.userDetails,
  organization: state.organization,
  mspPermissions: (
    (state?.msp?.mspDetail?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items,
  isSidebarOpen: state.appState.isSidebarOpen,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      trackEvent,
    },
    dispatch,
  );
const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function UserMenu(props: PropsFromRedux) {
  const { organization, userDetails, trackEvent, mspId, isSidebarOpen } = props;
  const { keycloak, loginOptions } = useKeycloakContext();

  if (organization?.__typename === 'GraphqlError') {
    const error = organization as GraphqlError;
    throw new Error(`${error.code}: ${error.message}`);
  }

  const { name: userName, email, userType } = userDetails || {};

  const logoutHandler = () => {
    trackEvent('Logged out user', { user: userName, mspId });
    if (
      !(process.env.STAGE === 'local' && process.env.LOCAL_TO_REMOTE === 'mock')
    ) {
      keycloak?.logout({
        redirectUri: loginOptions.redirectUri,
      });
    }
  };

  const loginHandler = () => {
    trackEvent('Tried to log in');
    keycloak.login(loginOptions);
  };

  return (
    <BaPopover.Root>
      <BaPopover.Trigger asChild>
        <div
          className="flex cursor-pointer items-center space-x-3"
          role="presentation"
        >
          <NewAvatar
            className="cursor-pointer"
            fullName={userName}
            email={email}
            userType={userType}
          />
          {isSidebarOpen && (
            <Typography variant="body-13-regular" className="text-white">
              {userName}
            </Typography>
          )}
        </div>
      </BaPopover.Trigger>
      <BaPopover.Content side="top" paddingclass="p-0">
        <BaPopover.Arrow />
        <div className="z-10 flex w-72 flex-col justify-center">
          <div className={`${DROP_DOWN_LAYOUT} my-4`}>
            <NewAvatar fullName={userName} email={email} userType={userType} />
            <div className="flex flex-col space-y-0.5 truncate">
              <Typography
                variant="body-14-bold"
                className="truncate capitalize"
              >
                {userName}
              </Typography>
              <Typography
                variant="label-12-regular"
                className="truncate lowercase text-mid"
              >
                {email}
              </Typography>
            </div>
          </div>
          <Divider className="mx-3 mt-1" />
          <DropDownItem
            className="cursor-pointer rounded-10 py-3 text-error "
            onClick={keycloak.authenticated ? logoutHandler : loginHandler}
          >
            <LogOut className="text-16" />
            <Typography variant="body-13-regular" className="capitalize">
              <FormattedMessage id="User.signOut" defaultMessage="Sign Out" />
            </Typography>
          </DropDownItem>
        </div>
      </BaPopover.Content>
    </BaPopover.Root>
  );
}

export default connector(UserMenu);
