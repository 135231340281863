import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../../common/redux-store';

import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { trackEvent } from '../../../../analytics';
import { useGetTopOffsetWithClassName } from '../../../../common/hooks/useTopOffset';
import { AccountPlan, Operation, Operator } from '../../../../generated-types';
import {
  useListAccountPlansQuery,
  useSearchAccountPlanQuery,
} from './account-api';
import { RmapOverlayLoading, opportunityTableCols } from './table-config';

const mapState = (state: RootState) => ({
  searchRedux: state.dashboard.accountSearch,
  // selectedFilters: state?.filters?.selectedFilters?.['rmap-opportunity'],
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ trackEvent }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function OpportunityTable(props: PropsFromRedux) {
  const { searchRedux } = props;

  const { data, isLoading, isFetching, isError } = useListAccountPlansQuery({
    input: {},
  });

  const {
    data: searchData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    isError: isSearchError,
  } = useSearchAccountPlanQuery(
    {
      input: {
        operations: {
          filter: {
            operator: Operator.AND,
            filters: [
              {
                op: Operation.LIKE,
                field: 'accountPlan.searchField',
                values: [searchRedux ?? ''],
              },
            ],
          },
        },
      },
    },
    { skip: !searchRedux, refetchOnMountOrArgChange: true },
  );

  const topOffset = useGetTopOffsetWithClassName(
    'ba-rmap-account-table',
    isLoading,
  );

  const list = searchRedux
    ? (searchData as AccountPlan[])
    : (data as AccountPlan[]);

  return (
    <div
      className="ba-rmap-account-table w-full"
      style={{ height: window.innerHeight - topOffset }}
    >
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width - 2}
            height={height}
            loading={isLoading || isSearchLoading}
            data={list}
            overlayRenderer={
              <RmapOverlayLoading
                isNextPageLoading={isFetching || isSearchFetching}
                isLoading={isLoading || isSearchLoading}
                isEmpty={list?.length === 0}
                isError={isError || isSearchError}
              />
            }
            rowKey="tableKey"
            rowClassName="group"
          >
            {opportunityTableCols.map(({ dataKey, ...restProps }) => (
              // eslint-disable-next-line react/jsx-key
              <Column key={dataKey} dataKey={dataKey} {...restProps} />
            ))}
          </BaseTable>
        )}
      </AutoResizer>
    </div>
  );
}
//
export default connector(OpportunityTable);
