import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { BaSelect, Typography } from '../../../components';
import { TOption } from '../../../components/ba-select/common';

type UserCompanyProps = {
  options: {
    value: string;
    label: string;
    datatest: string;
    logo: string;
  }[];
  selectedCompanyId: string;
  userId?: string;
  setCompanyId: React.Dispatch<any>;
  companyId?: string;
};

function UserCompany(props: UserCompanyProps) {
  const {
    options: allOptions,
    selectedCompanyId,
    userId,
    setCompanyId,
    companyId,
  } = props;

  const options = companyId
    ? allOptions.filter(({ value }) => value === companyId)
    : allOptions;

  const tempOptions = options.map((option) => ({
    id: option.value,
    name: option.label,
    logo: option.logo,
  })) as unknown as TOption[];

  const onSelect = (option: TOption | null) => {
    if (option) setCompanyId(option.id);
  };

  const selectedItem = tempOptions.find(
    (option) => selectedCompanyId === option.id,
  ) as TOption;

  const isTabsDisabled = options?.length !== 1 && (!!userId || !!companyId);

  return (
    <>
      {userId ? (
        <div className="flex flex-col">
          <Typography
            variant="label-11-regular"
            className="mb-1 text-neutral-300"
          >
            <FormattedMessage
              id="Form.companyName"
              defaultMessage={'Company Name'}
            />
          </Typography>
          <BaSelect
            placeholder="Select Template"
            optionList={tempOptions}
            setSelected={onSelect}
            selectedItem={selectedItem}
            startIcon={
              selectedItem.logo ? (
                <img
                  src={selectedItem.logo}
                  className="mx-1 block h-3.5 bg-transparent text-white"
                  height={14}
                />
              ) : (
                <HiOutlineOfficeBuilding size={14} className="text-mid" />
              )
            }
            isNetworkError={false}
            inputClassName="h-9"
            disabled
          />
        </div>
      ) : (
        <Tabs
          value={selectedCompanyId}
          indicatorColor="primary"
          name="companyId"
          onChange={(_event: React.SyntheticEvent, value: string) =>
            !userId && !companyId ? setCompanyId(value) : null
          }
          disabled={isTabsDisabled}
          aria-label="msp share tabs"
          className="min-h-[36px] border-0 border-b border-solid border-mid-30 bg-white"
        >
          {options.map(({ value, datatest, label, logo }) => (
            <Tab
              key={value}
              label={label}
              value={value}
              data-test={datatest}
              icon={
                logo ? (
                  <img
                    alt="company-logo"
                    className="mx-1 block h-3.5 bg-transparent text-white"
                    src={logo}
                  />
                ) : (
                  <HiOutlineOfficeBuilding size={14} className="text-mid" />
                )
              }
              className={clsx(
                'semibold text-[11px] capitalize',
                isTabsDisabled ? 'cursor-default opacity-50' : '',
              )}
              sx={{
                minHeight: '20px !important',
                minWidth: '68px !important',
                padding: '12px 8px 12px 4px',
              }}
              iconPosition="start"
            />
          ))}
        </Tabs>
      )}
    </>
  );
}

export default UserCompany;
