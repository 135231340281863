import { useApolloClient } from '@apollo/client';
import { Skeleton, Tab, Tabs } from '@mui/material';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useState } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { getMspId } from '../../../common/methods/get-msp-id';
import { RootState } from '../../../common/redux-store';
import { Typography } from '../../../components';
import { DROP_DOWN_LAYOUT } from '../../../components/drop-down/drop-down-item';
import { BaResource, NotificationStatus } from '../../../generated-types';
import { fetchNotification } from './api-call';
import { useMarkedReadNotification } from './api-hooks';
import { NotificationTabType } from './models';
import NotificationFilter from './notification-filter';
import NotificationTable from './notification-list';
import { setBaResource } from './notification-redux-store';

const mapState = (state: RootState) => ({
  userId: state?.userInfo?.userDetails?.userId ?? '',
  newCount: state?.notification?.newCount,
  loading: state?.notification?.loading,
  notificationType:
    state?.notification?.notificationFilterDetails?.notificationType,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setBaResource,
      fetchNotification,
      trackEvent,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const tabList = [
  { label: 'Tasks', key: BaResource.TASK },
  { label: 'Comments', key: BaResource.COMMENTS },
  { label: 'Insights', key: BaResource.MSP },
];

function NotificationModal(props: PropsFromRedux) {
  const {
    userId,
    loading,
    notificationType,
    setBaResource,
    fetchNotification,
    trackEvent,
    newCount,
  } = props;

  const [selectedTab, setSelectedTab] = useState<string>('');
  const { markedReadNotification } = useMarkedReadNotification();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const mspId = getMspId(window.location.pathname);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    tabValue: BaResource,
  ) => {
    if (tabValue) {
      trackEvent(TRACKING_CONSTANTS.CLICKED_NOTIFICATION_BY_TYPE_TAB, {
        notificationFilterType: NotificationTabType.BY_TYPE,
        selectedTab: tabValue,
      });
      setSelectedTab(tabValue);
      setBaResource(tabValue);
      fetchNotification({
        client,
        userId,
        accountId: undefined,
        baResourceList: [{ type: tabValue }],
      });
    }
  };

  const onMarkedAllRead = () => {
    dispatch(
      trackEvent(TRACKING_CONSTANTS.NOTIFICATION_MARKED_ALL_AS_READ, {
        mspId,
        id: userId,
      }),
    );
    markedReadNotification(userId, NotificationStatus.READ);
  };

  return (
    <div className="min-h-full w-[460px] shadow-lg">
      <div
        className={clsx(
          'justify-between',
          DROP_DOWN_LAYOUT,
          notificationType === NotificationTabType.BY_TYPE ? 'mt-4' : 'my-4',
        )}
      >
        <div className="flex items-center space-x-2">
          <Typography variant="heading4" className="text-neutral-450">
            Notifications
          </Typography>
          <NotificationFilter setSelectedTab={setSelectedTab} />
        </div>
        {newCount ? (
          <Typography
            variant="body-13-medium"
            className="cursor-pointer text-mid-500 hover:text-primary"
            onClick={onMarkedAllRead}
          >
            Mark all as read
          </Typography>
        ) : null}
      </div>
      {notificationType === NotificationTabType.BY_TYPE && (
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="notification tabs"
          sx={{ minHeight: 36 }}
          className="mx-5 border-0 border-b border-solid border-gray-100"
        >
          {tabList?.map(({ key, label }) => (
            <Tab
              data-test={`notification-${key}`}
              key={key}
              label={label}
              value={key}
              sx={{
                minHeight: 36,
                padding: '8px 14px',
                minWidth: 68,
                fontSize: 13,
              }}
            />
          ))}
        </Tabs>
      )}
      {loading ? <NotificationLoader /> : <NotificationTable />}
    </div>
  );
}

function NotificationLoader() {
  return (
    <>
      {new Array(9).fill(0).map((_, index) => (
        <div className="flex space-x-4 py-4 px-6" key={index}>
          <Skeleton
            variant="circular"
            className="-mt-1"
            width={36}
            height={36}
            animation="wave"
          />
          <div className="flex flex-col space-y-1">
            <Skeleton variant="text" width={130} height={12} animation="wave" />
            <Skeleton variant="text" width={60} height={12} animation="wave" />
            <Skeleton variant="text" width={340} height={12} animation="wave" />
          </div>
        </div>
      ))}
    </>
  );
}

export default connector(NotificationModal);
