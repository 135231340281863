import dayjs from 'dayjs';
import { TaskStatus } from '../../generated-types';

export function handleDueDateDifference(
  date: string | number | undefined,
  taskStatus: TaskStatus,
) {
  const dueDateDifference =
    ((date && dayjs.unix(date as number).fromNow()) as string) ?? '';
  if (
    dueDateDifference &&
    dueDateDifference.includes('hours') &&
    taskStatus !== TaskStatus?.COMPLETED
  ) {
    return dueDateDifference;
  }
  return null;
}
