import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../../common/redux-store';

import { useCallback } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { trackEvent } from '../../../../analytics';
import { TRACKING_CONSTANTS } from '../../../../analytics/constants/trackingConstants';
import {
  IFilterConfig,
  IFilterDetails,
} from '../../../../components/filters/model';
import { Operation, Operator } from '../../../../generated-types';
import { IFilterQuery } from '../../api-call';
import {
  TOpportunityResponse,
  useGetDashboardMspListQuery,
  useGetOpportunityFiltersQuery,
  useLazyGetPaginatedMspListQuery,
} from '../rmap-dashboard-api';
import { RmapOverlayLoading, opportunityTableCols } from './table-config';
import { useGetTopOffsetWithClassName } from '../../../../common/hooks/useTopOffset';

export const getOpportunityFilterVariable = (
  searchTerm?: string,
  selectedFilters?: IFilterDetails,
  filterDetails?: IFilterConfig,
) => {
  const filters = [
    {
      op: Operation.NE,
      values: [''],
      field: 'msp.opportunityId',
    },
  ];
  const filterKeys = selectedFilters ? Object.keys(selectedFilters) : null;
  if (selectedFilters && filterKeys) {
    const selected = filterKeys.reduce((acc, key) => {
      const arr = selectedFilters[key];
      const fieldName = filterDetails && filterDetails[key]?.key;
      const values = arr
        .filter(({ isSelected }) => isSelected)
        .map(({ value }) => value);

      if (values.length) {
        acc.push({
          op: Operation.IN,
          values,
          field: fieldName,
        });
      }

      return acc;
    }, [] as IFilterQuery[]);
    filters.push(...selected);
  }
  if (searchTerm) {
    filters.push({
      op: Operation.LIKE,
      values: [searchTerm],
      field: 'opportunity.name',
    });
  }

  return {
    mspOperations: {
      filter: {
        operator: Operator.AND,
        filters,
      },
    },
    pageConfiguration: {
      limit: 30,
    },
  };
};

export const getOpportunityVariable = (afterCursor?: string) => {
  return {
    pageConfiguration: {
      limit: 30,
      ...(afterCursor ? { afterCursor } : {}),
    },
  };
};

const mapState = (state: RootState) => ({
  searchRedux: state.dashboard.opportunitySearch,
  selectedFilters: state?.filters?.selectedFilters?.['rmap-opportunity'],
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ trackEvent }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function OpportunityTable(props: PropsFromRedux) {
  const { searchRedux, selectedFilters } = props;

  const [
    fetchPaginatedOpportunity,
    { isFetching: paginationLoading, isError: pageError },
  ] = useLazyGetPaginatedMspListQuery();

  const { data } = useGetOpportunityFiltersQuery();

  const {
    opportunityList,
    isLoading,
    isFetching,
    hasNextPage,
    afterCursor,
    isError,
  } = useGetDashboardMspListQuery(
    getOpportunityFilterVariable(
      searchRedux,
      selectedFilters,
      data as IFilterConfig,
    ),
    {
      // refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching, isError, isLoading }) => {
        return {
          isLoading,
          isError,
          isFetching,
          opportunityList:
            (data as TOpportunityResponse)?.opportunityList || [],
          hasNextPage: (data as TOpportunityResponse)?.pageInfo?.hasNextPage,
          afterCursor: (data as TOpportunityResponse)?.pageInfo?.afterCursor,
        };
      },
    },
  );

  const handleEndReached = useCallback(async () => {
    if (hasNextPage) {
      trackEvent(TRACKING_CONSTANTS.LOADING_MORE_MSP);
      await fetchPaginatedOpportunity(getOpportunityVariable(afterCursor));
    }
  }, [hasNextPage, afterCursor]);

  const topOffset = useGetTopOffsetWithClassName(
    'ba-rmap-opportunity-table',
    isLoading,
  );

  return (
    <div
      className="ba-rmap-opportunity-table w-full"
      style={{ height: window.innerHeight - topOffset }}
    >
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width - 2}
            height={height}
            loading={isLoading || isFetching}
            data={opportunityList}
            overlayRenderer={
              <RmapOverlayLoading
                isNextPageLoading={paginationLoading || isFetching}
                isLoading={isLoading}
                isEmpty={opportunityList?.length === 0}
                isError={isError || pageError}
              />
            }
            rowKey="tableKey"
            onEndReachedThreshold={100}
            onEndReached={handleEndReached}
            rowClassName="group"
          >
            {opportunityTableCols.map(({ dataKey, ...restProps }) => (
              // eslint-disable-next-line react/jsx-key
              <Column key={dataKey} dataKey={dataKey} {...restProps} />
            ))}
          </BaseTable>
        )}
      </AutoResizer>
    </div>
  );
}
//
export default connector(OpportunityTable);
