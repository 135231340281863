import { gql } from '@apollo/client';
import {
  ASSETS_FRAGMENT,
  AUDIO_FRAGMENT,
  DOCUMENT_FRAGMENT,
  IMAGE_FRAGMENT,
  LINK_FRAGMENT,
  VIDEO_FRAGMENT,
} from '../../../graphql/fragments/resources-fragments';
import { CORE_GRAPH_ERROR } from '../../../graphql/fragments/error';

export const GET_MEDIA_FILTER = gql`
  query getMediaFilter($operations: Operations!) {
    mediaQuery {
      mediaFilters(operations: $operations) {
        __typename
        ... on FilterItemsPayload {
          items {
            key
            displayName
            values
            isVisible
          }
        }
      }
    }
  }
`;

export const MARK_INTERNAL = gql`
  ${CORE_GRAPH_ERROR}
  mutation ($input: MarkMediaInternalInput!) {
    mediaMutation {
      markMediaInternal(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          id
          success
        }
      }
    }
  }
`;

export const GET_RESOURCE_LIST = gql`
  ${CORE_GRAPH_ERROR}
  ${ASSETS_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${AUDIO_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${LINK_FRAGMENT}
  query getMspMedia($operations: Operations!) {
    mediaQuery {
      listMedia(operations: $operations) {
        ...CoreErrorFields
        ... on MediaConnection {
          edges {
            node {
              ...CoreErrorFields
              ... on MediaPayloadItem {
                item {
                  ... on Media {
                    id
                    name
                    orgId
                    createdBy {
                      ...CoreErrorFields
                      ... on User {
                        id
                        firstName
                        lastName
                        email
                        userType
                      }
                    }
                    description
                    mediaType
                    mimeType
                    createdAt
                    ...AssetsFragment
                    ...ImageFragment
                    ...AudioFragment
                    ...DocumentFragment
                    ...VideoFragment
                    ...LinkFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ATTACH_RESOURCE = gql`
  ${CORE_GRAPH_ERROR}
  ${ASSETS_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${AUDIO_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${LINK_FRAGMENT}
  mutation attachMedia($input: CreateAndAttachMediaInput!) {
    mediaMutation {
      createAndAttachMedia(input: $input) {
        __typename
        ...CoreErrorFields
        ... on MediaPayload {
          items {
            ...CoreErrorFields
            ... on MediaPayloadItem {
              item {
                ... on Media {
                  id
                  orgId
                  createdBy {
                    ...CoreErrorFields
                    ... on User {
                      id
                      firstName
                      lastName
                      email
                      userType
                    }
                  }
                  description
                  mediaType
                  mimeType
                  createdAt
                  ...AssetsFragment
                  ...ImageFragment
                  ...AudioFragment
                  ...DocumentFragment
                  ...VideoFragment
                  ...LinkFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;
