import { useApolloClient } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { ActionCreatorWithPayload, bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import {
  useCallback,
} from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { scroller, Element } from 'react-scroll';
import { getMspId } from '../../../common/methods/get-msp-id';
import { RootState } from '../../../common/redux-store';
import { usePaginationHooks } from '../../../common/utilities/utils';
import { Typography } from '../../../components';
import { MspPermissionPayload, MspUserBridge, NotificationStatus } from '../../../generated-types';
import { notificationPagination } from './api-call';
import { useMarkedReadNotification } from './api-hooks';
import { INotification } from './models';
import NotificationCard from './notification-card';
import { setNotificationCount } from './notification-redux-store';

const mapState = (state: RootState) => ({
  errorMessage: state?.notification?.error,
  totalCount: state?.notification?.totalCount,
  isNextPageLoading: state?.notification?.isNextPageLoading,
  notificationList: state?.notification?.notificationList,
  afterCursor: state?.notification?.pageInfo?.afterCursor,
  hasNextPage: state?.notification?.pageInfo?.hasNextPage,
  userId: state.userInfo.userDetails.userId ?? '',
  accountId: state.notification.notificationFilterDetails.accountId,
  baResourceList: state.notification.notificationFilterDetails.baResourceList,
  newNotificationCount: state.notification.newCount,
  mspPermissions: (
      (state?.msp?.mspDetail?.userBridge as MspUserBridge)
        ?.permissions as MspPermissionPayload
  )?.items,
});

const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      notificationPagination,
      setNotificationCount,
    },
    dispatch,
  );
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const OverlayLoading = (
  {
    isNextPageLoading, newNotificationCount, userId, setNotificationCount,
  }:
   { isNextPageLoading: boolean, newNotificationCount:number, userId: string, setNotificationCount: ActionCreatorWithPayload<number, string> },
) => {
  const dispatch = useDispatch();
  const { markedReadNotification } = useMarkedReadNotification();
  const mspId = getMspId(window.location.pathname);

  const onNewNotification = useCallback(() => {
    markedReadNotification(userId, NotificationStatus.ACKNOWLEDGED);
    dispatch(setNotificationCount(0));
    scroller.scrollTo('notification-placeholder', {
      duration: 200,
      delay: 80,
      smooth: true,
      containerId: `notifications:${mspId}`,
    });
  }, [dispatch, markedReadNotification, mspId, setNotificationCount, userId]);

  if (isNextPageLoading) {
    return (
      <div className="absolute bottom-0 w-full z-50">
        <div className="flex justify-center items-center">
          <div className="w-56 m-8 flex justify-center items-center space-x-6 p-3 py-3.5 shadow-100 border border-solid border-mid-100 bg-primary-50 rounded-md">
            <Typography className="text-primary" variant="label-12-semibold">Getting more Notifications...</Typography>
            <CircularProgress size={22} />
          </div>
        </div>
      </div>
    );
  }

  if (newNotificationCount) {
    return (
      <div className="absolute top-20 w-full cursor-pointer z-50">
        <div
          role="presentation"
          className="flex justify-center items-center"
          onClick={onNewNotification}
        >
          <div className="bg-primary-300 m-6 flex justify-center items-center px-3 p-2.5 shadow-100 border border-solid border-primary-300 rounded-md">
            <Typography className="text-white" variant="label-12-bold">
              {newNotificationCount}
              {' '}
              New Notification
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

function NotificationTable(props: PropsFromRedux) {
  const {
    isNextPageLoading,
    notificationList,
    afterCursor,
    notificationPagination,
    hasNextPage,
    errorMessage,
    userId,
    accountId,
    baResourceList,
    newNotificationCount,
    setNotificationCount,
  } = props;
  const client = useApolloClient();

  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      notificationPagination({
        client, afterCursor, userId, accountId, baResourceList: baResourceList?.[0] ? [{ type: baResourceList[0] }] : undefined,
      });
    }
  }, [accountId, afterCursor, baResourceList, client, hasNextPage, notificationPagination, userId]);

  const { setLastElement } = usePaginationHooks({ handleEndReached });

  const mspId = getMspId(window.location.pathname);

  if (errorMessage) {
    return (
      <div className="w-full notification-height-without-header flex items-center justify-center bg-opacity-50">
        <Typography className="text-mid-300" variant="heading2">Something went wrong.</Typography>
      </div>
    );
  }

  return (
    <Element name={`notifications:${mspId}`} id={`notifications:${mspId}`} className="notification-height-without-header overflow-y-auto border-0 border-t border-solid border-light-bg">
      <OverlayLoading
        isNextPageLoading={isNextPageLoading}
        newNotificationCount={newNotificationCount}
        userId={userId}
        setNotificationCount={setNotificationCount}
      />
      <Element
        name="notification-placeholder"
        id="notification-placeholder"
      />
      {notificationList.map((rowData, index) => (
        <Element
          key={rowData.userNotificationStatusId}
          name={rowData.userNotificationStatusId}
          id={rowData.userNotificationStatusId}
          className="border-0 border-b border-solid border-light-bg"
        >
          {(index === notificationList.length - 1) ? <div ref={setLastElement}><NotificationCard notification={rowData as INotification} /></div> : <NotificationCard notification={rowData as INotification} />}
        </Element>
      ))}
    </Element>
  );
}

export default connector(NotificationTable);
