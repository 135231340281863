import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IFilterConfig, IFilterDetails } from '../../components/filters/model';
import { AlignxQuery, Operation } from '../../generated-types';
import { GET_FILTERED_MSP_LIST, GET_MSP_FILTERS } from './graphql';

export type ResultMsp = {
  alignxQuery: AlignxQuery;
};
export interface IFilterQuery {
  op: Operation;
  values: (string | undefined)[];
  field: string | undefined;
}

export interface IMspThunkArgs {
  client: ApolloClient<object>;
  searchTerm?: string;
  selectedFilters?: IFilterDetails;
  filterDetails?: IFilterConfig;
  afterCursor?: string;
}

export const fetchMspList = createAsyncThunk(
  'dashboard/get-msp',
  async (
    {
      client,
      searchTerm,
      selectedFilters,
      filterDetails,
      afterCursor,
    }: IMspThunkArgs,
    { rejectWithValue },
  ) => {
    try {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const filterKeys = selectedFilters ? Object.keys(selectedFilters) : null;
      let filters = [] as IFilterQuery[];
      if (selectedFilters && filterKeys) {
        filters = filterKeys.reduce((acc, key) => {
          const arr = selectedFilters[key];
          const fieldName = filterDetails && filterDetails[key]?.key;
          const values = arr
            .filter(({ isSelected }) => isSelected)
            .map(({ value }) => value);

          if (values.length) {
            acc.push({
              op: Operation.IN,
              values,
              field: fieldName,
            });
          }

          return acc;
        }, [] as IFilterQuery[]);
      }
      if (searchTerm) {
        filters.push({
          op: Operation.LIKE,
          values: [searchTerm],
          field: 'msp.name',
        });
      }

      const { data } = await client.query<ResultMsp>({
        query: GET_FILTERED_MSP_LIST,

        variables: {
          pageConfiguration: {
            limit: 30,
            ...(afterCursor && { afterCursor }),
          },
          milestoneOperations: {
            filter: {
              operator: 'AND',
              filters: [
                {
                  op: 'LE',
                  values: [dayjs(currentDate).unix().toString()],
                  field: 'milestone.startDate',
                },
                {
                  op: 'GE',
                  values: [dayjs(currentDate).unix().toString()],
                  field: 'milestone.endDate',
                },
              ],
            },
          },
          ...((filterKeys || searchTerm) && {
            mspOperations: {
              filter: {
                operator: 'AND',
                filters,
              },
            },
          }),
        },
      });
      return data;
    } catch (error: unknown) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const fetchMspFilters = createAsyncThunk(
  'dashboard/get-msp-filters',
  async ({ client }: { client: ApolloClient<object> }, { rejectWithValue }) => {
    try {
      const { data } = await client.query<ResultMsp>({
        query: GET_MSP_FILTERS,
        variables: {},
      });
      return data;
    } catch (error) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);

export const mspPagination = createAsyncThunk(
  'dashboard/mspPagination',
  async (
    {
      client,
      afterCursor,
    }: {
      client: ApolloClient<object>;
      afterCursor?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultMsp>({
        query: GET_FILTERED_MSP_LIST,

        variables: {
          pageConfiguration: {
            limit: 30,
            afterCursor,
          },
        },
      });
      return data;
    } catch (error: unknown) {
      return rejectWithValue((error as Error)?.message);
    }
  },
);
