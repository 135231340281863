import { useApolloClient } from '@apollo/client';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useEffect } from 'react';
import { IoEyeOffOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import AddAssigneeSvg from '../../../assets/add-assignee.svg';
import { toolTip, viewOnlyToolTip } from '../../../common-language';
import { checkAttentionRequire } from '../../../common/methods/check-attention-require-date';
import { useDebounce } from '../../../common/methods/debounce';
import { handleDueDateDifference } from '../../../common/methods/due-date-difference';
import { getGetQueryParamsWithTaskId } from '../../../common/methods/get-query-params';
import { setUserSearchMspId } from '../../../common/redux-store/reducers/search-user-reducer';
import { BaDatePicker, BaModal, CustomToolTip } from '../../../components';
import { CommonAvatar } from '../../../components/avatar/new-avatar';
import {
  CalendarIcon,
  IoIosCloseCircleOutline,
} from '../../../components/icons';
import {
  TaskStatusIcon,
  TaskStatusMenu,
  useUpdateTaskStatus,
} from '../../../components/task-status-menu';
import { COMPONENTYPE } from '../../../components/task-status-menu/task-status-menu';
import { Typography } from '../../../components/typography';
import CustomWarning from '../../../components/warning-popover';
import {
  Assignee,
  Company,
  Msp,
  MspPermissionPayload,
  MspUserBridge,
  TaskStatus,
  User,
  UserResponse,
  UserType,
} from '../../../generated-types';
import { IPlaceholder } from '../../msp-view/model';
import { setDashboardRequiredDetails } from '../../msp-view/msp-redux-store';
import {
  AccountNameHeaderRenderer,
  AssigneeHeaderRenderer,
  headerRenderer,
  NameHeaderRenderer,
} from '../table-cells';
import AddAssignee from './add-assignee';
import { ITask } from './all-tasks-redux-store';
import {
  removeTaskAssignee,
  TaskType,
  updateAllTaskStatus,
  updateTaskDueDate,
} from './api-call';

const warningTitle = {
  id: 'WarningTitle.removeUserTitle',
  defaultMessage: 'Remove user',
};

const warningMessage = {
  id: 'WarningMessage.removeUserMsg',
  defaultMessage: 'Do you want to remove user?',
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 14px 10px rgba(0, 0, 0, 0.04);',
    borderRadius: '1px',
  },
}));

export const TaskNameCell = ({
  cellData,
  rowData,
}: {
  cellData: NonNullable<React.ReactNode>;
  rowData: ITask;
}) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const taskId = rowData.id;
  const { mspId } = rowData;
  const isTaskInternal = rowData?.isInternal;
  const optimisticTaskStatusRef = React.useRef(rowData.status);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const { handleTaskStatus, handleTaskStatusOnHover } = useUpdateTaskStatus();
  const mspPermissions = (
    ((rowData?.msp as Msp)?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items;
  let isViewModeEnabled = false;
  if (mspPermissions) {
    if (mspPermissions.length === 1 && mspPermissions[0] === 'VIEW') {
      isViewModeEnabled = true;
    }
  }
  const updateMyTask = useDebounce((newRequestedTaskStatus: TaskStatus) => {
    dispatch(
      trackEvent(TRACKING_CONSTANTS.TASK_STATUS_CHANGED_ALL_TASK, {
        mspId,
        newRequestedTaskStatus,
        taskId,
      }),
    );
    dispatch(
      updateAllTaskStatus({
        client,
        taskId,
        mspId,
        newRequestedTaskStatus,
        taskType: rowData.taskType,
        dispatch,
      }),
    );
  }, 300);
  useEffect(() => {
    if (rowData.status) {
      optimisticTaskStatusRef.current = rowData.status;
    }
  }, [rowData.status]);

  if (!rowData?.dueDate)
    return (
      <Typography
        variant="body-14-bold"
        className="w-full truncate pl-2 text-neutral"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      >
        {cellData}
        {/* {`${cellData} (${rowData.count})`} */}
      </Typography>
    );
  return (
    <>
      <IconButton
        id={taskId}
        disabled={!!isViewModeEnabled}
        onMouseEnter={(event) => handleTaskStatusOnHover(event, setAnchorEl)}
        onMouseLeave={(event) => handleTaskStatusOnHover(event, setAnchorEl)}
        onClick={(event) => {
          if (rowData.status === TaskStatus.COMPLETED) {
            setAnchorEl(event.currentTarget);
          } else {
            const newRequestedTaskStatus = handleTaskStatus(
              optimisticTaskStatusRef.current,
            );
            optimisticTaskStatusRef.current = newRequestedTaskStatus;
            updateMyTask(optimisticTaskStatusRef.current);
          }
        }}
        className="cursor-pointer"
      >
        <TaskStatusIcon taskStatus={rowData.status} />
      </IconButton>
      <BaModal
        popperOffSet={[0, -3]}
        placement="bottom-start"
        anchorEl={anchorEl}
        onClose={handleClose}
        setAnchorEl={setAnchorEl}
        borderRadiusClass="rounded-none"
      >
        <TaskStatusMenu
          componentType={COMPONENTYPE?.ALLTASK}
          onTaskStatusChange={(newRequestedTaskStatus) =>
            dispatch(
              updateAllTaskStatus({
                client,
                taskId,
                mspId,
                newRequestedTaskStatus,
                taskType: rowData.taskType,
                dispatch,
              }),
            )
          }
          setAnchorEl={setAnchorEl}
        />
      </BaModal>
      <Link
        to={{
          pathname: `/ui/alignx/msp/${rowData?.mspId}/plan`,
          search: getGetQueryParamsWithTaskId(rowData?.id),
        }}
        className="flex items-center truncate no-underline"
      >
        <div
          onClick={() => {
            dispatch(
              trackEvent(TRACKING_CONSTANTS.ACCESSED_MSP, {
                from: 'All Task',
                mspId: rowData?.mspId,
              }),
            );
          }}
          className="flex cursor-pointer items-center truncate no-underline"
        >
          <CustomToolTip showTruncated arrow placement="top" title={cellData}>
            <div className="w-full truncate">
              <Typography
                variant="body-13-semibold"
                className={`primary-highlight w-full truncate text-neutral ${
                  mspPermissions.length === 1 && mspPermissions[0] === 'VIEW'
                    ? 'opacity-50'
                    : ''
                }`}
              >
                {cellData}
              </Typography>
            </div>
          </CustomToolTip>
          {isTaskInternal && (
            <CustomToolTip
              placement="top"
              arrow
              title={toolTip['MspPlanView.markedInternalTask']}
            >
              <div className="ml-3 flex items-center">
                <IoEyeOffOutline fontSize={16} className="text-gray-500" />
              </div>
            </CustomToolTip>
          )}
        </div>
      </Link>
    </>
  );
};
const mspNameClass = 'text-neutral primary-highlight truncate w-full';
export const MspNameCell = ({
  cellData,
  rowData,
}: {
  cellData: NonNullable<React.ReactNode>;
  rowData: ITask;
}) => {
  const mspPermissions = (
    ((rowData?.msp as Msp)?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items;
  if (rowData?.id?.includes('error'))
    return (
      <Typography variant="body-13-semibold" className={mspNameClass}>
        {cellData}
      </Typography>
    );
  return (
    <Link
      to={`/ui/alignx/msp/${rowData?.mspId}/plan-view`}
      className="truncate no-underline"
    >
      <CustomToolTip arrow placement="top" title={cellData} showTruncated>
        <div>
          <Typography
            variant="body-13-regular"
            className={`${mspNameClass} ${
              mspPermissions.length === 1 && mspPermissions[0] === 'VIEW'
                ? 'opacity-50'
                : ''
            }`}
          >
            {cellData}
          </Typography>
        </div>
      </CustomToolTip>
    </Link>
  );
};

const NameCell = ({
  cellData,
  rowData,
}: {
  cellData: NonNullable<React.ReactNode>;
  rowData: ITask;
}) => {
  const mspPermissions = (
    ((rowData?.msp as Msp)?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items;
  return (
    <CustomToolTip arrow placement="top" title={cellData} showTruncated>
      <div className="truncate">
        <Typography
          variant="body-13-regular"
          className={`w-full truncate text-neutral ${
            mspPermissions?.length === 1 && mspPermissions?.[0] === 'VIEW'
              ? 'opacity-50'
              : ''
          }`}
        >
          {cellData}
        </Typography>
      </div>
    </CustomToolTip>
  );
};
const AssigneeCell = ({
  rowData,
}: {
  cellData: NonNullable<React.ReactNode>;
  rowData: ITask;
}) => {
  let user = rowData.assignee;
  const classes = useStyles();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const mspId = rowData?.mspId;
  const buyerCompany = (rowData?.msp as Msp)?.buyerCompany as Company;
  const sellerCompany = (rowData?.msp as Msp)?.sellerCompany as Company;
  const taskAssigneeType = rowData.assigneeType;
  if (
    user &&
    user?.__typename === 'Placeholder' &&
    taskAssigneeType === 'PLACEHOLDER'
  ) {
    const userName = (user as IPlaceholder)?.name.split('-');
    const firstName = `${userName?.[1]} - ${userName?.[0]}`;
    user = {
      id: (user as IPlaceholder)?.placeholderId,
      firstName,
      lastName: '',
      email: '',
      userType: user?.companyType,
    } as IPlaceholder;
  }
  const mspPermissions = (
    ((rowData?.msp as Msp)?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items;
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const [assigneeAnchorEl, setAssigneeAnchorEl] = React.useState<
    SVGSVGElement | HTMLElement | null
  >(null);
  const handleClose = useCallback(() => {
    setAssigneeAnchorEl(null);
  }, [setAssigneeAnchorEl]);
  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  let isViewModeEnabled = false;
  if (mspPermissions) {
    if (mspPermissions.length === 1 && mspPermissions[0] === 'VIEW') {
      isViewModeEnabled = true;
    }
  }
  const handleAddAssignee = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isViewModeEnabled) {
        setAssigneeAnchorEl(event.currentTarget as HTMLElement);
        dispatch(trackEvent(TRACKING_CONSTANTS.TASK_ASSIGNEE_CHANGED_ALL_TASK));
        dispatch(
          setDashboardRequiredDetails({ mspId, buyerCompany, sellerCompany }),
        );
        dispatch(setUserSearchMspId({ mspId }));
      }
    },
    [isViewModeEnabled, dispatch, mspId, sellerCompany, buyerCompany],
  );
  const onRemoveAssignee = useCallback((user: User) => {
    onClose();
    dispatch(
      removeTaskAssignee({
        client,
        taskId: rowData.id,
        mspId,
        taskType: rowData?.taskType,
        dispatch,
        user,
      }),
    );
  }, []);
  const onDelete = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (!isViewModeEnabled) {
        setAnchorEl(event.currentTarget);
      }
    },
    [],
  );

  const getUserFullName = (user: Assignee) => {
    let firstName = '';
    let lastName = '';
    const userFirstName =
      (user && (user as User)?.firstName) ||
      (user as User)?.email?.split('@')?.[0];
    const userLastName =
      (user && (user as User)?.lastName)?.split(' ').join('') ?? '';
    if (userFirstName && userFirstName.length > 0) {
      firstName =
        userFirstName?.[0]
          .toUpperCase()
          .concat(userFirstName.slice(1, userFirstName.length)) ?? '';
    }
    if (userLastName && userLastName.length > 0) {
      lastName =
        userLastName?.[0]
          .toUpperCase()
          .concat(userLastName.slice(1, userLastName.length)) ?? '';
    }
    return `${firstName} ${lastName}`;
  };

  if ((user as UserResponse)?.__typename === 'GraphqlError') {
    return (
      <div className="mr-4 flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
        <BrokenImageIcon color="disabled" />
      </div>
    );
  }
  return (
    <>
      {user && (user as User)?.id ? (
        <div
          className={`clear-assignee-parent group relative mr-1 flex w-full max-w-fit space-x-1 truncate ${
            mspPermissions.length === 1 && mspPermissions[0] === 'VIEW'
              ? 'opacity-50'
              : ''
          }`}
        >
          <div
            role="presentation"
            className={`${
              isViewModeEnabled ? 'cursor-default' : 'cursor-pointer'
            }`}
            onClick={handleAddAssignee}
            data-test="custom-assignee-avtar"
          >
            <CommonAvatar
              email={(user as User)?.email ?? ''}
              size="small"
              userType={(user as User)?.userType ?? UserType.BUYER}
              fullName={user && getUserFullName(user)}
            />
          </div>
          <div
            role="presentation"
            className={`${
              isViewModeEnabled ? 'cursor-default' : 'cursor-pointer'
            } mt-1 ml-2 w-full truncate`}
            onClick={handleAddAssignee}
            data-test="custom-assignee-avtar"
          >
            <Typography
              variant="body-13-regular"
              className="w-full truncate text-neutral"
            >
              {user && getUserFullName(user)}
            </Typography>
          </div>
          <span>
            {!isViewModeEnabled && (
              <IoIosCloseCircleOutline
                className={clsx(
                  'right-1.5 z-10 mt-1.5 hidden bg-transparent text-16  text-mid hover:text-error',
                  isViewModeEnabled
                    ? 'cursor-default hover:bg-none'
                    : 'cursor-pointer group-hover:flex',
                )}
                onClick={onDelete}
              />
            )}
          </span>
          <CustomWarning
            anchorEl={anchorEl}
            warningTitle={warningTitle}
            warningMessage={warningMessage}
            onSubmit={() => onRemoveAssignee(user)}
            onClose={onClose}
          />
        </div>
      ) : (
        <CustomToolTip
          arrow
          title={
            isViewModeEnabled
              ? viewOnlyToolTip['MspPlanView.addAssignee']
              : toolTip['MspPlanView.addAssignee']
          }
          placement="top-start"
        >
          {isViewModeEnabled ? (
            <div
              className={`cursor-default ${
                mspPermissions.length === 1 && mspPermissions[0] === 'VIEW'
                  ? 'opacity-50'
                  : ''
              }`}
            >
              <img alt="add-assignee" src={AddAssigneeSvg as string} />
            </div>
          ) : (
            <div
              role="presentation"
              aria-label="Add assignee"
              onClick={handleAddAssignee}
              data-test="add-assignee"
              className={`cursor-pointer ${
                mspPermissions?.length === 1 && mspPermissions?.[0] === 'VIEW'
                  ? 'opacity-50'
                  : ''
              }`}
            >
              <img alt="add-assignee" src={AddAssigneeSvg as string} />
            </div>
          )}
        </CustomToolTip>
      )}

      <Popover
        open={Boolean(assigneeAnchorEl)}
        anchorEl={assigneeAnchorEl}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <AddAssignee
          onClose={handleClose}
          taskId={rowData.id}
          mspId={rowData.mspId}
          taskType={TaskType.ALLTASKS}
          isTaskInternal={rowData.isInternal}
          sellerCompany={(rowData?.msp as Msp)?.sellerCompany as Company}
        />
      </Popover>
    </>
  );
};

export const DueDateCell = ({ rowData }: { rowData: ITask }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const taskStatus = rowData?.status;
  const handleDueDate = useCallback((date: Date, prevDate: Date) => {
    dispatch(
      trackEvent(TRACKING_CONSTANTS.UPDATED_TASK_DUE_DATE, {
        taskId,
        mspId: rowData?.mspId,
        from: 'All Task Tab',
      }),
    );
    if (!dayjs(date).isSame(dayjs(prevDate ?? 0))) {
      dispatch(
        updateTaskDueDate({
          client,
          dueDate: dayjs(date).endOf('day').unix(),
          taskId,
          mspId: rowData?.mspId,
          taskType: rowData?.taskType,
          dispatch,
        }),
      );
    }
  }, []);
  const showWarning =
    (taskStatus === TaskStatus.PENDING ||
      taskStatus === TaskStatus?.INPROGRESS) &&
    dayjs
      .unix(rowData?.dueDate ?? 0)
      .isBefore(dayjs().subtract(1, 'day').endOf('day')) &&
    !checkAttentionRequire(rowData?.dueDate, taskStatus);
  const taskId = rowData?.id;
  const mspPermissions = (
    ((rowData?.msp as Msp)?.userBridge as MspUserBridge)
      ?.permissions as MspPermissionPayload
  )?.items;
  let isViewModeEnabled = false;
  if (mspPermissions) {
    if (mspPermissions.length === 1 && mspPermissions[0] === 'VIEW') {
      isViewModeEnabled = true;
    }
  }
  if (!rowData?.dueDate) return null;
  return (
    <div
      className={`cursor-default ${
        mspPermissions.length === 1 && mspPermissions[0] === 'VIEW'
          ? 'opacity-50'
          : ''
      }`}
    >
      <BaDatePicker
        placeholder={
          handleDueDateDifference(rowData?.dueDate, rowData?.status) as string
        }
        selectedDate={dayjs.unix(rowData?.dueDate ?? 0)}
        format="MMM DD"
        handleDateChange={(date: Date, prevDate: Date) => {
          handleDueDate(date, prevDate);
        }}
        dropDownclassName="table-container"
        type="TASK_DATE"
        fontSize={13}
        disabled={!!isViewModeEnabled}
        isShowWarning={showWarning}
        pickerClassname={clsx(
          'all-task-table',
          showWarning && !isViewModeEnabled
            ? 'overdue-task'
            : checkAttentionRequire(rowData?.dueDate, rowData?.status)
            ? 'upcoming-due-task'
            : 'text-mid-500',
          '!p-0',
        )}
        isViewModeEnabled={isViewModeEnabled}
        suffixIcon={
          <div
            className={clsx(
              showWarning && !isViewModeEnabled
                ? 'overdue-task'
                : checkAttentionRequire(rowData?.dueDate, rowData?.status)
                ? 'upcoming-due-task'
                : 'text-mid-500',
              'mr-2',
            )}
          >
            <CalendarIcon fontSize="medium" />
          </div>
        }
      />
    </div>
  );
};

export const columnDefinition = [
  {
    dataKey: 'name',
    title: 'Task Name',
    width: '30%',
    cellRenderer: ({
      cellData,
      rowData,
    }: {
      cellData: NonNullable<React.ReactNode>;
      rowData: ITask;
    }) => <TaskNameCell cellData={cellData} rowData={rowData} />,
    headerRenderer: NameHeaderRenderer,
    flexGrow: 1,
  },
  {
    dataKey: 'companyName',
    title: 'Account Name',
    width: 100,
    headerRenderer: AccountNameHeaderRenderer,
    flexGrow: 1,
    cellRenderer: ({
      cellData,
      rowData,
    }: {
      cellData: NonNullable<React.ReactNode>;
      rowData: ITask;
    }) => <NameCell cellData={cellData} rowData={rowData} />,
  },
  {
    dataKey: 'dueDate',
    title: 'Due Date',
    width: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: { rowData: ITask }) => (
      <DueDateCell rowData={rowData} />
    ),
    headerRenderer,
  },
  {
    dataKey: 'assignee',
    title: 'Assignee',
    width: 100,
    flexGrow: 1,
    cellRenderer: ({
      cellData,
      rowData,
    }: {
      cellData: NonNullable<React.ReactNode>;
      rowData: ITask;
    }) => <AssigneeCell cellData={cellData} rowData={rowData} />,
    headerRenderer: AssigneeHeaderRenderer,
  },
  {
    dataKey: 'milestoneName',
    title: 'Milestone',
    width: 100,
    headerRenderer,
    cellRenderer: ({
      cellData,
      rowData,
    }: {
      cellData: NonNullable<React.ReactNode>;
      rowData: ITask;
    }) => <NameCell cellData={cellData} rowData={rowData} />,
    flexGrow: 1,
  },
];
