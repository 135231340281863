import { ActionType, GraphEdge, GraphNode } from '../../generated-types';
import { EContactCard, FGraphItemData } from './models';
import { v4 as uuid } from 'uuid';

export const reportsToTestData = {
  nodes: [
    {
      width: 280,
      height: 109,
      id: '1fbdb701-cfa0-481c-b343-120746329594',
      dragging: null,
      type: 'user',
      position: {
        x: 0,
        y: 200,
      },
      positionAbsolute: {
        x: 0,
        y: 200,
      },
      selected: null,
      data: {
        id: '8c61f8d3-a7e5-4d71-8398-6d2f7794cc17',
        dataPayload: [
          {
            key: 'CONTACT_CARD:REPORTS_TO',
            id: '649bf506b976f4000871c384',
            values: [
              {
                id: '614300a9ace8960008be5758',
                email: 'sumit.agarwal@buyerassist.io',
                firstName: 'Sumit',
                lastName: 'Agarwal',
                userCompaniesScope: [
                  {
                    companyId: '613fa10d9ae2dc3cfd06a877',
                    userType: 'SELLER',
                  },
                ],
                title: '',
              },
              {
                id: '6151adb433c43c00086009b0',
                email: 'buyerfourteen.skedulo@buyer.io',
                firstName: 'BuyerFourteen',
                lastName: 'Skedulo',
                userCompaniesScope: [
                  {
                    _id: '648af6d791e2e200080e5ffe',
                    companyId: '614dd5653ac03a0008f8c9f4',
                    userType: 'BUYER',
                  },
                ],
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:TITLE',
            id: '6151e5f2bfb5580008f49e13',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:NAME',
            id: '6151e5f2bfb5580008f49e13',
            values: [
              {
                value: 'BuyerNineteen Skedulo',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:LINKEDIN_URL',
            id: '6151e5f2bfb5580008f49e13',
            values: [
              {
                retry: false,
                queryTextInput: 'BuyerNineteen Skedulo  Skedulo',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:AVATAR',
            id: '6151e5f2bfb5580008f49e13',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:COMPANY_NAME',
            id: '614dd5653ac03a0008f8c9f4',
            values: [
              {
                value: 'Salesforce',
              },
            ],
            __typename: 'DataPayload',
          },
        ],
        metaData: {
          structureId: '95d752b3-8a1b-4ff5-8d06-fc78bb2f1260',
          structureType: 'CONTACT_CARD',
          __typename: 'GraphItemDataMetaData',
        },
        __typename: 'GraphItemData',
      },
      __typename: 'GraphNode',
      sourcePosition: 'bottom',
      targetPosition: 'top',
    },
    {
      width: 280,
      height: 109,
      id: '36a807c6-5aa2-4731-af44-fd982078b0b1',
      dragging: false,
      type: 'user',
      position: {
        x: 296.99999999999994,
        y: 600,
      },
      positionAbsolute: {
        x: 296.99999999999994,
        y: 600,
      },
      selected: false,
      data: {
        id: '793965bc-a1fb-4957-a018-5ddd3d8eeeca',
        dataPayload: [
          {
            key: 'CONTACT_CARD:REPORTS_TO',
            id: '649bf4ffb976f4000871c382',
            values: [
              // {
              //     id: '613fa1a89534cf0009d3c338',
              //     email: 'gaurav.panday@buyerassist.io',
              //     firstName: 'Gaurav',
              //     lastName: 'Panday',
              //     userCompaniesScope: [
              //         {
              //             companyId: '613fa10d9ae2dc3cfd06a877',
              //             userType: 'SELLER'
              //         }
              //     ],
              //     title: 'F7'
              // },
              // {
              //     id: '6151adb433c43c00086009b4',
              //     email: 'buyersixteen.skedulo@buyer.io',
              //     firstName: 'BuyerSixteen',
              //     lastName: 'Skedulo',
              //     userCompaniesScope: [
              //         {
              //             _id: '648af6d791e2e200080e600b',
              //             companyId: '614dd5653ac03a0008f8c9f4',
              //             userType: 'BUYER'
              //         }
              //     ]
              // }
              {
                id: '62c2e3a6433178000805aeec',
                email: 'buyersixteen.skedulo@buyer.io',
                firstName: 'Report Con 4',
                lastName: 'Report Con 4',
                userCompaniesScope: [
                  {
                    _id: '648af6d791e2e200080e600b',
                    companyId: '614dd5653ac03a0008f8c9f4',
                    userType: 'BUYER',
                  },
                ],
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:TITLE',
            id: '6151e5f3bfb5580008f49e1b',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:NAME',
            id: '6151e5f3bfb5580008f49e1b',
            values: [
              {
                value: 'BuyerTwentyOne Skedulo',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:LINKEDIN_URL',
            id: '6151e5f3bfb5580008f49e1b',
            values: [
              {
                retry: false,
                queryTextInput: 'BuyerTwentyOne Skedulo  Skedulo',
                searchEngine: 'DuckDuckGo',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:AVATAR',
            id: '6151e5f3bfb5580008f49e1b',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:COMPANY_NAME',
            id: '614dd5653ac03a0008f8c9f4',
            values: [
              {
                value: 'Salesforce',
              },
            ],
            __typename: 'DataPayload',
          },
        ],
        metaData: {
          structureId: '5d6e9ecf-b497-4aa6-be3e-d8ccaec7a005',
          structureType: 'CONTACT_CARD',
          __typename: 'GraphItemDataMetaData',
        },
        __typename: 'GraphItemData',
      },
      __typename: 'GraphNode',
      sourcePosition: 'bottom',
      targetPosition: 'top',
    },
    {
      width: 280,
      height: 109,
      id: 'c6b1bfe6-7345-4104-ad2d-ecc7472df08d',
      dragging: false,
      type: 'user',
      position: {
        x: 296.99999999999994,
        y: 800,
      },
      positionAbsolute: {
        x: 296.99999999999994,
        y: 800,
      },
      selected: true,
      data: {
        id: '5f628719-5e82-499f-91a5-80ad43ef8231',
        dataPayload: [
          {
            key: 'CONTACT_CARD:CONTACT_ROLE',
            id: '646b1e018c738f0008e860d0',
            values: [
              {
                id: '646b1e018c738f0008e860d0',
                value: 'Decision Maker',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:SENTIMENTS',
            id: '649283e9770de9000890bbb5',
            values: [
              {
                value: 'Positive',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:REPORTS_TO',
            id: '649bf548b976f4000871c38a',
            values: [
              {
                id: '6151e5f3bfb5580008f49e1b',
                email: 'buyertwentyone.skedulo@buyer.io',
                firstName: 'BuyerTwentyOne',
                lastName: 'Skedulo',
                userCompaniesScope: [
                  {
                    _id: '648af6d891e2e200080e601b',
                    companyId: '614dd5653ac03a0008f8c9f4',
                    userType: 'BUYER',
                  },
                ],
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:TITLE',
            id: '644b5c8c52cf9b0008321dd5',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:NAME',
            id: '644b5c8c52cf9b0008321dd5',
            values: [
              {
                value: 'HS ONE MAIN',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:LINKEDIN_URL',
            id: '644b5c8c52cf9b0008321dd5',
            values: [
              {
                profileUrl: 'https://www.linkedin.com/in/ron-sortino-10a46529',
                name: 'Ron Sortino',
                title: ' Assistant Manager ',
                avatarUrl:
                  'https://media.licdn.com/dms/image/C5603AQF32x0jTQ38CA/profile-displayphoto-shrink_800_800/0/1655235467089?e=2147483647&v=beta&t=JmNaWZejNVews06sFBBDH2uSI8ZQKt5Ki0jh1h5kHbo',
                summary:
                  "At OneMain, as Assistant Manager I empower customers – listening to their needs and providing access to friendly, fast and affordable financing for life's expenses. | Learn more about Ron Sortino's work experience, education, connections & more by visiting their profile on LinkedIn",
                queryTextInput: 'HS ONE MAIN  a',
                retry: false,
                value: 'https://www.linkedin.com/in/ron-sortino-10a46529',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:AVATAR',
            id: '644b5c8c52cf9b0008321dd5',
            values: [
              {
                value:
                  'https://media.licdn.com/dms/image/C5603AQF32x0jTQ38CA/profile-displayphoto-shrink_800_800/0/1655235467089?e=2147483647&v=beta&t=JmNaWZejNVews06sFBBDH2uSI8ZQKt5Ki0jh1h5kHbo',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:COMPANY_NAME',
            id: '6440bd1b0008f600080e1cb0',
            values: [
              {
                value: 'a',
              },
            ],
            __typename: 'DataPayload',
          },
        ],
        metaData: {
          structureId: '0a755b99-83cc-4275-8081-bff97b8539c4',
          structureType: 'CONTACT_CARD',
          __typename: 'GraphItemDataMetaData',
        },
        __typename: 'GraphItemData',
      },
      __typename: 'GraphNode',
      sourcePosition: 'bottom',
      targetPosition: 'top',
    },
    {
      width: 280,
      height: 109,
      id: '838bc279-84a1-4f9c-ad22-4ed38e5d75df',
      dragging: null,
      type: 'user',
      position: {
        x: 296.99999999999994,
        y: 400,
      },
      positionAbsolute: {
        x: 296.99999999999994,
        y: 400,
      },
      selected: null,
      data: {
        id: '9c78cf48-a8e6-4277-bc85-8372cae62c88',
        dataPayload: [
          {
            key: 'CONTACT_CARD:CONTACT_ROLE',
            id: '646b1dfc8c738f0008e860cc',
            values: [
              {
                id: '646b1dfc8c738f0008e860cc',
                value: 'Business User',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:KEY_STAKEHOLDER',
            id: '646c79fccbca050008f89a5c',
            values: [
              {
                value: false,
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:ASSIGNEES',
            id: '649199fbc7a6cd00082b074a',
            values: [
              {
                value: 'K SIXTEEN SELLER MAIN STAGING',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:REPORTS_TO',
            id: '649e96604dd6b1000811d867',
            values: [
              //   {
              //     id: '6151e5f2bfb5580008f49e13',
              //     email: 'buyernineteen.skedulo@buyer.io',
              //     firstName: 'BuyerNineteen',
              //     lastName: 'Skedulo',
              //     userCompaniesScope: [
              //       {
              //         _id: '648af6d791e2e200080e600e',
              //         companyId: '614dd5653ac03a0008f8c9f4',
              //         userType: 'BUYER',
              //       },
              //     ],
              //   },
              {
                id: '644b5c8c52cf9b0008321dd5',
                email: 'buyernineteen.skedulo@buyer.io',
                firstName: 'BuyerNineteen',
                lastName: 'Skedulo',
                userCompaniesScope: [
                  {
                    _id: '648af6d791e2e200080e600e',
                    companyId: '614dd5653ac03a0008f8c9f4',
                    userType: 'BUYER',
                  },
                ],
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:TITLE',
            id: '644b74916245240008ac17d1',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:NAME',
            id: '644b74916245240008ac17d1',
            values: [
              {
                value: 'HS TWO MAIN',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:LINKEDIN_URL',
            id: '644b74916245240008ac17d1',
            values: [
              {
                profileUrl: 'https://www.linkedin.com/in/johnryanmiller2989',
                name: 'John Ryan Miller',
                title: ' ASCM ',
                avatarUrl:
                  'https://media.licdn.com/dms/image/C4E03AQGIuC8qGOeblQ/profile-displayphoto-shrink_800_800/0/1517556941395?e=2147483647&v=beta&t=9WZGNj-DpPdzjFJVvYnltauxh7u9UIRfBHLHMCfbOeU',
                summary:
                  'View John Ryan Miller’s profile on LinkedIn, the world’s largest professional community. John Ryan has 1 job listed on their profile. See the complete profile on LinkedIn and discover John Ryan’s connections and jobs at similar companies.',
                queryTextInput: 'HS TWO MAIN  shshshshshshshshshs',
                retry: false,
                value: 'https://www.linkedin.com/in/johnryanmiller2989',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:AVATAR',
            id: '644b74916245240008ac17d1',
            values: [
              {
                value:
                  'https://media.licdn.com/dms/image/C4E03AQGIuC8qGOeblQ/profile-displayphoto-shrink_800_800/0/1517556941395?e=2147483647&v=beta&t=9WZGNj-DpPdzjFJVvYnltauxh7u9UIRfBHLHMCfbOeU',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:COMPANY_NAME',
            id: '644b6bf8d4b8920008c06b78',
            values: [
              {
                value: 'shshshshshshshshshs',
              },
            ],
            __typename: 'DataPayload',
          },
        ],
        metaData: {
          structureId: '0a755b99-83cc-4275-8081-bff97b8539c4',
          structureType: 'CONTACT_CARD',
          __typename: 'GraphItemDataMetaData',
        },
        __typename: 'GraphItemData',
      },
      __typename: 'GraphNode',
      sourcePosition: 'bottom',
      targetPosition: 'top',
    },
    {
      width: 280,
      height: 109,
      id: '08487cc7-7188-4efe-9c15-6077be512d19',
      dragging: false,
      type: 'user',
      position: {
        x: 296.99999999999994,
        y: 1000,
      },
      positionAbsolute: {
        x: 296.99999999999994,
        y: 1000,
      },
      selected: true,
      data: {
        id: 'dcd68a7b-a000-4842-aedb-5c23b27c1ab7',
        dataPayload: [
          {
            key: 'CONTACT_CARD:CONTACT_ROLE',
            id: '646b1df58c738f0008e860c8',
            values: [
              {
                id: '646b1df58c738f0008e860c8',
                value: 'Champion',
              },
              {
                id: '649186b336932300084628df',
                value: 'End User',
              },
              {
                id: '649186b336932300084628e0',
                value: 'Evaluator',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:SENTIMENTS',
            id: '6490639fa2c3ff00083b2144',
            values: [
              {
                value: 'Positive',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:KEY_STAKEHOLDER',
            id: '646c7aabbd50090008fdb0cc',
            values: [
              {
                value: true,
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:ASSIGNEES',
            id: '649185a536932300084628d2',
            values: [
              {
                value: 'test2 User',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:TITLE',
            id: '644cba764a80050008686965',
            values: [{}],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:NAME',
            id: '644cba764a80050008686965',
            values: [
              {
                value: 'K THREE MAIN STAGING',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:LINKEDIN_URL',
            id: '644cba764a80050008686965',
            values: [
              {
                profileUrl:
                  'https://www.linkedin.com/in/shelly-koehler-346a0523',
                name: 'Shelly Koehler',
                title: ' Founder & Creative Director ',
                avatarUrl:
                  'https://media.licdn.com/dms/image/C4D03AQEHjKbhxqnVZA/profile-displayphoto-shrink_800_800/0/1517050073389?e=2147483647&v=beta&t=WHW40KPp_ud4p8xPJmkJpiPeXUGplu9deedhiq8rT8k',
                summary:
                  "Over 20 years in and around Real Estate in many different roles.  Customer Service has always been top priority and making sure I do all I can to make sure my brand, company etc. stand over the standards of excellence.<br><br>Experienced Realtor with a demonstrated history of success in the real estate industry including coaching new agents into a wonderful new career, and leadership. Skilled in Negotiation, Luxury Sales & Goods, leasing, Operations, and Customer Relationship Management (CRM). Strong sales professional who loves people. | Learn more about Shelly Koehler's work experience, education, connections & more by visiting their profile on LinkedIn",
                queryTextInput: 'K THREE MAIN STAGING  k.com',
                retry: false,
                value: 'https://www.linkedin.com/in/shelly-koehler-346a0523',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:AVATAR',
            id: '644cba764a80050008686965',
            values: [
              {
                value:
                  'https://media.licdn.com/dms/image/C4D03AQEHjKbhxqnVZA/profile-displayphoto-shrink_800_800/0/1517050073389?e=2147483647&v=beta&t=WHW40KPp_ud4p8xPJmkJpiPeXUGplu9deedhiq8rT8k',
              },
            ],
            __typename: 'DataPayload',
          },
          {
            key: 'CONTACT_CARD:COMPANY_NAME',
            id: '644cb84a62687f0008f23e0c',
            values: [
              {
                value: 'k.com',
              },
            ],
            __typename: 'DataPayload',
          },
        ],
        metaData: {
          structureId: 'e3987714-bf72-4992-b431-f6837e0dc33d',
          structureType: 'CONTACT_CARD',
          __typename: 'GraphItemDataMetaData',
        },
        __typename: 'GraphItemData',
      },
      __typename: 'GraphNode',
      sourcePosition: 'bottom',
      targetPosition: 'top',
    },
  ],
  edges: [
    {
      markerEnd: {
        type: 'arrowclosed',
      },
      type: 'orgLine',
      id: '36a807c6-5aa2-4731-af44-fd982078b0b1->c6b1bfe6-7345-4104-ad2d-ecc7472df08d',
      source: '36a807c6-5aa2-4731-af44-fd982078b0b1',
      sourceHandle: null,
      style: null,
      target: 'c6b1bfe6-7345-4104-ad2d-ecc7472df08d',
      targetHandle: null,
      data: null,
      __typename: 'GraphEdge',
    },
    {
      markerEnd: {
        type: 'arrowclosed',
      },
      type: 'orgLine',
      id: '1fbdb701-cfa0-481c-b343-120746329594->838bc279-84a1-4f9c-ad22-4ed38e5d75df',
      source: '1fbdb701-cfa0-481c-b343-120746329594',
      sourceHandle: null,
      style: null,
      target: '838bc279-84a1-4f9c-ad22-4ed38e5d75df',
      targetHandle: null,
      data: null,
      __typename: 'GraphEdge',
    },
    // {
    //     markerEnd: {
    //         type: 'arrowclosed'
    //     },
    //     type: 'orgLine',
    //     id: '36a807c6-5aa2-4731-af44-fd982078b0b1->838bc279-84a1-4f9c-ad22-4ed38e5d75df',
    //     source: '838bc279-84a1-4f9c-ad22-4ed38e5d75df',
    //     sourceHandle: null,
    //     style: null,
    //     target: '36a807c6-5aa2-4731-af44-fd982078b0b1',
    //     targetHandle: null,
    //     data: null,
    //     __typename: 'GraphEdge'
    // },
    {
      markerEnd: {
        type: 'arrowclosed',
      },
      type: 'orgLine',
      id: '36a807c6-5aa2-4731-af44-fd982078b0b1->838bc279-84a1-4f9c-ad22-4ed38e5d75df',
      source: '838bc279-84a1-4f9c-ad22-4ed38e5d75df',
      sourceHandle: null,
      style: null,
      target: '36a807c6-5aa2-4731-af44-fd982078b0b1',
      targetHandle: null,
      data: null,
      __typename: 'GraphEdge',
    },
    {
      markerEnd: {
        type: 'arrowclosed',
      },
      type: 'orgLine',
      id: '08487cc7-7188-4efe-9c15-6077be512d19->c6b1bfe6-7345-4104-ad2d-ecc7472df08d',
      source: 'c6b1bfe6-7345-4104-ad2d-ecc7472df08d',
      sourceHandle: null,
      style: null,
      target: '08487cc7-7188-4efe-9c15-6077be512d19',
      targetHandle: null,
      data: null,
      __typename: 'GraphEdge',
    },
  ],
};

export function checkForReportsToChange({
  nodes = [],
  edges = [],
}: {
  nodes: GraphNode[] | undefined;
  edges: GraphEdge[] | undefined;
}) {
  const nodeToUserIdMap = nodes.map((node) => {
    const payload = (node?.data as FGraphItemData)?.dataPayload;
    const user = payload?.find((item) => item?.key === EContactCard.NAME);
    const userId = user?.id;

    return { userId, nodeId: node?.id };
    // return { userId, nodeId: node.id, name: user?.values?.[0]?.value };
  });

  const edgeList = nodes?.reduce((acc, node) => {
    const dataPayload = (node?.data as FGraphItemData)?.dataPayload;

    const manager = dataPayload?.find(
      (item) => item?.key === EContactCard.REPORTS_TO,
    );
    // const lastIndex = (manager?.values?.length || 1) - 1;
    const managerDetails = manager?.values?.[0] as { id: string };
    const managerId = managerDetails?.id;

    const manNode = nodeToUserIdMap.find(({ userId: u }) => u === managerId);
    if (manNode) {
      const manNodeId = manNode.nodeId;
      const edgeInfo = {
        target: node.id,
        source: manNodeId,
      };
      acc.push(edgeInfo);
    }

    return acc;
  }, [] as { target: string; source: string }[]);

  const { orgLines, influencerLines } = edges?.reduce(
    (acc, edge) => {
      if (edge?.type === 'orgLine') {
        acc.orgLines.push(edge);
      } else {
        acc.influencerLines.push(edge);
      }
      return acc;
    },
    { orgLines: [] as GraphEdge[], influencerLines: [] as GraphEdge[] },
  );
  const newEdgeList = edgeList?.reduce((acc, edge) => {
    const { target, source } = edge;
    const matchingEdge = orgLines?.find(
      ({ target: t, source: s }) => t === target && s === source,
    );

    if (matchingEdge) {
      acc.push(matchingEdge);
    } else {
      const newEdge = {
        id: uuid(),
        source,
        target,
        type: 'orgLine',
        actionType: ActionType.CREATE,
      };
      acc.push(newEdge);
    }
    return acc;
  }, [] as GraphEdge[]);

  return ([] as GraphEdge[]).concat(newEdgeList.concat(influencerLines));

  // const reportsToChangedEdges = edgeList.filter(({ target, source }) => {
  //   const matchingEdge = orgLines.find(({ target: t, source: s }) => {
  //     return t === target && s === source;
  //   });
  //   return !matchingEdge;
  // });

  // const targetIdList = reportsToChangedEdges.map(({ target }) => target);
  // const sourceIdList = reportsToChangedEdges.map(({ source }) => source);

  // const filterEdgeList = orgLines
  //   .filter(({ target = '' }) => !targetIdList.includes(target))
  //   .filter(({ target = '' }) => !sourceIdList.includes(target));

  // const newEdgeList: GraphEdge[] = reportsToChangedEdges.map(
  //   ({ target, source }) => {
  //     return {
  //       id: `${source}->${target}`,
  //       source,
  //       target,
  //       type: 'orgLine',
  //       actionType: ActionType.CREATE,
  //     };
  //   },
  // );

  // return ([] as GraphEdge[]).concat(
  //   filterEdgeList.concat(newEdgeList).concat(influencerLines),
  // );
}

// checkForReportsToChange({
//   nodes: reportsToTestData.nodes,
//   edges: reportsToTestData.edges,
// });
