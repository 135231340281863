/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  ApolloClient,
  ApolloLink,
  Observable,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { KeycloakInstance } from 'keycloak-js';
import { useKeycloakContext } from '../common/auth/authentication-context';
import { getToken } from '../common/auth/authentication-service';
import { getMspId } from '../common/methods/get-msp-id';
import { isJwtExpired } from '../common/methods/jwt-expired';
import { getAPIUrl, orgIdExtractor } from '../utils';

const apiUrl = getAPIUrl();

const orgId = orgIdExtractor();
const httpLink = new HttpLink({ uri: apiUrl });

const authMiddleware = new ApolloLink((operation, forward) => {
  const mspId = getMspId(window.location.pathname);

  const mspContext: Record<string, string> = operation.getContext();

  const mspid: string = mspContext?.currentMspId
    ? mspContext?.currentMspId
    : mspId;

  const token = getToken();
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        SellerOrgId: orgId,
        ...(mspid && { MspId: mspid }),
      },
    }));
  } else {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        SellerOrgId: orgId,
        ...(mspId && { MspId: mspId }),
      },
    }));
  }
  return forward(operation);
});

const uspMiddleware = new ApolloLink((operation, forward) => {
  const mspId = getMspId(window.location.pathname);
  const uspToken = mspId ? sessionStorage.getItem(mspId) : false;

  try {
    if (uspToken && !isJwtExpired(uspToken)) {
      operation.setContext(
        ({ headers = {} }: { headers: { [key: string]: string } }) => {
          const { ...restHeaders } = headers;
          return {
            headers: {
              ...(uspToken ? { UspToken: uspToken, ...restHeaders } : headers),
            },
          };
        },
      );
    }
  } catch (error) {
    throw new Error(`[Apollo] Error: ${error as string}`);
  }

  return forward(operation);
});

const updateTokenMiddleware = (
  keycloak: KeycloakInstance,
  // loginOptions: KeycloakLoginOptions,
) =>
  new ApolloLink((operation, forward) => {
    if (keycloak.token) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        keycloak.updateToken(30).then(() => forward(operation));
      } catch {
        // keycloak.login(loginOptions);
      }
    }
    return forward(operation);
  });

const connections: { [key: string]: any } = {};

const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // Set x-CSRF token (not related to abort use case)
      const context = operation.getContext();
      /** Final touch to cleanup */

      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        },
      });

      const cleanUp = () => {
        connectionHandle?.unsubscribe();
        delete connections[context.requestTrackerId];
      };

      if (context.requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...(context?.fetchOptions as Record<string, unknown>),
          },
        });

        if (connections[context.requestTrackerId]) {
          // If a controller exists, that means this operation should be aborted.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          connections[context.requestTrackerId]?.abort();
        }

        connections[context.requestTrackerId] = controller;
      }

      return connectionHandle;
    }),
);

const getApolloClient = (keycloak: KeycloakInstance) => {
  const middlewares = [
    updateTokenMiddleware(keycloak),
    authMiddleware,
    uspMiddleware,
    cancelRequestLink,
    httpLink,
  ];
  const instance = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([...middlewares]),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    // queryDeduplication: false,
  });

  return instance;
};

const useApolloClient = () => {
  const { keycloak } = useKeycloakContext();
  const client = getApolloClient(keycloak);

  return client;
};

export default useApolloClient;
