/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated-types/index';

module.hot?.accept();
export type CoreErrorFieldsFragment = {
  __typename: 'GraphqlError';
  code: string;
  message: string;
  type: Types.ErrorType;
  helpLink?: string;
};

export const CoreErrorFieldsFragmentDoc = `
    fragment CoreErrorFields on GraphqlError {
  __typename
  code
  message
  type
  helpLink
}
    `;
