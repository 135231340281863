/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { capitalizeString } from '../../../common/utilities/utils';
import { IFilterConfig, TFilterItem } from '../../../components/filters/model';
import {
  BaseMilestone,
  BaseMsp,
  BooleanObject,
  Company, FilterItemsCount, FilterItemsCountPayload, FilterItemsPayload,
  FilterType,
  GraphqlError, Maybe, PageInfo, Task, TaskConnection, TaskEdge, TaskPayload, TaskStatus, User,
} from '../../../generated-types';
import {
  fetchAllTasksFilters, fetchAllTasksList, ResultAllTasks, ResultAllTasksFilters, TaskType, updateAllTaskStatus, updateTaskDueDate,
  updateTaskAssignee,
  taskPagination,
  removeTaskAssignee,
  fetchAllTasksFiltersCount,
} from './api-call';
import { BoxFilterType, BoxFilterFields, BoxFilterDisplayName } from '../models';

export interface GqlError extends GraphqlError{
  id: string;
}

export type ITaskResponse = GqlError | ITask;
interface IDefaultAllTasks{
    allTasks: {
      taskList: ITaskResponse[];
    }
    allTasksLoading:boolean;
    searchText: string;
    filterDetails: IFilterConfig;
    filterLoading: boolean;
    filterError: unknown;
    pageInfo: null | PageInfo
    isNextPageLoading:boolean
    isFilterCountLoading:boolean
    additionalBoxFilters:{ [key in BoxFilterType]: BoxFilterFields}
    allTaskFilterCount: Record<string, FilterItemsCount>
    filterCountIds:string []
}
export interface ITask extends Task {
  mspName:string;
  milestoneName: string;
  companyName: string;
  mspId: string;
  taskType: TaskType,
  count?: number,
}

const defaultMyTasks:IDefaultAllTasks = {
  allTasks: {
    taskList: [],
  },
  allTasksLoading: true,
  searchText: '',
  filterDetails: {} as IFilterConfig,
  filterLoading: true,
  filterError: undefined as unknown,
  isNextPageLoading: false,
  isFilterCountLoading: false,
  pageInfo: null,
  additionalBoxFilters: {} as { [key in BoxFilterType]: BoxFilterFields},
  allTaskFilterCount: {},
  filterCountIds: [],
};

const displayNameBoxFilterMapper = {
  [BoxFilterDisplayName.assigneedToMeFilters]: { boxFilterType: BoxFilterType?.assigneedToMeFilters, iconType: 'assigneeIcon' },
  [BoxFilterDisplayName.overdueFilters]: { boxFilterType: BoxFilterType?.overdueFilters, iconType: 'overdueIcon' },
  [BoxFilterDisplayName.dueTodayFilters]: { boxFilterType: BoxFilterType?.dueTodayFilters, iconType: 'calendarIcon' },
  [BoxFilterDisplayName.dueSevenDaysFilters]: { boxFilterType: BoxFilterType?.dueSevenDaysFilters, iconType: 'calendarIcon' },
} as Record<string, {boxFilterType:BoxFilterType, iconType:string}>;

export const datakeyMap: { [key: string]: string } = {
  'msp.buyerCompanyId': 'AccountName',
};
const taskStatusListKeyMapper = {
  [TaskStatus?.PENDING]: 'task-pending',
  [TaskStatus?.INPROGRESS]: 'task-progress',
  [TaskStatus?.COMPLETED]: 'task-completed',
} as any;

const DEFAULT_FILTER_COLUMNS = {
  filterDetails: {} as IFilterConfig,
};
const getUserFullName = (user:User) => {
  let firstName = ''; let lastName = '';
  const userFirstName = ((user && (user as User)?.firstName) || (user as User)?.email?.split('@')?.[0]).split(' ').join('');
  const userLastName = (user && (user as User)?.lastName)?.split(' ').join('') ?? '';
  if (userFirstName && userFirstName.length > 0) {
    firstName = userFirstName?.[0].toUpperCase().concat(userFirstName.slice(1, userFirstName.length)) ?? '';
  }
  if (userLastName && userLastName.length > 0) {
    lastName = (userLastName?.[0].toUpperCase().concat(userLastName.slice(1, userLastName.length))) ?? '';
  }
  return `${firstName} ${lastName}`;
};

function transformFilterResponse(response: ResultAllTasksFilters) {
  try {
    const allTaskFilters = response?.taskQuery.allTaskFilters;
    if (
      allTaskFilters?.__typename === 'GraphqlError'
    ) {
      return DEFAULT_FILTER_COLUMNS;
    }
    const additionalBoxFilters = {} as { [key in BoxFilterType]: BoxFilterFields };
    const filterDetails = (allTaskFilters as FilterItemsPayload)?.items?.reduce((accumulator: IFilterConfig, current) => {
      const {
        displayName, key, values, isVisible, filterType, id,
      } = current;

      if (key && displayName && values && isVisible) {
        if (filterType === FilterType?.DROP_DOWN_FILTER) {
          const val = {
            key,
            labelInfo: {
              title: displayName,
            },
            trackEventMessage: `Clicked ${displayName}`,
            list: values?.reduce((acc, cur) => {
              const { id, displayName, email } = cur;
              if (id && displayName) {
                acc.push({
                  value: id,
                  label: displayName,
                  isSelected: false,
                  listKey: key === 'task.status' ? taskStatusListKeyMapper[id] : null,
                });
              }
              if (id && email) {
                acc.push({
                  value: id,
                  label: getUserFullName(cur as User),
                  isSelected: false,
                  listKey: 'msp.buyerCompanyId',
                  additionalFilterDetails: cur,
                });
              }

              return acc as TFilterItem[];
            }, [] as TFilterItem[]),
          };
          accumulator[displayName] = val;
        }
        if (filterType === FilterType?.QUICK_FILTER) {
          const { boxFilterType, iconType } = displayNameBoxFilterMapper[displayName];
          additionalBoxFilters[boxFilterType as BoxFilterType] = {
            isSelected: false, field: key, value: key, label: displayName, filterType: boxFilterType, iconType, id: id as string,
          };
        }
      }
      return accumulator;
    }, {} as IFilterConfig) ?? {};
    return { filterDetails, additionalBoxFilters };
  } catch (err) {
    return DEFAULT_FILTER_COLUMNS;
  }
}

function transformAllTasksResponse(response: ResultAllTasks, taskType:TaskType) {
  try {
    const allTasksResultList = response?.taskQuery?.allTasksWithFilters;
    if (allTasksResultList?.__typename === 'GraphqlError') {
      return [];
    }
    const edges = ((allTasksResultList as TaskConnection)?.edges) as TaskEdge[];
    const pageInfo = ((allTasksResultList as TaskConnection)?.pageInfo);

    const tasks = [] as Array<ITaskResponse>;
    for (let i = 0; i < edges?.length; i += 1) {
      const node = edges[i]?.node;

      if (node?.__typename === 'GraphqlError'
    || (node as Task)?.milestone?.__typename === 'GraphqlError'
    || (node as Task)?.msp?.__typename === 'GraphqlError') {
        const id = uuid();
        const graphQLError = (node as GraphqlError) || ((node as Task)?.milestone as GraphqlError)
      || ((node as Task)?.msp as GraphqlError);
        tasks.push({ id, ...graphQLError });
      } else {
        const { name: milestoneName, id: milestoneId } = ((node as Task)?.milestone as BaseMilestone);
        const { name: mspName, buyerCompany: Company, id: mspId } = ((node as Task)?.msp as BaseMsp);

        tasks.push({
          ...node, milestoneId, milestoneName, mspName, companyName: capitalizeString((Company as Company)?.displayName), mspId, taskType,
        } as ITask);
      }
    }
    return { tasks, pageInfo };
  } catch (error:unknown) {
    return { tasks: [{ id: `error${uuid()}`, name: 'Something went wrong' }], pageInfo: null };
  }
}

const allTasksSlice = createSlice({
  name: 'milestone-template',
  initialState: {
    ...defaultMyTasks,
    loading: false,
    error: undefined as unknown,
  },
  reducers: {
    setSearchText: (state, action: PayloadAction<Maybe<string>>) => {
      state.searchText = action.payload ?? '';
    },
    setBoxFilterDetails: (state, action: PayloadAction<{filterType:BoxFilterType}>) => {
      const { filterType } = action.payload;
      state.additionalBoxFilters[filterType].isSelected = !state.additionalBoxFilters[filterType].isSelected;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTasksList.fulfilled, (state, { payload }) => {
        const { data, taskType } = payload;
        const { tasks, pageInfo } = transformAllTasksResponse(data, taskType) as {tasks:any, pageInfo:any};
        state.allTasks.taskList = tasks;
        state.pageInfo = pageInfo;
        state.allTasksLoading = false;
      })
      .addCase(fetchAllTasksList.pending, (state) => {
        state.allTasksLoading = true;
      })
      .addCase(fetchAllTasksList.rejected, (state, { payload }) => {
        state.error = payload;
        state.allTasksLoading = false;
      })
      .addCase(taskPagination.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const taskType = TaskType?.ALLTASKS;
        const { tasks, pageInfo } = transformAllTasksResponse(data, taskType) as {tasks:any, pageInfo:any};
        state.allTasks.taskList = state.allTasks.taskList.concat(tasks);
        state.pageInfo = pageInfo;
        state.isNextPageLoading = false;
      })
      .addCase(taskPagination.pending, (state) => {
        state.isNextPageLoading = true;
      })
      .addCase(taskPagination.rejected, (state, { payload }) => {
        state.error = payload;
        state.isNextPageLoading = false;
      })
      .addCase(fetchAllTasksFilters.fulfilled, (state, { payload }) => {
        const { filterDetails, additionalBoxFilters } = transformFilterResponse(payload);
        state.filterDetails = filterDetails;
        state.additionalBoxFilters = additionalBoxFilters;
        const ids = Object.values(additionalBoxFilters as { [key in BoxFilterType]: BoxFilterFields}).map(({ id }) => id);
        state.filterCountIds = ids;
        state.filterLoading = false;
      })
      .addCase(fetchAllTasksFilters.pending, (state) => {
        state.filterLoading = true;
      })
      .addCase(fetchAllTasksFilters.rejected, (state, { payload }) => {
        state.filterError = payload;
        state.filterLoading = false;
      })
      .addCase(fetchAllTasksFiltersCount.fulfilled, (state, { payload }) => {
        if (payload?.taskQuery?.allTaskFiltersCount?.__typename !== 'GraphqlError') {
          const result = (payload?.taskQuery?.allTaskFiltersCount as FilterItemsCountPayload)?.items;
          const allTaskCountMapper = {} as Record<string, FilterItemsCount>;
          result?.forEach((item) => { allTaskCountMapper[item.id as string] = item; });
          state.allTaskFilterCount = allTaskCountMapper;
        }
        state.isFilterCountLoading = false;
      })
      .addCase(fetchAllTasksFiltersCount.pending, (state) => {
        state.isFilterCountLoading = true;
      })
      .addCase(fetchAllTasksFiltersCount.rejected, (state, { payload }) => {
        state.filterError = payload;
        state.isFilterCountLoading = false;
      })
      .addCase(updateTaskDueDate.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const resultTask = ((data?.taskMutation?.updateTask as TaskPayload)?.items?.[0] as Task);
        if (resultTask) {
          const tasks = state.allTasks.taskList;
          const index = tasks.findIndex((task) => (task as Task)?.id === resultTask?.id);
          if (index > -1) {
            const taskObj = tasks[index] as Task;
            taskObj.dueDate = resultTask?.dueDate;
          }
        }
        state.loading = false;
      })
      .addCase(updateTaskDueDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTaskDueDate.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateAllTaskStatus.fulfilled, (state, { payload }) => {
        const { data, newRequestedTaskStatus } = payload;
        const tasks = state.allTasks.taskList;
        if (tasks) {
          const index = tasks.findIndex((task) => (task as Task)?.id === (data?.taskMutation?.changeTaskStatus as BooleanObject)?.id);
          if (index > -1) {
            const taskObj = tasks[index] as Task;
            taskObj.status = newRequestedTaskStatus as TaskStatus;
          }
        }

        state.loading = false;
      })

      .addCase(updateAllTaskStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAllTaskStatus.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateTaskAssignee.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTaskAssignee.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateTaskAssignee.fulfilled, (state, { payload }) => {
        const {
          user, taskId,
        } = payload;
        const tasks = state.allTasks.taskList;
        const index = tasks.findIndex((task) => (task as Task)?.id === taskId);
        if (index > -1) {
          const taskObj = tasks[index] as Task;
          taskObj.assignee = user;
        }
        state.loading = false;
      })
      .addCase(removeTaskAssignee.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeTaskAssignee.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(removeTaskAssignee.fulfilled, (state, { payload }) => {
        const {
          taskId,
        } = payload;
        const tasks = state.allTasks.taskList;
        const index = tasks.findIndex((task) => (task as Task)?.id === taskId);
        if (index > -1) {
          const taskObj = tasks[index] as Task;
          taskObj.assignee = undefined;
        }
        state.loading = false;
      });
  },
});

export const { setSearchText, setBoxFilterDetails } = allTasksSlice.actions;
export default allTasksSlice.reducer;
