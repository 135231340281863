import { gql, useLazyQuery, useMutation } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useHistory } from 'react-router-dom';
import { Typography } from '../../../components/typography';
import { trackEvent } from '../../../analytics';
import { message } from '../../../common-language/index';
import { useDebounce } from '../../../common/methods/debounce';
import ErrorPages from '../../../common/methods/error-index';
import { RootState } from '../../../common/redux-store';
import { CustomToolTip } from '../../../components/tool-tip/tooltip';
import {
  GraphqlError,
  MspMutationLinkOpportunityArgs,
  BooleanObject,
  MspConnection,
  Msp,
  AlignxQuery,
  Alignx,
  MspQuery,
  MspResponse,
} from '../../../generated-types';
import { CORE_GRAPH_ERROR } from '../../../graphql/fragments/error';
import { setMspList } from '../msp-redux-store';
import { EMspSaveIndicator, LinkOpportunityMspResult } from '../model';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import CustomWarning from '../../../components/warning-popover';
import { CheckIcon } from '../../../components/icons';
import { showToast } from '../../../components/hooks/use-toastify';
import CircularIndeterminate from '../../../components/circular-loader';

const warningTitle = {
  id: 'WarningTitle.msp',
  defaultMessage: 'Link MSP',
};

export const LIST_MSP_TO_LINK_CRM = gql`
  ${CORE_GRAPH_ERROR}
  query {
    mspQuery {
      listMspsToLinkInCRM {
        ...CoreErrorFields
        ... on MspConnection {
          edges {
            node {
              __typename
              ...CoreErrorFields
              ... on Msp {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const LINK_OPPORTUNITY = gql`
  ${CORE_GRAPH_ERROR}
  mutation UpdateMspOpportunity($input: LinkOpportunityInput!) {
    mspMutation {
      linkOpportunity(input: $input) {
        __typename
        ...CoreErrorFields
        ... on BooleanObject {
          success
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey['50'],
  },
}));

function includesTerm(input: string, term: string) {
  try {
    return input?.toLowerCase()?.includes(term?.toLowerCase());
  } catch {
    return input;
  }
}

export type ResultMspQuery = {
  mspQuery: MspQuery;
  alignxQuery: AlignxQuery;
};
interface MspObj {
  mspList: Msp[] | undefined;
  filterMspList: Msp[] | undefined;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setMspList,
      addToSavingList,
      removeFromSavingList,
    },
    dispatch,
  );

const mapStateToProps = (state: RootState) => ({
  mspList: state?.msp?.mspList,
  mspId: state?.msp?.mspDetail?.id,
  userId: state?.userInfo?.userDetails?.userId,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SearchMsp(props: PropsFromRedux) {
  const [linkOpportunity] = useMutation<
    LinkOpportunityMspResult,
    MspMutationLinkOpportunityArgs
  >(LINK_OPPORTUNITY);

  const [selectedMspDetails, setMspDetails] = useState<Msp | null>();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const { setMspList, addToSavingList, removeFromSavingList } = props;
  const classes = useStyles();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [msp, setMsp] = useState<MspObj>({
    mspList: [],
    filterMspList: [],
  });
  const { filterMspList = [], mspList = [] } = msp;

  const { opportunityId } = useParams<{ opportunityId: string }>();
  const [getMsp, { loading, error, data }] = useLazyQuery<ResultMspQuery>(
    LIST_MSP_TO_LINK_CRM,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        if (res?.mspQuery?.listMspsToLinkInCRM?.__typename !== 'GraphqlError') {
          const mspList =
            (res?.mspQuery?.listMspsToLinkInCRM as MspConnection)?.edges?.map(
              (edge) => edge?.node as Msp,
            ) ?? [];
          setMspList(mspList);
          setMsp({
            mspList,
            filterMspList: mspList,
          });
        }
      },
    },
  );

  const debouncedListFilter = useDebounce((list: Msp[], term: string) => {
    const filteredList =
      list &&
      list.filter(({ name }) => {
        if (includesTerm(name, term)) return true;
        return false;
      });
    setMsp({ filterMspList: filteredList, mspList: list });
  }, 500);

  const onSelectingMsp = useCallback(async () => {
    try {
      const { id: mspId } = selectedMspDetails as Msp;
      trackEvent(TRACKING_CONSTANTS.MSP_SELECTED, {
        selectedMspDetails,
      });
      if (mspId) {
        // TODO: Account Id is calculated from backend but still is a required input so hardcoding
        const resp = await linkOpportunity({
          context: {
            headers: {
              MspId: mspId,
            },
          },
          variables: {
            input: { mspId, opportunityId, accountId: 'accountId' },
          },
        });
        if (
          resp.data?.mspMutation.linkOpportunity.__typename === 'GraphqlError'
        ) {
          throw new Error('Msp Could Not Be Linked');
        } else if (
          (resp.data?.mspMutation.linkOpportunity as BooleanObject).success
        ) {
          removeFromSavingList(EMspSaveIndicator.OPPORTUNITY_MSP_UPDATE);
          history.push(`/ui/alignx/msp/${mspId}/plan-view`);
        }
      }
    } catch {
      showToast(
        <FormattedMessage
          id="MspPlanView.mspUpdateAPIError"
          defaultMessage="Msp could not be linked with current opportunity. Please try again"
        />,
        {
          variant: 'error',
        },
      );
      removeFromSavingList(EMspSaveIndicator.OPPORTUNITY_MSP_UPDATE);
    }
  }, [
    selectedMspDetails,
    linkOpportunity,
    opportunityId,
    removeFromSavingList,
    history,
  ]);

  const onClose = useCallback(() => {
    setMspDetails(null);
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSubmit = useCallback(async () => {
    addToSavingList({
      saving: true,
      id: EMspSaveIndicator.OPPORTUNITY_MSP_UPDATE,
    });
    setAnchorEl(null);
    await onSelectingMsp();
  }, [addToSavingList, setAnchorEl, onSelectingMsp]);

  const warningMessage = {
    id: 'WarningTitle.msp',
    defaultMessage: `Are you sure you want to assign this msp ${
      selectedMspDetails?.name as string
    } to this Opportunity?`,
  };

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== null)
      debouncedListFilter(mspList, searchTerm);
  }, [searchTerm, mspList, debouncedListFilter]);

  useEffect(() => {
    if (props.mspList.length > 0) {
      setMsp({
        mspList: props.mspList,
        filterMspList: props.mspList,
      });
    }
  }, []);

  useEffect(() => {
    getMsp();
  }, [getMsp]);

  if (loading) return <CircularIndeterminate />;
  if (
    error ||
    data?.mspQuery?.listMspsToLinkInCRM?.__typename === 'GraphqlError' ||
    (data?.mspQuery?.listMspsToLinkInCRM as MspResponse)?.__typename ===
      'GraphqlError'
  ) {
    return (
      <ErrorPages
        errorCode={(data?.mspQuery?.listMspsToLinkInCRM as GraphqlError)?.code}
      />
    );
  }

  return (
    <>
      <div className="create-success-container w-auto overflow-y-auto px-3">
        <div className="relative mt-4 w-full">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
            className="w-full rounded border border-0 border-solid border-gray-300 bg-primary-50 py-3 pl-10 text-gray-600 placeholder-gray-500 shadow focus:outline-none focus:ring-offset-indigo-400"
            placeholder={message['MspPlanView.searchMspPlaceHolder']}
          />
          <SearchIcon className="absolute top-2 left-2 text-gray-500" />
        </div>
        {filterMspList.length === 0 ? (
          <div className="my-4 w-full text-center text-base text-gray-400">
            <Typography className="text-mid-300" variant="heading2">
              <FormattedMessage
                id="Dashboard.blankMsgMspTitle"
                defaultMessage="No matching MSPs found"
              />
            </Typography>
            <Typography className="text-mid-300" variant="body-14-regular">
              <FormattedMessage
                id="Dashboard.blankMsgMspDescription"
                defaultMessage="Modify the search criteria. We will look through the MSPs again."
              />
            </Typography>
          </div>
        ) : (
          <div className="w-full">
            <List>
              {filterMspList.map((msp: Msp) => {
                const { id, name } = msp;
                return (
                  <ListItem
                    key={id}
                    onClick={(event) => {
                      setMspDetails(msp);
                      setAnchorEl(event?.currentTarget);
                    }}
                    className={
                      id === selectedMspDetails?.id ? classes.root : ''
                    }
                    button
                    divider
                    dense
                    disableGutters={false}
                  >
                    <div className="flex w-full items-center justify-between">
                      <CustomToolTip
                        placement="top"
                        arrow
                        title={name}
                        showTruncated
                      >
                        <div className="truncate">
                          <Typography
                            variant="body-14-bold"
                            className="primary-highlight w-full truncate text-neutral"
                          >
                            {name}
                          </Typography>
                        </div>
                      </CustomToolTip>
                      <div
                        className={
                          id === selectedMspDetails?.id ? '' : 'hidden'
                        }
                      >
                        <CheckIcon size={20} />
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </div>
      <CustomWarning
        anchorEl={anchorEl}
        warningTitle={warningTitle}
        warningMessage={warningMessage}
        onSubmit={onSubmit}
        onClose={onClose}
        datatest="msp-confrirm"
        variant="primary"
        buttonLabel="Assign"
      />
    </>
  );
}

export default connector(SearchMsp);
