import React from 'react';

export const ProgressBar = ({
  completionPercentage = 0,
}: {
  completionPercentage: number;
}) => (
  <div className="h-1.5 w-full rounded-full bg-gray-100">
    <div
      className="h-full rounded-full bg-primary-300"
      style={{
        width: `${completionPercentage > 100 ? 100 : completionPercentage}%`,
      }}
    />
  </div>
);

export default ProgressBar;
