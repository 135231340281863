import { useMutation } from '@apollo/client';
import { createContext, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useThrottle } from 'rooks';
import { trackEvent } from '../../../analytics';
import { TRACKING_CONSTANTS } from '../../../analytics/constants/trackingConstants';
import { showToast } from '../../../components/hooks/use-toastify';
import {
  AssigneeType,
  BooleanObject,
  Maybe,
  Task,
  TaskMutationMoveTasksAcrossMilestoneArgs,
  TaskPayload,
  TaskStatus,
  User,
} from '../../../generated-types';
import {
  AddAssignResult,
  AddUserAssignTaskResult,
  ADD_ASSIGNEE,
  ADD_USER_ASSIGN_TASK,
} from '../../../graphql/mutation/task';
import {
  CreateTaskResult,
  CREATE_TASK,
} from '../../../graphql/mutation/task/create-task';
import {
  ReOrderTaskAcrossResult,
  ReOrderTaskResult,
  REORDER_TASK,
  REORDER_TASK_ACROSS_MILESTONE,
} from '../../../graphql/mutation/task/reorder-task';
import {
  UpdateTaskResult,
  UPDATE_TASK,
} from '../../../graphql/mutation/task/update-task';
import {
  addToSavingList,
  removeFromSavingList,
} from '../../save-indicator/save-redux';
import { EMspSaveIndicator } from '../model';
import { setSubscribersListAfterAssignUser } from '../msp-redux-store';
import {
  MilestoneType,
  TaskDetailType,
  TaskTabType,
} from '../task-detail-view/graphql';
import { deleteTask, setTaskName } from './milestone-redux-store';

export type TDrawerContext = {
  parentId?: string;
  taskIndex?: number;
  milestoneIndex?: number;
  parentRef?: React.MutableRefObject<null>;
  tabValue?: TaskTabType | MilestoneType;
  drawerType?: string;
};

type TMilestoneDrawerContext = {
  setDrawerData: React.Dispatch<React.SetStateAction<TDrawerContext>>;
  onDrawerOpen: () => void;
  parentId?: string;
  onDrawerClose: () => void;
};
export const MilestoneDrawerContext = createContext<TMilestoneDrawerContext>(
  {} as TMilestoneDrawerContext,
);

export enum ModalType {
  PROFILE = 'PROFILE',
  ASSIGNEE = 'ADD_ASSIGNEE',
}
export type TCardContext = {
  modalType?: ModalType;
  anchorEl?: HTMLElement | null;
  isAddUserCardOpen?: boolean;
};

export type AssigneeCardDataType = {
  mspIndex?: number;
  taskIndex?: number;
  isTaskDetail?: boolean;
  taskRef?: React.MutableRefObject<null>;
  taskDetailType?: TaskDetailType;
  mspId?: string;
  task?: Maybe<Task>;
  isViewModeEnabled?: boolean;
  isMilestoneDrawer?: boolean;
};
export type TMilestoneAssigneeCardContext = {
  setMilestoneAssigneeData: React.Dispatch<React.SetStateAction<TCardContext>>;
  milestoneAssigneeData?: TCardContext;
  onCardOpen: () => void;
  onCardClose: () => void;
  setAssigneeCardData: React.Dispatch<
    React.SetStateAction<AssigneeCardDataType>
  >;
  assigneeCardData: AssigneeCardDataType;
};

export const MilestoneAssigneeCardContext =
  createContext<TMilestoneAssigneeCardContext>(
    {} as TMilestoneAssigneeCardContext,
  );

export function useUpdateTaskName({
  taskId,
  prevTaskName,
  mspIndex,
  taskIndex,
  milestoneId,
  isMilestoneInternal = false,
  milestoneStartDate,
  taskDetailType,
  mspId,
  area,
}: // handleAddNewTask,
{
  taskId: string | undefined;
  prevTaskName: string | undefined;
  mspIndex: number;
  taskIndex: number;
  milestoneId: string | undefined;
  isMilestoneInternal?: boolean;
  milestoneStartDate?: number;
  taskDetailType: TaskDetailType;
  mspId?: string;
  area?: string;
  // handleAddNewTask: () => void;
}) {
  const dispatch = useDispatch();
  const [updateTaskName] = useMutation<UpdateTaskResult>(UPDATE_TASK);

  const updateText = useCallback(
    (text: string) => {
      if (taskDetailType === TaskDetailType.PLAN_VIEW) {
        dispatch(
          setTaskName({
            taskIndex,
            mspIndex,
            taskName: text,
          }),
        );
      }
    },
    [dispatch, taskDetailType, mspIndex, taskIndex],
  );

  const [createTask, { loading: createTaskLoading }] =
    useMutation<CreateTaskResult>(CREATE_TASK);

  const changeTaskName = useCallback(
    (name: string) => {
      if (prevTaskName !== name?.trim()) {
        updateText(name?.trim() || '');
        dispatch(
          addToSavingList({ saving: true, id: EMspSaveIndicator.TASK_NAME }),
        );
        if (taskId && !taskId.includes('newTask')) {
          updateTaskName({
            context: {
              headers: {
                ...(taskDetailType !== TaskDetailType.PLAN_VIEW && { mspId }),
              },
            },
            variables: {
              id: taskId,
              input: {
                ...(name !== '' && { name: name?.trim() }),
              },
            },
          })
            .then((result) => {
              const taskResult = result?.data;
              dispatch(removeFromSavingList(EMspSaveIndicator.TASK_NAME));
              const taskName = (
                (taskResult?.taskMutation?.updateTask as TaskPayload)
                  .items as Task[]
              )[0]?.name;

              if (
                taskResult?.taskMutation?.updateTask.__typename ===
                  'GraphqlError' ||
                (taskResult?.taskMutation?.updateTask as TaskPayload).items[0]
                  .__typename === 'GraphqlError'
              ) {
                throw new Error('Something went wrong while update task name');
              } else if (!taskName) {
                updateText(prevTaskName || '');
              } else {
                dispatch(
                  trackEvent(TRACKING_CONSTANTS.UPDATED_TASK_NAME, {
                    taskId,
                    mspId,
                    from: area,
                  }),
                );
              }
            })
            .catch(() => {
              updateText(prevTaskName || '');

              showToast(
                <FormattedMessage
                  id="MspPlanView.taskNameAPIError"
                  defaultMessage={"Task Name didn't save, please try again"}
                />,
                {
                  variant: 'error',
                },
              );
              dispatch(removeFromSavingList(EMspSaveIndicator.TASK_NAME));
            });
        } else {
          createTask({
            variables: {
              milestoneId,
              input: {
                name: name?.trim(),
                isInternal: isMilestoneInternal,
              },
              order: taskIndex + 1,
            },
          })
            .then((result) => {
              const taskResult = result.data;
              dispatch(removeFromSavingList(EMspSaveIndicator.TASK_NAME));
              const newTask = (
                (taskResult?.taskMutation?.createTask as TaskPayload)
                  .items as Task[]
              )[0];

              if (
                taskResult?.taskMutation?.createTask.__typename ===
                  'GraphqlError' ||
                (taskResult?.taskMutation?.createTask as TaskPayload).items[0]
                  .__typename === 'GraphqlError'
              ) {
                dispatch(
                  deleteTask({
                    mspIndex,
                    taskId,
                  }),
                );
                showToast(
                  <FormattedMessage
                    id="MspPlanView.newTaskApiError"
                    defaultMessage={"Task Name didn't save, please try again"}
                  />,
                  {
                    variant: 'error',
                  },
                );
              } else if (newTask) {
                dispatch(
                  setTaskName({
                    taskIndex,
                    mspIndex,
                    taskDueDate: newTask?.dueDate,
                    taskName: newTask?.name,
                    id: newTask?.id,
                    isTaskInternal: newTask?.isInternal,
                    taskStatus: TaskStatus.PENDING,
                  }),
                );
                dispatch(
                  trackEvent(TRACKING_CONSTANTS.ADDED_TASK, {
                    from: area,
                    mspId,
                  }),
                );
              }
            })
            .catch(() => {
              showToast(
                <FormattedMessage
                  id="MspPlanView.newTaskApiError"
                  defaultMessage={"Task Name didn't save, please try again"}
                />,
                {
                  variant: 'error',
                },
              );
              dispatch(removeFromSavingList(EMspSaveIndicator.TASK_NAME));
            });
        }
      }
    },
    [
      prevTaskName,
      updateText,
      dispatch,
      taskId,
      updateTaskName,
      createTask,
      milestoneId,
      milestoneStartDate,
      isMilestoneInternal,
      taskIndex,
    ],
  );
  return { changeTaskName, createTaskLoading };
}

export function useReorderTask() {
  const dispatch = useDispatch();
  const [reorderTask] = useMutation(REORDER_TASK, {
    onCompleted(taskResult: ReOrderTaskResult) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_REORDER));
      const isReorderCompleted = (
        taskResult?.taskMutation?.reOrderTask as BooleanObject
      ).success;
      if (
        !isReorderCompleted ||
        taskResult?.taskMutation?.reOrderTask.__typename === 'GraphqlError'
      ) {
        showToast(
          <FormattedMessage
            id="MspPlanView.taskReorderError"
            defaultMessage="Task reorder not saved. Please try again."
          />,
          {
            variant: 'error',
          },
        );
      }
    },
    onError() {
      showToast(
        <FormattedMessage
          id="MspPlanView.taskReorderError"
          defaultMessage="Task reorder not saved. Please try again."
        />,
        {
          variant: 'error',
        },
      );
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_REORDER));
    },
  });

  const [moveTasksAcrossMilestone] = useMutation<
    ReOrderTaskAcrossResult,
    TaskMutationMoveTasksAcrossMilestoneArgs
  >(REORDER_TASK_ACROSS_MILESTONE, {
    onCompleted(taskResult) {
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_REORDER));
      const isReorderCompleted = (
        taskResult.taskMutation.moveTasksAcrossMilestone as BooleanObject
      ).success;
      if (
        !isReorderCompleted ||
        taskResult.taskMutation.moveTasksAcrossMilestone.__typename ===
          'GraphqlError'
      ) {
        showToast(
          <FormattedMessage
            id="MspPlanView.taskReorderError"
            defaultMessage="Task re order not saved. Please try again."
          />,
          {
            variant: 'error',
          },
        );
      }
    },
    onError() {
      showToast(
        <FormattedMessage
          id="MspPlanView.taskReorderError"
          defaultMessage="Task re order not saved. Please try again."
        />,
        {
          variant: 'error',
        },
      );
      dispatch(removeFromSavingList(EMspSaveIndicator.TASK_REORDER));
    },
  });

  return {
    reorderTask,
    moveTasksAcrossMilestone,
  };
}

export function useAddAssignee() {
  const [
    assigneeMutation,
    // { loading: addAssigneLoading },
  ] = useMutation<AddAssignResult>(ADD_ASSIGNEE);
  const [createAssignMutation, { loading: createUserLoading }] =
    useMutation<AddUserAssignTaskResult>(ADD_USER_ASSIGN_TASK);
  const dispatch = useDispatch();

  const addAssignee = useCallback(
    ({
      taskId,
      user,
      assigneeType,
      mspId,
      area,
    }: {
      taskId: string;
      user: User;
      assigneeType: AssigneeType;
      mspId?: string;
      area?: string;
    }) => {
      function onError() {
        showToast(
          <FormattedMessage
            id="MspPlanView.addAssigneeError"
            defaultMessage="Task assignee could not be saved. Please try again."
          />,
          {
            variant: 'error',
          },
        );
      }
      assigneeMutation({
        context: {
          headers: {
            ...(mspId && { mspId }),
          },
        },
        variables: {
          taskIds: taskId,
          userIdMap: { id: user.id, primaryId: user?.primaryId, assigneeType },
        },
      })
        .then((res) => {
          const response = res?.data?.taskMutation
            ?.addAssignee as BooleanObject;
          if (
            !response?.success ||
            res?.data?.taskMutation.addAssignee.__typename === 'GraphqlError'
          ) {
            onError();
          } else {
            showToast(
              <FormattedMessage
                id="MspPlanView.addAssigneeAPI"
                defaultMessage="User assigned"
              />,
              {
                variant: 'success',
              },
            );
            if (assigneeType === AssigneeType?.USER) {
              dispatch(
                setSubscribersListAfterAssignUser({
                  user: { ...user, id: response.id },
                }),
              );
            }
            dispatch(
              trackEvent(TRACKING_CONSTANTS.ADDED_UPDATED_TASK_ASSIGNEE, {
                taskId,
                assigneeId: response.id,
                from: area,
              }),
            );
          }
        })
        .catch(() => {
          onError();
        });
    },
    [],
  );

  // const createUserAssign = useCallback(
  //   ({ taskId, data, handleClose, mspIndex }) => {
  //     function onError(errorCode: string) {
  //       showToast(
  //         <FormattedMessage
  //           id={`ErrorCode.${errorCode}`}
  //           defaultMessage="Task assignee could not be saved. Please try again."
  //         />,
  //         {
  //           variant: 'error',
  //         },
  //       );
  //     }
  //     createAssignMutation({
  //       variables: {
  //         taskIds: [taskId],
  //         input: data,
  //       },
  //     })
  //       .then((resp) => {
  //         const user = (
  //           (resp.data?.taskMutation?.createUserAndAssignToTasks as UserPayload)
  //             ?.items as User[]
  //         )?.[0];
  //         if (
  //           !user ||
  //           resp.data?.taskMutation.createUserAndAssignToTasks.__typename ===
  //             'GraphqlError' ||
  //           (
  //             (
  //               resp.data?.taskMutation
  //                 ?.createUserAndAssignToTasks as UserPayload
  //             ).items as UserResponse[]
  //           )[0]?.__typename === 'GraphqlError'
  //         ) {
  //           onError(
  //             (
  //               resp.data?.taskMutation
  //                 .createUserAndAssignToTasks as GraphqlError
  //             )?.code ||
  //               (
  //                 (
  //                   resp.data?.taskMutation
  //                     ?.createUserAndAssignToTasks as UserPayload
  //                 ).items?.[0] as GraphqlError
  //               )?.code,
  //           );
  //         } else {
  //           handleClose();
  //           dispatch(addAssigneeToTask({ user, mspIndex, taskId }));
  //           dispatch(setSubscribersListAfterAssignUser({ user }));
  //           showToast(
  //             <FormattedMessage
  //               id="Snackbar.addAssigneSuccess"
  //               defaultMessage="Assigned successfully"
  //             />,
  //             {
  //               variant: 'success',
  //             },
  //           );
  //         }
  //       })
  //       .catch(() => {
  //         onError('UNKNOWN_ERROR');
  //       });
  //   },
  //   [],
  // );

  return { addAssignee, createUserLoading };
}

let mouseLeaveTimer: unknown | number;

export function useUpdateAssigneeModal() {
  const handleMouseleave = (
    setMilestoneAssigneeData: React.Dispatch<
      React.SetStateAction<TCardContext>
    >,
  ) => {
    mouseLeaveTimer = setTimeout(() => {
      setMilestoneAssigneeData({});
      // setAssigneeCardData({});
    }, 420);
  };
  const clearMouseLeaveEvent = () => {
    clearTimeout(mouseLeaveTimer as number);
  };
  const [handleMouseEnter] = useThrottle(
    (
      targetElem: HTMLElement,
      setMilestoneAssigneeData: React.Dispatch<
        React.SetStateAction<TCardContext>
      >,
      type: ModalType,
      setAssigneeCardData: React.Dispatch<
        React.SetStateAction<AssigneeCardDataType>
      >,
      assigneeCardData: AssigneeCardDataType,
    ) => {
      setMilestoneAssigneeData({
        modalType: type,
        anchorEl: targetElem,
      });
      setAssigneeCardData(assigneeCardData);
    },
    400,
  );
  const handleOnAvatarMouseEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    setMilestoneAssigneeData: React.Dispatch<
      React.SetStateAction<TCardContext>
    >,
    type: ModalType,
    setAssigneeCardData: React.Dispatch<
      React.SetStateAction<AssigneeCardDataType>
    >,
    assigneeCardData: AssigneeCardDataType,
  ) => {
    clearTimeout(mouseLeaveTimer as number);
    const eventType = event?.type;
    const targetElem = event?.currentTarget;
    if (eventType === 'mouseleave') {
      handleMouseleave(setMilestoneAssigneeData);
    } else {
      handleMouseEnter(
        targetElem,
        setMilestoneAssigneeData,
        type,
        setAssigneeCardData,
        assigneeCardData,
      );
    }
  };

  return { handleOnAvatarMouseEvent, clearMouseLeaveEvent, handleMouseleave };
}

// export type TDrawerContext = {
//   parentId?:string;
//   taskIndex?:number;
//   milestoneIndex?:number;
//   parentRef?: React.MutableRefObject<null>;
//   tabValue?: TaskTabType|MilestoneType;
//   drawerType?:string;
// }

// type TMilestoneDrawerContext = {
//   setDrawerData: React.Dispatch<React.SetStateAction<TDrawerContext>>;
//   onDrawerOpen: ()=>void;
//   parentId?: string;
//   onDrawerClose: ()=>void;
// };
// export const MilestoneDrawerContext = createContext<TMilestoneDrawerContext>({} as TMilestoneDrawerContext);
