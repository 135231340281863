import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const commentLoadingSlice = createSlice({
  name: 'comment-loading',
  initialState: { loadingStatus: false, list: [] as string[] },
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{
        loading: boolean;
        id: string;
      }>,
    ) => {
      const { loading, id } = action.payload;
      if (loading) {
        state.list.push(id);
        state.loadingStatus = loading;
      } else {
        const newList = state.list.filter((item) => id !== item);
        state.list = newList;
        state.loadingStatus = !!newList.length;
      }
    },
  },
});

export const { setLoadingStatus } = commentLoadingSlice.actions;

export default commentLoadingSlice.reducer;
