export type DynamicImportType = () => Promise<{
  default: React.ComponentType<any>;
}>;
export type LazyComponentImport = () => React.LazyExoticComponent<
  React.ComponentType<any>
>;
export type LazyComponentType = React.LazyExoticComponent<
  React.ComponentType<any>
>;

export const lazyRetry: (
  componentImport: DynamicImportType,
) => Promise<{ default: React.ComponentType<any> }> = function (
  componentImport: DynamicImportType,
) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    ) as boolean;
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
        return true;
      })
      .catch((error: Error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload(); // refresh the page
        }
        reject(error); // there was an error
      });
  });
};
