import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { expandedAll } from '../../alignx/msp-view/milestone-details/milestone-redux-store';
import { trackEvent } from '../../analytics';
import MarkTaskCompleted from '../../assets/micro-task-completed.svg';
import MarkTaskPending from '../../assets/micro-task-pending.svg';
import MarkTaskProgress from '../../assets/micro-task-progress.svg';
import { useDebounce } from '../../common/methods/debounce';
import { Assignee, User, UserType } from '../../generated-types';
import { CommonAvatar } from '../avatar/new-avatar';
import { DotIcon, RightTickIcon } from '../icons';
import { BaInput } from '../input';
import { CustomToolTip } from '../tool-tip/tooltip';
import { Typography } from '../typography';
import { clearAllFilters, setSelectedFilters } from './filter-redux';
import { TFilterItem } from './model';

const useStylesForTooltip = makeStyles(() => ({
  tooltip: {
    textTransform: 'capitalize',
  },
}));
interface IFilterList {
  list: TFilterItem[];
  selectedFilterType: string | undefined;
  sectionName: string;
  debounceTime: number;
  isFilterSelected: boolean;
  onApply?:
    | ((filters?: { name: string; list: TFilterItem[] }) => void)
    | undefined;
  isExpanded?: boolean;
  isSingleSelect?: boolean;
  onClose?: () => void;
}
const getUserFullName = (user: Assignee) => {
  let firstName = '';
  let lastName = '';
  const userFirstName = (
    (user && (user as User)?.firstName) ||
    (user as User)?.email?.split('@')?.[0]
  )
    .split(' ')
    .join('');
  const userLastName =
    (user && (user as User)?.lastName)?.split(' ').join('') ?? '';
  if (userFirstName && userFirstName.length > 0) {
    firstName =
      userFirstName?.[0]
        .toUpperCase()
        .concat(userFirstName.slice(1, userFirstName.length)) ?? '';
  }
  if (userLastName && userLastName.length > 0) {
    lastName =
      userLastName?.[0]
        .toUpperCase()
        .concat(userLastName.slice(1, userLastName.length)) ?? '';
  }
  return `${firstName} ${lastName}`;
};
function getFilterListIcon(
  id: string | undefined,
  additionalFilterDetails: User,
) {
  switch (id) {
    case 'task-pending':
      return <img alt="task-pending" src={MarkTaskPending as string} />;
    case 'task-progress':
      return <img alt="task-progress" src={MarkTaskProgress as string} />;
    case 'task-completed':
      return <img alt="task-completed" src={MarkTaskCompleted as string} />;
    case 'ON_TRACK':
      return <DotIcon className="text-lg text-green-500" />;
    case 'OFF_TRACK':
      return <DotIcon className="text-lg text-red-500" />;
    case 'UNKNOWN':
      return <DotIcon className="text-lg text-yellow-500" />;
    case 'msp.buyerCompanyId':
      return (
        <CommonAvatar
          email={additionalFilterDetails?.email ?? ''}
          size="small"
          userType={additionalFilterDetails?.userType ?? UserType.BUYER}
          fullName={
            additionalFilterDetails &&
            getUserFullName(additionalFilterDetails ?? '')
          }
        />
      );

    default:
      return null;
  }
}

export function FilterList({
  list,
  selectedFilterType = '',
  onApply,
  isExpanded,
  sectionName,
  debounceTime,
  isFilterSelected,
  isSingleSelect,
  onClose,
}: IFilterList) {
  const dispatch = useDispatch();
  const classes = useStylesForTooltip();
  const [localList, setLocalList] = useState<TFilterItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  useEffect(() => {
    const arr = list?.map(
      ({ isSelected, label, value, listKey, additionalFilterDetails }) => ({
        label,
        isSelected,
        value,
        listKey,
        additionalFilterDetails,
      }),
    );
    setLocalList(arr);
  }, [list]);

  const debouncedReduxFilterUpdate = useDebounce((list: TFilterItem[]) => {
    if (!isExpanded) dispatch(expandedAll());
    dispatch(
      setSelectedFilters({ name: selectedFilterType, list, sectionName }),
    );
    if (onApply && typeof onApply === 'function') {
      onApply({ name: selectedFilterType, list });
    }
  }, debounceTime);

  const handleClick = useCallback(
    (
      clickedItemName: string | undefined,
      selectedFilterType: string | undefined,
    ) => {
      const newList = localList?.map(
        ({ isSelected, label, value, listKey, additionalFilterDetails }) => {
          if (clickedItemName === value) {
            dispatch(
              trackEvent(`${selectedFilterType as string} Filter Clicked`, {
                value: label,
              }),
            );
            return {
              label,
              isSelected: !isSelected,
              value,
              listKey,
              additionalFilterDetails,
            };
          }
          return {
            label,
            isSelected: isSingleSelect ? false : isSelected,
            value,
            listKey,
            additionalFilterDetails,
          };
        },
      );
      setLocalList(newList);
      debouncedReduxFilterUpdate(newList);
      if (isSingleSelect) {
        onClose?.();
      }
    },
    [localList],
  );

  if ((!localList || localList?.length === 0) && sectionName !== 'analytics')
    return (
      <Typography
        variant="body-13-regular"
        element="div"
        className="p-6 text-mid"
      >
        No results found
      </Typography>
    );

  return (
    <div className={`${localList?.length > 5 ? 'w-64' : 'w-40'} max-h-72`}>
      <div
        className={`max-h-52 space-y-1 ${
          isFilterSelected ? 'pt-2' : 'py-2'
        } flex flex-col`}
      >
        {localList?.length > 5 && (
          // TODO:create an enum for all the selectedFilter types and use value from there
          <BaInput
            parentValue={searchTerm}
            placeholder={`Search ${
              selectedFilterType === 'allUsers'
                ? 'Assignees'
                : selectedFilterType
            }`}
            className="m-2"
            onChange={setSearchTerm}
            type="search"
            hasBackground
          />
        )}
        <div className="flex max-h-full flex-col space-y-1 overflow-y-auto">
          {localList
            ?.filter(({ label }) =>
              label?.toLowerCase()?.includes(searchTerm?.toLowerCase() ?? ''),
            )
            .map(
              ({
                label,
                isSelected,
                value,
                listKey,
                additionalFilterDetails,
              }) => (
                <div
                  role="presentation"
                  key={value}
                  className={`flex cursor-pointer items-center justify-start space-x-2 px-4 py-2 ${
                    isSelected ? 'bg-primary-100' : ''
                  } hover:bg-neutral-50`}
                  onClick={() => handleClick(value, selectedFilterType)}
                >
                  {getFilterListIcon(listKey, additionalFilterDetails as User)}
                  <CustomToolTip classes={classes} title={label} showTruncated>
                    <Typography
                      variant="body-13-regular"
                      className="px truncate capitalize"
                    >
                      {label?.toLowerCase()?.replace(/[_]/, ' ')}
                    </Typography>
                  </CustomToolTip>
                  {isSelected && (
                    <RightTickIcon className="text-18 text-primary" />
                  )}
                </div>
              ),
            )}
        </div>
      </div>
      {isFilterSelected && !isSingleSelect && (
        <Typography
          variant="body-13-regular"
          element="div"
          className="mt-1 mb-2 w-full cursor-pointer bg-white px-4 py-2 text-error hover:bg-neutral-50"
          onClick={() =>
            dispatch(
              clearAllFilters({ filterType: selectedFilterType, sectionName }),
            )
          }
        >
          Clear all
        </Typography>
      )}
    </div>
  );
}
