import { ApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  MspPayloadResponse, Operation, TaskConnectionResponse, TaskStatus,
} from '../../../generated-types';
import { GET_OVERDUE_TASKS_IN_MILESTONE, GET_MSP_TRACK_STATUS } from './graphql';

export type ResultOffTrackTask = {
  taskQuery: {
    overdueTasks: TaskConnectionResponse;
  };
};

export type ResultMspTrackStatus = {
  mspQuery: {
    getMsp: MspPayloadResponse;
  };
};

export const fetchOverdueMilestoneList = createAsyncThunk(
  'msp/get-overdue-milestones',
  async (
    {
      client,
      mspId,
    }: {
      client: ApolloClient<object>;
      mspId:string
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultOffTrackTask>({
        query: GET_OVERDUE_TASKS_IN_MILESTONE,
        variables: {
          operations: {
            sort: [{ field: 'task.order', order: 'ASC' }],
            filter: {
              operator: 'AND',
              filters: [
                {
                  op: Operation.LE,
                  values: [dayjs().unix().toString()],
                  field: 'task.dueDate',
                },
                {
                  op: Operation.EQ,
                  field: 'task.mspId',
                  values: [mspId],
                },
                {
                  op: Operation.IN,
                  field: 'task.status',
                  values: [TaskStatus.PENDING, TaskStatus.INPROGRESS],
                },
              ],
            },
          },
        },
      });
      return { data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchMspTrackStatus = createAsyncThunk(
  'msp/get-msp-track-status',
  async (
    {
      client,
      mspId,
    }: {
      client: ApolloClient<object>;
      mspId:string
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await client.query<ResultMspTrackStatus>({
        query: GET_MSP_TRACK_STATUS,
        variables: {
          id: mspId,
          options: {
            recalculateAnalytics: true,
          },
        },
      });
      return { data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
