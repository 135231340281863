import jwtDecode from 'jwt-decode';

export function isJwtExpired(token: string) {
  if (!token) return true;

  try {
    const { exp }: { exp: number } = jwtDecode(token);
    return exp < (new Date().getTime() + 1) / 1000;
  } catch {
    return true;
  }
}
