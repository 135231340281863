module.exports = {
  fontSize: {
    8: ['8px', 1.4],
    10: ['10px', 1.4],
    11: ['11px', 1.4],
    12: ['12px', 1.4],
    13: ['13px', 1.4],
    14: ['14px', 1.4],
    16: ['16px', 1.5],
    18: ['18px', 1.5],
    20: ['20px', 1.5],
    22: ['22px', 1.5],
    24: ['24px', 1.5],
  },
  boxShadow: {
    20: '0px 1px 24px 0px #00000012',
    30: '0px 1px 24px 0px rgba(0, 0, 0, 0.04)',
    40: '0px 2px 14px rgba(0, 0, 0, 0.06)',
    80: '0px 4px 14px rgba(0, 0, 0, 0.08)',
    100: '0px 15px 12px 0px rgba(0, 0, 0, 0.15)',
    200: '6px 7px 15px 1px rgb(0 0 0 / 2%)',
    300: '0px 7px 15px 1px rgba(0, 0, 0, 0.11)',
    400: '0px 2px 14px 0px rgba(72, 92, 240, 0.16)',
    500: '0px 3px 14px 0px rgba(192, 203, 237, 0.29)',
    600: '0px 5px 12px rgba(0, 0, 0, 0.31)',
    'toast-success': '0px 10px 24px rgba(64, 190, 97, 0.1)',
    'toast-error': '0px 10px 24px rgba(253, 73, 84, 0.1)',
    'toast-info': '0px 10px 24px rgba(28, 157, 248, 0.1)',
    'toast-warning': '0px 10px 24px rgba(255, 106, 9, 0.1)',
    'menu-active-indicator': '-3px 0px 0px 0px #485cf0 inset;',
    'i-map-card-hover': '0px 4px 10px 0px #59758A33',
    'i-map-card-sentiment': '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
    'i-map-filter-card': '0px 3px 13px 0px #00000014',
    'i-map-contact-card': '0px 4px 14px 0px #00000017',
  },
  colors: {
    primary: {
      50: '#F8FAFC',
      100: 'var(--color-primary-100)',
      200: 'var(--color-primary-200)',
      250: 'rgba(17, 141, 239, 0.1)',
      300: 'var(--color-primary-300)',
      400: '#6D7DF3',
      450: '#118DEF',
      500: 'var(--color-primary)',
      DEFAULT: 'var(--color-primary)',
      600: '#435868',
      'light-bg': 'rgba(72, 92, 240, 0.1)',
      'light-100': 'rgba(141, 153, 246, 0.1)',
      700: '#2D3FC6',
      linkedin: 'var(--color-linkedin)',
    },
    'old-primary': {
      DEFAULT: '#485cf0',
      100: '#8D99F6',
    },
    secondary: {
      50: '#EAFBFE',
      100: '#D4F6FD',
      200: '#AAEDFB',
      300: '#7FE4F8',
      400: '#55DBF6',
      500: 'var(--color-secondary)',
      DEFAULT: 'var(--color-secondary)',
      600: '#209EB7',
    },
    warning: {
      10: 'var(--color-warning-10)',
      100: '#FFFBF7',
      200: '#FFE4D1',
      300: '#FFBE40',
      350: '#EC9750',
      400: '#FF6B00',
      500: 'var(--color-warning-500)',
      600: 'var(--color-warning-600)',
      700: 'var(--color-warning-700)',
      DEFAULT: '#FD4954',
    },
    success: {
      100: '#F2FFE2',
      200: '#65CC81',
      300: 'rgba(60, 193, 59, 0.1)',
      450: '#3CC13B',
      DEFAULT: '#3FBF62',
      600: '#27AE60',
    },
    error: {
      200: 'rgba(255, 92, 92, 0.1)',
      300: '#FF5C5C',
      500: '#EE224E',
      DEFAULT: '#EE224E',
      550: 'rgba(253, 73, 84, 0.1)',
      600: '#FD4954',
    },
    mid: {
      10: 'rgba(250, 250, 250, 1)',
      20: 'rgba(227, 227, 227, 1)',
      30: '#E8EEF2',
      40: '#EBEBEB',
      50: '#EFF2F4',
      100: '#DEE3E8',
      200: '#BDC8D0',
      250: '#BCC9D6',
      300: '#9BACB9',
      350: '#879FB7',
      400: '#7A91A1',
      500: 'var(--color-mid)',
      DEFAULT: 'var(--color-mid)',
      600: '#435868',
      650: '#395468',
      700: '#415B91',
      800: '#59758A',
      900: '#5F7585',
      1000: '#4B5C6D',
    },
    neutral: {
      40: '#EAEAEA',
      50: '#E9EAEB',
      100: '#D1D3D5',
      200: '#A4A8AC',
      300: '#767C82',
      350: '#4B4B4B',
      400: '#495159',
      450: '#333333',
      470: '#707070',
      500: 'var(--color-neutral)',
      DEFAULT: 'var(--color-neutral)',
      550: ' #263238',
      600: '#141C23',
      700: '#0F172A',
      800: '#0000001a',
    },
    'light-bg': {
      DEFAULT: 'var(--color-light-bg)',
      10: '#F8F8F8',
      20: '#F5F6FB',
      30: '#F6F8FA',
      40: '#FFFCF8',
    },
    'white-opacity': {
      10: 'rgba(255, 255, 255, 0.1)',
    },
    yellow: {
      400: 'rgba(255, 217, 16, 0.1)',
      500: '#FFD910',
      DEFAULT: '#FFD910',
      600: '#FFBE40',
      700: 'var(--color-yellow-700)',
      800: '#FFA800',
    },
    linkedin: {
      DEFAULT: 'var(--color-linkedin)',
      light: 'var(--color-linkedin-light)',
    },
  },
};
